<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" title="原辅材料" :mask-closable="false" width="1000px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" class="cost-form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="材料编号" :labelCol="{span: 8}" style="margin-left: 70px;" prop="materialInfo.code">
        <a-select
          style="width: 500px"
          :value="form.materialInfo.innerCode"
          show-search
          :default-active-first-option="false"
          placeholder="请输入材料编号/材料牌号/名称/规格搜索"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @search="fetchMaterialByInnerCode"
          @change="(value, option) => handleMaterialNameChange(value, option)"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option
            v-for="material in materials"
            :key="material.id"
          >{{ material.materialName }}【{{ material.purchaseSpecification }} {{ material.materialCode }}】</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="材料牌号" :labelCol="{span: 8}" style="margin-left: 70px;" prop="materialInfo.code">
        <a-input style="width: 400px" :maxLength="20" v-model.trim="form.materialInfo.code" placeholder="请输入材料牌号" />
      </a-form-model-item>
      <a-form-model-item label="材料名称" :labelCol="{span: 8}" style="margin-left: 70px;" prop="materialInfo.name">
        <a-input style="width: 400px" :maxLength="20" v-model.trim="form.materialInfo.name" placeholder="请输入材料名称" />
      </a-form-model-item>
      <a-form-model-item label="采购规格" :labelCol="{span: 8}" style="margin-left: 70px;" prop="materialInfo.purchaseSpecification">
        <a-input style="width: 400px" :maxLength="20" v-model.trim="form.materialInfo.purchaseSpecification" placeholder="请输入采购规格" />
      </a-form-model-item>
      <a-form-model-item label="材料单位" :labelCol="{span: 8}" style="margin-left: 70px;" prop="materialInfo.unit">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.materialInfo.unit" placeholder="请输入材料单位" />
      </a-form-model-item>
      <a-form-model-item label="当前最新单价(元/kg)" :labelCol="{span: 8}" style="margin-left: 70px;" prop="materialInfo.unitPrice">
        <a-input
          style="width: 300px;margin-right: 10px"
          :maxLength="20"
          v-model.trim="form.materialInfo.unitPrice"
          placeholder="请输入当前最新单价"
        />
      </a-form-model-item>
      <a-form-model-item label="计价模型" :labelCol="{span: 8}" style="margin-left: 70px;" prop="pricingModel">
        <a-radio-group v-model="form.pricingModel" style="width: 400px; fontWeight: 400;" @change="handleChangePricingModal">
          <a-radio value="CBE">方料</a-radio>
          <a-radio value="CLM">棒料</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="密度(g/cm3)" :labelCol="{span: 8}" style="margin-left: 70px;" prop="materialInfo.density">
        <a-input style="width: 300px; margin-right: 10px" :maxLength="20" v-model.trim="form.materialInfo.density" placeholder="请输入密度" />
      </a-form-model-item>
      <template v-if="form.pricingModel === 'CBE'">
        <a-form-model-item label="长度(mm)" :labelCol="{span: 8}" style="margin-left: 70px;" prop="consumeSpecification.length">
          <a-input
            style="width: 300px;margin-right: 10px"
            :maxLength="20"
            v-model.trim="form.consumeSpecification['length']"
            placeholder="请输入长度"
          />
        </a-form-model-item>
        <a-form-model-item label="宽度(mm)" :labelCol="{span: 8}" style="margin-left: 70px;" prop="consumeSpecification.width">
          <a-input
            style="width: 300px;margin-right: 10px"
            :maxLength="20"
            v-model.trim="form.consumeSpecification.width"
            placeholder="请输入宽度"
          />
        </a-form-model-item>
        <a-form-model-item label="厚度(mm)" :labelCol="{span: 8}" style="margin-left: 70px;" prop="consumeSpecification.height">
          <a-input
            style="width: 300px;margin-right: 10px"
            :maxLength="20"
            v-model.trim="form.consumeSpecification.height"
            placeholder="请输入厚度"
          />
        </a-form-model-item>
      </template>
      <template v-if="form.pricingModel === 'CLM'">
        <a-form-model-item label="直径(mm)" :labelCol="{span: 8}" style="margin-left: 70px;" prop="consumeSpecification.diameter">
          <a-input
            style="width: 300px;margin-right: 10px"
            :maxLength="20"
            v-model.trim="form.consumeSpecification.diameter"
            placeholder="请输入长度"
          />
        </a-form-model-item>
        <a-form-model-item label="高度(mm)" :labelCol="{span: 8}" style="margin-left: 70px;" prop="consumeSpecification.height">
          <a-input
            style="width: 300px;margin-right: 10px"
            :maxLength="20"
            v-model.trim="form.consumeSpecification.height"
            placeholder="请输入高度"
          />
        </a-form-model-item>
      </template>
    </a-form-model>
  </a-modal>
</template>
<script>
import {
  fuzzyMaterial,
  getMaterialByMaterialCode,
  getMaterialByMaterialInnerCode
} from '@/api/material'
import * as R from 'ramda'
import debounce from 'lodash/debounce'
export default {
  props: {
    record: Object
  },
  data() {
    this.fetchMaterialByInnerCode = debounce(this.fetchMaterialByInnerCode, 800)
    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      fetching: false,
      form: this.record || {
        sequence: '',
        materialInfo: {
          code: '',
          name: '',
          density: '',
          unit: '',
          unitPrice: '',
          purchaseSpecification: ''
        },
        pricingModel: 'CLM',
        consumeSpecification: {}
      },
      rules: {
        'materialInfo.code': [
          {
            required: true,
            message: '请输入材料牌号',
            trigger: 'blur'
          }
        ],
        'materialInfo.name': [
          {
            required: true,
            message: '请输入材料名称',
            trigger: 'blur'
          }
        ],
        'materialInfo.density': [
          {
            required: true,
            message: '请输入密度',
            trigger: 'blur'
          }
        ],
        'materialInfo.unit': [
          {
            required: true,
            message: '请输入材料单位',
            trigger: 'blur'
          }
        ],
        'materialInfo.unitPrice': [
          {
            required: true,
            message: '请输入单价',
            trigger: 'blur'
          }
        ],
        pricingModel: [
          {
            required: true,
            message: '请选择计价模型',
            trigger: 'change'
          }
        ],
        'consumeSpecification.diameter': [
          {
            required: true,
            message: '请输入直径',
            trigger: 'blur'
          }
        ],
        'consumeSpecification.height': [
          {
            required: true,
            message: '请输入高度或厚度',
            trigger: 'blur'
          }
        ],
        'consumeSpecification.length': [
          {
            required: true,
            message: '请输入长度',
            trigger: 'blur'
          }
        ],
        'consumeSpecification.width': [
          {
            required: true,
            message: '请输入宽度',
            trigger: 'blur'
          }
        ]
      },
      lastFetchId: 0,
      materials: []
    }
  },
  computed: {
    // 计算属性的 getter
    isUpdate: function() {
      return this.record
    },
    isAdd: function() {
      return !this.record
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.$emit('add', this.form)
          } else {
            this.$emit('update', this.form)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleChangePricingModal() {
      this.form.consumeSpecification = {}
    },

    fetchMaterialByInnerCode(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      fuzzyMaterial({
        searchText: value
      })
        .then(result => {
          if (fetchId !== this.lastFetchId) {
            return
          }
          this.materials = result.data
          this.fetching = false
        })
        .finally(() => {
          this.fetching = false
        })
    },
    handleMaterialNameChange(id, option, index) {
      this.materials.map(val => {
        if (val.id === id) {
          console.log(id, val)
          this.form.materialInfo.innerCode = val.innerCode
          this.form.materialInfo.code = val.materialCode
          this.form.materialInfo.name = val.materialName
          this.form.materialInfo.purchaseSpecification =
            val.purchaseSpecification
          this.form.materialInfo.unit = val.unit
          this.form.materialInfo.unitPrice = val.unitPrice
          this.form.materialInfo.density = val.density
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-select-dropdown-menu-item {
  overflow: unset;
  white-space: wrap;
  text-overflow: unset;
}
</style>
