<template>
  <div class="card-container">
    <div id="craft-card-print" class="custom-table">
      <div style="page-break-after:always">
        <div class="title">
          <div class="left">
            <img src="@/assets/logo.png" alt style="width: 100%; height: 100%" />
          </div>
          <div class="middle">
            <p>重庆市DBR刀具有限公司</p>
            <p>废品入库单</p>
          </div>
          <div class="right">NO: {{ data.putInNumber }}</div>
        </div>
        <a-table :columns="columns" :data-source="productList" bordered :pagination="false">
          <div slot="responsibleOperationInfo" slot-scope="text, column">
            <template v-if="text">
              {{ column.responsibleOperationInfo.map((item) => {
              return item.operationName
              }).toString() }}
            </template>
            <template v-else>{{ }}</template>
          </div>
          <div slot="defeatReasonInfo" slot-scope="text, column">
            <template v-if="text">
              {{ column.defeatReasonInfo.map((item) => {
              return item.parameterName
              }).toString() }}
            </template>
            <template v-else>{{ }}</template>
          </div>
        </a-table>
        <div class="footer-form">
          <div class="top">
            <div class="top-left" />
            <div class="top-right">
              <div class="label">合计金额</div>
              <div class="content">{{ totalAmount }}</div>
            </div>
          </div>
          <div class="bottom">
            <div class="left">制单人：{{ $store.state.user.name }}</div>
            <div class="middle">接收人：</div>
            <div class="right">日期：</div>
          </div>
        </div>
      </div>
    </div>
    <a-button v-print="'craft-card-print'" type="primary" block>打印</a-button>
  </div>
</template>
<script>
import { getAbandonCard } from '@/api/inventory'
const columns = [
  {
    title: '序号',
    dataIndex: 'sequenceNum',
    width: 50,
  },
  {
    title: '任务号',
    dataIndex: 'taskCode',
    width: 100,
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    width: 120,
  },
  {
    title: '图号',
    dataIndex: 'drawingNum',
    width: 120,
  },
  {
    title: '单位',
    dataIndex: 'unit',
    width: 50,
  },
  {
    title: '数量',
    dataIndex: 'putInCount',
    width: 80,
    scopedSlots: {
      customRender: 'putInCount',
    },
  },
  {
    title: '产值单价',
    dataIndex: 'productionPrice',
    width: 100,
  },
  {
    title: '产值金额',
    dataIndex: 'productionAmount',
    width: 80,
  },
  {
    title: '报废工序',
    dataIndex: 'responsibleOperationInfo',
    width: 200,
    scopedSlots: {
      customRender: 'responsibleOperationInfo',
    },
  },
  {
    title: '报废因素',
    dataIndex: 'defeatReasonInfo',
    width: 150,
    scopedSlots: {
      customRender: 'defeatReasonInfo',
    },
  },
  {
    title: '报废单号',
    dataIndex: 'abandonReceiptNumber',
    width: 150,
  },
  {
    title: '备注',
    dataIndex: 'comment',
    width: 100,
  },
]
export default {
  data() {
    return {
      columns,
      data: [],
      productList: [],
      totalAmount: '',
    }
  },
  created() {
    const params = this.$route.query
    getAbandonCard(params).then((res) => {
      if (res.data) {
        this.totalAmount = res.data.totalAmount
        this.productList = res.data.receiptItems

        const len = this.productList.length
        const _len = 10 - this.productList.length

        this.productList.map((val, index) => {
          val.sequenceNum = index + 1
        })
        if (_len > 0) {
          for (let i = 0; i < _len; i++) {
            this.productList.push({
              sequenceNum: len + i + 1,
            })
          }
        }
        this.data = res.data
      } else {
        this.$message.error('获取废品入库单失败，请重试！')
      }
    })
  },
}
</script>
  <style scoped lang="scss">
.card-container {
  width: 70%;
  margin: auto;
  margin-top: 100px;
}

.title {
  width: 100%;
  height: 80px;
  /* border: 2px solid #000; */
  position: relative;
  .left {
    width: 100px;
    height: 50px;
    position: absolute;
    top: -15px;
    left: 100px;
  }
  .middle {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #000;
  }
  .right {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 16px;
    color: #000;
  }
}
.info {
  width: 100%;
  display: flex;
  margin-bottom: -10px;
  font-size: 16px;
  .date {
    margin-left: 800px;
  }
}
/deep/ .ant-table {
  padding: 10px 0px;
  color: black;
}

/deep/ .ant-descriptions-item-label {
  color: black;
}

/deep/ .ant-descriptions-item-content {
  color: black;
}

.card-title >>> .ant-descriptions-item-label {
  font-size: 20px;
}
.amount-font {
  font-size: 18px;
  margin: 0;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
  border: 1px solid black;
  text-align: center;
  padding: 1px 8px;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
  border: 1px solid black;
  text-align: center;
  padding: 1px 8px;
}

/deep/ .ant-table-thead th {
  padding: 8px 8px;
  text-align: center;
  border: 1px solid black;
  color: black;
}

/deep/ .ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border: 1px solid black;
}

/deep/ .ant-table-tbody td {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 2px 8px;
  text-align: center;
}

/deep/ .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid black;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-view {
  border: 0px;
}

.footer-form {
  width: 100%;
  height: 100px;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 14px;
  .top {
    flex: 1;
    border-bottom: 2px solid #000;
    border: 1px solid #000;
    display: flex;
    .top-left {
      flex: 1.17;
    }
    .top-right {
      color: #000;
      flex: 1;
      border-left: 2px solid #000;
      display: flex;
      .label {
        flex: 1;
        border-right: 2px solid #000;
        text-align: center;
        line-height: 50px;
      }
      .content {
        flex: 2.1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .bottom {
    color: #000;
    flex: 1;
    display: flex;
    align-items: center;
    .middle {
      margin-left: 200px;
    }
    .right {
      margin-left: 480px;
    }
  }
}

.bottom-line {
  width: 100px;
  height: 20px;
  margin-right: 10px;

  display: inline-block;
}
.custom-table {
  margin-bottom: 40px;
}
</style>

