var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.code + "产品项明细",
        "mask-closable": false,
        width: "75%",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _vm.status === "CCK"
            ? [
                _c(
                  "a-button",
                  { attrs: { type: "danger" }, on: { click: _vm.reject } },
                  [_vm._v("驳回")]
                ),
                _c(
                  "a-button",
                  { attrs: { type: "primary" }, on: { click: _vm.approval } },
                  [_vm._v("通过")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("a-table", {
        staticClass: "users-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          columns: _vm.approvalDetailColumns,
          "data-source": _vm.list,
          loading: _vm.loading,
          pagination: _vm.pagination,
          scroll: { x: 1500 },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function (text) {
              return _c("div", {}, [
                text === "CCK" ? _c("span", [_vm._v("审价中")]) : _vm._e(),
                text === "AGR" ? _c("span", [_vm._v("已通过")]) : _vm._e(),
                text === "RJT" ? _c("span", [_vm._v("已驳回")]) : _vm._e(),
              ])
            },
          },
          {
            key: "unitCompanyResidueRate",
            fn: function (text) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text * 100 + "%" : ""))]),
              ])
            },
          },
          {
            key: "outsourcingCostRate",
            fn: function (text) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text * 100 + "%" : ""))]),
              ])
            },
          },
          {
            key: "outProcessingCostRate",
            fn: function (text) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text * 100 + "%" : ""))]),
              ])
            },
          },
          {
            key: "materialCostRate",
            fn: function (text) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text * 100 + "%" : ""))]),
              ])
            },
          },
          {
            key: "operate",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-user" },
                [
                  _c(
                    "a-dropdown",
                    [
                      _c(
                        "a-menu",
                        {
                          staticClass: "ant-dropdown-link",
                          attrs: { slot: "overlay" },
                          slot: "overlay",
                        },
                        [
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleEditPrice(column.id, column)
                                },
                              },
                            },
                            [_vm._v("调整报出价格")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        { staticClass: "user-btn" },
                        [
                          _vm._v(" 操作 "),
                          _c("a-icon", { attrs: { type: "down" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
      _vm.approcalPriceModalVisible
        ? _c("ApprocalPriceModal", {
            attrs: { id: _vm.editId, record: _vm.editRecord },
            on: {
              confirm: _vm.approcalPriceConfirm,
              cancel: _vm.approcalPriceCancel,
            },
          })
        : _vm._e(),
      _vm.approvalResultModalVisible
        ? _c("ApprovalResultModal", {
            attrs: { code: _vm.code, type: _vm.approvalType },
            on: { confirm: _vm.approcalConfirm, cancel: _vm.approcalCancel },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }