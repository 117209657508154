export const commonColumns = [
  {
    title: '任务编号',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    searchType: 'text',
    width: 150,
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'code',
    },
  }
]

export const detailColumns = [
  ...commonColumns,
  {
    title: '紧急类型',
    dataIndex: 'emergencyType',
    key: 'target_task.emergencyType',
    width: 150,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'emergencyType',
    },
    filters: [
      {
        text: '普通',
        value: 'NML',
      },
      {
        text: '必完件',
        value: 'MFN',
      },
      {
        text: '紧急件',
        value: 'UGC',
      },
      {
        text: '插入急件',
        value: 'IUG',
      },
      {
        text: '转入急件',
        value: 'TUG',
      },
    ],
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    width: 200,
    ellipsis: true,
    key: 'product.productName',
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'productName',
    },
  },
  {
    title: '图号',
    dataIndex: 'drawingNum',
    key: 'drawingNum',
    searchType: 'text',
    width: 200,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'drawingNum',
    },
  },
  {
    title: '客户名称',
    dataIndex: 'client',
    key: 'client',
    searchType: 'text',
    width: 200,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'client',
    },
  },
  {
    title: '任务类型',
    dataIndex: 'type',
    width: 150,
    key: 'type',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'type',
    },
    filters: [
      {
        text: '普通任务',
        value: 0,
      },
      {
        text: '返修任务',
        value: 1,
      },
      {
        text: '生产补投任务',
        value: 2,
      },
      {
        text: '协作任务',
        value: 3,
      },
      {
        text: '普通补投任务',
        value: 4,
      },
    ],
  },
  {
    title: '在产数量',
    dataIndex: 'producingNum',
    key: 'producingNum',
    width: 150,
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'producingNum',
    },
  },
  {
    title: '产值单价',
    dataIndex: 'productionUnitPrice',
    key: 'productionPrice',
    width: 150,
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'productionUnitPrice',
    },
  },
  {
    title: '计划启动日期',
    dataIndex: 'startTime',
    width: 180,
    key: 'startTime',
    searchType: 'date',

    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'startTime',
    },
  },
  {
    title: '产品要求交货时间',
    dataIndex: 'deliverTime',
    width: 180,
    key: 'deliverTime',
    searchType: 'date',

    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'deliverTime',
    },
  },
  {
    title: '是否分件',
    dataIndex: 'isParts',
    width: 150,
    key: 'isParts',
    align: 'center',
    scopedSlots: {
      customRender: 'isParts',
    },
    filters: [
      {
        text: '是',
        value: 1,
      },
      {
        text: '否',
        value: 0,
      },
    ],
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    width: 200,
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '进行中',
        value: 2,
      },
      {
        text: '已完成',
        value: 3,
      },
      {
        text: '超期进行中',
        value: 4,
      },
      {
        text: '即将超期',
        value: 5,
      },
      {
        text: '暂停中',
        value: 9,
      },
      {
        text: '已取消',
        value: 8,
      },
      {
        text: '异常终止',
        value: 10,
      },
    ],
  },
  {
    title: '任务实时位置',
    dataIndex: 'latestOperationName',
    key: 'latestOperationName',
    width: 150,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'latestOperationName',
    },
  },
  {
    title: '未完成工序',
    key: 'process',
    width: 10000,
    scopedSlots: {
      customRender: 'process',
    },
  },
]

export const groupColumns = [
  ...commonColumns,
  {
    title: '班组名称',
    dataIndex: 'groupName',
    key: 'groupName',
    align: 'center',
    searchType: 'text',
    width: 150,
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'groupName'
    }
  },
  ...detailColumns
]


export const memberColumns = [
  ...commonColumns,
  {
    title: '员工姓名',
    dataIndex: 'realName',
    key: 'realName',
    align: 'center',
    searchType: 'text',
    width: 150,
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'realName'
    }
  },
  {
    title: '班组名称',
    dataIndex: 'groupName',
    key: 'groupName',
    align: 'center',
    searchType: 'text',
    width: 150,
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'groupName'
    }
  },
  ...detailColumns
]