<template>
  <div class="container">
    <div class="content">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">任务紧急度分布情况概览</span>
          <div class="date-container">
            <p>日期:</p>
            <a-date-picker v-model="taskTrendsDate" placeholder="选择日期" value-format="YYYY-MM-DD" :allowClear="false"
              :disabledDate="disabledDate" />
          </div>
        </div>
        <div class="target-tab">
          <div class="target-content">
            <div ref="taskTrends"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="trends-change-wrapper">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">任务紧急度变化趋势概览</span>
          <div class="date-container">
            <p>日期:</p>
            <a-range-picker v-model="taskTrendsChangeDate" value-format="YYYY-MM-DD" :allowClear="false" />
          </div>
        </div>
        <div class="trends-change-inner">
          <div class="target-content" ref="taskTrendsChange">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { Chart } from '@antv/g2';
import { getTaskTrends } from '@/api/tocDbr'
import { EParamsPrefix, EStaticParamsDimension, EStaticParamsType } from '@/enums'
import { ETaskColorTextMap, ETaskColor, ETaskColorMapArr } from '@/enums/task'
import { DefaultOnlyDateFormat, curNowDate, lastOneMonthDateArr } from '@/common/consts'
import { getDateRange } from '@/utils/date'

export default {
  name: 'IntelligentScheduling',
  data() {
    return {
      taskTrendsDate: curNowDate,
      taskTrendsData: [],
      taskTrendsChangeDate: lastOneMonthDateArr,
      taskTrendsChangeData: [],
    }
  },
  computed: {
    taskTrendsDateRangeFormat() {
      return getDateRange(this.taskTrendsDate || curNowDate)
    },
    taskTrendsDateChangeRangeFormat() {
      return (this.taskTrendsChangeDate || lastOneMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    }
  },
  mounted() {
    // this.taskTrendsChart = this.renderBarChart(this.$refs.taskTrends);
    // this.taskTrendsChangeChart = this.renderBarChangeChart(this.$refs.taskTrendsChange);

    this.getTaskTrendsData()
    this.getTaskTrendsChangeData()
  },
  watch: {
    taskTrendsDate() {
      this.getTaskTrendsData()
    },
    taskTrendsData() {
      this.renderBarChart()
    },
    taskTrendsChangeDate() {
      this.getTaskTrendsChangeData()
    },
    taskTrendsChangeData() {
      this.renderBarChangeChart()
    },
  },
  methods: {
    disabledDate(current) {
      return current && moment(current).isAfter(moment(), 'day')
    },
    renderBarChart(container) {
      if (this.taskTrendsChart) {
        this.taskTrendsChart.destroy()
      }

      this.taskTrendsChart = new Chart({
        container: this.$refs.taskTrends,
        height: 400
      });

      this.taskTrendsChart.coordinate({ type: 'theta', outerRadius: 0.8 });
      this.taskTrendsChart
        .interval()
        .data(this.taskTrendsData)
        .transform({ type: 'stackY' })
        .encode('y', 'progressStatusRate')
        .encode('color', data => ETaskColorTextMap[data.progressStatusType])
        .scale('color', {
          relations: ETaskColorMapArr,
        })
        .label({
          position: 'outside',
          text: (data) => `${ETaskColorTextMap[data.progressStatusType]}: ${data.progressStatusRate * 100}%`,
        })
        .tooltip((data) => ({
          name: ETaskColorTextMap[data.progressStatusType],
          value: `${data.progressStatusRate * 100}%`,
        }));

      // 渲染可视化
      this.taskTrendsChart.render();
    },
    renderBarChangeChart(container) {
      if (this.taskTrendsChangeChart) {
        this.taskTrendsChangeChart.destroy()
      }

      this.taskTrendsChangeChart = new Chart({
        container: this.$refs.taskTrendsChange,
        autoFit: true,
        // width: 1200,
        // height: 460,
        insetRight: 10,
      });

      this.taskTrendsChangeChart
        .line()
        .data(this.taskTrendsChangeData)
        // .transform({ type: 'normalizeY', basis: 'first', groupBy: 'color' })
        .encode('x', (d) => moment(d.time).format(DefaultOnlyDateFormat))
        .encode('y', 'rate')
        .encode('color', data => ETaskColorTextMap[data.type])
        // .scale('y', { type: 'log' })
        .axis('y', { title: '占比 (%)' })
        .label({
          text: data => ETaskColorTextMap[data.type],
          selector: 'last',
          fontSize: 10,
        })
        .tooltip({ channel: 'y', valueFormatter: '.1f' })

      // 渲染可视化
      this.taskTrendsChangeChart.render();
    },
    updateBarChart(chart, newData) {
      // 获得 Interval Mark
      const interval = chart.getNodesByType('interval')[0];

      interval.data(newData);
      // 重新渲染
      chart.render();
    },
    getTaskTrendsData() {
      getTaskTrends({
        type: `${EParamsPrefix.EQ}${EStaticParamsType.TPS}`,
        dimension: `${EParamsPrefix.EQ}${EStaticParamsDimension.DAY}`,
        generateTime: `${EParamsPrefix.BTN}${this.taskTrendsDateRangeFormat.join(',')}`
      }).then((res) => {
        this.taskTrendsData = ((res.data || [])?.[0]?.extendInfo || []).map(d => ({
          ...d,
          color: ETaskColor[d.progressStatusType]
        }))
      }).catch(e => {
        console.error(e)
      })
    },
    getTaskTrendsChangeData() {
      getTaskTrends({
        type: `${EParamsPrefix.EQ}${EStaticParamsType.TPS}`,
        dimension: `${EParamsPrefix.EQ}${EStaticParamsDimension.DAY}`,
        generateTime: `${EParamsPrefix.BTN}${this.taskTrendsDateChangeRangeFormat.join(',')}`
      }).then((res) => {
        const newData = res.data || [];
        const processData = newData.flatMap(item => item.extendInfo.map(info => ({
          time: item.generateTime,
          type: info.progressStatusType,
          rate: info.progressStatusRate * 100
        })));

        this.taskTrendsChangeData = processData;
      }).catch(e => {
        console.error(e)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  border-radius: 8px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 6px rgb(206, 206, 206);
}

/* 非激活窗口 */
::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(206, 206, 206);
}

.container {
  width: 100%;
  padding-bottom: 30px;
  background: rgb(240, 242, 245);
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 95%;
    height: 480px;
    margin-top: 20px;
    background: white;
    position: relative;

    .filter {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid rgb(212, 212, 212);
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 60px;
      }

      .date-container {
        display: flex;
        align-items: center;
        font-size: 16px;

        p {
          margin-right: 20px;
        }
      }
    }

    .target-tab {
      height: 420px;
      position: relative;

      .target-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .list {
          .item {
            margin-right: 215px;
          }
        }
      }
    }
  }

  .trends-change-wrapper {
    width: 95%;
    margin-top: 20px;
    background: white;
    position: relative;

    .filter {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid rgb(212, 212, 212);
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 60px;
      }

      .date-container {
        display: flex;
        align-items: center;
        font-size: 16px;

        p {
          margin-right: 20px;
        }
      }
    }

    .trends-change-inner {
      height: 530px;
      overflow: auto;
      position: relative;
      padding: 18px;
    }
  }


}

/deep/ .ant-table-pagination,
.ant-pagination {
  float: left;
}

.target-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .target-content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    position: relative;
  }
}

.page-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  /deep/ .anticon {
    font-size: 48px;
    color: white;
  }
}
</style>
