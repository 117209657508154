<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    :visible="true"
    :title="code + '产品项明细'"
    :mask-closable="false"
    width="75%"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <template v-if="status === 'CCK'">
        <a-button type="danger" @click="reject">驳回</a-button>
        <a-button type="primary" @click="approval">通过</a-button>
      </template>
    </template>
    <a-table
      row-key="id"
      bordered
      class="users-table"
      :columns="
      approvalDetailColumns"
      :data-source="list"
      :loading="loading"
      :pagination="pagination"
      :scroll="{ x: 1500 }"
      @change="handleTableChange"
    >
      <div slot="status" slot-scope="text">
        <span v-if="text === 'CCK'">审价中</span>
        <span v-if="text === 'AGR'">已通过</span>
        <span v-if="text === 'RJT'">已驳回</span>
      </div>
      <div slot="unitCompanyResidueRate" slot-scope="text">
        <span>{{ text ? text * 100 + '%' : '' }}</span>
      </div>
      <div slot="outsourcingCostRate" slot-scope="text">
        <span>{{ text ? text * 100 + '%' : '' }}</span>
      </div>
      <div slot="outProcessingCostRate" slot-scope="text">
        <span>{{ text ? text * 100 + '%' : '' }}</span>
      </div>
      <div slot="materialCostRate" slot-scope="text">
        <span>{{ text ? text * 100 + '%' : '' }}</span>
      </div>
      <div slot="operate" slot-scope="text, column" class="table-user">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleEditPrice(column.id, column)">调整报出价格</a-menu-item>
          </a-menu>
          <a class="user-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <ApprocalPriceModal
      :id="editId"
      :record="editRecord"
      v-if="approcalPriceModalVisible"
      @confirm="approcalPriceConfirm"
      @cancel="approcalPriceCancel"
    />
    <ApprovalResultModal
      v-if="approvalResultModalVisible"
      :code="code"
      :type="approvalType"
      @confirm="approcalConfirm"
      @cancel="approcalCancel"
    />
  </a-modal>
</template>
<script>
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { approvalDetailColumns } from './dataLogic'
import { exportAllUsers } from '@/api/user'
import { geApprovalDetail } from '@/api/approval'
import { downloadTemplate } from '@/common/templateDownload'
import ApprocalPriceModal from '@/components/ApprocalPriceModal'
import ApprovalResultModal from '@/components/ApprovalResultModal'

export default {
  props: {
    code: String,
    status: String
  },
  components: {
    ApprocalPriceModal,
    ApprovalResultModal
  },
  data() {
    return {
      loading: false,
      uploading: false,
      list: [],

      approvalDetailColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentEditType: '',
      currentUser: {},
      editUserModalVisible: false,
      userRoleModalVisible: false,
      userOperationModalVisible: false,
      columnSearchParams: {},
      uploadUrl: process.env.VUE_APP_BASE_API,
      exportLoading: false,
      editId: '',
      editRecord: '',
      approcalPriceModalVisible: false,
      approvalResultModalVisible: false,
      approvalType: ''
    }
  },
  created() {
    this.getApprovalList(this.initQueryParams)
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    getApprovalList(data) {
      this.loading = true
      geApprovalDetail(this.code, data)
        .then(res => {
          this.list = res.data.records.map((item, index) => {
            item.index =
              (this.currentQueryParams.pageNum - 1) *
                this.currentQueryParams.pageSize +
              index +
              1
            return item
          })
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getApprovalListByParams() {
      const cQueryParams = deepClone(this.currentQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getApprovalList(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('sex')) {
        delete this.columnSearchParams['sex']
        if (filters['sex'].length > 0) {
          this.columnSearchParams['sex'] = `in:${filters['sex'].toString()}`
        } else {
          delete this.columnSearchParams['sex']
        }
      }
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['user.status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['user.status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['user.status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentUserListQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentUserListQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentUserListQueryParams.pageNum = pagination.current
      this.currentUserListQueryParams.pageSize = pagination.pageSize
      this.getApprovalListByParams()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      if (dataIndex === 'roles') {
        dataIndex = 'role.name'
      }
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleDownloadTemplate() {
      downloadTemplate('user')
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`批量导入失败，请稍后再试`)
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      this.$info({
        title: '员工信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入数据总计：' + response.totalNum + '条;'),
          h('p', '非法数据条数：' + response.failedNum + ';'),
          h('p', '导入成功数据条数：' + response.successfulNum + ';'),
          h('p', '非法数据信息:'),
          h('p', response.invalidMessages.join(' ||  '))
        ]),
        onOk: () => {
          this.uploading = false
          this.getUsers(this.currentUserListQueryParams)
        }
      })
    },
    handleExportUserCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'ALL': {
          exportAllUsers()
            .then(res => {
              this.downloadInquiry(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        }
        default:
          this.exportLoading = false
          break
      }
    },
    downloadInquiry(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.USER_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleEditPrice(id, record) {
      this.editId = id
      this.editRecord = record
      this.approcalPriceModalVisible = true
    },
    approcalPriceConfirm() {
      this.approcalPriceModalVisible = false
      this.editId = ''
      this.editRecord = ''
      this.getApprovalList(this.initQueryParams)
    },
    approcalPriceCancel() {
      this.editId = ''
      this.editRecord = ''
      this.approcalPriceModalVisible = false
    },
    approval() {
      this.approvalType = 'approval'
      this.approvalResultModalVisible = true
    },
    reject() {
      this.approvalType = 'reject'
      this.approvalResultModalVisible = true
    },
    approcalConfirm() {
      this.approvalResultModalVisible = false
      this.$emit('confirm')
    },
    approcalCancel() {
      this.approvalResultModalVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
}
</style>
