export const taskColumns = [
  {
    title: '目标名称',
    width: 150,
    dataIndex: 'targetName',
    key: 'targetName',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '操作类型',
    width: 120,
    dataIndex: 'operateType',
    key: 'operateType',
    align: 'center',
    scopedSlots: {
      customRender: 'operateType',
    },
  },
  {
    title: '任务编号',
    width: 150,
    dataIndex: 'code',
    key: 'task.code',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'code',
    },
  },
  {
    title: '产品名称',
    width: 150,
    dataIndex: 'productName',
    key: 'product.productName',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品图号',
    width: 200,
    dataIndex: 'drawingNum',
    key: 'drawingNum',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '订单数量',
    width: 120,
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '无税单价',
    width: 130,
    dataIndex: 'taxFreePrice',
    key: 'taxFreePrice',
    align: 'center',
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '操作人姓名',
    width: 150,
    dataIndex: 'operatorName',
    key: 'operatorName',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '操作时间',
    width: 150,
    dataIndex: 'operateTime',
    key: 'operateTime',
    align: 'center',
    searchType: 'date',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '小组名称',
    width: 150,
    dataIndex: 'groupName',
    key: 'groupName',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '操作后数量',
    width: 150,
    dataIndex: 'productNum',
    key: 'productNum',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
]
