/*!
 * 质量管理 表头
 */

export const commonColumns = [
  {
    title: '任务号',
    dataIndex: 'taskCode',
    key: 'task.code',
    width: 150,
    ellipsis: true,
    align: 'center',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'taskCode',
    },
  },
  {
    title: '缺陷类型',
    dataIndex: 'defeatType',
    key: 'defeatType',
    width: 100,
    align: 'center',
    scopedSlots: {
      customRender: 'defeatType',
    },
  },
  {
    dataIndex: 'generateTime',
    key: 'quality.generateTime',
    align: 'center',
    width: 150,
    ellipsis: true,
    sorter: true,
    searchType: 'date',
    slots: { title: 'generateTimeTitle' },
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'generateTime',
    },
  },
  {
    title: '合同数量',
    dataIndex: 'amount',
    key: 'product.amount',
    align: 'center',
    width: 130,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户需求',
    dataIndex: 'clientDemand',
    key: 'plan.clientDemand',
    align: 'center',
    width: 130,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '缺陷数量',
    dataIndex: 'productAmount',
    key: 'productAmount',
    align: 'center',
    width: 130,
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    key: 'product.productName',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '图号',
    dataIndex: 'drawingNum',
    key: 'product.drawingNum',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品规格',
    dataIndex: 'specification',
    key: 'product.specification',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户名称',
    dataIndex: 'client',
    key: 'product_order.client',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产值单价',
    width: 150,
    dataIndex: 'taxFreePrice',
    key: 'product.taxFreePrice',
    align: 'center',
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产值金额',
    width: 170,
    dataIndex: 'totalMoneyTaxExclued',
    key: 'totalMoneyTaxExclued',
    align: 'center',
  },
  {
    title: '是否需要采取预防措施',
    dataIndex: 'hasAction',
    key: 'hasAction',
    width: 200,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      customRender: 'hasAction',
    },
    filters: [
      {
        text: '需要',
        value: 'Y',
      },
      {
        text: '不需要',
        value: 'N',
      },
    ],
  },
  {
    width: 150,
    title: '措施负责人',
    dataIndex: 'actionCharger',
    key: 'user.realName',
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '措施效果',
    dataIndex: 'actionEffect',
    key: 'actionEffect',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '备注',
    dataIndex: 'comment',
    key: 'quality.comment',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
]

// 返工统计
export const redoneColumns = [
  {
    title: '处置单号',
    dataIndex: 'defeatReceiptNumber',
    key: 'defeatReceiptNumber',
    width: 150,
    ellipsis: true,
    align: 'center',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'defeatReceiptNumber',
    },
  },
  {
    title: '检验员',
    dataIndex: 'checker',
    key: 'checker',
    width: 120,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'checker',
    },
  },
  {
    title: '不合格详情',
    dataIndex: 'defeatDesc',
    key: 'defeatDesc',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'defeatDesc',
    },
  },
  {
    title: '返工要求',
    dataIndex: 'defeatDemand',
    key: 'defeatDemand',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
    },
  },
  ...commonColumns,
  {
    title: '返工效果',
    dataIndex: 'repairEffect',
    key: 'repairEffect',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      customRender: 'redone-repairEffect',
    },
    filters: [
      {
        text: '再次返修',
        value: 'RE',
      },
      {
        text: '合格',
        value: 'OK',
      },
      {
        text: '报废',
        value: 'BF',
      },
      {
        text: '超差',
        value: 'CC',
      },
    ],
  },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    align: 'center',
    width: 80,
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

// 生产报废统计
export const worthlessColumns = [
  {
    title: '处置单号',
    dataIndex: 'defeatReceiptNumber',
    key: 'defeatReceiptNumber',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'defeatReceiptNumber',
    },
  },
  {
    title: '检验员',
    dataIndex: 'checker',
    key: 'checker',
    width: 120,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'checker',
    },
  },
  {
    title: '报废品描述',
    dataIndex: 'defeatDesc',
    key: 'defeatDesc',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'defeatDesc',
    },
  },
  {
    title: '材料规格',
    dataIndex: 'contactRecord',
    key: 'contactRecord',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'contactRecord',
    },
  },
  {
    title: '是否下达补投任务',
    dataIndex: 'defeatDemand',
    key: 'defeatDemand',
    width: 200,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      customRender: 'defeatDemand',
    },
    filters: [
      {
        text: '未下达',
        value: 'null',
      },
      {
        text: '已下达',
        value: 'notNull',
      },
    ],
  },
  ...commonColumns,
  {
    title: '实发材料',
    dataIndex: 'actualUseMaterialList',
    key: 'actualUseMaterialList',
    width: 200,
    align: 'center',
    scopedSlots: {
      customRender: 'actualUseMaterialList',
    },
  },
  {
    title: '通过瓶颈标识',
    dataIndex: 'bottleneckFlag',
    key: 'bottleneckFlag',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'bottleneckFlag',
    },
  },
  {
    title: '损失金额',
    dataIndex: '',
    width: 150,
    key: '',
    align: 'center',
  },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    align: 'center',
    width: 80,
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

// 退货统计
export const returnColumns = [
  {
    title: '退库交接单号',
    dataIndex: 'returnReceiptNumber',
    key: 'returnReceiptNumber',
    width: 150,
    ellipsis: true,
    align: 'center',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'returnReceiptNumber',
    },
  },
  {
    title: '处置单号',
    dataIndex: 'defeatReceiptNumber',
    key: 'defeatReceiptNumber',
    width: 150,
    ellipsis: true,
    align: 'center',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'defeatReceiptNumber',
    },
  },
  {
    title: '处置方式',
    dataIndex: 'repairEffect',
    key: 'repairEffect',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'repairEffect',
    },
  },
  {
    title: '客户反馈描述',
    dataIndex: 'contactRecord',
    key: 'contactRecord',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'contactRecord',
    },
  },
  {
    title: '返工效果',
    dataIndex: 'actionEffect',
    key: 'actionEffect',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      customRender: 'actionEffect',
    },
    filters: [
      {
        text: '合格',
        value: 'OK',
      },
      {
        text: '报废',
        value: 'BF',
      },
      {
        text: '超差',
        value: 'CC',
      },
    ],
  },
  {
    title: '核实情况描述',
    dataIndex: 'defeatDesc',
    key: 'defeatDesc',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'defeatDesc',
    },
  },
  ...commonColumns,
  {
    title: '是否完结',
    width: 100,
    dataIndex: 'taskStatus',
    key: 'task.status',
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      customRender: 'taskStatus',
    },
    filters: [
      {
        text: '进行中',
        value: 2,
      },
      {
        text: '已完成',
        value: 3,
      },
      {
        text: '暂停中',
        value: 9,
      },
      {
        text: '已取消',
        value: 8,
      },
      {
        text: '异常终止',
        value: 10,
      },
    ],
  },
  {
    title: '实发材料',
    dataIndex: 'actualUseMaterialList',
    key: 'actualUseMaterialList',
    width: 200,
    align: 'center',
    scopedSlots: {
      customRender: 'actualUseMaterialList',
    },
  },
  {
    title: '损失金额',
    dataIndex: '',
    width: 150,
    key: '',
    align: 'center',
  },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    align: 'center',
    width: 80,
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

// 超差处理
export const overproofColumns = [
  {
    title: '处置单号',
    dataIndex: 'defeatReceiptNumber',
    key: 'defeatReceiptNumber',
    width: 120,
    ellipsis: true,
    align: 'center',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'defeatReceiptNumber',
    },
  },
  {
    title: '检验员',
    dataIndex: 'checker',
    key: 'checker',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'checker',
    },
  },
  {
    title: '不合格详情',
    dataIndex: 'defeatDesc',
    key: 'defeatDesc',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'defeatDesc',
    },
  },
  {
    title: '顾客意见',
    dataIndex: 'contactRecord',
    key: 'contactRecord',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'contactRecord',
    },
  },
  ...commonColumns,
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    align: 'center',
    width: 80,
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

export const inventoryRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({
    props: {
      disabled:
        record.defeatType === 'TH'
          ? !!record.returnReceiptNumber
          : !record.defeatReceiptNumber,
    },
  }),
}
