<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    :visible="true"
    :title="'对账单' + proofNumber + '结算凭据录入'"

    :maskClosable="false"
    @cancel="handleCancel"
    width="850px"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm"
        >确定</a-button
      >
    </template>
    <a-form-model
      ref="ruleForm"
      :model="settle"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row v-for="(settleReceipt, index) in settle.settleReceipts" :key="index"> 
        <a-form-model-item
          label="凭据类型"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          :prop="index > 0 ? `receiptType${index}` : 'receiptType'"
          class="customOperation seqNum"
        >
          <a-select
            style="width: 100px"
            v-model="settle.settleReceipts[index].receiptType"
            placeholder="请选择凭据类型"
          >
            <a-select-option value="IVC">
              发票
            </a-select-option>
            <a-select-option value="RCP">
              收据
            </a-select-option>
          </a-select>
        </a-form-model-item>    
        <a-form-model-item
          label="凭据号"
          style="width: 45%;margin-left: -17px;"
          class="customOperation"
          :prop="index > 0 ? `receipeNumber${index}` : 'receipeNumber'"
        >
          <a-input
            style="width: 150px"
            v-model="settle.settleReceipts[index].receipeNumber"
            placeholder="请输入凭据号"
          />
          <template>
            <a-icon
              v-if="index === settle.settleReceipts.length - 1"
              @click="handleAddSettleReceipts"
              type="plus-circle"
              style="fontSize: 16px; marginLeft: 20px; cursor: pointer;marginTop: 4px;"
            />
            <a-icon
              v-if="index > 0"
              @click="handleDeleteSettleReceipts(index)"
              type="minus-circle"
              style="fontSize: 16px; marginLeft: 20px; cursor: pointer;marginTop: 4px;"
            />
          </template>
        </a-form-model-item>
      </a-row>
      <a-form-model-item label="挂账期" prop="pendingPeriod">
        <a-input
          style="width: 300px"
          v-model.trim="settle.pendingPeriod"
          placeholder="请输入挂账期"
        />
      </a-form-model-item>
      <a-form-model-item label="结算类型" prop="settleType">
        <a-radio-group v-model="settle.settleType" style="width: 300px">
          <a-radio :value="0">挂账</a-radio>
          <a-radio :value="1">现款</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="comment">
        <a-textarea
          style="width: 300px"
          v-model.trim="settle.comment"
          :max-length="1024"
          placeholder="请输入备注"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { saveSettlementReceipt } from '@/api/settlements'
export default {
  props: {
    id: Number,
    proofNumber: String
  },
  data() {
    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      loading: false,
      settle: {
        settleReceipts: [{
          receipeNumber: '',
          receiptType: 'IVC'
        }],
        pendingPeriod: '',
        comment: '',
        settleType: '',
      },
      rules: {
        receipeNumber: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.settle.settleReceipts[0].receipeNumber) {
                return callback(new Error('请输入凭据号'))
              }
              callback()
            }
          }
        ],
        receiptType: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (
                !this.settle.settleReceipts[0].receiptType
              ) {
                return callback(new Error('请选择凭据类型'))
              }
              callback()
            }
          }
        ],
        settleType: [
          {
            required: true,
            trigger: 'change',
            message: '请选择结算类型',
          },
        ],
      },
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          saveSettlementReceipt(this.id, {
            ...this.settle,
          }).then(
            (res) => {
              this.loading = false
              this.$message.success('操作成功')
              this.$emit('confirm')
            },
            (error) => {
              this.loading = false
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleAddSettleReceipts() {
      this.settle.settleReceipts.push({
        receipeNumber: '',
        receiptType: 'IVC'
      })
      this.rules[`receipeNumber${this.settle.settleReceipts.length - 1}`] = [
        {
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            // console.log('opera', value)
            if (
              !this.settle.settleReceipts[this.settle.settleReceipts.length - 1]
                .receipeNumber
            ) {
              return callback(new Error('请输入凭据号'))
            }
            callback()
          }
        }
      ]
      this.rules[`receiptType${this.settle.settleReceipts.length - 1}`] = [
        {
          required: true,
          trigger: 'change',
          validator: (rule, value, callback) => {
            if (
              !this.settle.settleReceipts[this.settle.settleReceipts.length - 1]
                .receiptType
            ) {
              return callback(new Error('请选择凭据类型'))
            }
            callback()
          }
        }
      ]
    },
    handleDeleteSettleReceipts(index) {
      this.settle.settleReceipts.splice(index, 1)
      delete this.rules[`receipeNumber${index}`]
      delete this.rules[`receiptType${index}`]
    }
  },
}
</script>
<style scoped>
/deep/ .ant-form-item {
  margin-bottom: 2px;
}
.modal-container {
  height: 400px;
}
.customOperation >>> .ant-form-item-children {
  display: flex;
  align-items: center;
}
.customOperation >>> .ant-form-item-control-wrapper {
  display: flex;
  align-items: center;
}
.seqNum >>> .ant-form-item-control {
  margin-left: 21px;
}
</style>
<style lang="less" scoped>
.customOperation {
  width: 30%;
  display: inline-flex;
  .ant-form-item-control {
    display: flex;
  }
}
.taskInfo {
  padding-left: 185px;
  display: flex;
  .item {
    display: flex;
  }
}
</style>
