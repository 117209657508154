<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    :visible="true"
    title="操作日志"
    :mask-closable="false"
    width="1200px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button
        type="primary"
        @click="handleConfirm"
      >确定</a-button>
    </template>
    <div class="table-container">
      <a-table
        :key="tableKey"
        row-key="taskId"
        bordered
        :scroll="{ x: 800, y: 500 }"
        :columns="recordColumns"
        :data-source="list"
        :loading="loading"
        :pagination="false"
        @change="handleTableChange"
      >
        <div slot="changeType" slot-scope="text">
          <a-tag v-if="text==='IPC'" color="green">入库撤销</a-tag>
          <a-tag v-if="text==='OPC'" color="green">出库撤销</a-tag>
          <a-tag v-if="text==='ABC'" color="green">报废撤销</a-tag>
          <a-tag v-if="text==='TKC'" color="green">入库任务撤销</a-tag>
          <a-tag v-if="text==='SMG'" color="green">对账单开票</a-tag>
          <a-tag v-if="text==='SMS'" color="green">对账单结算</a-tag>
          <a-tag v-if="text==='SMC'" color="green">对账单撤销</a-tag>
        </div>
        <div slot="oldData" slot-scope="text">
          <p>单号: {{text.recepitNumber}}</p>
          <p>数量: {{text.count}}</p>
          <p>任务号: {{text.taskCode}}</p>
        </div>
      </a-table>
    </div>
  </a-modal>
</template>
<script>
import { recordColumns } from './recordDataLogic'
import { getLogList } from '@/api/log'
import { getSettlementsLog } from "@/api/settlements"
export default {
  props: {
    id: Number, // 记录Id
    type: String // 记录类型
  },
  data() {
    return {
      tableKey: 0,
      recordColumns,
      list: [],
      selectedRowKeys: [],
      targetKeys: [],
      columnSearchParams: {},
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      currentTaskParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      totalPages: '',
      loading: false,
      submitLoading: false,
      totalUserPages: '',
      fetchUserLoading: false,
      editableId: ''
    }
  },
  mounted() {
    this.getLogList()
  },
  methods: {
    getLogList() {
      this.loading = true
      if(this.type === 'settlement') {
        getSettlementsLog(this.id)
          .then(res => {
            this.list = res.data
          })
          .finally(() => {
            this.loading = false
          })
      } else {        
        getLogList(this.id)
          .then(res => {
            this.list = res.data
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$emit('cancel')
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      console.log(selectedKeys, dataIndex)
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          if (dataIndex === 'code') {
            this.columnSearchParams['task.code'] =
              'like:' + selectedKeys[0].trim()
          } else if (dataIndex === 'plan.deliverTime') {
            this.columnSearchParams['plan.deliverTime'] =
              'lt:' + selectedKeys[0].trim()
          } else {
            this.columnSearchParams[dataIndex] =
              'like:' + selectedKeys[0].trim()
          }
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          if (dataIndex === 'deliverTime') {
            this.columnSearchParams['plan.deliverTime'] =
              'btn:' + selectedKeys.toString()
          } else if (dataIndex === 'producingNum') {
            this.columnSearchParams['task.producingNum'] =
              'btn:' + selectedKeys.toString()
          } else {
            this.columnSearchParams[dataIndex] =
              'btn:' + selectedKeys.toString()
          }
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      if (dataIndex === 'code') {
        this.columnSearchParams['task.code'] = null
      } else if (dataIndex === 'deliverTime') {
        this.columnSearchParams['plan.deliverTime'] = null
      } else if (dataIndex === 'producingNum') {
        this.columnSearchParams['task.producingNum'] = null
      } else {
        this.columnSearchParams[dataIndex] = null
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('task.emgType')) {
        delete this.columnSearchParams['task.emgType']
        if (filters['task.emgType'].length > 0) {
          this.columnSearchParams['task.emgType'] = `in:${filters[
            'task.emgType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.emgType']
        }
      }
      if (filtersKeys.includes('product.type')) {
        delete this.columnSearchParams['product.type']
        if (filters['product.type'].length > 0) {
          this.columnSearchParams['product.type'] = `in:${filters[
            'product.type'
          ].toString()}`
        } else {
          delete this.columnSearchParams['product.type']
        }
      }
      if (filtersKeys.includes('plan.isParts')) {
        delete this.columnSearchParams['plan.isParts']
        if (filters['plan.isParts'].length > 0) {
          this.columnSearchParams['plan.isParts'] = `in:${filters[
            'plan.isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['plan.isParts']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        if (sorter.columnKey === 'code') {
          sorter.columnKey = 'task.code'
        }
        this.currentTaskParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentTaskParams.sort_by = null
      }
      this.pagination = pagination
      this.currentTaskParams.pageNum = pagination.current
      this.currentTaskParams.pageSize = pagination.pageSize
      this.getTasksByParams()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-modal-body {
  height: 600px;
  overflow-y: scroll;
}
/deep/ .ant-checkbox-inner {
  border-color: black;
}
/deep/ .ant-modal {
  top: 50px;
  left: 100px;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}
.modal-container {
  overflow-y: scroll;
  .table-container {
    width: 100%;
    height: 620px;
    .table-process {
      float: left;
      width: auto;
    }

    .process-desc p {
      margin: 0;
      width: 250px;
    }

    .description-font {
      color: rgb(31, 29, 29);
    }
  }
}
/deep/ .ant-steps-item-content {
  width: 250px;
}
.filename-wrapper {
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .file-link {
    color: #409eff;
    text-decoration: underline;
  }
}
</style>
