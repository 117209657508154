var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        "body-style": _vm.bodyStyle,
        visible: true,
        title: _vm.title,
        "mask-closable": false,
        width: "1200px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c("a-button", { on: { click: _vm.handleIgnore } }, [_vm._v("忽略")]),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.loading,
                disabled: !_vm.selectedRowKeys.length,
              },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("强制结束")]
          ),
        ],
        1
      ),
      _c("a-table", {
        staticClass: "tasks-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          scroll: { x: 2700, y: 700 },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
          },
          columns: _vm.columns,
          "data-source": _vm.taskList,
          pagination: false,
          loading: _vm.tableLoading,
        },
        scopedSlots: _vm._u([
          {
            key: "type",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === 0
                    ? _c("a-tag", { attrs: { color: "volcano" } }, [
                        _vm._v("普通任务"),
                      ])
                    : _vm._e(),
                  text === 1
                    ? _c("a-tag", { attrs: { color: "geekblue" } }, [
                        _vm._v("返修任务"),
                      ])
                    : _vm._e(),
                  text === 2
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("生产补投任务"),
                      ])
                    : _vm._e(),
                  text === 3
                    ? _c("a-tag", { attrs: { color: "grey" } }, [
                        _vm._v("协作任务"),
                      ])
                    : _vm._e(),
                  text === 4
                    ? _c("a-tag", { attrs: { color: "red" } }, [
                        _vm._v("普通补投任务"),
                      ])
                    : _vm._e(),
                  text === 5
                    ? _c("a-tag", { attrs: { color: "orange" } }, [
                        _vm._v("库存转入任务"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "emgType",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  _c("a-tag", { attrs: { color: "volcano" } }, [
                    _vm._v(_vm._s(_vm.EmergencyType[text])),
                  ]),
                ],
                1
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("准备中 "),
                      ]
                    : _vm._e(),
                  text === 1
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("未开始 "),
                      ]
                    : _vm._e(),
                  text === 2
                    ? [
                        _c("a-badge", { attrs: { status: "processing" } }),
                        _vm._v("进行中 "),
                      ]
                    : _vm._e(),
                  text === 3
                    ? [
                        _c("a-badge", { attrs: { status: "success" } }),
                        _vm._v("已完成 "),
                      ]
                    : _vm._e(),
                  text === 9
                    ? [
                        _c("a-badge", { attrs: { status: "warning" } }),
                        _vm._v("暂停中 "),
                      ]
                    : _vm._e(),
                  text === 8
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("已取消 "),
                      ]
                    : _vm._e(),
                  text === 10
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("异常终止 "),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }