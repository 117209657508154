<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    :visible="true"
    title="调入"
    :mask-closable="false"
    width="1200px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button
        type="primary"
        @click="handlePutIn"
        :loading="putInLoading"
        :disabled="!selectedRowKeys.length"
        >调入</a-button
      >
      <a-button @click="handleCancel">取消</a-button>
    </template>
    <div class="table-container">
      <a-table
        :key="tableKey"
        row-key="id"
        bordered
        :scroll="{ x: 800, y: 500 }"
        :columns="columns"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        @change="handleTableChange"
      >
        <span slot="isParts" slot-scope="text">
          <a-tag v-if="text === 0" color="green">否</a-tag>
          <a-tag v-if="text === 1" color="red">是</a-tag>
        </span>
        <span slot="warehouseType" slot-scope="text">
          <a-tag v-if="text === 1" color="green">半成品</a-tag>
          <a-tag v-if="text === 0" color="red">成品</a-tag>
          <a-tag v-if="text === 2" color="red">工装</a-tag>
          <a-tag v-if="text === 3" color="red">材料</a-tag>
        </span>
      </a-table>
    </div>
  </a-modal>
</template>
<script>
import { deepClone } from '@/utils'

import { columns } from './putInDataLogic'
import { getNoReceiptsSettlements, inSettlement } from '@/api/settlements'
export default {
  props: {
    id: Number,
  },
  data() {
    return {
      tableKey: 0,
      columns,
      list: [],
      selectedRowKeys: [],
      targetKeys: [],
      columnSearchParams: {},
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      currentTaskParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
      },
      totalPages: '',
      loading: false,
      submitLoading: false,
      totalUserPages: '',
      fetchUserLoading: false,
      editableId: '',
      putInLoading: false,
    }
  },
  mounted() {
    this.getList(this.currentTaskParams)
  },
  methods: {
    getList(queryParams) {
      this.loading = true
      getNoReceiptsSettlements(queryParams)
        .then((res) => {
          this.list = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    getListByParams() {
      const cQueryParams = deepClone(this.currentTaskParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getList(queryParams)
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$emit('confirm')
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      console.log(selectedKeys, dataIndex)
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          if (dataIndex === 'code') {
            this.columnSearchParams['task.code'] =
              'like:' + selectedKeys[0].trim()
          } else if (dataIndex === 'plan.deliverTime') {
            this.columnSearchParams['plan.deliverTime'] =
              'lt:' + selectedKeys[0].trim()
          } else {
            this.columnSearchParams[dataIndex] =
              'like:' + selectedKeys[0].trim()
          }
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          if (dataIndex === 'deliverTime') {
            this.columnSearchParams['plan.deliverTime'] =
              'btn:' + selectedKeys.toString()
          } else if (dataIndex === 'producingNum') {
            this.columnSearchParams['task.producingNum'] =
              'btn:' + selectedKeys.toString()
          } else {
            this.columnSearchParams[dataIndex] =
              'btn:' + selectedKeys.toString()
          }
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      if (dataIndex === 'code') {
        this.columnSearchParams['task.code'] = null
      } else if (dataIndex === 'deliverTime') {
        this.columnSearchParams['plan.deliverTime'] = null
      } else if (dataIndex === 'producingNum') {
        this.columnSearchParams['task.producingNum'] = null
      } else {
        this.columnSearchParams[dataIndex] = null
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('task.emgType')) {
        delete this.columnSearchParams['task.emgType']
        if (filters['task.emgType'].length > 0) {
          this.columnSearchParams['task.emgType'] = `in:${filters[
            'task.emgType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.emgType']
        }
      }
      if (filtersKeys.includes('product.type')) {
        delete this.columnSearchParams['product.type']
        if (filters['product.type'].length > 0) {
          this.columnSearchParams['product.type'] = `in:${filters[
            'product.type'
          ].toString()}`
        } else {
          delete this.columnSearchParams['product.type']
        }
      }
      if (filtersKeys.includes('plan.isParts')) {
        delete this.columnSearchParams['plan.isParts']
        if (filters['plan.isParts'].length > 0) {
          this.columnSearchParams['plan.isParts'] = `in:${filters[
            'plan.isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['plan.isParts']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        if (sorter.columnKey === 'code') {
          sorter.columnKey = 'task.code'
        }
        this.currentTaskParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentTaskParams.sort_by = null
      }
      this.pagination = pagination
      this.currentTaskParams.pageNum = pagination.current
      this.currentTaskParams.pageSize = pagination.pageSize
      this.getListByParams()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handlePutIn() {
      this.putInLoading = true
      inSettlement(this.id, {
        waitSettleIds: this.selectedRowKeys,
      }).then((res) => {
        this.$message.success("调入成功")
        this.selectedRowKeys = []
        this.getListByParams()
      }).finally(() => {
        this.putInLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/* /deep/ .ant-modal-body {
  height: 700px;
  overflow-y: hidden;
} */
/deep/ .ant-checkbox-inner {
  border-color: black;
}
/deep/ .ant-modal {
  top: 50px;
  left: 100px;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}
.modal-container {
  overflow-y: scroll;
  .table-container {
    width: 100%;
    height: 620px;
    .table-process {
      float: left;
      width: auto;
    }

    .process-desc p {
      margin: 0;
      width: 250px;
    }

    .description-font {
      color: rgb(31, 29, 29);
    }
  }
}
/deep/ .ant-steps-item-content {
  width: 250px;
}
.filename-wrapper {
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .file-link {
    color: #409eff;
    text-decoration: underline;
  }
}
/deep/ .ant-table-pagination,
.ant-pagination {
  float: left;
}
</style>
