export const recordColumns = [
  {
    title: '变更人工号',
    dataIndex: 'changerCode',
    key: 'changerCode',
    width: 50,
    align: 'center',
    ellipsis: true,
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '变更人名称',
    dataIndex: 'changerName',
    key: 'changerName',
    width: 50,
    align: 'center',
    ellipsis: true,
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '变更类型',
    dataIndex: 'changeType',
    key: 'changeType',
    width: 50,
    align: 'center',
    ellipsis: true,
    scopedSlots: {
      customRender: 'changeType',
    },
  },
  {
    title: '变更时间',
    dataIndex: 'changeTime',
    key: 'changeTime',
    align: 'center',
    ellipsis: true,
    width: 50,
    searchType: 'date',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '变更原因',
    dataIndex: 'changeReason',
    key: 'changeReason',
    align: 'center',
    width: 50,
    ellipsis: true,
    searchType: 'date',
    scopedSlots: {
      customRender: 'customRender',
    },
  }
]
