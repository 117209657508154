<template>
  <a-modal
    v-drag-modal
    :bodyStyle="bodyStyle"
    :visible="true"
    width="1300px"
    :title="code + ' 产品' + record.inquiryItem.productInfo.name + '  ' + '图号' + record.inquiryItem.productInfo.drawingNum + ' ' + '规格' + record.inquiryItem.productInfo.specification + '  ' + '成本核算'"
    :mask-closable="false"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button v-if="currentStep !== 4" type="primary" :loading="stagingLoading" @click="handleNext">暂存并下一步</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">提交核算</a-button>
    </template>
    <a-steps v-model="currentStep" type="navigation">
      <a-step status="process" title="原辅材料明细" />
      <a-step status="process" title="外购明细" />
      <a-step status="process" title="外协明细" />
      <a-step status="process" title="生产加工明细" />
      <a-step status="process" title="其他费用" />
    </a-steps>
    <a-form-model ref="ruleForm" class="cost-form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <template v-if="currentStep === 0">
        <a-button type="primary" style="margin-bottom: 16px;" @click="handldeAddRawMaterial">添加原辅材料</a-button>
        <a-table
          row-key="sequence"
          bordered
          :columns="rawMaterialColumns"
          :data-source="form.manufacturingCost.materialCost.materialCostItemList"
        >
          <div slot="pricingModel" slot-scope="text">
            <span v-if="text === 'CBE'">方料</span>
            <span v-if="text === 'CLM'">棒料</span>
          </div>

          <div slot="materialSpec" slot-scope="text, column">
            <template
              v-if="column.pricingModel=== 'CBE'"
            >{{ column.consumeSpecification['length'] }}*{{ column.consumeSpecification.width }}*{{ column.consumeSpecification.height }}</template>
            <template v-else>{{ column.consumeSpecification.diameter }}*{{ column.consumeSpecification.height }}</template>
          </div>
          <div slot="operate" slot-scope="text, column" class="table-user">
            <a-dropdown>
              <a-menu slot="overlay" class="ant-dropdown-link">
                <a-menu-item @click="handleUpdateRawMaterial(column.sequence, column)">更新</a-menu-item>
                <a-menu-item @click="handleDeleteRawMaterial(column.sequence)">删除</a-menu-item>
              </a-menu>
              <a class="user-btn">
                操作&nbsp;
                <a-icon type="down" />
              </a>
            </a-dropdown>
          </div>
        </a-table>
      </template>
      <template v-if="currentStep === 1">
        <a-button type="primary" style="margin-bottom: 16px;" @click="handldeAddOutSourcing">添加外购明细</a-button>
        <a-table
          row-key="sequence"
          bordered
          :columns="outSourcingColumns"
          :data-source="form.manufacturingCost.purchaseCost.outsourcingCostList"
        >
          <div slot="operate" slot-scope="text, column" class="table-user">
            <a-dropdown>
              <a-menu slot="overlay" class="ant-dropdown-link">
                <a-menu-item @click="handleUpdateOutSourcing(column.sequence, column)">更新</a-menu-item>
                <a-menu-item @click="handleDeleteOutSourcing(column.sequence)">删除</a-menu-item>
              </a-menu>
              <a class="user-btn">
                操作&nbsp;
                <a-icon type="down" />
              </a>
            </a-dropdown>
          </div>
        </a-table>
      </template>
      <template v-if="currentStep === 2">
        <a-button type="primary" style="margin-bottom: 16px;" @click="handldeAddOutProcessing">添加外协明细</a-button>
        <a-table
          row-key="sequence"
          bordered
          :columns="outProcessingColumns"
          :data-source="form.manufacturingCost.purchaseCost.outProcessingCostList"
        >
          <div slot="operationInfoType" slot-scope="text">
            <span v-if="text == 1">本部工序</span>
            <span v-if="text == 2">委外工序</span>
            <span v-if="text == 3">检验工序</span>
          </div>
          <div slot="operate" slot-scope="text, column" class="table-user">
            <a-dropdown>
              <a-menu slot="overlay" class="ant-dropdown-link">
                <a-menu-item @click="handleUpdateOutProcessing(column.sequence, column)">更新</a-menu-item>
                <a-menu-item @click="handleDeleteOutProcessing(column.sequence)">删除</a-menu-item>
              </a-menu>
              <a class="user-btn">
                操作&nbsp;
                <a-icon type="down" />
              </a>
            </a-dropdown>
          </div>
        </a-table>
      </template>
      <template v-if="currentStep === 3">
        <a-button type="primary" style="margin-bottom: 16px;" @click="handldeAddDirectPay">添加生产加工明细</a-button>
        <a-table
          row-key="sequence"
          bordered
          :columns="directPayColumns"
          :data-source="form.manufacturingCost.processingCost.directPayCostList"
        >
          <div slot="operationInfoType" slot-scope="text">
            <span v-if="text == 1">本部工序</span>
            <span v-if="text == 2">委外工序</span>
            <span v-if="text == 3">检验工序</span>
          </div>
          <div slot="operate" slot-scope="text, column" class="table-user">
            <a-dropdown>
              <a-menu slot="overlay" class="ant-dropdown-link">
                <a-menu-item @click="handleUpdateDirectPay(column.sequence, column)">更新</a-menu-item>
                <a-menu-item @click="handleDeleteDirectPay(column.sequence)">删除</a-menu-item>
              </a-menu>
              <a class="user-btn">
                操作&nbsp;
                <a-icon type="down" />
              </a>
            </a-dropdown>
          </div>
        </a-table>
      </template>

      <template v-if="currentStep === 4">
        <a-form-model-item
          label="生产制造费率"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          prop="manufacturingCost.processingCost.manufacturingCostRate"
        >
          <a-input
            style="width: 300px"
            :maxLength="20"
            v-model.trim="form.manufacturingCost.processingCost.manufacturingCostRate"
            placeholder="请输入生产制造费率"
          />
        </a-form-model-item>

        <a-form-model-item
          label="工模具费用"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          prop="manufacturingCost.processingCost.toolingCost"
        >
          <a-input
            style="width: 300px"
            :maxLength="20"
            v-model.trim="form.manufacturingCost.processingCost.toolingCost"
            placeholder="请输入工模具费用"
          />
        </a-form-model-item>

        <a-form-model-item
          label="余料价值"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          prop="manufacturingCost.processingCost.surplusValue"
        >
          <a-input
            style="width: 300px"
            :maxLength="20"
            v-model.trim="form.manufacturingCost.processingCost.surplusValue"
            placeholder="请输入余料价值"
          />
        </a-form-model-item>

        <a-form-model-item label="管理费率" :labelCol="{span: 8}" style="margin-left: 89px;" prop="periodCost.manageCostRate">
          <a-input style="width: 300px" :maxLength="20" v-model.trim="form.periodCost.manageCostRate" placeholder="请输入管理费率" />
        </a-form-model-item>
        <a-form-model-item label="财务费率" :labelCol="{span: 8}" style="margin-left: 89px;" prop="periodCost.financialCostRate">
          <a-input style="width: 300px" :maxLength="20" v-model.trim="form.periodCost.financialCostRate" placeholder="请输入财务费率" />
        </a-form-model-item>
        <a-form-model-item label="销售费率" :labelCol="{span: 8}" style="margin-left: 89px;" prop="periodCost.sellingCostRate">
          <a-input style="width: 300px" :maxLength="20" v-model.trim="form.periodCost.sellingCostRate" placeholder="请输入销售费率" />
        </a-form-model-item>
        <a-form-model-item label="设计费率" :labelCol="{span: 8}" style="margin-left: 89px;" prop="periodCost.designCostRate">
          <a-input style="width: 300px" :maxLength="20" v-model.trim="form.periodCost.designCostRate" placeholder="请输入设计费率" />
        </a-form-model-item>
        <a-form-model-item label="利润率" :labelCol="{span: 8}" style="margin-left: 89px;" prop="profitRate">
          <a-input style="width: 300px" :maxLength="20" v-model.trim="form.profitRate" placeholder="请输入利润率" />
        </a-form-model-item>
        <a-form-model-item label="销售税率" :labelCol="{span: 8}" style="margin-left: 89px;" prop="taxRate">
          <a-input style="width: 300px" :maxLength="20" v-model.trim="form.taxRate" placeholder="请输入销售税率" />
        </a-form-model-item>
        <a-form-model-item label="预计生产周期" :labelCol="{span: 8}" style="margin-left: 89px;" prop="leadTime">
          <a-input style="width: 300px" :maxLength="20" v-model.trim="form.leadTime" placeholder="请输入预计生产周期（工作日）" />
        </a-form-model-item>
        <a-form-model-item label="产品净重" :labelCol="{span: 8}" style="margin-left: 89px;" prop="netWeight">
          <a-input style="width: 300px" :maxLength="20" v-model.trim="form.netWeight" placeholder="请输入产品净重" />
        </a-form-model-item>
        <a-form-model-item label="说明" :labelCol="{span: 8}" style="margin-left: 89px;" prop="remark">
          <a-input style="width: 300px" :maxLength="20" v-model.trim="form.remark" placeholder="请输入说明" />
        </a-form-model-item>
      </template>
    </a-form-model>
    <RawMaterialModal
      :index="currentEditRawMateralIndex"
      :record="currentEditRawMateral"
      v-if="rawMaterialModalVisible"
      @add="handleRawMaterialAddConfirm"
      @update="handleRawMaterialUpdateConfirm"
      @cancel="handleRawMaterialCancel"
    />
    <OutSourcingModal
      :record="currentEditOutSourcing"
      :originRecord="record"
      v-if="outSourcingModalVisible"
      @add="handleOutSourcingAddConfirm"
      @update="handleOutSourcingUpdateConfirm"
      @cancel="handleOutSourcingCancel"
    />
    <OutProcessingModal
      v-if="outProcessingModalVisible"
      :record="currentEditOutProcessing"
      @add="handleOutProcessingAddConfirm"
      @update="handleOutProcessingUpdateConfirm"
      @cancel="handleOutProcessingCancel"
    />
    <DirectPayCostModal
      v-if="directPayModalVisible"
      :record="currentEditDirectPay"
      @add="handleDirectPayAddConfirm"
      @update="handleDirectPayUpdateConfirm"
      @cancel="handleDirectPayCancel"
    />
  </a-modal>
</template>
<script>
import {
  stagingCostAccountingDetail,
  getStagingCostAccountingDetail,
  saveCostAccountingDetail,
  updateCostAccountingDetail
} from '@/api/costs'
import { deepClone } from '@/utils'
import RawMaterialModal from './RawMaterial/index.vue'
import { rawMaterialColumns } from './RawMaterial/dataLogic'
import { outSourcingColumns } from './OutSourcing/dataLogic'
import OutSourcingModal from './OutSourcing/index.vue'
import { outProcessingColumns } from './OutProcessing/dataLogic'
import OutProcessingModal from './OutProcessing/index.vue'

import { directPayColumns } from './DirectPayCost/dataLogic'
import DirectPayCostModal from './DirectPayCost/index.vue'

export default {
  props: {
    code: String,
    type: String,
    record: Object
  },
  components: {
    RawMaterialModal,
    OutSourcingModal,
    OutProcessingModal,
    DirectPayCostModal
  },
  data() {
    return {
      currentStep: 0,
      bodyStyle: {
        height: '700px',
        overflowY: 'scroll'
      },
      labelCol: {
        span: 5,
        offset: 2
      },
      wrapperCol: {
        span: 10,
        offset: 1
      },
      loading: false,
      rawMaterialColumns,
      outSourcingColumns,
      outProcessingColumns,
      directPayColumns,
      form: {
        manufacturingCost: {
          materialCost: {
            materialCostItemList: []
          },
          purchaseCost: {
            outsourcingCostList: [],
            outProcessingCostList: []
          },
          processingCost: {
            directPayCostList: [],
            manufacturingCostRate: 0.1,
            toolingCost: 0,
            surplusValue: 0
          }
        },
        periodCost: {
          manageCostRate: 0.03,
          financialCostRate: 0.03,
          sellingCostRate: 0.03,
          designCostRate: 0.01
        },
        profitRate: 0.08,
        taxRate: 0.13,
        leadTime: '',
        netWeight: '',
        remark: ''
      },
      rules: {
        'manufacturingCost.processingCost.manufacturingCostRate': [
          {
            required: true,
            message: '请输入生产制造费率',
            trigger: 'blur'
          }
        ],
        'manufacturingCost.processingCost.toolingCost': [
          {
            required: true,
            message: '请输入工模具费用',
            trigger: 'blur'
          }
        ],
        'manufacturingCost.processingCost.surplusValue': [
          {
            required: true,
            message: '请输入余料价值',
            trigger: 'blur'
          }
        ],
        'periodCost.manageCostRate': [
          {
            required: true,
            message: '请输入管理费率',
            trigger: 'blur'
          }
        ],
        'periodCost.financialCostRate': [
          {
            required: true,
            message: '请输入财务税率',
            trigger: 'blur'
          }
        ],
        'periodCost.sellingCostRate': [
          {
            required: true,
            message: '请输入销售税率',
            trigger: 'blur'
          }
        ],
        'periodCost.designCostRate': [
          {
            required: true,
            message: '请输入设计费率',
            trigger: 'blur'
          }
        ],
        profitRate: [
          {
            required: true,
            message: '请输入利润率',
            trigger: 'blur'
          }
        ],
        taxRate: [
          {
            required: true,
            message: '请输入销售税率',
            trigger: 'blur'
          }
        ]
      },
      currentEditRawMateralIndex: null,
      currentEditRawMateral: null,
      rawMaterialModalVisible: false,
      stagingLoading: false,
      outSourcingModalVisible: false,
      currentEditOutSourcing: null,
      currentEditOutSourcingIndex: null,
      outProcessingModalVisible: false,
      currentEditOutProcessing: null,
      currentEditOutProcessingIndex: null,
      directPayModalVisible: false,
      currentEditDirectPay: null,
      currentEditDirectPayIndex: null
    }
  },
  mounted() {
    this.handleGetStagingInfo()
  },
  methods: {
    handleNext() {
      if (this.currentStep < 5) {
        this.currentStep += 1
      }
      this.handleStaging()
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },

    // 原辅材料
    handldeAddRawMaterial() {
      this.rawMaterialModalVisible = true
    },
    handleUpdateRawMaterial(index, record) {
      this.currentEditRawMateralIndex = index
      this.currentEditRawMateral = deepClone(record)
      this.rawMaterialModalVisible = true
    },
    handleRawMaterialAddConfirm(data) {
      const len = this.form.manufacturingCost.materialCost.materialCostItemList
        .length
      this.rawMaterialModalVisible = false
      this.form.manufacturingCost.materialCost.materialCostItemList.push({
        ...data,
        sequence: len
          ? this.form.manufacturingCost.materialCost.materialCostItemList[
              len - 1
            ].sequence + 1
          : 1
      })
    },
    handleRawMaterialUpdateConfirm(data) {
      this.rawMaterialModalVisible = false

      const _index = this.form.manufacturingCost.materialCost.materialCostItemList.findIndex(
        item => item.sequence === this.currentEditRawMateralIndex
      )
      this.form.manufacturingCost.materialCost.materialCostItemList.splice(
        _index,
        1,
        data
      )

      this.currentEditRawMateral = null
      this.currentEditRawMateralIndex = null
    },
    handleDeleteRawMaterial(sequence) {
      const materialCostItemList = [
        ...this.form.manufacturingCost.materialCost.materialCostItemList
      ]
      this.form.manufacturingCost.materialCost.materialCostItemList = materialCostItemList.filter(
        item => item.sequence !== sequence
      )
    },
    handleRawMaterialCancel() {
      this.currentEditRawMateral = null
      this.currentEditRawMateralIndex = null
      this.rawMaterialModalVisible = false
    },

    // 外购明细
    handldeAddOutSourcing() {
      this.outSourcingModalVisible = true
    },
    handleUpdateOutSourcing(index, record) {
      this.currentEditOutSourcingIndex = index
      this.currentEditOutSourcing = deepClone(record)
      this.outSourcingModalVisible = true
    },
    handleOutSourcingAddConfirm(data) {
      const len = this.form.manufacturingCost.purchaseCost.outsourcingCostList
        .length
      this.outSourcingModalVisible = false
      this.form.manufacturingCost.purchaseCost.outsourcingCostList.push({
        ...data,
        sequence: len
          ? this.form.manufacturingCost.purchaseCost.outsourcingCostList[
              len - 1
            ].sequence + 1
          : 1
      })
      this.outSourcingModalVisible = null
      this.currentEditOutSourcingIndex = null
    },
    handleOutSourcingUpdateConfirm(data) {
      this.outSourcingModalVisible = false

      const _index = this.form.manufacturingCost.purchaseCost.outsourcingCostList.findIndex(
        item => item.sequence === this.currentEditOutSourcingIndex
      )

      this.form.manufacturingCost.purchaseCost.outsourcingCostList.splice(
        _index,
        1,
        data
      )

      this.outSourcingModalVisible = null
      this.currentEditOutSourcingIndex = null
    },
    handleDeleteOutSourcing(sequence) {
      const outsourcingCostList = [
        ...this.form.manufacturingCost.purchaseCost.outsourcingCostList
      ]
      this.form.manufacturingCost.purchaseCost.outsourcingCostList = outsourcingCostList.filter(
        item => item.sequence !== sequence
      )
    },
    handleOutSourcingCancel() {
      this.currentEditOutSourcing = null
      this.currentEditOutSourcingIndex = null
      this.outSourcingModalVisible = false
    },

    // 外协明细
    handldeAddOutProcessing() {
      this.outProcessingModalVisible = true
    },
    handleUpdateOutProcessing(index, record) {
      this.currentEditOutProcessingIndex = index
      this.currentEditOutProcessing = deepClone(record)
      this.outProcessingModalVisible = true
    },
    handleOutProcessingAddConfirm(data) {
      const len = this.form.manufacturingCost.purchaseCost.outProcessingCostList
        .length
      this.outProcessingModalVisible = false

      console.log(data)

      this.form.manufacturingCost.purchaseCost.outProcessingCostList.push({
        ...data,
        sequence: len
          ? this.form.manufacturingCost.purchaseCost.outProcessingCostList[
              len - 1
            ].sequence + 1
          : 1
      })
      this.currentEditOutProcessing = null
      this.currentEditOutProcessingIndex = null
    },
    handleOutProcessingUpdateConfirm(data) {
      this.outProcessingModalVisible = false

      const _index = this.form.manufacturingCost.purchaseCost.outProcessingCostList.findIndex(
        item => item.sequence === this.currentEditOutProcessingIndex
      )

      this.form.manufacturingCost.purchaseCost.outProcessingCostList.splice(
        _index,
        1,
        data
      )

      this.currentEditOutProcessing = null
      this.currentEditOutProcessingIndex = null
    },
    handleDeleteOutProcessing(sequence) {
      const outProcessingCostList = [
        ...this.form.manufacturingCost.purchaseCost.outProcessingCostList
      ]
      this.form.manufacturingCost.purchaseCost.outProcessingCostList = outProcessingCostList.filter(
        item => item.sequence !== sequence
      )
    },
    handleOutProcessingCancel() {
      this.currentEditOutProcessing = null
      this.currentEditOutProcessingIndex = null
      this.outProcessingModalVisible = false
    },

    // 生产明细
    handldeAddDirectPay() {
      this.directPayModalVisible = true
    },

    handleUpdateDirectPay(index, record) {
      this.currentEditDirectPayIndex = index
      this.currentEditDirectPay = deepClone(record)
      this.directPayModalVisible = true
    },

    handleDirectPayAddConfirm(data) {
      const len = this.form.manufacturingCost.processingCost.directPayCostList
        .length
      this.directPayModalVisible = false

      this.form.manufacturingCost.processingCost.directPayCostList.push({
        ...data,
        sequence: len
          ? this.form.manufacturingCost.processingCost.directPayCostList[
              len - 1
            ].sequence + 1
          : 1
      })
      this.currentEditDirectPay = null
      this.currentEditDirectPayIndex = null
    },

    handleDirectPayUpdateConfirm(data) {
      this.directPayModalVisible = false
      const _index = this.form.manufacturingCost.processingCost.directPayCostList.findIndex(
        item => item.sequence === this.currentEditDirectPayIndex
      )
      this.form.manufacturingCost.processingCost.directPayCostList.splice(
        _index,
        1,
        data
      )

      this.currentEditDirectPay = null
      this.currentEditDirectPayIndex = null
    },

    handleDeleteDirectPay(sequence) {
      const directPayCostList = [
        ...this.form.manufacturingCost.processingCost.directPayCostList
      ]
      this.form.manufacturingCost.processingCost.directPayCostList = directPayCostList.filter(
        item => item.sequence !== sequence
      )
    },
    handleDirectPayCancel() {
      this.currentEditDirectPay = null
      this.currentEditDirectPayIndex = null
      this.directPayModalVisible = false
    },

    handleGetStagingInfo() {
      getStagingCostAccountingDetail(this.code).then(res => {
        if (res.data) {
          this.form = res.data
        }
      })
    },

    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.type === 'add') {
            saveCostAccountingDetail(this.code, this.form)
              .then(res => {
                if (res) {
                  this.$message.success('保存成功')
                  this.$emit('confirm')
                }
              })
              .catch(error => {
                this.$message.error(error)
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            updateCostAccountingDetail(this.code, this.form)
              .then(res => {
                if (res) {
                  this.$message.success('更新成功')
                  this.$emit('confirm')
                }
              })
              .finally(() => {
                this.loading = false
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleStaging() {
      this.stagingLoading = true
      stagingCostAccountingDetail(this.code, this.form)
        .then(res => {
          this.$message.success('暂存成功')
        })
        .catch(err => {
          this.$message.error('暂存失败')
        })
        .finally(() => {
          this.stagingLoading = false
        })
    }
  }
}
</script>

<style scoped>
.modal-container {
  height: 400px;
  overflow-y: scroll;
}
.cost-form {
  margin-top: 26px;
}
.cost-form >>> .ant-col-offset-1 {
  margin-left: 0px;
}
</style>

<style lang="scss" scoped>
.craft-form {
  padding-top: 14px;
  .craft-form-item {
    margin-bottom: 14px;
  }
}
</style>
