<template>
	<a-modal v-drag-modal class="modal-container" :visible="true" :title="title" :mask-closable="false" width="600px"
		@cancel="handleCancel">
		<template slot="footer">
			<a-button @click="handleCancel">取消</a-button>
			<a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
		</template>
		<a-form-model ref="form" :rules="rules" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
			<a-form-model-item label="撤销原因" prop="cancelReason">
				<a-textarea v-model.trim="form.cancelReason" :auto-size="{ minRows: 3, maxRows: 5 }" />
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>
<script>
	import { deleteInventorys, deleteOutInventorys, deleteInventorysTasks } from '@/api/inventory'
	export default {
		props: {
			id: String | Array,
			type: String,
		},
		data() {
			return {
				title: this.type === 'save' ? '入库单撤销原因' : this.type === 'out'  ?  '出库单撤销原因' : '入库任务撤销原因',
				labelCol: {
					span: 7,
				},
				wrapperCol: {
					span: 10,
					offset: 1,
				},
				template: 1,
				form: {
					cancelReason: '',
				},
				loading: false,
				rules: {
					cancelReason: [
						{
							required: true,
							message: '请输入撤销原因',
							trigger: 'blur',
						},
					],
				},
			}
		},
		mounted() {
			console.log(this.id)
		},
		methods: {
			handleCancel() {
				this.$emit('cancel')
			},
			handleConfirm() {
				this.$refs.form.validate((valid) => {
					this.loading = true
					if (valid) {
						if (this.type === 'save') {
							deleteInventorys(this.id, this.form).then((res) => {
								this.$message.success('入库单撤销成功')
								this.$emit('confirm')
							}, error => {
								this.$message.error('入库单撤销失败', error)
							}).finally(() => {
								this.loading = false
							})
						} else if (this.type === 'out') {
							deleteOutInventorys(this.id, this.form).then((res) => {
								this.$message.success('出库单撤销成功')
								this.$emit('confirm')
							}, error => {
								this.$message.error('出库单撤销失败', error)
							}).finally(() => {
								this.loading = false
							})
						} else {
							deleteInventorysTasks({
								cancelReason: this.form.cancelReason,
								recordIds: Array.isArray(this.id) ? this.id : [this.id]
							}).then(() => {
								this.$message.success('入库任务撤销成功')
								this.$emit('confirm')
							}, error => {
								this.$message.error('出库单撤销失败', error)
							}).finally(() => {
								this.loading = false
							})
						}
					} else {
						console.log('error submit!!', valid)
						return false
					}
				})
			},
		},
	}
</script>

<style scoped>

</style>
