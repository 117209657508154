var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        "body-style": _vm.bodyStyle,
        visible: "true",
        title: _vm.title,
        "mask-closable": false,
        width: "900px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.ifCheck
            ? [
                _c("a-button", { on: { click: _vm.handleReset } }, [
                  _vm._v("重置"),
                ]),
                _c("a-button", { on: { click: _vm.handleCancel } }, [
                  _vm._v("取消"),
                ]),
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.handleConfirm },
                  },
                  [_vm._v("确定")]
                ),
              ]
            : [_c("span")],
        ],
        2
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.template,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "模板编号", prop: "templateCode" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.ifCheck,
                  "max-length": "20",
                  placeholder: "请输入模板编号",
                },
                model: {
                  value: _vm.template.templateCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.template,
                      "templateCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "template.templateCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "材料名称", prop: "materialName" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "materialSelect",
                  staticStyle: { width: "100%", border: "none" },
                  attrs: {
                    disabled: _vm.ifCheck,
                    value: _vm.template.materialName,
                    "show-search": "",
                    "default-active-first-option": false,
                    placeholder: "请选择材料名称",
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": _vm.fetching ? undefined : null,
                  },
                  on: {
                    search: _vm.fetchMaterialByName,
                    change: function (value, option) {
                      return _vm.handleMaterialNameChange(
                        value,
                        option,
                        _vm.index
                      )
                    },
                  },
                },
                [
                  _vm.fetching
                    ? _c("a-spin", {
                        attrs: { slot: "notFoundContent", size: "small" },
                        slot: "notFoundContent",
                      })
                    : _vm._e(),
                  _vm._l(_vm.materials, function (material) {
                    return _c("a-select-option", { key: material.id }, [
                      _vm._v(
                        _vm._s(material.materialName) +
                          "(" +
                          _vm._s(material.materialCode) +
                          ")"
                      ),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "材料牌号", prop: "materialCode" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%", border: "none" },
                  attrs: {
                    disabled: _vm.ifCheck,
                    value: _vm.template.materialCode,
                    "show-search": "",
                    "default-active-first-option": false,
                    placeholder: "请输入材料图号",
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": _vm.fetching ? undefined : null,
                  },
                  on: {
                    search: _vm.fetchMaterialByCode,
                    change: function (value, option) {
                      return _vm.handleMaterialNameChange(
                        value,
                        option,
                        _vm.index
                      )
                    },
                  },
                },
                [
                  _vm.fetching
                    ? _c("a-spin", {
                        attrs: { slot: "notFoundContent", size: "small" },
                        slot: "notFoundContent",
                      })
                    : _vm._e(),
                  _vm._l(_vm.materials, function (material) {
                    return _c("a-select-option", { key: material.id }, [
                      _vm._v(
                        _vm._s(material.materialName) +
                          "(" +
                          _vm._s(material.materialCode) +
                          ")"
                      ),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "硬度", prop: "operationHardness" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.ifCheck,
                  "max-length": "20",
                  placeholder: "请输入硬度",
                },
                model: {
                  value: _vm.template.operationHardness,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.template,
                      "operationHardness",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "template.operationHardness",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "编制人员", prop: "designerId" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    disabled: _vm.ifCheck,
                    "show-search": "",
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterUser,
                  },
                  model: {
                    value: _vm.template.designerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.template, "designerId", $$v)
                    },
                    expression: "template.designerId",
                  },
                },
                _vm._l(_vm.userList, function (user) {
                  return _c(
                    "a-select-option",
                    { key: user.id, attrs: { value: user.id } },
                    [
                      _vm._v(
                        _vm._s(user.realName) + "(" + _vm._s(user.uid) + ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "craft-form-item",
              attrs: { label: "编制时间", prop: "designTime" },
            },
            [
              _c("a-date-picker", {
                staticStyle: { width: "300px" },
                attrs: { disabled: true, format: "YYYY-MM-DD HH:mm:ss" },
                model: {
                  value: _vm.template.designTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.template, "designTime", $$v)
                  },
                  expression: "template.designTime",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-table",
        {
          staticClass: "processTable",
          staticStyle: { width: "100%", "margin-top": "-2px" },
          attrs: {
            bordered: "",
            scroll: { x: 0, y: 200 },
            columns: _vm.craftProcessColumns,
            "data-source": _vm.template.processRouteTemplate,
            pagination: false,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "operation",
                fn: function (text, column) {
                  return column.sequenceNum % 2 !== 0
                    ? _c(
                        "div",
                        { staticClass: "table-operation" },
                        [
                          _c(
                            "a-dropdown",
                            [
                              _c(
                                "a-menu",
                                {
                                  staticClass: "ant-dropdown-link",
                                  attrs: { slot: "overlay" },
                                  slot: "overlay",
                                },
                                [
                                  _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeleteCraft(column)
                                        },
                                      },
                                    },
                                    [_vm._v("删除工序")]
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEditCraft(column)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑工序")]
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleInsertCraft(
                                            column,
                                            _vm.index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("插入工序")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a",
                                { staticClass: "operation-btn" },
                                [
                                  _vm._v(" 操作 "),
                                  _c("a-icon", { attrs: { type: "down" } }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e()
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c(
            "div",
            { attrs: { slot: "customTitle" }, slot: "customTitle" },
            [
              _c("p", [_vm._v("工序号")]),
              !_vm.ifCheck
                ? [
                    !_vm.ifPrint
                      ? _c(
                          "a-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleAddCraftProcess()
                              },
                            },
                          },
                          [
                            _c("a-icon", {
                              staticStyle: {
                                "vertical-align": "middle",
                                "margin-right": "-5px",
                              },
                              attrs: { type: "plus-circle" },
                            }),
                            _vm._v("增加工序 "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm.processEditModalVisible
        ? _c("CraftProcessEditModal", {
            attrs: {
              "modal-type": _vm.editModalType,
              "craft-form": _vm.editProcessRoute,
            },
            on: {
              confirm: _vm.handleConfirmCraftModal,
              cancel: _vm.handleCloseCraftModal,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }