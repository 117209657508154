export const userColumns = [
  {
    title: '员工编号',
    dataIndex: 'uid',
    key: 'uid',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '员工名称',
    dataIndex: 'realName',
    key: 'realName',
    searchType: 'text',
    sorter: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    },
  },
  {
    title: '员工性别',
    dataIndex: 'sex',
    key: 'sex',
    align: 'center',
    scopedSlots: {
      customRender: 'sex'
    },
    filters: [
      {
        text: '男',
        value: 1
      },
      {
        text: '女',
        value: 2
      },
    ]
  },
  {
    title: '员工状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status'
    },
    filters: [
      {
        text: '正常',
        value: 1
      },
      {
        text: '已删除',
        value: 2
      },
    ]
  },
  {
    title: '联系电话',
    dataIndex: 'phone',
    key: 'phone',
    searchType: 'text',
    sorter: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    },
  },

  {
    title: '员工角色',
    dataIndex: 'roles',
    key: 'role.name',
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'role'
    },
  },

  {
    title: '操作',
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate'
    }
  }
]
