<template>
  <el-pagination
    class="el-pagination"
    background
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    :current-page="cPage"
    :page-sizes="[5, 10, 20, 30, 40, 50]"
    :page-size="pageSize"
    @current-change="currentChange"
    @size-change="handleSizeChange"
  />
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      type: Number,
      required: true,
      default: 10
    },
    params: {
      type: Object
    }
  },
  data() {
    return {
      cPage: 1,
      pageSize: 10
    }
  },
  methods: {
    /**
     * 分页请求方法
     * @param pageNum {number}
     */
    currentChange(pageNum, params) {
      this.cPage = pageNum
      let data = { pageNum: pageNum, pageSize: this.pageSize }
      if (params) {
        data = {
          pageNum: pageNum,
          pageSize: this.pageSize,
          ...params
        }
      }
      this.$emit('getData', data, this.params)
    },
    /**
     * 每页数量变化时的处理
     * @param pageSize {number}
     */
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.currentChange(1)
    }
  }
}
</script>

<style scoped>
.el-pagination {
  margin-top: 15px;
}
</style>
