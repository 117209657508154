var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title,
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.material,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "材料类别", prop: "categoryCode" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    placeholder: "请选择材料类别",
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterUser,
                  },
                  model: {
                    value: _vm.material.categoryCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.material, "categoryCode", $$v)
                    },
                    expression: "material.categoryCode",
                  },
                },
                _vm._l(_vm.currentType, function (type) {
                  return _c(
                    "a-select-option",
                    {
                      key: type.parameterValue,
                      attrs: { value: type.parameterCode },
                    },
                    [_vm._v(_vm._s(type.parameterValue))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "材料牌号", prop: "materialCode" } },
            [
              _c("a-input", {
                attrs: { "max-length": "20", placeholder: "请输入材料图号" },
                model: {
                  value: _vm.material.materialCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.material,
                      "materialCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "material.materialCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "材料名称", prop: "materialName" } },
            [
              _c("a-input", {
                attrs: { "max-length": "20", placeholder: "请输入材料名称" },
                model: {
                  value: _vm.material.materialName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.material,
                      "materialName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "material.materialName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "采购规格", prop: "purchaseSpecification" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入采购规格" },
                model: {
                  value: _vm.material.purchaseSpecification,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.material,
                      "purchaseSpecification",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "material.purchaseSpecification",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "内部编号", prop: "innerCode" } },
            [
              _c("a-input", {
                attrs: { "max-length": "20", placeholder: "请输入内部编号" },
                model: {
                  value: _vm.material.innerCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.material,
                      "innerCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "material.innerCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "密度(g/cm3)", prop: "density" } },
            [
              _c("a-input-number", {
                staticStyle: { width: "350px" },
                attrs: {
                  precision: 2,
                  min: 0,
                  placeholder: "请输入密度(g/cm3)",
                },
                model: {
                  value: _vm.material.density,
                  callback: function ($$v) {
                    _vm.$set(_vm.material, "density", $$v)
                  },
                  expression: "material.density",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "最新单价", prop: "unitPrice" } },
            [
              _c("a-input-number", {
                staticStyle: { width: "350px" },
                attrs: { precision: 2, min: 0, placeholder: "请输入最新单价" },
                model: {
                  value: _vm.material.unitPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.material, "unitPrice", $$v)
                  },
                  expression: "material.unitPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "计价单位", prop: "unit" } },
            [
              _c("a-input", {
                attrs: { "max-length": "20", placeholder: "请输入计价单位" },
                model: {
                  value: _vm.material.unit,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.material,
                      "unit",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "material.unit",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }