import Vue from 'vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import moment from 'moment'
import Print from 'vue-print-nb'
// import VueClipboard from 'vue-clipboard2'
// import Print from './plugins/print/Print' // 引入附件的js文件
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
/**
 * 下面注释的依赖项在本项目中都通过cdn的方式来引入
 * 如果需要修改为本地打包部署文件 则放开注释并且转移到 vue.config.js 文件中取消 externals 配置
 */

import iView from 'iview'
import 'iview/dist/styles/iview.css' // 使用 CSS
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n
// import i18n from './lang' // internationalization

import '@/styles/index.scss' // global css
import App from './App'
import store from './store'
import router from './router'
import 'moment/locale/zh-cn'
import '@/icons' // icon
import '@/permission' // permission control
import packages from './packages';

// 上线之后 取消掉mock
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import { mockXHR } from '../mock'
// if (process.env.NODE_ENV === 'product') {
//   mockXHR()
// }

/**
 * 引入cdn资源文件之后则可以不需要use 美汁汁～
 * 如果需要本地打包文件部署则请放开注释
 */
// set ElementUI lang to EN
Vue.use(packages)
Vue.use(ElementUI)

Vue.use(iView)
Vue.config.productionTip = false

Vue.prototype.validSe = function (value, number = 255) {
  value = value
    .replace(
      /[`～*~!#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！#@￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
      ''
    )
    .replace(/\s/g, '')
  if (value.length >= number) {
    this.$message({
      type: 'warning',
      message: `输入内容不能超过${number}个字符`
    })
  }
  return value
}
Vue.use(Antd)
Vue.use(Print)
// Vue.use(VueClipboard)
Vue.directive('drag-modal', (el, bindings, vnode) => {
  Vue.nextTick(() => {
    const { visible, destroyOnClose } = vnode.componentInstance
    // 防止未定义 destroyOnClose 关闭弹窗时dom未被销毁，指令被重复调用
    if (!visible) return
    const modal = el.getElementsByClassName('ant-modal')[0]
    const header = el.getElementsByClassName('ant-modal-header')[0]
    let left = 0
    let top = 0

    // 未定义 destroyOnClose 时，dom未被销毁，关闭弹窗再次打开，弹窗会停留在上一次拖动的位置
    if (!destroyOnClose) {
      left = modal.left || 0
      top = modal.top || 0
    }
    // top 初始值为 offsetTop
    top = top || modal.offsetTop
    header.onmousedown = e => {
      const startX = e.clientX
      const startY = e.clientY
      header.left = header.offsetLeft
      header.top = header.offsetTop
      el.onmousemove = event => {
        const endX = event.clientX
        const endY = event.clientY
        modal.left = header.left + (endX - startX) + left
        modal.top = header.top + (endY - startY) + top
        modal.style.left = modal.left + 'px'
        modal.style.top = modal.top + 'px'
      }
      el.onmouseup = event => {
        left = modal.left
        top = modal.top
        el.onmousemove = null
        el.onmouseup = null
        header.releaseCapture && header.releaseCapture()
      }
      header.setCapture && header.setCapture()
    }
  })
})
Vue.prototype.$moment = moment
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
