<template>
  <div class="content">
    <div class="content-header">
      <div id="container"></div>
      <div class="container-wrap">
        <div class="container-title">原辅材料明细</div>
        <div id="materialContainer"></div>
      </div>
      <div class="container-wrap">
        <div class="container-title">外协明细</div>
        <div id="outProcessingContainer"></div>
      </div>
      <div class="container-wrap">
        <div class="container-title">外购明细</div>
        <div id="outSourceingContainer"></div>
      </div>
      <div class="container-wrap">
        <div class="container-title">直接工资明细费</div>
        <div id="processingContainer"></div>
      </div>
    </div>
    <div class="content-footer">说明: {{ info.remark }}</div>
  </div>
</template>
<script>
import { PivotSheet, TableSheet } from '@antv/s2'
import {
  CustomCornerCell,
  CustomColCell,
  CustomDataCell,
  CustomRowCell
} from './extend'
import { getCostAccountingDetail } from '@/api/costs'

const MetaData = {
  describe: '标准交叉表数据。',
  fields: {
    rows: ['project', 'made'],
    columns: ['productName', 'productType', 'productWeight', 'totalCost'],
    values: ['account'],
    valueInCols: true
  },
  meta: [
    {
      field: 'project',
      name: '项目'
    },
    {
      field: 'made',
      name: '构成'
    },
    {
      field: 'account',
      name: '金额'
    },

    {
      field: 'totalCost',
      name: '单位产品成本核算汇总'
    }
  ],
  data: [],
  totalData: []
}

const MaterialData = {
  fields: {
    columns: [
      'materialName',
      'materialTexture',
      'materialDensity',
      'materialUnit',
      'materialUnitPrice',
      'materialQuantity',
      'materialCost'
    ]
  },
  meta: [
    {
      field: 'materialName',
      name: '原辅材料名称'
    },
    {
      field: 'materialTexture',
      name: '材质'
    },
    {
      field: 'materialDensity',
      name: '规格'
    },
    {
      field: 'materialUnit',
      name: '单位'
    },
    {
      field: 'materialUnitPrice',
      name: '单价'
    },
    {
      field: 'materialQuantity',
      name: '消耗数量'
    },
    {
      field: 'materialCost',
      name: '金额'
    }
  ]
}

const OutProcessingData = {
  fields: {
    columns: ['operationName', 'companyName', 'unitPrice', 'quantity', 'amount']
  },
  meta: [
    {
      field: 'operationName',
      name: '工序名称'
    },
    {
      field: 'companyName',
      name: '厂家'
    },
    {
      field: 'unitPrice',
      name: '单价'
    },
    {
      field: 'quantity',
      name: '数量'
    },
    {
      field: 'amount',
      name: '金额'
    }
  ]
}

const OutSourceingData = {
  fields: {
    columns: ['productName', 'companyName', 'unitPrice', 'quantity', 'amount']
  },
  meta: [
    {
      field: 'productName',
      name: '产品名称'
    },
    {
      field: 'companyName',
      name: '厂家'
    },
    {
      field: 'unitPrice',
      name: '单价'
    },
    {
      field: 'quantity',
      name: '数量'
    },
    {
      field: 'amount',
      name: '金额'
    }
  ]
}

const ProcessingCostData = {
  fields: {
    columns: ['operationName', 'workTime', 'unitPrice', 'amount']
  },
  meta: [
    {
      field: 'operationName',
      name: '工序名称'
    },
    {
      field: 'workTime',
      name: '工时'
    },
    {
      field: 'unitPrice',
      name: '工时单价'
    },
    {
      field: 'amount',
      name: '工资金额'
    }
  ]
}

export default {
  data() {
    return {
      MetaData,
      MaterialData,
      OutProcessingData,
      OutSourceingData,
      ProcessingCostData,
      info: {}
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    CustomCornerCell,
    CustomColCell,
    CustomDataCell,
    CustomRowCell,
    async getInfo() {
      const code = this.$route.query.code
      const res = await getCostAccountingDetail(code)
      if (!res.data) {
        this.$message.error('核算信息缺失')
        return
      }
      this.info = res.data
      this.init(res.data)
      this.initMaterial(res.data)
      this.initOutProcessingCost(res.data)
      this.initOutSourceingDataCost(res.data)
      this.initProcessingCost(res.data)
    },
    init(info) {
      const container = document.getElementById('container')

      this.MetaData.meta.push(
        {
          field: 'productName',
          name: `产品名称:   ${info.productInfo.name || ''}`
        },
        {
          field: 'productType',
          name: `规格型号:   ${info.productInfo.specification || ''}`
        },
        {
          field: 'productWeight',
          name: `产品净重:  ${info.netWeight || ''}`
        }
      )

      this.MetaData.totalData.push(
        {
          account: info.manufacturingCost.materialCost.totalAmountTaxFree,
          project: '制造成本',
          made: '原辅材料费'
        },
        {
          account: info.manufacturingCost.purchaseCost.totalAmountTaxFree,
          project: '制造成本',
          made: '外购外协费'
        },
        {
          account:
            info.manufacturingCost.processingCost.directPayCost.totalAmount,
          project: '制造成本',
          made: '直接工资费'
        },
        {
          account:
            info.manufacturingCost.processingCost.manufacturingCost.amount,
          project: '制造成本',
          made:
            '生产制造费' +
            info.manufacturingCost.processingCost.manufacturingCost.rate * 100 +
            '%'
        },
        {
          account: info.manufacturingCost.processingCost.toolingCost,
          project: '制造成本',
          made: '工模具费'
        },
        {
          account: info.manufacturingCost.processingCost.surplusValue,
          project: '制造成本',
          made: '余料回收价值'
        },
        {
          account: info.manufacturingCost.totalAmount,
          project: '制造成本',
          made: '小计'
        },
        {
          account: info.periodCost.manageCost.amount,
          project: '期间费用',
          made: '管理费' + info.periodCost.manageCost.rate * 100 + '%'
        },
        {
          account: info.periodCost.financialCost.amount,
          project: '期间费用',
          made: '财务费' + info.periodCost.financialCost.rate * 100 + '%'
        },
        {
          account: info.periodCost.sellingCost.amount,
          project: '期间费用',
          made: '销售费' + info.periodCost.sellingCost.rate * 100 + '%'
        },
        {
          account: info.periodCost.designCost.amount,
          project: '期间费用',
          made: '设计费' + info.periodCost.designCost.rate * 100 + '%'
        },
        {
          account: info.periodCost.totalAmount,
          project: '期间费用',
          made: '小计'
        },
        {
          account: info.profit.amount,
          project: '利润(' + info.profit.rate * 100 + '%)'
        },
        {
          account: info.totalAmountTaxFree,
          project: '合计(不含税)'
        },
        {
          account: info.totalTaxAmount.amount,
          project: '报价(含税' + info.totalTaxAmount.rate * 100 + '%)'
        },
        {
          account: info.leadTime,
          project: '预计生产周期(工作日)'
        }
      )

      this.MetaData.data = [
        {
          project: '制造成本',
          made: '原辅材料费'
        },
        {
          project: '制造成本',
          made: '外购外协费'
        },
        {
          project: '制造成本',
          made: '直接工资费'
        },
        {
          project: '制造成本',
          made:
            '生产制造费' +
            info.manufacturingCost.processingCost.manufacturingCost.rate * 100 +
            '%'
        },
        {
          project: '制造成本',
          made: '工模具费'
        },
        {
          project: '制造成本',
          made: '余料回收价值'
        },
        {
          project: '制造成本',
          made: '小计'
        },
        {
          project: '期间费用',
          made: '管理费' + info.periodCost.manageCost.rate * 100 + '%'
        },
        {
          project: '期间费用',
          made: '财务费' + info.periodCost.financialCost.rate * 100 + '%'
        },
        {
          project: '期间费用',
          made: '销售费' + info.periodCost.sellingCost.rate * 100 + '%'
        },
        {
          project: '期间费用',
          made: '设计费' + info.periodCost.designCost.rate * 100 + '%'
        },
        {
          project: '期间费用',
          made: '小计'
        },
        {
          project: '利润(' + info.profit.rate * 100 + '%)'
        },
        {
          project: '合计(不含税)'
        },
        {
          project: '报价(含税' + info.totalTaxAmount.rate * 100 + '%)'
        },
        {
          project: '预计生产周期(工作日)'
        }
      ]

      const s2Options = {
        width: 500,
        height: 640,
        cornerCell: (node, s2, headConfig) => {
          return new this.CustomCornerCell(node, s2, headConfig)
        },
        colCell: (node, s2, headConfig) => {
          return new CustomColCell(node, s2, headConfig)
        },
        placeholder: cell => {
          return ''
        },
        rowCell: (node, s2, headConfig) => {
          return new CustomRowCell(node, s2, headConfig)
        },
        dataCell: viewMeta => {
          return new CustomDataCell(viewMeta, viewMeta?.spreadsheet)
        }
      }

      const s2 = new PivotSheet(container, this.MetaData, s2Options)

      s2.render()
    },
    initMaterial(info) {
      const container = document.getElementById('materialContainer')

      const len =
        info.manufacturingCost.materialCost.materialCostItemList.length
      let data = []

      // 补充数据
      if (len) {
        data = info.manufacturingCost.materialCost.materialCostItemList.map(
          item => {
            return {
              materialName: item.materialInfo.name,
              materialTexture: item.materialInfo.code,
              materialDensity:
                item.pricingModel === 'CBE'
                  ? `${item.consumeSpecification['length']} * ${item.consumeSpecification.width} * ${item.consumeSpecification.height}`
                  : `${item.consumeSpecification.diameter} * ${item.consumeSpecification.height}`,
              materialUnit: item.materialInfo.unit,
              materialUnitPrice: item.materialInfo.unitPrice,
              materialQuantity: item.quantity,
              materialCost: item.amount
            }
          }
        )
      }

      // 补全长度
      if (len < 14) {
        data.push(
          ...new Array(14 - len).fill({
            materialName: '  ',
            materialTexture: '  ',
            materialDensity: '  ',
            materialUnit: '  ',
            materialUnitPrice: '  ',
            materialQuantity: '  ',
            materialCost: '  '
          })
        )
      }

      // 补充小计
      data.push(
        {
          materialName: '  ',
          materialTexture: '  ',
          materialDensity: '  ',
          materialUnit: '  ',
          materialUnitPrice: '  ',
          materialQuantity: '含税小计',
          materialCost: info.manufacturingCost.materialCost.totalAmountWithTax
        },
        {
          materialName: '  ',
          materialTexture: '  ',
          materialDensity: '  ',
          materialUnit: '  ',
          materialUnitPrice: '  ',
          materialQuantity: '不含税小计',
          materialCost: info.manufacturingCost.materialCost.totalAmountTaxFree
        }
      )

      this.MaterialData.data = data

      const totalLen = data.length

      const s2Options = {
        width: 700,
        height: 520,
        showSeriesNumber: false,
        mergedCellsInfo: [
          [
            { colIndex: 0, rowIndex: totalLen - 2 },
            { colIndex: 1, rowIndex: totalLen - 2 },
            { colIndex: 2, rowIndex: totalLen - 2 },
            { colIndex: 3, rowIndex: totalLen - 2 },
            { colIndex: 4, rowIndex: totalLen - 2, showText: true }
          ],
          [
            { colIndex: 0, rowIndex: totalLen - 1 },
            { colIndex: 1, rowIndex: totalLen - 1 },
            { colIndex: 2, rowIndex: totalLen - 1 },
            { colIndex: 3, rowIndex: totalLen - 1 },
            { colIndex: 4, rowIndex: totalLen - 1, showText: true }
          ]
        ],
        dataCell: viewMeta => {
          return new CustomDataCell(viewMeta, viewMeta?.spreadsheet)
        }
      }

      const s2 = new TableSheet(container, this.MaterialData, s2Options)

      s2.render()
    },
    initOutProcessingCost(info) {
      const container = document.getElementById('outProcessingContainer')

      const len =
        info.manufacturingCost.purchaseCost.outProcessingCost
          .outProcessingCostItemList.length

      let data = []

      if (len) {
        data = info.manufacturingCost.purchaseCost.outProcessingCost.outProcessingCostItemList.map(
          item => {
            return {
              operationName: item.operationInfo.name,
              companyName: item.companyInfo.name,
              unitPrice: item.operationInfo.unitPrice,
              quantity: item.quantity,
              amount: item.amount
            }
          }
        )
        this.OutProcessingData.data = data
      }

      // 补全长度
      if (len < 14) {
        data.push(
          ...new Array(14 - len).fill({
            operationName: '  ',
            companyName: '  ',
            unitPrice: '  ',
            quantity: '  ',
            amount: '  '
          })
        )
      }

      // 补充小计
      data.push(
        {
          operationName: '  ',
          companyName: '  ',
          unitPrice: '  ',
          quantity: '含税小计',
          amount:
            info.manufacturingCost.purchaseCost.outProcessingCost
              .totalAmountWithTax
        },
        {
          operationName: '  ',
          companyName: '  ',
          unitPrice: '  ',
          quantity: '不含税小计',
          amount:
            info.manufacturingCost.purchaseCost.outProcessingCost
              .totalAmountTaxFree
        }
      )

      this.OutProcessingData.data = data

      const totalLen = data.length

      const s2Options = {
        width: 600,
        height: 520,
        showSeriesNumber: false,
        mergedCellsInfo: [
          [
            { colIndex: 0, rowIndex: totalLen - 2 },
            { colIndex: 1, rowIndex: totalLen - 2 },
            { colIndex: 2, rowIndex: totalLen - 2 },
            { colIndex: 3, rowIndex: totalLen - 2, showText: true }
          ],
          [
            { colIndex: 0, rowIndex: totalLen - 1 },
            { colIndex: 1, rowIndex: totalLen - 1 },
            { colIndex: 2, rowIndex: totalLen - 1 },
            { colIndex: 3, rowIndex: totalLen - 1, showText: true }
          ]
        ],
        dataCell: viewMeta => {
          return new CustomDataCell(viewMeta, viewMeta?.spreadsheet)
        }
      }

      const s2 = new TableSheet(container, this.OutProcessingData, s2Options)

      s2.render()
    },
    initOutSourceingDataCost(info) {
      const container = document.getElementById('outSourceingContainer')

      const len =
        info.manufacturingCost.purchaseCost.outsourcingCost
          .outsourcingCostItemList.length

      let data = []

      if (len) {
        data = info.manufacturingCost.purchaseCost.outsourcingCost.outsourcingCostItemList.map(
          item => {
            return {
              productName: item.productInfo.name,
              companyName: item.companyInfo.name,
              unitPrice: item.unitPrice,
              quantity: item.quantity,
              amount: item.amount
            }
          }
        )
      }

      // 补全长度
      if (len < 14) {
        data.push(
          ...new Array(14 - len).fill({
            productName: '  ',
            companyName: '  ',
            unitPrice: '  ',
            quantity: '  ',
            amount: '  '
          })
        )
      }

      // 补充小计
      data.push(
        {
          productName: '  ',
          companyName: '  ',
          unitPrice: '  ',
          quantity: '含税小计',
          amount:
            info.manufacturingCost.purchaseCost.outsourcingCost
              .totalAmountWithTax
        },
        {
          productName: '  ',
          companyName: '  ',
          unitPrice: '  ',
          quantity: '不含税小计',
          amount:
            info.manufacturingCost.purchaseCost.outsourcingCost
              .totalAmountTaxFree
        }
      )

      this.OutSourceingData.data = data

      const totalLen = data.length

      const s2Options = {
        width: 600,
        height: 520,
        showSeriesNumber: false,
        mergedCellsInfo: [
          [
            { colIndex: 0, rowIndex: totalLen - 2 },
            { colIndex: 1, rowIndex: totalLen - 2 },
            { colIndex: 2, rowIndex: totalLen - 2 },
            { colIndex: 3, rowIndex: totalLen - 2, showText: true }
          ],
          [
            { colIndex: 0, rowIndex: totalLen - 1 },
            { colIndex: 1, rowIndex: totalLen - 1 },
            { colIndex: 2, rowIndex: totalLen - 1 },
            { colIndex: 3, rowIndex: totalLen - 1, showText: true }
          ]
        ],
        dataCell: viewMeta => {
          return new CustomDataCell(viewMeta, viewMeta?.spreadsheet)
        }
      }

      const s2 = new TableSheet(container, this.OutSourceingData, s2Options)

      s2.render()
    },
    initProcessingCost(info) {
      const container = document.getElementById('processingContainer')

      const len =
        info.manufacturingCost.processingCost.directPayCost
          .directPayCostItemList.length

      let data = []

      if (len) {
        data = info.manufacturingCost.processingCost.directPayCost.directPayCostItemList.map(
          item => {
            return {
              operationName: item.operationInfo.name,
              workTime: item.workTime,
              unitPrice: item.operationInfo.unitPrice,
              amount: item.amount
            }
          }
        )
      }

      // 补全长度
      if (len < 15) {
        data.push(
          ...new Array(15 - len).fill({
            operationName: '  ',
            workTime: '  ',
            unitPrice: '  ',
            amount: '  '
          })
        )
      }

      // 补充小计
      data.push({
        operationName: '  ',
        workTime: '  ',
        unitPrice: '直接工资小计',
        amount: info.manufacturingCost.processingCost.directPayCost.totalAmount
      })

      this.ProcessingCostData.data = data

      const totalLen = data.length

      const s2Options = {
        width: 600,
        height: 520,
        showSeriesNumber: false,
        mergedCellsInfo: [
          [
            { colIndex: 0, rowIndex: totalLen - 1 },
            { colIndex: 1, rowIndex: totalLen - 1 },
            { colIndex: 2, rowIndex: totalLen - 1, showText: true }
          ]
        ],
        dataCell: viewMeta => {
          return new CustomDataCell(viewMeta, viewMeta?.spreadsheet)
        }
      }

      const s2 = new TableSheet(container, this.ProcessingCostData, s2Options)

      s2.render()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 3100px;
  padding: 60px;
  .content-header {
    display: flex;
    flex-wrap: nowrap;
  }
  .content-footer {
    width: 100%;
    height: 60px;
    border: 1px solid #bddef9;
    margin-top: -10px;
    font-size: 15px;
    font-weight: bold;
    color: black;
    display: flex;
    align-items: center;
  }
}
.container-wrap {
  width: auto;
  .container-title {
    height: 120px;
    font-size: 20px;
    line-height: 120px;
    text-align: center;
    font-weight: bold;
    color: black;
    background: #dbe6fc;
    border-right: 2px solid #bddef9;
  }
}
</style>
