/*!
 * 记录页 Table表头展示数据
 */

// 记录表头数据列
export const recordColumns = [
  {
    title: '任务编号',
    dataIndex: 'taskCode',
    key: 'task.code',
    align: 'center',
    width: 130,
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'name'
    }
  },
  {
    title: '记录类型',
    dataIndex: 'recordType',
    key: 'recordType',
    width: 130,
    filters: [
      {
        text: '加工记录',
        value: 1
      },
      {
        text: '检验记录',
        value: 2
      },
      {
        text: '返工加工记录',
        value: 3
      },
      {
        text: '返工检验记录',
        value: 4
      }
    ],
    align: 'center',
    scopedSlots: {
      customRender: 'recordType'
    }
  },
  {
    title: '工序名称',
    dataIndex: 'operationName',
    key: 'op.operationName',
    align: 'center',
    searchType: 'text',
    width: 130,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '下一工序',
    key: 'nxt_op.operationName',
    dataIndex: 'nextOperationName',
    width: 130,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '员工名称',
    dataIndex: 'realName',
    key: 'user.realName',
    width: 130,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '交检/流转数量',
    dataIndex: 'checkCount',
    key: 'checkCount',
    width: 180,
    align: 'center',
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '实际交检数量',
    dataIndex: 'actualCheckCount',
    key: 'actualCheckCount',
    align: 'center',
    searchType: 'number',
    width: 180,
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '报废数量',
    dataIndex: 'abandonCount',
    key: 'abandonCount',
    align: 'center',
    width: 130,
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '超差数量',
    dataIndex: 'outOfToleranceCount',
    key: 'outOfToleranceCount',
    align: 'center',
    width: 130,
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '返修数量',
    dataIndex: 'repairedCount',
    key: 'repairedCount',
    align: 'center',
    searchType: 'number',
    width: 130,
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '转入时间',
    dataIndex: 'recordStartTime',
    key: 'recordStartTime',
    align: 'center',
    ellipsis: true,
    sorter: true,
    width: 200,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '转出时间',
    dataIndex: 'generateTime',
    key: 'generateTime',
    align: 'center',
    width: 200,
    ellipsis: true,
    sorter: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '停留时间(小时)',
    dataIndex: 'duration',
    key: 'duration',
    width: 160,
    align: 'center',
    sorter: true
  },
  {
    title: '备注',
    dataIndex: 'comment',
    key: 'comment',
    width: 160,
    align: 'center',
    ellipsis: true
  },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    align: 'center',
    width: 80,
    scopedSlots: {
      customRender: 'operation'
    }
  }
]
