<template>
  <a-modal class="modal-container" :visible="true" :title="code + '开始核价'" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="全部开始核价" prop="isAll">
        <a-radio-group v-model="form.isAll">
          <a-radio :value="true">是</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="核算备注" prop="comment">
        <a-textarea v-model="form.comment" :max-length="10" placeholder="请输入核算备注" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { startCostAccounting } from '@/api/inquirylist'

export default {
  props: {
    code: String
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      form: {
        code: this.code,
        isAll: true,
        comment: '',
        itemSeqList: ''
      },
      rules: {
        isAll: [
          {
            required: true,
            message: '请选择全部开始核价',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          startCostAccounting([this.form])
            .then(res => {
              this.$message.success('核价启动成功')
              this.$emit('confirm')
            })
            .catch(err => {
              this.$message.error(err.message)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
