<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" :title="title" :mask-closable="false" width="1300px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="exportLoading" @click="exportDetail">导出</a-button>
    </template>
    <div class="table-container">
      <a-table
        :key="tableKey"
        :loading="tableLoading"
        row-key="id"
        bordered
        :columns="taskColumns"
        :data-source="detailList"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
          <template v-if="column.searchType === 'text'">
            <a-input
              v-ant-ref="c => (searchInput = c)"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
            />
          </template>
          <div v-else-if="column.searchType === 'number'">
            <a-input-number
              v-model="selectedKeys[0]"
              size="small"
              placeholder="最小值"
              :min="0"
              :max="999999"
              style="width: 95px; margin-bottom: 8px; display: inline-block;"
              @change="e => setSelectedKeys([e,selectedKeys[1]])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
            />
            <span>-</span>
            <a-input-number
              v-model="selectedKeys[1]"
              size="small"
              placeholder="最大值"
              :min="selectedKeys[0]"
              :max="999999"
              style="width: 95px; margin-bottom: 8px; display: inline-block;"
              @change="e => setSelectedKeys([selectedKeys[0],e])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
            />
          </div>
          <div v-else-if="column.searchType === 'date'">
            <a-range-picker
              :show-time="{ format: 'HH:mm:ss' }"
              size="small"
              style="width: 330px; margin-bottom: 8px;"
              :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
              @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
            />
          </div>
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.key)"
          >搜索</a-button>
          <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
        </div>
        <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        <div slot="operateType" slot-scope="text">
          <template v-if="text === 'C'">新增</template>
          <template v-if="text === 'D'">删除</template>
          <template v-if="text === 'U'">更新</template>
        </div>
      </a-table>
    </div>
  </a-modal>
</template>
<script>
import { getTargetLogDetail, exportTargetLogDetail } from '@/api/statistics'
import { taskColumns } from './dataLogic'
import { downloadItem } from '@/utils/api.request'
export default {
  props: {
    targetId: Number, // 目标id
    operateType: String
  },
  data() {
    return {
      title: '任务规划操作明细',
      tableLoading: false,
      baseURL: process.env.VUE_APP_BASE_API,
      tableKey: 0,
      taskColumns,
      detailList: [],
      selectedRowKeys: [],
      targetKeys: [],
      columnSearchParams: {},
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      currentTaskParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      totalPages: '',
      fetchLoading: false,
      exportLoading: false
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  mounted() {
    this.getTargetLogDetail()
  },
  methods: {
    getTargetLogDetail() {
      const data = {
        ...this.currentTaskParams,
        ...this.columnSearchParams
      }
      getTargetLogDetail(this.targetId, this.operateType, data).then(
        res => {
          this.detailList = res.data.records
          this.pagination.total = res.data.total
          this.tableLoading = false
        },
        error => {
          this.tableLoading = false
        }
      )
    },
    handleCancel() {
      this.$emit('cancel')
    },

    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)

      if (filtersKeys.includes('operateType')) {
        delete this.columnSearchParams['operateType']
        if (filters['operateType'].length > 0) {
          this.columnSearchParams['operateType'] = `in:${filters[
            'operateType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['operateType']
        }
      }

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentTaskParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentTaskParams.sort_by = null
      }

      this.pagination = pagination
      this.currentTaskParams.pageNum = pagination.current
      this.currentTaskParams.pageSize = pagination.pageSize
      this.getTargetLogDetail()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    exportDetail() {
      const data = {
        ...this.currentTaskParams,
        ...this.columnSearchParams
      }
      this.exportLoading = true
      exportTargetLogDetail(this.targetId, this.operateType, data)
        .then(res => {
          this.exportLoading = false
          this.downloadPlan(res)
        })
        .finally(() => {
          this.exportLoading = false
        })
    },
    downloadPlan(blobData) {
      const currentDate = +Date.now()
      downloadItem(blobData, 'application/vnd.ms-excel', '操作明细表')
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-btn {
  padding: 0 15px !important;
}
/deep/ .ant-modal-body {
  height: 800px;
  overflow-y: scroll;
}

.modal-container {
  overflow-y: scroll;
}
</style>
