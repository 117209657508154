var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
          staticClass: "modal-container",
          attrs: {
            "body-style": _vm.bodyStyle,
            visible: true,
            title: _vm.title + "记录——" + _vm.record.taskCode,
            "mask-closable": false,
            width: "800px",
          },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.handleReset } }, [
                _vm._v("重置"),
              ]),
              _c("a-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消"),
              ]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.record,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "记录类型", prop: "recordType" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { disabled: _vm.isUpdate },
                      on: { change: _vm.onRadioChange },
                      model: {
                        value: _vm.record.recordType,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "recordType", $$v)
                        },
                        expression: "record.recordType",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("加工记录"),
                      ]),
                      _c("a-radio", { attrs: { value: 2 } }, [
                        _vm._v("检验记录"),
                      ]),
                      _c("a-radio", { attrs: { value: 3 } }, [
                        _vm._v("返工加工记录"),
                      ]),
                      _c("a-radio", { attrs: { value: 4 } }, [
                        _vm._v("返工检验记录"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "记录人员", prop: "userId" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        "show-search": "",
                        placeholder: "请选择记录人员",
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterUser,
                      },
                      on: { select: _vm.handleUserSelected },
                      model: {
                        value: _vm.record.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "userId", $$v)
                        },
                        expression: "record.userId",
                      },
                    },
                    _vm._l(_vm.currentUserList, function (user) {
                      return _c(
                        "a-select-option",
                        { key: user.id, attrs: { value: user.id } },
                        [
                          _vm._v(
                            _vm._s(user.realName) + "(" + _vm._s(user.uid) + ")"
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "工序", prop: "operationId" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        loading: _vm.checkLoading,
                        "show-search": "",
                        placeholder: "请选择工序",
                        "option-filter-prop": "children",
                      },
                      on: { change: _vm.checkOperationVaild },
                      model: {
                        value: _vm.record.operationId,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "operationId", $$v)
                        },
                        expression: "record.operationId",
                      },
                    },
                    [
                      !_vm.checkLoading && _vm.operationVaild
                        ? [
                            _c("a-icon", {
                              attrs: { slot: "suffixIcon", type: "check" },
                              slot: "suffixIcon",
                            }),
                          ]
                        : _vm._e(),
                      _vm._l(_vm.currentOperationList, function (operation) {
                        return _c(
                          "a-select-option",
                          { key: operation.id, attrs: { value: operation.id } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(operation.operationName) +
                                "(" +
                                _vm._s(operation.operationCode) +
                                ") "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.operationName === "下料"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "期望下料时间" } },
                    [
                      _c(
                        "span",
                        { staticStyle: { color: "red", "font-size": "20px" } },
                        [_vm._v(_vm._s(_vm.record.expectMaterialTime))]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.operationName === "下料"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "实发材料", prop: "actualUseMaterial" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "460px" },
                          attrs: {
                            mode: "multiple",
                            loading: _vm.checkLoading,
                            "show-search": "",
                            "default-active-first-option": false,
                            placeholder: "请选择实发材料",
                            "option-filter-prop": "children",
                            "show-arrow": false,
                            "filter-option": false,
                            "not-found-content": _vm.fetching
                              ? undefined
                              : null,
                          },
                          on: {
                            focus: _vm.handleFetchALlMaterial,
                            search: _vm.fetchMaterialByCode,
                            select: function (value, option) {
                              return _vm.handleMaterialNameChange(value, option)
                            },
                            deselect: function (value, option) {
                              return _vm.handleMaterialNameChange(value, option)
                            },
                          },
                          model: {
                            value: _vm.actualUseMaterial,
                            callback: function ($$v) {
                              _vm.actualUseMaterial = $$v
                            },
                            expression: "actualUseMaterial",
                          },
                        },
                        [
                          _vm.fetching
                            ? _c("a-spin", {
                                attrs: {
                                  slot: "notFoundContent",
                                  size: "small",
                                },
                                slot: "notFoundContent",
                              })
                            : _vm._e(),
                          _vm._l(_vm.materials, function (material) {
                            return _c("a-select-option", { key: material.id }, [
                              _vm._v(
                                _vm._s(material.innerCode) +
                                  " " +
                                  _vm._s(material.materialName) +
                                  "(" +
                                  _vm._s(material.materialCode) +
                                  ")"
                              ),
                            ])
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.record.operationId === 263
                ? _c("a-form-model-item", { attrs: { label: "入库提示" } }, [
                    _vm._v(_vm._s(_vm.checkNextOperationName)),
                  ])
                : _vm._e(),
              _vm.record.operationId === 263
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "入库库房", prop: "warehouseType" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择入库库房" },
                          model: {
                            value: _vm.record.warehouseType,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "warehouseType", $$v)
                            },
                            expression: "record.warehouseType",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: 0 } }, [
                            _vm._v("成品库"),
                          ]),
                          _c("a-select-option", { attrs: { value: 1 } }, [
                            _vm._v("半成品库"),
                          ]),
                          _c("a-select-option", { attrs: { value: 2 } }, [
                            _vm._v("工装库"),
                          ]),
                          _c("a-select-option", { attrs: { value: 3 } }, [
                            _vm._v("材料库"),
                          ]),
                          _c("a-select-option", { attrs: { value: 4 } }, [
                            _vm._v("废品库"),
                          ]),
                          _c("a-select-option", { attrs: { value: 5 } }, [
                            _vm._v("来料退回库"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isOperationRecord
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "交检数量" } },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 10,
                          disabled: true,
                          placeholder: "请输入交检数量",
                        },
                        model: {
                          value: _vm.record.checkCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "checkCount", _vm._n($$v))
                          },
                          expression: "record.checkCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isOperationRepairRecord
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "交检数量", prop: "checkCount" } },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 10,
                          placeholder: "请输入交检数量",
                        },
                        model: {
                          value: _vm.record.checkCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "checkCount", _vm._n($$v))
                          },
                          expression: "record.checkCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isOperationRecord || _vm.isOperationRepairRecord
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "实际交检数量",
                        prop: "actualCheckCount",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 10,
                          placeholder: "请输入实际交检数量",
                        },
                        model: {
                          value: _vm.record.actualCheckCount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.record,
                              "actualCheckCount",
                              _vm._n($$v)
                            )
                          },
                          expression: "record.actualCheckCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCheckRepairRecord
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "流转数量", prop: "checkCount" } },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 10,
                          placeholder: "请输入流转数量",
                        },
                        model: {
                          value: _vm.record.checkCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "checkCount", _vm._n($$v))
                          },
                          expression: "record.checkCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCheckRecord || _vm.isCheckRepairRecord
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "has-feedback": "",
                        label: "报废数量",
                        prop: "abandonCount",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 10,
                          placeholder: "请输入报废数量",
                        },
                        model: {
                          value: _vm.record.abandonCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "abandonCount", _vm._n($$v))
                          },
                          expression: "record.abandonCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.abandonCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "报废责任人", prop: "abandonChargerIds" },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择报废责任人",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterUser,
                          },
                          model: {
                            value: _vm.record.abandonChargerIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "abandonChargerIds", $$v)
                            },
                            expression: "record.abandonChargerIds",
                          },
                        },
                        _vm._l(_vm.allUserList, function (user) {
                          return _c(
                            "a-select-option",
                            { key: user.id, attrs: { value: user.id } },
                            [
                              _vm._v(
                                _vm._s(user.realName) +
                                  "(" +
                                  _vm._s(user.uid) +
                                  ")"
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.abandonCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "报废责任工序",
                        prop: "abandonChargeOperationIds",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择报废责任工序",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOperation,
                          },
                          model: {
                            value: _vm.record.abandonChargeOperationIds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.record,
                                "abandonChargeOperationIds",
                                $$v
                              )
                            },
                            expression: "record.abandonChargeOperationIds",
                          },
                        },
                        _vm._l(_vm.allOperationList, function (operation) {
                          return _c(
                            "a-select-option",
                            {
                              key: operation.id,
                              attrs: { value: operation.id },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(operation.operationName) +
                                  "(" +
                                  _vm._s(operation.operationCode) +
                                  ") "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.abandonCount > 0
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "报废原因", prop: "abandonReasonIds" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择报废原因",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterReason,
                          },
                          model: {
                            value: _vm.record.abandonReasonIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "abandonReasonIds", $$v)
                            },
                            expression: "record.abandonReasonIds",
                          },
                        },
                        _vm._l(_vm.allDefeatReasons, function (reason) {
                          return _c(
                            "a-select-option",
                            { key: reason.id, attrs: { value: reason.id } },
                            [_vm._v(_vm._s(reason.parameterValue))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.abandonCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "材料规格",
                        prop: "abandonContactRecord",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 50,
                          placeholder: "请输入材料规格",
                        },
                        model: {
                          value: _vm.record.abandonContactRecord,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "abandonContactRecord", $$v)
                          },
                          expression: "record.abandonContactRecord",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.abandonCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "报废品描述", prop: "abandonDefeatDesc" },
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          "max-length": 255,
                          placeholder: "请输入报废品描述",
                        },
                        model: {
                          value: _vm.record.abandonDefeatDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "abandonDefeatDesc", $$v)
                          },
                          expression: "record.abandonDefeatDesc",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCheckRecord || _vm.isCheckRepairRecord
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "has-feedback": "",
                        label: "返工数量",
                        prop: "repairedCount",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 10,
                          placeholder: "请输入返工数量",
                        },
                        model: {
                          value: _vm.record.repairedCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "repairedCount", _vm._n($$v))
                          },
                          expression: "record.repairedCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.repairedCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "返工责任人",
                        prop: "repairedChargerIds",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择返工责任人",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterUser,
                          },
                          model: {
                            value: _vm.record.repairedChargerIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "repairedChargerIds", $$v)
                            },
                            expression: "record.repairedChargerIds",
                          },
                        },
                        _vm._l(_vm.allUserList, function (user) {
                          return _c(
                            "a-select-option",
                            { key: user.id, attrs: { value: user.id } },
                            [
                              _vm._v(
                                _vm._s(user.realName) +
                                  "(" +
                                  _vm._s(user.uid) +
                                  ")"
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.repairedCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "返工责任工序",
                        prop: "repairedChargeOperationIds",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择返工责任工序",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOperation,
                          },
                          model: {
                            value: _vm.record.repairedChargeOperationIds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.record,
                                "repairedChargeOperationIds",
                                $$v
                              )
                            },
                            expression: "record.repairedChargeOperationIds",
                          },
                        },
                        _vm._l(_vm.allOperationList, function (operation) {
                          return _c(
                            "a-select-option",
                            {
                              key: operation.id,
                              attrs: { value: operation.id },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(operation.operationName) +
                                  "(" +
                                  _vm._s(operation.operationCode) +
                                  ") "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.repairedCount > 0
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "返工原因", prop: "repairedReasonIds" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择返工原因",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterReason,
                          },
                          model: {
                            value: _vm.record.repairedReasonIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "repairedReasonIds", $$v)
                            },
                            expression: "record.repairedReasonIds",
                          },
                        },
                        _vm._l(_vm.allDefeatReasons, function (reason) {
                          return _c(
                            "a-select-option",
                            { key: reason.id, attrs: { value: reason.id } },
                            [_vm._v(_vm._s(reason.parameterValue))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.repairedCount > 0
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "返工人", prop: "repairedUserIds" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择返工人",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterUser,
                          },
                          model: {
                            value: _vm.record.repairedUserIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "repairedUserIds", $$v)
                            },
                            expression: "record.repairedUserIds",
                          },
                        },
                        _vm._l(_vm.allUserList, function (user) {
                          return _c(
                            "a-select-option",
                            { key: user.id, attrs: { value: user.id } },
                            [
                              _vm._v(
                                _vm._s(user.realName) +
                                  "(" +
                                  _vm._s(user.uid) +
                                  ")"
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.repairedCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "返工工序",
                        prop: "repairedOperationIds",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择返工工序",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOperation,
                          },
                          model: {
                            value: _vm.record.repairedOperationIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "repairedOperationIds", $$v)
                            },
                            expression: "record.repairedOperationIds",
                          },
                        },
                        _vm._l(_vm.allOperationList, function (operation) {
                          return _c(
                            "a-select-option",
                            {
                              key: operation.id,
                              attrs: { value: operation.id },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(operation.operationName) +
                                  "(" +
                                  _vm._s(operation.operationCode) +
                                  ") "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.repairedCount > 0
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "返修要求", prop: "defeatDemand" } },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 50,
                          placeholder: "请输入返修要求",
                        },
                        model: {
                          value: _vm.record.defeatDemand,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "defeatDemand", $$v)
                          },
                          expression: "record.defeatDemand",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.repairedCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "不合格详情", prop: "repairDefeatDesc" },
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          "max-length": 255,
                          placeholder: "请输入不合格详情",
                        },
                        model: {
                          value: _vm.record.repairDefeatDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "repairDefeatDesc", $$v)
                          },
                          expression: "record.repairDefeatDesc",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCheckRecord || _vm.isCheckRepairRecord
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "has-feedback": "",
                        label: "超差数量",
                        prop: "outOfToleranceCount",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 10,
                          placeholder: "请输入超差数量",
                        },
                        model: {
                          value: _vm.record.outOfToleranceCount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.record,
                              "outOfToleranceCount",
                              _vm._n($$v)
                            )
                          },
                          expression: "record.outOfToleranceCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.outOfToleranceCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "超差责任人",
                        prop: "outOfToleranceChargerIds",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择超差责任人",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterUser,
                          },
                          model: {
                            value: _vm.record.outOfToleranceChargerIds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.record,
                                "outOfToleranceChargerIds",
                                $$v
                              )
                            },
                            expression: "record.outOfToleranceChargerIds",
                          },
                        },
                        _vm._l(_vm.allUserList, function (user) {
                          return _c(
                            "a-select-option",
                            { key: user.id, attrs: { value: user.id } },
                            [
                              _vm._v(
                                _vm._s(user.realName) +
                                  "(" +
                                  _vm._s(user.uid) +
                                  ")"
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.outOfToleranceCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "超差责任工序",
                        prop: "outOfToleranceChargeOperationIds",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择超差责任工序",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOperation,
                          },
                          model: {
                            value: _vm.record.outOfToleranceChargeOperationIds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.record,
                                "outOfToleranceChargeOperationIds",
                                $$v
                              )
                            },
                            expression:
                              "record.outOfToleranceChargeOperationIds",
                          },
                        },
                        _vm._l(_vm.allOperationList, function (operation) {
                          return _c(
                            "a-select-option",
                            {
                              key: operation.id,
                              attrs: { value: operation.id },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(operation.operationName) +
                                  "(" +
                                  _vm._s(operation.operationCode) +
                                  ") "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.outOfToleranceCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "超差原因",
                        prop: "outOfToleranceReasonIds",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            "show-search": "",
                            placeholder: "请选择超差原因",
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterReason,
                          },
                          model: {
                            value: _vm.record.outOfToleranceReasonIds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.record,
                                "outOfToleranceReasonIds",
                                $$v
                              )
                            },
                            expression: "record.outOfToleranceReasonIds",
                          },
                        },
                        _vm._l(_vm.allDefeatReasons, function (reason) {
                          return _c(
                            "a-select-option",
                            { key: reason.id, attrs: { value: reason.id } },
                            [_vm._v(_vm._s(reason.parameterValue))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.outOfToleranceCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "顾客意见",
                        prop: "outOfToleranceContactRecord",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 50,
                          placeholder: "请输入顾客意见",
                        },
                        model: {
                          value: _vm.record.outOfToleranceContactRecord,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.record,
                              "outOfToleranceContactRecord",
                              $$v
                            )
                          },
                          expression: "record.outOfToleranceContactRecord",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isCheckRecord || _vm.isCheckRepairRecord) &&
              _vm.record.outOfToleranceCount > 0
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "不合格详情",
                        prop: "outOfToleranceDefeatDesc",
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          "max-length": 255,
                          placeholder: "请输入不合格详情",
                        },
                        model: {
                          value: _vm.record.outOfToleranceDefeatDesc,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.record,
                              "outOfToleranceDefeatDesc",
                              $$v
                            )
                          },
                          expression: "record.outOfToleranceDefeatDesc",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              (_vm.isOperationRecord || _vm.isOperationRepairRecord)
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "是否自检", prop: "isSelfCheck" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.record.isSelfCheck,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "isSelfCheck", $$v)
                            },
                            expression: "record.isSelfCheck",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: false } }, [
                            _vm._v("非自检"),
                          ]),
                          _c("a-radio", { attrs: { value: true } }, [
                            _vm._v("自检"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd &&
              _vm.record.isSelfCheck &&
              (_vm.isOperationRecord || _vm.isOperationRepairRecord)
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "下一加工工序", prop: "nextOperationId" },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "300px" },
                          attrs: {
                            "show-search": "",
                            placeholder: "请选择下一加工工序",
                            "option-filter-prop": "children",
                          },
                          model: {
                            value: _vm.record.nextOperationId,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "nextOperationId", $$v)
                            },
                            expression: "record.nextOperationId",
                          },
                        },
                        _vm._l(_vm.allOperationList, function (operation) {
                          return _c(
                            "a-select-option",
                            {
                              key: operation.id,
                              attrs: { value: operation.id },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(operation.operationName) +
                                  "(" +
                                  _vm._s(operation.operationCode) +
                                  ") "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "交检/检验时间", prop: "generateTime" } },
                [
                  _c("a-date-picker", {
                    attrs: { "show-time": "", disabled: "", type: "date" },
                    model: {
                      value: _vm.record.generateTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "generateTime", $$v)
                      },
                      expression: "record.generateTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "备注", prop: "comment" } },
                [
                  _c("a-input", {
                    attrs: {
                      "max-length": 1024,
                      placeholder: "请输入备注(不超过1000个字)",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.record.comment,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.record,
                          "comment",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "record.comment",
                    },
                  }),
                ],
                1
              ),
              _vm.isCheckRecord && _vm.isAdd
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "当前流转数量" } },
                    [_vm._v(" " + _vm._s(_vm.currentCirculationNum) + " ")]
                  )
                : _vm._e(),
              _vm.isCheckRecord && _vm.isAdd
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "流转数量确认", prop: "circulationNum" },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 10,
                          placeholder: "请输入流转数量",
                        },
                        model: {
                          value: _vm.record.circulationNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "circulationNum", _vm._n($$v))
                          },
                          expression: "record.circulationNum",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCheckRecord || _vm.isCheckRepairRecord
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "下一加工工序", prop: "nextOperationId" },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "300px" },
                          attrs: {
                            "show-search": "",
                            placeholder: "请选择下一加工工序",
                            "option-filter-prop": "children",
                          },
                          model: {
                            value: _vm.record.nextOperationId,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "nextOperationId", $$v)
                            },
                            expression: "record.nextOperationId",
                          },
                        },
                        _vm._l(_vm.allOperationList, function (operation) {
                          return _c(
                            "a-select-option",
                            {
                              key: operation.id,
                              attrs: { value: operation.id },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(operation.operationName) +
                                  "(" +
                                  _vm._s(operation.operationCode) +
                                  ") "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCheckRecord || _vm.isCheckRepairRecord
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "当前瓶颈工序" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "rgb(255, 174, 0)",
                            "font-size": "20px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.bottleneckOperations.join(", ")))]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isCheckRecord && _vm.isAdd
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "是否打印合格证", prop: "certificate" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.record.certificate,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "certificate", $$v)
                            },
                            expression: "record.certificate",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("否"),
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("是"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdd && _vm.record.recordType === 4
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "返工是否结束",
                        prop: "whetherRepairFinished",
                      },
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.record.whetherRepairFinished,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "whetherRepairFinished", $$v)
                            },
                            expression: "record.whetherRepairFinished",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: false } }, [
                            _vm._v("继续返修"),
                          ]),
                          _c("a-radio", { attrs: { value: true } }, [
                            _vm._v("结束返修"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "请选择要打印的单据？",
            "ok-text": "打印完成",
            "cancel-text": "暂不打印",
          },
          on: { ok: _vm.handleCardConfirm, cancel: _vm.handleCardCancel },
          model: {
            value: _vm.printVisible,
            callback: function ($$v) {
              _vm.printVisible = $$v
            },
            expression: "printVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "card-list" },
            [
              _vm.record.repairedCount > 0
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleJump("rework")
                        },
                      },
                    },
                    [_vm._v("不合格品返工处置通知单")]
                  )
                : _vm._e(),
              _vm.record.outOfToleranceCount > 0
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleJump("overTolerance")
                        },
                      },
                    },
                    [_vm._v("不合格品超差处置单")]
                  )
                : _vm._e(),
              _vm.record.abandonCount > 0
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleJump("scrap")
                        },
                      },
                    },
                    [_vm._v("产品报废单")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.assembleRecordEditVisible
        ? _c("RecordAssembleEdit", {
            attrs: { "assemble-form": _vm.assembleForm },
            on: {
              cancel: _vm.handleRecordAssembleModalCanceled,
              confirm: _vm.handleRecordAssembleModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.taskFinishModalVisible
        ? _c("RecordTaskFinishModal", {
            attrs: {
              "task-finish-user-id": _vm.taskFinishUserId,
              "task-finish-plan-id": _vm.taskFinishPlanId,
            },
            on: {
              cancel: _vm.finishPartsTasksCancel,
              confirm: _vm.finishPartsTasksConfirm,
              ignore: _vm.finishPartsTaskIgnore,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }