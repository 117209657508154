<template>
  <div class="wrap">
    <div id="craft-card-print">
      <div
        v-for="(card, index) of cardBodyMessage.craftProcessRouteListDTO"
        :key="index"
        class="card-container"
        style="page-break-after: always"
      >
        <div class="card-header-title">
          <div class="card">
            <img class="img" src="@/assets/logo.png" alt />
            <div class="card-title">工艺卡片模板</div>
          </div>
          <div
            v-if="cardHeaderMessage.operationCardBaseDTO.craftCode"
            class="code"
          >
            <vue-qrcode
              margin="0"
              scale="2"
              :value="cardHeaderMessage.operationCardBaseDTO.craftCode"
            />
          </div>
          <div v-else class="code" />
          <div class="info">
            <div class="unit">单位</div>
            <div class="name">重庆市DBR刀具有限公司</div>
          </div>
        </div>
        <div class="card-header-production">
          <div class="product-item">
            <div class="desc">
              <p>产品名称</p>
            </div>
            <div class="cell">
              <textarea
                v-model="cardHeaderMessage.craftProductDTO.craftProductName"
                :disabled="ifCheck"
                requierd
                maxlength="16"
                cols="10"
                :style="{ 'line-height': 1.2 * pageUnit + 'px' }"
              />
            </div>
          </div>
          <div class="product-item">
            <div class="desc">
              <p>产品图号</p>
            </div>
            <div class="cell">
              <textarea
                v-model="cardHeaderMessage.craftProductDTO.craftDrawingNum"
                :disabled="ifCheck"
                maxlength="35"
                rows="2"
                cols="10"
                :style="{ 'line-height': 1.2 * pageUnit + 'px' }"
              />
            </div>
          </div>
          <div class="product-item">
            <div class="desc">
              <p>产品规格</p>
            </div>
            <div class="cell">
              <textarea
                v-model="cardHeaderMessage.craftProductDTO.craftSpecification"
                :disabled="ifCheck"
                maxlength="25"
                rows="2"
                cols="10"
                :style="{ 'line-height': 1.2 * pageUnit + 'px' }"
              />
            </div>
          </div>
          <div class="product-item">
            <div class="desc">
              <p>模板编号</p>
            </div>
            <div class="cell">
              <textarea
                v-model="cardHeaderMessage.operationCardBaseDTO.craftCode"
                :disabled="ifCheck"
                maxlength="10"
                rows="2"
                cols="10"
                :style="{ 'line-height': 1.2 * pageUnit + 'px' }"
              />
            </div>
          </div>
          <div class="product-item">
            <div class="desc">
              <p>计划号</p>
            </div>
            <div class="cell">
              <textarea
                :disabled="true"
                maxlength="46"
                rows="2"
                cols="10"
                :style="{ 'line-height': 1.2 * pageUnit + 'px' }"
              >
								 ——
							</textarea
              >
            </div>
          </div>
        </div>
        <div class="card-header-material">
          <div class="material-item">
            <div class="desc">
              <p>材料</p>
            </div>
            <div class="cell">
              <!-- <textarea
                v-model="card.materialName"
                rows="2"
                cols="10"
                :style="{'line-height': card.materialName.length > 9 ? 0.6 * pageUnit + 'px' : 1.2 * pageUnit + 'px' }"
              />-->
              <a-select
                :disabled="ifCheck"
                class="materialSelect"
                :value="cardHeaderMessage.craftMaterialDTO.materialName"
                show-search
                :default-active-first-option="false"
                style="width: 96%; margin-left: 5px; margin-top: 3px"
                placeholder="请输入材料名"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="fetchMaterialByName"
                @change="
                  (value, option) =>
                    handleMaterialNameChange(value, option, index)
                "
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option
                  v-for="material in materials"
                  :key="material.id"
                  >{{ material.materialName }}({{
                    material.materialCode
                  }})</a-select-option
                >
              </a-select>
            </div>
          </div>
          <div class="material-item">
            <div class="desc">
              <p>材料牌号</p>
            </div>
            <div class="cell">
              <a-select
                :disabled="ifCheck"
                class="materialSelect"
                :value="cardHeaderMessage.craftMaterialDTO.materialCode"
                show-search
                :default-active-first-option="false"
                style="
                  width: 96%;
                  margin-left: 5px;
                  margin-top: 3px;
                  border: none;
                "
                placeholder="请输入材料图号"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="fetchMaterialByCode"
                @change="
                  (value, option) =>
                    handleMaterialNameChange(value, option, index)
                "
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option
                  v-for="material in materials"
                  :key="material.id"
                  >{{ material.materialName }}({{
                    material.materialCode
                  }})</a-select-option
                >
              </a-select>
            </div>
          </div>
          <div class="material-item">
            <div class="desc">
              <p>硬度</p>
            </div>
            <div class="cell">
              <!-- <textarea
                v-model="cardHeaderMessage.craftProductDTO.operationHardness"
                :disabled="ifCheck"
                rows="2"
                cols="10"
                maxlength="10"
                :style="{'line-height': 1.2 * pageUnit + 'px' }"
              />-->
              <a-select
                v-model="cardHeaderMessage.craftProductDTO.operationHardness"
                :show-arrow="false"
                :allow-clear="true"
                class="materialSelect"
                style="width: 96%; margin-left: 5px; margin-top: 3px"
                :disabled="ifCheck"
                show-search
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="fetchHardness"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option
                  v-for="hardness in hardnessList"
                  :key="hardness.parentParameterId"
                  :value="hardness.parameterValue"
                  >{{ hardness.parameterValue }}</a-select-option
                >
                <!-- <a-select-option
                  v-for=" hardness in hardnessList"
                  :key="hardness.parentParameterId"
                  :value="hardness.parameterValue"
                >{{ hardness.parameterValue }}</a-select-option>-->
              </a-select>
            </div>
          </div>
          <div class="material-item">
            <div class="desc">
              <p>版本号</p>
            </div>
            <div class="cell">
              <a-select
                value="null"
                class="materialSelect userSelect versionSelect"
                :disabled="true"
                style="width: 100%; text-align: center; border: none"
                option-filter-prop="children"
              >
                <a-select-option value="null">—</a-select-option>
              </a-select>
              <!-- <textarea
                :disabled="ifCheck"
                v-model="cardHeaderMessage.operationCardBaseDTO.operationCardVersion"
                rows="2"
                cols="10"
                :style="{'line-height': cardHeaderMessage.operationCardBaseDTO.operationCardVersion.length > 9 ? 0.6 * pageUnit + 'px' : 1.2 * pageUnit + 'px' }"
              />-->
            </div>
          </div>
          <div class="material-item">
            <div class="desc">
              <p>备注</p>
            </div>
            <div class="cell">
              <textarea
                v-model="cardHeaderMessage.operationCardBaseDTO.comment"
                :disabled="ifCheck"
                maxlength="20"
                rows="2"
                cols="10"
              />
            </div>
          </div>
        </div>
        <div
          id="processRoute"
          :ref="processRoute + index"
          class="card-header-processRoute"
        >
          <div class="card-header-processRoute-header">
            <div class="num">工序号</div>
            <div class="type">工种</div>
            <div class="content">
              <template v-if="!ifPrint && !ifCheck">
                <c-upload
                  name="file"
                  accept=".xlsx"
                  :multiple="true"
                  :show-upload-list="false"
                  :action="`${baseURL}crafts/operation-cards/process-route/imports`"
                  @change="handleProcessFileChange"
                >
                  <a-button slot="content">
                    <a-icon type="upload" />导入路线
                  </a-button>
                </c-upload>
              </template>
              工序名称及内容
              <template
                v-if="
                  index + 1 === cardBodyMessage.craftProcessRouteListDTO.length
                "
              >
                <a-icon
                  v-if="!ifPrint && !ifCheck"
                  type="plus-circle"
                  theme="twoTone"
                  @click="handleAddCraft(index)"
                />
              </template>
            </div>
            <div class="time-info">
              <div class="all-time">
                <div class="desc">
                  <p>总加工工时</p>
                </div>
                <div class="data">
                  <p>
                    {{
                      cardHeaderMessage.operationCardBaseDTO.totalProcessHour
                    }}
                  </p>
                </div>
              </div>
              <div class="stand-time">
                <div class="ot">
                  <p>核定加工工时</p>
                </div>
                <div class="date">
                  <p>核定转出日期</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header-processRoute-content">
            <table :ref="'processRouteContent' + index">
              <tbody>
                <tr
                  v-for="(route, routeIndex) of card.processRoute"
                  :key="route.id"
                  class="process"
                >
                  <td class="num">
                    <template v-if="route.sequenceNum % 2 !== 0 && !ifCheck">
                      <a-dropdown>
                        <a-menu slot="overlay" class="ant-dropdown-link">
                          <a-menu-item @click="handleDeleteCraft(route, index)"
                            >删除工序</a-menu-item
                          >
                          <a-menu-item @click="handleEditCraft(route, index)"
                            >编辑工序</a-menu-item
                          >
                          <a-menu-item
                            @click="handleInsertCraft(route, routeIndex, index)"
                            >插入工序</a-menu-item
                          >
                          <a-menu-item
                            @click="handleCopyCraft(route, routeIndex, index)"
                            >复制工序</a-menu-item
                          >
                          <a-menu-item
                            v-if="ifcopyStatus"
                            @click="handlePasteCraft(route, routeIndex, index)"
                            >粘贴工序</a-menu-item
                          >
                        </a-menu>
                        <a style="color: #1890ff">{{ route.sequenceNum }}</a>
                      </a-dropdown>
                    </template>
                    <template v-else>{{ route.sequenceNum }}</template>
                  </td>
                  <td class="type">{{ route.operationName }}</td>
                  <td class="content">{{ route.content }}</td>
                  <td class="hour">{{ route.verifyProcessHour }}</td>
                  <td class="date">{{ route.verifyTransferOutTime }}</td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody
                v-for="(item, newindex) of new Array(card.nullProcessRouteNum)"
                :key="newindex"
              >
                <tr class="process null">
                  <td class="num" />
                  <td class="type" />
                  <td class="content" />
                  <td class="hour" />
                  <td class="date" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-header-bottom">
          <div class="row">
            <div class="column-1 desc" />
            <div class="column-2 desc" />
            <div class="column-3 desc" />
            <div class="column-4 desc" />
            <div class="column-5 desc" />
            <div class="column-6 desc" />
            <div class="column-7 desc" />
            <div class="column-8 desc" />
            <div class="column-9 desc" />
            <div class="column-10 desc" />
          </div>
          <div class="row">
            <div class="column-1 desc" />
            <div class="column-2 desc" />
            <div class="column-3 desc" />
            <div class="column-4 desc" />
            <div class="column-5 desc" />
            <div class="column-6 desc" />
            <div class="column-7 desc" />
            <div class="column-8 desc" />
            <div class="column-9 desc" />
            <div class="column-10 desc" />
          </div>
          <div class="row">
            <div class="column-1 desc">标记</div>
            <div class="column-2 desc">处数</div>
            <div class="column-3 desc">更改单号</div>
            <div class="column-4 desc">签名</div>
            <div class="column-5 desc">日期</div>
            <div class="column-6 desc">标记</div>
            <div class="column-7 desc">处数</div>
            <div class="column-8 desc">更改单号</div>
            <div class="column-9 desc">签名</div>
            <div class="column-10 desc">日期</div>
          </div>
          <div class="row">
            <div
              class="column-1 desc"
              style="border-bottom: none; border-right: none"
            />
            <div
              class="column-2 desc"
              style="border-left: none; border-bottom: none"
            />
            <div class="column-3 desc">编制</div>
            <div class="column-4 desc">
              <a-select
                v-model="cardFooterMessage.craftDesignDTO.designerId"
                class="materialSelect userSelect"
                :disabled="ifCheck"
                style="
                  width: 96%;
                  height: 98%;
                  margin-left: 2px;
                  text-align: center;
                  border: none;
                "
                show-search
                option-filter-prop="children"
                :filter-option="filterUser"
              >
                <a-select-option
                  v-for="user in userList"
                  :key="user.id"
                  :value="user.id"
                  >{{ user.realName }}</a-select-option
                >
              </a-select>
            </div>
            <div class="column-5 desc">
              <a-date-picker
                v-model="cardFooterMessage.craftDesignDTO.designTime"
                style="
                  width: 96%;
                  height: 98%;
                  margin-left: 2px;
                  text-align: center;
                  border: none;
                "
                :disabled="ifCheck"
                placeholder
                value-format="YYYY-MM-DD HH:mm:ss"
              >
                <span slot="suffixIcon" />
              </a-date-picker>
            </div>
            <div
              class="column-6 desc"
              v-html="'标&#8197;&#8197;准&#8197;&#8197;化'"
            />
            <div class="column-7 desc">
              <a-select
                class="materialSelect userSelect"
                :disabled="true"
                style="
                  width: 96%;
                  height: 98%;
                  margin-left: 2px;
                  text-align: center;
                  border: none;
                "
                show-search
                option-filter-prop="children"
                :filter-option="filterUser"
              >
                <a-select-option
                  v-for="user in userList"
                  :key="user.id"
                  :value="user.id"
                  >{{ user.realName }}</a-select-option
                >
              </a-select>
            </div>
            <div class="column-8 desc">
              <a-date-picker
                :disabled="true"
                placeholder
                value-format="YYYY-MM-DD HH:mm:ss"
              >
                <span slot="suffixIcon" />
              </a-date-picker>
            </div>
            <div class="column-combine desc">阶段标记</div>
          </div>
          <div class="row">
            <div class="column-1 desc" style="border-bottom: none" />
            <div
              class="column-2 desc"
              style="border-left: none; border-bottom: none"
            />
            <div class="column-3 desc">校对</div>
            <div class="column-4 desc">
              <a-select
                class="materialSelect userSelect"
                :disabled="true"
                style="
                  width: 96%;
                  height: 98%;
                  margin-left: 2px;
                  text-align: center;
                  border: none;
                "
                show-search
                option-filter-prop="children"
                :filter-option="filterUser"
              >
                <a-select-option
                  v-for="user in userList"
                  :key="user.id"
                  :value="user.id"
                  >{{ user.realName }}</a-select-option
                >
              </a-select>
            </div>
            <div class="column-5 desc">
              <a-date-picker
                :disabled="true"
                style="
                  width: 96%;
                  height: 98%;
                  margin-left: 2px;
                  text-align: center;
                  border: none;
                "
                placeholder
                value-format="YYYY-MM-DD HH:mm:ss"
              >
                <span slot="suffixIcon" />
              </a-date-picker>
            </div>
            <div class="column-6 desc">质量会签</div>
            <div class="column-7 desc">
              <a-select
                class="materialSelect userSelect"
                :disabled="true"
                style="
                  width: 96%;
                  height: 98%;
                  margin-left: 2px;
                  text-align: center;
                  border: none;
                "
                show-search
                option-filter-prop="children"
                :filter-option="filterUser"
              >
                <a-select-option
                  v-for="user in userList"
                  :key="user.id"
                  :value="user.id"
                  >{{ user.realName }}</a-select-option
                >
              </a-select>
            </div>
            <div class="column-8 desc">
              <a-date-picker
                :disabled="true"
                placeholder
                value-format="YYYY-MM-DD HH:mm:ss"
              >
                <span slot="suffixIcon" />
              </a-date-picker>
            </div>
            <div class="column-9 desc">
              <div class="left">S</div>
              <div class="right" />
            </div>
            <div class="column-10 desc">
              <div class="left" />
              <div class="right" />
            </div>
          </div>
          <div class="row">
            <div class="column-1 desc" />
            <div class="column-2 desc" style="border-left: none" />
            <div class="column-3 desc">审核</div>
            <div class="column-4 desc">
              <a-select
                class="materialSelect userSelect"
                :disabled="true"
                style="width: 100%; text-align: center; border: none"
                show-search
                option-filter-prop="children"
                :filter-option="filterUser"
              >
                <a-select-option
                  v-for="user in userList"
                  :key="user.id"
                  :value="user.id"
                  >{{ user.realName }}</a-select-option
                >
              </a-select>
            </div>
            <div class="column-5 desc">
              <a-date-picker
                :disabled="true"
                style="
                  width: 96%;
                  margin-left: 2px;
                  text-align: center;
                  border: none;
                "
                placeholder
                value-format="YYYY-MM-DD HH:mm:ss"
              >
                <span slot="suffixIcon" />
              </a-date-picker>
            </div>
            <div class="column-6 desc" v-html="'批&#12288;&#12288;准'" />
            <div class="column-7 desc">
              <a-select
                class="materialSelect userSelect"
                :disabled="true"
                style="
                  width: 96%;
                  margin-left: 2px;
                  text-align: center;
                  border: none;
                "
                show-search
                option-filter-prop="children"
                :filter-option="filterUser"
              >
                <a-select-option
                  v-for="user in userList"
                  :key="user.id"
                  :value="user.id"
                  >{{ user.realName }}</a-select-option
                >
              </a-select>
            </div>
            <div class="column-8 desc">
              <a-date-picker
                :disabled="true"
                style="
                  width: 96%;
                  height: 98%;
                  margin-left: 2px;
                  text-align: center;
                  border: none;
                "
                placeholder
                value-format="YYYY-MM-DD HH:mm:ss"
              >
                <span slot="suffixIcon" />
              </a-date-picker>
            </div>
            <div class="column-9 desc">
              共 {{ cardHeaderMessage.operationCardBaseDTO.totalPages }} 页
            </div>
            <div class="column-10 desc">第 {{ index + 1 }} 页</div>
          </div>
        </div>
      </div>
      <div v-if="!ifPrint" class="card-footer">
        <a-popconfirm
          title="是否确认提交数据？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="handleSaveCard"
        >
          <a-button v-if="!ifCheck" :loading="saveLoading">保存</a-button>
        </a-popconfirm>
        <a-popconfirm
          title="是否另存为一个新模版？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="handleSaveOtherCard"
        >
          <a-button style="margin-left: 100px;" v-if="!ifCheck" :loading="saveOtherTemplateLoading"
            >另存为</a-button
          >
        </a-popconfirm>
        <!-- <a-button style="margin-left: 100px;" @click="handleAddCard">增加卡片</a-button> -->
        <a-button
          v-print="'craft-card-print'"
          style="margin-left: 100px"
          type="primary"
          @click="handlePrint"
          >打印</a-button
        >
      </div>
    </div>

    <div v-if="uploadLoading" class="page-loading">
      <a-icon type="loading" />
    </div>
    <div class="symbols" v-if="ifAdd || ifUpdate">
      <a-card title="特殊字符" :bordered="true" style="width: 300px">
        <p>{{ craftSymbols }}</p>
      </a-card>
    </div>
    <CraftProcessEditModal
      v-if="editModalVisible"
      :modal-type="modalType"
      :craft-form="editProcessRoute"
      @confirm="handleConfirmCraftModal"
      @cancel="handleCloseCraftModal"
    />
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import CraftProcessEditModal from '@/components/CraftProcessEditModal'
import * as R from 'ramda'
import { addTemplates, updateTemplate, getTemplateById } from '@/api/template'
import {
  getMaterialByMaterialName,
  getMaterialByMaterialCode,
} from '@/api/material'
import {
  getCraftSymbols
} from '@/api/craft'
import { getAllUsersWithDeleted } from '@/api/user'
import { getAllHardnessByParentCode } from '@/api/parameter'
import debounce from 'lodash/debounce'

export default {
  components: {
    VueQrcode,
    CraftProcessEditModal,
  },
  data() {
    this.lastFetchId = 0
    this.fetchMaterialByName = debounce(this.fetchMaterialByName, 800)
    this.fetchMaterialByCode = debounce(this.fetchMaterialByCode, 800)
    this.fetchHardness = debounce(this.fetchHardness, 800)

    return {
      pageUnit: 54,
      userList: [],
      hardnessList: [],
      versionList: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
      ],
      cardData: [],
      cardHeaderMessage: {},
      cardFooterMessage: {},
      cardBodyMessage: {},
      ifCheck: false, // 是否查看状态
      ifAdd: false, // 是否添加状态
      ifUpdate: false, // 是否更新状态
      handleCardIds: '',
      handleType: '',
      fetching: false, // 材料搜索
      materials: {},
      ifPrint: false,
      cardType: 1, // 1为主卡片 0为分卡片

      editModalVisible: false,
      modalType: '', // 工艺编辑类型
      ifEditLastRouteForPage: false, // 是否编辑本页最后一个非检测工序
      insertIndex: Number, // 工艺插入的位置
      deleteIndex: Number, // 工艺删除的位置
      beforeEditHeight: '',
      editProcessRoute: {},

      nullProcessRouteNum: 0,
      saveLoading: false,
      ifcopyStatus: false, // 是否复制了工序
      copyProcessRoute: {},

      baseURL: '',
      uploadLoading: false,
      saveOtherTemplateLoading: false,
      craftSymbols: ''
    }
  },
  async mounted() {
    this.baseURL = process.env.VUE_APP_BASE_API
    await this.getCraftData()
    this.getAllUsersWithDeleted()
    this.initNullColumn()
    this.handleGetCraftSymbols()
  },
  methods: {
    handleGetCraftSymbols() {
      getCraftSymbols().then((res) => {
        // console.log(res)
        this.craftSymbols = res.data.parameterValue
      })
    },
    getCraftData() {
      return new Promise((resolve, reject) => {
        const { type, cardType } = this.$route.query
        this.handleType = type

        if (type === 'add') {
          this.ifAdd = true
          const data = {
            operationCardHeaderDTO: {
              craftProductDTO: {
                craftProductName: '',
                craftDrawingNum: '',
                craftSpecification: '',
                operationHardness: '',
              },
              craftMaterialDTO: {
                materialId: '',
                materialName: '',
                materialCode: '',
              },
              craftPlanDTO: {
                planId: -1,
                parentPlanId: '',
                planCode: '',
                isParts: '',
              },
              operationCardBaseDTO: {
                craftId: '',
                craftCode: '',
                operationCardVersion: '',
                totalPages: '',
                operationCardType: cardType,
                operationCardStatus: '',
              },
            },
            operationCardBodyDTO: {
              craftProcessRouteListDTO: [
                {
                  pageSeq: 1,
                  processRoute: [],
                },
              ],
            },
            operationCardFooterDTO: {
              craftDesignDTO: {
                designerId: '',
                designTime: this.$moment(new Date()).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                designer: '',
              },
              craftProofreadDTO: {
                collatorId: '',
                proofreadTime: '',
                collator: '',
              },
              craftAuditDTO: {
                auditorId: '',
                auditTime: '',
                auditor: '',
              },
              craftStandardDTO: {
                standardId: '',
                standardTime: '',
                standarder: '',
              },
              craftQualityDTO: {
                qualityId: '',
                qualityTime: '',
                qualitier: '',
              },
              craftApproveDTO: {
                approverId: '',
                approveTime: '',
                approver: '',
              },
              phaseTag: 'S',
            },
          }
          this.cardHeaderMessage = data.operationCardHeaderDTO
          this.cardFooterMessage = data.operationCardFooterDTO
          this.cardBodyMessage = data.operationCardBodyDTO
          resolve()
        }
        if (type === 'edit') {
          this.ifUpdate = true
          getTemplateById(this.$route.query.ids).then((res) => {
            this.cardHeaderMessage = res.data.operationCardHeaderDTO
            this.cardFooterMessage = res.data.operationCardFooterDTO
            this.cardBodyMessage = res.data.operationCardBodyDTO
            if (!this.cardBodyMessage.craftProcessRouteListDTO.length) {
              this.cardBodyMessage.craftProcessRouteListDTO.push({
                pageSeq: 1,
                processRoute: [],
              })
            }
            resolve()
          })
        }
        if (type === 'check') {
          this.ifCheck = true
          getTemplateById(this.$route.query.ids).then((res) => {
            this.cardHeaderMessage = res.data.operationCardHeaderDTO
            this.cardFooterMessage = res.data.operationCardFooterDTO
            this.cardBodyMessage = res.data.operationCardBodyDTO
            if (!this.cardBodyMessage.craftProcessRouteListDTO.length) {
              this.cardBodyMessage.craftProcessRouteListDTO.push({
                pageSeq: 1,
                processRoute: [],
              })
            }
            resolve()
          })
        }
      })
    },
    getAllUsersWithDeleted() {
      getAllUsersWithDeleted({
        'role.name': 'like:工艺',
      }).then((res) => {
        if (res.data && res.data.accountList) {
          this.userList = R.clone(res.data.accountList)
        }
      })
    },
    filterUser(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    fetchMaterialByName(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      getMaterialByMaterialName(value).then((result) => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.materials = result.data
        this.fetching = false
      })
    },
    fetchMaterialByCode(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      getMaterialByMaterialCode(value).then((result) => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.materials = result.data
        this.fetching = false
      })
    },
    fetchHardness(value) {
      console.log(value)
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.hardnessList = []
      this.hardnessList.push({
        parameterValue: value,
      })
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.fetching = true
      getAllHardnessByParentCode('RT_GYYD', value).then((result) => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.hardnessList.push(...result.data)
        this.fetching = false
      })
    },
    handleMaterialNameChange(id, option, index) {
      this.materials.map((val) => {
        if (val.id === id) {
          console.log(val)
          this.cardHeaderMessage.craftMaterialDTO.materialName =
            val.materialName
          this.cardHeaderMessage.craftMaterialDTO.materialCode =
            val.materialCode
          this.cardHeaderMessage.craftMaterialDTO.materialId = val.id
        }
      })
      Object.assign(this, {
        materials: [],
        fetching: false,
      })
    },
    handleAddCraft(cardDataIndex) {
      this.modalType = 'add'
      this.currentProcessIndex = cardDataIndex
      this.editModalVisible = true
    },
    handleProcessRouteSeq() {
      let _seq = 0
      this.cardBodyMessage.craftProcessRouteListDTO.map((routePage) => {
        routePage.processRoute.map((route) => {
          _seq += 1
          route.sequenceNum = _seq
        })
      })
    },
    handleConfirmCraftModal(data) {
      console.log(data)
      const that = this
      if (this.modalType === 'add' || this.modalType === 'insert') {
        const _process = {
          craftId:
            this.cardBodyMessage.craftProcessRouteListDTO[
              this.currentProcessIndex
            ].craftId,
          operationName: data.operationLabel,
          operationId: data.operationId,
          content: data.content,
        }
        const _testProcess = {
          craftId:
            this.cardBodyMessage.craftProcessRouteListDTO[
              this.currentProcessIndex
            ].craftId,
          operationName: data.testLabel,
          operationId: data.testName,
          content: data.testDesc,
        }
        if (this.modalType === 'add') {
          this.cardBodyMessage.craftProcessRouteListDTO[
            this.currentProcessIndex
          ].processRoute.push(_process, _testProcess)
          this.handleProcessRouteSeq()
          setTimeout(() => {
            that.watchBodyHeightForAdd(that.currentProcessIndex)
            that.handlePageSeqForProcess()
          }, 0)
        } else {
          this.cardBodyMessage.craftProcessRouteListDTO[
            this.currentProcessIndex
          ].processRoute.splice(this.insertIndex, 0, _process, _testProcess)
          setTimeout(() => {
            that.watchBodyHeightForInsert(
              that.currentProcessIndex,
              that.insertIndex
            )
            that.handlePageSeqForProcess()
          }, 0)
          this.handleProcessRouteSeq()
        }
        // this.cardBodyMessage.craftProcessRouteListDTO[this.currentProcessIndex].processRoute.map(
        //   (val, index) => {
        //     val.sequenceNum = index + 1
        //   }
        // )
      } else {
        this.cardBodyMessage.craftProcessRouteListDTO[
          this.currentProcessIndex
        ].processRoute.map((val, index, arr) => {
          if (val.sequenceNum === data.sequenceNum) {
            this.cardBodyMessage.craftProcessRouteListDTO[
              this.currentProcessIndex
            ].processRoute[index].operationName = data.operationLabel
            this.cardBodyMessage.craftProcessRouteListDTO[
              this.currentProcessIndex
            ].processRoute[index].operationId = data.operationId
            this.cardBodyMessage.craftProcessRouteListDTO[
              this.currentProcessIndex
            ].processRoute[index].content = data.content
            if (this.ifEditLastRouteForPage) {
              this.cardBodyMessage.craftProcessRouteListDTO[
                this.currentProcessIndex + 1
              ].processRoute[0].content = data.testDesc
            } else {
              this.cardBodyMessage.craftProcessRouteListDTO[
                this.currentProcessIndex
              ].processRoute[index + 1].content = data.testDesc
            }
            delete this.cardBodyMessage.craftProcessRouteListDTO[
              this.currentProcessIndex
            ].processRoute[index].testDesc
          }
        })
        setTimeout(() => {
          this.watchBodyHeightForEdit(this.currentProcessIndex)
          this.handlePageSeqForProcess()
        }, 0)
      }
      this.editModalVisible = false
    },
    handleEditCraft(column, cardDataIndex) {
      this.ifEditLastRouteForPage = false
      this.modalType = 'edit'
      this.currentProcessIndex = cardDataIndex
      this.cardBodyMessage.craftProcessRouteListDTO[
        this.currentProcessIndex
      ].processRoute.map((val, index, arr) => {
        if (val.sequenceNum === column.sequenceNum) {
          this.editProcessRoute = column
          //  判断编辑的工序是否本页的最后一个非检测工序
          if (index === arr.length - 1) {
            this.ifEditLastRouteForPage = true
            this.editProcessRoute.testDesc =
              this.cardBodyMessage.craftProcessRouteListDTO[
                this.currentProcessIndex + 1
              ].processRoute[0].content
          } else {
            this.editProcessRoute.testDesc = arr[index + 1].content
          }
        }
      })
      // 没编辑之前的高度
      this.beforeEditHeight =
        this.$refs[
          `processRouteContent${this.currentProcessIndex}`
        ][0].offsetHeight
      this.editModalVisible = true
    },
    handleInsertCraft(column, columnIndex, cardDataIndex) {
      this.modalType = 'insert'
      this.editProcessRoute = {}
      this.currentProcessIndex = cardDataIndex
      // 判断插入时 是否为本页最后一个非检测工序
      if (
        column.sequenceNum % 2 !== 0 &&
        columnIndex ===
          this.cardBodyMessage.craftProcessRouteListDTO[
            this.currentProcessIndex
          ].processRoute.length -
            1
      ) {
        this.insertIndex = 1
        this.currentProcessIndex++
      } else {
        this.insertIndex = column.sequenceNum + 1
      }
      this.editModalVisible = true
    },
    // 复制工序
    handleCopyCraft(column, columnIndex, cardDataIndex) {
      const testProcessRoute =
        this.cardBodyMessage.craftProcessRouteListDTO[cardDataIndex]
          .processRoute[columnIndex + 1]
      const _process = {
        craftId: column.craftId,
        operationName: column.operationName,
        operationId: column.operationId,
        content: column.content,
      }
      const _testProcess = {
        craftId: column.craftId,
        operationName: testProcessRoute.operationName,
        operationId: testProcessRoute.operationId,
        content: testProcessRoute.content,
      }
      this.copyProcessRoute = {
        process: _process,
        testProcess: _testProcess,
      }
      this.ifcopyStatus = true
      this.$message.success('复制工序成功')
    },

    // 粘贴工序
    handlePasteCraft(column, columnIndex, cardDataIndex) {
      this.currentProcessIndex = cardDataIndex
      if (
        column.sequenceNum % 2 !== 0 &&
        columnIndex ===
          this.cardBodyMessage.craftProcessRouteListDTO[
            this.currentProcessIndex
          ].processRoute.length -
            1
      ) {
        this.insertIndex = 1
        this.currentProcessIndex++
      } else {
        this.insertIndex = column.sequenceNum + 1
      }

      const clone = Object.assign({}, this.copyProcessRoute)
      // const handleProcessRoute = this.copyProcessRoute.process
      // const handleProcessTestRoute = this.copyProcessRoute.testProcess
      this.cardBodyMessage.craftProcessRouteListDTO[
        this.currentProcessIndex
      ].processRoute.splice(
        this.insertIndex,
        0,
        {
          ...clone.process,
        },
        {
          ...clone.testProcess,
        }
      )
      setTimeout(() => {
        this.watchBodyHeightForInsert(cardDataIndex, columnIndex)
        this.handlePageSeqForProcess()
      }, 0)
      this.handleProcessRouteSeq()
      this.$message.success('粘贴工序成功')
    },
    handleCloseCraftModal() {
      this.editModalVisible = false
    },
    handleDeleteCraft(column, cardDataIndex) {
      this.currentProcessIndex = cardDataIndex
      this.cardBodyMessage.craftProcessRouteListDTO[
        this.currentProcessIndex
      ].processRoute.map((val, index, array) => {
        if (val.sequenceNum === column.sequenceNum) {
          if (index === array.length - 1) {
            // 如果恰好是本页最后一个工序 则需要跳到下一页删除检测工序
            this.cardBodyMessage.craftProcessRouteListDTO[
              this.currentProcessIndex
            ].processRoute.pop()
            this.cardBodyMessage.craftProcessRouteListDTO[
              this.currentProcessIndex + 1
            ].processRoute.shift()
          } else {
            this.cardBodyMessage.craftProcessRouteListDTO[
              this.currentProcessIndex
            ].processRoute.splice(index, 2)
          }
        }
      })
      this.handleProcessRouteSeq()
      // 更新空行的高度
      setTimeout(() => {
        const height =
          this.$refs[`processRouteContent${cardDataIndex}`][0].offsetHeight
        this.handleAsyncUpdateNullProcess(cardDataIndex, height)
        this.watchBodyHeightForDelete(cardDataIndex, column.sequenceNum)
        this.handlePageSeqForProcess()
      }, 0)
    },
    updateProcessRouteIndex(index) {
      this.cardData[this.currentProcessIndex].processRoute.map((val, index) => {
        val.sequenceNum = index + 1
      })
    },
    handlePrint() {
      // 打印前先隐藏部分Dom
      const that = this
      this.ifPrint = true
      setTimeout(() => {
        that.ifPrint = false
      }, 100)
    },
    initNullColumn() {
      this.cardBodyMessage.craftProcessRouteListDTO.map((card, index) => {
        const height = this.$refs[`processRouteContent${index}`][0].offsetHeight
        card.nullProcessRouteNum = Math.floor((930 - height) / 43)
      })
    },
    // 增加时处理
    watchBodyHeightForAdd(index) {
      // 1032是标准高度
      // 每页固定空 2 行
      // 标准长度为1032 - 2 * 43 = 930
      // 填空空行的个数 = 1032 - processRoute  / 43(每个空行的高度)
      const that = this
      const height = this.$refs[`processRouteContent${index}`][0].offsetHeight
      if (height >= 930) {
        // 如果添加的最后一个工序超出单个页面长度 则顺延到下一页
        const lastRoute =
          this.cardBodyMessage.craftProcessRouteListDTO[index].processRoute[
            this.cardBodyMessage.craftProcessRouteListDTO[index].processRoute
              .length - 1
          ]
        this.cardBodyMessage.craftProcessRouteListDTO.push({
          pageSeq: index + 2,
          processRoute: [lastRoute],
        })
        this.cardBodyMessage.craftProcessRouteListDTO[index].processRoute.pop()
        setTimeout(() => {
          // 添加完之后的高度
          const _height =
            that.$refs[`processRouteContent${index}`][0].offsetHeight
          //  如果最后一个工序顺延到下一页之后还是超出页面长度，则顺延倒数第二个工序
          if (_height >= 930) {
            const lastRoute =
              that.cardBodyMessage.craftProcessRouteListDTO[index].processRoute[
                that.cardBodyMessage.craftProcessRouteListDTO[index]
                  .processRoute.length - 1
              ]
            that.cardBodyMessage.craftProcessRouteListDTO[
              index
            ].processRoute.pop()
            that.cardBodyMessage.craftProcessRouteListDTO[
              index + 1
            ].processRoute.unshift(lastRoute)
          }
          setTimeout(() => {
            const _height1 =
              that.$refs[`processRouteContent${index}`][0].offsetHeight
            that.handleAsyncUpdateNullProcess(index, _height1)
            const _height2 =
              that.$refs[`processRouteContent${index + 1}`][0].offsetHeight
            console.log(_height1, _height2)
            that.handleAsyncUpdateNullProcess(index + 1, _height2)
          }, 0)
        }, 0)
      } else {
        const _e = this.cardBodyMessage.craftProcessRouteListDTO[index]
        _e.nullProcessRouteNum = Math.floor((930 - height) / 43)
        this.$set(this.cardBodyMessage.craftProcessRouteListDTO, index, _e)
      }
    },
    // 插入时处理
    watchBodyHeightForInsert(pageIndex) {
      // 遍历循环插入
      const that = this
      for (
        let i = pageIndex;
        i < this.cardBodyMessage.craftProcessRouteListDTO.length;
        i++
      ) {
        setTimeout(() => {
          const height = that.$refs[`processRouteContent${i}`][0].offsetHeight
          if (height >= 930) {
            // 如果添加的最后一个工序超出单个页面长度 则顺延到下一页
            const lastRoute =
              that.cardBodyMessage.craftProcessRouteListDTO[i].processRoute[
                that.cardBodyMessage.craftProcessRouteListDTO[i].processRoute
                  .length - 1
              ]
            // 判断当前是否为最后一页， 如果是最后一页，则增加一页
            if (
              that.cardBodyMessage.craftProcessRouteListDTO.length ===
              i + 1
            ) {
              that.cardBodyMessage.craftProcessRouteListDTO.push({
                pageSeq: i + 2,
                processRoute: [lastRoute],
              })
            } else {
              that.cardBodyMessage.craftProcessRouteListDTO[
                i + 1
              ].processRoute.unshift(lastRoute)
            }
            that.cardBodyMessage.craftProcessRouteListDTO[i].processRoute.pop()

            setTimeout(() => {
              // 添加完之后的高度
              const _height =
                that.$refs[`processRouteContent${i}`][0].offsetHeight
              //  如果最后一个工序顺延到下一页之后还是超出页面长度，则顺延倒数第二个工序
              if (_height >= 930) {
                const lastRoute =
                  that.cardBodyMessage.craftProcessRouteListDTO[i].processRoute[
                    that.cardBodyMessage.craftProcessRouteListDTO[i]
                      .processRoute.length - 1
                  ]
                that.cardBodyMessage.craftProcessRouteListDTO[
                  i
                ].processRoute.pop()
                that.cardBodyMessage.craftProcessRouteListDTO[
                  i + 1
                ].processRoute.unshift(lastRoute)
                setTimeout(() => {
                  const _height1 =
                    that.$refs[`processRouteContent${i}`][0].offsetHeight
                  const _height2 =
                    that.$refs[`processRouteContent${i + 1}`][0].offsetHeight
                  that.handleAsyncUpdateNullProcess(i, _height1)
                  that.handleAsyncUpdateNullProcess(i + 1, _height2)
                }, 0)
              } else {
                setTimeout(() => {
                  that.handleAsyncUpdateNullProcess(i, _height)
                  const _height2 =
                    that.$refs[`processRouteContent${i + 1}`][0].offsetHeight
                  that.handleAsyncUpdateNullProcess(i + 1, _height2)
                }, 0)
              }
            }, 0)
          } else {
            setTimeout(() => {
              that.handleAsyncUpdateNullProcess(i, height)
            }, 0)
            return
          }
        }, 0)
      }
    },
    // 删除时处理
    async watchBodyHeightForDelete(pageIndex) {
      // 判断是否最后一页
      if (
        pageIndex + 1 >=
        this.cardBodyMessage.craftProcessRouteListDTO.length
      ) {
        await this.handleUpdateNullProcess(pageIndex)
        return
      }
      // 遍历循环删除
      const that = this
      for (
        let i = pageIndex + 1;
        i < this.cardBodyMessage.craftProcessRouteListDTO.length;
        i++
      ) {
        // 处理最后一页
        if (
          !that.cardBodyMessage.craftProcessRouteListDTO[i].processRoute.length
        ) {
          return
        }
        for (
          let j = 0;
          j <
          that.cardBodyMessage.craftProcessRouteListDTO[i].processRoute.length;
          j++
        ) {
          const firstRoute =
            that.cardBodyMessage.craftProcessRouteListDTO[i].processRoute[j]
          that.cardBodyMessage.craftProcessRouteListDTO[
            i - 1
          ].processRoute.push(firstRoute)
          that.cardBodyMessage.craftProcessRouteListDTO[i].processRoute.shift()

          const res = await this.handleAsyncDomLoad(i, j, firstRoute)
          await this.handleUpdateNullProcess(i)
          await this.handleUpdateNullProcess(i - 1)
          // 如果当前页面已经装满了 则跳出当轮循环
          if (res) {
            break
            // 反之继续下一轮的循环
          } else {
            j = -1
          }
        }
      }
    },
    // 编辑时处理
    async watchBodyHeightForEdit(pageIndex) {
      const height =
        this.$refs[`processRouteContent${pageIndex}`][0].offsetHeight
      if (height === this.beforeEditHeight) {
        return
      } else if (height > this.beforeEditHeight) {
        // 高度增加了 相当于插入
        this.watchBodyHeightForInsert(pageIndex)
      } else {
        // 高度减少了 相当于删除
        this.watchBodyHeightForDelete(pageIndex)
      }
    },
    handleAsyncUpdateNullProcess(index, height) {
      const _e = this.cardBodyMessage.craftProcessRouteListDTO[index]
      _e.nullProcessRouteNum = Math.floor((930 - height) / 43)
      this.$set(this.cardBodyMessage.craftProcessRouteListDTO, index, _e)
    },
    handleUpdateNullProcess(index) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const height =
            this.$refs[`processRouteContent${index}`][0].offsetHeight
          const _e = this.cardBodyMessage.craftProcessRouteListDTO[index]
          _e.nullProcessRouteNum = Math.floor((930 - height) / 43)
          this.$set(this.cardBodyMessage.craftProcessRouteListDTO, index, _e)
          resolve()
        }, 0)
      })
    },
    handleAsyncDomLoad(i, j, firstRoute) {
      const that = this
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const height =
            that.$refs[`processRouteContent${i - 1}`][0].offsetHeight
          console.log('height', height)
          if (height > 940) {
            that.cardBodyMessage.craftProcessRouteListDTO[
              i - 1
            ].processRoute.pop()
            that.cardBodyMessage.craftProcessRouteListDTO[
              i
            ].processRoute.unshift(firstRoute)
            resolve(true)
          } else {
            resolve(false)
          }
        }, 0)
      })
    },
    // 处理页码及空白页
    handlePageSeqForProcess() {
      setTimeout(() => {
        if (this.cardBodyMessage.craftProcessRouteListDTO.length) {
          this.cardBodyMessage.craftProcessRouteListDTO.map((item, index) => {
            console.log(item.processRoute.length)
            if (!item.processRoute.length && index !== 0) {
              this.cardBodyMessage.craftProcessRouteListDTO.splice(index, 1)
              return
            }
            item.pageSeq = index + 1
          })
        }
        this.cardHeaderMessage.operationCardBaseDTO.totalPages =
          this.cardBodyMessage.craftProcessRouteListDTO.length
      }, 0)
    },

    // 校验数据
    handleCheckData() {
      if (!this.cardHeaderMessage.operationCardBaseDTO.craftCode) {
        this.$message.error('请输入模板编号')
        return false
      }
      if (
        !this.cardBodyMessage.craftProcessRouteListDTO[0].processRoute.length
      ) {
        this.$message.error('请录入工序路线')
        return false
      }
      if (!this.cardFooterMessage.craftDesignDTO.designerId) {
        this.$message.error('请输入编制人')
        return false
      }
      if (!this.cardFooterMessage.craftDesignDTO.designTime) {
        this.$message.error('请输入编制时间')
        return false
      }
      return true
    },
    // 数据保存
    handleSaveCard() {
      const res = this.handleCheckData()
      if (!res) {
        return
      }
      this.saveLoading = true
      const data = {
        operationCardHeaderDTO: this.cardHeaderMessage,
        operationCardBodyDTO: this.cardBodyMessage,
        operationCardFooterDTO: this.cardFooterMessage,
      }
      if (this.handleType === 'add') {
        addTemplates(data)
          .then((res) => {
            if (res.status === 200) {
              this.$message.success('模板新增成功')
              setTimeout(() => {
                window.location.href = 'about:blank'
                window.close()
              }, 1000)
            }
          })
          .finally(() => {
            this.saveLoading = false
          })
      }
      if (this.handleType === 'edit') {
        updateTemplate(this.cardHeaderMessage.operationCardBaseDTO.id, data)
          .then((res) => {
            if (res.status === 200) {
              this.$message.success('模板更新成功')
              setTimeout(() => {
                window.location.href = 'about:blank'
                window.close()
              }, 1000)
            }
          })
          .finally(() => {
            this.saveLoading = false
          })
      }
    },
    handleSaveOtherCard() {
      const res = this.handleCheckData()
      if (!res) {
        return
      }
      this.saveOtherTemplateLoading = true
      const data = {
        operationCardHeaderDTO: this.cardHeaderMessage,
        operationCardBodyDTO: this.cardBodyMessage,
        operationCardFooterDTO: this.cardFooterMessage,
      }
      addTemplates(data)
        .then((res) => {
          if (res.status === 200) {
            this.$message.success('另存为模版成功')
            // setTimeout(() => {
            //   window.location.href = 'about:blank'
            //   window.close()
            // }, 1000)
          }
        })
        .finally(() => {
          this.saveOtherTemplateLoading = false
        })
    },
    // 工艺路线上传
    handleProcessFileChange(info) {
      this.uploadLoading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.$message.success('导入成功')
          this.cardBodyMessage.craftProcessRouteListDTO = [
            {
              pageSeq: 1,
              processRoute: [],
            },
          ]
          this.handleBatchAddProcessRoute(
            info.file.response.processRouteOperationVOList
          )
        }
      } else if (info.file.status === 'error') {
        this.$message.error(info.file.response.apierror.message)
        this.uploadLoading = false
      }
    },
    async handleBatchAddProcessRoute(list) {
      for (let i = 0; i < list.length - 1; i = i + 2) {
        this.currentProcessIndex =
          this.cardBodyMessage.craftProcessRouteListDTO.length - 1
        const process = {
          operationName: list[i].operationName,
          operationId: list[i].operationId,
          content: list[i].content,
        }
        const test = {
          operationName: list[i + 1].operationName,
          operationId: list[i + 1].operationId,
          content: list[i + 1].content,
        }
        this.cardBodyMessage.craftProcessRouteListDTO[
          this.currentProcessIndex
        ].processRoute.push(process, test)
        this.handleProcessRouteSeq()
        await this.watchBatchAdd(this.currentProcessIndex)
      }
      console.log(this.cardBodyMessage.craftProcessRouteListDTO)
      this.cardBodyMessage.craftProcessRouteListDTO.map((val, index) => {
        const height = this.$refs[`processRouteContent${index}`][0].offsetHeight
        val.nullProcessRouteNum = Math.floor((930 - height) / 43)
        this.$set(this.cardBodyMessage.craftProcessRouteListDTO, index, val)
      })
      this.handlePageSeqForProcess()
      this.uploadLoading = false
    },
    // 批量增加时 处理空行和分页
    watchBatchAdd(index) {
      return new Promise((resolve, reject) => {
        const that = this
        setTimeout(() => {
          const height =
            this.$refs[`processRouteContent${index}`][0].offsetHeight
          if (height >= 930) {
            // 如果添加的最后一个工序超出单个页面长度 则顺延到下一页
            const lastRoute =
              this.cardBodyMessage.craftProcessRouteListDTO[index].processRoute[
                this.cardBodyMessage.craftProcessRouteListDTO[index]
                  .processRoute.length - 1
              ]
            console.log('height', lastRoute)
            this.cardBodyMessage.craftProcessRouteListDTO.push({
              pageSeq: index + 2,
              processRoute: [lastRoute],
            })
            this.cardBodyMessage.craftProcessRouteListDTO[
              index
            ].processRoute.pop()
            setTimeout(() => {
              // 添加完之后的高度
              const _height =
                that.$refs[`processRouteContent${index}`][0].offsetHeight
              //  如果最后一个工序顺延到下一页之后还是超出页面长度，则顺延倒数第二个工序
              if (_height >= 930) {
                const lastRoute =
                  that.cardBodyMessage.craftProcessRouteListDTO[index]
                    .processRoute[
                    that.cardBodyMessage.craftProcessRouteListDTO[index]
                      .processRoute.length - 1
                  ]
                that.cardBodyMessage.craftProcessRouteListDTO[
                  index
                ].processRoute.pop()
                that.cardBodyMessage.craftProcessRouteListDTO[
                  index + 1
                ].processRoute.unshift(lastRoute)
              }
              resolve()
            }, 0)
          } else {
            // const _e = this.cardBodyMessage.craftProcessRouteListDTO[index]
            // _e.nullProcessRouteNum = Math.floor((930 - height) / 43)
            // this.$set(this.cardBodyMessage.craftProcessRouteListDTO, index, _e)
            const lastRoute =
              this.cardBodyMessage.craftProcessRouteListDTO[index].processRoute[
                this.cardBodyMessage.craftProcessRouteListDTO[index]
                  .processRoute.length - 1
              ]
            console.log('height', lastRoute, height)
            resolve()
          }
        }, 0)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$unit: 54px;
.card-container {
  width: 19.83 * $unit;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  .card-header-title {
    display: flex;
    .card {
      box-sizing: border-box;
      width: 12.84 * $unit;
      height: 1.6 * $unit;
      border: 1px solid black;
      border-bottom: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      letter-spacing: 30px;
      position: relative;
      .template-btn {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
      }
      .img {
        width: 120px;
        height: 70px;
        background: url(~@/assets/logo.png);
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 20px;
      }
      .card-title {
        position: relative;
        .tag {
          letter-spacing: 2px;
          position: absolute;
          top: 2px;
          right: -25px;
        }
      }
    }
    .code {
      box-sizing: border-box;
      width: 2.38 * $unit;
      height: 1.6 * $unit;
      border: 1px solid black;
      border-left: none;
      border-bottom: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .info {
      box-sizing: border-box;
      width: 4.63 * $unit;
      height: 1.6 * $unit;
      border: 1px solid black;
      border-left: none;
      border-bottom: none;
      display: flex;
      flex-direction: column;
      font-weight: bold;
      font-size: 18px;
      .unit {
        flex: 1;
        box-sizing: border-box;
        text-align: center;
        line-height: 0.8 * $unit;
        border-bottom: 1px solid black;
      }
      .name {
        flex: 1;
        text-align: center;
        line-height: 0.8 * $unit;
      }
    }
  }
  .card-header-production {
    display: flex;
    .product-item {
      .desc {
        box-sizing: border-box;
        width: 4.1 * $unit;
        height: 1.2 * $unit;
        border: 1px solid black;
        border-right: none;
        border-bottom: none;
        p {
          width: 100%;
          text-align: center;
          font-weight: bold;
          font-size: 18px;
          line-height: 1.2 * $unit;
          letter-spacing: 10px;
        }
      }
      .cell {
        box-sizing: border-box;
        width: 4.1 * $unit;
        height: 1.2 * $unit;
        border: 1px solid black;
        border-right: none;
        textarea {
          width: 100%;
          height: 100%;
          background: none;
          text-align: center;
          padding: 0;
          outline-style: none;
          border: none;
          resize: none;
          overflow: hidden;
          font-size: 10px;
          font-weight: bold;
        }
      }
      &:nth-child(1) {
        .desc {
          width: 4.1 * $unit;
        }
        .cell {
          width: 4.1 * $unit;
        }
      }
      &:nth-child(2) {
        .desc {
          width: 6.5 * $unit;
        }
        .cell {
          width: 6.5 * $unit;
        }
      }
      &:nth-child(3) {
        .desc {
          width: 4.6 * $unit;
        }
        .cell {
          width: 4.6 * $unit;
        }
      }
      &:nth-child(4) {
        .desc {
          width: 2.3 * $unit;
        }
        .cell {
          width: 2.3 * $unit;
        }
      }
      &:nth-child(5) {
        border-right: 1px solid black;
        .desc {
          width: 2.3 * $unit;
        }
        .cell {
          width: 2.3 * $unit;
        }
      }
    }
  }
  .card-header-material {
    display: flex;
    .material-item {
      display: flex;
      .desc {
        box-sizing: border-box;
        width: 2.1 * $unit;
        height: 1.2 * $unit;
        border: 1px solid black;
        border-top: none;
        p {
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          line-height: 1.2 * $unit;
        }
      }
      .cell {
        overflow: hidden;
        box-sizing: border-box;
        width: 4.1 * $unit;
        height: 1.2 * $unit;
        border: 1px solid black;
        border-left: none;
        border-right: none;
        border-top: none;

        textarea {
          width: 100%;
          height: 100%;
          background: none;
          text-align: center;
          padding: 0;
          outline-style: none;
          border: none;
          resize: none;
          overflow: hidden;
          font-size: 10px;
          font-weight: bold;
        }
      }
      &:nth-child(1) {
        .desc {
          width: 1.16 * $unit;
        }
        .cell {
          width: 2.94 * $unit;
        }
      }
      &:nth-child(2) {
        .desc {
          width: 2 * $unit;
        }
        .cell {
          width: 4.5 * $unit;
        }
      }
      &:nth-child(3) {
        .desc {
          width: 2 * $unit;
        }
        .cell {
          width: 2.6 * $unit;
        }
      }
      &:nth-child(4) {
        .desc {
          width: 1 * $unit;
        }
        .cell {
          width: 1.31 * $unit;
        }
      }
      &:nth-child(5) {
        border-right: 1px solid black;
        .desc {
          width: 1.2 * $unit;
        }
        .cell {
          width: 1.1 * $unit;
        }
      }
    }
  }
  .card-header-processRoute {
    &-header {
      width: 19.83 * $unit;
      display: flex;
      position: relative;
      font-weight: bold;
      font-size: 18px;
      div {
        box-sizing: border-box;
        text-align: center;
      }
      .num {
        width: 1.13 * $unit;
        height: 1.5 * $unit;
        border: 1px solid black;
        border-top: none;
        border-right: none;
        writing-mode: vertical-rl;
        text-align: center;
        line-height: 1.13 * $unit;
      }
      .type {
        width: 1.3 * $unit;
        height: 1.5 * $unit;
        border: 1px solid black;
        border-top: none;
        border-right: none;
        line-height: 1.5 * $unit;
      }
      .content {
        width: 12.88 * $unit;
        height: 1.5 * $unit;
        border: 1px solid black;
        border-top: none;
        border-right: none;
        line-height: 1.5 * $unit;
        letter-spacing: 15px;
      }
      .time-info {
        width: 4.68 * $unit;
        height: 1.5 * $unit;
        border: 1px solid black;
        border-top: none;
        display: flex;
        flex-direction: column;
        p {
          font-size: 18px;
          margin-bottom: 0px;
        }
        .all-time {
          flex: 1;
          display: flex;
          border-bottom: 1px solid black;
          .desc {
            flex: 1;
            border-right: 1px solid black;
            line-height: 0.8 * $unit;
          }
          .data {
            flex: 1;
            line-height: 0.8 * $unit;
          }
        }
        .stand-time {
          flex: 1;
          display: flex;
          overflow: hidden;
          .ot {
            flex: 1;
            line-height: 0.7 * $unit;
            border-right: 1px solid black;
          }
          .date {
            flex: 1;
            line-height: 0.7 * $unit;
          }
        }
      }
      .operation {
        width: 3 * $unit;
        height: 1.5 * $unit;
        border: 1px solid black;
        border-left: none;
        border-bottom: none;
        // position: absolute;
        // top: -1px;
        // right: -3 * $unit;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &-content {
      table {
        table-layout: fixed !important;
      }
      td {
        text-align: center;
      }

      .process {
        width: 19.83 * $unit;
        min-height: 0.8 * $unit;
        line-height: 0.7 * $unit;
        position: relative;
        font-weight: bold;
        .num {
          width: 1.14 * $unit;
          height: 0.8 * $unit;
          box-sizing: border-box;
          border: 1px solid black;
          border-top: none;
          border-right: none;
          writing-mode: horizontal-tb;
          text-align: center;
        }
        .type {
          width: 1.3 * $unit;
          height: 0.8 * $unit;
          box-sizing: border-box;
          border: 1px solid black;
          border-top: none;
          border-right: none;
        }
        .content {
          width: 12.89 * $unit;
          height: 0.8 * $unit;
          padding-left: 5px;
          box-sizing: border-box;
          white-space: break-word;
          border: 1px solid black;
          border-top: none;
          border-right: none;
          line-height: 17px;
          text-align: left;
          word-wrap: break-word;
          word-break: break-all;
        }
        .hour {
          width: 2.33 * $unit;
          height: 0.8 * $unit;
          box-sizing: border-box;
          border: 1px solid black;
          border-top: none;
        }
        .date {
          font-size: 12px;
          width: 2.32 * $unit;
          height: 0.8 * $unit;
          box-sizing: border-box;
          border: 1px solid black;
          border-top: none;
        }
        .operation {
          width: 3 * $unit;
          height: 0.82 * $unit;
          border: 1px solid black;
          border-left: none;
          // position: absolute;
          // top: -1px;
          // right: -3 * $unit;
        }
      }
      .null {
        .operation {
          border-top: none;
        }
      }
    }
  }
  .card-header-bottom {
    .row {
      display: flex;
      overflow: hidden;
      .desc {
        box-sizing: border-box;
        line-height: 0.8 * $unit;
        border: 1px solid black;
        border-top: none;
        border-right: none;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        .left {
          flex: 1;
          border-right: 1px solid black;
        }
        .right {
          flex: 1;
        }
      }
      .column-1 {
        width: 1.13 * $unit;
        height: 0.8 * $unit;
      }
      .column-2 {
        width: 1.3 * $unit;
        height: 0.8 * $unit;
      }
      .column-3 {
        width: 1.67 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-4 {
        width: 2.3 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-5 {
        width: 2.4 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-6 {
        width: 1.9 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-7 {
        width: 2.2 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-8 {
        width: 2.3 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-9 {
        width: 2.3 * $unit;
        height: 0.8 * $unit;
        display: flex;
        justify-content: center;
      }
      .column-10 {
        width: 2.35 * $unit;
        height: 0.8 * $unit;
        display: flex;
        justify-content: center;
        border-right: 1px solid black;
      }
      .column-combine {
        width: 4.65 * $unit;
        height: 0.8 * $unit;
        border-right: 1px solid black;
        letter-spacing: 10px;
      }
    }
  }
}
.page-loading {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  /deep/ .anticon {
    font-size: 48px;
    color: white;
  }
}
</style>
<style scoped>
.materialSelect >>> .ant-select-selection {
  border: none;
}
.materialSelect >>> .ant-select-selection--single {
  height: 60px;
}
.materialSelect >>> .ant-select-selection__rendered {
  line-height: 60px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 0px;
  margin-left: 5px;
}

.materialSelect >>> .ant-select-selection-selected-value {
  overflow: unset;
  max-width: unset;
  text-overflow: unset;
  white-space: break-word;
}

/deep/ .ant-select-disabled {
  background: white;
  color: rgba(0, 0, 0, 0.65);
}
/deep/ .ant-select-selection {
  background: white;
}

/deep/ .ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: white;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
}

.ant-select-selection:hover {
  border-color: white;
}
.userSelect >>> .ant-select-selection--single {
  height: 40px;
  color: rgba(0, 0, 0, 0.65);
}
.userSelect >>> .ant-select-selection__rendered {
  line-height: 40px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
}
.userSelect >>> .anticon {
  display: none;
}

.versionSelect >>> .ant-select-selection__rendered {
  line-height: 62px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}

/deep/ .ant-calendar-picker .ant-input {
  border: none;
}
/deep/ .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.card-footer {
  padding-bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.symbols {
  position: absolute;
  top: 100px;
  right:50px;
}
</style>
