export const inventoryColumns = [
  {
    title: '计划号',
    width: 150,
    dataIndex: 'planCode',
    key: 'plan.code',
    align: 'center',
    fixed: 'left',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务号',
    width: 200,
    dataIndex: 'taskCode',
    key: 'task.code',
    align: 'center',
    fixed: 'left',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '出库单号',
    width: 150,
    dataIndex: 'takeOutNumber',
    key: 'takeOutNumber',
    align: 'center',
    fixed: 'left',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'oddNumbers',
    },
  },
  {
    title: '出库时间',
    width: 150,
    dataIndex: 'takeOutTime',
    key: 'inOutRecord.takeOutTime',
    align: 'center',
    sorter: true,
    ellipsis: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '订单编号',
    width: 150,
    sorter: true,
    dataIndex: 'orderCode',
    key: 'productOrder.orderCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '物流单号',
    width: 150,
    sorter: true,
    dataIndex: 'trackingNumber',
    key: 'trackingNumber',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户订单编号',
    width: 150,
    dataIndex: 'clientOrderCode',
    key: 'clientOrderCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '库存状态',
    width: 150,
    dataIndex: 'status',
    key: 'inOutRecord.status',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '正常',
        value: 'NRM',
      },
      {
        text: '出库单撤销',
        value: 'OCN',
      },
      {
        text: '待出库',
        value: 'WOT',
      },
      {
        text: '已出库',
        value: 'FOT',
      },
    ],
  },
  {
    title: '客户合同编号',
    width: 150,
    dataIndex: 'clientContractCode',
    key: 'clientContractCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户产品库房编号',
    width: 200,
    dataIndex: 'clientWarehouseCode',
    key: 'clientWarehouseCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户名称',
    width: 150,
    dataIndex: 'client',
    key: 'client',
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },

  {
    title: '是否分件',
    width: 150,
    dataIndex: 'isParts',
    key: 'isParts',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      customRender: 'isParts',
    },
    filters: [
      {
        text: '否',
        value: 0,
      },
      {
        text: '是',
        value: 1,
      },
    ],
  },
  {
    title: '产品名称',
    width: 150,
    dataIndex: 'productName',
    key: 'productName',
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品图号',
    width: 150,
    dataIndex: 'drawingNum',
    key: 'product.drawingNum',
    ellipsis: true,
    sorter: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品规格',
    width: 150,
    dataIndex: 'specification',
    key: 'product.specification',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '实发材料',
    dataIndex: 'actualUseMaterialList',
    key: 'actualUseMaterialList',
    width: 200,
    align: 'center',
    scopedSlots: {
      customRender: 'actualUseMaterialList',
    },
  },
  {
    title: '订单数量',
    width: 150,
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '入库数量',
    width: 150,
    dataIndex: 'putInCount',
    key: 'putInCount',
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '出库数量',
    width: 150,
    dataIndex: 'takeOutCount',
    key: 'takeOutCount',
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '库存数量',
    width: 150,
    dataIndex: 'stockCount',
    key: 'stockCount',
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '库房类型',
    width: 150,
    dataIndex: 'recordWarehouseType',
    key: 'recordWarehouseType',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      customRender: 'warehouseType',
    },
    filters: [
      {
        text: '成品库',
        value: 0,
      },
      {
        text: '半成品库',
        value: 1,
      },
      {
        text: '工装库',
        value: 2,
      },
      {
        text: '废品库',
        value: 4,
      },
      {
        text: '来料退回库',
        value: 5,
      },
      {
        text: '材料库',
        value: 3,
      },
    ],
  },
  {
    title: '单位',
    width: 80,
    dataIndex: 'unit',
    key: 'unit',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '入库日期',
    width: 200,
    dataIndex: 'putInTime',
    key: 'putInTime',
    align: 'center',
    sorter: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '销售单价（无税）',
    width: 200,
    sorter: true,
    dataIndex: 'taxFreePrice',
    key: 'taxFreePrice',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '销售金额（无税）',
    width: 200,
    dataIndex: 'totalTaxFreePrice',
    key: 'totalTaxFreePrice',
    align: 'center',
    sorter: true,
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '入库备注',
    width: 180,
    dataIndex: 'putInComment',
    key: 'putInComment',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'putInComment',
    },
  },
  {
    title: '备注',
    width: 180,
    dataIndex: 'takeOutComment',
    key: 'takeOutComment',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'takeOutComment',
    },
  },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    fixed: 'right',
    width: 80,
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

export const inventoryRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({
    props: {
      disabled: !!record.takeOutNumber,
    },
  }),
}
