export const costAccountingColumns = [
  {
    title: '序号',
    width: 80,
    dataIndex: 'index',
    key: 'index',
    fixed: 'left',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '核算单号',
    width: 180,
    dataIndex: 'code',
    key: 'car.code',
    align: 'center',
    fixed: 'left',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'costAccountCode',
    },
  },
  {
    title: '询价单号',
    width: 180,
    dataIndex: 'inquiryCode',
    key: 'inquiryCode',
    searchType: 'text',
    fixed: 'left',
    sorter: false,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },

  {
    title: '核算状态',
    width: 120,
    dataIndex: 'inquiryItem.status',
    key: 'inquiryItem.status',
    align: 'center',
    fixed: 'left',
    searchType: 'text',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '待核价',
        value: 'WCA',
      },
      {
        text: '核价中',
        value: 'CAC',
      },

      {
        text: '已取消',
        value: 'CNL',
      },
      {
        text: '核价完成',
        value: 'FIN',
      },
    ],
  },
  {
    title: '产品名称',
    width: 200,
    ellipsis: true,
    dataIndex: 'inquiryItem.productInfo.name',
    key: 'inquiryItem.productInfo.name',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'productInfo.name',
    },
  },
  {
    title: '产品图号',
    width: 200,
    ellipsis: true,
    dataIndex: 'inquiryItem.productInfo.drawingNum',
    key: 'inquiryItem.productInfo.drawingNum',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'productInfo.drawingNum',
    },
  },
  {
    title: '产品规格',
    width: 200,
    ellipsis: true,
    dataIndex: 'inquiryItem.productInfo.specification',
    key: 'inquiryItem.productInfo.specification',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'productInfo.specification',
    },
  },
  {
    title: '询价数量',
    width: 130,
    dataIndex: 'inquiryItem.quantity',
    key: 'iqi.quantity',
    align: 'center',
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'inquiryQuatity',
    },
  },
  {
    title: '核算开始时间',
    width: 200,
    ellipsis: true,
    dataIndex: 'startTime',
    key: 'startTime',
    searchType: 'date',
    sorter: false,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '核算结束时间',
    width: 200,
    ellipsis: true,
    dataIndex: 'endTime',
    key: 'endTime',
    searchType: 'date',
    sorter: false,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户名称',
    dataIndex: 'clientName',
    width: 200,
    ellipsis: true,
    key: 'clientName',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientName',
    },
  },
  {
    title: '无税单价',
    width: 120,
    ellipsis: true,
    dataIndex: 'allPartsSummaryCostInfo.totalAmountTaxFree',
    key: 'allPartsSummaryCostInfo.totalAmountTaxFree',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'totalAmountTaxFree',
    },
  },
  {
    title: '单件材料费成本',
    width: 200,
    ellipsis: true,
    dataIndex: 'allPartsSummaryCostInfo.unitMaterialCost',
    key: 'allPartsSummaryCostInfo.unitMaterialCost',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      customRender: 'unitMaterialCost',
    },
  },
  {
    title: '材料成本占比',
    width: 200,
    ellipsis: true,
    dataIndex: 'allPartsSummaryCostInfo.materialCostRate',
    key: 'allPartsSummaryCostInfo.materialCostRate',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      customRender: 'materialCostRate',
    },
  },
  {
    title: '单件外协成本',
    width: 200,
    ellipsis: true,
    dataIndex: 'allPartsSummaryCostInfo.unitOutProcessingCost',
    key: 'allPartsSummaryCostInfo.unitOutProcessingCost',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      customRender: 'unitOutProcessingCost',
    },
  },
  {
    title: '外协成本占比',
    width: 200,
    ellipsis: true,
    dataIndex: 'allPartsSummaryCostInfo.outProcessingCostRate',
    key: 'allPartsSummaryCostInfo.outProcessingCostRate',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      customRender: 'outProcessingCostRate',
    },
  },
  {
    title: '单件外购成本',
    width: 200,
    ellipsis: true,
    dataIndex: 'allPartsSummaryCostInfo.unitOutsourcingCost',
    key: 'allPartsSummaryCostInfo.unitOutsourcingCost',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      customRender: 'unitOutsourcingCost',
    },
  },
  {
    title: '外购成本占比',
    width: 200,
    ellipsis: true,
    dataIndex: 'allPartsSummaryCostInfo.outsourcingCostRate',
    key: 'allPartsSummaryCostInfo.outsourcingCostRate',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      customRender: 'outsourcingCostRate',
    },
  },
  {
    title: '单件公司剩余金额占比',
    width: 200,
    ellipsis: true,
    dataIndex: 'allPartsSummaryCostInfo.unitCompanyResidueRate',
    key: 'allPartsSummaryCostInfo.unitCompanyResidueRate',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      customRender: 'unitCompanyResidueRate',
    },
  },
  {
    title: '最初报出无税单价',
    width: 200,
    ellipsis: true,
    dataIndex: 'inquiryItem.firstQuotePriceTaxFree',
    key: 'inquiryItem.firstQuotePriceTaxFree',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'firstQuotePriceTaxFree',
    },
  },
  {
    title: '最后报出无税单价',
    width: 200,
    ellipsis: true,
    dataIndex: 'inquiryItem.lastQuotePriceTaxFree',
    key: 'inquiryItem.lastQuotePriceTaxFree',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      customRender: 'lastQuotePriceTaxFree',
    },
  },
  {
    title: '成交无税单价',
    width: 200,
    ellipsis: true,
    dataIndex: 'inquiryItem.dealPriceTaxFree',
    key: 'inquiryItem.dealPriceTaxFree',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      customRender: 'dealPriceTaxFree',
    },
  },
  {
    title: '成交订单号',
    width: 200,
    ellipsis: true,
    dataIndex: 'dealOrderCode',
    key: 'dealOrderCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'dealOrderCode',
    },
  },
  {
    title: '成交计划号',
    width: 200,
    ellipsis: true,
    dataIndex: 'dealPlanCode',
    key: 'dealPlanCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'dealPlanCode',
    },
  },
  {
    title: '备注',
    width: 150,
    ellipsis: true,
    dataIndex: 'inquiryItem.comment',
    key: 'comment',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '操作',
    width: 80,
    key: 'operate',
    align: 'center',
    fixed: 'right',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
