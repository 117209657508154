<template>
  <a-modal class="modal-container" visible="true" :title='taskForm.code+"——任务分批"' :maskClosable="false"
    @cancel="handleCancel" width="600px">
    <template slot="footer">
      <a-button @click="handleReset">
        重置
      </a-button>
      <a-button @click="handleCancel">
        取消
      </a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">
        确定
      </a-button>
    </template>
    <a-form-model ref="ruleForm" :model="task" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="分批数量" prop="batchNum">
        <a-input v-model.number="task.batchNum" placeholder='请输入任务分批数量' />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
  import {
    batchTask,
  } from '@/api/task'
  export default {
    props: {
      taskForm: Object,
    },
    data() {
      let checkNumber = (rule, value, callback) => {
        if (value !== 0 && !value) {
          return callback(new Error('请输入分批数量'));
        }
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字'));
        } else if (value <= 0 || value >= this.maxBatchNum) {
          callback(new Error('请输入不超过任务在产数量' + this.maxBatchNum + '的正整数'));
        } else {
          callback();
        }
      };
      return {
        title: this.modalTitle,
        labelCol: {
          span: 5
        },
        wrapperCol: {
          span: 15,
          offset: 1
        },
        loading: false,
        task: {
          id: this.taskForm.id,
          code: this.taskForm.code,
          producingNum: this.taskForm.producingNum,
          batchNum: null,
        },
        rules: {
          batchNum: [{
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }],
        },
      }
    },
    computed: {
      // 计算属性的 getter
      maxBatchNum: function () {
        return this.task.producingNum;
      }
    },
    methods: {
      handleReset() {
        this.$refs.ruleForm.resetFields();
      },
      handleCancel() {
        this.$emit('cancel');
      },
      handleConfirm() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.batchTask(this.task);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      batchTask(taskFormData) {
        this.loading = true;
        batchTask(taskFormData.id, taskFormData.batchNum).then(res => {
          if (res.data.id) {
            this.loading = false;
            this.$emit('confirm');
          } else {
            this.loading = false;
            this.$message.error('任务分批，请重试！')
          }
        }).catch(
          // 工序失败原因
          (reason) => {
            this.loading = false;
            console.log('处理失败的 promise (' + reason + ')');
          });
      },
    }
  }

</script>

<style scoped>


</style>
