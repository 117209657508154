<template>
  <a-modal class="modal-container" visible="true" :title="title" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>

    <a-form-model ref="ruleForm" :model="taskAssembleDTO" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="装配人员" prop="userId">
        <a-select
          v-model="taskAssembleDTO.userId"
          show-search
          placeholder="请选择装配人员"
          style="width: 300px"
          option-filter-prop="children"
          :filter-option="filterUser"
          @select="handleUserSelected"
        >
          <a-select-option v-for="user in currentUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import * as R from 'ramda'
import { getAllUsers } from '@/api/user'
import { assembleProducts } from '@/api/task'

export default {
  props: {
    modalTitle: String,
    taskForm: Array,
  },

  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      loading: false,
      userQueryParams: {},
      currentUserList: [],
      allUserList: [],
      taskAssembleDTO: {
        taskIds: this.taskForm,
        userId: null,
      },
      rules: {
        userId: [
          {
            required: true,
            message: '请选择记录人员',
            trigger: 'change',
          },
        ],
      },
    }
  },

  created() {
    this.getAllUsers()
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    getAllUsers() {
      getAllUsers(this.userQueryParams).then((res) => {
        if (res.data && res.data.accountList) {
          if (R.isEmpty(this.userQueryParams)) {
            this.allUserList = R.clone(res.data.accountList)
          }
          this.currentUserList = R.clone(res.data.accountList)
        }
      })
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.assembleProducts()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    assembleProducts() {
      assembleProducts(this.taskAssembleDTO).then((res) => {
        if (res.status === 200) {
          this.$emit('confirm')
        } else {
          const failMessage = '产品装配失败，请重试！'
          this.$message.error(failMessage)
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
