<template>
  <div class="app-container">
    <div class="top-tip">
      <div class="selected-wrapper">
        <span v-if="hasSelected">
          已选中
          <strong>{{ selectedRowKeys.length }}</strong> 项计划
        </span>
        <a-dropdown v-if="hasSelected">
          <a class="batch-operate-link" @click="e => e.preventDefault()">
            <a-icon type="edit" />批量操作
          </a>

          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="handleCreateCraftCard(selectedRowKeys)">工艺交接表打印</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-tabs :active-key="currentActiveKey" type="card" @change="handleTabChange">
      <a-tab-pane key="L" tab="L计划" />
      <a-tab-pane key="D" tab="D计划" />
      <a-tab-pane key="G" tab="G计划" />
    </a-tabs>
    <a-table
      :key="tableKey"
      row-key="id"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :columns="planColumns"
      :data-source="plans"
      :loading="loading"
      :expanded-row-keys="expandedRowKeys"
      :pagination="pagination"
      bordered
      :scroll="{ x: 4000, y: 590 }"
      class="plans-table"
      @change="handleTableChange"
      @expand="handleRowExpand"
    >
      <el-link
        v-if="column.status === 0"
        slot="name"
        slot-scope="text, column, record, index"
        type="danger"
        title="点击制定计划"
        @click="showDetail(column, record, index)"
      >{{ text }}</el-link>
      <el-link
        v-else-if="column.status === 1"
        slot="name"
        slot-scope="text, column, record, index"
        type="info"
        title="点击查看详情"
        @click="showDetail(column, record, index)"
      >{{ text }}</el-link>
      <el-link
        v-else
        slot="name"
        slot-scope="text, column, record, index"
        type="success"
        title="点击查看详情"
        @click="showDetail(column, record, index)"
      >{{ text }}</el-link>
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <a-input
          v-if="column.searchType===&quot;text&quot;"
          v-ant-ref="c => (searchInput = c)"
          :placeholder="`搜索 ${column.title}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block;"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
        />
        <div v-if="column.searchType===&quot;number&quot;">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-if="column.searchType===&quot;date&quot;">
          <a-range-picker
            :show-time="{ format: 'HH:mm:ss' }"
            size="small"
            style="width: 330px; margin-bottom: 8px;"
            :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
            @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys,clearFilters,column.key)">重置</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <span slot="status" slot-scope="text">
        <span v-if="text===0">
          <a-badge status="default" />未制定
        </span>
        <span v-if="text===1">
          <a-badge status="default" />工艺编制中
        </span>
        <span v-if="text===2">
          <a-badge status="processing" />进行中
        </span>
        <span v-if="text===3">
          <a-badge status="success" />已完成
        </span>
        <span v-if="text===9">
          <a-badge status="warning" />暂停中
        </span>
        <span v-if="text===8">
          <a-badge status="error" />已取消
        </span>
      </span>
      <div slot="productCategory" slot-scope="text, column">
        <span v-if="text === 'MLJ'">单体</span>
        <span v-if="text === 'ZLJ'">单体轴类</span>
        <span v-if="text === 'OLJ'">单体非轴类</span>
        <span v-if="text === 'CLJ'">组合</span>
      </div>
      <a-table
        slot="expandedRowRender"
        row-key="id"
        size="middle"
        :loading="tasksTableLoading"
        :columns="taskColumns"
        :data-source="tasks"
        :pagination="false"
      >
        <span slot="status" slot-scope="text">
          <span v-if="text===0">
            <a-badge status="default" />未制定
          </span>
          <span v-if="text===1">
            <a-badge status="default" />未开始
          </span>
          <span v-if="text===2">
            <a-badge status="processing" />进行中
          </span>
          <span v-if="text===3">
            <a-badge status="success" />已完成
          </span>
          <span v-if="text===4">
            <a-badge status="error" />超期进行中
          </span>
          <span v-if="text===5">
            <a-badge status="warning" />即将超期
          </span>
          <span v-if="text===9">
            <a-badge status="warning" />暂停中
          </span>
          <span v-if="text===8">
            <a-badge status="error" />已取消
          </span>
          <span v-if="text===10">
            <a-badge status="error" />异常终止
          </span>
        </span>
        <span slot="tags" slot-scope="text">
          <a-tag v-if="text===0" color="volcano">普通任务</a-tag>
          <a-tag v-if="text===1" color="geekblue">返修任务</a-tag>
          <a-tag v-if="text===2" color="green">生产补投任务</a-tag>
          <a-tag v-if="text===3" color="grey">协作任务</a-tag>
          <a-tag v-if="text===4" color="red">普通补投任务</a-tag>
          <a-tag v-if="text===5" color="orange">库存转入任务</a-tag>
        </span>
        <el-link
          slot="name"
          slot-scope="text, column, record, index"
          type="primary"
          :title="column.status===0 ? '点击编辑任务': '点击更新任务'"
          @click="handleUpdateTask(column, record, index)"
        >{{ text }}</el-link>
      </a-table>
      <span slot="operation" slot-scope="text, column" class="table-operation">
        <a-dropdown placement="topRight">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item v-if="column.status===2||column.status===3" @click="handleAddTask(column.id)">新增任务</a-menu-item>
            <a-menu-item v-if="column.status===2" @click="handleUpdatePlan(column)">更新计划</a-menu-item>
            <a-menu-item v-if="column.status===0||column.status===1||column.status===2" @click="switchPlanStatus(column,'c')">取消计划</a-menu-item>
            <a-menu-item v-if="column.status===2" @click="switchPlanStatus(column,'s')">暂停计划</a-menu-item>
            <a-menu-item v-if="column.status===9" @click="switchPlanStatus(column,'r')">恢复计划</a-menu-item>
            <a-menu-item v-if="column.status===8||column.status===0||column.status===1" @click="handleDeletePlan(column)">删除计划</a-menu-item>
          </a-menu>
          <a>
            操作
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </span>
    </a-table>
    <el-dropdown class="export-plan" @command="handleExportPlanCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出计划</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="SELECTED">导出当前已选计划</el-dropdown-item>
        <el-dropdown-item command="PAGE">导出当前页计划</el-dropdown-item>
        <el-dropdown-item command="FILTERED">导出当前筛选计划</el-dropdown-item>
        <el-dropdown-item command="ALL">导出所有计划</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <PlanEdit
      v-if="editModalVisible"
      :modal-title="currentEditType"
      :plan-type="currentActiveKey"
      :plan-form="currentPlan"
      @cancel="handlePlanModalCanceled"
      @confirm="handlePlanModalConfirmed"
    />
    <TaskEdit
      v-if="editTaskModalVisible"
      :modal-title="currentEditType"
      :task-form="currentTask"
      @cancel="handleTaskModalCanceled"
      @confirm="handleTaskModalConfirmed"
    />
    <a-modal
      v-if="isInitModal"
      v-model="planDetailModalVisible"
      :title="`${currentPlanCode} - 计划详情`"
      :centered="true"
      :width="1200"
      :footer="null"
      @ok="handleOk"
    >
      <div class="modal-div">
        <a-descriptions bordered>
          <a-descriptions-item label=" 累计投产数量">{{ currentPlanDetail.totalNum }}</a-descriptions-item>
          <a-descriptions-item label="累计在产数量">{{ currentPlanDetail.producingNum }}</a-descriptions-item>
          <a-descriptions-item label=" 累计报废数量">{{ currentPlanDetail.totalAbandonNum }}</a-descriptions-item>
          <a-descriptions-item label="累计退货数量">{{ currentPlanDetail.totalReturnedNum }}</a-descriptions-item>
          <a-descriptions-item label="计划下达日期">{{ currentPlanDetail.commandTime }}</a-descriptions-item>
          <a-descriptions-item label="合同交货日期">{{ currentPlanDetail.deadline }}</a-descriptions-item>
          <a-descriptions-item label="单价(含税)">{{ currentPlanDetail.taxPrice }}</a-descriptions-item>
          <a-descriptions-item label="累计入库数量">{{ currentPlanDetail.totalPutInNum }}</a-descriptions-item>
          <a-descriptions-item label="累计入库金额">{{ currentPlanDetail.totalPutInMoney }}</a-descriptions-item>
          <a-descriptions-item label="库存转入数量">{{ currentPlanDetail.inventoryTurnsIntoNum }}</a-descriptions-item>
          <a-descriptions-item label="库存数量">{{ currentPlanDetail.inventoryNum }}</a-descriptions-item>
          <a-descriptions-item label="合同未交数量">{{ currentPlanDetail.contractNoDeliveryNum }}</a-descriptions-item>
          <a-descriptions-item label="报废不需补投数量">{{ currentPlanDetail.abandonNotComplementNum }}</a-descriptions-item>
          <a-descriptions-item label="最近完成日期">{{ currentPlanDetail.finishTime }}</a-descriptions-item>
          <a-descriptions-item label="最近退货日期">{{ currentPlanDetail.returnedTime }}</a-descriptions-item>
          <a-descriptions-item label="累计返修数量">{{ currentPlanDetail.totalRepairNum }}</a-descriptions-item>
          <a-descriptions-item label="累计投产金额">{{ currentPlanDetail.totalMoney }}</a-descriptions-item>
          <a-descriptions-item label="累计报废投产数量">{{ currentPlanDetail.totalAbandonProduceNum }}</a-descriptions-item>
          <a-descriptions-item label="备投数量">{{ currentPlanDetail.prepareNum }}</a-descriptions-item>
          <a-descriptions-item label="累计在产金额">{{ currentPlanDetail.totalProducingMoney }}</a-descriptions-item>
          <a-descriptions-item label="累计报废金额">{{ currentPlanDetail.totalAbandonMoney }}</a-descriptions-item>
          <a-descriptions-item label="累计退货金额">{{ currentPlanDetail.totalReturnedMoney }}</a-descriptions-item>
          <a-descriptions-item label="实际出库金额">{{ currentPlanDetail.actualTakeOutMoney }}</a-descriptions-item>
          <a-descriptions-item label="超期天数">{{ currentPlanDetail.overdueDays }}</a-descriptions-item>
          <a-descriptions-item label="累计投产金额">{{ currentPlanDetail.totalMoney }}</a-descriptions-item>
          <a-descriptions-item label="超期违约金">{{ currentPlanDetail.penalty }}</a-descriptions-item>
          <a-descriptions-item label="设计人员">{{ currentPlanDetail.designer }}</a-descriptions-item>
          <a-descriptions-item label="客户物料代码">{{ currentPlanDetail.clientMaterialCode }}</a-descriptions-item>
          <a-descriptions-item label="客户计划编码">{{ currentPlanDetail.clientPlanCode }}</a-descriptions-item>
          <a-descriptions-item label="客户合同编码">{{ currentPlanDetail.clientContractCode }}</a-descriptions-item>
          <a-descriptions-item label="客户采购计划编号">{{ currentPlanDetail.clientPurchaseOrderCode }}</a-descriptions-item>
          <a-descriptions-item label="材料类别">{{ currentPlanDetail.materialCategories }}</a-descriptions-item>
          <a-descriptions-item label="负责人">{{ currentPlanDetail.charger }}</a-descriptions-item>
          <a-descriptions-item label="备注">{{ currentPlanDetail.comment }}</a-descriptions-item>
        </a-descriptions>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {
  getPlans,
  getPlanById,
  deletePlan,
  exportAllPlans,
  exportPlansByIds,
  exportCurrentPagePlans,
  exportFilteredPlans,
  switchPlanStatus
} from '@/api/plan'
import { CONSTANTS } from '@/utils/constants'
import { getTasks } from '@/api/task'
import { downloadItem } from '@/utils/api.request'
import PlanEdit from '@/components/PlanEdit'
import TaskEdit from '@/components/TaskEdit'
import * as R from 'ramda'
import { planColumns, taskColumns } from './dataLogic.js'

export default {
  components: {
    PlanEdit,
    TaskEdit
  },
  data() {
    return {
      tableKey: 0,
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        showQuickJumper: true
      },
      plans: [],
      planColumns,
      taskColumns,
      tasks: [],
      currentPlanId: null,
      currentPlan: {},
      currentTask: {},
      defaultPlan: {
        id: null,
        code: '',
        type: '',
        whetherParts: true,
        commandTime: null,
        deliverTime: null,
        clientDemand: null,
        inventoryTurnsIntoNum: 0,
        abandonNotComplementNum: 0,
        startTime: null,
        prepareNum: 0,
        status: 2,
        charger: '',
        comment: ''
      },
      defaultTask: {
        id: null,
        planId: null,
        code: '',
        productionNum: null,
        type: 4,
        status: 2,
        comment: ''
      },
      currentPlanCode: '',
      currentEditType: '',
      currentActiveKey: 'L',
      expandedRowKeys: [],
      selectedRowKeys: [],
      exportIds: [],
      loading: false,
      exportLoading: false,
      modalConfirmButtonLoading: false,
      tasksTableLoading: false,
      currentClickRowIndex: null,
      currentPlanDetail: {},
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      isInitModal: false,
      editModalVisible: false,
      editTaskModalVisible: false,
      editType: '',
      planDetailModalVisible: false,
      initPlanListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        'product.type': 'eq:L'
      },
      currentPlanListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        'product.type': 'eq:L'
      },
      columnSearchParams: {},
      otherFilterParams: null,
      columnFilterParams: null
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  created() {
    if (this.$route.query.planId) {
      this.getPlans({
        pageNum: 1,
        pageSize: 10,
        'plan.id': 'eq:' + this.$route.query.planId
      })
    } else {
      this.getPlans(this.initPlanListQueryParams)
    }
  },
  methods: {
    handleTabChange(activeKey) {
      this.initPlanListQueryParams['product.type'] = 'eq:' + activeKey
      this.currentPlanListQueryParams = R.clone(this.initPlanListQueryParams)
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getPlans(this.initPlanListQueryParams)
      this.currentActiveKey = activeKey
      this.tableKey += 1
    },
    async getPlans(data) {
      this.loading = true
      try {
        const res = await getPlans(data)
        this.handleDataShow(res.data.records)
        this.pagination.total = res.data.total
        if (this.$route.query.planId && res.data.records.length === 1) {
          this.currentActiveKey = res.data.records[0].type
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    },
    getPlanById(planId) {
      getPlanById(planId)
        .then(res => {
          if (res.data) {
            this.currentPlanDetail = res.data
          }
        })
        .catch(
          // 记录失败原因
          reason => {
            this.currentPlanDetail = {}
          }
        )
    },
    handleDataShow(data) {
      if (!data) {
        return
      }
      const obj = {
        0: '未制定',
        1: '工艺编制中',
        2: '在产中',
        3: '已取消',
        4: '已暂停',
        5: '已完成',
        6: '已超期'
      }
      data.forEach(item => {
        item.statusText = obj[item.status]
      })
      this.plans = data
    },
    showDetail(column, record, index) {
      switch (index.dataIndex) {
        case 'code':
          if (column.status === 0) {
            const planForm = R.clone(column)
            planForm.startTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            planForm.status = 2
            this.currentEditType = '制定'
            this.handleMakePlan(planForm)
            break
          }
          this.isInitModal = true
          this.currentPlanCode = column.code
          this.currentPlanId = column.id
          this.showModal()
          break
        default:
          break
      }
    },
    handleRowExpand(expanded, record) {
      this.currentPlanId = null
      if (expanded) {
        const tasksQueryParams = {
          pageNum: 1,
          pageSize: 50,
          planId: 'eq:' + record.id
        }
        this.currentPlanId = record.id
        this.expandedRowKeys = []
        this.expandedRowKeys.push(record.id)
        this.getPlanTasks(tasksQueryParams)
        this.planColumns = this.planColumns.map(item => {
          return {
            ...item,
            fixed: false
          }
        })
      } else {
        this.expandedRowKeys = []
        this.planColumns = this.planColumns.map((item, index) => {
          if (index < 5) {
            return {
              ...item,
              fixed: 'left'
            }
          }
          return {
            ...item,
            fixed: false
          }
        })
      }
    },
    getPlanTasks(queryParams) {
      this.tasksTableLoading = true
      getTasks(queryParams)
        .then(res => {
          if (res.data) {
            res.data.records.forEach(item => {
              if (item.status === 0) {
                item.code = '点击编辑任务'
              }
            })
            this.tasks = res.data.records
            this.tasksTableLoading = false
          }
        })
        .catch(
          // 记录失败原因
          reason => {
            this.tasks = []
            this.tasksTableLoading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    handleAddPlan(column) {
      this.currentEditType = '新增分件'
      this.currentPlan = R.clone(column)
      this.currentPlan.code += '/'
      const currentDateTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      this.currentPlan.amount = null
      this.currentPlan.productName = null
      this.currentPlan.specification = null
      this.currentPlan.commandTime = currentDateTime
      this.currentPlan.startTime = currentDateTime
      this.editModalVisible = true
    },
    handleAddTask(planId) {
      this.currentEditType = '新增'
      this.currentTask = this.defaultTask
      this.currentTask.planId = planId
      this.editTaskModalVisible = true
    },
    handleMakePlan(column) {
      this.currentPlan = column
      this.editModalVisible = true
    },
    handleUpdatePlan(column) {
      this.currentEditType = '更新'
      this.currentPlan = column
      this.editModalVisible = true
    },
    handleUpdateTask(column) {
      this.currentEditType = '更新'
      this.currentTask = R.clone(column)
      if (column.code === '点击编辑任务') {
        this.currentTask.code = ''
        this.currentTask.status = 2
      }
      this.editTaskModalVisible = true
    },
    handleDeletePlan(column) {
      const planCode = column.status === 0 ? '' : column.code
      this.$confirm({
        content: '确认删除计划' + planCode + '?',
        onOk: () => {
          deletePlan(column.id).then(res => {
            if (res) {
              this.$message.success('删除计划成功！')
              this.getPlans(this.currentPlanListQueryParams)
            }
          })
        }
      })
    },
    // 改变计划状态
    switchPlanStatus(row, statusCode) {
      let actionName = ''
      if (row.code === null) {
        row.code = ''
      }
      if (statusCode === 'c') {
        actionName = '取消'
      } else if (statusCode === 's') {
        actionName = '暂停'
      } else if (statusCode === 'r') {
        actionName = '恢复'
      }
      this.$confirm({
        content: '确认' + actionName + '计划' + row.code + '?',
        onOk: () => {
          switchPlanStatus(row.id, statusCode).then(() => {
            this.$message.success(actionName + '计划成功！')
            this.getPlans(this.currentPlanListQueryParams)
          })
        }
      })
    },
    handlePlanModalConfirmed() {
      this.editModalVisible = false
      this.getPlans(this.currentPlanListQueryParams)
    },
    handleTaskModalConfirmed() {
      this.editTaskModalVisible = false
      const tasksQueryParams = {
        pageNum: 1,
        pageSize: 50,
        planId: 'eq:' + this.currentPlanId
      }
      this.getPlanTasks(tasksQueryParams)
    },
    handlePlanModalCanceled() {
      this.editModalVisible = false
    },
    handleTaskModalCanceled() {
      this.editTaskModalVisible = false
    },
    onSelectChange(selectedRowKeys) {
      if (selectedRowKeys.length > 150) {
        this.$message.info('最多选择150项')
        this.selectedRowKeys = selectedRowKeys.slice(0, 150)
        return
      }
      this.selectedRowKeys = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },

    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = ''
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination = pagination
      this.currentPlanListQueryParams.pageNum = pagination.current
      this.currentPlanListQueryParams.pageSize = pagination.pageSize
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentPlanListQueryParams.sort_by = sortType + sorter.columnKey
      }
      this.columnSearchParams['plan.status'] = ''
      if (filters.status && filters.status.length > 0) {
        this.columnSearchParams['plan.status'] =
          'in:' + filters.status.toString()
      }
      this.columnSearchParams['productCategory'] = ''
      if (filters.productCategory && filters.productCategory.length > 0) {
        this.columnSearchParams['productCategory'] =
          'in:' + filters.productCategory.toString()
      }
      this.currentPlanListQueryParams = R.merge(
        this.currentPlanListQueryParams,
        this.columnSearchParams
      )
      this.currentPlanListQueryParams = R.reject(R.equals(''))(
        this.currentPlanListQueryParams
      )
      this.getPlans(this.currentPlanListQueryParams)
    },
    showModal() {
      this.getPlanById(this.currentPlanId)
      this.planDetailModalVisible = true
    },
    handleOk(e) {
      console.log(e)
      this.planDetailModalVisible = false
    },

    getExportIdsFromPage() {
      this.exportIds = this.plans.map(item => {
        return item.id
      })
    },
    getExportIdsFromSelection() {
      this.exportIds = this.selectedRowKeys
    },
    handleExportPlanCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'SELECTED':
          if (this.selectedRowKeys.length === 0) {
            this.$message.warning('选择项不能为空！')
            this.exportLoading = false
            break
          }
          this.getExportIdsFromSelection()
          exportPlansByIds({
            ids: this.exportIds.toString()
          })
            .then(res => {
              this.downloadPlan(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        case 'PAGE':
          if (this.plans.length === 0) {
            this.$message.warning('导出数据不能为！')
            this.exportLoading = false
            break
          }
          exportCurrentPagePlans(this.currentPlanListQueryParams)
            .then(res => {
              this.downloadPlan(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        case 'FILTERED':
          exportFilteredPlans(this.currentPlanListQueryParams)
            .then(res => {
              this.exportLoading = false
              this.downloadPlan(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        case 'ALL': {
          exportAllPlans()
            .then(res => {
              this.downloadPlan(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        }
        default:
          this.exportLoading = false
          break
      }
    },
    downloadPlan(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.PLAN_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleCreatePlanCard(keys) {
      const newPage = this.$router.resolve({
        path: '/plan-delivery-card',
        query: {
          ids: keys.toString()
        }
      })
      window.open(newPage.href, '_blank')
    },
    handleCreateCraftCard(keys) {
      const newPage = this.$router.resolve({
        path: '/craft-delivery-card',
        query: {
          ids: keys.toString()
        }
      })
      window.open(newPage.href, '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
.app-container {
  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }
  .batch-operate-link {
    padding-left: 20px;
    cursor: pointer;
    color: #409eff;
  }
  .plans-table {
    width: 100%;
    max-height: 590px;
  }

  .table-operation {
    a {
      cursor: pointer;
      color: #409eff;
    }
  }

  .permission-tree {
    margin-bottom: 30px;
  }
}

.plan-table-expand {
  font-size: 0;

  label {
    width: 155px;
    color: #99a9bf;
  }

  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.export-plan {
  float: right;
  bottom: -16px;
  right: 0px;
}

.top-tip {
}

/deep/ .ant-table-pagination,
.ant-pagination {
  float: left;
}
/deep/ .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: hidden;
}
</style>
