var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-row",
        {
          staticClass: "row-wrapper",
          attrs: { type: "flex", justify: "space-between", align: "middle" },
        },
        [
          _c("a-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "trends-change-wrapper" }, [
              _c("div", { staticClass: "target-statistics" }, [
                _c("div", { staticClass: "filter" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("各班组产品平均停留时间"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "date-container" },
                    [
                      _c("p", [_vm._v("日期:")]),
                      _c("a-range-picker", {
                        attrs: {
                          "value-format": "YYYY-MM-DD",
                          allowClear: false,
                        },
                        model: {
                          value: _vm.taskTrendsDate,
                          callback: function ($$v) {
                            _vm.taskTrendsDate = $$v
                          },
                          expression: "taskTrendsDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "trends-change-inner" }, [
                  _c("div", {
                    ref: "taskTrends",
                    staticClass: "target-content",
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "trends-change-wrapper" }, [
              _c("div", { staticClass: "target-statistics" }, [
                _c("div", { staticClass: "filter" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("各工序产品平均停留时间"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "date-container" },
                    [
                      _c("p", [_vm._v("日期:")]),
                      _c("a-range-picker", {
                        attrs: {
                          "value-format": "YYYY-MM-DD",
                          allowClear: false,
                        },
                        model: {
                          value: _vm.taskTrendsChangeDate,
                          callback: function ($$v) {
                            _vm.taskTrendsChangeDate = $$v
                          },
                          expression: "taskTrendsChangeDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "trends-change-inner" }, [
                  _c("div", {
                    ref: "taskTrendsChange",
                    staticClass: "target-content",
                  }),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "trends-change-wrapper" }, [
        _c("div", { staticClass: "target-statistics" }, [
          _c("div", { staticClass: "filter" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("各班组产品平均停留时间趋势"),
            ]),
            _c(
              "div",
              { staticClass: "date-container" },
              [
                _c(
                  "a-radio-group",
                  {
                    staticClass: "date-type",
                    model: {
                      value: _vm.costDimension,
                      callback: function ($$v) {
                        _vm.costDimension = $$v
                      },
                      expression: "costDimension",
                    },
                  },
                  [
                    _c("a-radio-button", { attrs: { value: "WEEK" } }, [
                      _vm._v("周"),
                    ]),
                    _c("a-radio-button", { attrs: { value: "MONTH" } }, [
                      _vm._v("月"),
                    ]),
                  ],
                  1
                ),
                _c("p", [_vm._v("日期:")]),
                _c("a-range-picker", {
                  attrs: { "value-format": "YYYY-MM-DD", allowClear: false },
                  model: {
                    value: _vm.costDate,
                    callback: function ($$v) {
                      _vm.costDate = $$v
                    },
                    expression: "costDate",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "trends-change-inner" }, [
            _c("div", { ref: "cost", staticClass: "target-content" }),
          ]),
        ]),
      ]),
      _c(
        "div",
        { ref: "scrapAmountWrapper", staticClass: "trends-change-wrapper" },
        [
          _c("div", { staticClass: "target-statistics" }, [
            _c("div", { staticClass: "filter" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("各工序产品平均停留时间趋势"),
              ]),
              _c(
                "div",
                { staticClass: "date-container" },
                [
                  _c(
                    "a-radio-group",
                    {
                      staticClass: "date-type",
                      model: {
                        value: _vm.scrapAmountDimension,
                        callback: function ($$v) {
                          _vm.scrapAmountDimension = $$v
                        },
                        expression: "scrapAmountDimension",
                      },
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "WEEK" } }, [
                        _vm._v("周"),
                      ]),
                      _c("a-radio-button", { attrs: { value: "MONTH" } }, [
                        _vm._v("月"),
                      ]),
                    ],
                    1
                  ),
                  _c("p", [_vm._v("日期:")]),
                  _c("a-range-picker", {
                    attrs: { "value-format": "YYYY-MM-DD", allowClear: false },
                    model: {
                      value: _vm.scrapAmountDate,
                      callback: function ($$v) {
                        _vm.scrapAmountDate = $$v
                      },
                      expression: "scrapAmountDate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "scrap-amount-inner" }, [
              _c("div", { ref: "scrapAmount", staticClass: "target-content" }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }