<template>
  <div class="container">
    <div class="top">
      <div class="target-current">
        <div class="target-slogan">
          <p class="title">{{ targetStatisticsInfo.targetInfo ? targetStatisticsInfo.targetInfo.targetName : '暂无目标数据' }}</p>
          <a-radio-group v-model="onGoingTab" class="tab" @change="onGoingTabChange">
            <a-radio-button value="month">当月</a-radio-button>
            <a-radio-button value="week">当周</a-radio-button>
            <a-radio-button value="day">当日</a-radio-button>
          </a-radio-group>
          <div v-if="targetStatisticsInfo.comprehensiveStatisticsInfo" class="list">
            <div class="item">
              <p class="data">{{ (targetStatisticsInfo.comprehensiveStatisticsInfo.insertEmergencyRate * 100).toFixed(0) }}%</p>
              <p class="desc">目标插入急件综合占比</p>
            </div>
            <div class="item">
              <p class="data">{{ (targetStatisticsInfo.comprehensiveStatisticsInfo.insertEmergencyFinishedRate * 100).toFixed(0) }}%</p>
              <p class="desc">实际完成插入急件综合占比</p>
            </div>
            <div class="item">
              <p class="data">{{ (targetStatisticsInfo.comprehensiveStatisticsInfo.normalFinishedRate * 100).toFixed(0) }}%</p>
              <p class="desc">实际完成考核目标综合占比</p>
            </div>
            <div class="item">
              <p class="data">{{ (targetStatisticsInfo.comprehensiveStatisticsInfo.finishedRate * 100).toFixed(0) }}%</p>
              <p class="desc">实际完成率</p>
            </div>
          </div>
        </div>
        <div class="target-tab">
          <a-radio-group v-model="targetTab" class="tab" @change="onTargetTabChange()">
            <a-radio-button value="ALL">ALL</a-radio-button>
            <a-radio-button value="LD">LD</a-radio-button>
            <a-radio-button value="D">D</a-radio-button>
            <a-radio-button value="L">L</a-radio-button>
            <a-radio-button value="G">G</a-radio-button>
          </a-radio-group>
          <div class="target-content">
            <div class="check">
              <p class="title">考核目标</p>
              <div v-if="targetStatisticsInfo.normalTargetStatisticsInfo" class="list">
                <div class="item">
                  <div id="taskStatisticsInfo" class="pie" />
                  <div class="desc">
                    <p>总计: {{ targetStatisticsInfo.normalTargetStatisticsInfo.taskStatisticsInfo.totalCount }}</p>
                    <p>完成: {{ targetStatisticsInfo.normalTargetStatisticsInfo.taskStatisticsInfo.finishedCount }}</p>
                  </div>
                </div>
                <div class="item">
                  <div id="productStatisticsInfo" class="pie" />
                  <div class="desc">
                    <p>总计: {{ targetStatisticsInfo.normalTargetStatisticsInfo.productStatisticsInfo.totalCount }}</p>
                    <p>完成: {{ targetStatisticsInfo.normalTargetStatisticsInfo.productStatisticsInfo.finishedCount }}</p>
                  </div>
                </div>
                <div class="item">
                  <div id="amountStatisticsInfo" class="pie" />
                  <div class="desc">
                    <p>总计: {{ targetStatisticsInfo.normalTargetStatisticsInfo.amountStatisticsInfo.totalAmount }}</p>
                    <p>完成: {{ targetStatisticsInfo.normalTargetStatisticsInfo.amountStatisticsInfo.finishedAmount }}</p>
                  </div>
                </div>
                <a-button
                  v-if="targetTab !== 'ALL'&&targetTab !== 'LD'&& (targetStatisticsInfo.targetInfo && targetStatisticsInfo.targetInfo.id)"
                  class="btn"
                  @click="jumpToTargetManage(targetTab + 'N')"
                >
                  {{ targetTab }}明细统计
                  <a-icon type="right" />
                </a-button>
              </div>
              <template v-if="targetTab !== 'G'">
                <p class="title">插入急件目标</p>
                <div v-if="targetStatisticsInfo.emergencyTargetStatisticsInfo" class="list">
                  <div class="item">
                    <div id="emergency-taskStatisticsInfo" class="pie" />
                    <div class="desc">
                      <p>总计: {{ targetStatisticsInfo.emergencyTargetStatisticsInfo.taskStatisticsInfo.totalCount }}</p>
                      <p>完成: {{ targetStatisticsInfo.emergencyTargetStatisticsInfo.taskStatisticsInfo.finishedCount }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div id="emergency-productStatisticsInfo" class="pie" />
                    <div class="desc">
                      <p>总计: {{ targetStatisticsInfo.emergencyTargetStatisticsInfo.productStatisticsInfo.totalCount }}</p>
                      <p>完成: {{ targetStatisticsInfo.emergencyTargetStatisticsInfo.productStatisticsInfo.finishedCount }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div id="emergency-amountStatisticsInfo" class="pie" />
                    <div class="desc">
                      <p>总计: {{ targetStatisticsInfo.emergencyTargetStatisticsInfo.amountStatisticsInfo.totalAmount }}</p>
                      <p>完成: {{ targetStatisticsInfo.emergencyTargetStatisticsInfo.amountStatisticsInfo.finishedAmount }}</p>
                    </div>
                  </div>
                  <a-button
                    v-if="targetTab !== 'ALL' && targetTab !== 'LD'&&targetTab !== 'G' && (targetStatisticsInfo.targetInfo && targetStatisticsInfo.targetInfo.id)"
                    class="btn"
                    @click="jumpToTargetManage(targetTab + 'E')"
                  >
                    {{ targetTab }}急件统计
                    <a-icon type="right" />
                  </a-button>
                </div>
              </template>
            </div>
            <div class="urgent" />
          </div>
          <div v-if="targetLoading" class="page-loading" style="height: 380px;">
            <a-icon type="loading" style="color:black;" />
          </div>
        </div>
      </div>
      <div class="target-list">
        <p class="title">
          目标列表
          <a-button style="padding: 0 12px;" @click="jump">管理目标</a-button>
        </p>
        <div class="list" @scroll="handleScroll">
          <a-tree :selected-keys="targetKey" :tree-data="targetList" :load-data="handleGetChildren" @select="handleSwitchTarget" />
          <!-- <div
            v-for="item of targetList"
            :key="item.id"
            class="item"
            :class="{'active':activeTargetId === item.id }"
            @click="haneleChooseTarget(item.id)"
          >
            <span class="name">{{ item.targetName }}</span>
            <a-tag v-if="item.isOngoing " class="tag" color="#f50">进行中</a-tag>
            <span
              class="time"
            >{{ moment(item.targetStartTime).format('YYYY-MM-DD') }} - {{ moment(item.targetEndTime).format('YYYY-MM-DD') }}</span>
          </div>-->
        </div>
      </div>
    </div>
    <div v-if="ifWeek || ifDay" class="bottom">
      <div class="desc">
        <p class="title">{{ ifWeek ? '班组周目标统计' : '员工日目标统计' }}</p>
      </div>
      <div class="table">
        <a-table
          :key="userTableKey"
          row-key="id"
          :bordered="true"
          :columns="ifWeek ? groupColumns : memberColumns"
          :data-source="userStatisticList"
          :loading="userTableLoading"
          :pagination="userPagination"
          @change="handleUserTableChange"
        >
          <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
            <template v-if="column.searchType === 'text'">
              <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`搜索 ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
            </template>
            <div v-else-if="column.searchType === 'number'">
              <a-input-number
                v-model="selectedKeys[0]"
                size="small"
                placeholder="最小值"
                :min="0"
                :max="999999"
                style="width: 95px; margin-bottom: 8px; display: inline-block;"
                @change="e => setSelectedKeys([e,selectedKeys[1]])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
              <span>-</span>
              <a-input-number
                v-model="selectedKeys[1]"
                size="small"
                placeholder="最大值"
                :min="selectedKeys[0]"
                :max="999999"
                style="width: 95px; margin-bottom: 8px; display: inline-block;"
                @change="e => setSelectedKeys([selectedKeys[0],e])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
            </div>
            <div v-else-if="column.searchType === 'date'">
              <a-range-picker
                :show-time="{ format: 'HH:mm:ss' }"
                size="small"
                style="width: 330px; margin-bottom: 8px;"
                :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
                @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
              />
            </div>
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm, column.key)"
            >搜索</a-button>
            <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
          </div>
          <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />

          <div slot="userName" slot-scope="text,column">
            <a-tooltip placement="top" title="点击查看详情统计">
              <el-link type="primary" @click="memberJump(column)">{{ text }}</el-link>
            </a-tooltip>
          </div>
          <div slot="groupName" slot-scope="text, column">
            <a-tooltip placement="top" title="点击查看详情统计">
              <el-link type="primary" @click="groupJump(column)">{{ text }}</el-link>
            </a-tooltip>
          </div>
          <div slot="rate" slot-scope="text,column">
            <p>{{ (text * 100).toFixed(0) }}%</p>
          </div>
        </a-table>
      </div>
    </div>
    <div class="content">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">生产情况概览</span>
          <div class="date-container">
            <p>订单交货日期/任务创建日期:</p>
            <a-range-picker
              class="range"
              :default-value="[moment(new Date(new Date().getTime() - 3600 * 1000 * 24 * 30), dateFormat), moment(new Date(), dateFormat)]"
              @change="onDateChange"
            >
              <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
          </div>
        </div>
        <div class="target-tab">
          <a-radio-group v-model="taskTab" class="tab" @change="getTaskStatisticsByTime">
            <a-radio-button value="ALL">ALL</a-radio-button>
            <a-radio-button value="LD">LD</a-radio-button>
            <a-radio-button value="D">D</a-radio-button>
            <a-radio-button value="L">L</a-radio-button>
            <a-radio-button value="G">G</a-radio-button>
          </a-radio-group>
          <div class="target-content">
            <div class="check">
              <p class="title">订单准交情况统计</p>
              <div v-if="orderStatisticInfo.normalTargetStatisticsInfo" class="list">
                <div class="item">
                  <div id="order-taskStatisticsInfo" class="pie" />
                  <div class="desc">
                    <p>总计: {{ orderStatisticInfo.normalTargetStatisticsInfo.taskStatisticsInfo.totalCount }}</p>
                    <p>完成: {{ orderStatisticInfo.normalTargetStatisticsInfo.taskStatisticsInfo.finishedCount }}</p>
                  </div>
                </div>
                <div class="item">
                  <div id="order-productStatisticsInfo" class="pie" />
                  <div class="desc">
                    <p>总计: {{ orderStatisticInfo.normalTargetStatisticsInfo.productStatisticsInfo.totalCount }}</p>
                    <p>完成: {{ orderStatisticInfo.normalTargetStatisticsInfo.productStatisticsInfo.finishedCount }}</p>
                  </div>
                </div>
                <div class="item">
                  <div id="order-amountStatisticsInfo" class="pie" />
                  <div class="desc">
                    <p>总计: {{ orderStatisticInfo.normalTargetStatisticsInfo.amountStatisticsInfo.totalAmount }}</p>
                    <p>完成: {{ orderStatisticInfo.normalTargetStatisticsInfo.amountStatisticsInfo.finishedAmount }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="check">
              <p class="title">任务完成情况统计</p>
              <div v-if="taskInfo.normalTargetStatisticsInfo" class="list">
                <div class="item">
                  <div id="task-taskStatisticsInfo" class="pie" />
                  <div class="desc">
                    <p>总计: {{ taskInfo.normalTargetStatisticsInfo.taskStatisticsInfo.totalCount }}</p>
                    <p>完成: {{ taskInfo.normalTargetStatisticsInfo.taskStatisticsInfo.finishedCount }}</p>
                  </div>
                </div>
                <div class="item">
                  <div id="task-productStatisticsInfo" class="pie" />
                  <div class="desc">
                    <p>总计: {{ taskInfo.normalTargetStatisticsInfo.productStatisticsInfo.totalCount }}</p>
                    <p>完成: {{ taskInfo.normalTargetStatisticsInfo.productStatisticsInfo.finishedCount }}</p>
                  </div>
                </div>
                <div class="item">
                  <div id="task-amountStatisticsInfo" class="pie" />
                  <div class="desc">
                    <p>总计: {{ taskInfo.normalTargetStatisticsInfo.amountStatisticsInfo.totalAmount }}</p>
                    <p>完成: {{ taskInfo.normalTargetStatisticsInfo.amountStatisticsInfo.finishedAmount }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="urgent" />
          </div>
          <div v-if="taskLoading" class="page-loading" style="height: 390px;">
            <a-icon type="loading" style="color:black;" />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="desc">
        <p class="title">任务规划情况统计</p>
      </div>
      <div class="table">
        <a-table
          :key="tableKey"
          row-key="id"
          :bordered="true"
          :columns="logColumns"
          :data-source="logList"
          :loading="tableLoading"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
            <template v-if="column.searchType === 'text'">
              <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`搜索 ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
            </template>
            <div v-else-if="column.searchType === 'number'">
              <a-input-number
                v-model="selectedKeys[0]"
                size="small"
                placeholder="最小值"
                :min="0"
                :max="999999"
                style="width: 95px; margin-bottom: 8px; display: inline-block;"
                @change="e => setSelectedKeys([e,selectedKeys[1]])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
              <span>-</span>
              <a-input-number
                v-model="selectedKeys[1]"
                size="small"
                placeholder="最大值"
                :min="selectedKeys[0]"
                :max="999999"
                style="width: 95px; margin-bottom: 8px; display: inline-block;"
                @change="e => setSelectedKeys([selectedKeys[0],e])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
            </div>
            <div v-else-if="column.searchType === 'date'">
              <a-range-picker
                :show-time="{ format: 'HH:mm:ss' }"
                size="small"
                style="width: 330px; margin-bottom: 8px;"
                :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
                @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
              />
            </div>
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm, column.key)"
            >搜索</a-button>
            <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
          </div>
          <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />

          <span slot="targetType" slot-scope="text">
            <a-tag v-if="text==='M'" color="volcano">月目标</a-tag>
            <a-tag v-if="text==='W'" color="cyan">周目标</a-tag>
            <a-tag v-if="text==='D'" color="green">日目标</a-tag>
          </span>
          <span slot="deleteNum" slot-scope="text, column">
            <template v-if="text > 0">
              <el-link type="success" @click="handleShowLogDetail(column, 'D')">{{ text }}</el-link>
            </template>
            <template v-else>{{ text }}</template>
          </span>
          <span slot="updateNum" slot-scope="text, column">
            <template v-if="text > 0">
              <el-link type="success" @click="handleShowLogDetail(column, 'U')">{{ text }}</el-link>
            </template>
            <template v-else>{{ text }}</template>
          </span>
        </a-table>
      </div>
    </div>
    <LogDetailModal
      v-if="logDetailModalVisible"
      :target-id="logTargetId"
      :operate-type="logType"
      @confirm="logDetailConfirm"
      @cancel="logDetailConfirm"
    />
  </div>
</template>

<script>
import { getTargets, getAllWeekTarget, getAllDayTarget } from '@/api/target'
import {
  getMonthStatistics,
  getWeekStatistics,
  getDayStatistics,
  getStatisticsDetailByTime,
  getTargetLog,
  getGroupStatistics,
  getMemberStatistics,
  getOrderStatisticsDetailByTime,
} from '@/api/statistics'
import { Pie } from '@antv/g2plot'
import moment from 'moment'
import { logColumns, groupColumns, memberColumns } from './dataLogic'
import LogDetailModal from '@/components/LogDetailModal'

export default {
  name: 'TargetStatistics',
  components: {
    LogDetailModal,
  },
  data() {
    return {
      piePlot: '',
      moment,
      targetTab: 'ALL',
      targetLoading: true,
      taskTab: 'ALL',
      taskLoading: true,
      timeFilter: {
        startTime: moment(
          new Date(new Date().getTime() - 3600 * 1000 * 24 * 30)
        ).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      },
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      targetsPagination: {
        pageNum: 1,
        pageSize: 50,
      },
      activeTargetId: -1,
      activeTargetName: '',
      targetList: [],
      targetTotalPages: '',
      targetStatisticsInfo: {},
      taskInfo: {},

      logColumns,
      logList: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      currentTaskParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
      },
      columnSearchParams: {},
      tableLoading: false,
      tableKey: 1,

      logDetailModalVisible: false,
      logTargetId: '',
      logType: '',

      mode: 'inline',
      theme: 'light',
      targetKey: [],
      onGoingTab: '',

      // 班组统计信息、员工统计信息相关
      userTableKey: 1,
      groupColumns,
      memberColumns,
      userTableLoading: false,
      userStatisticList: [],
      userPagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      currentUserParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
      },
      userColumnSearchParams: {},
      orderStatisticInfo: {},
    }
  },
  computed: {
    ifMonth() {
      return (
        (this.targetKey.length && this.targetKey[0].indexOf('month') !== -1) ||
        (this.onGoingTab && this.onGoingTab === 'month')
      )
    },
    ifWeek() {
      return (
        (this.targetKey.length && this.targetKey[0].indexOf('week') !== -1) ||
        (this.onGoingTab && this.onGoingTab === 'week')
      )
    },
    ifDay() {
      return (
        (this.targetKey.length && this.targetKey[0].indexOf('day') !== -1) ||
        (this.onGoingTab && this.onGoingTab === 'day')
      )
    },
  },
  mounted() {
    this.getTargets(this.targetsPagination)
    this.getTaskStatisticsByTime()
    this.getTargetLog()
  },
  methods: {
    // 获取目标列表
    getTargets(data) {
      getTargets(data).then(async (res) => {
        const handledTree = this.handleFormatTargets(res.data.records, 'month')
        this.targetList.push(...handledTree)
        this.targetTotalPages = res.data.pages
        this.targetList.map((val) => {
          if (val.isOngoing) {
            this.activeTargetName = val.targetName
          }
        })
        this.onGoingTab = 'month'
        await this.getStatistics('month')
        this.getDetailTableInfo('month')
      })
    },
    handleGetChildren(treeNode) {
      return new Promise((resolve, reject) => {
        const { key, id, children } = treeNode.dataRef
        if (!children) {
          resolve()
          return
        }
        if (key.indexOf('month') !== -1) {
          getAllWeekTarget(id).then((res) => {
            const handledTree = this.handleFormatTargets(res.data, 'week')
            children.push(...handledTree)
            resolve()
          })
        }
        if (key.indexOf('week') !== -1) {
          getAllDayTarget(id).then((res) => {
            const handledTree = this.handleFormatTargets(res.data, 'day')
            children.push(...handledTree)
            resolve()
          })
        }
        if (key.indexOf('day') !== -1) {
          resolve()
        }
      })
    },
    handleFormatTargets(targetList, type) {
      const res = targetList.map((target) => {
        target.key = type + '-' + target.id
        target.title = target.targetName
        target.children = []
        target.isLeaf = type === 'day'
        return target
      })
      return res
    },
    //  切换目标
    async handleSwitchTarget(selectedKeys, info) {
      this.onGoingTab = ''
      this.targetKey = selectedKeys
      this.activeTargetName = info.node.dataRef.targetName
      const type = selectedKeys[0].split('-')[0]
      await this.getStatistics(type, info.node.dataRef.id)
      this.getDetailTableInfo(type)
    },
    // 切换当月 当周 当日Tab
    async onGoingTabChange() {
      this.targetKey = []
      await this.getStatistics(this.onGoingTab)
      this.getDetailTableInfo(this.onGoingTab)
    },

    onTargetTabChange() {
      if (this.targetKey.length) {
        this.getStatistics(
          this.targetKey[0].split('-')[0],
          this.targetKey[0].split('-')[1]
        )
      } else {
        this.getStatistics(this.onGoingTab)
      }
    },
    // 获取当前目标任务统计明细信息
    getStatistics(type, id) {
      return new Promise((resolve, reject) => {
        this.targetLoading = true
        let promise
        switch (type) {
          case 'month':
            promise = getMonthStatistics(id, this.targetTab)
            break
          case 'week':
            promise = getWeekStatistics(id, this.targetTab)
            break

          case 'day':
            promise = getDayStatistics(id, this.targetTab)
            break
        }
        promise.then(
          (res) => {
            this.targetStatisticsInfo = res.data
            this.$nextTick(() => {
              this.pieEmpty(this.targetStatisticsInfo, '', 'emergency-')
              this.pieRender(this.targetStatisticsInfo, '', 'emergency-')
              this.targetLoading = false
            })

            resolve()
          },
          (error) => {
            this.targetLoading = false
            reject()
            return error
          }
        )
      })
    },

    // 获取目标统计table
    getDetailTableInfo(type) {
      if (type === 'week') {
        this.userStatisticList = []
        this.getGroupStatistics()
      } else if (type === 'day') {
        this.userStatisticList = []
        this.getMemberStatitics()
      }
    },

    // 根据时间范围获取统计信息
    getTaskStatisticsByTime() {
      this.taskLoading = true
      getStatisticsDetailByTime(this.taskTab, {
        ...this.timeFilter,
      }).then(
        (res) => {
          console.log(res.data)
          this.taskInfo = res.data
          this.$nextTick(() => {
            this.pieEmpty(this.taskInfo, 'task-', 'task-emergency-')
            this.pieRender(this.taskInfo, 'task-', 'task-emergency-')
            this.taskLoading = false
          })
        },
        (error) => {
          this.taskLoading = false
        }
      )
      getOrderStatisticsDetailByTime(this.taskTab, {
        ...this.timeFilter,
      }).then(
        (res) => {
          console.log(res.data)
          this.orderStatisticInfo = res.data
          this.$nextTick(() => {
            this.pieEmpty(this.orderStatisticInfo, 'order-', 'task-emergency-')
            this.pieRender(this.orderStatisticInfo, 'order-', 'task-emergency-')
            this.taskLoading = false
          })
        },
        (error) => {
          this.taskLoading = false
        }
      )
    },
    // 监听滚动到底部
    handleScroll(e) {
      console.log('scroll')
      const { scrollTop, clientHeight, scrollHeight } = e.target
      // console.log(scrollTop, clientHeight, scrollHeight)
      if (scrollTop + clientHeight === scrollHeight) {
        // alert('滚动到底部啦')
        if (this.targetsPagination.pageNum < this.targetTotalPages) {
          this.targetsPagination.pageNum++
          this.getTargets(this.targetsPagination)
        }
      }
    },
    pieEmpty(data, key1, key2) {
      const _info = data.normalTargetStatisticsInfo
      for (const key in _info) {
        if (document.getElementById(`${key1}${key}`)) {
          document.getElementById(`${key1}${key}`).innerHTML = ''
        }
      }
      const _emergencyInfo = data.emergencyTargetStatisticsInfo
      for (const key in _emergencyInfo) {
        if (document.getElementById(`${key2}${key}`)) {
          document.getElementById(`${key2}${key}`).innerHTML = ''
        }
      }
    },
    pieRender(data, key1, key2) {
      const _info = data.normalTargetStatisticsInfo
      for (const key in _info) {
        if (document.getElementById(`${key1}${key}`)) {
          this.pie(`${key1}${key}`, _info[key], key)
        }
      }
      const _emergencyInfo = data.emergencyTargetStatisticsInfo
      for (const key in _emergencyInfo) {
        if (document.getElementById(`${key2}${key}`)) {
          this.pie(`${key2}${key}`, _emergencyInfo[key], key)
        }
      }
    },
    pie(dom, value, key) {
      const data = [
        { type: key, value: value.finishedRate * 100 },
        { type: '其他', value: 100 - value.finishedRate * 100 },
      ]
      let content = ''
      switch (key) {
        case 'amountStatisticsInfo':
          content = '金额'
          break
        case 'productStatisticsInfo':
          content = '产品'
          break
        case 'taskStatisticsInfo':
          content = '任务'
          break
      }
      const piePlot = new Pie(dom, {
        data,
        angleField: 'value',
        colorField: 'type',
        color: ({ type }) => {
          if (type === 'amountStatisticsInfo') {
            return '#00b3bf'
          }
          if (type === 'productStatisticsInfo') {
            return '#fb9920'
          }
          if (type === 'taskStatisticsInfo') {
            return '#fb480f'
          }
          return 'rgb(233,233,233)'
        },
        radius: 1,
        innerRadius: 0.6,
        tooltip: false,
        statistic: {
          title: {
            style: {
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: 14,
            },
            content: content,
          },
          content: {
            style: {
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: 14,
            },
            content: (value.finishedRate * 100).toFixed(0) + '%',
          },
        },
        label: false,
        legend: false,
      })

      this.$nextTick(() => {
        piePlot.render()
      })
    },
    jumpToTargetManage(tab) {
      console.log(this.targetStatisticsInfo)
      this.$router.push({
        name: 'TargetDetail',
        query: {
          id: this.targetStatisticsInfo.targetInfo.id,
          tab: tab,
          targetType: this.ifDay ? 'day' : this.ifMonth ? 'month' : 'week',
          targetName: this.targetStatisticsInfo.targetInfo
            ? this.targetStatisticsInfo.targetInfo.targetName
            : '当前月',
        },
      })
    },
    jump() {
      this.$router.push({
        name: 'TargetManage',
      })
    },
    onDateChange(date) {
      this.timeFilter.startTime = moment(date[0]).format('YYYY-MM-DD HH:mm:ss')
      this.timeFilter.endTime = moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
      this.getTaskStatisticsByTime()
    },

    getTargetLog() {
      const data = {
        ...this.currentTaskParams,
        ...this.columnSearchParams,
      }
      getTargetLog(data).then(
        (res) => {
          this.logList = res.data.records
          this.pagination.total = res.data.total
          this.tableLoading = false
        },
        (error) => {
          this.tableLoading = false
        }
      )
    },
    handleShowLogDetail(column, type) {
      this.logTargetId = column.targetId
      this.logType = type
      this.logDetailModalVisible = true
    },

    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)

      if (filtersKeys.includes('targetType')) {
        delete this.columnSearchParams['targetType']
        if (filters['targetType'].length > 0) {
          this.columnSearchParams['targetType'] = `in:${filters[
            'targetType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['targetType']
        }
      }

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        if (sorter.columnKey === 'code') {
          sorter.columnKey = 'task.code'
        }
        this.currentTaskParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentTaskParams.sort_by = null
      }

      this.pagination = pagination
      this.currentTaskParams.pageNum = pagination.current
      this.currentTaskParams.pageSize = pagination.pageSize
      this.getTargetLog()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          if (dataIndex === 'code') {
            this.columnSearchParams['task.code'] =
              'like:' + selectedKeys[0].trim()
          } else {
            this.columnSearchParams[dataIndex] =
              'like:' + selectedKeys[0].trim()
          }
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          if (dataIndex === 'deliverTime') {
            this.columnSearchParams['plan.deliverTime'] =
              'btn:' + selectedKeys.toString()
          } else if (dataIndex === 'producingNum') {
            this.columnSearchParams['task.producingNum'] =
              'btn:' + selectedKeys.toString()
          } else {
            this.columnSearchParams[dataIndex] =
              'btn:' + selectedKeys.toString()
          }
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    logDetailConfirm() {
      this.logDetailModalVisible = false
    },
    getGroupStatistics() {
      if (!this.targetStatisticsInfo.targetInfo) {
        return
      }
      this.userTableLoading = true
      const data = {
        targetId: this.targetStatisticsInfo.targetInfo.id,
        ...this.currentUserParams,
        ...this.userColumnSearchParams,
      }
      getGroupStatistics(data).then(
        (res) => {
          this.userStatisticList = res.data.records
          this.userPagination.total = res.data.total
          this.userTableLoading = false
        },
        (error) => {
          this.userTableLoading = false
        }
      )
    },
    getMemberStatitics() {
      if (!this.targetStatisticsInfo.targetInfo) {
        return
      }
      this.userTableLoading = true
      const data = {
        targetId: this.targetStatisticsInfo.targetInfo.id,
        ...this.currentUserParams,
        ...this.userColumnSearchParams,
      }
      getMemberStatistics(data).then(
        (res) => {
          this.userStatisticList = res.data.records
          this.userPagination.total = res.data.total
          this.userTableLoading = false
        },
        (error) => {
          this.userTableLoading = false
        }
      )
    },
    handleUserTableChange(pagination) {
      this.userPagination = pagination
      this.currentUserParams.pageNum = pagination.current
      this.currentUserParams.pageSize = pagination.pageSize
      const type = this.onGoingTab || this.targetKey[0].split('-')[0]
      this.getDetailTableInfo(type)
    },
    memberJump(column) {
      this.$router.push({
        name: 'TargetDetail',
        query: {
          id: this.targetStatisticsInfo.targetInfo.id,
          tab: 'DN',
          memberId: column.memberInfo.id,
          name: column.memberInfo.realName,
          groupId: column.groupInfo ? column.groupInfo.id : '',
          targetType: this.ifDay ? 'day' : this.ifMonth ? 'month' : 'week',
          targetName: this.targetStatisticsInfo.targetInfo
            ? this.targetStatisticsInfo.targetInfo.targetName
            : '当前月',
        },
      })
    },
    groupJump(column) {
      this.$router.push({
        name: 'TargetDetail',
        query: {
          id: this.targetStatisticsInfo.targetInfo.id,
          tab: 'DN',
          groupId: column.groupInfo.id,
          name: column.groupInfo.groupName,
          targetType: this.ifDay ? 'day' : this.ifMonth ? 'month' : 'week',
          targetName: this.targetStatisticsInfo.targetInfo
            ? this.targetStatisticsInfo.targetInfo.targetName
            : '当前月',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  border-radius: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 6px rgb(206, 206, 206);
}
/* 非激活窗口 */
::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(206, 206, 206);
}
/deep/ .ant-tree {
  font-size: 16px;
}
/deep/ .ant-btn {
  padding: 0;
}
/deep/ .anticon {
  margin-left: 0px !important;
}
.container {
  width: 100%;
  padding-bottom: 30px;
  background: rgb(240, 242, 245);
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    margin-top: 20px;
    width: 95%;
    height: 600px;
    background: white;
    display: flex;
    .target-current {
      flex: 4.2;
      border-right: 1px solid rgb(212, 212, 212);
      .target-slogan {
        width: 100%;
        height: 180px;
        box-sizing: border-box;
        border-bottom: 1px solid rgb(212, 212, 212);
        padding-top: 16px;
        padding-left: 24px;
        position: relative;
        p {
          margin-bottom: 0;
        }
        .title {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        .tab {
          position: absolute;
          top: 16px;
          left: 50%;
          transform: translateX(-50%);
        }
        .list {
          width: 100%;
          margin-top: 8px;
          display: flex;
          .item {
            flex: 1;
            .data {
              font-size: 26px;
              font-weight: bold;
              text-align: center;
            }
            .desc {
              margin-top: 4px;
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }
    }
    .target-list {
      flex: 1.8;
      .title {
        margin-top: 12px;
        margin-left: 24px;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        padding-right: 16px;
      }
      .list {
        width: 100%;
        height: 480px;
        overflow-x: hidden;
        overflow-y: scroll;
        font-size: 18px;
      }
    }
  }
  .content {
    width: 95%;
    height: 480px;
    margin-top: 20px;
    background: white;
    position: relative;
    .filter {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid rgb(212, 212, 212);
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 60px;
      }
      .date-container {
        display: flex;
        align-items: center;
        font-size: 16px;
        p {
          margin-right: 20px;
        }
      }
    }
    .target-tab {
      height: 420px;
      position: relative;
      .target-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .list {
          .item {
            margin-right: 215px;
          }
        }
      }
    }
  }
  .bottom {
    width: 95%;
    min-height: 100px;
    padding-bottom: 50px;
    margin-top: 20px;
    background: white;
    position: relative;
    .desc {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      padding-top: 12px;
      padding-left: 24px;
      padding-right: 24px;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .table {
      width: 100%;
      margin: 0 auto;
    }
  }
}
/deep/ .ant-table-pagination,
.ant-pagination {
  float: left;
}
.target-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .tab {
    margin-top: 10px;
  }
  .target-content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    position: relative;
    .check {
      .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 17px;
      }
      .list {
        position: relative;
        width: 100%;
        margin-bottom: 30px;
        display: flex;

        .item {
          flex: 1;
          display: flex;
          align-items: center;
          .pie {
            width: 100px;
            height: 100px;
          }
          .desc {
            margin-left: 12px;
          }
        }
        .btn {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          width: 90px;
        }
      }
    }
  }
}
.page-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /deep/ .anticon {
    font-size: 48px;
    color: white;
  }
}
</style>
