export const directPayColumns = [
  {
    title: '顺序号',
    dataIndex: 'sequence',
    key: 'sequence',
    align: 'center',
  },
  {
    title: '工序编号',
    dataIndex: 'operationInfo.code',
    key: 'operationInfo.code',
    align: 'center',
  },
  {
    title: '工序名称',
    dataIndex: 'operationInfo.name',
    key: 'operationInfo.name',
    align: 'center',
  },
  {
    title: '工序类型',
    dataIndex: 'operationInfo.type',
    key: 'operationInfo.type',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'operationInfoType',
    },
  },
  {
    title: '工时单价',
    dataIndex: 'operationInfo.unitPrice',
    key: 'operationInfo.unitPrice',
    align: 'center',
  },
  {
    title: '工时',
    dataIndex: 'workTime',
    key: 'workTime',
    align: 'center',
  },
  {
    title: '操作',
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
