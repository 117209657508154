<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    :visible="true"
    :title="title"
    :mask-closable="false"
    width="600px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model
      ref="form"
      :rules="rules"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="备注" prop="comment">
        <a-textarea
          v-model.trim="form.comment"
          :placeholder="placeholder"
          :max-length="500"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { createReturnRecord } from '@/api/qualities'
export default {
  props: {
    type: String,
    recordsData: Array,
  },
  data() {
    return {
      title: '退库交接单信息',
      placeholder: '退库单交接单备注',
      labelCol: {
        span: 7,
      },
      wrapperCol: {
        span: 10,
        offset: 1,
      },
      template: 1,
      cardType: 0,
      form: {
        comment: '',
      },
      rules: {},
    }
  },
  methods: {
    initData() {
      this.form.comment = ''
    },
    handleCancel() {
      this.initData()
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.form.validate((valid) => {
        const that = this
        if (valid) {
          const params = {
            returnRecordIds: this.recordsData,
            returnComment: this.form.comment,
          }
          createReturnRecord(params).then((res) => {
            if (res.data) {
              this.$confirm({
                title: '单据生成成功',
                content: '退库交接单生成成功，是否需要打印',
                okText: '打印',
                onOk() {
                  const route = that.$router.resolve({
                    path: '/return-inventory-card',
                    query: {
                      receiptNumber: res.data,
                    },
                  })
                  window.open(route.href, '_blank')
                  that.$emit('confirm')
                },
                cancelText: '暂不打印',
                onCancel() {
                  that.$emit('confirm')
                },
              })
            }
          })
        } else {
          console.log('error submit!!', valid)
          return false
        }
        this.initData()
      })
    },
  },
}
</script>

<style scoped></style>
