import request from '@/utils/api.request'

/**
 * 分页获取客户
 * @returns
 */
export function getSuppliers(data) {
  return request({
    url: `/tenants/suppliers`,
    method: 'get',
    params: data,
  })
}

export function getAllSuppliers(data) {
  return request({
    url: `/tenants/suppliers/all`,
    method: 'get',
    params: data,
  })
}

export function getSuppliersImportTemplate() {
  return request({
    url: `/tenants/suppliers/import-template`,
    method: 'get',
    responseType: 'blob',
  })
}

/**
 * 导出所有供应商
 */
export function exportAllSuppliers(data) {
  return request({
    url: `/tenants/suppliers/exports`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}
