export const EmergencyType = {
  NML: '普通',
  MFN: '必完件',
  UGC: '紧急件',
  IUG: '插入急件',
  TUG: '转入急件',
}

export const RepairEffectType = {
  FX: '返修',
  BT: '报废补投',
  TH: '退回客户',
}

export const PollingTime = 60 * 1000 * 10 // 10分钟

// 数据类型 ODI-订单延误指数 WHI-库存指数 TPS-任务进展状态占比 TPT-任务平均生产周期 TAA-累计报废金额 POS-工序产品平均停留时间 PGS-班组产品平均停留时间
export const EStaticParamsType = {
  /** 订单延误指数 */
  ODI: 'ODI',
  /** 库存指数 */
  WHI: 'WHI',
  /** 任务进展状态占比 */
  TPS: 'TPS',
  /** 任务平均生产周期 */
  TPT: 'TPT',
  /** 累计报废金额 */
  TAA: 'TAA',
  /** 工序产品平均停留时间 */
  POS: 'POS',
  /** 班组产品平均停留时间 */
  PGS: 'PGS',
  /** 有效产出 */
  EFP: 'EFP',
}

// 统计维度 DAY-每天 WEEK-每周 MONTH-每月
export const EStaticParamsDimension = {
  /** 每天 */
  DAY: 'DAY',
  /** 每周 */
  WEEK: 'WEEK',
  /** 每月 */
  MONTH: 'MONTH',
}

export const EParamsPrefix = {
  EQ: 'eq:',
  BTN: 'btn:',
}
