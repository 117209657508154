<template>
  <a-modal v-drag-modal class="modal-container" visible="true" title="标准工艺路线" width="60%" @cancel="handleCancel">
    <template slot="footer">
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <div class="modal-body">
      <template v-if="versionList.length">
        <label v-if="selectVisible && versionList.length > 1" for="select">当前卡片版本：</label>
        <a-select
          v-if="selectVisible && versionList.length > 1"
          id="select"
          placeholder="请选择卡片版本"
          style="width: 200px; margin-bottom: 20px;display: inline-block;"
          @change="handleChange"
        >
          <a-select-option v-for="i in versionList" :key="i">{{ i }}</a-select-option>
        </a-select>
      </template>
      <a-empty v-if="processRoute.length===0" />
      <template v-if="processRoute.length">
        <a-steps size="small" direction="vertical">
          <a-step
            v-for="(process, index) in processRoute"
            :key="index"
            :title="'第'+process.sequenceNum+'步——'+process.operationName"
            :sub-title="process.verifyProcessHour&&process.verifyTransferOutTime?'  核定工时：'+process.verifyProcessHour+'小时 '+'     核定转出日期：'+process.verifyTransferOutTime:''"
            status="finish"
            :description="process.content"
          />
        </a-steps>
      </template>
    </div>
  </a-modal>
</template>
<script>
export default {
  props: {
    modalTitle: String,
    record: Object,
    craftData: Object,
    currentVersion: String,
    selectVisible: Boolean,
  },
  data() {
    return {
      code: this.modalTitle ? this.modalTitle : '',
      processRoute: this.record ? this.record : [],
      versionRoute: new Map(),
      versionList: [],
    }
  },
  computed: {},
  mounted() {
    console.log(this.processRoute, this.versionList)
    this.craftData.map((val) => {
      if (
        val.operationCardHeaderDTO.operationCardBaseDTO.operationCardVersion
      ) {
        this.versionList.push(
          val.operationCardHeaderDTO.operationCardBaseDTO.operationCardVersion
        )
        this.versionRoute.set(
          val.operationCardHeaderDTO.operationCardBaseDTO.operationCardVersion,
          val.processRoute
        )
      }
    })
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm')
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleChange(val) {
      this.processRoute = this.versionRoute.get(val)
    },
  },
}
</script>

<style scoped>
.modal-body {
  padding: 10px 150px;
  max-height: 500px;
  overflow-y: scroll;
}

/deep/ .ant-modal-body {
  padding: 5px;
}
</style>
