import request from '@/utils/api.request'

/**
 * 分页获取首页任务相关信息
 * @param {object} data
 */
 export function getDashboardTasks(data) {
  return request({
    url: `/dashboard/tasks`,
    method: 'get',
    params: data
  })
}

export function getProcessRouteByPlanId(planId){
  return request({
    url: `/dashboard/${planId}/process-route`,
    method: 'get',
  })
}
