var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c(
        "div",
        { staticClass: "custom-table", attrs: { id: "craft-card-print" } },
        [
          _c(
            "div",
            { staticStyle: { "page-break-after": "always" } },
            [
              _c("div", { staticClass: "title" }, [
                _c("div", { staticClass: "left" }, [
                  _vm._v("入库类型：" + _vm._s(_vm.data.productType)),
                ]),
                _c("div", { staticClass: "middle" }, [
                  _vm._v("重庆市DBR刀具有限公司产成品入库单"),
                ]),
                _c("div", { staticClass: "right" }, [
                  _vm._v("入库单号：" + _vm._s(_vm.data.putInNumber)),
                ]),
              ]),
              _c("a-table", {
                attrs: {
                  columns: _vm.columns,
                  "data-source": _vm.productList,
                  bordered: "",
                  pagination: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "amount",
                    fn: function (text) {
                      return _c("p", { staticClass: "amount-font" }, [
                        _vm._v(_vm._s(text)),
                      ])
                    },
                  },
                ]),
              }),
              _c(
                "a-descriptions",
                { attrs: { bordered: "" } },
                [
                  _c("a-descriptions-item", { attrs: { label: "数量合计" } }, [
                    _c("p", { staticClass: "amount-font" }, [
                      _vm._v(_vm._s(_vm.data.totalPutInAmount)),
                    ]),
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "金额合计" } }, [
                    _vm._v(_vm._s(_vm.data.totalProductionPrice)),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "footer-form", staticStyle: { float: "left" } },
                [
                  _c("span", [
                    _vm._v("制单人：" + _vm._s(_vm.data.putInReceiptUser)),
                  ]),
                  _c("div", { staticClass: "bottom-line" }),
                ]
              ),
              _c("div", { staticClass: "footer-form" }, [
                _c("span", [_vm._v("计划员：")]),
                _c("div", { staticClass: "bottom-line" }),
                _c("span", [_vm._v("日期：")]),
                _c("span", [_vm._v(_vm._s(_vm.data.receiptTime))]),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-button",
        {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: "craft-card-print",
              expression: "'craft-card-print'",
            },
          ],
          attrs: { type: "primary", block: "" },
        },
        [_vm._v("打印")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }