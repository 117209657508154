var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "left" }, [
        _c(
          "div",
          { staticClass: "container-top" },
          [
            _c("a-input-search", {
              staticClass: "input-search",
              attrs: { placeholder: "搜索" },
              on: { change: _vm.onSearchChange },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "menu" },
          [
            _c("a-tree", {
              attrs: {
                "tree-data": _vm.treeData,
                "selected-keys": _vm.selectedKeys,
                "expanded-keys": _vm.expandedKeys,
                "auto-expand-parent": _vm.autoExpandParent,
              },
              on: {
                select: _vm.handleSelect,
                rightClick: _vm.handleRightClick,
                expand: _vm.onExpand,
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function (ref) {
                    var treeKey = ref.key
                    var title = ref.title
                    return [
                      _c(
                        "a-dropdown",
                        {
                          attrs: { trigger: ["contextmenu"] },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "overlay",
                                fn: function () {
                                  return [
                                    _c(
                                      "a-menu",
                                      {
                                        on: {
                                          click: function (ref) {
                                            var menuKey = ref.key

                                            return _vm.onContextMenuClick(
                                              treeKey,
                                              menuKey
                                            )
                                          },
                                        },
                                      },
                                      [
                                        treeKey.indexOf("week") > -1 &&
                                        treeKey.indexOf("day") === -1
                                          ? _c(
                                              "a-menu-item",
                                              { key: "addDay" },
                                              [_vm._v("添加日目标")]
                                            )
                                          : _vm._e(),
                                        treeKey.indexOf("day") > -1 &&
                                        treeKey.indexOf("group") === -1
                                          ? [
                                              _c(
                                                "a-menu-item",
                                                { key: "editDay" },
                                                [_vm._v("更新日目标")]
                                              ),
                                              _c(
                                                "a-menu-item",
                                                { key: "deleteDay" },
                                                [_vm._v("删除日目标")]
                                              ),
                                            ]
                                          : _vm._e(),
                                        treeKey.indexOf("member") > -1
                                          ? [
                                              _c(
                                                "a-menu-item",
                                                { key: "editMemberPlan" },
                                                [_vm._v("规划成员任务")]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          title.indexOf(_vm.searchValue) > -1
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      title.substr(
                                        0,
                                        title.indexOf(_vm.searchValue)
                                      )
                                    ) +
                                    " "
                                ),
                                _c("span", { staticStyle: { color: "#f50" } }, [
                                  _vm._v(_vm._s(_vm.searchValue)),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      title.substr(
                                        title.indexOf(_vm.searchValue) +
                                          _vm.searchValue.length
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v(_vm._s(title))]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _vm.taskList.length || _vm.columnSearchParams !== {}
            ? [
                _c("div", { staticClass: "top-tip" }, [
                  _c(
                    "div",
                    { staticClass: "selected-wrapper" },
                    [
                      _vm.hasSelected
                        ? _c("span", [
                            _vm._v(" 已选中 "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.selectedRowKeys.length)),
                            ]),
                            _vm._v(" 项任务 "),
                          ])
                        : _vm._e(),
                      _vm.hasSelected &&
                      _vm.selectedKeys[0].indexOf("member") > 1
                        ? _c(
                            "a-dropdown",
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "batch-operate-link",
                                  on: {
                                    click: function (e) {
                                      return e.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _vm._v("批量操作 "),
                                ],
                                1
                              ),
                              _c(
                                "a-menu",
                                { attrs: { slot: "overlay" }, slot: "overlay" },
                                [
                                  _c("a-menu-item", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDeleteTask(
                                              _vm.selectedTaskIds
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("批量删除任务")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("a-table", {
                  key: _vm.tableKey,
                  staticStyle: { "margin-top": "16px" },
                  attrs: {
                    "row-key": "id",
                    "row-selection": {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onSelectChange,
                      getCheckboxProps: _vm.targetRowSelection.getCheckboxProps,
                    },
                    bordered: "",
                    scroll: { x: 2000 },
                    columns: _vm.taskColumns,
                    "data-source": _vm.taskList,
                    loading: _vm.loading,
                    pagination: _vm.pagination,
                  },
                  on: { change: _vm.handleTableChange },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "isParts",
                        fn: function (text) {
                          return _c(
                            "div",
                            {},
                            [
                              text === 0
                                ? [
                                    _c("a-tag", { attrs: { color: "red" } }, [
                                      _vm._v("否"),
                                    ]),
                                  ]
                                : _vm._e(),
                              text === 1
                                ? [
                                    _c("a-tag", { attrs: { color: "green" } }, [
                                      _vm._v("是"),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        },
                      },
                      {
                        key: "emergencyType",
                        fn: function (text) {
                          return _c(
                            "span",
                            {},
                            [
                              _c("a-tag", { attrs: { color: "volcano" } }, [
                                _vm._v(_vm._s(_vm.EmergencyType[text])),
                              ]),
                            ],
                            1
                          )
                        },
                      },
                      {
                        key: "code",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            {},
                            [
                              column.taskType
                                ? [
                                    _c(
                                      "a-badge",
                                      {
                                        attrs: {
                                          count: "计划外",
                                          offset: [10, -10],
                                          "number-style": {
                                            fontSize: "12px",
                                            backgroundColor: "#fff",
                                            color: "red",
                                            boxShadow:
                                              "0 0 0 2px #d9d9d9 inset",
                                          },
                                        },
                                      },
                                      [_c("span", [_vm._v(_vm._s(text))])]
                                    ),
                                  ]
                                : [_vm._v(_vm._s(text))],
                            ],
                            2
                          )
                        },
                      },
                      {
                        key: "filterDropdown",
                        fn: function (ref) {
                          var setSelectedKeys = ref.setSelectedKeys
                          var selectedKeys = ref.selectedKeys
                          var confirm = ref.confirm
                          var clearFilters = ref.clearFilters
                          var column = ref.column
                          return _c(
                            "div",
                            { staticStyle: { padding: "8px" } },
                            [
                              column.searchType === "text"
                                ? [
                                    _c("a-input", {
                                      directives: [
                                        {
                                          name: "ant-ref",
                                          rawName: "v-ant-ref",
                                          value: function (c) {
                                            return (_vm.searchInput = c)
                                          },
                                          expression: "c => (searchInput = c)",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "188px",
                                        "margin-bottom": "8px",
                                        display: "block",
                                      },
                                      attrs: {
                                        placeholder: "搜索 " + column.title,
                                        value: selectedKeys[0],
                                      },
                                      on: {
                                        change: function (e) {
                                          return setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        },
                                        pressEnter: function () {
                                          return _vm.handleSearch(
                                            selectedKeys,
                                            confirm,
                                            column.key
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                : column.searchType === "number"
                                ? _c(
                                    "div",
                                    [
                                      _c("a-input-number", {
                                        staticStyle: {
                                          width: "95px",
                                          "margin-bottom": "8px",
                                          display: "inline-block",
                                        },
                                        attrs: {
                                          size: "small",
                                          placeholder: "最小值",
                                          min: 0,
                                          max: 999999,
                                        },
                                        on: {
                                          change: function (e) {
                                            return setSelectedKeys([
                                              e,
                                              selectedKeys[1],
                                            ])
                                          },
                                          pressEnter: function () {
                                            return _vm.handleSearch(
                                              selectedKeys,
                                              confirm,
                                              column.key
                                            )
                                          },
                                        },
                                        model: {
                                          value: selectedKeys[0],
                                          callback: function ($$v) {
                                            _vm.$set(selectedKeys, 0, $$v)
                                          },
                                          expression: "selectedKeys[0]",
                                        },
                                      }),
                                      _c("span", [_vm._v("-")]),
                                      _c("a-input-number", {
                                        staticStyle: {
                                          width: "95px",
                                          "margin-bottom": "8px",
                                          display: "inline-block",
                                        },
                                        attrs: {
                                          size: "small",
                                          placeholder: "最大值",
                                          min: selectedKeys[0],
                                          max: 999999,
                                        },
                                        on: {
                                          change: function (e) {
                                            return setSelectedKeys([
                                              selectedKeys[0],
                                              e,
                                            ])
                                          },
                                          pressEnter: function () {
                                            return _vm.handleSearch(
                                              selectedKeys,
                                              confirm,
                                              column.key
                                            )
                                          },
                                        },
                                        model: {
                                          value: selectedKeys[1],
                                          callback: function ($$v) {
                                            _vm.$set(selectedKeys, 1, $$v)
                                          },
                                          expression: "selectedKeys[1]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : column.searchType === "date"
                                ? _c(
                                    "div",
                                    [
                                      _c("a-range-picker", {
                                        staticStyle: {
                                          width: "330px",
                                          "margin-bottom": "8px",
                                        },
                                        attrs: {
                                          "show-time": { format: "HH:mm:ss" },
                                          size: "small",
                                          ranges: {
                                            今天: [
                                              _vm.$moment(),
                                              _vm.$moment(),
                                            ],
                                            本月: [
                                              _vm.$moment().startOf("month"),
                                              _vm.$moment().endOf("month"),
                                            ],
                                          },
                                        },
                                        on: {
                                          change: function (
                                            dates,
                                            dateStrings
                                          ) {
                                            return setSelectedKeys(
                                              [].concat(dateStrings)
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "a-button",
                                {
                                  staticStyle: {
                                    width: "90px",
                                    "margin-right": "8px",
                                  },
                                  attrs: {
                                    type: "primary",
                                    icon: "search",
                                    size: "small",
                                  },
                                  on: {
                                    click: function () {
                                      return _vm.handleSearch(
                                        selectedKeys,
                                        confirm,
                                        column.key
                                      )
                                    },
                                  },
                                },
                                [_vm._v("搜索")]
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { width: "90px" },
                                  attrs: { size: "small" },
                                  on: {
                                    click: function () {
                                      return _vm.handleReset(
                                        selectedKeys,
                                        clearFilters,
                                        column.key
                                      )
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            2
                          )
                        },
                      },
                      {
                        key: "filterIcon",
                        fn: function (filtered) {
                          return _c("a-icon", {
                            style: { color: filtered ? "#108ee9" : undefined },
                            attrs: { type: "search" },
                          })
                        },
                      },
                      {
                        key: "operation",
                        fn: function (text, column) {
                          return (column.status === 2 ||
                            column.status === 4 ||
                            column.status === 5 ||
                            column.status === 8) &&
                            _vm.selectedKeys[0].indexOf("member") > 1
                            ? _c(
                                "span",
                                { staticClass: "table-operation" },
                                [
                                  _c(
                                    "a-dropdown",
                                    { attrs: { placement: "bottomRight" } },
                                    [
                                      _c(
                                        "a-menu",
                                        {
                                          staticClass: "ant-dropdown-link",
                                          attrs: { slot: "overlay" },
                                          slot: "overlay",
                                        },
                                        [
                                          _c(
                                            "a-menu-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDeleteTask(
                                                    column.taskId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除任务")]
                                          ),
                                          _c(
                                            "a-menu-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleUpdateTargetNum(
                                                    column
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("修改目标数量")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a",
                                        { staticClass: "operation-btn" },
                                        [
                                          _vm._v(" 操作 "),
                                          _c("a-icon", {
                                            attrs: { type: "down" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e()
                        },
                      },
                      {
                        key: "status",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.selectedKeys.length &&
                              _vm.selectedKeys[0].indexOf("day") > 1
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: { type: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleShowProcessRoute(
                                            column
                                          )
                                        },
                                      },
                                    },
                                    [
                                      text === 0
                                        ? [
                                            _c("a-badge", {
                                              attrs: { status: "default" },
                                            }),
                                            _vm._v("准备中 "),
                                          ]
                                        : _vm._e(),
                                      text === 1
                                        ? [
                                            _c("a-badge", {
                                              attrs: { status: "default" },
                                            }),
                                            _vm._v("未开始 "),
                                          ]
                                        : _vm._e(),
                                      text === 2
                                        ? [
                                            _c("a-badge", {
                                              attrs: { status: "processing" },
                                            }),
                                            _vm._v("进行中 "),
                                          ]
                                        : _vm._e(),
                                      text === 3
                                        ? [
                                            _c("a-badge", {
                                              attrs: { status: "success" },
                                            }),
                                            _vm._v("已完成 "),
                                          ]
                                        : _vm._e(),
                                      text === 4
                                        ? [
                                            _c("a-badge", {
                                              attrs: { status: "error" },
                                            }),
                                            _vm._v("超期进行中 "),
                                          ]
                                        : _vm._e(),
                                      text === 5
                                        ? [
                                            _c("a-badge", {
                                              attrs: { status: "warning" },
                                            }),
                                            _vm._v("即将超期 "),
                                          ]
                                        : _vm._e(),
                                      text === 9
                                        ? [
                                            _c("a-badge", {
                                              attrs: { status: "warning" },
                                            }),
                                            _vm._v("暂停中 "),
                                          ]
                                        : _vm._e(),
                                      text === 8
                                        ? [
                                            _c("a-badge", {
                                              attrs: { status: "error" },
                                            }),
                                            _vm._v("已取消 "),
                                          ]
                                        : _vm._e(),
                                      text === 10
                                        ? [
                                            _c("a-badge", {
                                              attrs: { status: "error" },
                                            }),
                                            _vm._v("异常终止 "),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : [
                                    text === 0
                                      ? [
                                          _c("a-badge", {
                                            attrs: { status: "default" },
                                          }),
                                          _vm._v("准备中 "),
                                        ]
                                      : _vm._e(),
                                    text === 1
                                      ? [
                                          _c("a-badge", {
                                            attrs: { status: "default" },
                                          }),
                                          _vm._v("未开始 "),
                                        ]
                                      : _vm._e(),
                                    text === 2
                                      ? [
                                          _c("a-badge", {
                                            attrs: { status: "processing" },
                                          }),
                                          _vm._v("进行中 "),
                                        ]
                                      : _vm._e(),
                                    text === 3
                                      ? [
                                          _c("a-badge", {
                                            attrs: { status: "success" },
                                          }),
                                          _vm._v("已完成 "),
                                        ]
                                      : _vm._e(),
                                    text === 9
                                      ? [
                                          _c("a-badge", {
                                            attrs: { status: "warning" },
                                          }),
                                          _vm._v("暂停中 "),
                                        ]
                                      : _vm._e(),
                                    text === 8
                                      ? [
                                          _c("a-badge", {
                                            attrs: { status: "error" },
                                          }),
                                          _vm._v("已取消 "),
                                        ]
                                      : _vm._e(),
                                    text === 10
                                      ? [
                                          _c("a-badge", {
                                            attrs: { status: "error" },
                                          }),
                                          _vm._v("异常终止 "),
                                        ]
                                      : _vm._e(),
                                  ],
                            ],
                            2
                          )
                        },
                      },
                      {
                        key: "taskStatus",
                        fn: function (text) {
                          return _c(
                            "div",
                            {},
                            [
                              text === "P"
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "processing" },
                                    }),
                                    _vm._v("未完成 "),
                                  ]
                                : _vm._e(),
                              text === "F"
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "success" },
                                    }),
                                    _vm._v("已完成 "),
                                  ]
                                : _vm._e(),
                              text === "X"
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "default" },
                                    }),
                                    _vm._v("不涉及 "),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        },
                      },
                      {
                        key: "process",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            { staticClass: "table-process" },
                            [
                              column.remainProcessRoute.length > 0
                                ? [
                                    _c(
                                      "a-steps",
                                      {
                                        attrs: {
                                          current:
                                            column.remainProcessRoute.length -
                                            1,
                                          status: "finish",
                                          size: "small",
                                        },
                                      },
                                      _vm._l(
                                        column.remainProcessRoute,
                                        function (process) {
                                          return _c(
                                            "a-step",
                                            {
                                              key: process.id,
                                              attrs: {
                                                title: process.operationName,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "process-desc",
                                                  attrs: {
                                                    slot: "description",
                                                  },
                                                  slot: "description",
                                                },
                                                [
                                                  process.operationType > 0 &&
                                                  process.verifyProcessHour
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "description-font",
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              "加工工时："
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                process.verifyProcessHour
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  process.operationType > 0 &&
                                                  process.verifyTransferOutTime
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "description-font",
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              "转出日期："
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                process.verifyTransferOutTime
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm.selectedKeys.length
                  ? [
                      _vm.selectedKeys[0].indexOf("day") > 1
                        ? _c(
                            "el-dropdown",
                            {
                              staticClass: "export-plan",
                              on: { command: _vm.handleExportPlanCommand },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    loading: _vm.exportLoading,
                                    size: "medium",
                                    type: "primary",
                                    icon: "el-icon-download",
                                  },
                                },
                                [_vm._v("导出目标")]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "FILTERED" } },
                                    [_vm._v("导出当前筛选目标")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ]
            : [
                _vm.targetList.length
                  ? [
                      _c("a-empty", { staticClass: "empty" }, [
                        _c(
                          "span",
                          {
                            attrs: { slot: "description" },
                            slot: "description",
                          },
                          [_vm._v("暂无任务信息")]
                        ),
                      ]),
                    ]
                  : [
                      _c(
                        "a-empty",
                        { staticClass: "empty" },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "description" },
                              slot: "description",
                            },
                            [_vm._v("暂无目标信息")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleAddTarget },
                            },
                            [_vm._v("添加目标")]
                          ),
                        ],
                        1
                      ),
                    ],
              ],
        ],
        2
      ),
      _vm.taskEditModalVisible
        ? _c("TargetEdit", {
            attrs: {
              "modal-title": _vm.editType,
              "target-form": _vm.editTarget,
              "father-target-id": Number(_vm.activeTargetKey.split("-")[1]),
              "target-type": "day",
            },
            on: { confirm: _vm.targetConfirm, cancel: _vm.targetCancel },
          })
        : _vm._e(),
      _vm.targetTaskModalVisible
        ? _c("DayTargetTaskEdit", {
            attrs: {
              id: _vm.editDayTargetId,
              title: _vm.editTitle,
              "group-id": _vm.editGroupId,
              "member-id": _vm.editMemberId,
            },
            on: { confirm: _vm.taskEditConfirm, cancel: _vm.taskEditCancel },
          })
        : _vm._e(),
      _vm.targetNumEditModalVisible
        ? _c("TargetNumEditModal", {
            attrs: { "target-form": _vm.targetNumEditItem },
            on: {
              confirm: _vm.targetNumEditConfirm,
              cancel: _vm.targetNumEditCancel,
            },
          })
        : _vm._e(),
      _vm.targetProcessRouteModalVisible
        ? _c("TargetProcessRouteModal", {
            attrs: {
              "target-id": _vm.selectedKeys[0].split("-")[3],
              "task-id": _vm.processRouteTaskId,
              "task-code": _vm.processRouteTaskCode,
            },
            on: { confirm: _vm.handleShowProcessRouteConfirm },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }