var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("a-table", {
        staticStyle: { "margin-top": "16px" },
        attrs: {
          "row-key": "id",
          bordered: "",
          scroll: { x: 2000, y: 800 },
          columns: _vm.taskColumns,
          "data-source": _vm.taskList,
          loading: _vm.loading,
          pagination: _vm.pagination,
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "emergencyType",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  _c("a-tag", { attrs: { color: "volcano" } }, [
                    _vm._v(_vm._s(_vm.EmergencyType[text])),
                  ]),
                ],
                1
              )
            },
          },
          {
            key: "code",
            fn: function (text, column) {
              return _c(
                "div",
                {},
                [
                  column.taskType
                    ? [
                        _c(
                          "a-badge",
                          {
                            attrs: {
                              count: "计划外",
                              offset: [10, -10],
                              "number-style": {
                                fontSize: "12px",
                                backgroundColor: "#fff",
                                color: "red",
                                boxShadow: "0 0 0 2px #d9d9d9 inset",
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(text))])]
                        ),
                      ]
                    : [_vm._v(_vm._s(text))],
                ],
                2
              )
            },
          },
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.key
                              )
                            },
                          },
                        }),
                      ]
                    : column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "330px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              "default-value": [
                                _vm.moment(new Date()),
                                _vm.moment(new Date()),
                              ],
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("准备中 "),
                      ]
                    : _vm._e(),
                  text === 1
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("未开始 "),
                      ]
                    : _vm._e(),
                  text === 2
                    ? [
                        _c("a-badge", { attrs: { status: "processing" } }),
                        _vm._v("进行中 "),
                      ]
                    : _vm._e(),
                  text === 3
                    ? [
                        _c("a-badge", { attrs: { status: "success" } }),
                        _vm._v("已完成 "),
                      ]
                    : _vm._e(),
                  text === 9
                    ? [
                        _c("a-badge", { attrs: { status: "warning" } }),
                        _vm._v("暂停中 "),
                      ]
                    : _vm._e(),
                  text === 8
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("已取消 "),
                      ]
                    : _vm._e(),
                  text === 10
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("异常终止 "),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "taskStatus",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === "P"
                    ? [
                        _c("a-badge", { attrs: { status: "processing" } }),
                        _vm._v("未完成 "),
                      ]
                    : _vm._e(),
                  text === "F"
                    ? [
                        _c("a-badge", { attrs: { status: "success" } }),
                        _vm._v("已完成 "),
                      ]
                    : _vm._e(),
                  text === "X"
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("不涉及 "),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "taskType",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0 ? [_vm._v(" 否 ")] : _vm._e(),
                  text === 1 ? [_vm._v(" 是 ")] : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "process",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-process" },
                [
                  column.remainProcessRoute &&
                  column.remainProcessRoute.length > 0
                    ? [
                        _c(
                          "a-steps",
                          {
                            attrs: {
                              current: column.remainProcessRoute.length - 1,
                              status: "finish",
                              size: "small",
                            },
                          },
                          _vm._l(column.remainProcessRoute, function (process) {
                            return _c(
                              "a-step",
                              {
                                key: process.id,
                                attrs: { title: process.operationName },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "process-desc",
                                    attrs: { slot: "description" },
                                    slot: "description",
                                  },
                                  [
                                    process.operationType > 0 &&
                                    process.verifyProcessHour
                                      ? _c(
                                          "p",
                                          { staticClass: "description-font" },
                                          [
                                            _c("b", [_vm._v("加工工时：")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  process.verifyProcessHour
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    process.operationType > 0 &&
                                    process.verifyTransferOutTime
                                      ? _c(
                                          "p",
                                          { staticClass: "description-font" },
                                          [
                                            _c("b", [_vm._v("转出日期：")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  process.verifyTransferOutTime
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
        ]),
      }),
      _c(
        "el-dropdown",
        {
          staticClass: "export-plan",
          on: { command: _vm.handleExportPlanCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出目标")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选目标"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }