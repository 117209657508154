<template>
  <a-modal v-drag-modal class="modal-container" visible="true" title="结算信息录入" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="form" :rules="rules" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="已选金额">
        <span>{{ amount }}</span>
      </a-form-model-item>
      <a-form-model-item label="实际结算金额" prop="settlePrice">
        <a-input v-model.trim="form.settlePrice" :max-length="20" placeholder="请输入实际结算金额" />
      </a-form-model-item>
      <a-form-model-item label="发票号/单据号" prop="invoiceNumber">
        <a-input v-model.trim="form.invoiceNumber" :max-length="20" placeholder="请输入发票号/单据号" />
      </a-form-model-item>
      <a-form-model-item label="结算类型" prop="settleType">
        <a-radio-group v-model="form.settleType" style="width: 400px; fontWeight: 400;">
          <a-radio :value="1">现款</a-radio>
          <a-radio :value="0">挂账</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="结算时间" prop="settleTime">
        <a-date-picker v-model="form.settleTime" show-time type="date" placeholder="请选择结算时间" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="settleComment">
        <a-textarea v-model.trim="form.settleComment" max-length="500" placeholder="请输入结算备注" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { putSettleRecords, getSettleAmount } from '@/api/inventory'
import Moment from 'moment'
export default {
  props: {
    settleData: Object,
  },
  data() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 10,
        offset: 1,
      },
      form: {
        settlePrice: '',
        invoiceNumber: '',
        settleComment: '',
        settleType: 1,
        settleTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      },
      rules: {
        settlePrice: [
          {
            required: true,
            message: '请输入实际结算金额',
            trigger: 'blur',
          },
        ],
        invoiceNumber: [
          {
            required: true,
            message: '请输入发票号/单据号',
            trigger: 'blur',
          },
        ],
        settleType: [
          {
            required: true,
            message: '请选择结算类型',
            trigger: 'change',
          },
        ],
        settleTime: [
          {
            required: true,
            message: '请选择结算时间',
            trigger: 'change',
          },
        ],
      },
      amount: 0,
    }
  },
  mounted() {
    getSettleAmount({ settleRecordIds: this.settleData.join(',') }).then(
      (res) => {
        this.amount = res.data
      }
    )
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.settleTime = Moment(this.form.settleTime).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          const params = {
            inOutRecordIds: this.settleData,
            settlePrice: this.form.settlePrice,
            invoiceNumber: this.form.invoiceNumber,
            settleComment: this.form.settleComment,
            settleType: this.form.settleType,
            settleTime: this.form.settleTime,
          }
          putSettleRecords(params).then((res) => {
            this.$message.success('结算成功')
            this.$emit('confirm')
          })
        } else {
          console.log('error submit!!', valid)
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
