<template>
  <a-modal class="modal-container" visible="true" :title="title" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="parameter" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="参数类型" prop="parameterType">
        <a-radio-group v-model="parameter.parameterType" :disabled="isUpdate" style="width: 400px; fontWeight: 400;">
          <a-radio value="根参数">根参数</a-radio>
          <a-radio value="父参数">父参数</a-radio>
          <a-radio value="子参数">子参数</a-radio>
          <a-radio value="其他">其他</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="parameter.parameterType === '子参数'||parameter.parameterType === '父参数'" label="父参数" prop="parentParameterId">
        <a-select v-model="parameter.parentParameterId">
          <a-select-option v-for="item in parentParameter" :key="item.id" :value="item.id">{{ item.parameterName }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="参数编码" prop="parameterCode">
        <a-input
          v-model.trim="parameter.parameterCode"
          maxlength="20"
          :disabled="isUpdate&&parameter.parameterType === '根参数'"
          placeholder="请输入参数编码"
        />
      </a-form-model-item>
      <a-form-model-item label="参数名称" prop="parameterName">
        <a-input v-model.trim="parameter.parameterName" maxlength="20" placeholder="请输入参数名称" />
      </a-form-model-item>
      <a-form-model-item label="参数值" prop="parameterValue">
        <a-input v-model.trim="parameter.parameterValue" maxlength="20" placeholder="请输入参数值" />
      </a-form-model-item>
      <a-form-model-item label="参数描述" prop="parameterDesc">
        <a-input v-model.trim="parameter.parameterDesc" maxlength="20" placeholder="请输入参数描述" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import * as R from 'ramda'
import {
  addParameter,
  updateParameter,
  getAllParameters,
} from '@/api/parameter'
export default {
  props: {
    modalTitle: String,
    parameterForm: Object,
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      loading: false,
      parentParameter: [],
      parameter: {
        id: this.parameterForm.id,
        parentParameterId: this.parameterForm.parentParameterId,
        parameterType: this.parameterForm.parameterType,
        parameterValue: this.parameterForm.parameterValue,
        parameterCode: this.parameterForm.parameterCode,
        parameterName: this.parameterForm.parameterName,
        parameterDesc: this.parameterForm.parameterDesc,
      },
      queryParams: {
        parameterType: 'in:PAR,ROT',
      },
      rules: {
        parameterType: [
          {
            required: true,
            message: '请选择参数类型',
            trigger: 'blur',
          },
        ],
        parameterValue: [
          {
            required: true,
            message: '请输入参数值',
            trigger: 'blur',
          },
        ],
        parameterCode: [
          {
            required: true,
            message: '请输入参数编码',
            trigger: 'blur',
          },
        ],
        parameterName: [
          {
            required: true,
            message: '请输入参数名称',
            trigger: 'blur',
          },
        ],
        parentParameterId: [
          {
            required: true,
            message: '请选择父参数',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function () {
      return this.modalTitle === '新增参数'
    },
    isUpdate: function () {
      return this.modalTitle === '更新参数'
    },
  },
  mounted() {
    this.handleGetParentParameter()
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addParameter(this.parameter)
          } else if (this.isUpdate) {
            this.updateParameter(this.parameter)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updateParameter(parameterFormData) {
      this.loading = true
      updateParameter(parameterFormData.id, parameterFormData)
        .then((res) => {
          if (res.status === 200) {
            const successMessage = '更新参数成功！'
            this.$emit('confirm')
            this.$message.success(successMessage)
          } else {
            const failMessage = '更新参数失败，请重试！'
            this.$message.error(failMessage)
          }
        })
        .catch(
          // 工序失败原因
          (reason) => {
            this.loading = false
          }
        )
    },
    handleGetParentParameter() {
      getAllParameters(this.queryParams).then((res) => {
        this.parentParameter = res.data
      })
    },
    addParameter(parameterFormData) {
      this.loading = true
      const submitData = R.clone(parameterFormData)
      submitData.id = null
      addParameter(submitData)
        .then((res) => {
          if (res.status === 201) {
            this.$emit('confirm')
            this.$message.success('新增参数成功')
          } else {
            this.$message.error('新增参数失败，请重试！')
          }
        })
        .catch(
          // 工序失败原因
          (reason) => {
            this.loading = false
          }
        )
    },
  },
}
</script>

<style scoped>
</style>
