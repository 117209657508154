var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.type === "add" ? "新增资源" : "更新资源",
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "resourceForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.resource,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "资源类型", prop: "type" } },
            [
              _c(
                "a-radio-group",
                {
                  staticStyle: { width: "400px", fontWeight: "400" },
                  model: {
                    value: _vm.resource.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.resource, "type", $$v)
                    },
                    expression: "resource.type",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: 0 } }, [_vm._v("根节点")]),
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("页面")]),
                  _c("a-radio", { attrs: { value: 2 } }, [_vm._v("API")]),
                  _c("a-radio", { attrs: { value: 3 } }, [_vm._v("父模块")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.resource.type === 1 ||
          _vm.resource.type === 2 ||
          _vm.resource.type === 3
            ? _c(
                "a-form-model-item",
                { attrs: { label: "父模块", prop: "parentId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { prop: "type" },
                      model: {
                        value: _vm.resource.parentId,
                        callback: function ($$v) {
                          _vm.$set(_vm.resource, "parentId", $$v)
                        },
                        expression: "resource.parentId",
                      },
                    },
                    _vm._l(_vm.parentNode, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.description))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "资源编码", prop: "code" } },
            [
              _c("a-input", {
                attrs: { "max-length": 30, placeholder: "请输入资源编码" },
                model: {
                  value: _vm.resource.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.resource, "code", $$v)
                  },
                  expression: "resource.code",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "资源描述", prop: "description" } },
            [
              _c("a-input", {
                attrs: { "max-length": 30, placeholder: "请输入资源描述" },
                model: {
                  value: _vm.resource.description,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.resource,
                      "description",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "resource.description",
                },
              }),
            ],
            1
          ),
          _vm.resource.type === 1 || _vm.resource.type === 2
            ? _c(
                "a-form-model-item",
                { attrs: { label: "资源地址", prop: "uri" } },
                [
                  _c("a-input", {
                    attrs: { "max-length": 30, placeholder: "请输入资源地址" },
                    model: {
                      value: _vm.resource.uri,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.resource,
                          "uri",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "resource.uri",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.resource.type === 2
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "资源请求方法",
                    prop: "method",
                    required: _vm.resource.type === 2,
                  },
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      staticStyle: { width: "400px", fontWeight: "400" },
                      model: {
                        value: _vm.resource.method,
                        callback: function ($$v) {
                          _vm.$set(_vm.resource, "method", $$v)
                        },
                        expression: "resource.method",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "GET" } }, [
                        _vm._v("GET"),
                      ]),
                      _c("a-radio", { attrs: { value: "POST" } }, [
                        _vm._v("POST"),
                      ]),
                      _c("a-radio", { attrs: { value: "PUT" } }, [
                        _vm._v("PUT"),
                      ]),
                      _c("a-radio", { attrs: { value: "DELETE" } }, [
                        _vm._v("DELETE"),
                      ]),
                      _c("a-radio", { attrs: { value: "PATCH" } }, [
                        _vm._v("PATCH"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }