<template>
  <div>
    <div v-if="ifBorderVisible" class="card-container">
      <div id="craft-card-print">
        <div class="card-header">
          <img src="@/assets/logo.png" alt />
          <div class="title">
            <p class="company">重庆市DBR刀具公司</p>
            <p class="desc">产品合格证</p>
          </div>
        </div>
        <a-descriptions class="desc" bordered>
          <a-descriptions-item label="产品名称" :span="2">{{ data.productName }}</a-descriptions-item>
          <a-descriptions-item label="任务号" :span="3">{{ data.taskCode }}</a-descriptions-item>
          <a-descriptions-item label="产品图号" :span="2">{{ data.drawingNum }}</a-descriptions-item>
          <a-descriptions-item label="产品规格" :span="3">{{ data.specification }}</a-descriptions-item>
          <a-descriptions-item label="数量" :span="2">{{ data.producingNum }}</a-descriptions-item>
          <a-descriptions-item label="单位" :span="3">{{ data.unit }}</a-descriptions-item>
          <a-descriptions-item label="结论" :span="2">{{ data.checkResult }}</a-descriptions-item>
          <a-descriptions-item label="客户名称" :span="3">
            <template v-if="ifClientVisible">{{ data.client }}</template>
            <template v-else>
              <span style="color:white; opacity: 0;">{{ data.client }}</span>
            </template>
          </a-descriptions-item>
          <a-descriptions-item label="检验员" :span="2"></a-descriptions-item>
          <a-descriptions-item label="日期" :span="3">{{ data.checkTime }}</a-descriptions-item>
        </a-descriptions>
      </div>
      <div class="footer">
        <div class="radio">
          是否显示边框
          <a-switch default-checked @change="onSwitchChange" />
        </div>
        <div class="radio">
          是否显示客户名称
          <a-switch default-checked @change="onClientSwitchChange" />
        </div>
        <a-button v-print="'craft-card-print'" type="primary" class="btn" block>打印</a-button>
      </div>
    </div>
    <div v-else class="card-container container-noborder">
      <div id="craft-card-print">
        <div class="card-no-border-header">
          <img src="@/assets/logo.png" alt />
          <div class="title">
            <p>重庆市DBR刀具公司产品合格证</p>
          </div>
        </div>
        <a-descriptions class="desc-without-border" bordered>
          <a-descriptions-item label="产品名称" :span="2">{{ data.productName }}</a-descriptions-item>
          <a-descriptions-item label="任务号" :span="2">{{ data.taskCode }}</a-descriptions-item>
          <a-descriptions-item label="产品图号" :span="2">{{ data.drawingNum }}</a-descriptions-item>
          <a-descriptions-item label="产品规格" :span="2">{{ data.specification }}</a-descriptions-item>
          <a-descriptions-item label="数量" :span="2">{{ data.producingNum }}</a-descriptions-item>
          <a-descriptions-item label="单位" :span="2">{{ data.unit }}</a-descriptions-item>
          <a-descriptions-item label="结论" :span="2">{{ data.checkResult }}</a-descriptions-item>
          <a-descriptions-item label="客户名称" :span="2">
            <template v-if="ifClientVisible">{{ data.client }}</template>
            <template v-else>
              <span style="color:white;opacity: 0;">{{ data.client }}</span>
            </template>
          </a-descriptions-item>
          <a-descriptions-item label="检验员" :span="2"></a-descriptions-item>
          <a-descriptions-item label="日期" :span="2">{{ data.checkTime }}</a-descriptions-item>
        </a-descriptions>
      </div>
      <div class="footer">
        <div class="radio">
          是否显示边框
          <a-switch default-checked @change="onSwitchChange" />
        </div>
        <div class="radio">
          是否显示客户名称
          <a-switch default-checked @change="onClientSwitchChange" />
        </div>
        <a-button v-print="'craft-card-print'" type="primary" class="btn" block>打印</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getCertificatesByTaskCode } from '@/api/task'

export default {
  data() {
    return {
      data: {},
      ifBorderVisible: true,
      ifClientVisible: true,
    }
  },
  mounted() {
    this.getCertificateInfo()
  },
  methods: {
    onSwitchChange(checked) {
      this.ifBorderVisible = checked
      console.log(this.ifBorderVisible)
    },
    onClientSwitchChange(checked) {
      this.ifClientVisible = checked
    },
    getCertificateInfo() {
      getCertificatesByTaskCode(this.$route.query.id).then((res) => {
        this.data = res.data
      })
    },
  },
}
</script>

<style lang="scss">
// 1厘米等于28px
.card-header {
  border: 1px solid black;
  width: 100%;
  height: 88px;
  display: flex;
  border-bottom: none;
}
.card-no-border-header {
  width: 100%;
  height: 88px;
  display: flex;
  border: none;
  opacity: 0;
}
.logo {
  flex: 1;
  height: 100%;
  background: url('~@/assets/logo.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.title {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: black;
  .company {
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
  .desc {
    font-size: 20px;
    font-weight: bold;
    font-weight: bold;
    letter-spacing: 2px;
  }
}
</style>

<style scoped>
/deep/ .ant-descriptions-item-label {
  padding: 0;
}
/deep/ .ant-descriptions-row > th {
  padding-bottom: 0;
}
</style>

<style scope>
.desc .ant-descriptions-bordered {
  border: 1px solid black;
  text-align: center;
  font-weight: bold;
}
.desc .ant-descriptions-item-content {
  border: 1px solid black !important;
  text-align: center;
  font-weight: bold;
}
.desc .ant-descriptions-bordered {
  border: none;
}
.desc .ant-descriptions-view {
  border: none;
}

.desc .ant-descriptions-bordered {
  border: none;
}
.desc .ant-descriptions-row {
  border: none;
}

.desc .ant-descriptions-bordered {
  background-color: white;
}
.desc .ant-descriptions-item-label {
  background-color: white;
}

.desc .ant-descriptions-bordered {
  border: 1px solid black;
  text-align: center;
}
.desc .ant-descriptions-item-label {
  border: 1px solid black;
  text-align: center;
}
.desc .ant-descriptions-item-content {
  font-size: 18px;
}
</style>

<style scope>
.desc-without-border .ant-descriptions-bordered {
  border: 1px solid black;
  text-align: center;
  font-weight: bold;
  border-color: white;
}
.desc-without-border .ant-descriptions-item-content {
  border: 1px solid black;
  text-align: center;
  font-weight: bold;
  border-color: white;
}
.desc-without-border .ant-descriptions-bordered {
  border-color: white;
}
.desc-without-border .ant-descriptions-view {
  border-color: white;
}

.desc-without-border .ant-descriptions-bordered {
  border-color: white;
}
.desc-without-border .ant-descriptions-row {
  border-color: white;
}

.desc-without-border .ant-descriptions-bordered {
  background-color: white;
}
.desc-without-border .ant-descriptions-item-label {
  background-color: white;
}

.desc-without-border .ant-descriptions-bordered {
  border: 1px solid black;
  text-align: center;
  border-color: white;
}
.desc-without-border .ant-descriptions-item-label {
  border: 1px solid black;
  text-align: center;
  border-color: white;
  color: white;
  opacity: 0;
}
.desc-without-border .ant-descriptions-item-content {
  font-size: 18px;
}
</style>

<style scoped lang="scss">
.card-container {
  width: 30%;
  margin: auto;
  padding-top: 100px;
  position: relative;

  .footer {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      width: 120px;
    }
    .radio {
      font-weight: bold;
      margin-right: 50px;
    }
  }
  /deep/ .ant-descriptions-item-label {
    padding: 0;
  }
  /deep/ .ant-descriptions-row > th {
    padding-bottom: 0;
    width: 80px;
    height: 70px;
  }
  /deep/ .ant-descriptions-item-content {
    padding: 20px;
    width: 350px;
    height: 55%;
  }
}
</style>
