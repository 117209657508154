import request from '@/utils/api.request'

/**
 * 分页获取订单信息
 * @param {object} data
 */
export function getOrders(data) {
  return request({
    url: `/orders`,
    method: 'get',
    params: data
  })
}

/**
 * 根据id获取指定的订单信息
 * @param {number} id
 */
export function getOrderById(id) {
  return request({
    url: `/orders/${id}`,
    method: 'get'
  })
}

/**
 * 增加订单
 * @param {object} data
 */
export function addOrder(data) {
  return request({
    url: `/orders`,
    method: 'post',
    data
  })
}

/**
 * 更新指定id的订单
 * @param {number} id
 * @param {object} data
 */
export function updateOrder(id, data) {
  return request({
    url: `/orders/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除指定id的订单
 * @param {number} id
 */
export function deleteOrder(id) {
  return request({
    url: `/orders/${id}`,
    method: 'delete'
  })
}

/**
 * 通过订单id删除对应的合同文件
 * @param {number} id
 */
export function deleteOrderPdf(id) {
  return request({
    url: `/orders/${id}/file`,
    method: 'delete'
  })
}

/**
 * 增加订单的产品
 * @param {object} data
 */
export function addProductByOrder(id, data) {
  return request({
    url: `/orders/${id}/products`,
    method: 'post',
    data
  })
}

/**
 * 通过订单id预览对应的合同文件
 * @param {number} id
 */
export function showOrderPdf(id) {
  return request({
    url: `/orders/${id}/file`,
    method: 'get'
  })
}

/**
 * 增加订单的支付信息
 * @param id
 * @param data
 * @returns {AxiosPromise}
 */
export function addPaymentByOrder(id, data) {
  return request({
    url: `/orders/${id}/payment`,
    method: 'post',
    data
  })
}

/**
 * 更新订单的支付信息
 * @param id
 * @param data
 * @returns {AxiosPromise}
 */
export function updatePaymentByOrder(id, data) {
  return request({
    url: `/orders/${id}/payment`,
    method: 'put',
    data
  })
}

/**
 * 查询订单的支付信息
 * @param id
 * @returns {AxiosPromise}
 */
export function getPaymentByOrder(id) {
  return request({
    url: `/orders/${id}/payment`,
    method: 'get'
  })
}

/**
 * 导出所有计划
 * @param {*} data 导出计划参数
 */
export function exportAllOrders(data) {
  return request({
    url: `/orders/exports/all`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}

/**
 * 导出选择计划
 * @param {*} data 导出计划参数
 */
export function exportOrdersByIds(data) {
  return request({
    url: `/orders/exports/ids`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}

/**
 * 导出筛选计划
 * @param {*} data 导出计划参数
 */
export function exportFilteredOrders(data) {
  return request({
    url: `/orders/exports/filtered`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}
/**
 * 导出当前页计划
 * @param {*} data 导出计划参数
 */
export function exportCurrentPageOrders(data) {
  return request({
    url: `/orders/exports/current-page`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}

export function getProductImportTemplate() {
  return request({
    url: `/orders/product-import-template`,
    method: 'get',
    responseType: 'blob'
  })
}

/**
 * 通过订单id下达该订单下所有产品计划
 * @param id
 * @returns {AxiosPromise}
 */
export function makeOrderPlans(id, data) {
  return request({
    url: `/orders/${id}/plans`,
    method: 'post',
    data
  })
}

/**
 * 暂停、取消、恢复订单
 * @param id
 * @param data
 * @returns {AxiosPromise}
 */
export function switchOrderStatus(orderId, statusCode) {
  return request({
    url: `/orders/${orderId}/status/${statusCode}`,
    method: 'put'
  })
}
