var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { staticStyle: { "margin-right": "30px" }, attrs: { span: 2 } },
            [
              _c(
                "c-upload",
                {
                  attrs: {
                    name: "file",
                    accept: ".xlsx",
                    "show-upload-list": false,
                    action: _vm.uploadUrl + "orders/products/imports",
                  },
                  on: { change: _vm.handleFileChange },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "content",
                        type: "primary",
                        loading: _vm.uploading,
                        icon: "el-icon-circle-plus-outline",
                      },
                      slot: "content",
                    },
                    [_vm._v("导入订单与产品")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { staticStyle: { "margin-left": "300px" }, attrs: { span: 9 } },
            [
              _c("a-input-search", {
                attrs: {
                  "allow-clear": true,
                  placeholder: "请输入产品名称/规格/图号/计划编码查询",
                  "enter-button": "查找",
                  size: "large",
                },
                on: { search: _vm.handleSearch },
                model: {
                  value: _vm.searchValue,
                  callback: function ($$v) {
                    _vm.searchValue = $$v
                  },
                  expression: "searchValue",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { staticStyle: { "margin-top": "10px" }, attrs: { span: 4 } },
            [
              _c("span", [_vm._v("是否展示分件：")]),
              _c("a-switch", {
                attrs: {
                  "checked-children": "是",
                  "un-checked-children": "否",
                  "default-checked": false,
                  loading: _vm.switchLoading,
                },
                on: { change: _vm.handleShowPart },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            {
              staticStyle: { float: "right", "margin-right": "18px" },
              attrs: { span: 3 },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-download" },
                  on: { click: _vm.handleDownloadTemplate },
                },
                [_vm._v("点击下载订单产品导入模板")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "productTable",
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: {
            "element-loading-text": "加载中...",
            "row-key": _vm.getRowKey,
            data: _vm.productList,
            "show-overflow-tooltip": true,
            border: "",
            "max-height": "700",
            "header-cell-class-name": _vm.handleHeaderClass,
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            select: _vm.handleSelect,
            "sort-change": _vm.handleSortChange,
            "filter-change": _vm.onFilterChange,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "55",
              align: "center",
              "reserve-selection": true,
            },
          }),
          _c("el-table-column", {
            staticClass: "border-top-solid",
            attrs: {
              fixed: "left",
              align: "center",
              label: "序号",
              type: "index",
              width: "70",
              index: _vm.indexMethod,
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单编号",
              prop: "orderCode",
              "column-key": "orderCode",
              width: "130",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": "product_order.orderCode",
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "签约日期",
              prop: "signTime",
              "column-key": "signTime",
              width: "160",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": "product_order.signTime",
                        "search-type": "date",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品名称",
              prop: "productName",
              "column-key": "productName",
              width: "260",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.productName))]
                },
              },
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "产品状态",
              prop: "statusText",
              "column-key": "statusText",
              width: "110",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: scope.row.statusTag.type } },
                      [_vm._v(_vm._s(scope.row.statusTag.label))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "计划编码",
              prop: "isPlanExisted",
              "column-key": "isPlanExisted",
              width: "200",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": "plan.code",
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.isPlanExisted === 1
                      ? _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              title: "点击查看计划详情",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.jumpToDashboardPage(
                                  scope.row.planCode,
                                  scope.row.type
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.planCode))]
                        )
                      : _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: "确定下达该产品计划？",
                              placement: "top",
                            },
                            on: {
                              onConfirm: function ($event) {
                                return _vm.addProductPlan(scope)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "primary",
                                  size: "mini",
                                  loading: scope.row.isButtonLoading,
                                  round: "",
                                },
                                slot: "reference",
                              },
                              [_vm._v("请点击下达计划")]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品类型",
              prop: "type",
              "column-key": "type",
              width: "100",
              filters: [
                { text: "L类型", value: "L" },
                { text: "D类型", value: "D" },
                { text: "G类型", value: "G" },
              ],
              "filter-multiple": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "图号",
              prop: "drawingNum",
              "column-key": "drawingNum",
              width: "150",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "规格",
              width: "200",
              sortable: "custom",
              prop: "specification",
              "column-key": "specification",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "单位",
              prop: "unit",
              "column-key": "unit",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单数量",
              prop: "amount",
              "column-key": "amount",
              width: "120",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "number",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "不含税单价",
              prop: "taxFreePrice",
              "column-key": "taxFreePrice",
              width: "130",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "number",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "税率",
              prop: "taxRate",
              "column-key": "taxRate",
              width: "50",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.taxRate))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "含税单价",
              prop: "taxPrice",
              "column-key": "taxPrice",
              width: "120",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "number",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "含税金额",
              prop: "totalMoneyTaxInclued",
              "column-key": "totalMoneyTaxInclued",
              width: "150",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "number",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "不含税金额",
              prop: "totalMoneyTaxExclued",
              "column-key": "totalMoneyTaxExclued",
              width: "150",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "number",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品交货日期",
              prop: "deliverTime",
              "column-key": "deliverTime",
              width: "160",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": _vm.plan.deliverTime,
                        "search-type": "date",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产值单价",
              prop: "productionPrice",
              "column-key": "productionPrice",
              width: "130",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "number",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客户原始工艺文件",
              prop: "fileName",
              width: "300",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.fileName
                      ? _c(
                          "span",
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewFile(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.fileName))]
                            ),
                            _vm._v(" "),
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "您确定要删除工艺文件？",
                                  "ok-text": "确定",
                                  "cancel-text": "取消",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.handleDeleteCraftFile(
                                      scope.row.id
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "a",
                                  { staticClass: "filename-delete" },
                                  [_c("a-icon", { attrs: { type: "delete" } })],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "c-upload",
                          {
                            attrs: {
                              name: "file",
                              "show-upload-list": false,
                              action:
                                _vm.uploadUrl +
                                "products/" +
                                scope.row.id +
                                "/file",
                            },
                            on: { change: _vm.uploadCraftSuc },
                          },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  slot: "content",
                                  type: "primary",
                                  underline: false,
                                },
                                slot: "content",
                              },
                              [_vm._v("上传工艺文件")]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品客户需求",
              prop: "productClientDemand",
              "column-key": "productClientDemand",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客户物料代码",
              prop: "clientMaterialCode",
              "column-key": "clientMaterialCode",
              width: "150",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客户计划编码",
              prop: "clientPlanCode",
              "column-key": "clientPlanCode",
              width: "150",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客户合同编号",
              prop: "clientContractCode",
              "column-key": "clientContractCode",
              width: "150",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": "product_order.clientContractCode",
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客户采购编号",
              prop: "clientPurchaseOrderCode",
              "column-key": "clientPurchaseOrderCode",
              width: "150",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": "product_order.clientPurchaseOrderCode",
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客户订单编号",
              prop: "clientOrderCode",
              "column-key": "clientOrderCode",
              width: "150",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": "product_order.clientOrderCode",
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客户库房编号",
              prop: "clientWarehouseCode",
              "column-key": "clientWarehouseCode",
              width: "150",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": "clientWarehouseCode",
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客户名称",
              prop: "client",
              "column-key": "client",
              width: "130",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": "product_order.client",
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品类别",
              prop: "productCategory",
              "column-key": "productCategory",
              width: "100",
              filters: [
                { text: "单体", value: "MLJ" },
                { text: "单体轴类", value: "ZLJ" },
                { text: "单体非轴类", value: "OLJ" },
                { text: "组合", value: "CLJ" },
              ],
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.productCategory))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "材料类别",
              prop: "materialCategoryValue",
              "column-key": "materialCategoryValue",
              width: "110",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.materialCategoryValue))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "备注",
              prop: "comment",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      {
                        attrs: { trigger: "click" },
                        on: {
                          command: function (value) {
                            _vm.handleCommand(value, scope)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _vm._v(" 操作 "),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  icon: "el-icon-edit",
                                  command: "update",
                                },
                              },
                              [_vm._v("更新产品信息")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  icon: "el-icon-delete",
                                  command: "delete",
                                },
                              },
                              [_vm._v("删除产品")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dropdown",
        {
          staticClass: "export-product",
          on: { command: _vm.handleExportProductCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出产品")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "SELECTED" } }, [
                _vm._v("导出当前已选产品"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "PAGE" } }, [
                _vm._v("导出当前页产品"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选产品"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "ALL" } }, [
                _vm._v("导出所有产品"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.addProductModalVisible
        ? _c("ProductEdit", {
            attrs: {
              "modal-title": _vm.currentEditType,
              "product-form": _vm.currentProduct,
            },
            on: {
              cancel: _vm.handleProductModalCanceled,
              confirm: _vm.handleProductModalConfirmed,
            },
          })
        : _vm._e(),
      _c("Pagination", {
        ref: "pagination",
        attrs: { total: _vm.total },
        on: {
          getData: function (data) {
            _vm.searchValue ? _vm.onProductSearch(data) : _vm.getProducts(data)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }