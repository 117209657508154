var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title,
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.record,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "客户要求时间", prop: "clientDeliveryTime" } },
            [
              _c("a-date-picker", {
                attrs: {
                  "show-time": "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  placeholder: "请选择客户要求时间",
                },
                model: {
                  value: _vm.record.clientDeliveryTime,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.record,
                      "clientDeliveryTime",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "record.clientDeliveryTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车间反馈时间", prop: "workshopTime" } },
            [
              _c("a-date-picker", {
                attrs: {
                  "show-time": "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  placeholder: "请选择车间反馈时间",
                },
                model: {
                  value: _vm.record.workshopTime,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.record,
                      "workshopTime",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "record.workshopTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "最终确认时间", prop: "finalDeliveryTime" } },
            [
              _c("a-date-picker", {
                attrs: {
                  "show-time": "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  placeholder: "请选择最终确认时间",
                },
                model: {
                  value: _vm.record.finalDeliveryTime,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.record,
                      "finalDeliveryTime",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "record.finalDeliveryTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "完成情况分析", prop: "negotiateContent" } },
            [
              _c("a-textarea", {
                attrs: { placeholder: "请输入完成情况分析" },
                model: {
                  value: _vm.record.negotiateContent,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.record,
                      "negotiateContent",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "record.negotiateContent",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }