<template>
  <a-modal class="modal-container" :visible="true" :title="title" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button type="primary" @click="handleCancel">确定</a-button>
    </template>
    <div class="list">
      <a-timeline v-if="list.length">
        <a-timeline-item v-for="item in list" :key="item.id">
          <p>客户要求时间: {{ item.clientDeliveryTime }}</p>
          <p>车间反馈时间: {{ item.workshopTime }}</p>
          <p>最终确认时间: {{ item.finalDeliveryTime }}</p>
          <p>完成情况分析: {{ item.negotiateContent }}</p>
          <p>记录时间: {{ item.createTime }}</p>
        </a-timeline-item>
      </a-timeline>
      <a-empty v-else />
    </div>
  </a-modal>
</template>
<script>
import { getNegotiates } from '@/api/emgtask'
export default {
  props: {
    taskId: Number
  },
  data() {
    return {
      title: '协商记录',
      list: []
    }
  },

  mounted() {
    this.getNegotiates()
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    getNegotiates() {
      getNegotiates(this.taskId).then(res => {
        this.list = res.data
      })
    }
  }
}
</script>

<style scoped>
.list {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
