<template>
  <div>
    <div class="screen-title">
      工厂生产实时情况概览
      <p class="date">{{ date }}</p>
    </div>
    <div class="container">
      <div class="left">
        <div class="card target">
          <p class="title">班组周/员工日目标完成率</p>
          <div class="content">
            <template v-if="groupTargetInfo.all.length">
              <div id="groupBar" class="bar week" />
            </template>
            <template v-else>
              <p class="nullStatus">班组周目标暂无数据</p>
            </template>
            <template v-if="memberTargetInfo.all.length">
              <div id="memberBar" class="bar day" />
            </template>
            <template v-else>
              <p class="nullStatus">员工日目标暂无数据</p>
            </template>
          </div>
        </div>
        <div class="card craft">
          <p class="title">排产计划情况概览</p>
          <div class="content">
            <div class="custom_item">
              <p class="custom_item_title">计划待制定项数</p>
              <div class="custom_item_list">
                <div v-for="item of planData" :key="item.productType" class="item" :class="{'gap': item.productType !== '量具&刀具'}">
                  <p class="item-data">
                    <countTo :start-val="0" :end-val="Number(item.totalPlanNotMakeCount)" :duration="1000" />
                  </p>
                  <p class="item-desc">{{ item.productType === '量具&刀具' ? '量具&刀具' : item.productType }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="card all_target">
          <p class="title">目标进度概览</p>
          <div class="content">
            <div class="content-left target">
              <p class="title">当前{{ productType }}型月目标完成情况</p>
              <div class="item-list">
                <div class="item">
                  <div class="pie">
                    <div id="monthTaskPie" class="pie" />
                  </div>
                  <div v-if="monthTargetInfo.taskStatisticsInfo" class="pie-info">
                    <p>总计: {{ monthTargetInfo.taskStatisticsInfo.totalCount }}</p>
                    <p>完成: {{ monthTargetInfo.taskStatisticsInfo.finishedCount }}</p>
                    <p>未完成: {{ monthTargetInfo.taskStatisticsInfo.totalCount - monthTargetInfo.taskStatisticsInfo.finishedCount }}</p>
                  </div>
                </div>
                <div class="item">
                  <div class="pie">
                    <div id="monthProductPie" class="pie" />
                  </div>
                  <div v-if="monthTargetInfo.productStatisticsInfo" class="pie-info">
                    <p>总计: {{ monthTargetInfo.productStatisticsInfo.totalCount }}</p>
                    <p>完成: {{ monthTargetInfo.productStatisticsInfo.finishedCount }}</p>
                    <p>未完成: {{ monthTargetInfo.productStatisticsInfo.totalCount - monthTargetInfo.productStatisticsInfo.finishedCount }}</p>
                  </div>
                </div>
                <div class="item">
                  <div class="pie">
                    <div id="monthAmountPie" class="pie" />
                  </div>
                  <div v-if="monthTargetInfo.amountStatisticsInfo" class="pie-info">
                    <p>总计: {{ monthTargetInfo.amountStatisticsInfo.totalAmount }}</p>
                    <p>完成: {{ monthTargetInfo.amountStatisticsInfo.finishedAmount }}</p>
                    <p>未完成: {{ (monthTargetInfo.amountStatisticsInfo.totalAmount - monthTargetInfo.amountStatisticsInfo.finishedAmount).toFixed(2) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-right target">
              <p class="title">当前{{ productType }}型周目标完成情况</p>
              <div class="item-list">
                <div class="item">
                  <div class="pie">
                    <div id="weekTaskPie" class="pie" />
                  </div>
                  <div v-if="weekTargetInfo.taskStatisticsInfo" class="pie-info">
                    <p>总计: {{ weekTargetInfo.taskStatisticsInfo.totalCount }}</p>
                    <p>完成: {{ weekTargetInfo.taskStatisticsInfo.finishedCount }}</p>
                    <p>未完成: {{ weekTargetInfo.taskStatisticsInfo.totalCount - weekTargetInfo.taskStatisticsInfo.finishedCount }}</p>
                  </div>
                </div>
                <div class="item">
                  <div class="pie">
                    <div id="weekProductPie" class="pie" />
                  </div>
                  <div v-if="weekTargetInfo.productStatisticsInfo" class="pie-info">
                    <p>总计: {{ weekTargetInfo.productStatisticsInfo.totalCount }}</p>
                    <p>完成: {{ weekTargetInfo.productStatisticsInfo.finishedCount }}</p>
                    <p>未完成: {{ weekTargetInfo.productStatisticsInfo.totalCount - weekTargetInfo.productStatisticsInfo.finishedCount }}</p>
                  </div>
                </div>
                <div class="item">
                  <div class="pie">
                    <div id="weekAmountPie" class="pie" />
                  </div>
                  <div v-if="weekTargetInfo.amountStatisticsInfo" class="pie-info">
                    <p>总计: {{ weekTargetInfo.amountStatisticsInfo.totalAmount }}</p>
                    <p>完成: {{ weekTargetInfo.amountStatisticsInfo.finishedAmount }}</p>
                    <p>未完成: {{ (weekTargetInfo.amountStatisticsInfo.totalAmount - weekTargetInfo.amountStatisticsInfo.finishedAmount).toFixed(2) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card all_inventory">
          <div class="content">
            <div class="inventory-part">
              <p class="title">入库信息概览</p>
              <div class="custom_item">
                <p class="custom_item_title">当月入库情况</p>
                <div class="custom_item_list">
                  <div class="item gap">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(inventoryData.mouthPutInProductItemCount)" :duration="1000" />
                    </p>
                    <p class="item-desc">产品项数</p>
                  </div>
                  <div class="item gap">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(inventoryData.mouthPutInProductCount)" :duration="1000" />
                    </p>
                    <p class="item-desc">产品数量</p>
                  </div>
                  <div class="item">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(inventoryData.mouthPutInAmount)" :duration="1000" :decimals="2" />
                    </p>
                    <p class="item-desc">产值金额(元)</p>
                  </div>
                </div>
              </div>
              <div class="custom_item">
                <p class="custom_item_title">当日入库情况</p>
                <div class="custom_item_list">
                  <div class="item gap">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(inventoryData.dayPutInProductItemCount)" :duration="1000" />
                    </p>
                    <p class="item-desc">产品项数</p>
                  </div>
                  <div class="item gap">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(inventoryData.dayPutInProductCount)" :duration="1000" />
                    </p>
                    <p class="item-desc">产品数量</p>
                  </div>
                  <div class="item">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(inventoryData.dayPutInAmount)" :duration="1000" :decimals="2" />
                    </p>
                    <p class="item-desc">产值金额(元)</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="plan-part">
              <p class="title">工艺情况概览</p>
              <div class="custom_item">
                <p class="custom_item_title">工艺待编制项数</p>
                <div class="custom_item_list">
                  <div v-for="item of planData" :key="item.productType" class="item" :class="{'gap': item.productType !== '量具&刀具'}">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(item.totalNotFinishCraftCount)" :duration="1000" />
                    </p>
                    <p class="item-desc">{{ item.productType === '量具&刀具' ? '量具&刀具' : item.productType }}</p>
                  </div>
                </div>
              </div>
              <div class="custom_item">
                <p class="custom_item_title">工艺编制超期项数</p>
                <div class="custom_item_list">
                  <div v-for="item of planData" :key="item.productType" class="item" :class="{'gap': item.productType !== '量具&刀具'}">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(item.totalOverdueCraftCount)" :duration="1000" />
                    </p>
                    <p class="item-desc">{{ item.productType === '量具&刀具' ? '量具&刀具' : item.productType }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card emg">
          <p class="title">未完急件情况概览</p>
          <div class="content">
            <div class="emg-part">
              <div class="custom_item" v-for="emg of emgData" :key="emg.emergencyType">
                <p class="custom_item_title">{{EmergencyType[emg.emergencyType]}}</p>
                <div class="custom_item_list">
                  <div class="item gap">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(emg.totalNotFinishTaskItemCount)" :duration="1000" />
                    </p>
                    <p class="item-desc">任务项数</p>
                  </div>
                  <div class="item gap">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(emg.totalNotFinishProductCount)" :duration="1000" />
                    </p>
                    <p class="item-desc">产品数量</p>
                  </div>
                  <div class="item">
                    <p class="item-data">
                      <countTo :start-val="0" :end-val="Number(emg.totalNotFinishAmount)" :duration="1000" :decimals="2" />
                    </p>
                    <p class="item-desc">产值金额(元)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="card opearation">
          <p class="title">工序待加工任务信息概览</p>
          <div id="productBar" class="bar" />
        </div>
        <div class="card task">
          <p class="title">未完成任务信息概览</p>
          <div class="content">
            <p
              class="content-title"
            >{{showingTaskInfo.show.productType === '量具' ? 'L型' : showingTaskInfo.show.productType === '刀具' ? 'D型' : 'LD型'}}</p>
            <div class="custom_item">
              <div class="custom_item_list">
                <div class="item gap">
                  <p class="item-data">
                    <countTo :start-val="0" :end-val="Number(showingTaskInfo.show.totalNotFinishTaskItemCount)" :duration="1000" />
                  </p>
                  <p class="item-desc">产品项数</p>
                </div>
                <div class="item gap">
                  <p class="item-data">
                    <countTo :start-val="0" :end-val="Number(showingTaskInfo.show.totalNotFinishProductCount)" :duration="1000" />
                  </p>
                  <p class="item-desc">产品数量</p>
                </div>
                <div class="item amount">
                  <p class="item-data">
                    <countTo :start-val="0" :end-val="Number(showingTaskInfo.show.totalNotFinishAmount)" :duration="1000" :decimals="2" />
                  </p>
                  <p class="item-desc">产值金额</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card deal">
          <p class="title">新增订单情况概览</p>
          <div class="content">
            <div class="custom_item all">
              <div class="custom_item_list">
                <div class="item month">
                  <p class="item-data">
                    <countTo :start-val="0" :end-val="Number(orderData.mouthProductAmount)" :duration="1000" :decimals="2" />
                  </p>
                  <p class="item-desc">当月新增订单产值总额</p>
                </div>
              </div>
            </div>
            <div class="custom_item">
              <p class="custom_item_title deal">当日新增订单情况</p>
              <div class="custom_item_list">
                <div class="item gap">
                  <p class="item-data">
                    <countTo :start-val="0" :end-val="Number(orderData.dayProductItemCount)" :duration="1000" />
                  </p>
                  <p class="item-desc">产品项数</p>
                </div>
                <div class="item gap">
                  <p class="item-data">
                    <countTo :start-val="0" :end-val="Number(orderData.dayProductCount)" :duration="1000" />
                  </p>
                  <p class="item-desc">产品数量</p>
                </div>
                <div class="item">
                  <p class="item-data">
                    <countTo :start-val="0" :end-val="Number(orderData.dayProductAmount)" :duration="1000" :decimals="2" />
                  </p>
                  <p class="item-desc">产值金额</p>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './screen.mixin'

export default {
  name: 'ManageBoard',
  mixins: [mixin]
}
</script>

<style scoped lang="less">
.calcfontsize(@size) {
  //font-size: calc(@x / 19.2) * 1vw;
  font-size: calc(@size / 19.2 * 1vw);
}

div {
  box-sizing: border-box;
}
.custom_item {
  height: 40%;
  &_title {
    .calcfontsize(18);
  }
  &_list {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .item {
      flex: 1;
      .calcfontsize(16);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      &-data {
        .calcfontsize(24);
        font-weight: bold;
        color: #333;
      }
      &-desc {
        .calcfontsize(18);
        color: rgba(0, 0, 0, 0.8);
      }
    }
    .gap {
      position: relative;
      &::after {
        content: '';
        width: 1px;
        height: 90%;
        background: #ccc;
        opacity: 0.4;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
}
.desc_data_list {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  .item {
    flex: 1;
    .calcfontsize(16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-data {
      .calcfontsize(18);
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    &-desc {
      .calcfontsize(14);
      color: #9c9c9c;
    }
  }
  .gap {
    position: relative;
    &::after {
      content: '';
      width: 1px;
      height: 90%;
      background: #ccc;
      opacity: 0.4;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}
.screen-title {
  width: 100%;
  height: 40px;
  text-align: center;
  background: #f0f2f5;
  position: relative;
  .calcfontsize(24);
  .date {
    height: 40px;
    .calcfontsize(20);
    position: absolute;
    top: 70%;
    right: 10px;
    transform: translateY(-50%);
  }
}
.container {
  width: 100vw;
  height: calc(100vh - 50px);
  background: #f0f2f5;
  padding: 0 12px;
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
  .card {
    background: white;
    border-radius: 2px;
    padding: 12px 12px 0 12px;
    .title {
      .calcfontsize(20);
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .left {
    width: 20vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .card {
      flex: 1;
      width: 100%;
      //height: 240px;
      position: relative;
      .item {
        display: flex;
        align-items: center;
        &-desc {
          .calcfontsize(16);
        }
        &-data {
          margin-left: 8px;
          .calcfontsize(26);
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
    .target {
      flex: 3;
      overflow: hidden;
      position: relative;
      &::before {
        content: '';
        width: 95%;
        height: 1px;
        background: #ccc;
        opacity: 0.4;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
      .content {
        width: 100%;
        height: 90%;
        .bar {
          width: 100%;
        }
        .week {
          height: 40%;
          margin-bottom: 30px;
        }
        .day {
          height: 50%;
        }
        .nullStatus {
          width: 100%;
          height: 50%;
          margin-top: 10%;
          text-align: center;
          .calcfontsize(20);
          font-weight: bold;
        }
      }
    }
    .craft {
      flex: 1;
      position: relative;
      &::before {
        content: '';
        width: 95%;
        height: 1px;
        background: #ccc;
        opacity: 0.4;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
      .content {
        width: 100%;
        height: 90%;
        margin-top: 20px;
        .custom_item {
          .custom_item_title {
            margin-bottom: 5%;
          }
        }
      }
    }
  }
  .right {
    width: 23vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .card {
      flex: 1;
      width: 100%;
      position: relative;
    }
    .task {
      flex: 0.7;
      &::before {
        content: '';
        width: 95%;
        height: 1px;
        background: #ccc;
        opacity: 0.4;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
      .content {
        margin-top: 5%;
        .content-title {
          .calcfontsize(20);
          color: rgba(0, 0, 0, 0.8);
        }
        .custom_item_list {
          padding: 0;
          .amount {
            flex-basis: 1;
            flex-grow: 1.5;
          }
        }
      }
    }
    .deal {
      flex: 1;
      .title {
        margin-bottom: 20px;
      }
      .content {
        .month {
          .item-data {
            .calcfontsize(24);
          }
        }
        .all {
          margin-bottom: 10%;
        }
        .deal {
          margin-bottom: 5%;
        }
      }
      &::before {
        content: '';
        width: 95%;
        height: 1px;
        background: #ccc;
        opacity: 0.4;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
    }
    .opearation {
      flex: 1.4;
      &::before {
        content: '';
        width: 95%;
        height: 1px;
        background: #ccc;
        opacity: 0.4;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
      .bar {
        width: 100%;
        height: 90%;
      }
      .bar-info {
        width: 100%;
        .calcfontsize(18);
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  .middle {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    .card {
      width: 100%;
      flex: 1;
      margin-top: 10px;
      .title {
        text-align: center;
      }
      .content {
        width: 100%;
        display: flex;
        margin-top: 20px;
        .target {
          flex: 1;
          position: relative;
          box-sizing: border-box;
          .title {
            .calcfontsize(18);
            font-weight: 400;
          }
          .item-list {
            display: flex;
            .item {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              .pie-info {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
        &-left {
          position: relative;
          &::after {
            content: '';
            width: 1px;
            height: 90%;
            background: #ccc;
            opacity: 0.4;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }
      }
      .pie {
        width: 120px;
        height: 120px;
      }
    }
    .all_inventory {
      background: #f0f2f5;
      display: flex;
      flex-direction: column;
      padding: 0;
      .content {
        width: 100%;
        height: 100%;
        margin-top: 0px;
        display: flex;
        .inventory-part {
          flex: 1;
          height: 100%;
          box-sizing: border-box;
          padding: 12px;
          background: white;
          margin-right: 10px;
          .title {
            .calcfontsize(20);
            font-weight: bold;
            margin-bottom: 10px;
          }
          .inventory {
            height: 40%;
            &-title {
              .calcfontsize(18);
            }
            &-list {
              width: 100%;
              height: 80%;
              display: flex;
              justify-content: space-between;
              padding: 0 20px;
              .item {
                flex: 1;
                .calcfontsize(16);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &-data {
                  .calcfontsize(18);
                  font-weight: bold;
                  color: #333;
                  margin-bottom: 10px;
                }
                &-desc {
                  .calcfontsize(14);
                  color: #9c9c9c;
                }
              }
              .gap {
                position: relative;
                &::after {
                  content: '';
                  width: 1px;
                  height: 90%;
                  background: #ccc;
                  opacity: 0.4;
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
        .plan-part {
          flex: 1;
          background: white;
          height: 100%;
          box-sizing: border-box;
          padding: 12px;
          .plan {
            height: 40%;
            &-title {
              .calcfontsize(18);
            }
            &-list {
              width: 100%;
              height: 80%;
              display: flex;
              justify-content: space-between;
              padding: 0 20px;
              .item {
                flex: 1;
                .calcfontsize(16);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &-data {
                  .calcfontsize(18);
                  font-weight: bold;
                  color: #333;
                  margin-bottom: 10px;
                }
                &-desc {
                  .calcfontsize(14);
                  color: #9c9c9c;
                }
              }
              .gap {
                position: relative;
                &::after {
                  content: '';
                  width: 1px;
                  height: 90%;
                  background: #ccc;
                  opacity: 0.4;
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
    .emg {
      flex: 1.2;
      .title {
        margin-bottom: 0px;
      }
      .content {
        width: 100%;
        height: 90%;
        .emg-part {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .custom_item {
            width: 50%;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
</style>
