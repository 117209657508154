<template>
  <a-modal :body-style="bodyStyle" class="modal-container" visible="true" :title="title" :mask-closable="false" width="900px" @cancel="handleCancel">
    <template slot="footer">
      <template v-if="!ifCheck">
        <a-button @click="handleReset">重置</a-button>
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
      </template>
      <template v-else>
        <span />
      </template>
    </template>
    <a-form-model ref="ruleForm" :model="template" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="模板编号" prop="templateCode">
        <a-input v-model.trim="template.templateCode" :disabled="ifCheck" max-length="20" placeholder="请输入模板编号" />
      </a-form-model-item>
      <a-form-model-item label="材料名称" prop="materialName">
        <a-select
          :disabled="ifCheck"
          class="materialSelect"
          :value="template.materialName"
          show-search
          :default-active-first-option="false"
          style="width: 100%;border: none;"
          placeholder="请选择材料名称"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @search="fetchMaterialByName"
          @change="(value, option) => handleMaterialNameChange(value, option, index)"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="material in materials" :key="material.id">{{ material.materialName }}({{ material.materialCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="材料牌号" prop="materialCode">
        <a-select
          :disabled="ifCheck"
          :value="template.materialCode"
          show-search
          :default-active-first-option="false"
          style="width: 100%;border: none;"
          placeholder="请输入材料图号"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @search="fetchMaterialByCode"
          @change="(value, option) => handleMaterialNameChange(value, option, index)"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="material in materials" :key="material.id">{{ material.materialName }}({{ material.materialCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="硬度" prop="operationHardness">
        <a-input v-model.trim="template.operationHardness" :disabled="ifCheck" max-length="20" placeholder="请输入硬度" />
      </a-form-model-item>
      <a-form-model-item label="编制人员" prop="designerId">
        <a-select v-model="template.designerId" :disabled="ifCheck" style="width: 300px" show-search option-filter-prop="children" :filter-option="filterUser">
          <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
          </a-select-option>
        </a-select></a-form-model-item>
      <a-form-model-item class="craft-form-item" label="编制时间" prop="designTime">
        <a-date-picker
          v-model="template.designTime"
          :disabled="true"
          style="width: 300px;"
          format="YYYY-MM-DD HH:mm:ss"
        />
      </a-form-model-item>
    </a-form-model>
    <!-- 工序相关 -->
    <a-table
      bordered
      style="width: 100%; margin-top: -2px;"
      class="processTable"
      :scroll="{x: 0, y: 200 }"
      :columns="craftProcessColumns"
      :data-source="template.processRouteTemplate"
      :pagination="false"
    >
      <div slot="customTitle">
        <p>工序号</p>
        <template v-if="!ifCheck">
          <a-button v-if="!ifPrint" @click="handleAddCraftProcess()">
            <a-icon type="plus-circle" style="vertical-align: middle; margin-right: -5px;" />增加工序
          </a-button>
        </template>
      </div>
      <div v-if="column.sequenceNum %2 !== 0" slot="operation" slot-scope="text, column" class="table-operation">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleDeleteCraft(column)">删除工序</a-menu-item>
            <a-menu-item @click="handleEditCraft(column)">编辑工序</a-menu-item>
            <a-menu-item @click="handleInsertCraft(column, index)">插入工序</a-menu-item>

          </a-menu>
          <a class="operation-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <CraftProcessEditModal
      v-if="processEditModalVisible"
      :modal-type="editModalType"
      :craft-form="editProcessRoute"
      @confirm="handleConfirmCraftModal"
      @cancel="handleCloseCraftModal"
    />
  </a-modal>
</template>
<script>
import {
  getMaterialByMaterialName,
  getMaterialByMaterialCode
} from '@/api/material'
import { getAllUsers } from '@/api/user'
import { addTemplates, updateTemplate } from '@/api/template'
import CraftProcessEditModal from '@/components/CraftProcessEditModal'
import debounce from 'lodash/debounce'
import * as R from 'ramda'
import moment from 'moment'
// const craftProcessColumns = [
//   {
//     align: 'center',
//     width: 150,
//     dataIndex: 'sequenceNum',
//     slots: { title: 'customTitle', customRender: 'sequenceNum' }
//   },
//   {
//     title: '工种',
//     align: 'center',
//     width: 150,
//     dataIndex: 'operationLabel'
//   },
//   {
//     title: '工序名称及内容',
//     align: 'center',
//     width: 300,
//     dataIndex: 'content'
//   },
//   {
//     title: '操作',
//     key: 'operation',
//     align: 'center',
//     width: 100,
//     scopedSlots: {
//       customRender: 'operation'
//     }
//   }
// ]
export default {
  components: {
    CraftProcessEditModal
  },
  props: {
    modalTitle: String,
    templateFrom: Object
  },
  data() {
    this.lastFetchId = 0
    this.fetchMaterialByName = debounce(this.fetchMaterialByName, 800)
    this.fetchMaterialByCode = debounce(this.fetchMaterialByCode, 800)
    return {
      title: '',
      ifCheck: false,
      bodyStyle: {
        height: '500px',
        overflowY: 'scroll'
      },
      craftProcessColumns: [
        {
          align: 'center',
          width: 150,
          dataIndex: 'sequenceNum',
          slots: { title: 'customTitle', customRender: 'sequenceNum' }
        },
        {
          title: '工种',
          align: 'center',
          width: 150,
          dataIndex: 'operationLabel'
        },
        {
          title: '工序名称及内容',
          align: 'center',
          width: 300,
          dataIndex: 'content'
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      currentTemplateList: [],
      id: this.modalTitle === 'update' || this.modalTitle === 'check' ? this.templateFrom.id : '',
      template: {
        templateCode: this.modalTitle === 'update' || this.modalTitle === 'check' ? this.templateFrom.templateCode : '',
        materialId: this.modalTitle === 'update' || this.modalTitle === 'check' ? this.templateFrom.materialId : '',
        materialName: this.modalTitle === 'update' || this.modalTitle === 'check' ? this.templateFrom.materialName : '',
        materialCode: this.modalTitle === 'update' || this.modalTitle === 'check' ? this.templateFrom.materialCode : '',
        operationHardness: this.modalTitle === 'update' || this.modalTitle === 'check' ? this.templateFrom.operationHardness : '',
        designerId: this.modalTitle === 'update' || this.modalTitle === 'check' ? this.templateFrom.designerId : '',
        designTime: this.modalTitle === 'update' || this.modalTitle === 'check' ? this.templateFrom.designTime : this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        processRouteTemplate: this.modalTitle === 'update' || this.modalTitle === 'check' ? this.templateFrom.processRouteTemplate : []
      },
      rules: {
        templateCode: [
          {
            required: true,
            message: '请输入模板编号',
            trigger: 'blur'
          }
        ],
        designerId: [
          {
            required: true,
            message: '请选择编制人员',
            trigger: 'change'
          }
        ]
      },
      userList: [],
      fetching: false,
      materials: [],
      processEditModalVisible: false,
      editProcessRoute: '',
      editModalType: '',
      insertIndex: Number // 工艺插入的位置
    }
  },
  mounted() {
    this.getAllUsers()
    this.handleProcessRouteLabel()
    switch (this.modalTitle) {
      case 'add':
        this.title = '添加模板'
        this.ifCheck = false
        break
      case 'update':
        this.title = '更新模板'
        this.ifCheck = false
        break
      case 'check':
        this.title = '查看模板'
        this.ifCheck = true
        this.craftProcessColumns.pop()
        break
    }
  },
  methods: {
    getAllUsers() {
      getAllUsers(this.userQueryParams).then(res => {
        if (res.data && res.data.accountList) {
          this.userList = R.clone(res.data.accountList)
        }
      })
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.template.processRouteTemplate.map(item => {
            delete item.operationLabel
          })
          if (this.modalTitle === 'add') {
            const data = [this.template]
            if (data[0].designTime) {
              data[0].designTime = moment(new Date(data[0].designTime)).format('YYYY-MM-DD HH:mm:ss')
            }
            addTemplates(data).then(res => {
              this.$emit('confirm')
            })
          } else {
            updateTemplate(this.id, this.template).then(res => {
              this.$emit('confirm')
            })
          }
        }
      })
    },
    handleConfirmCraftModal(data) {
      if (this.editModalType === 'add' || this.editModalType === 'insert') {
        const _process = {
          operationLabel: data.operationLabel,
          operationId: data.operationId,
          content: data.content
        }
        const _testProcess = {
          operationLabel: data.testLabel,
          operationId: data.testName,
          content: data.testDesc
        }
        if (this.modalType === 'add') {
          this.template.processRouteTemplate.push(_process, _testProcess)
        } else {
          this.template.processRouteTemplate.splice(
            this.insertIndex,
            0,
            _process,
            _testProcess
          )
        }
        this.template.processRouteTemplate.map((val, index) => {
          val.sequenceNum = index + 1
        })
      } else {
        this.template.processRouteTemplate.map((val, index, arr) => {
          if (val.sequenceNum === data.sequenceNum) {
            this.template.processRouteTemplate[index].operationLabel =
              data.operationLabel
            this.template.processRouteTemplate[index].operationId = data.operationId
            this.template.processRouteTemplate[index].content = data.content
            this.template.processRouteTemplate[index + 1].content = data.testDesc
            delete this.template.processRouteTemplate[index].testDesc
          }
        })
      }
      this.processEditModalVisible = false
    },
    handleCloseCraftModal() {
      this.processEditModalVisible = false
    },
    // 添加工序
    handleAddCraftProcess() {
      this.editModalType = 'add'
      this.processEditModalVisible = true
    },
    handleInsertCraft(column, cardDataIndex) {
      this.editModalType = 'insert'
      this.insertIndex = column.sequenceNum + 1
      this.processEditModalVisible = true
    },
    // 编辑工序
    handleEditCraft(column) {
      this.editModalType = 'edit'
      this.template.processRouteTemplate.map((val, index, arr) => {
        if (val.sequenceNum === column.sequenceNum) {
          this.editProcessRoute = column
          this.editProcessRoute.testDesc = arr[index + 1].content
          console.log(this.editProcessRoute)
        }
      })
      this.processEditModalVisible = true
    },
    // 删除工序
    handleDeleteCraft(column, cardDataIndex) {
      let operationIndex
      this.template.processRouteTemplate.map((val, index) => {
        if (val.sequenceNum === column.sequenceNum) {
          this.template.processRouteTemplate.splice(index, 2)
          operationIndex = index
        }
      })
      this.updateProcessRouteIndex(operationIndex)
    },
    updateProcessRouteIndex(index) {
      this.template.processRouteTemplate.map((val, index) => {
        val.sequenceNum = index + 1
      })
      // this.handleProcessRouteLabel()
    },
    handleProcessRouteLabel() {
      this.template.processRouteTemplate.map(route => {
        route[
          `operationLabel`
        ] = `${route.operationName}(${route.operationCode})`
      })
    },
    fetchMaterialByName(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      getMaterialByMaterialName(value).then(result => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.materials = result.data
        this.fetching = false
      })
    },
    fetchMaterialByCode(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      getMaterialByMaterialCode(value).then(result => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.materials = result.data
        this.fetching = false
      })
    },
    handleMaterialNameChange(id, option, index) {
      this.materials.map(val => {
        if (val.id === id) {
          console.log(val)
          this.template.materialName = val.materialName
          this.template.materialCode = val.materialCode
          this.template.materialId = val.id
        }
      })
      Object.assign(this, {
        materials: [],
        fetching: false
      })
    }
    // handleChange(value) {
    //   console.log(value)
    // }
  }
}
</script>

<style scoped>
.modal-container {
  height: 400px;
}
/deep/ .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background: #fff !important;
}
/deep/ .ant-select-selection {
  background: #fff;
}
/deep/ .ant-select-disabled {
  color: rgba(0, 0, 0, 0.65);
  background: #fff !important;
}
.userSelect >>> .ant-select-selection {
  border: none !important;
  background: #fff;
}
.userSelect >>> .anticon {
  display: none;
}
</style>
