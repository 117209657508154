<!-- 出库明细 -->
<template>
  <div class="app-container">
    <div class="top-tip" v-if="hasSelected">
      <div class="selected-wrapper">
        <div>
          <span v-if="hasSelected">
            已选中
            <strong>{{ selectedRowKeys.length }}</strong> 项
          </span>
          <a-dropdown v-if="hasSelected">
            <a class="batch-operate-link" @click="(e) => e.preventDefault()">
              <a-icon type="edit" />批量操作
            </a>

            <a-menu slot="overlay">
              <!-- <a-menu-item>
                <a @click="handleSettleProducts()">批量结算</a>
              </a-menu-item>-->
              <a-menu-item>
                <a @click="handleCreateSettlements()">生产预对账单</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
    </div>
    <el-button class="download" type="text" icon="el-icon-download" @click="handleDownloadTemplate">点击下载对账单导入匹配模板</el-button>
    <a-tabs :active-key="currentActiveKey" type="card" @change="handleTabChange">
      <a-tab-pane key="reconciled" tab="待对账" />
      <a-tab-pane key="all" tab="所有状态" />
    </a-tabs>
    <a-table
      :key="tableKey"
      row-key="id"
      bordered
      :scroll="{ x: 1700 }"
      :columns="inventoryColumns"
      :data-source="inventoryList"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: inventoryRowSelection.getCheckboxProps,
      }"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-else-if="column.searchType === 'number'">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([e, selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([selectedKeys[0], e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px"
            :ranges="{
              今天: [$moment(), $moment()],
              本月: [$moment().startOf('month'), $moment().endOf('month')],
            }"
            @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <span slot="warehouseType" slot-scope="text">
        <a-tag v-if="text === 1" color="orange">半成品</a-tag>
        <a-tag v-if="text === 0" color="green">成品</a-tag>
        <a-tag v-if="text === 2" color="blue">工装</a-tag>
        <a-tag v-if="text === 4" color="red">废品</a-tag>
        <a-tag v-if="text === 5" color="red">来料退回库</a-tag>
        <a-tag v-if="text === 3" color="orange">材料</a-tag>
      </span>
      <span slot="isParts" slot-scope="text">
        <a-tag v-if="text === 0" color="green">否</a-tag>
        <a-tag v-if="text === 1" color="red">是</a-tag>
      </span>
      <span slot="settleType" slot-scope="text">
        <a-tag v-if="text === 0" color="green">挂账</a-tag>
        <a-tag v-if="text === 1" color="red">现款</a-tag>
      </span>
      <span slot="settleStatus" slot-scope="text">
        <a-tag v-if="text === 0">待对账</a-tag>
        <a-tag v-if="text === 1" color="blue">预对账</a-tag>
        <a-tag v-if="text === 2" color="orange">已开票</a-tag>
        <a-tag v-if="text === 3" color="green">已结算</a-tag>
      </span>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <template slot="invoiceNumber" slot-scope="text, column">
        <template v-if="column.invoiceNumber">
          <span>{{ text }}</span>
        </template>
        <template v-else>
          <el-link type="primary" @click="handleSettleSingleProducts(column)">点击录入</el-link>
        </template>
      </template>
      <div slot="operation" slot-scope="text, column">
        <a-dropdown v-if="column.settlementNumber && column.settleStatus === 1">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item v-if="column.settlementNumber && column.settleStatus === 1" @click="handleSettlementsBatch(column)">对账分批</a-menu-item>
            <!-- <a-menu-item @click="handleCheckLog(column)">查看操作日志</a-menu-item> -->
          </a-menu>
          <a>
            操作
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <div class="upload-btn">
      <c-upload :action="`${baseURL}wait-settlements/match-imports`" accept=".xlsx" :show-upload-list="false" @change="handleFileChange">
        <a-button slot="content" style="height: 36px" type="primary">
          <a-icon type="upload" />导入匹配对账
        </a-button>
      </c-upload>
    </div>
    <el-dropdown class="export-craft" @command="handleExportCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出出库明细记录</el-button>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item command="SELECTED">导出当前已选工艺</el-dropdown-item>
        <el-dropdown-item command="PAGE">导出当前页工艺</el-dropdown-item>-->
        <el-dropdown-item command="FILTERED">导出当前筛选记录</el-dropdown-item>
        <!-- <el-dropdown-item command="ALL">导出所有工艺</el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
    <CraftEditModal :modal-title="currentEditType" :craft-form="currentCraft" :visible="visible" />
    <ProcessRouteCard
      v-if="routeModalVisible"
      :modal-title="currentPlanCode"
      :record="currentProcessRoute"
      @cancel="handleRouteModalCanceled"
      @confirm="handleRouteModalConfirmed"
    />
    <SettleRecordsModal
      v-if="ifShowSettleRecordModal"
      :settle-data="settleIds"
      @cancel="handleSettleProductssModalCanceled"
      @confirm="handleSettleProductsModalConfirmed"
    />
    <CreateBillModal
      v-if="billModalVisible"
      :ids="selectedRowKeys"
      @confirm="handleSettlementsConfirmed"
      @cancel="handleSettlementsCancel"
    />
    <SettlementBatch
      v-if="settleBatchVisible"
      :id="batchId"
      :max-count="batchCount - 1"
      @confirm="handleSettlementsBatchConfirmed"
      @cancel="handleSettlementsBatchCancel"
    />
    <SettleMatchModal v-if="settleMatchModalVisible" :list="matchList" @confirm="handleSettleMatchConfirm" />
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { inventoryColumns, inventoryRowSelection } from './dataLogic'
import CraftEditModal from '@/components/CraftEditModal'
import SettleRecordsModal from '@/components/SettleRecordsModal'
import SettleMatchModal from '@/components/SettleMatchModal'
import ProcessRouteCard from '@/components/ProcessRouteCard'
import CreateBillModal from '@/components/CreateBillModal'
import SettlementBatch from '@/components/SettlementBatch'
import { downloadTemplate } from '@/common/templateDownload'
import { downloadItem } from '@/utils/api.request'
import * as R from 'ramda'
import {
  getSettleAccountsInventorys,
  exportSettleInventory,
  exportSettleAccount,
  getWaitSettlements
} from '@/api/inventory'

export default {
  components: {
    CraftEditModal,
    ProcessRouteCard,
    SettleRecordsModal,
    CreateBillModal,
    SettlementBatch,
    SettleMatchModal
  },
  data() {
    return {
      tableKey: 0,
      loading: false,
      inventoryList: [],
      inventoryColumns,
      inventoryRowSelection,
      selectedRowKeys: [],
      selectedRowPlanIds: [],
      searchInput: null,
      baseURL: '',
      fileURL: '',
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initInventoryListQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentInventoryListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentActiveKey: 'reconciled',
      currentEditType: '更新',
      currentCraft: {},
      currentPlanCode: '',
      currentProcessRoute: [],
      visible: false,
      routeModalVisible: false,
      columnSearchParams: {},
      ifShowSettleRecordModal: false,
      selectedSettleRows: [],
      settleIds: [],
      exportLoading: false,
      billModalVisible: false,
      settleBatchVisible: false,
      batchId: '',
      batchCount: '',

      settleMatchModalVisible: false,
      matchList: []
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  created() {
    // 初始化加载数据
    this.baseURL = process.env.VUE_APP_BASE_API
    this.getSettlementsByTab()
  },
  methods: {
    handleTabChange(activeKey) {
      if (activeKey === 'all') {
        this.getInventorys(this.initInventoryListQueryParams)
      } else {
        this.getWaitSettlements(this.initInventoryListQueryParams)
      }
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.currentActiveKey = activeKey
      this.tableKey += 1
    },
    // 获取工艺数据
    getInventorys(data) {
      this.loading = true
      getSettleAccountsInventorys(data)
        .then(res => {
          this.inventoryList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    getWaitSettlements(data) {
      this.loading = true
      getWaitSettlements(data)
        .then(res => {
          this.inventoryList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    getWaitSettlementsByParams() {
      const cQueryParams = deepClone(this.currentInventoryListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getWaitSettlements(queryParams)
    },
    // 根据当前分页数据和搜索条件查询数据
    getInventorysByParams() {
      const cQueryParams = deepClone(this.currentInventoryListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getInventorys(queryParams)
    },
    getSettlementsByTab() {
      if (this.currentActiveKey === 'all') {
        this.getInventorysByParams()
      } else {
        this.getWaitSettlementsByParams()
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    // 重置搜索筛选条件
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedSettleRows = selectedRows
      this.selectedRowKeys = selectedRowKeys
      console.log(selectedRows, selectedRowKeys)
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      console.log(filtersKeys)
      if (filtersKeys.includes('isParts')) {
        delete this.columnSearchParams['isParts']
        if (filters['isParts'].length > 0) {
          this.columnSearchParams['isParts'] = `in:${filters[
            'isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['isParts']
        }
      }
      if (filtersKeys.includes('settleStatus')) {
        delete this.columnSearchParams['settleStatus']
        if (filters['settleStatus'].length > 0) {
          this.columnSearchParams['settleStatus'] = `in:${filters[
            'settleStatus'
          ].toString()}`
        } else {
          delete this.columnSearchParams['settleStatus']
        }
      }
      if (filtersKeys.includes('settleType')) {
        delete this.columnSearchParams['settleType']
        if (filters['settleType'].length > 0) {
          this.columnSearchParams['settleType'] = `in:${filters[
            'settleType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['settleType']
        }
      }
      if (filtersKeys.includes('recordWarehouseType')) {
        delete this.columnSearchParams['recordWarehouseType']
        if (filters['recordWarehouseType'].length > 0) {
          this.columnSearchParams['recordWarehouseType'] = `in:${filters[
            'recordWarehouseType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['recordWarehouseType']
        }
      }

      this.pagination = pagination
      this.currentInventoryListQueryParams.pageNum = pagination.current
      this.currentInventoryListQueryParams.pageSize = pagination.pageSize

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentInventoryListQueryParams.sort_by =
          sortType + sorter.columnKey
      } else {
        this.currentInventoryListQueryParams.sort_by = null
      }

      this.getSettlementsByTab()
    },
    handleSettleSingleProducts(column) {
      this.settleIds = [column.id]
      this.ifShowSettleRecordModal = true
    },
    handleSettleProducts() {
      this.settleIds = this.selectedRowKeys
      this.ifShowSettleRecordModal = true
    },
    handleSettleProductssModalCanceled() {
      this.ifShowSettleRecordModal = false
    },
    handleSettleProductsModalConfirmed() {
      this.settleIds = []
      this.selectedRowKeys = []
      console.log(this.selectedKeys, this.selectedRowKeys)
      this.ifShowSettleRecordModal = false
      // this.getInventorysByParams()
      this.getSettlementsByTab()
    },
    handleExportCommand(command) {
      this.exportLoading = true
      const params = deepClone(this.columnSearchParams)

      if (this.currentActiveKey === 'reconciled') {
        params.status = 0
      }

      switch (command) {
        case 'FILTERED':
          exportSettleAccount({
            ...params
          })
            .then(res => {
              this.exportLoading = false
              this.download(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        default:
          this.exportLoading = false
          break
      }
    },
    download(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        '出库明细记录表' + currentDate
      )
    },
    handleCreateSettlements() {
      this.billModalVisible = true
    },
    handleSettlementsConfirmed() {
      this.selectedRowKeys = []
      this.billModalVisible = false
      // this.getInventorysByParams()
      this.getSettlementsByTab()
    },
    handleSettlementsCancel() {
      this.billModalVisible = false
    },

    handleSettlementsBatch(column) {
      this.batchId = column.id
      this.batchCount = column.waitSettleCount
      this.settleBatchVisible = true
    },
    handleSettlementsBatchConfirmed() {
      this.settleBatchVisible = false
      // this.getInventorysByParams()
      this.getSettlementsByTab()
    },
    handleSettlementsBatchCancel() {
      this.settleBatchVisible = false
    },
    // 处理文件上传
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.matchList = info.file.response
          this.settleMatchModalVisible = true
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`文件导入失败，请稍后再试`)
      }
    },
    handleDownloadTemplate() {
      downloadTemplate('settleAccount')
    },
    handleSettleMatchConfirm() {
      this.settleMatchModalVisible = false
      // this.getInventorysByParams()
      this.getSettlementsByTab()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  position: relative;
  .upload-btn {
    float: right;
    margin-top: -50px;
  }
  .file-wrapper {
    width: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .filename-wrapper {
      display: inline-block;
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 10px;

      a {
        color: #67c23a;
      }
    }

    .filename-delete {
      color: #ff4d4f;
      margin-right: 8px;
    }
  }
  .batch-operate-link {
    padding-left: 20px;
    cursor: pointer;
    color: #409eff;
  }

  .file-link {
    color: #409eff;
  }

  .craft-download-button {
    position: absolute;
    right: 20px;
    top: 70px;
  }
  .operation {
    .operation-btn {
      cursor: pointer;
      color: #409eff;
    }
  }

  .export-craft {
    float: right;
    top: -50px;
    right: 150px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }
  .selected-wrapper {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .download {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }
}
</style>
