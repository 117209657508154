export const outSourcingColumns = [
  {
    title: '顺序号',
    dataIndex: 'sequence',
    key: 'sequence',
    align: 'center',
  },
  {
    title: '产品名称',
    dataIndex: 'productInfo.name',
    key: 'productInfo.name',
    align: 'center',
  },
  {
    title: '产品图号',
    dataIndex: 'productInfo.drawingNum',
    key: 'productInfo.drawingNum',
    align: 'center',
  },
  {
    title: '规格',
    dataIndex: 'productInfo.specification',
    key: 'productInfo.specification',
    align: 'center',
  },
  {
    title: '单位',
    dataIndex: 'productInfo.unit',
    key: 'productInfo.unit',
    align: 'center',
  },
  {
    title: '供应商名称',
    dataIndex: 'companyInfo.name',
    key: 'companyInfo.name',
    align: 'center',
  },
  {
    title: '单价',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    align: 'center',
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
  },
  {
    title: '操作',
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
