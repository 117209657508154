<template>
  <div class="app-container">
    <a-table
      row-key="id"
      bordered
      class="users-table"
      :columns="
        offerColumns"
      :data-source="list"
      :loading="loading"
      :pagination="pagination"
      :scroll="{ x: 1500 }"
      @change="handleTableChange"
    >
    <div slot="taxRate" slot-scope="text">
          <span>{{ text ? text * 100 + '%' : '' }}</span>
        </div>
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <div v-if="column.searchType===&quot;number&quot;">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px"
            :ranges="{
              今天: [$moment(), $moment()],
              本月: [$moment().startOf('month'), $moment().endOf('month')],
            }"
            @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>
      <div slot="code" slot-scope="text">
        <el-link type="primary" @click="handleCheckDetail(text)">{{text}}</el-link>
      </div>
      <div slot="status" slot-scope="text">
        <a-tag color="blue" v-if="text === 'WSO'">待报出</a-tag>
        <a-tag color="orange" v-if="text === 'WDL'">待成交</a-tag>
        <a-tag color="red" v-if="text === 'CNL'">已取消</a-tag>
        <a-tag color="green" v-if="text === 'DEL'">已成交</a-tag>
        <a-tag color="lime" v-if="text === 'PDL'">部分成交</a-tag>
        <a-tag color="red" v-if="text === 'NDL'">未成交</a-tag>
        <a-tag color="red" v-if="text === 'RVC'">已撤销</a-tag>
      </div>
      <div slot="payType" slot-scope="text">
        <span v-if="text === 'CSH'">现金</span>
        <span v-if="text === 'BNK'">银行转账</span>
        <span v-if="text === 'CRD'">挂账</span>
      </div>
      <div slot="deliveryType" slot-scope="text">
        <span v-if="text === 'SND'">送货</span>
        <span v-if="text === 'SLF'">自取</span>
        <span v-if="text === 'EXP'">快递</span>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operate" slot-scope="text, column" class="table-user">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleCheckDetail(column.code)">查看报价单明细</a-menu-item>
            <a-menu-item v-if="column.status === 'WSO'" @click="handleOffer(column.code)">报出报价单</a-menu-item>
            <a-menu-item @click="handleCancel(column.code)">撤销报价单</a-menu-item>
            <a-menu-item v-if="column.status === 'WDL' || column.status === 'DEL'" @click="handlePrintDetail(column.code)">打印报价单明细</a-menu-item>
            <a-menu-item v-if="column.status === 'WDL'" @click="handleOrder(column.code)">成交报价</a-menu-item>
          </a-menu>
          <a class="user-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <OfferDetailModal
      v-if="offerModalVisible"
      :code="currentOfferCode"
      @confirm="handleOfferModalConfirm"
      @cancel="handleOfferModalConfirm"
    />
    <QuotationsModal
      v-if="quotationsModalVisible"
      :code="currentOfferCode"
      @confirm="handleQuotationsModalConfirm"
      @cancel="handleQuotationsModalCancel"
    />
    <RevocationModal
      v-if="revocationModalVisible"
      :code="currentOfferCode"
      @confirm="handleRevocationModalConfirm"
      @cancel="handleRevocationModalCancel"
    />
    <OrderModal v-if="orderModalVisible" :code="currentOfferCode" @confirm="handleOrderModalConfirm" @cancel="handleOrderModalCancel" />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { offerColumns } from './dataLogic'
import { getOffers } from '@/api/offer'
import { downloadTemplate } from '@/common/templateDownload'
import OfferDetailModal from '@/components/OfferDetailModal/index.vue'
import QuotationsModal from '@/components/QuotationsModal/index.vue'
import RevocationModal from '@/components/RevocationModal/index.vue'
import OrderModal from '@/components/OrderModal/index.vue'

export default {
  components: {
    OfferDetailModal,
    QuotationsModal,
    RevocationModal,
    OrderModal
  },
  data() {
    return {
      loading: false,
      uploading: false,
      list: [],

      offerColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentUser: {},
      columnSearchParams: {},
      uploadUrl: process.env.VUE_APP_BASE_API,
      exportLoading: false,
      offerModalVisible: false,
      currentOfferCode: '',
      quotationsModalVisible: false,
      revocationModalVisible: false,
      orderModalVisible: false
    }
  },
  created() {
    this.getOfferList(this.initQueryParams)
  },
  methods: {
    getOfferList(data) {
      this.loading = true
      getOffers(data)
        .then(res => {
          this.list = res.data.records.map((item, index) => {
            item.index =
              (this.currentQueryParams.pageNum - 1) *
                this.currentQueryParams.pageSize +
              index +
              1
            return item
          })
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getOfferListByParams() {
      const cQueryParams = deepClone(this.currentQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getOfferList(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentQueryParams.pageNum = pagination.current
      this.currentQueryParams.pageSize = pagination.pageSize
      this.getOfferListByParams()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      if (dataIndex === 'roles') {
        dataIndex = 'role.name'
      }
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleDownloadTemplate() {
      downloadTemplate('user')
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`批量导入失败，请稍后再试`)
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      this.$info({
        title: '员工信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入数据总计：' + response.totalNum + '条;'),
          h('p', '非法数据条数：' + response.failedNum + ';'),
          h('p', '导入成功数据条数：' + response.successfulNum + ';'),
          h('p', '非法数据信息:'),
          h('p', response.invalidMessages.join(' ||  '))
        ]),
        onOk: () => {
          this.uploading = false
          this.getUsers(this.currentUserListQueryParams)
        }
      })
    },
    downloadInquiry(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.USER_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleOfferModalConfirm() {
      this.offerModalVisible = false
    },
    handleOfferModalCancel() {
      this.offerModalVisible = false
    },
    handleCheckDetail(code) {
      this.currentOfferCode = code
      this.offerModalVisible = true
    },
    handlePrintDetail(code) {
      const routeData = this.$router.resolve({
        path: '/quote-card',
        query: {
          code: code
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleOffer(code) {
      this.currentOfferCode = code
      this.quotationsModalVisible = true
    },
    handleQuotationsModalConfirm() {
      this.quotationsModalVisible = false
      this.getOfferListByParams()
    },
    handleQuotationsModalCancel() {
      this.quotationsModalVisible = false
    },
    handleCancel(code) {
      this.currentOfferCode = code
      this.revocationModalVisible = true
    },
    handleRevocationModalConfirm() {
      this.revocationModalVisible = false
      this.getOfferListByParams()
    },
    handleRevocationModalCancel() {
      this.revocationModalVisible = false
    },
    handleOrder(code) {
      this.currentOfferCode = code
      this.orderModalVisible = true
    },
    handleOrderModalConfirm() {
      this.orderModalVisible = false
      this.getOfferListByParams()
    },
    handleOrderModalCancel() {
      this.orderModalVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
}
</style>
