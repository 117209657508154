<template>
  <a-modal class="modal-container" :visible="true" title="选择模板" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="template" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="模板" prop="id">
        <a-select
          v-model="template.id"
          show-search
          placeholder="请输入模板编码"
          style="width: 300px"
          option-filter-prop="children"
          @search="fetchTemplateByCode"
        >
          <a-select-option v-for="item in currentTemplateList" :key="item.templateId" :value="item.templateId">{{ item.templateCode }}</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { getAllTemplates } from '@/api/template'
import debounce from 'lodash/debounce'
import * as R from 'ramda'

export default {
  data() {
    this.lastFetchId = 0
    this.fetchTemplateByCode = debounce(this.fetchTemplateByCode, 800)
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      loading: false,
      fetching: false, // 搜索
      currentTemplateList: [],
      template: {
        id: '',
      },
      rules: {
        id: [
          {
            required: true,
            message: '请选择模板',
            trigger: 'change',
          },
        ],
      },
    }
  },
  mounted() {
    // this.getTemplates()
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    fetchTemplateByCode(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      getAllTemplates({ templateCode: value }).then((res) => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.currentTemplateList = res.data
        this.fetching = false
      })
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('confirm', this.template.id)
        }
      })
    },
    // handleChange(value) {
    //   console.log(value)
    // }
  },
}
</script>

<style scoped>
</style>
