<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" :title="title" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="target" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="目标名称" prop="targetName">
        <a-input v-model.trim="target.targetName" :max-length="20" placeholder="请输入目标名称" />
      </a-form-model-item>
      <a-form-model-item label="目标周期" prop="targetStartTime">
        <a-range-picker format="YYYY-MM-DD HH:mm:ss" :value="[target.targetStartTime, target.targetEndTime]" @change="onDateChange" />
      </a-form-model-item>
      <template v-if="targetType === 'month'">
        <a-form-model-item label="插入急件占比" prop="emergencyRate">
          <a-input-number v-model="target.emergencyRate" :min="0" :max="1" :precision="2" />
        </a-form-model-item>
      </template>
    </a-form-model>
  </a-modal>
</template>
<script>
import {
  updateTarget,
  addTarget,
  addWeekTarget,
  updateWeekTarget,
  addDayTarget,
  updateDayTarget
} from '@/api/target'
import moment from 'moment'
import * as R from 'ramda'
import { getMonthWeek } from '@/utils/index'
export default {
  props: {
    modalTitle: String,
    targetForm: Object,
    targetType: String, // 月目标 周目标  日目标，
    fatherTargetId: Number
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      target: {
        id: this.targetForm.id,
        targetName:
          this.targetForm.targetName ||
          moment().format('YYYY') + '年' + moment().format('MM') + '月目标',
        targetStartTime:
          this.targetForm.targetStartTime ||
          moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        targetEndTime:
          this.targetForm.targetEndTime ||
          moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        emergencyRate: this.targetForm.emergencyRate || 0.2
      },
      rules: {
        targetName: [
          {
            required: true,
            message: '请输入目标名称',
            trigger: 'blur'
          }
        ],
        targetStartTime: [
          {
            required: true,
            message: '请输入目标周期',
            trigger: 'change'
          }
        ],
        emergencyRate: [
          {
            required: this.targetType === 'month',
            message: '请输入插入急件占比',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function() {
      return this.modalTitle.indexOf('新增') > -1
    },
    isUpdate: function() {
      return this.modalTitle.indexOf('编辑') > -1 || this.modalTitle.indexOf('更新') > -1
    }
  },
  mounted() {
    if (this.targetType === 'month') {
      this.target.targetStartTime =
        this.targetForm.targetStartTime ||
        moment().startOf('month').format('YYYY-MM-DD HH:mm:ss')
      this.target.targetEndTime =
        this.targetForm.targetEndTime ||
        moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (this.targetType === 'week') {
      const date = moment(new Date()).format('YYYY-MM-DD').split('-')
      const weekNum = getMonthWeek(
        parseInt(date[0]),
        parseInt(date[1]),
        parseInt(date[2])
      )
      this.target.targetName = `${date[0]}年${date[1]}月第${weekNum}周目标`
      this.target.targetStartTime =
        this.targetForm.targetStartTime ||
        moment().startOf('week').format('YYYY-MM-DD HH:mm:ss')
      this.target.targetEndTime =
        this.targetForm.targetEndTime ||
        moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      this.target.emergencyRate = null
    } else if (this.targetType === 'day') {
      this.target.targetName =
        this.targetForm.targetName ||
        moment().format('YYYY') +
          '年' +
          moment().format('MM') +
          '月' +
          moment().format('DD') +
          '日目标'
      this.target.targetStartTime =
        this.targetForm.targetStartTime ||
        moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.target.targetEndTime =
        this.targetForm.targetEndTime ||
        moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addTarget(this.target)
          } else if (this.isUpdate) {
            this.updateTarget(this.target)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updateTarget(operationFormData) {
      this.loading = true
      if (this.targetType === 'month') {
        try {
          updateTarget(operationFormData.id, operationFormData)
            .then((res) => {
              if (res.status) {
                const successMessage = '更新目标成功！'
                this.$emit('confirm')
                this.$message.success(successMessage)
              } else {
                this.$message.error('更新目标失败，请重试')
                this.loading = false
              }
            })
            .catch(
              // 工序失败原因
              (reason) => {
                this.loading = false
                console.log('处理失败的 promise (' + reason + ')')
              }
            )
        } catch (e) {
          console.log(e)
        }
      } else if (this.targetType === 'week') {
        const submitData = R.clone(operationFormData)
        delete submitData.emergencyRate
        updateWeekTarget(submitData.id, submitData)
          .then((res) => {
            if (res.status) {
              const successMessage = '更新目标成功！'
              this.$emit('confirm')
              this.$message.success(successMessage)
            } else {
              this.$message.error('更新目标失败，请重试')
              this.loading = false
            }
          })
          .catch(
            // 工序失败原因
            (reason) => {
              this.loading = false
              console.log('处理失败的 promise (' + reason + ')')
            }
          )
      } else if (this.targetType === 'day') {
        const submitData = R.clone(operationFormData)
        delete submitData.emergencyRate
        updateDayTarget(submitData.id, submitData)
          .then((res) => {
            if (res.status) {
              const successMessage = '更新目标成功！'
              this.$emit('confirm')
              this.$message.success(successMessage)
            } else {
              this.$message.error('更新目标失败，请重试')
              this.loading = false
            }
          })
          .catch(
            // 工序失败原因
            (reason) => {
              this.loading = false
              console.log('处理失败的 promise (' + reason + ')')
            }
          )
      }
    },
    addTarget(operationFormData) {
      this.loading = true
      const submitData = R.clone(operationFormData)
      submitData.id = null
      if (this.targetType === 'month') {
        addTarget(submitData)
          .then((res) => {
            console.log(res)
            if (res.status === 201) {
              this.$message.success('新增成功')
              this.$emit('confirm')
            } else {
              this.$message.error('新增目标失败，请重试！')
              this.loading = false
            }
          })
          .catch(
            // 工序失败原因
            (reason) => {
              this.loading = false
              console.log('处理失败的 promise (' + reason + ')')
            }
          )
      } else if (this.targetType === 'week') {
        addWeekTarget(this.fatherTargetId, {
          targetName: submitData.targetName,
          targetStartTime: submitData.targetStartTime,
          targetEndTime: submitData.targetEndTime
        })
          .then((res) => {
            console.log(res)
            if (res.status === 201) {
              this.$message.success('新增成功')
              this.$emit('confirm')
            } else {
              this.$message.error('新增目标失败，请重试！')
              this.loading = false
            }
          })
          .catch(
            // 工序失败原因
            (reason) => {
              this.loading = false
              console.log('处理失败的 promise (' + reason + ')')
            }
          )
      } else if (this.targetType === 'day') {
        addDayTarget(this.fatherTargetId, {
          targetName: submitData.targetName,
          targetStartTime: submitData.targetStartTime,
          targetEndTime: submitData.targetEndTime
        })
          .then((res) => {
            console.log(res)
            this.$message.success('新增成功')
            this.$emit('confirm')
            this.loading = false
          })
          .catch(
            // 工序失败原因
            (reason) => {
              this.loading = false
              console.log('处理失败的 promise (' + reason + ')')
            }
          )
      }
    },
    onDateChange(date, dateString) {
      console.log(date, dateString)
      this.target.targetStartTime = dateString[0]
      this.target.targetEndTime = dateString[1]
    }
  }
}
</script>

<style scoped>
</style>
