export const approvalDetailColumns = [
  {
    title: '序号',
    width: 100,
    dataIndex: 'index',
    key: 'index',
    align: 'center',
  },
  {
    title: '产品名称',
    width: 150,
    dataIndex: 'productInfo.name',
    key: 'productInfo.name',
    align: 'center',
  },
  {
    title: '产品图号',
    width: 150,
    dataIndex: 'productInfo.drawingNum',
    key: 'productInfo.drawingNum',
    align: 'center',
  },
  {
    title: '产品规格',
    width: 150,
    dataIndex: 'productInfo.specification',
    key: 'productInfo.specification',
    align: 'center',
  },
  {
    title: '询价数量',
    width: 130,
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
  },
  {
    title: '报出无税单价',
    width: 150,
    dataIndex: 'summaryCostInfo.totalAmountTaxFree',
    key: 'summaryCostInfo.totalAmountTaxFree',
    align: 'center',
  },
  {
    title: '最终确认无税单价',
    width: 180,
    dataIndex: 'finalAmountTaxFree',
    key: 'finalAmountTaxFree',
    align: 'center',
  },
  {
    title: '单件材料费成本',
    width: 180,
    dataIndex: 'summaryCostInfo.unitMaterialCost',
    key: 'summaryCostInfo.unitMaterialCost',
    align: 'center',
  },
  {
    title: '材料成本占比',
    width: 180,
    dataIndex: 'summaryCostInfo.materialCostRate',
    key: 'summaryCostInfo.materialCostRate',
    align: 'center',
    scopedSlots: {
      customRender: 'materialCostRate',
    },
  },
  {
    title: '单件外协成本',
    width: 180,
    dataIndex: 'summaryCostInfo.unitOutProcessingCost',
    key: 'summaryCostInfo.unitOutProcessingCost',
    align: 'center',
  },
  {
    title: '外协成本占比',
    width: 200,
    dataIndex: 'summaryCostInfo.outProcessingCostRate',
    key: 'summaryCostInfo.outProcessingCostRate',
    align: 'center',
    scopedSlots: {
      customRender: 'outProcessingCostRate',
    },
  },
  {
    title: '单件外购成本',
    width: 200,
    dataIndex: 'summaryCostInfo.unitOutsourcingCost',
    key: 'summaryCostInfo.unitOutsourcingCost',
    align: 'center',
  },
  {
    title: '外购成本占比',
    width: 200,
    dataIndex: 'summaryCostInfo.outsourcingCostRate',
    key: 'summaryCostInfo.outsourcingCostRate',
    align: 'center',
    scopedSlots: {
      customRender: 'outsourcingCostRate',
    },
  },
  {
    title: '单件公司剩余金额占比',
    width: 200,
    dataIndex: 'summaryCostInfo.unitCompanyResidueRate',
    key: 'summaryCostInfo.unitCompanyResidueRate',
    align: 'center',
    scopedSlots: {
      customRender: 'unitCompanyResidueRate',
    },
  },
  {
    title: '操作',
    width: 80,
    key: 'operate',
    fixed: 'right',
    align: 'center',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
