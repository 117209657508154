var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-container" }, [
    _c("div", { attrs: { id: "craft-card-print" } }, [
      _c("div", { staticStyle: { "page-break-after": "always" } }, [
        _c("div", { staticClass: "title" }, [_vm._v("报价单")]),
        _c("div", { staticClass: "base-info" }, [
          _c("div", { staticClass: "row-1" }, [
            _c("div", { staticClass: "item left" }, [
              _c("div", { staticClass: "label" }, [_vm._v("报价单号")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.data.code)),
              ]),
            ]),
            _c("div", { staticClass: "item right" }, [
              _c("div", { staticClass: "label" }, [_vm._v("报价日期")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.data.startTime)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row-1 row-bottom" }, [
            _vm._m(0),
            _c("div", { staticClass: "item right" }, [
              _c("div", { staticClass: "label" }, [_vm._v("采购方")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.data.clientName)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row-1 row-bottom" }, [
            _vm._m(1),
            _c("div", { staticClass: "item right" }, [
              _c("div", { staticClass: "label" }, [_vm._v("联系人")]),
              _c("div", { staticClass: "content" }, [_vm._v(_vm._s())]),
            ]),
          ]),
          _c("div", { staticClass: "row-1 row-bottom" }, [
            _vm._m(2),
            _c("div", { staticClass: "item right" }, [
              _c("div", { staticClass: "label" }, [_vm._v("地址")]),
              _c("div", { staticClass: "content" }, [_vm._v(_vm._s())]),
            ]),
          ]),
          _c("div", { staticClass: "row-1 row-bottom" }, [
            _vm._m(3),
            _c("div", { staticClass: "item right" }, [
              _c("div", { staticClass: "label" }, [_vm._v("电话")]),
              _c("div", { staticClass: "content" }, [_vm._v(_vm._s())]),
            ]),
          ]),
          _c("div", { staticClass: "row-1 row-bottom" }, [
            _vm._m(4),
            _c("div", { staticClass: "item right" }, [
              _c("div", { staticClass: "label" }, [_vm._v("传真")]),
              _c("div", { staticClass: "content" }, [_vm._v(_vm._s())]),
            ]),
          ]),
          _c("div", { staticClass: "row-1 row-bottom" }, [
            _c("div", { staticClass: "item left" }, [
              _c("div", { staticClass: "label" }, [_vm._v("付款方式")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.PAT_MAP[_vm.data.payType])),
              ]),
            ]),
            _c("div", { staticClass: "item right" }, [
              _c("div", { staticClass: "label" }, [_vm._v("交货方式")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.DELIVERY_MAP[_vm.data.deliveryType])),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "table" },
            [
              _vm._m(5),
              _vm._l(_vm.list, function (item) {
                return _c(
                  "div",
                  { key: item.index, staticClass: "table-row" },
                  [
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(_vm._s(item.index)),
                    ]),
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(_vm._s(item.productInfo.name)),
                    ]),
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(_vm._s(item.productInfo.specification)),
                    ]),
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(_vm._s(item.productInfo.unit)),
                    ]),
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(_vm._s(item.quantity)),
                    ]),
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(_vm._s(item.unitPriceWithTax)),
                    ]),
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(_vm._s(item.totalAmountWithTax)),
                    ]),
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(_vm._s(item.leadTime)),
                    ]),
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(_vm._s(item.remark)),
                    ]),
                  ]
                )
              }),
              _c("div", { staticClass: "table-row" }, [
                _vm._m(6),
                _c("div", { staticClass: "cell" }, [
                  _vm._v(_vm._s(_vm.data.totalAmountWithTax)),
                ]),
                _c("div", { staticClass: "cell" }),
                _c("div", { staticClass: "cell" }),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "注:如需订货，在双方签订正式合同后，请签字及盖章确认后回传，并缴纳总价50%预付款，交货时付清余款。（报价时效：180天）"
            ),
          ]),
          _vm._m(7),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "a-button",
          {
            directives: [
              {
                name: "print",
                rawName: "v-print",
                value: "craft-card-print",
                expression: "'craft-card-print'",
              },
            ],
            staticClass: "btn",
            attrs: { type: "primary", block: "" },
          },
          [_vm._v("打印")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item left" }, [
      _c("div", { staticClass: "label" }, [_vm._v("供应商")]),
      _c("div", { staticClass: "content" }, [_vm._v("重庆市DBR刀具有限公司")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item left" }, [
      _c("div", { staticClass: "label" }, [_vm._v("联系人")]),
      _c("div", { staticClass: "content" }, [_vm._v("XXXXX")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item left" }, [
      _c("div", { staticClass: "label" }, [_vm._v("地址")]),
      _c("div", { staticClass: "content" }, [_vm._v("XXXXX")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item left" }, [
      _c("div", { staticClass: "label" }, [_vm._v("电话")]),
      _c("div", { staticClass: "content" }, [_vm._v("023-XXXXXXXXX转0")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item left" }, [
      _c("div", { staticClass: "label" }, [_vm._v("传真")]),
      _c("div", { staticClass: "content" }, [_vm._v("023-XXXXX")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "cell" }, [_vm._v("序号")]),
      _c("div", { staticClass: "cell" }, [_vm._v("产品名称")]),
      _c("div", { staticClass: "cell" }, [_vm._v("产品规格")]),
      _c("div", { staticClass: "cell" }, [_vm._v("单位")]),
      _c("div", { staticClass: "cell" }, [_vm._v("数量")]),
      _c("div", { staticClass: "cell" }, [_vm._v("单价")]),
      _c("div", { staticClass: "cell" }, [_vm._v("金额")]),
      _c("div", { staticClass: "cell" }, [_vm._v("周期")]),
      _c("div", { staticClass: "cell" }, [_vm._v("备注")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell6" }, [_c("p", [_vm._v("合计")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("div", { staticClass: "desc-left" }, [_vm._v("供应商确认（盖章）")]),
      _c("div", { staticClass: "desc-right" }, [_vm._v("签字：")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }