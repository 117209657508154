<template>
  <div class="app-container">
    <a-row>
      <a-col :span="2">
        <el-button class="header-button" type="primary" icon="el-icon-circle-plus-outline" @click="handleAddResource">新增资源</el-button>
      </a-col>
    </a-row>
    <a-table
      row-key="id"
      bordered
      class="roles-table"
      :columns="resourceColumns"
      :data-source="resourceList"
      :loading="loading"
      :pagination="pagination"
      :scroll="{y: 590 }"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <span slot="resourceType" slot-scope="text">
        <a-tag v-if="text===0" color="volcano">根节点</a-tag>
        <a-tag v-if="text===1" color="geekblue">页面</a-tag>
        <a-tag v-if="text===2" color="volcano">API</a-tag>
        <a-tag v-if="text===3" color="geekblue">父模块</a-tag>
      </span>
      <div slot="operate" slot-scope="text, column" class="table-role">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleUpdateResource(column)">更新资源</a-menu-item>
            <a-menu-item @click="handleDeleteResource(column)">删除资源</a-menu-item>
          </a-menu>
          <a class="role-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <ResourceEdit
      v-if="editResourceModalVisible"
      :type="type"
      :resource-form="currentResource"
      @cancel="handleResourceModalCanceled"
      @confirm="handleResourceModalConfirmed"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { getResources, deleteResource } from '@/api/resource'
import { resourceColumns } from './dataLogic'
import ResourceEdit from '@/components/ResourceEdit'
export default {
  components: {
    ResourceEdit
  },
  data() {
    return {
      loading: false,
      resourceColumns,
      resourceList: [],
      editResourceModalVisible: false,
      selectedRowKeys: [],
      searchInput: null,
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      columnSearchParams: {},
      type: '',
      currentResource: {}
    }
  },
  mounted() {
    this.handleGetResource(this.initQueryParams)
  },
  methods: {
    handleAddResource() {
      this.type = 'add'
      this.currentResource = {}
      this.editResourceModalVisible = true
    },
    handleUpdateResource(column) {
      console.log(column)
      this.type = 'update'
      this.currentResource = deepClone(column)
      this.editResourceModalVisible = true
    },
    handleDeleteResource(column) {
      const code = column.code ? column.code : ''
      this.$confirm({
        content: '确认删除资源' + code + '?',
        onOk: () => {
          deleteResource(column.id).then(() => {
            this.$message.success('删除资源成功！')
            this.handlegGetResourceByParams()
          })
        }
      })
    },
    handleResourceModalCanceled() {
      this.editResourceModalVisible = false
    },
    handleResourceModalConfirmed() {
      this.editResourceModalVisible = false
      this.handleGetResource(this.currentParams)
    },
    handleGetResource(data) {
      this.loading = true
      getResources(data)
        .then(res => {
          this.resourceList = res.data.records
          this.pagination.total = res.data.total
          console.log(this.resourceList)
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    handlegGetResourceByParams() {
      const _QueryParams = deepClone(this.currentParams)
      const queryParams = Object.assign(_QueryParams, this.columnSearchParams)
      this.handleGetResource(queryParams)
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      console.log(filtersKeys)
      if (filtersKeys.includes('type')) {
        delete this.columnSearchParams['type']
        if (filters['type'].length > 0) {
          this.columnSearchParams['type'] = `in:${filters['type'].toString()}`
        } else {
          delete this.columnSearchParams['type']
        }
      }

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentParams.sort_by = null
      }

      this.pagination = pagination
      this.currentParams.pageNum = pagination.current
      this.currentParams.pageSize = pagination.pageSize
      this.handlegGetResourceByParams()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .role-btn {
    cursor: pointer;
    color: #409eff;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
}
</style>
