<template>
  <div>
    <a-modal
      v-drag-modal
      class="modal-container"
      :visible="true"
      :title="title+planType+&quot;计划&quot;"
      :mask-closable="false"
      width="700px"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button @click="handleReset">重置</a-button>
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
      </template>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div>
          <a-divider>
            <p class="divider-text">计划信息</p>
          </a-divider>

          <a-form-model-item ref="code" label="计划编号" prop="plan.code">
            <a-input v-if="isAdd" v-model.trim="form.plan.code" :max-length="20" placeholder="请输入计划编号" />
            <a-input v-else v-model.trim="form.plan.code" :max-length="20" :disabled="true" placeholder="请输入计划编号" />
          </a-form-model-item>
          <a-form-model-item v-if="false" label="是否分件计划" prop="plan.whetherParts">
            <a-radio-group v-model="form.plan.whetherParts">
              <a-radio :value="false">否</a-radio>
              <a-radio :value="true">是</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="isMake || isUpdate" label="当前库存数量">
            <a-tooltip>
              <template slot="title">点击进行库存匹配</template>
              <a-button type="link" @click="handleEditWareHouse">{{ currentTurnsIntoNum }}</a-button>
            </a-tooltip>
          </a-form-model-item>
          <a-form-model-item v-if="isMake || isUpdate" label="库存转入数量" prop="plan.inventoryTurnsIntoNum">
            <a-input v-model.number="form.plan.inventoryTurnsIntoNum" placeholder="请输入库存转入数量" />
          </a-form-model-item>
          <a-form-model-item v-if="isMake || isUpdate" has-feedback label="报废不需补投数量" prop="plan.abandonNotComplementNum">
            <a-input v-model.number="form.plan.abandonNotComplementNum" placeholder="请输入报废不需补投数量" />
          </a-form-model-item>
          <a-form-model-item has-feedback label="产品备投数量" prop="plan.prepareNum">
            <a-input v-model.number="form.plan.prepareNum" placeholder="请输入产品备投数量" />
          </a-form-model-item>
          <a-form-model-item v-if="isAdd" label="计划下达时间" prop="plan.commandTime">
            <a-date-picker v-model="form.plan.commandTime" show-time disabled type="date" placeholder="请选择下达时间" />
          </a-form-model-item>
          <a-form-model-item v-if="false" label="计划启动时间" prop="plan.startTime">
            <a-date-picker v-model="form.plan.startTime" show-time disabled type="date" placeholder="请选择启动时间" />
          </a-form-model-item>
          <a-form-model-item v-if="isMake" label="合同交货日期" prop="plan.deadline">
            <a-date-picker v-model="form.plan.deadline" show-time disabled type="date" placeholder="暂无合同交货日期" />
          </a-form-model-item>
          <a-form-model-item label="产品要求交货时间" prop="plan.deliverTime">
            <a-date-picker
              v-model="form.plan.deliverTime"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              type="date"
              placeholder="请选择交货时间"
              @change="deliverDateConfirmed"
            />
          </a-form-model-item>
          <a-form-model-item v-if="form.plan.deliverTime" label="当日累计产值金额">
            <span>{{ totalProductionPrice }}</span>
          </a-form-model-item>
          <a-form-model-item v-if="isMake" label="期望下料时间" prop="plan.expectMaterialTime">
            <a-date-picker
              v-model="form.plan.expectMaterialTime"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              type="date"
              placeholder="请选择期望下料时间"
            />
          </a-form-model-item>
          <a-form-model-item v-if="!isUpdate" label="任务紧急类型" prop="emgType">
            <a-select v-model="form.emgType">
              <a-select-option value="NML">普通</a-select-option>
              <a-select-option value="MFN">必完件</a-select-option>
              <a-select-option value="UGC">紧急件</a-select-option>
              <a-select-option value="IUG">插入紧急件</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="!isUpdate" label="客户需求">
            <span>{{ planForm.clientDemand }}</span>
          </a-form-model-item>
          <a-form-model-item v-if="isUpdate" label="客户需求" prop="plan.clientDemand">
            <a-input v-model.trim="form.plan.clientDemand" :max-length="1024" placeholder="请输入计划客户需求(不超过1024个字)" type="textarea" />
          </a-form-model-item>
          <a-form-model-item label="备注" prop="plan.comment">
            <a-input v-model.trim="form.plan.comment" :max-length="1024" placeholder="请输入计划备注(不超过1024个字)" type="textarea" />
          </a-form-model-item>
        </div>
        <div v-if="!isUpdate">
          <a-divider>
            <p class="divider-text">产品信息</p>
          </a-divider>
          <a-form-model-item label="产品名称" prop="product.productName">
            <a-input v-model.trim="form.product.productName" :max-length="200" placeholder="请输入产品名称" />
          </a-form-model-item>
          <a-form-model-item v-if="isAdd" label="产品类型" prop="product.type">
            <a-radio-group v-model="form.product.type">
              <a-radio value="L">L类型</a-radio>
              <a-radio value="D">D类型</a-radio>
              <a-radio value="G">G类型</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="产品图号" prop="product.drawingNum">
            <a-input v-model.trim="form.product.drawingNum" :max-length="200" placeholder="请输入产品图号" />
          </a-form-model-item>
          <a-form-model-item label="产品规格" prop="product.specification">
            <a-input v-model.trim="form.product.specification" :max-length="200" placeholder="请输入产品规格" />
          </a-form-model-item>
          <a-form-model-item has-feedback label="订单数量" prop="product.amount">
            <a-input v-model.number="form.product.amount" :disabled="isMake&&form.plan.whetherParts===0" placeholder="请输入订单数量" />
          </a-form-model-item>
          <a-form-model-item label="产品类型" prop="product.productCategory">
            <a-radio-group v-model="form.product.productCategory">
              <template v-if="planType === 'L'">
                <a-radio value="单体轴类">单体轴类</a-radio>
                <a-radio value="单体非轴类">单体非轴类</a-radio>
                <a-radio value="组合">组合</a-radio>
              </template>
              <template v-else>
                <a-radio value="单体">单体</a-radio>
                <a-radio value="组合">组合</a-radio>
              </template>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="planForm.whetherParts === 1" has-feedback label="单位" prop="product.unit">
            <a-input v-model="form.product.unit" placeholder="请输入产品单位" />
          </a-form-model-item>
          <a-form-model-item v-if="form.product.type==='D'" label="刀具材料类别" prop="product.materialCategories">
            <a-select v-model="form.product.materialCategories" show-search placeholder="请选择刀具材料类别" option-filter-prop="children">
              <a-select-option
                v-for="category in materialCategories"
                :key="category.parameterValue"
                :value="category.parameterCode"
              >{{ category.parameterValue }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
    <PlanWareHouseModal
      v-if="warehouseModalVisible"
      :source="warehouseData"
      :title="warehouseTitle"
      :productName="planForm.productName"
      :drawingNum="planForm.drawingNum"
      :specification="planForm.specification"
      @cancel="handleWareHouseCancel"
      @confirm="handleWareHouseConfirm"
    />
  </div>
</template>
<script>
import {
  updatePlan,
  addPartPlan,
  makePlan,
  getLatestPlanCode,
  getPlanInventoryNew,
  getTotalProductionPriceByDeliveryDateTime
} from '@/api/plan'
import { getAllParametersByParentCode } from '@/api/parameter'
import PlanWareHouseModal from '@/components/PlanWareHouseModal'
import Moment from 'moment'
export default {
  components: {
    PlanWareHouseModal
  },
  props: {
    modalTitle: String,
    planForm: Object
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      if (value !== 0 && !value) {
        return callback(new Error('请输入数量'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字'))
      } else if (value < 0 || value > 999999) {
        callback(new Error('请输入不超过999999的正整数'))
      } else {
        callback()
      }
    }
    const checkFirstLetter = (rule, value, callback) => {
      if (value === '' || value === null) {
        return callback(new Error('请输入计划编号'))
      }
      if (value.substring(0, 1) !== this.planType) {
        callback(new Error('计划编码类型错误'))
      } else if (this.isAdd && (value.length < 11 || value.length > 20)) {
        callback(new Error('分件计划编码长度错误'))
      } else if (value.substring(3, 4) !== '-') {
        callback(new Error('计划编码格式错误'))
      } else if (this.isAdd && value.substring(8, 9) !== '/') {
        callback(new Error('分件计划编码格式错误'))
      } else if (this.isMake && isNaN(value.substring(1, 3))) {
        callback(new Error('计划编码格式错误'))
      } else if (
        this.isAdd &&
        (isNaN(value.substring(1, 3)) ||
          isNaN(value.substring(4, 8)) ||
          isNaN(value.substring(9, 10)))
      ) {
        callback(new Error('分件计划编码格式错误'))
      } else {
        callback()
      }
    }
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5,
        offset: 2
      },
      wrapperCol: {
        span: 10,
        offset: 1
      },
      loading: false,
      planType: this.planForm.type,
      totalProductionPrice: '交货日期更新后显示',
      materialCategories: [],
      form: {
        plan: {
          id: this.planForm.id,
          code: this.planForm.code,
          whetherParts: this.planForm.whetherParts,
          commandTime: this.planForm.commandTime,
          deliverTime: this.planForm.deliverTime
            ? Moment(this.planForm.deliverTime)
            : null,
          expectMaterialTime: null, 
          deadline: this.planForm.deadline,
          clientDemand: this.planForm.clientDemand,
          inventoryTurnsIntoNum: this.planForm.inventoryTurnsIntoNum || 0,
          abandonNotComplementNum: this.planForm.abandonNotComplementNum,
          startTime: this.planForm.startTime,
          prepareNum: this.planForm.prepareNum,
          status: this.planForm.status,
          charger: this.planForm.charger,
          comment: this.planForm.comment
        },
        product: {
          orderId: this.planForm.orderId,
          productName: this.planForm.productName,
          type: this.planForm.type,
          amount: this.planForm.amount,
          drawingNum: this.planForm.drawingNum,
          specification: this.planForm.specification,
          materialCategories: this.planForm.materialCategories,
          unit: this.planForm.unit || '件'
        },
        craft: {},
        emgType: this.planForm.emgType || 'NML' // NML - 普通 MFN - 必完件 UGC - 紧急件 IUG - 插入紧急件
      },
      currentTurnsIntoNum: 0,
      rules: {
        'plan.code': [
          {
            required: true,
            validator: checkFirstLetter,
            trigger: 'blur'
          }
        ],
        'product.type': [
          {
            required: true,
            message: '请选择一种类型',
            trigger: 'change'
          }
        ],
        'plan.whetherParts': [
          {
            required: true,
            message: '请选择是否分件计划',
            trigger: 'change'
          }
        ],
        'plan.inventoryTurnsIntoNum': [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }
        ],
        'plan.abandonNotComplementNum': [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }
        ],
        'plan.prepareNum': [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }
        ],
        'plan.commandTime': [
          {
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        'plan.deliverTime': [
          {
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        'product.productName': [
          {
            required: true,
            message: '请输入产品名称',
            trigger: 'blur'
          }
        ],
        'product.drawingNum': [
          {
            required: true,
            message: '请输入产品图号',
            trigger: 'blur'
          }
        ],
        'product.specification': [
          {
            required: true,
            message: '请输入产品规格',
            trigger: 'blur'
          }
        ],
        'product.amount': [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }
        ],
        'product.productCategory': [
          {
            required: true,
            message: '请选择产品类别',
            trigger: 'blur'
          }
        ],
        'product.materialCategories': [
          {
            required: true,
            message: '请选择刀具材料类别',
            trigger: 'blur'
          }
        ],
        'product.unit': [
          {
            required: true,
            message: '请输入单位',
            trigger: 'blur'
          }
        ],
        emgType: [
          {
            required: true,
            message: '请选择任务紧急类型',
            trigger: 'blur'
          }
        ]
      },
      warehouseData: [],
      warehouseModalVisible: false,
      warehouseTitle: ''
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function() {
      return this.modalTitle === '新增分件'
    },
    isMake: function() {
      return this.modalTitle === '制定'
    },
    isUpdate: function() {
      return this.modalTitle === '更新'
    },
    planCodeAutoInput: function() {
      return this.form.plan.code !== ''
    }
  },
  mounted() {
    this.getPlanInventory()
    this.handleGetProductMaterialCategory()
    console.log(this.planForm)
  },
  methods: {
    getNewestPlanCode() {
      getLatestPlanCode(this.planType).then(res => {
        if (res.data.planCode) {
          this.form.plan.code = res.data.planCode
        }
      })
    },
    getPlanInventory() {
      if (
        this.planForm.productName &&
        this.planForm.drawingNum &&
        this.planForm.specification
      ) {
        getPlanInventoryNew({
          productName: this.planForm.productName,
          drawingNum: this.planForm.drawingNum,
          specification: this.planForm.specification
        }).then(res => {
          console.log(res.data)
          const len = res.data.length
          this.warehouseData = res.data
          if (len) {
            this.warehouseTitle = `${this.planForm.productName}|${this.planForm.drawingNum}|${this.planForm.specification} 库存匹配`
            this.warehouseModalVisible = true
          } else {
            this.form.plan.inventoryTurnsIntoNum = 0
            this.currentTurnsIntoNum = 0
          }
        })
      } else {
        this.currentTurnsIntoNum = 0
        this.form.plan.inventoryTurnsIntoNum = 0
      }
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.form.plan.deliverTime) {
            this.form.plan.deliverTime = this.form.plan.deliverTime
              .local()
              .format('YYYY-MM-DD HH:mm:ss')
          }
          if (this.form.plan.expectMaterialTime) {
            this.form.plan.expectMaterialTime = this.form.plan.expectMaterialTime
              .local()
              .format('YYYY-MM-DD HH:mm:ss')
          }
          if (this.isAdd) {
            this.addPartPlan(this.form)
          } else if (this.isUpdate) {
            this.updatePlan(this.form.plan)
          } else {
            this.makePlan(this.form)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updatePlan(planFormData) {
      this.loading = true
      updatePlan(planFormData.id, planFormData)
        .then(res => {
          if (res.data.id) {
            const successMessage = '更新计划成功！'
            this.$emit('confirm')
            this.$message.success(successMessage)
          } else {
            const failMessage = '更新计划失败，请重试！'
            this.$message.error(failMessage)
          }
        })
        .catch(
          // 记录失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    handleGetProductMaterialCategory() {
      getAllParametersByParentCode('RT_DJMC').then(res => {
        if (res.data) {
          this.materialCategories = res.data
        }
      })
    },
    addPartPlan(planFormData) {
      this.loading = true
      planFormData.plan.parentPlanId = this.planForm.id
      planFormData.plan.id = null
      planFormData.plan.status = 2
      addPartPlan(planFormData)
        .then(res => {
          if (res.data.id) {
            this.$emit('confirm')
            this.$message.success('新增计划成功！')
          } else {
            this.$message.error('新增计划失败，请重试！')
          }
        })
        .catch(
          // 记录失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    makePlan(planFormData) {
      this.loading = true
      planFormData.plan.status = 2
      makePlan(planFormData.plan.id, planFormData)
        .then(res => {
          if (res.data) {
            this.$emit('confirm')
            this.$message.success('制定计划成功！')
          } else {
            this.$message.error('制定计划失败，请重试！')
          }
        })
        .catch(
          // 记录失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    deliverDateConfirmed() {
      const deliveryDateTime = this.form.plan.deliverTime
        .local()
        .format('YYYY-MM-DD HH:mm:ss')
      getTotalProductionPriceByDeliveryDateTime({ deliveryDateTime }).then(
        res => {
          this.totalProductionPrice = res.data
        }
      )
    },
    handleEditWareHouse() {
      if (!this.warehouseData || this.warehouseData.length === 0) {
        this.$message.error('暂无库存匹配')
        return
      }
      this.warehouseTitle = `${this.planForm.productName}${this.planForm.drawingNum}${this.planForm.specification} 库存匹配`
      this.warehouseModalVisible = true
    },
    handleWareHouseConfirm(num) {
      this.form.plan.inventoryTurnsIntoNum = num
      this.currentTurnsIntoNum = num
      this.warehouseModalVisible = false
    },
    handleWareHouseCancel() {
      this.warehouseModalVisible = false
    }
  }
}
</script>

<style scoped>
.searchFilterIcon {
  position: absolute;
  top: 10px;
  right: 0;
  padding-right: 5px;
  padding-left: 15px;
  padding-bottom: 20px;
}

.searchFilterIcon ~ .el-table__column-filter-trigger {
  display: none;
}

.searchButton {
  width: 90px;
  margin-right: 8px;
}

.resetButton {
  width: 90px;
}

.searchInput {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}

.divider-text {
  color: grey;
  font-size: 13px;
}

.modal-container >>> .ant-modal-body {
  padding-top: 0px;
}

.modal-container >>> .ant-form-item {
  margin-bottom: 10px;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: bold;
}
.modal-container >>> .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: bold;
}
</style>
