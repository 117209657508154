import { login, getInfoByAppId } from '@/api/account'
import {
  getAppIdByLocalStorage,
  getTokenByLocalStorage,
  setTokenToLocalStorage,
  removeTokenFromLocalStorage,
  setAppIdToLocalStorage,
  removeAppIdFromLocalStorage
} from '@/utils/auth'
import { resetRouter } from '@/router'
import { Message } from 'element-ui'
import CryptoJS from 'crypto-js'

/**
 * 加密密码函数 返回加密完成后的密文
 * @param password
 * @param tokenKey
 * @returns {string}
 */
function encryptPassword(password, tokenKey) {
  const parsePassword = CryptoJS.enc.Utf8.parse(password)
  const parseTokenKey = CryptoJS.enc.Utf8.parse(tokenKey)
  const encryptedPassword = CryptoJS.AES.encrypt(parsePassword, parseTokenKey, {
    iv: parseTokenKey,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
  return encryptedPassword
}

const state = {
  token: getTokenByLocalStorage(),
  appId: getAppIdByLocalStorage(),
  name: '',
  avatar: '',
  roles: [],
  accessRoute: [],
  userId: '',
  uid: '',
  tmp: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_APPID: (state, appId) => {
    state.appId = appId
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ACCESSROUTE: (state, route) => {
    state.accessRoute = route
  },
  SET_USER_ID: (state, id) => {
    state.userId = id
  },
  SET_UID: (state, uid) => {
    state.uid = uid
  },
  SET_TMP: (state, tmp) => {
    state.tmp = tmp
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, userKey, tokenKey } = userInfo
    const encryptedPassword = encryptPassword(password, tokenKey)
    const questBody = {
      appId: username,
      password: encryptedPassword,
      methodName: 'login',
      timestamp: new Date().toLocaleString(),
      userKey: userKey
    }
    return new Promise((resolve, reject) => {
      login(questBody)
        .then(response => {
          if (response.data.code === 2000) {
            commit('SET_TOKEN', response.data.data.jwtToken)
            commit('SET_APPID', username)
            setTokenToLocalStorage(response.data.data.jwtToken)
            setAppIdToLocalStorage(username)
            getInfoByAppId(username).then(res => {
              const { data } = res
              const { realName } = data
              Message({
                message: `欢迎您，${realName}！`,
                type: 'success'
              })
            })
            resolve()
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfoByAppId(state.appId)
        .then(response => {
          const { data } = response
          if (!data) {
            reject('Verification failed, please Login again.')
          }

          const { username, avatar, roles, pages, id, uid } = data
          if (roles.length === 0) {
            roles.push('')
          }
          commit('SET_NAME', username)
          commit('SET_AVATAR', avatar)
          commit('SET_ROLES', roles)
          commit('SET_ACCESSROUTE', pages)
          commit('SET_USER_ID', id)
          commit('SET_UID', uid)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // 退出登录
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_ACCESSROUTE', [])
      commit('SET_APPID', '')
      removeTokenFromLocalStorage()
      removeAppIdFromLocalStorage()
      resetRouter()
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_ACCESSROUTE', [])
      commit('SET_APPID', '')
      removeTokenFromLocalStorage()
      removeAppIdFromLocalStorage()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
