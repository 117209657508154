import request from '@/utils/api.request'

export function getResources(data) {
  return request({
    url: `/resources`,
    method: 'get',
    params: data
  })
}

export function addResource(data) {
  return request({
    url: '/resources',
    method: 'post',
    data
  })
}

export function updateResource(id, data) {
  return request({
    url: `/resources/${id}`,
    method: 'put',
    data
  })
}

export function deleteResource(id) {
  return request({
    url: `/resources/${id}`,
    method: 'delete'
  })
}

export function getResourceById(id) {
  return request({
    url: `/resources/${id}`,
    method: 'get'
  })
}

export function getResourcesByRoleId(id, data) {
  return request({
    url: `/roles/${id}/resources?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: 'get'
  })
}

export function getResourcesParentNode() {
  return request({
    url: `/resources/parent-node`,
    method: 'get'
  })
}
