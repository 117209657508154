<template>
  <a-modal class="modal-container" :visible="true" :title="title" :maskClosable="false" @cancel="handleCancel" width="600px">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="group" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="班组名称" prop="groupName">
        <a-input maxlength="20" v-model.trim="group.groupName" placeholder="请输入班组名称" />
      </a-form-model-item>
      <a-form-model-item label="班组长列表">
        <a-select
          v-model="group.leaderIds"
          mode="multiple"
          show-search
          placeholder="请选择班组长列表"
          option-filter-prop="children"
          :filter-option="filterUser"
          @change="handleUserChange"
        >
          <a-select-option v-for="user in currentUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="成员列表">
        <a-select
          v-model="group.memberIds"
          mode="multiple"
          show-search
          placeholder="请选择成员列表"
          option-filter-prop="children"
          :filter-option="filterUser"
        >
          <a-select-option v-for="user in currentUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="工序列表">
        <a-select v-model="group.operationIds" show-search mode="multiple" placeholder="请选择工序" option-filter-prop="children">
          <a-select-option
            v-for="operation in currentOperationList"
            :key="operation.id"
            :value="operation.id"
          >{{ operation.operationName }}({{ operation.operationCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { getAllUsers } from '@/api/user'
import { getAllOperations } from '@/api/operation'
import { updateGroup, addGroup } from '@/api/group'
import * as R from 'ramda'
export default {
  props: {
    modalTitle: String,
    groupForm: Object,
  },
  data() {
    return {
      title: this.modalTitle + '班组',
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      loading: false,
      currentUserList: [],
      currentOperationList: [],
      group: {
        id: this.groupForm.id || '',
        groupName: this.groupForm.groupName || '',
        leaderIds:
          this.groupForm.groupLeaders &&
          this.groupForm.groupLeaders.map((val) => val.id),
        memberIds:
          this.groupForm.groupMembers &&
          this.groupForm.groupMembers.map((val) => val.id),
        operationIds:
          this.groupForm.groupOperations &&
          this.groupForm.groupOperations.map((val) => val.id),
      },
      rules: {
        groupName: [
          {
            required: true,
            message: '请输入班组名称',
            trigger: 'blur',
          },
        ],
        leaderIds: [
          {
            required: true,
            message: '请输入员工编号',
            trigger: 'blur',
          },
        ],
        memberIds: [
          {
            required: true,
            message: '请输入员工名称',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function () {
      return this.modalTitle === '新增'
    },
    isUpdate: function () {
      return this.modalTitle === '更新'
    },
  },
  mounted() {
    this.getAllUsers()
    this.getAllOperations()
  },
  methods: {
    getAllUsers() {
      getAllUsers().then((res) => {
        if (res.data && res.data.accountList) {
          this.currentUserList = R.clone(res.data.accountList)
        }
      })
    },
    filterUser(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getAllOperations() {
      getAllOperations().then((res) => {
        if (res.data.operationList) {
          this.currentOperationList = R.clone(res.data.operationList)
        }
      })
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addGroup(this.group)
          } else if (this.isUpdate) {
            this.updateGroup(this.group)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updateGroup(userFormData) {
      this.loading = true
      updateGroup(userFormData.id, userFormData)
        .then((res) => {
          const successMessage = '更新班组成功！'
          this.$emit('confirm')
          this.$message.success(successMessage)
        })
        .catch(
          // 员工失败原因
          (reason) => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    addGroup(userFormData) {
      this.loading = true
      let submitData = R.clone(userFormData)
      submitData.id = null
      console.log(submitData)
      addGroup(submitData)
        .then((res) => {
          this.$message.success('新增班组成功')
          this.$emit('confirm')
        })
        .catch(
          // 员工失败原因
          (reason) => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    handleUserChange(user) {
      console.log(user)
    },
  },
}
</script>

<style scoped>
</style>