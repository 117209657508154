<template>
  <a-modal class="modal-container" :visible="true" :title="code + '报价成交'" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="全部成交" prop="template">
        <a-radio-group v-model="form.isAllDeal" style="width: 400px; fontWeight: 400;">
          <a-radio :value="true">是</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="合同签订日期" prop="dealOrderInfo.signTime">
        <a-date-picker
          style="width: 300px"
          v-model="form.dealOrderInfo.signTime"
          placeholder="请选择合同签订日期"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </a-form-model-item>
      <a-form-model-item label="合同交货日期" prop="dealOrderInfo.deadline">
        <a-date-picker
          style="width: 300px"
          v-model="form.dealOrderInfo.deadline"
          placeholder="请选择合同交货日期"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </a-form-model-item>
      <a-form-model-item label="客户订单编号" prop="clientOrderCode">
        <a-input style="width: 300px" v-model.trim="form.dealOrderInfo.clientOrderCode" placeholder="请输入客户订单编号" />
      </a-form-model-item>
      <a-form-model-item label="客户合同编号" prop="clientContractCode">
        <a-input style="width: 300px" v-model.trim="form.dealOrderInfo.clientContractCode" placeholder="请输入客户合同编号" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="comment">
        <a-textarea v-model="form.comment" :max-length="200" placeholder="请输入备注" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { orderQuotations } from '@/api/offer'

export default {
  props: {
    code: String | Number
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      form: {
        code: this.code,
        isAllDeal: true,
        dealOrderInfo: {
          signTime: '',
          deadline: '',
          clientOrderCode: '',
          clientContractCode: ''
        },
        comment: ''
      },
      rules: {
        'dealOrderInfo.signTime': [
          {
            required: true,
            message: '请选择合同签订日期',
            trigger: 'change'
          }
        ],
        'dealOrderInfo.deadline': [
          {
            required: true,
            message: '请选择合同交货日期',
            trigger: 'change'
          }
        ],
        isAllDeal: [
          {
            required: true,
            message: '请选择是否全部成交',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          orderQuotations([this.form])
            .then(res => {
              this.$message.success('报价成交')
              this.$emit('confirm')
            })
            .catch(err => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
