<template>
  <a-modal
    class="modal-container"
    visible="true"
    :title="userName+&quot;员工工种设置&quot;"
    :mask-closable="false"
    width="800px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button :loading="loading" @click="handleReset">重置</a-button>
      <a-button :loading="loading" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-transfer
      :data-source="operationsData"
      :list-style="{
        width: '355px',
        height: '500px',
      }"
      show-search
      :target-keys="targetKeys"
      :titles="['未拥有工种', '已拥有工种']"
      :render="item => `${item.title} ${item.description}`"
      @change="handleChange"
    >
      <span slot="notFoundContent">暂无数据</span>
    </a-transfer>
  </a-modal>
</template>
<script>
import { getAllOperations } from '@/api/operation'
import { updateOperationsByUserId } from '@/api/user'

export default {
  props: {
    modalTitle: String,
    userForm: Object
  },
  data() {
    return {
      loading: false,
      userName: this.userForm.realName,
      userId: this.userForm.id,
      operationsData: [],
      targetKeys: []
    }
  },
  mounted() {
    this.getOperationsData()
  },
  methods: {
    getOperationsData() {
      const targetKeys = []
      let operationsData = []
      let currentUserOperationsData = []
      getAllOperations({
        userId: 'eq:' + this.userId,
        sort_by: '+operationCode'
      })
        .then(res => {
          if (res.data) {
            currentUserOperationsData = res.data.operationList
            currentUserOperationsData.forEach(item => {
              targetKeys.push(item.id)
            })
          }
        })
        .then(() => {
          getAllOperations({
            sort_by: '+operationCode'
          }).then(res => {
            if (res.data) {
              const operationsRes = res.data.operationList
              operationsData = operationsRes.map(element => {
                return {
                  key: element.id,
                  title:
                    element.operationName +
                    (element.operationType === 1 ? '-自有' : '-外协'),
                  description: '(' + element.operationCode + ')'
                }
              })
            }
            this.operationsData = operationsData
            this.targetKeys = targetKeys
          })
        })
    },
    setUserOperationsByUserId() {
      this.loading = true
      updateOperationsByUserId(this.userId, this.targetKeys).then(res => {
        if (res.data && res.data.id) {
          const successMessage = '员工工种设置成功'
          const failMessage = '员工工种设置失败，请稍后重试'
          this.$emit('confirm')
          this.$message.success(successMessage)
        } else {
          this.$message.error(failMessage)
        }
      })
    },
    handleChange(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys
    },
    handleReset() {
      this.getOperationsData()
    },
    handleConfirm() {
      this.setUserOperationsByUserId()
    },
    handleCancel() {
      this.$emit('cancel')
    }
  }
}
</script>
<style scoped>
</style>
