import request from '@/utils/api.request'

/**
 * 获取产品工序平均停留时间
 * @param {object} data
 */
export function calculateProductOperationAvgStayTime(data) {
  return request({
    url: `/toc/products/stay-time/operations`,
    method: 'get',
    params: data,
  })
}

/**
 * 获取产品班组平均停留时间
 * @param {object} data
 */
export function calculateProductGroupAvgStayTime(data) {
  return request({
    url: `/toc/products/stay-time/groups`,
    method: 'get',
    params: data,
  })
}

/**
 * 获取工序平均停留时间走势
 * @param {object} data
 */
export function getProductAvgStayTimeTrends(data) {
  return request({
    url: `/toc/statistics/trends`,
    method: 'get',
    params: data,
  })
}

/**
 * 获取工厂核心运营趋势数据
 * @param {object} data
 */
export function getKpiTrends(data) {
  return request({
    url: `/toc/statistics/trends`,
    method: 'get',
    params: data,
  })
}

/**
 * 工厂任务进展分类占比
 * @param {object} data
 */
export function getTaskTrends(data) {
  return request({
    url: `/toc/statistics/trends`,
    method: 'get',
    params: data,
  })
}

/**
 * 获取产品班组平均停留时间
 * @param {object} data
 */
export function getProductStayTime(data) {
  return request({
    url: `/toc/products/stay-time/groups`,
    method: 'get',
    params: data,
  })
}

/**
 * 获取产品工序平均停留时间
 * @param {object} data
 */
export function getProductTechStayTime(data) {
  return request({
    url: `/toc/products/stay-time/operations`,
    method: 'get',
    params: data,
  })
}
