<template>
  <div class="card-container">
    <div id="craft-card-print">
      <template v-if="receipt <= 1">
        <div style="page-break-after:always">
          <a-descriptions bordered>
            <a-descriptions-item label="供货商地址：" :span="2">重庆市渝北区大竹林街道天山大道东段1号</a-descriptions-item>
            <a-descriptions-item label="供货商电话：" :span="2">023-XXXXXXXXX</a-descriptions-item>
            <a-descriptions-item label="收货地址：" :span="2">重庆市渝北区大竹林街道天山大道东段1号</a-descriptions-item>
            <a-descriptions-item label="订单编号：" :span="2">{{ data.clientOrderCode }}{{ productTakeOutItemList[0].clientWarehouseCode || '' }}</a-descriptions-item>
            <a-descriptions-item label="送货人：" :span="2" />
            <a-descriptions-item label="经办人：" :span="2" />
            <a-descriptions-item label="送货日期：" :span="2">{{ currentDate }}</a-descriptions-item>
            <a-descriptions-item label="联系电话：" :span="2">023-XXXXXXXXX-8000</a-descriptions-item>
          </a-descriptions>
          <a-table :columns="columns" :data-source="productTakeOutItemList" bordered :pagination="false">
            <p slot="amount" slot-scope="text" class="amount-font">{{ text }}</p>
          </a-table>
          <div class="footer-form">
            <span>渝江收货人及日期：</span>
            <div class="bottom-line" />
          </div>
          <a-table :columns="reportColumns" :data-source="checkSelfList" bordered :pagination="false"></a-table>
          <div class="footer-form">
            <span>检测人及日期：</span>
            <div class="bottom-line" />
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="item of data" :key="item.takeOutNumber" class="page" style="page-break-after:always">
          <a-descriptions bordered>
            <a-descriptions-item label="供货商地址：" :span="2">重庆市渝北区大竹林街道天山大道东段1号</a-descriptions-item>
            <a-descriptions-item label="供货商电话：" :span="2">023-XXXXXXXXX</a-descriptions-item>
            <a-descriptions-item label="收货地址：" :span="2">重庆市渝北区大竹林街道天山大道东段1号</a-descriptions-item>
            <a-descriptions-item label="订单编号：" :span="2">{{ item.clientOrderCode }}{{ item.productTakeOutItemList[0].clientWarehouseCode }}</a-descriptions-item>
            <a-descriptions-item label="送货人：" :span="2" />
            <a-descriptions-item label="经办人：" :span="2" />
            <a-descriptions-item label="送货日期：" :span="2">{{ currentDate }}</a-descriptions-item>
            <a-descriptions-item label="联系电话：" :span="2">023-XXXXXXXXX-8000</a-descriptions-item>
          </a-descriptions>
          <a-table :columns="columns" :data-source="item.productTakeOutItemList" bordered :pagination="false">
            <p slot="amount" slot-scope="text" class="amount-font">{{ text }}</p>
          </a-table>
          <div class="footer-form">
            <span>渝江收货人及日期：</span>
            <div class="bottom-line" />
          </div>
          <a-table :columns="reportColumns" :data-source="item.checkSelfList" bordered :pagination="false">
            <p slot="amount" slot-scope="text" class="amount-font">{{ text }}</p>
          </a-table>
          <div class="footer-form">
            <span>检测人及日期：</span>
            <div class="bottom-line" />
          </div>
        </div>
      </template>
    </div>
    <a-button v-print="'craft-card-print'" type="primary" block>打印</a-button>
  </div>
</template>
<script>
import {
  getOutInventorysCheck,
  getAllOutInventorysCheck,
} from '@/api/inventory'

const columns = [
  {
    title: '序号',
    dataIndex: 'sequenceNum',
    width: 50,
  },
  {
    title: '物料号',
    dataIndex: 'clientMaterialCode',
    width: 100,
  },
  {
    title: '计划编号',
    dataIndex: 'clientPlanCode',
    width: 120,
  },
  {
    title: '计划人',
    dataIndex: '',
  },
  {
    title: '图纸号',
    dataIndex: 'drawingNum',
  },
  {
    title: '名称',
    dataIndex: 'productName',
    width: 80,
    scopedSlots: {
      customRender: 'productName',
    },
  },
  {
    title: '说明规格',
    dataIndex: 'specification',
  },
  {
    title: '备注',
    dataIndex: 'taskCode',
  },
  {
    title: '数量',
    dataIndex: 'amount',
  },
  {
    title: '清点',
    dataIndex: '',
  },
  {
    title: '检测日期',
    dataIndex: '',
  },
  {
    title: '检测结果',
    dataIndex: '',
  },
  {
    title: '检测人',
    dataIndex: '',
  },
  {
    title: '入库/退返',
    dataIndex: '',
  },
]
const reportColumns = [
  {
    title: '序号',
    dataIndex: 'sequenceNum',
    width: 50,
    customRender: (value, row, index) => {
      const obj = {
        children: value,
        attrs: {},
      }
      if (index === 0 || index % 4 === 0) {
        obj.attrs.rowSpan = 4
      }
      if (index === 1 || (index - 1) % 4 === 0) {
        obj.attrs.rowSpan = 0
      }
      if (index === 2 || (index - 2) % 4 === 0) {
        obj.attrs.rowSpan = 0
      }
      if (index === 3 || (index - 3) % 4 === 0) {
        obj.attrs.rowSpan = 0
      }

      return obj
    },
  },
  {
    title: '检具',
    dataIndex: 'detail',
    width: 100,
    customRender: (value, row, index) => {
      const obj = {
        children: `${row.drawingNum || ''}\n ${row.specification || ''} \n ${
          row.productName || ''
        }`,
        attrs: {},
      }
      if (index === 0 || index % 4 === 0) {
        obj.attrs.rowSpan = 4
      }
      if (index === 1 || (index - 1) % 4 === 0) {
        obj.attrs.rowSpan = 0
      }
      if (index === 2 || (index - 2) % 4 === 0) {
        obj.attrs.rowSpan = 0
      }
      if (index === 3 || (index - 3) % 4 === 0) {
        obj.attrs.rowSpan = 0
      }

      return obj
    },
    scopedSlots: {
      customRender: 'detail',
    },
  },
  {
    title: '周期检定尺寸',
    dataIndex: '',
    width: 120,
  },
  {
    title: '实测',
    dataIndex: '',
  },
  {
    title: '检测工具',
    dataIndex: '',
  },
  {
    title: '其他尺寸',
    dataIndex: '',
    width: 80,
    scopedSlots: {
      customRender: '',
    },
  },
  {
    title: '实测',
    dataIndex: '',
  },
  {
    title: '检测工具',
    dataIndex: '',
  },
  {
    title: '其他尺寸',
    dataIndex: '',
  },
  {
    title: '实测',
    dataIndex: '',
  },
  {
    title: '检测工具',
    dataIndex: '',
  },
  {
    title: '检测结果',
    dataIndex: 'clientPlanCode',
    customRender: (value, row, index) => {
      const obj = {
        children: value,
        attrs: {},
      }
      if (index === 0 || index % 4 === 0) {
        obj.attrs.rowSpan = 4
      }
      if (index === 1 || (index - 1) % 4 === 0) {
        obj.attrs.rowSpan = 0
      }
      if (index === 2 || (index - 2) % 4 === 0) {
        obj.attrs.rowSpan = 0
      }
      if (index === 3 || (index - 3) % 4 === 0) {
        obj.attrs.rowSpan = 0
      }

      return obj
    },
  },
]
export default {
  data() {
    return {
      columns,
      reportColumns,
      data: [],
      productTakeOutItemList: [],
      checkSelfList: [],
      type: '',
      receipt: Number,
      currentDate: this.$moment(new Date()).format('YYYY-MM-DD'),
    }
  },
  created() {
    const params = this.$route.query
    this.receipt = params.receiptNumber.split(',').length
    if (this.receipt > 1) {
      params.receiptNumbers = params.receiptNumber
      delete params.receiptNumber
      getAllOutInventorysCheck(params).then((res) => {
        if (res.data) {
          this.data = res.data
          this.data.map((item) => {
            item.productTakeOutItemList.map((val, index) => {
              val.sequenceNum = index + 1
            })
            const len = item.productTakeOutItemList.length
            if (len < 7) {
              for (let i = 0; i < 7 - len; i++) {
                item.productTakeOutItemList.push({
                  sequenceNum: len + i + 1,
                })
              }
            }
            item.productTakeOutItemList.map((val) => {
              for (let i = 0; i < val.amount; i++) {
                this.checkSelfList.push({
                  sequenceNum: i + 1,
                  drawingNum: val.drawingNum,
                  specification: val.specification,
                  productName: val.productName,
                })
                item.checkSelfList.push({}, {}, {})
              }
            })
            const listLen = item.checkSelfList.length / 4
            if (listLen < 7) {
              for (let j = 0; j < 7 - listLen; j++) {
                item.checkSelfList.push(
                  {
                    sequenceNum: listLen + j + 1,
                  },
                  {},
                  {},
                  {}
                )
              }
            }
          })
        } else {
          this.$message.error('获取出库单数据失败，请重试！')
        }
      })
    } else {
      getOutInventorysCheck(params).then((res) => {
        if (res.data) {
          this.productTakeOutItemList = res.data.productTakeOutItemList
          this.productTakeOutItemList.forEach((item, index) => {
            item.sequenceNum = index + 1
          })
          const len = this.productTakeOutItemList.length
          if (len < 7) {
            for (let i = 0; i < 7 - len; i++) {
              this.productTakeOutItemList.push({
                sequenceNum: len + i + 1,
              })
            }
          }
          this.productTakeOutItemList.map((val) => {
            for (let i = 0; i < val.amount; i++) {
              this.checkSelfList.push({
                sequenceNum: this.checkSelfList.length / 4 + 1,
                drawingNum: val.drawingNum,
                specification: val.specification,
                productName: val.productName,
              })
              this.checkSelfList.push({}, {}, {})
            }
          })
          console.log(this.checkSelfList)
          const listLen = this.checkSelfList.length / 4
          console.log(listLen)
          // if (listLen < 7) {
          //   for (let j = 0; j < 7 - listLen; j++) {
          //     this.checkSelfList.push(
          //       {
          //         sequenceNum: listLen + j + 1,
          //       },
          //       {},
          //       {},
          //       {}
          //     )
          //   }
          // }
          this.data = res.data
          console.log(this.data)
        } else {
          this.$message.error('获取出库单数据失败，请重试！')
        }
      })
    }
  },
}
</script>
<style scoped lang="scss">
.card-container {
  width: 80%;
  margin: auto;
}

.page {
  margin-bottom: 100px;
}
.title {
  height: 50px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  line-height: 50px;
  margin-bottom: 8px;
  color: black;
}

.card-title >>> .ant-descriptions-item-label {
  font-size: 20px;
}

.amount-font {
  font-size: 17px;
  margin: 0;
}

/deep/ .ant-table {
  padding: 10px 0px;
  color: black;
}

/deep/ .ant-descriptions-item-label {
  color: black;
}

/deep/ .ant-descriptions-item-content {
  color: black;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
  text-align: center;
  border: 1px solid black;
  padding: 1px 8px;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
  text-align: center;
  border: 1px solid black;
  padding: 1px 8px;
}

/deep/ .ant-table-thead th {
  padding: 8px 8px;
  text-align: center;
  border: 1px solid black;
  color: black;
}

/deep/ .ant-table-tbody td {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  // padding: 2px 8px;
  text-align: center;
}

/deep/ .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid black;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-view {
  border: 0px;
}

/deep/ .ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border: 1px solid black;
}

/deep/ .ant-table-row {
  height: 20px;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 0;
}

/deep/ .ant-table-row-cell-break-word {
  word-break: unset;
}

.footer-form {
  float: left;
  margin-top: 15px;
  margin-bottom: 20px;
  color: black;
}

.bottom-line {
  width: 100px;
  height: 20px;
  margin-right: 10px;

  display: inline-block;
}
</style>
