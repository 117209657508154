var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("a-table", {
        key: _vm.tableKey,
        staticClass: "records-table",
        attrs: {
          "row-key": "id",
          columns: _vm.recordColumns,
          "data-source": _vm.records,
          loading: _vm.loading,
          "expanded-row-keys": _vm.expandedRowKeys,
          pagination: _vm.pagination,
          bordered: "",
          scroll: { x: 1800, y: 700 },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? _c("a-input", {
                        directives: [
                          {
                            name: "ant-ref",
                            rawName: "v-ant-ref",
                            value: function (c) {
                              return (_vm.searchInput = c)
                            },
                            expression: "c => (searchInput = c)",
                          },
                        ],
                        staticStyle: {
                          width: "188px",
                          "margin-bottom": "8px",
                          display: "block",
                        },
                        attrs: {
                          placeholder: "搜索 " + column.title,
                          value: selectedKeys[0],
                        },
                        on: {
                          change: function (e) {
                            return setSelectedKeys(
                              e.target.value ? [e.target.value] : []
                            )
                          },
                          pressEnter: function () {
                            return _vm.handleSearch(
                              selectedKeys,
                              confirm,
                              column.key
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "190px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "recordType",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === 1
                    ? _c("a-tag", { attrs: { color: "volcano" } }, [
                        _vm._v("加工记录"),
                      ])
                    : _vm._e(),
                  text === 2
                    ? _c("a-tag", { attrs: { color: "geekblue" } }, [
                        _vm._v("检验记录"),
                      ])
                    : _vm._e(),
                  text === 3
                    ? _c("a-tag", { attrs: { color: "cyan" } }, [
                        _vm._v("返工加工记录"),
                      ])
                    : _vm._e(),
                  text === 4
                    ? _c("a-tag", { attrs: { color: "pink" } }, [
                        _vm._v("返工检验记录"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "operation",
            fn: function (text, column) {
              return _c(
                "span",
                { staticClass: "table-operation" },
                [
                  _c(
                    "a-dropdown",
                    { attrs: { placement: "topRight" } },
                    [
                      _c(
                        "a-menu",
                        {
                          staticClass: "ant-dropdown-link",
                          attrs: { slot: "overlay" },
                          slot: "overlay",
                        },
                        [
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpdateRecord(column)
                                },
                              },
                            },
                            [_vm._v("更新记录")]
                          ),
                          _c(
                            "a-menu-item",
                            [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "确定删除该记录？",
                                    "ok-text": "确定",
                                    "cancel-text": "取消",
                                    placement: "bottom",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.handleDeleteRecord(column.id)
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", {
                                    staticStyle: { color: "red" },
                                    attrs: {
                                      slot: "icon",
                                      type: "question-circle-o",
                                    },
                                    slot: "icon",
                                  }),
                                  _vm._v("删除记录 "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        [
                          _vm._v(" 操作 "),
                          _c("a-icon", { attrs: { type: "down" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c(
        "el-dropdown",
        {
          staticClass: "export-record",
          on: { command: _vm.handleExportRecordCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出生产记录")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选记录"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.editModalVisible
        ? _c("RecordEdit", {
            attrs: {
              "modal-title": _vm.currentEditType,
              "record-form": _vm.currentRecord,
            },
            on: {
              cancel: _vm.handleRecordModalCanceled,
              confirm: _vm.handleRecordModalConfirmed,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }