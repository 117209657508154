var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.isAdd ? "新增询价单" : "更新询价单",
        maskClosable: false,
        width: "800px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          staticClass: "inquiry-form",
          attrs: {
            model: _vm.inquiry,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "客户名称", prop: "clientName" } },
            [
              _c("c-select", {
                attrs: {
                  placeholder: "请输入客户名称",
                  getList: _vm.getAllTenants,
                  searchKey: "name",
                  searchFormat: function (value) {
                    return { name: "like:" + value }
                  },
                  showLabel: function (item) {
                    return item.name + "(" + item.code + ")"
                  },
                  onChange: function (value, option) {
                    return _vm.onChange(value, option)
                  },
                },
                model: {
                  value: _vm.inquiry.clientName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.inquiry,
                      "clientName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inquiry.clientName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "客户询价单号", prop: "clientInquiryCode" } },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 50, placeholder: "请输入客户询价单号" },
                model: {
                  value: _vm.inquiry.clientInquiryCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.inquiry,
                      "clientInquiryCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inquiry.clientInquiryCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "询价时间", prop: "queryTime" } },
            [
              _c("a-date-picker", {
                attrs: {
                  "show-time": "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  placeholder: "请选择询价时间",
                },
                model: {
                  value: _vm.inquiry.queryTime,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.inquiry,
                      "queryTime",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inquiry.queryTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "截止时间", prop: "expirationTime" } },
            [
              _c("a-date-picker", {
                attrs: {
                  "show-time": "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  placeholder: "请选择截止时间",
                },
                model: {
                  value: _vm.inquiry.expirationTime,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.inquiry,
                      "expirationTime",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inquiry.expirationTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "特殊情况说明", prop: "remark" } },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入特殊情况说明" },
                model: {
                  value: _vm.inquiry.remark,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.inquiry,
                      "remark",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inquiry.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "comment" } },
            [
              _c("a-textarea", {
                staticStyle: { width: "300px" },
                attrs: { "max-length": 500, placeholder: "请输入备注" },
                model: {
                  value: _vm.inquiry.comment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.inquiry,
                      "comment",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inquiry.comment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }