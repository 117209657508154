<template>
    <a-modal class="modal-container" :visible="true" title="撤销确认" :maskClosable="false" @cancel="handleCancel"
        width="70vw">
        <template slot="footer">
            <a-button @click="handleReset">
                重置
            </a-button>
            <a-button @click="handleCancel">
                取消
            </a-button>
            <a-button type="primary" :loading="loading" @click="handleConfirm">
                确定
            </a-button>
        </template>
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="操作类型" prop="oprType">
                <a-radio-group :options="[{ label: '全部撤销', value: 1 }, { label: '部分撤销', value: 2 }]"
                    v-model="form.oprType" />
            </a-form-model-item>
            <a-form-model-item label="撤销原因" prop="changeReason">
                <a-textarea style="width: 460px" placeholder='请输入撤销原因' v-model.trim="form.changeReason"
                    :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
            <a-table v-if="form.oprType === 2" row-key="taskCode" :data-source="productPutInItemList" :columns="columns"
                :loading="dataLoading" :row-selection="{ selectedRowKeys, onChange: onSelectChange }" bordered
                :scroll="{ x: 1200 }" />
        </a-form-model>
    </a-modal>
</template>

<script>
import { getSaveInventorysCheck, revertInRecords } from '@/api/inventory';

const columns = [
    {
        title: '序号',
        dataIndex: 'sequenceNum',
        width: 70,
        fixed: 'left',
    },
    {
        title: '任务号',
        dataIndex: 'taskCode',
        width: 120,
        fixed: 'left',
    },
    {
        title: '名称',
        dataIndex: 'productName',
        width: 120,
        fixed: 'left',
    },
    {
        title: '图号',
        dataIndex: 'drawingNum',
    },
    {
        title: '单位',
        dataIndex: 'unit',
        width: 70
    },
    {
        title: '数量',
        dataIndex: 'amount',
        width: 80,
        scopedSlots: {
            customRender: 'amount'
        }
    },
    {
        title: '产值单价',
        dataIndex: 'productionUnitPrice',
        width: 100
    },
    {
        title: '产值金额',
        dataIndex: 'productionPrice',
        width: 100
    },
    {
        title: '客户名称',
        dataIndex: 'client',
        width: 200
    },
    {
        title: '报废数量',
        dataIndex: 'abandonCount',
        width: 100
    }
]

export default {
    props: {
        record: Object
    },
    data() {
        return {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 15,
                offset: 1
            },
            loading: false,
            form: {
                oprType: 2,
                changeReason: null,
            },
            rules: {
                changeReason: [
                    { required: true, message: '请输入', trigger: 'blur' },
                    { min: 1, max: 500, message: '请输入 500 字以内', trigger: 'blur' },
                ],
            },
            columns,
            dataLoading: false,
            curRecordData: {},
            productPutInItemList: [],
            selectedRowKeys: []
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoading = true
            getSaveInventorysCheck({
                receiptNumber: this.record.putInNumber
            }).then(res => {
                this.curRecordData = res.data
                this.productPutInItemList = (res?.data?.productPutInItemList || [])
                    .map((item, index) => ({
                        ...item,
                        sequenceNum: index + 1
                    }))
            }).catch(e => {
                console.error(e)
            }).finally(() => {
                this.dataLoading = false
            })
        },
        onSelectChange(keys) {
            this.selectedRowKeys = keys;
        },
        handleReset() {
            this.$refs.ruleForm.resetFields();
            this.selectedRowKeys = []
        },
        handleCancel() {
            this.$emit('cancel');
        },
        handleConfirm() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if (this.form.oprType === 2 && !this.selectedRowKeys.length) {
                        this.$message.error('请选择要部分撤销的数据')
                    } else {
                        this.returnSettlement();
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        returnSettlement() {
            this.loading = true;
            revertInRecords(
                this.record.putInNumber,
                {
                    changeReason: this.form.changeReason,
                    recordIds: this.form.oprType === 2 ? this.selectedRowKeys : []
                }).then(() => {
                    this.$message.success('撤销成功')
                    this.$emit('confirm');
                }).catch(
                    (reason) => {
                        this.loading = false;
                        console.log('处理失败的 promise (' + reason + ')');
                    }).finally(() => {
                        this.loading = false
                    });
        },
    }
}

</script>

<style scoped></style>