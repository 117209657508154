import axios from 'axios'
import { Message, Notification } from 'element-ui'
import store from '@/store'
import router from '../router'
import {
  getTokenByLocalStorage,
  setTokenToLocalStorage,
  getAppIdByLocalStorage,
  removeTokenFromLocalStorage,
  removeAppIdFromLocalStorage,
} from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['authorization'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['authorization'] = getTokenByLocalStorage()
    }
    if (store.getters.appId) {
      config.headers['appId'] = getAppIdByLocalStorage()
    }
    config.headers['Tenantid'] = '600001'
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // 处理token超时刷新处理
    // 获取到响应后 先判断是否是需要refreshToken
    if (
      response.status === 200 &&
      response.data &&
      response.data.code === 1000
    ) {
      // 需要刷新jwtToken 写入新的jwtToken
      setTokenToLocalStorage(response.data.data.jwtToken)
      // 再继续上一次的请求
      return service({
        url: response.config.url,
        method: response.config.method,
        data: response.config.data,
      })
    } else {
      // 不需要刷新直接返回response即可
      return response
    }
  },
  (error) => {
    console.log('err', error.response) // for debug
    // console.log(error.response)
    if (error.message.includes('timeout')) {
      // 判断请求异常信息中是否含有超时timeout字符串
      Notification({
        message: '请求超时，请刷新或者切换网络！',
        type: 'error',
        title: '网络连接超时',
      })
      return Promise.reject(error) // reject这个错误信息
    }
    if (error.response) {
      if (error.response.data.code === 4004) {
        // 无权限处理
        Notification({
          message: error.response.data.message,
          type: 'error',
          title: '未登录不可退出！',
        })
        return Promise.reject(error)
      } else if (error.response.data.code === 4008) {
        // 未登录不可退出
        Notification({
          message: error.response.data.message,
          type: 'error',
          title: '权限不足',
        })
        return Promise.reject(error)
      } else if (error.response.data.code === 4003) {
        // token完全过期处理
        Notification({
          message: error.response.data.message,
          type: 'warning',
          title: '登录状态已失效！',
        })
        // token完全失效 先清理本地存储的token 然后跳转login
        removeTokenFromLocalStorage()
        removeAppIdFromLocalStorage()
        router.push({ path: '/login' })
        return Promise.reject(error)
      } else if (error.response.data.code === 4005) {
        // 无效token处理
        Notification({
          message: error.response.data.message,
          type: 'error',
          title: '无效令牌',
        })
        // token完全失效 先清理本地存储的token 然后跳转login
        removeTokenFromLocalStorage()
        removeAppIdFromLocalStorage()
        router.push({ path: '/login' })
        return Promise.reject(error)
      } else if (error.response.data.code === 4006) {
        // token认证失败，未知错误
        Notification({
          message: error.response.data.message,
          type: 'error',
          title: '认证失败',
        })
        // token完全失效 先清理本地存储的token 然后跳转login
        removeTokenFromLocalStorage()
        removeAppIdFromLocalStorage()
        router.push({ path: '/login' })
        return Promise.reject(error)
      } else if (error.response.data.code === 4007) {
        // 请求参数未携带token或者appId
        Notification({
          message: error.response.data.message,
          type: 'error',
          title: '缺少token或appId参数',
        })
        // token完全失效 先清理本地存储的token 然后跳转login
        removeTokenFromLocalStorage()
        removeAppIdFromLocalStorage()
        router.push({ path: '/login' })
        return Promise.reject(error)
      } else {
        if (error.response.data) {
          if (error.response.data.message || error.response.data.apierror) {
            Notification({
              message: error.response.data.message
                ? error.response.data.message
                : error.response.data.apierror.message,
              type: 'error',
              title: '错误',
            })
          } else {
            var blobReader = new Response(error.response.data).json()
            blobReader.then((res) => {
              console.log(res)
              Notification({
                message: res.apierror.message,
                type: 'error',
                title: '错误',
              })
            })
          }
        } else {
          Notification({
            message: '发生未知错误',
            type: 'error',
            title: '错误',
          })
          // 统一处理api错误提示信息
        }
        return Promise.reject(error)
      }
    } else {
      // 统一处理api错误提示信息
      Notification({
        message: '请检查网络连接是否正常',
        type: 'error',
        title: '网络异常',
      })
    }
  }
)

export default service

/**
 * 文件下载
 * @param {*} response 响应
 * @param {*} fileType 文件类型
 */
export function downloadItem(response, fileType, fileName) {
  const blob = new Blob([response.data], { type: fileType })
  const link = document.createElement('a')
  link.setAttribute('download', fileName)
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function handleDownLoad(url, fileType, fileName) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: 'get',
      responseType: 'blob',
    }).then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.target = '_blank'
      link.click()
      URL.revokeObjectURL(link.href)
      resolve()
    })
  })
}
