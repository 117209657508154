<template>
  <div class="app-container">
    <a-row>
      <a-col :span="2">
        <c-upload
          name="file"
          :accept="&quot;.xlsx&quot;"
          :show-upload-list="false"
          :action="`${uploadUrl}inquiries/imports`"
          @change="handleFileChange"
        >
          <el-button slot="content" class="header-button" type="primary" :loading="uploading" icon="el-icon-circle-plus-outline">导入询价单</el-button>
        </c-upload>
      </a-col>
      <a-col :span="2">
        <el-button class="header-button" type="primary" icon="el-icon-circle-plus-outline" @click="handleAddInquirys">新增询价单</el-button>
      </a-col>
      <a-col :span="3" :offset="17">
        <el-button type="text" icon="el-icon-download" @click="handleDownloadTemplate">点击下载询价单导入模板</el-button>
      </a-col>
    </a-row>
    <a-table
      row-key="id"
      bordered
      class="users-table"
      :columns="inquiryColumns"
      :data-source="inquiryList"
      :loading="loading"
      :pagination="pagination"
      :scroll="{ x: 1500 }"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <div v-if="column.searchType===&quot;number&quot;">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px"
            :ranges="{
              今天: [$moment(), $moment()],
              本月: [$moment().startOf('month'), $moment().endOf('month')],
            }"
            @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>
      <div slot="code" slot-scope="text">
        <el-link type="primary" @click="handleCheckDetail(text)">{{text}}</el-link>
      </div>
      <div slot="status" slot-scope="text">
        <template v-if="text==='WCA'">
          <a-tag color="blue">待核价</a-tag>
        </template>
        <template v-if="text==='CAC'">
          <a-tag color="orange">核价中</a-tag>
        </template>
        <template v-if="text==='CNL'">
          <a-tag color="red">已取消</a-tag>
        </template>
        <template v-if="text==='CCK'">
          <a-tag color="yellow">审价中</a-tag>
        </template>
        <template v-if="text==='QUT'">
          <a-tag color="cyan">待报出</a-tag>
        </template>
        <template v-if="text==='PDL'">
          <a-tag color="lime">部分成交</a-tag>
        </template>
        <template v-if="text==='ADL'">
          <a-tag color="green">全部成交</a-tag>
        </template>
        <template v-if="text==='BGN'">
          <a-tag color="gold">已报出</a-tag>
        </template>
        <template v-if="text==='DNL'">
          <a-tag color="red">未成交</a-tag>
        </template>
      </div>
      <div slot="waitCostAccountingCount" slot-scope="text, column">
        <template v-if="text > 0">
          <a-badge :count="text">
            <a-button type="primary" :size="small" :loading="startAccountLoading" @click="handleStartCostAccount(column.code)">点击全部开始</a-button>
          </a-badge>
        </template>

        <template v-else>
          <a-tag color="green">已全部启动</a-tag>
        </template>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operate" slot-scope="text, column" class="table-user">
        <a-dropdown v-if="column.status === 'CAC' || column.status === 'WCA'">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item v-if="column.status === 'WCA' || column.status === 'CAC' " @click="handleEditInquiry(column)">更新询价单</a-menu-item>
            <a-menu-item v-if="column.status === 'CAC'" @click="handleCheckProduct(column.code)">提交审批</a-menu-item>
            <a-menu-item @click="handleCancelInquiry(column)">取消询价单</a-menu-item>
            <a-menu-item @click="handleDeleteInquiry(column)" v-if="column.status === 'WCA'">
              <span>删除询价单</span>
            </a-menu-item>
            <a-menu-item v-if="column.status === 'WCA' || column.status === 'CAC'" @click="handleAddInquirysProduct(column.code)">添加产品</a-menu-item>
            <a-menu-item v-if="column.waitCostAccountingCount > 0" @click="handleStartCostAccount(column.code)">开始核价</a-menu-item>
          </a-menu>
          <a class="user-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <el-dropdown class="export-record" @command="handleExportRecordCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出询价单记录</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="FILTERED">导出当前筛选记录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <CreateInquirysModal
      :record="editInquiry"
      :type="editInquiryType"
      v-if="inquirysModalVisible"
      @cancel="handleAddInquirysCancel"
      @confirm="handleAddInquirysConfirm"
    />
    <CreateInquiryProductModal
      :inquiryCode="inquiryCode"
      editRecord="{}"
      type="add"
      v-if="inquirysProductModalVisible"
      @cancel="handleAddInquirysProductCancel"
      @confirm="handleAddInquirysProductConfirm"
    />
    <StartCostAccountModal
      :code="inquiryCode"
      v-if="startCostAccountModalVisible"
      @cancel="handleAccountStartCancel"
      @confirm="handleAccountStartConfirm"
    />
    <ProductLibraryDetailModal
      v-if="productLibraryDetailModalVisible"
      :code="productDetailCode"
      @refresh="getInquirysByParams"
      @cancel="handleProductDetailCancel"
      @confirm="handleProductDetailConfirm"
    />
    <InquiryCancelModal
      v-if="cancelModalVisible"
      :recordsData="currentInquiry"
      @confirm="handleCancelInquiryConfirm"
      @cancel="handleCancelInquiryCancel"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { inquiryColumns } from './dataLogic'
import { exportAllUsers } from '@/api/user'
import {
  getInquiryList,
  checkProduct,
  exportInquiryList,
  deleteInquiryItem
} from '@/api/inquirylist'
import InquiryCancelModal from '@/components/InquiryCancelModal'
import CreateInquirysModal from '@/components/CreateInquirysModal'
import CreateInquiryProductModal from '@/components/CreateInquiryProductModal'
import StartCostAccountModal from '@/components/StartCostAccountModal'
import ProductLibraryDetailModal from '@/components/ProductLibraryDetailModal'

import { downloadTemplate } from '@/common/templateDownload'

export default {
  components: {
    CreateInquirysModal,
    CreateInquiryProductModal,
    StartCostAccountModal,
    ProductLibraryDetailModal,
    InquiryCancelModal
  },
  data() {
    return {
      loading: false,
      startAccountLoading: false,
      uploading: false,
      inquiryList: [],
      inquiryColumns,
      selectedRowKeys: [],
      searchInput: null,
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      userOperationModalVisible: false,
      columnSearchParams: {},
      uploadUrl: process.env.VUE_APP_BASE_API,
      exportLoading: false,
      inquirysModalVisible: false,
      inquirysProductModalVisible: false,
      inquiryCode: '',
      startCostAccountModalVisible: false,
      productLibraryDetailModalVisible: false,
      productDetailCode: '',
      cancelModalVisible: false,
      currentInquiry: {},
      editInquiryType: '',
      editInquiry: {}
    }
  },
  created() {
    this.getInquirys(this.initQueryParams)
  },
  methods: {
    getInquirys(data) {
      this.loading = true
      getInquiryList(data)
        .then(res => {
          this.inquiryList = res.data.records.map((item, index) => {
            item.index =
              (this.currentQueryParams.pageNum - 1) *
                this.currentQueryParams.pageSize +
              index +
              1
            return item
          })
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getInquirysByParams() {
      const cQueryParams = deepClone(this.currentQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getInquirys(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['inquiry.status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['inquiry.status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['inquiry.status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentQueryParams.pageNum = pagination.current
      this.currentQueryParams.pageSize = pagination.pageSize
      this.getInquirysByParams()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      let _dataIndex = dataIndex
      this.columnSearchParams[_dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[_dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[_dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      console.log(dataIndex, this.columnSearchParams)
      this.columnSearchParams[dataIndex] = null
    },
    handleDownloadTemplate() {
      downloadTemplate('inquiry-list')
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`批量导入失败，请稍后再试`)
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      this.$info({
        title: '询价单信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入数据总计：' + response.totalNum + '条;'),
          h('p', '非法数据条数：' + response.failedNum + ';'),
          h('p', '导入成功数据条数：' + response.successfulNum + ';'),
          h('p', '非法数据信息:'),
          h('p', response.invalidMessages.join(' ||  '))
        ]),
        onOk: () => {
          this.uploading = false
          this.getInquirys(this.currentQueryParams)
        }
      })
    },
    handleExportUserCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'ALL': {
          exportAllUsers()
            .then(res => {
              this.downloadInquiry(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        }
        default:
          this.exportLoading = false
          break
      }
    },

    downloadInquiry(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.USER_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleAddInquirys() {
      this.editInquiryType = 'add'
      this.inquirysModalVisible = true
    },
    handleAddInquirysConfirm() {
      this.editInquiryType = ''
      this.editInquiry = {}
      this.inquirysModalVisible = false
      this.getInquirysByParams()
    },
    handleAddInquirysCancel() {
      this.editInquiryType = ''
      this.editInquiry = {}
      this.inquirysModalVisible = false
    },
    handleAddInquirysProduct(code) {
      this.inquiryCode = code
      this.inquirysProductModalVisible = true
    },
    handleAddInquirysProductConfirm() {
      this.inquirysProductModalVisible = false
      this.getInquirysByParams()
    },
    handleAddInquirysProductCancel() {
      this.inquirysProductModalVisible = false
    },
    handleAccountStartConfirm() {
      this.startCostAccountModalVisible = false
      this.getInquirysByParams()
    },

    handleAccountStartCancel() {
      this.startCostAccountModalVisible = false
    },
    handleCheckProduct(code) {
      this.$confirm({
        title: '确认将询价单' + code + '提交审批？',
        onOk: () => {
          checkProduct([code]).then(res => {
            this.$message.success('提交审批成功')
            this.getInquirysByParams()
          })
        },
        onCancel() {}
      })
    },
    handleStartCostAccount(code) {
      this.inquiryCode = code
      this.startCostAccountModalVisible = true
    },

    handleCheckDetail(code) {
      this.productDetailCode = code
      this.productLibraryDetailModalVisible = true
    },

    handleProductDetailConfirm() {
      this.productLibraryDetailModalVisible = false
      this.getInquirysByParams()
    },
    handleProductDetailCancel() {
      this.productLibraryDetailModalVisible = false
    },
    handleExportRecordCommand(command) {
      const current = deepClone(this.currentQueryParams)
      const columnSearch = deepClone(this.columnSearchParams)
      const queryParams = Object.assign(current, columnSearch)
      switch (command) {
        case 'FILTERED':
          this.exportLoading = true
          exportInquiryList(queryParams)
            .then(res => {
              this.downloadRecord(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })

          break
        default:
          break
      }
    },
    downloadRecord(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.INQUIRY_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleCancelInquiry(record) {
      this.currentInquiry = record
      this.cancelModalVisible = true
    },
    handleCancelInquiryConfirm() {
      this.currentInquiry = {}
      this.cancelModalVisible = false
      this.getInquirysByParams()
    },
    handleCancelInquiryCancel() {
      this.currentInquiry = {}
      this.cancelModalVisible = false
    },
    handleDeleteInquiry(column) {
      this.$confirm({
        content: `确定删除询价单${column.code}吗`,
        onOk: () => {
          deleteInquiryItem(column.code).then(res => {
            this.$message.success('删除成功')
            this.getInquirysByParams()
          })
        },
        cancelText: '取消',
        onCancel() {
          self.destroyAll()
        }
      })
    },
    handleEditInquiry(column) {
      this.editInquiryType = 'edit'
      this.editInquiry = column
      this.inquirysModalVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
  .export-record {
    float: right;
    bottom: 46px;
    right: 0px;
  }
}
</style>
