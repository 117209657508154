var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c(
        "div",
        { attrs: { id: "craft-card-print" } },
        _vm._l(_vm.taskDatas, function (taskData) {
          return _c(
            "div",
            { key: taskData, staticStyle: { "page-break-after": "always" } },
            [
              _c(
                "a-descriptions",
                { staticClass: "card-title", attrs: { bordered: "" } },
                [
                  _c(
                    "a-descriptions-item",
                    {
                      attrs: { label: "工序流转卡（代工序检验记录）", span: 3 },
                    },
                    [
                      taskData.code === null
                        ? _c("span", [_vm._v("暂无编号")])
                        : _c("vue-qrcode", {
                            attrs: {
                              margin: "0",
                              scale: "2",
                              value: taskData.code,
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-descriptions",
                { attrs: { bordered: "" } },
                [
                  _c("a-descriptions-item", { attrs: { label: "客户" } }, [
                    _vm._v(_vm._s(taskData.client)),
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "合同交期" } }, [
                    _vm._v(_vm._s(taskData.deadline)),
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "生产日期" } }, [
                    _vm._v(_vm._s(taskData.startTime)),
                  ]),
                ],
                1
              ),
              _c(
                "a-descriptions",
                { attrs: { bordered: "", layout: "vertical", column: 5 } },
                [
                  _c("a-descriptions-item", { attrs: { label: "任务号" } }, [
                    _vm._v(_vm._s(taskData.code)),
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "产品名称" } }, [
                    _vm._v(_vm._s(taskData.productName)),
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "产品图号" } }, [
                    _vm._v(_vm._s(taskData.drawingNum)),
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "实发材料" } }),
                  _c("a-descriptions-item", { attrs: { label: "投产数量" } }, [
                    _vm._v(_vm._s(taskData.productionNum)),
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "计划填写" } }),
                  _c("a-descriptions-item", { attrs: { label: "操作者填写" } }),
                  _c("a-descriptions-item", {
                    attrs: { label: "检验员填写", span: 3 },
                  }),
                ],
                1
              ),
              _c("a-table", {
                attrs: {
                  columns: _vm.columns,
                  "data-source": _vm.data,
                  bordered: "",
                  pagination: false,
                },
              }),
              _vm._m(0, true),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "a-button",
        {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: "craft-card-print",
              expression: "'craft-card-print'",
            },
          ],
          attrs: { type: "primary", block: "" },
        },
        [_vm._v("打印")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-form" }, [
      _c("span", [_vm._v("计划：")]),
      _c("div", { staticClass: "bottom-line" }),
      _c("span", [_vm._v("车间主任：")]),
      _c("div", { staticClass: "bottom-line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }