<template>
  <div class="card-container">
    <div id="craft-card-print">
      <div style="page-break-after: always">
        <div class="title">售后服务处置通知单</div>
        <div class="version">JL-8.7-01</div>
        <p class="num">
          <span>
            客户名称：{{ data.qualityBaseDTO && data.qualityBaseDTO.client }}
          </span>
          <span>
            NO:{{ data.qualityBaseDTO && data.qualityBaseDTO.defeatReceiptNumber }}
          </span>
        </p>
        <div class="base-info" v-if="data.qualityBaseDTO ">
          <div class="row-2">
            <div class="item left">
              <div class="label">任务号</div>
              <div class="content">{{ data.qualityBaseDTO.taskCode }}</div>
            </div>
            <div class="item middle">
              <div class="label">交付数量</div>
              <div class="content">{{ data.qualityBaseDTO.takeOutNum }}</div>
            </div>
            <div class="item right">
              <div class="label">交付日期</div>
              <div class="content">
                {{ data.qualityBaseDTO.takeOutTime }}
              </div>
            </div>
          </div>
          <div class="row-2 row-bottom" v-if="data.qualityBaseDTO ">
            <div class="item left">
              <div class="label">产品名称</div>
              <div class="content">{{ data.qualityBaseDTO.productName }}</div>
            </div>
            <div class="item middle">
              <div class="label">退货数量</div>
              <div class="content">{{ data.qualityBaseDTO.productAmount }}</div>
            </div>
            <div class="item right">
              <div class="label">退货原因类别</div>
              <div class="content">
                {{ defeatReasonInfo }}
              </div>
            </div>
          </div>
          <div class="row-1 row-bottom" >
            <div class="item left">
              <div class="label">图号</div>
              <div class="content">{{ data.qualityBaseDTO.drawingNum }}</div>
            </div>
            <div class="item right">
              <div class="label">处置结论</div>
              <div class="content">
                {{ RepairEffectType[data.qualityBaseDTO.repairEffect] }}
              </div>
            </div>
          </div>
          <div class="row-column row-bottom">
            <div class="label">客户反馈描述</div>
            <div class="content">
              {{ data.qualityBaseDTO.contactRecord }}
            </div>
          </div>
          <div class="row-column row-bottom">
            <div class="label">核实情况描述</div>
            <div class="content">
              {{ data.qualityBaseDTO.defeatDesc }}
            </div>
          </div>
          <div class="hand-check row-bottom">
            <div class="label">不合格处<p>置</p></div>
            <div class="content content-row">
              <div class="content-row-1">
                <div class="label">返修要求</div>
                <div class="context">{{ data.qualityBaseDTO.defeatDemand }}</div>
              </div>
              <div class="content-row-1">
                <div class="label noborder">返修工序</div>
                <div class="context noborder">{{ repairOperationInfo }}</div>
              </div>
            </div>
        </div>
        </div>
        <div class="base-footer" v-if="data.qualityBaseDTO">
          <p class="left">技术:</p>
          <p class="middle">检验: {{ data.qualityBaseDTO.checkerName }}</p>
          <p class="right">日期：{{ data.qualityBaseDTO.generateTime }}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button v-print="'craft-card-print'" type="primary" class="btn" block
        >打印</a-button
      >
    </div>
  </div>
</template>

<script>

import {
	getQualitiesReceipt,
} from '@/api/qualities'

import { RepairEffectType } from "@/enums"

export default {
	data() {
		return {
      RepairEffectType,
			data: '',
      responsibleOperationInfo: '',
      responsibleUserInfo: '',
      defeatReasonInfo: '',
      repairOperationInfo: '',
      cardList: []
		}
	},
	created() {
		this.getData()
	},
	methods: {
		getData() {
      getQualitiesReceipt(this.$route.query.ids).then((res) => {
        if (res.data) {
            this.data = res.data
            if(this.data.qualityDetailDTO.responsibleOperationInfo &&this.data.qualityDetailDTO.responsibleOperationInfo.length ) {
              this.data.qualityDetailDTO.responsibleOperationInfo.map((item) => {
                if(this.responsibleOperationInfo) {
                  this.responsibleOperationInfo += ','
                }
                this.responsibleOperationInfo += item.operationName + ' '
              })
            }
            if(this.data.qualityDetailDTO.repairOperationInfo &&this.data.qualityDetailDTO.repairOperationInfo.length ) {
              this.data.qualityDetailDTO.repairOperationInfo.map((item) => {
                if(this.repairOperationInfo) {
                  this.repairOperationInfo += ','
                }
                this.repairOperationInfo += item.operationName + ' '
              })
            }
            if(this.data.qualityDetailDTO.responsibleUserInfo &&this.data.qualityDetailDTO.responsibleUserInfo.length ) {
              this.data.qualityDetailDTO.responsibleUserInfo.map((item) => {
                if(this.responsibleUserInfo) {
                  this.responsibleUserInfo += ','
                }
                this.responsibleUserInfo += item.realName + ' '
              })
            }
            if(this.data.qualityDetailDTO.defeatReasonInfo &&this.data.qualityDetailDTO.defeatReasonInfo.length ) {
              this.data.qualityDetailDTO.defeatReasonInfo.map((item) => {
                if(this.defeatReasonInfo) {
                  this.defeatReasonInfo += ','
                }
                this.defeatReasonInfo += item.parameterName + ' '
              })
            }
        } else {
          this.$message.error('获取数据失败')
        }
      })
		}
	},
}
</script>
<style scoped lang="scss">
.card-container {
  width: 80%;
  margin: auto;
  position: relative;
}

.page {
  margin-bottom: 100px;
}
.title {
  height: 50px;
  display: flex;
  justify-content: center;
  font-family: SimHei;
  font-size: 30px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 8px;
  color: black;
}

.card-title >>> .ant-descriptions-item-label {
  font-family: SimHei;
  font-size: 20px;
}

.amount-font {
  font-family: SimHei;
  font-size: 17px;
  margin: 0;
}

.ant-table-bordered .ant-table-tbody > tr > td {
  border: 1px solid black;
}

.footer {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.version {
  font-size: 30px;
  position: absolute;
  top: 0;
  left: 0;
  color: black;
}

.base-info {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  .row-1 {
    min-height: 40px;
    border: 2px solid #000;
    display: flex;
    word-break: break-all;
    .left {
      flex: 1;
      display: flex;
      .label {
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
      }
      .content {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
      }
    }
    .right {
      flex: 1.8;
      display: flex;
      .label {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
      }
      .content {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .row-2 {
    min-height: 40px;
    border: 2px solid #000;
    display: flex;
    word-break: break-all;
    .left {
      flex: 1.5;
      display: flex;
      .label {
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
      }
      .content {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
      }
    }
    .middle {
      flex: 1.4;
      display: flex;
      .label {
        width: 44%;
        text-align: center;
        line-height: 38px;
        border-right: 2px solid #000;
      }
      .content {
        width: 70%;
        text-align: center;
        line-height: 38px;
        border-right: 2px solid #000;
      }
    }
    .right {
      flex: 1.3;
      display: flex;
      .label {
        width: 40%;
        text-align: center;
        line-height: 38px;
        border-right: 2px solid #000;
      }
      .content {
        width: 70%;
        text-align: center;
        line-height: 38px;
      }
    }
  }
  .row-column {
    min-height: 40px;
    border: 2px solid #000;
    display: flex;
    .label {
      width: 19.7%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 2px solid #000;
    }
    .content {
      box-sizing: border-box;
      padding-left: 10px;
      text-align: center;
      line-height: 38px;
    }
  }
  .hand-check {
    width: 100%;
    min-height: 100px;
    border: 2px solid #000;
    display: flex;
    .label {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      border-right: 2px solid #000;
    }
    .content {
      flex: 8;
      box-sizing: border-box;
      /* padding-left: 10px; */
      position: relative;
      display: flex;
    }
    .bottom-check {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 10px;
      P {
					width: 300px;
			}
    }
    .content-row {
      display: flex;
      flex-direction: column;
      .content-row-1 {
        width: 100%;
        flex: 1;
        display: flex;
        .label {
          flex: 1;
          border-bottom: 2px solid #000;
        }
        .context {
          flex: 7;
          border-bottom: 2px solid #000;
        }
        .noborder {
          border-bottom: none;
        }
      }
    }
  }
  .row-bottom {
    border-top: none;
  }
}
.num {
  box-sizing: border-box;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  text-align: right;
  padding-right: 200px;
  display: flex;
  justify-content: space-between;
}
.base-footer {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 100px;
}
</style>
