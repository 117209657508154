import request from '@/utils/api.request'

// 对账单数据分页查询
export function getSettlements(data) {
  return request({
    url: '/settlements',
    method: 'get',
    params: data
  })
}

// 生成预对账单
export function createSettlements(data) {
  return request({
    url: '/settlements',
    method: 'post',
    data
  })
}


// 根据对账单id获取对账单明细信息
export function getSettlementById(settlementId, data) {
  return request({
    url: `/settlements/${settlementId}`,
    method: 'get',
    params: data
  })
}

// 根据对账单id获取对账单明细信息
export function getSettlementByIdAll(data) {
  return request({
    url: `/settlements/receipts`,
    method: 'get',
    params: data
  })
}



// 对账单结算状态更新
export function updateSettlementStatus(id, data) {
  return request({
    url: `/settlements/${id}`,
    method: 'patch',
    data
  })
}

// 对账单对应出库明细调入
export function inSettlement(id, data) {
  return request({
    url: `/settlements/out-detail/${id}`,
    method: 'post',
    data
  })
}

// 对账单对应出库明细调出
export function outSettlement(id, data) {
  return request({
    url: `/settlements/out-detail/${id}`,
    method: 'delete',
    data
  })
}


export function getOutInventorys(data) {
  return request({
    url: '/out-records',
    method: 'get',
    params: data
  })
}

export function saveSettlementReceipt(id, data) {
  return request({
    url: `/settlements/${id}/settle-receipt-numbers`,
    method: 'post',
    data
  })
}

// 对账单结算状态更新
export function returnSettlement(data) {
  return request({
    url: `/settlements/status`,
    method: 'patch',
    data
  })
}


// 待结算记录分批
export function batchSettlements(id, data) {
  return request({
    url: `/wait-settlements/${id}`,
    method: 'post',
    data
  })
}

// 对账单数据分页查询
export function getSettlementsLog(relatedId) {
  return request({
    url: `/biz-operation-logs/settlement/${relatedId}`,
    method: 'get',
  })
}


// 未关联对账单的待结算记录分页查询
export function getNoReceiptsSettlements(data) {
  return request({
    url: `/wait-settlements/no-receipts`,
    method: 'get',
    params: data
  })
}


/**
 * 导出所有对账单记录
 */
 export function exportAllSettlements(data) {
  return request({
    url: `/settlements/exports`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}

/**
 * 获取匹配模版
 */
 export function getSettleAccountImportTemplate() {
  return request({
    url: `/settlements/templates/settle-records`,
    method: 'get',
    responseType: 'blob',
  })
}


