var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title,
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.parameter,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "参数类型", prop: "parameterType" } },
            [
              _c(
                "a-radio-group",
                {
                  staticStyle: { width: "400px", fontWeight: "400" },
                  attrs: { disabled: _vm.isUpdate },
                  model: {
                    value: _vm.parameter.parameterType,
                    callback: function ($$v) {
                      _vm.$set(_vm.parameter, "parameterType", $$v)
                    },
                    expression: "parameter.parameterType",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "根参数" } }, [
                    _vm._v("根参数"),
                  ]),
                  _c("a-radio", { attrs: { value: "父参数" } }, [
                    _vm._v("父参数"),
                  ]),
                  _c("a-radio", { attrs: { value: "子参数" } }, [
                    _vm._v("子参数"),
                  ]),
                  _c("a-radio", { attrs: { value: "其他" } }, [_vm._v("其他")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.parameter.parameterType === "子参数" ||
          _vm.parameter.parameterType === "父参数"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "父参数", prop: "parentParameterId" } },
                [
                  _c(
                    "a-select",
                    {
                      model: {
                        value: _vm.parameter.parentParameterId,
                        callback: function ($$v) {
                          _vm.$set(_vm.parameter, "parentParameterId", $$v)
                        },
                        expression: "parameter.parentParameterId",
                      },
                    },
                    _vm._l(_vm.parentParameter, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.parameterName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "参数编码", prop: "parameterCode" } },
            [
              _c("a-input", {
                attrs: {
                  maxlength: "20",
                  disabled:
                    _vm.isUpdate && _vm.parameter.parameterType === "根参数",
                  placeholder: "请输入参数编码",
                },
                model: {
                  value: _vm.parameter.parameterCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.parameter,
                      "parameterCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "parameter.parameterCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "参数名称", prop: "parameterName" } },
            [
              _c("a-input", {
                attrs: { maxlength: "20", placeholder: "请输入参数名称" },
                model: {
                  value: _vm.parameter.parameterName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.parameter,
                      "parameterName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "parameter.parameterName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "参数值", prop: "parameterValue" } },
            [
              _c("a-input", {
                attrs: { maxlength: "20", placeholder: "请输入参数值" },
                model: {
                  value: _vm.parameter.parameterValue,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.parameter,
                      "parameterValue",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "parameter.parameterValue",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "参数描述", prop: "parameterDesc" } },
            [
              _c("a-input", {
                attrs: { maxlength: "20", placeholder: "请输入参数描述" },
                model: {
                  value: _vm.parameter.parameterDesc,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.parameter,
                      "parameterDesc",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "parameter.parameterDesc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }