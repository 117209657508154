<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" :title="title" width="900px" @cancel="handleCancel">
    <template slot="footer">
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <div class="modal-body">
      <a-empty v-if="!standardProcessRoute.length && !actualProcessRoute.length" />
      <template v-if="standardProcessRoute.length">
        <div class="wrap">
          <p class="title">标准加工路线</p>
          <a-steps size="small" direction="vertical">
            <a-step
              v-for="(process, index) in standardProcessRoute"
              :key="index"
              :title="'第'+(index + 1) +'步——'+process.operationName"
              :sub-title="process.verifyProcessHour&&process.verifyTransferOutTime?'  核定工时：'+process.verifyProcessHour+'小时 '+'     核定转出日期：'+process.verifyTransferOutTime:''"
              status="finish"
              :description="process.content"
            />
          </a-steps>
        </div>
      </template>
      <template v-if="actualProcessRoute.length">
        <div class="wrap">
          <p class="title">实际加工路线</p>
          <a-steps size="small" direction="vertical">
            <a-step
              v-for="(process, index) in actualProcessRoute"
              :key="index"
              :title="'第'+(index + 1) +'步——'+process.operationName+'('+process.realName+')'"
              status="finish"
              :description="process.content"
            >
              <span slot="subTitle">
                <template v-if="process.duration&&process.timeRate">
                  <span
                    v-if="process.duration > standardProcessRoute[index].verifyProcessHour"
                    style="color: red;"
                  >加工耗时{{ process.duration }}小时</span>
                  <span v-else>加工耗时{{ process.duration }}小时</span>
                  耗时占比{{ process.timeRate }}
                </template>
              </span>
            </a-step>
          </a-steps>
        </div>
      </template>
    </div>
  </a-modal>
</template>
<script>
//  :sub-title="process.duration&&process.timeRate ? '加工耗时：'+process.duration+'小时 '+' 耗时占比：'+process.timeRate:''"
import { getTargetProcessRoutes } from '@/api/target'
export default {
  props: {
    targetId: Number || String,
    taskId: Number,
    taskCode: String,
    loading: false,
  },
  data() {
    return {
      title: this.taskCode + '任务加工详情',
      standardProcessRoute: [],
      actualProcessRoute: [],
    }
  },
  mounted() {
    this.getTargetProcessRoutes()
  },
  methods: {
    getTargetProcessRoutes() {
      getTargetProcessRoutes(this.targetId, this.taskId).then((res) => {
        this.actualProcessRoute = res.data.actualProcessRoute
        this.standardProcessRoute = res.data.standardProcessRoute
        console.log(res)
      })
    },
    handleConfirm() {
      this.$emit('confirm')
    },
    handleCancel() {
      this.$emit('confirm')
    },
  },
}
</script>

<style scoped>
.modal-body {
  padding: 10px 50px;
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}
.wrap {
  width: 50%;
}
.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

/deep/ .ant-modal-body {
  padding: 5px;
}

/deep/ .ant-steps-item-content {
  width: 300px;
}
</style>
