var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$store.state.user.name) +
                      " - " +
                      _vm._s(_vm.role) +
                      " "
                  ),
                ]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v("工作台")])],
                    1
                  ),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.handleUpdatePassword },
                      },
                      [_vm._v("修改密码")]
                    ),
                  ]),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("退出登录")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.updatePasswordModalVisible
        ? _c("UpdatePasswordModal", {
            on: {
              cancel: _vm.handleUpdatePasswordModalCanceled,
              confirm: _vm.handleUpdatePasswordModalConfirmed,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }