import request from '@/utils/api.request'

export function getUsers(data) {
  return request({
    url: `/users`,
    method: 'get',
    params: data
  })
}

export function getAllUsers(data) {
  return request({
    url: `/users/all`,
    method: 'get',
    params: data
  })
}

export function getAllUsersWithDeleted(data) {
  return request({
    url: `/users/all-with-deleted`,
    method: 'get',
    params: data
  })
}

export function addUser(data) {
  return request({
    url: '/users',
    method: 'post',
    data
  })
}

export function updateUser(id, data) {
  return request({
    url: `/users/${id}`,
    method: 'put',
    data
  })
}

export function deleteUser(id) {
  return request({
    url: `/users/${id}`,
    method: 'delete'
  })
}

export function getUserById(id) {
  return request({
    url: `/users/${id}`,
    method: 'get'
  })
}

export function getRolesByUserId(id) {
  return request({
    url: `/users/${id}/roles`,
    method: 'get'
  })
}

export function updateRolesByUserId(id, data) {
  return request({
    url: `/users/${id}/roles`,
    method: 'put',
    data
  })
}

export function updateOperationsByUserId(id, data) {
  return request({
    url: `/users/${id}/operations`,
    method: 'put',
    data
  })
}

/**
 * 获取员工信息导入模板
 */
export function getUserImportTemplate() {
  return request({
    url: `/users/import-template`,
    method: 'get',
    responseType: 'blob'
  })
}

/**
 * 导出所有员工
 * @param {*} data 导出员工参数
 */
export function exportAllUsers(data) {
  return request({
    url: `/users/exports/all`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}
