var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      attrs: {
        bodyStyle: _vm.bodyStyle,
        visible: true,
        width: "1300px",
        title:
          _vm.code +
          " 产品" +
          _vm.record.inquiryItem.productInfo.name +
          "  " +
          "图号" +
          _vm.record.inquiryItem.productInfo.drawingNum +
          " " +
          "规格" +
          _vm.record.inquiryItem.productInfo.specification +
          "  " +
          "成本核算",
        "mask-closable": false,
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _vm.currentStep !== 4
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.stagingLoading },
                  on: { click: _vm.handleNext },
                },
                [_vm._v("暂存并下一步")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("提交核算")]
          ),
        ],
        1
      ),
      _c(
        "a-steps",
        {
          attrs: { type: "navigation" },
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c("a-step", { attrs: { status: "process", title: "原辅材料明细" } }),
          _c("a-step", { attrs: { status: "process", title: "外购明细" } }),
          _c("a-step", { attrs: { status: "process", title: "外协明细" } }),
          _c("a-step", { attrs: { status: "process", title: "生产加工明细" } }),
          _c("a-step", { attrs: { status: "process", title: "其他费用" } }),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          staticClass: "cost-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _vm.currentStep === 0
            ? [
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-bottom": "16px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.handldeAddRawMaterial },
                  },
                  [_vm._v("添加原辅材料")]
                ),
                _c("a-table", {
                  attrs: {
                    "row-key": "sequence",
                    bordered: "",
                    columns: _vm.rawMaterialColumns,
                    "data-source":
                      _vm.form.manufacturingCost.materialCost
                        .materialCostItemList,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "pricingModel",
                        fn: function (text) {
                          return _c("div", {}, [
                            text === "CBE"
                              ? _c("span", [_vm._v("方料")])
                              : _vm._e(),
                            text === "CLM"
                              ? _c("span", [_vm._v("棒料")])
                              : _vm._e(),
                          ])
                        },
                      },
                      {
                        key: "materialSpec",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            {},
                            [
                              column.pricingModel === "CBE"
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        column.consumeSpecification["length"]
                                      ) +
                                        "*" +
                                        _vm._s(
                                          column.consumeSpecification.width
                                        ) +
                                        "*" +
                                        _vm._s(
                                          column.consumeSpecification.height
                                        )
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      _vm._s(
                                        column.consumeSpecification.diameter
                                      ) +
                                        "*" +
                                        _vm._s(
                                          column.consumeSpecification.height
                                        )
                                    ),
                                  ],
                            ],
                            2
                          )
                        },
                      },
                      {
                        key: "operate",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            { staticClass: "table-user" },
                            [
                              _c(
                                "a-dropdown",
                                [
                                  _c(
                                    "a-menu",
                                    {
                                      staticClass: "ant-dropdown-link",
                                      attrs: { slot: "overlay" },
                                      slot: "overlay",
                                    },
                                    [
                                      _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUpdateRawMaterial(
                                                column.sequence,
                                                column
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("更新")]
                                      ),
                                      _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDeleteRawMaterial(
                                                column.sequence
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a",
                                    { staticClass: "user-btn" },
                                    [
                                      _vm._v(" 操作 "),
                                      _c("a-icon", { attrs: { type: "down" } }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    2208263214
                  ),
                }),
              ]
            : _vm._e(),
          _vm.currentStep === 1
            ? [
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-bottom": "16px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.handldeAddOutSourcing },
                  },
                  [_vm._v("添加外购明细")]
                ),
                _c("a-table", {
                  attrs: {
                    "row-key": "sequence",
                    bordered: "",
                    columns: _vm.outSourcingColumns,
                    "data-source":
                      _vm.form.manufacturingCost.purchaseCost
                        .outsourcingCostList,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "operate",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            { staticClass: "table-user" },
                            [
                              _c(
                                "a-dropdown",
                                [
                                  _c(
                                    "a-menu",
                                    {
                                      staticClass: "ant-dropdown-link",
                                      attrs: { slot: "overlay" },
                                      slot: "overlay",
                                    },
                                    [
                                      _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUpdateOutSourcing(
                                                column.sequence,
                                                column
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("更新")]
                                      ),
                                      _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDeleteOutSourcing(
                                                column.sequence
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a",
                                    { staticClass: "user-btn" },
                                    [
                                      _vm._v(" 操作 "),
                                      _c("a-icon", { attrs: { type: "down" } }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    3054338357
                  ),
                }),
              ]
            : _vm._e(),
          _vm.currentStep === 2
            ? [
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-bottom": "16px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.handldeAddOutProcessing },
                  },
                  [_vm._v("添加外协明细")]
                ),
                _c("a-table", {
                  attrs: {
                    "row-key": "sequence",
                    bordered: "",
                    columns: _vm.outProcessingColumns,
                    "data-source":
                      _vm.form.manufacturingCost.purchaseCost
                        .outProcessingCostList,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "operationInfoType",
                        fn: function (text) {
                          return _c("div", {}, [
                            text == 1
                              ? _c("span", [_vm._v("本部工序")])
                              : _vm._e(),
                            text == 2
                              ? _c("span", [_vm._v("委外工序")])
                              : _vm._e(),
                            text == 3
                              ? _c("span", [_vm._v("检验工序")])
                              : _vm._e(),
                          ])
                        },
                      },
                      {
                        key: "operate",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            { staticClass: "table-user" },
                            [
                              _c(
                                "a-dropdown",
                                [
                                  _c(
                                    "a-menu",
                                    {
                                      staticClass: "ant-dropdown-link",
                                      attrs: { slot: "overlay" },
                                      slot: "overlay",
                                    },
                                    [
                                      _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUpdateOutProcessing(
                                                column.sequence,
                                                column
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("更新")]
                                      ),
                                      _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDeleteOutProcessing(
                                                column.sequence
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a",
                                    { staticClass: "user-btn" },
                                    [
                                      _vm._v(" 操作 "),
                                      _c("a-icon", { attrs: { type: "down" } }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    153630298
                  ),
                }),
              ]
            : _vm._e(),
          _vm.currentStep === 3
            ? [
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-bottom": "16px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.handldeAddDirectPay },
                  },
                  [_vm._v("添加生产加工明细")]
                ),
                _c("a-table", {
                  attrs: {
                    "row-key": "sequence",
                    bordered: "",
                    columns: _vm.directPayColumns,
                    "data-source":
                      _vm.form.manufacturingCost.processingCost
                        .directPayCostList,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "operationInfoType",
                        fn: function (text) {
                          return _c("div", {}, [
                            text == 1
                              ? _c("span", [_vm._v("本部工序")])
                              : _vm._e(),
                            text == 2
                              ? _c("span", [_vm._v("委外工序")])
                              : _vm._e(),
                            text == 3
                              ? _c("span", [_vm._v("检验工序")])
                              : _vm._e(),
                          ])
                        },
                      },
                      {
                        key: "operate",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            { staticClass: "table-user" },
                            [
                              _c(
                                "a-dropdown",
                                [
                                  _c(
                                    "a-menu",
                                    {
                                      staticClass: "ant-dropdown-link",
                                      attrs: { slot: "overlay" },
                                      slot: "overlay",
                                    },
                                    [
                                      _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUpdateDirectPay(
                                                column.sequence,
                                                column
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("更新")]
                                      ),
                                      _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDeleteDirectPay(
                                                column.sequence
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a",
                                    { staticClass: "user-btn" },
                                    [
                                      _vm._v(" 操作 "),
                                      _c("a-icon", { attrs: { type: "down" } }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    2282321626
                  ),
                }),
              ]
            : _vm._e(),
          _vm.currentStep === 4
            ? [
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "生产制造费率",
                      labelCol: { span: 8 },
                      prop: "manufacturingCost.processingCost.manufacturingCostRate",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: {
                        maxLength: 20,
                        placeholder: "请输入生产制造费率",
                      },
                      model: {
                        value:
                          _vm.form.manufacturingCost.processingCost
                            .manufacturingCostRate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.manufacturingCost.processingCost,
                            "manufacturingCostRate",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression:
                          "form.manufacturingCost.processingCost.manufacturingCostRate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "工模具费用",
                      labelCol: { span: 8 },
                      prop: "manufacturingCost.processingCost.toolingCost",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: { maxLength: 20, placeholder: "请输入工模具费用" },
                      model: {
                        value:
                          _vm.form.manufacturingCost.processingCost.toolingCost,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.manufacturingCost.processingCost,
                            "toolingCost",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression:
                          "form.manufacturingCost.processingCost.toolingCost",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "余料价值",
                      labelCol: { span: 8 },
                      prop: "manufacturingCost.processingCost.surplusValue",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: { maxLength: 20, placeholder: "请输入余料价值" },
                      model: {
                        value:
                          _vm.form.manufacturingCost.processingCost
                            .surplusValue,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.manufacturingCost.processingCost,
                            "surplusValue",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression:
                          "form.manufacturingCost.processingCost.surplusValue",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "管理费率",
                      labelCol: { span: 8 },
                      prop: "periodCost.manageCostRate",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: { maxLength: 20, placeholder: "请输入管理费率" },
                      model: {
                        value: _vm.form.periodCost.manageCostRate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.periodCost,
                            "manageCostRate",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.periodCost.manageCostRate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "财务费率",
                      labelCol: { span: 8 },
                      prop: "periodCost.financialCostRate",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: { maxLength: 20, placeholder: "请输入财务费率" },
                      model: {
                        value: _vm.form.periodCost.financialCostRate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.periodCost,
                            "financialCostRate",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.periodCost.financialCostRate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "销售费率",
                      labelCol: { span: 8 },
                      prop: "periodCost.sellingCostRate",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: { maxLength: 20, placeholder: "请输入销售费率" },
                      model: {
                        value: _vm.form.periodCost.sellingCostRate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.periodCost,
                            "sellingCostRate",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.periodCost.sellingCostRate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "设计费率",
                      labelCol: { span: 8 },
                      prop: "periodCost.designCostRate",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: { maxLength: 20, placeholder: "请输入设计费率" },
                      model: {
                        value: _vm.form.periodCost.designCostRate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.periodCost,
                            "designCostRate",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.periodCost.designCostRate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "利润率",
                      labelCol: { span: 8 },
                      prop: "profitRate",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: { maxLength: 20, placeholder: "请输入利润率" },
                      model: {
                        value: _vm.form.profitRate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "profitRate",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.profitRate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "销售税率",
                      labelCol: { span: 8 },
                      prop: "taxRate",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: { maxLength: 20, placeholder: "请输入销售税率" },
                      model: {
                        value: _vm.form.taxRate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "taxRate",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.taxRate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "预计生产周期",
                      labelCol: { span: 8 },
                      prop: "leadTime",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: {
                        maxLength: 20,
                        placeholder: "请输入预计生产周期（工作日）",
                      },
                      model: {
                        value: _vm.form.leadTime,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "leadTime",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.leadTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "产品净重",
                      labelCol: { span: 8 },
                      prop: "netWeight",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: { maxLength: 20, placeholder: "请输入产品净重" },
                      model: {
                        value: _vm.form.netWeight,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "netWeight",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.netWeight",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "说明",
                      labelCol: { span: 8 },
                      prop: "remark",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px" },
                      attrs: { maxLength: 20, placeholder: "请输入说明" },
                      model: {
                        value: _vm.form.remark,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "remark",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.remark",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.rawMaterialModalVisible
        ? _c("RawMaterialModal", {
            attrs: {
              index: _vm.currentEditRawMateralIndex,
              record: _vm.currentEditRawMateral,
            },
            on: {
              add: _vm.handleRawMaterialAddConfirm,
              update: _vm.handleRawMaterialUpdateConfirm,
              cancel: _vm.handleRawMaterialCancel,
            },
          })
        : _vm._e(),
      _vm.outSourcingModalVisible
        ? _c("OutSourcingModal", {
            attrs: {
              record: _vm.currentEditOutSourcing,
              originRecord: _vm.record,
            },
            on: {
              add: _vm.handleOutSourcingAddConfirm,
              update: _vm.handleOutSourcingUpdateConfirm,
              cancel: _vm.handleOutSourcingCancel,
            },
          })
        : _vm._e(),
      _vm.outProcessingModalVisible
        ? _c("OutProcessingModal", {
            attrs: { record: _vm.currentEditOutProcessing },
            on: {
              add: _vm.handleOutProcessingAddConfirm,
              update: _vm.handleOutProcessingUpdateConfirm,
              cancel: _vm.handleOutProcessingCancel,
            },
          })
        : _vm._e(),
      _vm.directPayModalVisible
        ? _c("DirectPayCostModal", {
            attrs: { record: _vm.currentEditDirectPay },
            on: {
              add: _vm.handleDirectPayAddConfirm,
              update: _vm.handleDirectPayUpdateConfirm,
              cancel: _vm.handleDirectPayCancel,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }