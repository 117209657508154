import request from '@/utils/api.request'

export function getInventorys(data) {
  return request({
    url: '/warehouses',
    method: 'get',
    params: data
  })
}

export function getWaitSettlements(data) {
  return request({
    url: '/wait-settlements/no-receipts',
    method: 'get',
    params: data
  })
}

export function getSaveInventorys(data) {
  return request({
    url: '/in-records',
    method: 'get',
    params: data
  })
}

export function getOutInventorys(data) {
  return request({
    url: '/out-records',
    method: 'get',
    params: data
  })
}

export function getSettleAccountsInventorys(data) {
  return request({
    url: '/wait-settlements/all',
    method: 'get',
    params: data
  })
}

export function postBatchInventorys(data) {
  return request({
    url: `/out-records/${data.id}`,
    method: 'post',
    data: {
      splitCount: data.count
    }
  })
}
export function getSaveInventorysCheck(data) {
  return request({
    url: `/in-records/put-in-receipt`,
    method: 'get',
    params: data
  })
}

export function getOutInventorysCheck(data) {
  return request({
    url: `/out-records/take-out-receipt`,
    method: 'get',
    params: data
  })
}

export function getAllOutInventorysCheck(data) {
  return request({
    url: `/out-records/take-out-receipts`,
    method: 'get',
    params: data
  })
}

export function putSettleRecords(data) {
  return request({
    url: `/settle-records`,
    method: 'put',
    data
  })
}
// 出库单开单信息
export function putOutRecords(data) {
  return request({
    url: `/out-records`,
    method: 'put',
    data
  })
}

// 出库单是否唯一
export function checkOutRecords(data) {
  return request({
    url: `/out-records/order-count`,
    method: 'get',
    params: data
  })
}

// 入库单开单信息
export function putInRecords(data) {
  return request({
    url: `/in-records`,
    method: 'put',
    data
  })
}

// 获取结算总金额
export function getSettleAmount(data) {
  return request({
    url: `/settle-records/total-settle-price`,
    method: 'get',
    params: data
  })
}

/**
 * 入库记录导出
 * @param {*} data
 */
export function exportSaveInventory(data) {
  return request({
    url: `/in-records/exports`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}

/**
 * 出库记录导出
 * @param {*} data
 */
export function exportOutInventory(data) {
  return request({
    url: `/out-records/exports`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}

/**
 * 结算记录导出
 * @param {*} data
 */
export function exportSettleInventory(data) {
  return request({
    url: `/settle-records/exports`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}

// 入库单开单信息
export function updateRecordsComment(id, data) {
  return request({
    url: `/in-records/${id}`,
    method: 'patch',
    data
  })
}

// 入库单撤销
export function deleteInventorys(id, data) {
  return request({
    url: `/in-records/in-receipts/${id}`,
    method: 'delete',
    data
  })
}


// 出库单撤销
export function deleteOutInventorys(id, data) {
  return request({
    url: `/out-records/out-receipts/${id}`,
    method: 'delete',
    data
  })
}


// 出库单撤销
export function deleteInventorysTasks(data) {
  return request({
    url: `/in-records`,
    method: 'delete',
    data
  })
}


// 废品入库单查询
export function getAbandonReceipt(data) {
  return request({
    url: `/abandon-in-records/abandon-in-receipt`,
    method: 'get',
    params: data,
  })
}

// 开废品入库单
export function createAbandonReceipt(data) {
  return request({
    url: `/abandon-records`,
    method: 'put',
    data
  })
}


// 生成废品入库单
export function createAbandonRecord(data) {
  return request({
    url: `/abandon-records`,
    method: 'put',
    data
  })
}
// 获取废品入库单
export function getAbandonCard(data) {
  return request({
    url: `/abandon-in-records/abandon-in-receipt`,
    method: 'get',
    params: data
  })
}



/**
 * 出库明细记录导出
 * @param {*} data
 */
 export function exportSettleAccount(data) {
  return request({
    url: `/wait-settlements/exports`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}

/**
 * 库存管理记录导出
 * @param {*} data
 */
export function exportInventoryRecord(data) {
  return request({
    url: `/warehouses/exports`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}