<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" title="生产加工明细" :mask-closable="false" width="800px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" class="cost-form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="工序编码" :labelCol="{span: 8}" style="margin-left: 89px;" prop="operationInfo.code">
        <a-select
          style="width: 300px"
          :value="form.operationInfo.code"
          show-search
          :default-active-first-option="false"
          placeholder="请输入工序编码"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @search="fetchOperationByCode"
          @change="(value, option) => handleOperationsChange(value, option, index)"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="operation in operations" :key="operation.id">{{ operation.operationName }}({{ operation.operationCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="工序名称" :labelCol="{span: 8}" style="margin-left: 89px;" prop="operationInfo.name">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.operationInfo.name" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="工时单价" :labelCol="{span: 8}" style="margin-left: 89px;" prop="operationInfo.unitPrice">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.operationInfo.unitPrice" placeholder="请输入工时单价" />
      </a-form-model-item>
      <a-form-model-item label="工时" :labelCol="{span: 8}" style="margin-left: 89px;" prop="workTime">
        <a-input-number :min="0" :max="999999" style="width: 300px" :maxLength="20" v-model.trim="form.workTime" placeholder="请输入工时" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { getAllOperations, fuzzyOperations } from '@/api/operation'
import * as R from 'ramda'
import debounce from 'lodash/debounce'
export default {
  props: {
    record: Object
  },
  data() {
    this.fetchOperationByCode = debounce(this.fetchOperationByCode, 800)
    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      form: this.record || {
        sequence: '',
        operationInfo: {
          code: '',
          name: '',
          type: 1,
          unitPrice: ''
        },
        workTime: ''
      },
      operations: [],
      fetching: false,
      lastFetchId: 0,
      rules: {
        'operationInfo.code': [
          {
            required: true,
            message: '请输入工序编码',
            trigger: 'blur'
          }
        ],
        'operationInfo.name': [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        'operationInfo.type': [
          {
            required: true,
            message: '请选择类型',
            trigger: 'change'
          }
        ],
        'operationInfo.unitPrice': [
          {
            required: true,
            message: '请输入单位',
            trigger: 'blur'
          }
        ],
        workTime: [
          {
            required: true,
            message: '请输入工时',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    // 计算属性的 getter
    isUpdate: function() {
      return this.record
    },
    isAdd: function() {
      return !this.record
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.$emit('add', this.form)
          } else {
            this.$emit('update', this.form)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    fetchOperationByCode(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.operations = []
      this.fetching = true
      fuzzyOperations({
        searchText: value,
        type: 1
      })
        .then(result => {
          if (fetchId !== this.lastFetchId) {
            return
          }
          this.operations = result.data
          this.fetching = false
        })
        .finally(() => {
          this.fetching = false
        })
    },
    handleOperationsChange(id, option, index) {
      this.operations.map(val => {
        if (val.id === id) {
          this.form.operationInfo.code = val.operationCode
          this.form.operationInfo.name = val.operationName
          this.form.operationInfo.unitPrice = val.unitPrice
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
