var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: true,
        width: "1000px",
        title: "产品BOM",
        "mask-closable": false,
      },
      on: { cancel: _vm.handleCancel },
    },
    [_c("div", { ref: "container" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }