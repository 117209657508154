var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.title,
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.target,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "目标名称", prop: "targetName" } },
            [
              _c("a-input", {
                attrs: { "max-length": 20, placeholder: "请输入目标名称" },
                model: {
                  value: _vm.target.targetName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.target,
                      "targetName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "target.targetName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "目标周期", prop: "targetStartTime" } },
            [
              _c("a-range-picker", {
                attrs: {
                  format: "YYYY-MM-DD HH:mm:ss",
                  value: [_vm.target.targetStartTime, _vm.target.targetEndTime],
                },
                on: { change: _vm.onDateChange },
              }),
            ],
            1
          ),
          _vm.targetType === "month"
            ? [
                _c(
                  "a-form-model-item",
                  { attrs: { label: "插入急件占比", prop: "emergencyRate" } },
                  [
                    _c("a-input-number", {
                      attrs: { min: 0, max: 1, precision: 2 },
                      model: {
                        value: _vm.target.emergencyRate,
                        callback: function ($$v) {
                          _vm.$set(_vm.target, "emergencyRate", $$v)
                        },
                        expression: "target.emergencyRate",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }