<template>
  <div class="app-container">
    <a-table
      style="margin-top:16px;"
      row-key="id"
      bordered
      :scroll="{ x: 2000, y: 800 }"
      :columns="taskColumns"
      :data-source="taskList"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="emergencyType" slot-scope="text">
        <a-tag color="volcano">{{ EmergencyType[text] }}</a-tag>
      </span>
      <div slot="code" slot-scope="text, column">
        <template v-if="column.taskType">
          <a-badge
            count="计划外"
            :offset="[10, -10]"
            :number-style="{
              fontSize: '12px',
              backgroundColor: '#fff',
              color: 'red',
              boxShadow: '0 0 0 2px #d9d9d9 inset'
            }"
          >
            <span>{{ text }}</span>
          </a-badge>
        </template>
        <template v-else>{{ text }}</template>
      </div>
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-else-if="column.searchType === 'number'">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            :default-value="[moment(new Date()), moment(new Date())]"
            size="small"
            style="width: 330px; margin-bottom: 8px;"
            :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
            @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="status" slot-scope="text">
        <template v-if="text === 0">
          <a-badge status="default" />准备中
        </template>
        <template v-if="text === 1">
          <a-badge status="default" />未开始
        </template>
        <template v-if="text === 2">
          <a-badge status="processing" />进行中
        </template>
        <template v-if="text === 3">
          <a-badge status="success" />已完成
        </template>
        <template v-if="text === 9">
          <a-badge status="warning" />暂停中
        </template>
        <template v-if="text === 8">
          <a-badge status="error" />已取消
        </template>
        <template v-if="text === 10">
          <a-badge status="error" />异常终止
        </template>
      </div>
      <div slot="taskStatus" slot-scope="text">
        <template v-if="text === 'P'">
          <a-badge status="processing" />未完成
        </template>
        <template v-if="text === 'F'">
          <a-badge status="success" />已完成
        </template>
        <template v-if="text === 'X'">
          <a-badge status="default" />不涉及
        </template>
      </div>
      <div slot="taskType" slot-scope="text">
        <template v-if="text === 0">
          否
        </template>
        <template v-if="text === 1">
          是
        </template>
      </div>
      <div slot="process" slot-scope="text, column" class="table-process">
        <template v-if="column.remainProcessRoute && column.remainProcessRoute.length > 0">
          <a-steps :current="column.remainProcessRoute.length-1" status="finish" size="small">
            <a-step v-for="process in column.remainProcessRoute" :key="process.id" :title="process.operationName">
              <span slot="description" class="process-desc">
                <p v-if="process.operationType>0&&process.verifyProcessHour" class="description-font">
                  <b>加工工时：</b>
                  {{ process.verifyProcessHour }}
                </p>
                <p v-if="process.operationType>0&&process.verifyTransferOutTime" class="description-font">
                  <b>转出日期：</b>
                  {{ process.verifyTransferOutTime }}
                </p>
              </span>
            </a-step>
          </a-steps>
        </template>
      </div>
    </a-table>
    <el-dropdown class="export-plan" @command="handleExportPlanCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出目标</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="FILTERED">导出当前筛选目标</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { taskColumns } from './dataLogic'
import { EmergencyType } from '@/enums/index'
import { getUserDayTasks, exportUserFilteredTarget } from '@/api/target'
import { downloadItem } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import moment from 'moment'

export default {
  data() {
    return {
      EmergencyType,
      loading: false,
      uploading: false,
      taskList: [],
      taskColumns,
      selectedRowKeys: [],
      searchInput: null,
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      currentUserListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
      },
      columnSearchParams: {
        targetStartTime: `btn:${moment(new Date()).format(
          'YYYY-MM-DD'
        )},${moment(new Date()).format('YYYY-MM-DD')}`,
      },
      exportLoading: false,
    }
  },
  created() {
    this.getTasksByParams()
  },
  methods: {
    moment,
    getTasks(data) {
      this.loading = true
      getUserDayTasks(data)
        .then((res) => {
          this.taskList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getTasksByParams() {
      const cQueryParams = deepClone(this.currentUserListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getTasks(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('task.emgType')) {
        delete this.columnSearchParams['task.emgType']
        if (filters['task.emgType'].length > 0) {
          this.columnSearchParams['task.emgType'] = `in:${filters[
            'task.emgType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.emgType']
        }
      }
      if (filtersKeys.includes('taskType')) {
        delete this.columnSearchParams['taskType']
        if (filters['taskType'].length > 0) {
          this.columnSearchParams['taskType'] = `in:${filters[
            'taskType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['taskType']
        }
      }
      if (filtersKeys.includes('taskStatus')) {
        delete this.columnSearchParams['taskStatus']
        if (filters['taskStatus'].length > 0) {
          this.columnSearchParams['taskStatus'] = `in:${filters[
            'taskStatus'
          ].toString()}`
        } else {
          delete this.columnSearchParams['taskStatus']
        }
      }
      if (filtersKeys.includes('task.status')) {
        delete this.columnSearchParams['task.status']
        if (filters['task.status'].length > 0) {
          this.columnSearchParams['task.status'] = `in:${filters[
            'task.status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentUserListQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentUserListQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentUserListQueryParams.pageNum = pagination.current
      this.currentUserListQueryParams.pageSize = pagination.pageSize
      this.getTasksByParams()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      if (dataIndex === 'roles') {
        dataIndex = 'role.name'
      }
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleExportPlanCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'FILTERED':
          exportUserFilteredTarget({
            realName: this.$store.state.uid,
            ...this.columnSearchParams,
          })
            .then((res) => {
              this.exportLoading = false
              this.downloadPlan(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        default:
          this.exportLoading = false
          break
      }
    },
    downloadPlan(blobData) {
      const currentDate = +Date.now()
      downloadItem(blobData, 'application/vnd.ms-excel', '个人任务数据表')
    },
  },
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
  .table-process {
    float: left;
    width: auto;
  }

  .process-desc p {
    margin: 0;
    width: 250px;
  }

  .description-font {
    color: rgb(31, 29, 29);
  }
  /deep/ .ant-steps-item-content {
    width: 250px;
  }
}
</style>
