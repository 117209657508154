var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "tip-box" }, [
        _c(
          "div",
          { staticClass: "null-tip", staticStyle: { "font-size": "15px" } },
          [
            _vm._v(" 当前类型工艺剩余未编制数量： "),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(_vm.notMakeCount)),
            ]),
            _vm._v(" 项 "),
          ]
        ),
        _vm.hasSelected
          ? _c("div", { staticClass: "top-tip" }, [
              _c(
                "div",
                { staticClass: "selected-wrapper" },
                [
                  _vm.hasSelected
                    ? _c("span", [
                        _vm._v(" 已选中 "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.selectedRowKeys.length)),
                        ]),
                        _vm._v(" 项计划 "),
                      ])
                    : _vm._e(),
                  _vm.hasSelected
                    ? _c(
                        "a-dropdown",
                        [
                          _c(
                            "a",
                            {
                              staticClass: "batch-operate-link",
                              on: {
                                click: function (e) {
                                  return e.preventDefault()
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "edit" } }),
                              _vm._v("批量操作 "),
                            ],
                            1
                          ),
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              _c("a-menu-item", [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlePrintCraftCard(
                                          _vm.selectedRowKeys
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("批量打印卡片")]
                                ),
                              ]),
                              _vm.batchExportTemplate
                                ? _c("a-menu-item", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleBatchExportCraft(
                                              _vm.selectedRowKeys
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("批量导入工艺模板")]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.batchCopyPartsCraft
                                ? _c("a-menu-item", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleBatchCopyPartsCraft(
                                              _vm.selectedRowKeys
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("批量复制分件卡片")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "download" },
          [
            _c(
              "el-button",
              {
                staticClass: "downloadBtn",
                attrs: { type: "text", icon: "el-icon-download" },
                on: {
                  click: function ($event) {
                    return _vm.handleDownloadTemplate("parts")
                  },
                },
              },
              [_vm._v("点击下载分件工艺导入模板")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "text", icon: "el-icon-download" },
                on: {
                  click: function ($event) {
                    return _vm.handleDownloadTemplate("processRoute")
                  },
                },
              },
              [_vm._v("点击下载工艺路线导入模板")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "top-dowload-btn" }, [
        _c(
          "div",
          { staticClass: "refresh" },
          [
            _c(
              "a-tooltip",
              {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v("点击清空筛选条件")]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("a-button", { on: { click: _vm.handleResetAll } }, [
                  _vm._v("刷新"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "a-tabs",
        {
          attrs: { "active-key": _vm.currentActiveKey, type: "card" },
          on: { change: _vm.handleTabChange },
        },
        [
          _c("a-tab-pane", { key: "L", attrs: { tab: "L计划" } }),
          _c("a-tab-pane", { key: "D", attrs: { tab: "D计划" } }),
          _c("a-tab-pane", { key: "G", attrs: { tab: "G计划" } }),
        ],
        1
      ),
      _c(
        "a-table",
        {
          key: _vm.tableKey,
          attrs: {
            "row-key": "id",
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange,
              onSelect: _vm.onSelectRows,
              onSelectAll: _vm.onSelectAllChange,
              getCheckboxProps: _vm.craftRowSelection.getCheckboxProps,
            },
            bordered: "",
            scroll: { x: 4000 },
            columns: _vm.craftColumns,
            "data-source": _vm.craftList,
            pagination: _vm.pagination,
            loading: _vm.loading,
            "row-class-name": function (record) {
              return record.craftCode ? "expand" : "no-expand"
            },
            "expanded-row-keys": _vm.expandedRowKeys,
          },
          on: { change: _vm.handleTableChange, expand: _vm.handleRowExpand },
          scopedSlots: _vm._u([
            {
              key: "craftCode",
              fn: function (text, column) {
                return _c(
                  "div",
                  {},
                  [
                    text
                      ? [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleShowProcessRoute(column)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(text) + " ")]
                          ),
                        ]
                      : column.craftStatus === 8
                      ? [
                          _c("a-tag", { attrs: { color: "gray" } }, [
                            _vm._v("已取消"),
                          ]),
                        ]
                      : column.craftStatus === 9
                      ? [
                          _c("a-tag", { attrs: { color: "pink" } }, [
                            _vm._v("已暂停"),
                          ]),
                        ]
                      : [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddPartCraftCard(
                                    column,
                                    column.isParts,
                                    false
                                  )
                                },
                              },
                            },
                            [_vm._v("点击新增卡片")]
                          ),
                        ],
                  ],
                  2
                )
              },
            },
            {
              key: "fileName",
              fn: function (text, column) {
                return _c(
                  "div",
                  { staticClass: "file-wrapper" },
                  [
                    column.fileName
                      ? [
                          _c("div", { staticClass: "filename-wrapper" }, [
                            _c(
                              "span",
                              {
                                staticClass: "file-link",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDownloadFile(
                                      column.id,
                                      column.fileName
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(column.fileName))]
                            ),
                          ]),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "您确定要删除此工艺图文件？",
                                "ok-text": "确定",
                                "cancel-text": "取消",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.handleDeleteFile(column.id)
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                { staticClass: "filename-delete" },
                                [_c("a-icon", { attrs: { type: "delete" } })],
                                1
                              ),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "c-upload",
                            {
                              attrs: {
                                name: "file",
                                accept: ".pdf",
                                "show-upload-list": false,
                                action:
                                  _vm.baseURL + "crafts/" + column.id + "/file",
                              },
                              on: { change: _vm.handleCraftFileChange },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "file-link",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("a-icon", { attrs: { type: "upload" } }),
                                  _vm._v(" 上传工艺图文件 "),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                  ],
                  2
                )
              },
            },
            {
              key: "isParts",
              fn: function (text) {
                return _c(
                  "div",
                  {},
                  [
                    text === 0
                      ? [
                          _c("a-tag", { attrs: { color: "red" } }, [
                            _vm._v("否"),
                          ]),
                        ]
                      : _vm._e(),
                    text === 1
                      ? [
                          _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v("是"),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              },
            },
            {
              key: "processRoute",
              fn: function (text, column) {
                return _c(
                  "div",
                  { staticClass: "file-wrapper" },
                  [
                    column.whetherProcessRouteExists
                      ? [
                          _c(
                            "div",
                            { staticClass: "filename-wrapper" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleShowProcessRoute(column)
                                    },
                                  },
                                },
                                [_vm._v("查看工艺路线")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "您确定要删除此工艺路线？",
                                "ok-text": "确定",
                                "cancel-text": "取消",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.handleDeleteProcessRoute(column.id)
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                { staticClass: "filename-delete" },
                                [_c("a-icon", { attrs: { type: "delete" } })],
                                1
                              ),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "c-upload",
                            {
                              attrs: {
                                name: "file",
                                accept: ".xlsx",
                                multiple: true,
                                "show-upload-list": false,
                                action:
                                  _vm.baseURL +
                                  "crafts/" +
                                  column.id +
                                  "/process-route/imports",
                              },
                              on: { change: _vm.handleProcessFileChange },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "file-link",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("a-icon", { attrs: { type: "upload" } }),
                                  _vm._v(" 导入工艺路线 "),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                  ],
                  2
                )
              },
            },
            {
              key: "filterDropdown",
              fn: function (ref) {
                var setSelectedKeys = ref.setSelectedKeys
                var selectedKeys = ref.selectedKeys
                var confirm = ref.confirm
                var clearFilters = ref.clearFilters
                var column = ref.column
                return _c(
                  "div",
                  { staticStyle: { padding: "8px" } },
                  [
                    column.searchType === "text"
                      ? [
                          _c("a-input", {
                            directives: [
                              {
                                name: "ant-ref",
                                rawName: "v-ant-ref",
                                value: function (c) {
                                  return (_vm.searchInput = c)
                                },
                                expression: "(c) => (searchInput = c)",
                              },
                            ],
                            staticStyle: {
                              width: "188px",
                              "margin-bottom": "8px",
                              display: "block",
                            },
                            attrs: {
                              placeholder: "搜索 " + column.title,
                              value: selectedKeys[0],
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys(
                                  e.target.value ? [e.target.value] : []
                                )
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                    column.searchType === "text-filter"
                      ? [
                          _c("a-input", {
                            staticStyle: {
                              width: "125px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: { placeholder: "搜索 " + column.title },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([
                                  e.target.value,
                                  selectedKeys[1],
                                ])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key,
                                  column
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input", {
                            staticStyle: {
                              width: "125px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: { placeholder: "搜索 " + column.title },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([
                                  selectedKeys[0],
                                  e.target.value,
                                ])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key,
                                  column
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ]
                      : column.searchType === "number"
                      ? _c(
                          "div",
                          [
                            _c("a-input-number", {
                              staticStyle: {
                                width: "95px",
                                "margin-bottom": "8px",
                                display: "inline-block",
                              },
                              attrs: {
                                size: "small",
                                placeholder: "最小值",
                                min: 0,
                                max: 999999,
                              },
                              on: {
                                change: function (e) {
                                  return setSelectedKeys([e, selectedKeys[1]])
                                },
                                pressEnter: function () {
                                  return _vm.handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.key
                                  )
                                },
                              },
                              model: {
                                value: selectedKeys[0],
                                callback: function ($$v) {
                                  _vm.$set(selectedKeys, 0, $$v)
                                },
                                expression: "selectedKeys[0]",
                              },
                            }),
                            _c("span", [_vm._v("-")]),
                            _c("a-input-number", {
                              staticStyle: {
                                width: "95px",
                                "margin-bottom": "8px",
                                display: "inline-block",
                              },
                              attrs: {
                                size: "small",
                                placeholder: "最大值",
                                min: selectedKeys[0],
                                max: 999999,
                              },
                              on: {
                                change: function (e) {
                                  return setSelectedKeys([selectedKeys[0], e])
                                },
                                pressEnter: function () {
                                  return _vm.handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.key
                                  )
                                },
                              },
                              model: {
                                value: selectedKeys[1],
                                callback: function ($$v) {
                                  _vm.$set(selectedKeys, 1, $$v)
                                },
                                expression: "selectedKeys[1]",
                              },
                            }),
                          ],
                          1
                        )
                      : column.searchType === "date"
                      ? _c(
                          "div",
                          [
                            _c("a-range-picker", {
                              staticStyle: {
                                width: "190px",
                                "margin-bottom": "8px",
                              },
                              attrs: {
                                size: "small",
                                ranges: {
                                  今天: [_vm.$moment(), _vm.$moment()],
                                  本月: [
                                    _vm.$moment().startOf("month"),
                                    _vm.$moment().endOf("month"),
                                  ],
                                },
                              },
                              on: {
                                change: function (dates, dateStrings) {
                                  return setSelectedKeys([].concat(dateStrings))
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c(
                          "a-button",
                          {
                            staticStyle: {
                              width: "90px",
                              "margin-right": "8px",
                            },
                            attrs: {
                              type: "primary",
                              icon: "search",
                              size: "small",
                            },
                            on: {
                              click: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key,
                                  column
                                )
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                        _c(
                          "a-button",
                          {
                            staticStyle: { width: "90px" },
                            attrs: { size: "small" },
                            on: {
                              click: function () {
                                return _vm.handleReset(
                                  selectedKeys,
                                  clearFilters,
                                  column.key,
                                  column
                                )
                              },
                            },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              },
            },
            {
              key: "filterIcon",
              fn: function (filtered) {
                return _c("a-icon", {
                  style: { color: filtered ? "#108ee9" : undefined },
                  attrs: { type: "search" },
                })
              },
            },
            {
              key: "operation",
              fn: function (text, column) {
                return _c(
                  "div",
                  { staticClass: "operation" },
                  [
                    column.craftStatus !== 8 &&
                    column.craftStatus !== 9 &&
                    (column.craftCode || _vm.copyCardId || _vm.copyFatherCardId)
                      ? _c(
                          "a-dropdown",
                          [
                            _c(
                              "a",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function (e) {
                                    return e.preventDefault()
                                  },
                                },
                              },
                              [
                                _vm._v(" 操作 "),
                                _c("a-icon", { attrs: { type: "down" } }),
                              ],
                              1
                            ),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                column.craftCode
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleUpdateCraft(column)
                                          },
                                        },
                                      },
                                      [_c("span", [_vm._v("更新工艺")])]
                                    )
                                  : _vm._e(),
                                column.craftCode && column.isParts === 0
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAddPartCraftCard(
                                              column,
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("新增分件卡片")]
                                    )
                                  : _vm._e(),
                                column.craftCode
                                  ? _c(
                                      "a-menu-item",
                                      [
                                        _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              "ok-text": "删除工艺",
                                              "cancel-text": "取消",
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.handleDeleteCraft(
                                                  column
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("template", { slot: "title" }, [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    "确认删除工艺[" +
                                                      column.craftCode +
                                                      "]?"
                                                  )
                                                ),
                                              ]),
                                            ]),
                                            _c("a", { attrs: { href: "#" } }, [
                                              _vm._v("删除工艺"),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                column.craftCode && column.isParts === 0
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleCopyIntactCard(
                                              column
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("复制完整工艺")]
                                    )
                                  : _vm._e(),
                                !column.craftCode &&
                                column.isParts === 0 &&
                                _vm.copyFatherCardId
                                  ? _c(
                                      "a-menu-item",
                                      [
                                        _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              "ok-text": "粘贴",
                                              "cancel-text": "取消",
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.handlePasteIntactCard(
                                                  column
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("template", { slot: "title" }, [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    "该操作无法撤销，确认粘贴完整工艺?"
                                                  )
                                                ),
                                              ]),
                                            ]),
                                            _c("a", { attrs: { href: "#" } }, [
                                              _vm._v("粘贴完整工艺"),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.copyCardId &&
                                _vm.copyCardPartsType === 0 &&
                                !column.craftCode &&
                                column.isParts === 0
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlePasteCraftCard(
                                              column,
                                              0,
                                              0
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("粘贴为主件卡片")]
                                    )
                                  : _vm._e(),
                                _vm.copyCardId &&
                                _vm.copyCardPartsType === 1 &&
                                !column.craftCode &&
                                column.isParts === 1
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlePasteCraftCard(
                                              column,
                                              1,
                                              0
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("粘贴为分件卡片")]
                                    )
                                  : _vm._e(),
                                _vm.copyCardId &&
                                column.craftCode &&
                                column.isParts === 0
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlePasteCraftCard(
                                              column,
                                              1,
                                              1
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("插入粘贴分件卡片")]
                                    )
                                  : _vm._e(),
                                _vm.batchCopyIds.length &&
                                column.craftCode &&
                                column.isParts === 0
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleBatchPasteCraftCard(
                                              column
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("批量插入粘贴分件卡片")]
                                    )
                                  : _vm._e(),
                                column.craftCode && column.isParts === 0
                                  ? _c(
                                      "a-menu-item",
                                      [
                                        _c(
                                          "c-upload",
                                          {
                                            attrs: {
                                              name: "file",
                                              accept: ".xlsx",
                                              multiple: true,
                                              "show-upload-list": false,
                                              action:
                                                _vm.baseURL +
                                                "/crafts/" +
                                                column.id +
                                                "/parts/imports",
                                            },
                                            on: {
                                              change: _vm.handleFileChange,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [_vm._v("批量导入分件工艺")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                column.craftCode
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAddTaskCard(column)
                                          },
                                        },
                                      },
                                      [_c("span", [_vm._v("新增任务卡片")])]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              },
            },
            {
              key: "craftStatus",
              fn: function (text) {
                return _c(
                  "div",
                  {},
                  [
                    text === 2
                      ? [
                          _c("a-badge", { attrs: { status: "processing" } }),
                          _vm._v("进行中 "),
                        ]
                      : _vm._e(),
                    text === 3
                      ? [
                          _c("a-badge", { attrs: { status: "success" } }),
                          _vm._v("已完成 "),
                        ]
                      : _vm._e(),
                    text === 4
                      ? [
                          _c("a-badge", { attrs: { status: "error" } }),
                          _vm._v("超期进行中 "),
                        ]
                      : _vm._e(),
                    text === 5
                      ? [
                          _vm._v(" 即将超期 "),
                          _c("a-badge", { attrs: { status: "warning" } }),
                        ]
                      : _vm._e(),
                    text === 9
                      ? [
                          _c("a-badge", { attrs: { status: "warning" } }),
                          _vm._v("暂停中 "),
                        ]
                      : _vm._e(),
                    text === 8
                      ? [
                          _c("a-badge", { attrs: { status: "error" } }),
                          _vm._v("已取消 "),
                        ]
                      : _vm._e(),
                    text === 10
                      ? [
                          _c("a-badge", { attrs: { status: "error" } }),
                          _vm._v("异常终止 "),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              },
            },
          ]),
        },
        [
          _c("a-table", {
            key: _vm.tableKey,
            attrs: {
              slot: "expandedRowRender",
              "row-key": "id",
              size: "middle",
              loading: _vm.craftCardLoading,
              columns: _vm.craftCardColumns,
              "data-source": _vm.craftCardData,
              pagination: false,
              customRow: _vm.handleCustomRow,
            },
            slot: "expandedRowRender",
            scopedSlots: _vm._u([
              {
                key: "operationCardVersion",
                fn: function (text, column) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCheckCraftCard(column)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(text) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "processRoute",
                fn: function (text, column) {
                  return _c(
                    "div",
                    { staticClass: "file-wrapper" },
                    [
                      column.processRoute && column.processRoute.length
                        ? [
                            _c(
                              "div",
                              { staticClass: "filename-wrapper" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: { type: "link" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleShowSingleProcessRoute(
                                          column
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看工艺路线")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                },
              },
              {
                key: "status",
                fn: function (text, column) {
                  return _c(
                    "div",
                    {},
                    [
                      column.operationCardFooterDTO.craftApproveDTO.approverId
                        ? [_vm._v("审批完成")]
                        : column.operationCardFooterDTO.craftDesignDTO
                            .designerId
                        ? [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "link" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleApproveCard(column)
                                  },
                                },
                              },
                              [_vm._v("点击审批")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                },
              },
              {
                key: "operation",
                fn: function (text, column) {
                  return _c(
                    "div",
                    { staticClass: "operation" },
                    [
                      _c(
                        "a-dropdown",
                        [
                          _c(
                            "a",
                            {
                              staticClass: "operation-btn",
                              on: {
                                click: function (e) {
                                  return e.preventDefault()
                                },
                              },
                            },
                            [
                              _vm._v(" 操作 "),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              column.operationCardHeaderDTO.operationCardBaseDTO
                                .operationCardType === 0
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCopyAddCraftCard(
                                            column
                                          )
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("新增版本")])]
                                  )
                                : _vm._e(),
                              _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCreateTaskCraftCard(
                                        column
                                      )
                                    },
                                  },
                                },
                                [_vm._v("新增实际工艺版本")]
                              ),
                              !column.operationCardFooterDTO.craftApproveDTO
                                .approverId
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdateCraftCard(
                                            column
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("更新")]
                                  )
                                : _vm._e(),
                              !column.operationCardFooterDTO.craftApproveDTO
                                .approverId
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeleteCraftCard(
                                            column
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                              column.operationCardHeaderDTO.operationCardBaseDTO
                                .operationCardType === 0
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCopyCraftCard(column)
                                        },
                                      },
                                    },
                                    [_vm._v("复制卡片")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dropdown",
        {
          staticClass: "export-craft",
          on: { command: _vm.handleExportCraftCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出工艺")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选工艺"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CraftEditModal", {
        attrs: {
          "modal-title": _vm.currentEditType,
          "craft-form": _vm.currentCraft,
          visible: _vm.visible,
        },
        on: { cancle: _vm.handleEditCancel },
      }),
      _vm.routeModalVisible
        ? _c("ProcessRouteCard", {
            attrs: {
              "modal-title": _vm.currentPlanCode,
              "select-visible": _vm.processRouteSelectVisiable,
              record: _vm.currentProcessRoute,
              "current-version": _vm.currentVersion,
              "craft-data": _vm.craftCardData,
            },
            on: {
              cancel: _vm.handleRouteModalCanceled,
              confirm: _vm.handleRouteModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.approveCardModalVisible
        ? _c("ApproveCardModal", {
            attrs: { "card-id": _vm.approveId },
            on: {
              cancel: _vm.handleApproveModalCanceled,
              confirm: _vm.handleApproveModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.taskCardChooseModalVisible
        ? _c("TaskCardChooseModal", {
            attrs: { "plan-id": _vm.taskCardPlanId },
            on: {
              cancel: _vm.handleTaskModalCanceled,
              confirm: _vm.handleTaskModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.taskCraftChooseModalVisible
        ? _c("TaskCraftChooseModal", {
            attrs: { "plan-id": _vm.actualColumn.planId },
            on: {
              cancel: _vm.handleCraftTaskModalCanceled,
              confirm: _vm.handleCraftTaskModalConfirmed,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }