<template>
  <div class="app-container">
    <a-row>
      <a-col :span="2">
        <c-upload
          name="file"
          :accept="&quot;.xlsx&quot;"
          :show-upload-list="false"
          :action="`${uploadUrl}tenants/suppliers/imports`"
          @change="handleFileChange"
        >
          <el-button slot="content" class="header-button" type="primary" :loading="uploading" icon="el-icon-circle-plus-outline">导入供应商</el-button>
        </c-upload>
      </a-col>
      <a-col :span="2">
        <el-button class="header-button" type="primary" icon="el-icon-circle-plus-outline" @click="handleAdd">新增供应商</el-button>
      </a-col>
      <a-col :span="3" :offset="17">
        <el-button type="text" icon="el-icon-download" @click="handleDownloadTemplate">点击下载供应商导入模板</el-button>
      </a-col>
    </a-row>
    <a-table
      row-key="id"
      bordered
      class="users-table"
      :columns="tenantsColumns"
      :data-source="list"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>
      <div slot="type" slot-scope="text">
        <span v-if="text === 'SPL'">供应商</span>
        <span v-if="text === 'CLT'">客户</span>
        <span v-if="text === 'BTH'">合作伙伴</span>
        <span v-if="text === 'OTH'">其他</span>
      </div>
      <div slot="contactsInfo" slot-scope="text">
        <div v-for="(item, index) in text" :key="index">
          <a-tooltip>
            <template slot="title">{{ item.telephone }} {{ item.address }}</template>
            <a-tag>{{item.name}}</a-tag>
          </a-tooltip>
        </div>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operate" slot-scope="text, column" class="table-user">
        <a-dropdown v-if="column.status!==2">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleUpdate(column)">更新供应商</a-menu-item>
            <a-menu-item @click="handleDelete(column)">删除供应商</a-menu-item>
          </a-menu>
          <a class="user-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <el-dropdown class="export-plan" @command="handleExportUserCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出供应商</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="ALL">导出所有供应商</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <TenantsModal
      v-if="editModalVisible"
      tenantType="SPL"
      :record="currentRecord"
      :modal-title="currentEditType"
      @cancel="handleModalCanceled"
      @confirm="handleModalConfirmed"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { tenantsColumns } from './dataLogic'
import TenantsModal from '@/components/TenantsModal'
import { getSuppliers, exportAllSuppliers } from '@/api/suppliers'
import { deleteTenants } from '@/api/tenants'
import { downloadTemplate } from '@/common/templateDownload'

export default {
  components: {
    TenantsModal
  },
  data() {
    return {
      loading: false,
      uploading: false,
      list: [],
      tenantsColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initListQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentEditType: '',
      currentRecord: {},
      editModalVisible: false,
      columnSearchParams: {},
      uploadUrl: process.env.VUE_APP_BASE_API,
      exportLoading: false
    }
  },
  created() {
    this.getData(this.initListQueryParams)
  },
  methods: {
    getData(data) {
      this.loading = true
      getSuppliers(data)
        .then(res => {
          this.list = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getDataByParams() {
      const cQueryParams = deepClone(this.currentQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getData(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentQueryParams.pageNum = pagination.current
      this.currentQueryParams.pageSize = pagination.pageSize
      this.getDataByParams()
    },
    handleDelete(column) {
      this.$confirm({
        content: '确认删除供应商' + column.name + '?',
        onOk: () => {
          deleteTenants(column.code).then(() => {
            this.$message.success('删除供应商成功！')
            this.getDataByParams()
          })
        }
      })
    },
    handleAdd() {
      this.currentEditType = '新增'
      this.currentRecord = {}
      this.editModalVisible = true
    },
    // 更新员工
    handleUpdate(column) {
      this.currentEditType = '更新'
      this.currentRecord = deepClone(column)
      this.editModalVisible = true
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      if (dataIndex === 'roles') {
        dataIndex = 'role.name'
      }
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleModalConfirmed() {
      this.editModalVisible = false
      this.getData(this.currentQueryParams)
    },
    handleModalCanceled() {
      this.editModalVisible = false
    },
    handleDownloadTemplate() {
      downloadTemplate('suppliers')
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`批量导入失败，请稍后再试`)
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      this.$info({
        title: '供应商信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入数据总计：' + response.totalNum + '条;'),
          h('p', '非法数据条数：' + response.failedNum + ';'),
          h('p', '导入成功数据条数：' + response.successfulNum + ';'),
          h('p', '非法数据信息:'),
          h('p', response.invalidMessages.join(' ||  '))
        ]),
        onOk: () => {
          this.uploading = false
          this.getData(this.currentQueryParams)
        }
      })
    },
    handleExportUserCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'ALL': {
          exportAllSuppliers()
            .then(res => {
              this.downloadUser(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        }
        default:
          this.exportLoading = false
          break
      }
    },
    downloadUser(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.SUPPILER_FILE_NAME +
          currentDate +
          CONSTANTS.EXPORT_FILE_SUFFIX
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
}
</style>
