var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: "对账单" + _vm.proofNumber + "结算凭据录入",
        maskClosable: false,
        width: "850px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.settle,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _vm._l(_vm.settle.settleReceipts, function (settleReceipt, index) {
            return _c(
              "a-row",
              { key: index },
              [
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "customOperation seqNum",
                    staticStyle: { "margin-left": "89px" },
                    attrs: {
                      label: "凭据类型",
                      labelCol: { span: 8 },
                      prop: index > 0 ? "receiptType" + index : "receiptType",
                    },
                  },
                  [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "100px" },
                        attrs: { placeholder: "请选择凭据类型" },
                        model: {
                          value: _vm.settle.settleReceipts[index].receiptType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.settle.settleReceipts[index],
                              "receiptType",
                              $$v
                            )
                          },
                          expression:
                            "settle.settleReceipts[index].receiptType",
                        },
                      },
                      [
                        _c("a-select-option", { attrs: { value: "IVC" } }, [
                          _vm._v(" 发票 "),
                        ]),
                        _c("a-select-option", { attrs: { value: "RCP" } }, [
                          _vm._v(" 收据 "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "customOperation",
                    staticStyle: { width: "45%", "margin-left": "-17px" },
                    attrs: {
                      label: "凭据号",
                      prop:
                        index > 0 ? "receipeNumber" + index : "receipeNumber",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "请输入凭据号" },
                      model: {
                        value: _vm.settle.settleReceipts[index].receipeNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.settle.settleReceipts[index],
                            "receipeNumber",
                            $$v
                          )
                        },
                        expression:
                          "settle.settleReceipts[index].receipeNumber",
                      },
                    }),
                    [
                      index === _vm.settle.settleReceipts.length - 1
                        ? _c("a-icon", {
                            staticStyle: {
                              fontSize: "16px",
                              marginLeft: "20px",
                              cursor: "pointer",
                              marginTop: "4px",
                            },
                            attrs: { type: "plus-circle" },
                            on: { click: _vm.handleAddSettleReceipts },
                          })
                        : _vm._e(),
                      index > 0
                        ? _c("a-icon", {
                            staticStyle: {
                              fontSize: "16px",
                              marginLeft: "20px",
                              cursor: "pointer",
                              marginTop: "4px",
                            },
                            attrs: { type: "minus-circle" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDeleteSettleReceipts(index)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                  ],
                  2
                ),
              ],
              1
            )
          }),
          _c(
            "a-form-model-item",
            { attrs: { label: "挂账期", prop: "pendingPeriod" } },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { placeholder: "请输入挂账期" },
                model: {
                  value: _vm.settle.pendingPeriod,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.settle,
                      "pendingPeriod",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "settle.pendingPeriod",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "结算类型", prop: "settleType" } },
            [
              _c(
                "a-radio-group",
                {
                  staticStyle: { width: "300px" },
                  model: {
                    value: _vm.settle.settleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.settle, "settleType", $$v)
                    },
                    expression: "settle.settleType",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: 0 } }, [_vm._v("挂账")]),
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("现款")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "comment" } },
            [
              _c("a-textarea", {
                staticStyle: { width: "300px" },
                attrs: { "max-length": 1024, placeholder: "请输入备注" },
                model: {
                  value: _vm.settle.comment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.settle,
                      "comment",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "settle.comment",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }