var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      attrs: {
        limit: 1,
        action: _vm.action,
        accept: _vm.accept,
        "before-upload": _vm.beforeUploadFile,
        "on-success": _vm.uploadSuc,
        "on-error": _vm.uploadErr,
        data: _vm.data,
        "on-progress": _vm.progressChange,
        multiple: "",
        "show-file-list": false,
      },
    },
    [
      _c("span", [
        _c("i", { staticClass: "el-icon-upload" }),
        _vm._v(
          "上传" +
            _vm._s(_vm.desc === "Excel" ? "Excel文件导入产品" : _vm.desc) +
            " "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }