export const groupColumns = [
  {
    title: '班组名称',
    dataIndex: 'groupName',
    key: 'groupName',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '班组长',
    dataIndex: 'leaderIds',
    key: 'leaderIds',
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      customRender: 'leaderIds'
    },
  },
  {
    title: '成员',
    dataIndex: 'memberIds',
    key: 'memberIds',
    align: 'center',
    scopedSlots: {
      customRender: 'memberIds'
    },
  },
  {
    title: '工序',
    dataIndex: 'operationIds',
    key: 'operationIds',
    align: 'center',
    scopedSlots: {
      customRender: 'operationIds'
    },
  },
  {
    title: '操作',
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate'
    }
  }
]

