var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: "操作日志",
        "mask-closable": false,
        width: "1200px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("a-table", {
            key: _vm.tableKey,
            attrs: {
              "row-key": "taskId",
              bordered: "",
              scroll: { x: 800, y: 500 },
              columns: _vm.recordColumns,
              "data-source": _vm.list,
              loading: _vm.loading,
              pagination: false,
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "changeType",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      text === "IPC"
                        ? _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v("入库撤销"),
                          ])
                        : _vm._e(),
                      text === "OPC"
                        ? _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v("出库撤销"),
                          ])
                        : _vm._e(),
                      text === "ABC"
                        ? _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v("报废撤销"),
                          ])
                        : _vm._e(),
                      text === "TKC"
                        ? _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v("入库任务撤销"),
                          ])
                        : _vm._e(),
                      text === "SMG"
                        ? _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v("对账单开票"),
                          ])
                        : _vm._e(),
                      text === "SMS"
                        ? _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v("对账单结算"),
                          ])
                        : _vm._e(),
                      text === "SMC"
                        ? _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v("对账单撤销"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
              {
                key: "oldData",
                fn: function (text) {
                  return _c("div", {}, [
                    _c("p", [_vm._v("单号: " + _vm._s(text.recepitNumber))]),
                    _c("p", [_vm._v("数量: " + _vm._s(text.count))]),
                    _c("p", [_vm._v("任务号: " + _vm._s(text.taskCode))]),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }