<template>
  <div class="app-container">
    <a-tabs :active-key="currentActiveKey" type="card" @change="handleTabChange">
      <a-tab-pane key="ALL" tab="所有急件" />
      <a-tab-pane key="UGC" tab="紧急件" />
      <a-tab-pane key="TUG" tab="转入急件" />
      <a-tab-pane key="IUG" tab="插入急件" />
      <a-tab-pane key="MFN" tab="必完件" />
    </a-tabs>
    <a-table
      :key="tableKey"
      row-key="id"
      bordered
      :scroll="{ x: 2000 }"
      :columns="taskColumns"
      :data-source="taskList"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-else-if="column.searchType === 'numberInput'">
          <a-input-number
            :value="selectedKeys[0]"
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :min="0"
            :max="999999"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e ? [e] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'number'">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px;"
            :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
            @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <span slot="emgType" slot-scope="text">
        <a-tag color="volcano">{{ EmergencyType[text] }}</a-tag>
      </span>
      <div slot="process" slot-scope="text, column" class="table-process">
        <template v-if="column.remainProcessRoute.length > 0">
          <a-steps :current="column.remainProcessRoute.length-1" status="finish" size="small">
            <a-step v-for="process in column.remainProcessRoute" :key="process.id" :title="process.operationName">
              <span slot="description" class="process-desc">
                <p v-if="process.operationType>0&&process.verifyProcessHour" class="description-font">
                  <b>加工工时：</b>
                  {{ process.verifyProcessHour }}
                </p>
                <p v-if="process.operationType>0&&process.verifyTransferOutTime" class="description-font">
                  <b>转出日期：</b>
                  {{ process.verifyTransferOutTime }}
                </p>
              </span>
            </a-step>
          </a-steps>
        </template>
      </div>
      <div slot="actualUseMaterialList" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span v-for="(item, index) in text" :key="index">
              {{item.materialName}}({{item.materialCode}})
              <span v-if="index < text.length - 1">,</span>
            </span>
          </template>
          <div class="table-ellis">
            <span v-for="(item, index) in text" :key="index">{{item.materialName}}({{item.materialCode}}),</span>
          </div>
        </a-tooltip>
      </div>
      <span slot="operation" slot-scope="text, column" class="table-operation">
        <a-dropdown placement="bottomRight">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleReadNegotiates(column.taskId)">查看协商记录</a-menu-item>
            <a-menu-item @click="handleAddNegotiates(column.taskId)">新增协商记录</a-menu-item>
            <a-menu-item @click="handleUpdateNegotiates(column.taskId)">更新协商记录</a-menu-item>
          </a-menu>
          <a class="operation-btn">
            操作
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </span>
      <div slot="status" slot-scope="text">
        <template>
          <template v-if="text === 0">
            <a-badge status="default" />准备中
          </template>
          <template v-if="text === 1">
            <a-badge status="default" />未开始
          </template>
          <template v-if="text === 2">
            <a-badge status="processing" />进行中
          </template>
          <template v-if="text === 4">
            <a-badge status="error" />超期进行中
          </template>
          <template v-if="text === 5">
            <a-badge status="warning" />即将超期
          </template>
          <template v-if="text === 3">
            <a-badge status="success" />已完成
          </template>
          <template v-if="text === 9">
            <a-badge status="warning" />暂停中
          </template>
          <template v-if="text === 8">
            <a-badge status="error" />已取消
          </template>
          <template v-if="text === 10">
            <a-badge status="error" />异常终止
          </template>
        </template>
      </div>
      <div slot="isParts" slot-scope="text">
        <template v-if="text === 0">
          <a-tag color="red">否</a-tag>
        </template>
        <template v-if="text === 1">
          <a-tag color="green">是</a-tag>
        </template>
      </div>
    </a-table>
    <el-dropdown class="export-plan" @command="handleExportTaskCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出任务</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="FILTERED">导出当前筛选任务</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <NegotiatesEditModal
      v-if="NegotiatesEditModalVisible"
      :task-id="NegotiatesEditTaskId"
      :type="NegotiatesEditType"
      @confirm="confirmed"
      @cancel="canceled"
    />
    <NegotiatesListModal v-if="NegotiatesListModalVisible" :task-id="NegotiatesEditTaskId" @confirm="canceled" @cancel="canceled" />
  </div>
</template>

<script>
import { CONSTANTS } from '@/utils/constants'
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'
import * as R from 'ramda'
import { taskColumns } from './dataLogic'
import NegotiatesEditModal from '@/components/NegotiatesEditModal'
import NegotiatesListModal from '@/components/NegotiatesListModal'

import { getEmgTasks, exportEmgTasks, getLatestNegotiates } from '@/api/emgtask'

import { EmergencyType } from '@/enums/index'

export default {
  components: {
    NegotiatesEditModal,
    NegotiatesListModal
  },
  data() {
    return {
      EmergencyType,
      tableKey: 0,
      loading: false,
      taskList: [],
      taskColumns,
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initInventoryListQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentInventoryListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
        'task.status': 'in:2,4,5'
      },
      currentActiveKey: 'ALL',
      columnSearchParams: {},
      NegotiatesEditModalVisible: false,
      NegotiatesEditTaskId: '',
      NegotiatesEditType: '',
      exportLoading: false,
      NegotiatesListModalVisible: false
    }
  },
  created() {
    this.getTasks(this.currentInventoryListQueryParams)
    this.updateTaskStatusFilters([2, 4, 5])
  },
  methods: {
    handleTabChange(activeKey) {
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.currentActiveKey = activeKey
      this.getTasks(this.currentInventoryListQueryParams)
      this.tableKey += 1
    },
    // 获取工艺数据
    getTasks(data) {
      this.loading = true
      getEmgTasks(this.currentActiveKey, data)
        .then(res => {
          this.taskList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getTasksByParams() {
      const cQueryParams = deepClone(this.currentInventoryListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getTasks(queryParams)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          if (dataIndex === 'stayTime') {
            const minTime = this.$moment()
              .subtract(selectedKeys[1], 'hours')
              .format('YYYY-MM-DD HH:mm:ss')
            const maxTime = this.$moment()
              .subtract(selectedKeys[0], 'hours')
              .format('YYYY-MM-DD HH:mm:ss')
            this.columnSearchParams['latestOperationTime'] =
              'btn:' + minTime + ',' + maxTime
          } else {
            this.columnSearchParams[dataIndex] =
              'btn:' + selectedKeys.toString()
          }
        }
      }
    },
    // 重置搜索筛选条件
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      if (dataIndex === 'stayTime') {
        this.columnSearchParams['latestOperationTime'] = null
      }
      this.columnSearchParams[dataIndex] = null
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('plan.isParts')) {
        delete this.columnSearchParams['plan.isParts']
        if (filters['plan.isParts'].length > 0) {
          this.columnSearchParams['plan.isParts'] = `in:${filters[
            'plan.isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['plan.isParts']
        }
      }
      if (filtersKeys.includes('task.status')) {
        delete this.columnSearchParams['task.status']
        if (filters['task.status'].length > 0) {
          this.columnSearchParams['task.status'] = `in:${filters[
            'task.status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.status']
        }
      }
      this.updateTaskStatusFilters(filters['task.status'])
      if (filtersKeys.includes('recordWarehouseType')) {
        delete this.columnSearchParams['recordWarehouseType']
        if (filters['recordWarehouseType'].length > 0) {
          this.columnSearchParams['recordWarehouseType'] = `in:${filters[
            'recordWarehouseType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['recordWarehouseType']
        }
      }
      if (filtersKeys.includes('product.type')) {
        delete this.columnSearchParams['product.type']
        if (filters['product.type'].length > 0) {
          this.columnSearchParams['product.type'] = `in:${filters[
            'product.type'
          ].toString()}`
        } else {
          delete this.columnSearchParams['product.type']
        }
      }

      this.pagination = pagination
      this.currentInventoryListQueryParams.pageNum = pagination.current
      this.currentInventoryListQueryParams.pageSize = pagination.pageSize

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentInventoryListQueryParams.sort_by =
          sortType + sorter.columnKey
      } else {
        this.currentInventoryListQueryParams.sort_by = null
      }

      this.getTasksByParams()
    },
    handleReadNegotiates(id) {
      this.NegotiatesEditTaskId = id
      this.NegotiatesListModalVisible = true
    },
    handleAddNegotiates(id) {
      this.NegotiatesEditTaskId = id
      this.NegotiatesEditType = 'add'
      this.NegotiatesEditModalVisible = true
    },
    handleUpdateNegotiates(id) {
      getLatestNegotiates(id).then(res => {
        if (res.data) {
          this.NegotiatesEditTaskId = id
          this.NegotiatesEditType = 'update'
          this.NegotiatesEditModalVisible = true
        } else {
          this.$message.error('暂无协商记录')
        }
      })
    },
    confirmed() {
      this.NegotiatesEditModalVisible = false
      this.getTasks(this.currentInventoryListQueryParams)
    },
    canceled() {
      this.NegotiatesEditModalVisible = false
      this.NegotiatesListModalVisible = false
    },

    handleExportTaskCommand(command) {
      const cQueryParams = deepClone(this.currentInventoryListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      switch (command) {
        case 'FILTERED':
          this.exportLoading = true
          exportEmgTasks(this.currentActiveKey, queryParams)
            .then(res => {
              this.exportLoading = false
              this.downloadTasks(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        default:
          this.exportLoading = false
          break
      }
    },
    downloadTasks(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.TASK_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    updateTaskStatusFilters(value) {
      this.taskColumns[4].filteredValue = value
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  .file-wrapper {
    width: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .filename-wrapper {
      display: inline-block;
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 10px;

      a {
        color: #67c23a;
      }
    }

    .filename-delete {
      color: #ff4d4f;
      margin-right: 8px;
    }
  }
  .batch-operate-link {
    padding-left: 20px;
    cursor: pointer;
    color: #409eff;
  }

  .file-link {
    color: #409eff;
  }

  .craft-download-button {
    position: absolute;
    right: 20px;
    top: 70px;
  }
  .operation {
    .operation-btn {
      cursor: pointer;
      color: #409eff;
    }
  }

  .export-craft {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }
}
.table-process {
  float: left;
  width: auto;
}

.process-desc p {
  margin: 0;
  width: 250px;
}
.description-font {
  color: rgb(31, 29, 29);
}
.export-plan {
  float: right;
  top: -50px;
  right: 20px;
}
.table-ellis {
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}
</style>
