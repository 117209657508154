var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: "修改密码",
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.disable,
                loading: _vm.loading,
              },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "passwordForm",
          attrs: {
            model: _vm.passwordForm,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { "has-feedback": "", label: "新密码", prop: "pass" } },
            [
              _c("a-input", {
                attrs: {
                  "max-length": 15,
                  type: "password",
                  autocomplete: "off",
                },
                model: {
                  value: _vm.passwordForm.pass,
                  callback: function ($$v) {
                    _vm.$set(_vm.passwordForm, "pass", $$v)
                  },
                  expression: "passwordForm.pass",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "has-feedback": "",
                label: "确认新密码",
                prop: "checkPass",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  "max-length": 15,
                  type: "password",
                  autocomplete: "off",
                },
                model: {
                  value: _vm.passwordForm.checkPass,
                  callback: function ($$v) {
                    _vm.$set(_vm.passwordForm, "checkPass", $$v)
                  },
                  expression: "passwordForm.checkPass",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }