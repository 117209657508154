<template>
  <div class="container">
    <div class="left">
      <div class="container-top" :bordered="false">
        <p>月目标</p>
        <a-icon type="plus" @click="handleAddTarget" />
      </div>
      <a-menu
        v-if="targetList.length"
        class="menu"
        :default-selected-keys="[targetList[0].id]"
        :mode="mode"
        :theme="theme"
        @scroll="handleScroll"
      >
        <a-menu-item v-for="item of targetList" :key="item.id" @click="handleInitTask(item)">
          <a-icon type="calendar" />
          <span>{{ item.targetName }}</span>
          <a-dropdown>
            <a-icon type="dash" class="handleIcon" @click.stop="e => e.preventDefault()" />
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="handleUpdateTarget(item)">更新月目标</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="handleUpdateTask(item)">规划月目标</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-menu-item>
        <!-- <a-menu-item key="7" style="text-align: center;">
        <a-icon type="loading" />
        </a-menu-item>-->
      </a-menu>
    </div>
    <div class="right">
      <template v-if="taskList.length || columnSearchParams !== {}">
        <div class="top-tip">
          <div class="selected-wrapper">
            <span v-if="hasSelected">
              已选中
              <strong>{{ selectedRowKeys.length }}</strong> 项任务
            </span>
            <a-dropdown v-if="hasSelected">
              <a class="batch-operate-link" @click="e => e.preventDefault()">
                <a-icon type="edit" />批量操作
              </a>

              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="handleBatchEditEmergencyType(selectedRowKeys)">批量设置紧急类型</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="handleDeleteTask(selectedTaskIds)">批量删除任务</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
        <a-table
          :key="tableKey"
          row-key="taskId"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: targetRowSelection.getCheckboxProps }"
          bordered
          :columns="monthColumns"
          :data-source="taskList"
          :scroll="{ x: 2000 }"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <div slot="isParts" slot-scope="text">
            <template v-if="text === 0">
              <a-tag color="red">否</a-tag>
            </template>
            <template v-if="text === 1">
              <a-tag color="green">是</a-tag>
            </template>
          </div>
          <span slot="emergencyType" slot-scope="text">
            <a-tag color="volcano">{{ EmergencyType[text] }}</a-tag>
          </span>
          <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
            <template v-if="column.searchType === 'text'">
              <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`搜索 ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
            </template>
            <div v-else-if="column.searchType === 'number'">
              <a-input-number
                v-model="selectedKeys[0]"
                size="small"
                placeholder="最小值"
                :min="0"
                :max="999999"
                style="width: 95px; margin-bottom: 8px; display: inline-block;"
                @change="e => setSelectedKeys([e,selectedKeys[1]])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
              <span>-</span>
              <a-input-number
                v-model="selectedKeys[1]"
                size="small"
                placeholder="最大值"
                :min="selectedKeys[0]"
                :max="999999"
                style="width: 95px; margin-bottom: 8px; display: inline-block;"
                @change="e => setSelectedKeys([selectedKeys[0],e])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
            </div>
            <div v-else-if="column.searchType === 'date'">
              <a-range-picker
                :show-time="{ format: 'HH:mm:ss' }"
                size="small"
                style="width: 330px; margin-bottom: 8px;"
                :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
                @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
              />
            </div>
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm, column.key)"
            >搜索</a-button>
            <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
          </div>
          <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
          <span v-if="column.status === 2||column.status === 4||column.status === 5||column.status === 8" slot="operation" slot-scope="text, column" class="table-operation">
            <a-dropdown placement="bottomRight">
              <a-menu slot="overlay" class="ant-dropdown-link">
                <a-menu-item v-if="column.status === 2||column.status === 4||column.status === 5" @click="handleEditEmergencyType(column)">设置紧急类型</a-menu-item>
                <a-menu-item @click="handleDeleteTask(column.taskId)">删除任务</a-menu-item>
              </a-menu>
              <a class="operation-btn">
                操作
                <a-icon type="down" />
              </a>
            </a-dropdown>
          </span>
          <div slot="status" slot-scope="text">
            <template v-if="text === 0">
              <a-badge status="default" />准备中
            </template>
            <template v-if="text === 1">
              <a-badge status="default" />未开始
            </template>
            <template v-if="text === 2">
              <a-badge status="processing" />进行中
            </template>
            <template v-if="text === 3">
              <a-badge status="success" />已完成
            </template>
            <template v-if="text === 4">
              <a-badge status="error" />超期进行中
            </template>
            <template v-if="text === 5">
              <a-badge status="warning" />即将超期
            </template>
            <template v-if="text === 9">
              <a-badge status="warning" />暂停中
            </template>
            <template v-if="text === 8">
              <a-badge status="error" />已取消
            </template>
            <template v-if="text === 10">
              <a-badge status="error" />异常终止
            </template>
          </div>
          <div slot="process" slot-scope="text, column" class="table-process">
            <template v-if="column.remainProcessRoute.length > 0">
              <a-steps :current="column.remainProcessRoute.length-1" status="finish" size="small">
                <a-step v-for="process in column.remainProcessRoute" :key="process.id" :title="process.operationName">
                  <span slot="description" class="process-desc">
                    <p v-if="process.operationType>0&&process.verifyProcessHour" class="description-font">
                      <b>加工工时：</b>
                      {{ process.verifyProcessHour }}
                    </p>
                    <p v-if="process.operationType>0&&process.verifyTransferOutTime" class="description-font">
                      <b>转出日期：</b>
                      {{ process.verifyTransferOutTime }}
                    </p>
                  </span>
                </a-step>
              </a-steps>
            </template>
          </div>
        </a-table>
      </template>
      <template v-else>
        <template v-if="targetList.length">
          <a-empty class="empty">
            <span slot="description">暂无任务信息</span>
            <a-button type="primary" @click="handleUpdateTask(activeTargetId)">添加任务</a-button>
          </a-empty>
        </template>
        <template v-else>
          <a-empty class="empty">
            <span slot="description">暂无目标信息</span>
            <a-button type="primary" @click="handleAddTarget">添加月目标</a-button>
          </a-empty>
        </template>
      </template>
    </div>
    <TargetEdit
      v-if="taskEditModalVisible"
      :modal-title="editType"
      :target-form="editTarget"
      target-type="month"
      @confirm="targetConfirm"
      @cancel="targetCancel"
    />
    <TargetTaskEdit v-if="targetTaskModalVisible" :id="editTaskId" :title="editTitle" @confirm="taskEditConfirm" @cancel="taskEditCancel" />
    <TaskEmergencyEditModal
      v-if="emergencyEditVisible"
      :record="emergencyEditColumns"
      :ids="emergencyEditIds"
      :target-id="activeTargetId"
      @confirm="handleTaskEmergencyTypeEditConfirm"
      @cancel="handleTaskEmergencyTypeEditCancel"
    />
  </div>
</template>

<script>
import {
  getTargets,
  getTasksById,
  deleteTarget,
  deleteMonthTargetTask,
} from '@/api/target'
import { EmergencyType } from '@/enums/index'
import { monthColumns, targetRowSelection } from './dataLogic'
import TargetEdit from '@/components/TargetEdit'
import TargetTaskEdit from '@/components/TargetTaskEdit'
import TaskEmergencyEditModal from '@/components/TaskEmergencyEditModal'

export default {
  name: 'TargetStatistics',
  components: {
    TargetEdit,
    TargetTaskEdit,
    TaskEmergencyEditModal,
  },
  data() {
    return {
      EmergencyType,
      targetRowSelection,
      tableKey: 0,
      targetsPagination: {
        pageNum: 1,
        pageSize: 50,
      },
      targetTotalPages: '',
      targetList: [],
      activeTargetId: '',
      activeItem: '',
      taskList: [],
      monthColumns,
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      currentTaskParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
      },
      columnSearchParams: {},
      loading: false,
      mode: 'inline',
      theme: 'light',
      taskEditModalVisible: false,
      editType: '',
      editTarget: {},
      editTitle: '',

      targetTaskModalVisible: false,
      emergencyEditIds: '',
      emergencyEditColumns: {},
      editTaskId: '',
      clear: '',

      emergencyEditVisible: false,
      selectedRowKeys: [],
      selectedTaskIds: [],
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
  },
  mounted() {
    this.getTargets(this.targetsPagination)
  },
  methods: {
    initTargets() {
      this.targetsPagination.pageNum = 1
      this.targetList = []
      this.getTargets(this.targetsPagination)
    },
    getTargets(data) {
      getTargets(data).then((res) => {
        this.targetList.push(...res.data.records)
        this.targetTotalPages = res.data.pages
        this.activeTargetId = this.targetList[0].id
        this.getTaskList(this.targetList[0].id)
      })
    },
    getTaskList(id) {
      if (this.loading) return
      this.loading = true
      getTasksById({
        targetId: id,
        ...this.currentTaskParams,
        ...this.columnSearchParams,
      }).then(
        (res) => {
          this.taskList = res.data.records
          this.pagination.total = res.data.total
          this.loading = false
        },
        (error) => {
          this.loading = false
        }
      )
    },
    handleInitTask(item) {
      this.tableKey += 1
      this.activeTargetId = item.id
      this.activeItem = item
      this.currentTaskParams.pageNum = 1
      this.currentTaskParams.sort_by = null
      this.columnSearchParams = {}
      this.selectedRowKeys = []
      this.getTaskList(item.id)
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      console.log(filtersKeys, filters)
      if (filtersKeys.includes('emergencyType')) {
        delete this.columnSearchParams['emergencyType']
        if (filters['emergencyType'].length > 0) {
          this.columnSearchParams['emergencyType'] = `in:${filters[
            'emergencyType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['emergencyType']
        }
      }
      if (filtersKeys.includes('task.status')) {
        delete this.columnSearchParams['task.status']
        if (filters['task.status'].length > 0) {
          this.columnSearchParams['task.status'] = `in:${filters[
            'task.status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.status']
        }
      }
      if (filtersKeys.includes('product.type')) {
        delete this.columnSearchParams['product.type']
        if (filters['product.type'].length > 0) {
          this.columnSearchParams['product.type'] = `in:${filters[
            'product.type'
          ].toString()}`
        } else {
          delete this.columnSearchParams['product.type']
        }
      }
      if (filtersKeys.includes('plan.isParts')) {
        delete this.columnSearchParams['plan.isParts']
        if (filters['plan.isParts'].length > 0) {
          this.columnSearchParams['plan.isParts'] = `in:${filters[
            'plan.isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['plan.isParts']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        if (sorter.columnKey === 'code') {
          sorter.columnKey = 'task.code'
        }
        this.currentTaskParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentTaskParams.sort_by = null
      }

      this.pagination = pagination
      this.currentTaskParams.pageNum = pagination.current
      this.currentTaskParams.pageSize = pagination.pageSize
      this.getTaskList(this.activeTargetId)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          if (dataIndex === 'code') {
            this.columnSearchParams['task.code'] =
              'like:' + selectedKeys[0].trim()
          } else {
            this.columnSearchParams[dataIndex] =
              'like:' + selectedKeys[0].trim()
          }
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          if (dataIndex === 'deliverTime') {
            this.columnSearchParams['plan.deliverTime'] =
              'btn:' + selectedKeys.toString()
          } else if (dataIndex === 'producingNum') {
            this.columnSearchParams['task.producingNum'] =
              'btn:' + selectedKeys.toString()
          } else {
            this.columnSearchParams[dataIndex] =
              'btn:' + selectedKeys.toString()
          }
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      if (dataIndex === 'code') {
        this.columnSearchParams['task.code'] = null
      } else if (dataIndex === 'deliverTime') {
        this.columnSearchParams['plan.deliverTime'] = null
      } else if (dataIndex === 'producingNum') {
        this.columnSearchParams['task.producingNum'] = null
      } else {
        this.columnSearchParams[dataIndex] = null
      }
    },
    // 监听滚动到底部
    handleScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      // console.log(scrollTop, clientHeight, scrollHeight)
      if (scrollTop + clientHeight === scrollHeight) {
        // alert('滚动到底部啦')
        if (this.targetsPagination.pageNum < this.targetTotalPages) {
          this.targetsPagination.pageNum++
          this.getTargets(this.targetsPagination)
        }
      }
    },

    handleDeleteTarget(id) {
      this.$confirm({
        content: '确认删除此目标?',
        onOk: () => {
          deleteTarget(id).then((res) => {
            if (res.status === 204) {
              this.$message.success('删除目标成功')
              this.initTargets()
              if (this.activeTargetId === id) {
                this.taskList = []
              }
            }
          })
        },
      })
    },
    handleAddTarget() {
      this.editType = '新增目标'
      this.taskEditModalVisible = true
    },
    handleUpdateTarget(target) {
      this.editType = '更新目标'
      this.editTarget = target
      this.taskEditModalVisible = true
    },
    targetConfirm() {
      this.initTargets()
      this.taskEditModalVisible = false
    },
    targetCancel() {
      this.taskEditModalVisible = false
    },

    handleUpdateTask(item) {
      this.editTitle = item.targetName
      this.editTaskId = item.id
      this.targetTaskModalVisible = true
    },
    taskEditConfirm() {
      this.initTargets()
      this.targetTaskModalVisible = false
    },

    taskEditCancel() {
      this.targetTaskModalVisible = false
    },

    handleEditEmergencyType(column) {
      this.emergencyEditVisible = true
      this.emergencyEditIds = [column.taskId]
      this.emergencyEditColumns = column
    },
    handleBatchEditEmergencyType(ids) {
      this.emergencyEditVisible = true
      this.emergencyEditIds = ids
      this.emergencyEditColumns = {}
    },

    handleTaskEmergencyTypeEditConfirm() {
      this.emergencyEditVisible = false
      this.getTaskList(this.activeTargetId)
    },
    handleTaskEmergencyTypeEditCancel() {
      this.emergencyEditVisible = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedTaskIds = selectedRows.map((val) => val.taskId)
    },
    handleDeleteTask(ids) {
      const arr = Array.isArray(ids) ? ids : [ids]
      this.$confirm({
        content: '确认删除选中任务?',
        onOk: () => {
          deleteMonthTargetTask(this.activeTargetId, arr).then((res) => {
            this.$message.success('删除任务成功')
            this.getTaskList(this.activeTargetId)
          })
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../target.scss';
</style>
