<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    :visible="true"
    title="生成预对账单"
    :maskClosable="false"
    @cancel="handleCancel"
    width="600px"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm"
        >确定</a-button
      >
    </template>
    <a-form-model
      ref="ruleForm"
      :model="settle"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <!-- <a-form-model-item label="开单时间" prop="billTime">
        <a-date-picker
          v-model="settle.billTime"
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          type="date"
          placeholder="请选择开单时间"
        />
      </a-form-model-item> -->
      <a-form-model-item label="开单备注" prop="billComment">
        <a-textarea
          v-model.trim="settle.billComment"
          :max-length="1024"
          placeholder="请输入开单备注"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { createSettlements } from '@/api/settlements'
import moment from 'moment'

export default {
  props: {
    ids: Array,
  },
  data() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      loading: false,
      settle: {
        // billTime: '',
        billComment: '',
      },
      rules: {
        // billTime: [
        //   {
        //     required: true,
        //     trigger: 'change',
        //     message: '请选择开单时间',
        //   },
        // ],
      },
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          createSettlements({
            waitSettleIds: this.ids,
            billTime: moment(this.settle.billTime).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            billComment: this.settle.billComment,
          }).then(
            (res) => {
              this.loading = false
              this.$message.success('操作成功')
              this.$emit('confirm')
            },
            (error) => {
              this.loading = false
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style scoped></style>
