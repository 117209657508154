export const taskColumns = [
  {
    title: '任务编码',
    width: 150,
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    searchType: 'text',
    fixed: 'left',
    // sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'code',
    },
  },
  {
    title: '产品名称',
    width: 150,
    dataIndex: 'productName',
    key: 'product.productName',
    align: 'left',
    fixed: 'left',
    searchType: 'text',
    // sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '工艺图文件',
    dataIndex: 'fileName',
    key: 'fileName',
    fixed: 'left',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'fileName',
    },
  },
  {
    title: '计划类型',
    width: 150,
    dataIndex: 'productType',
    key: 'product.type',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'productType',
    },
    filters: [
      {
        text: 'L计划',
        value: 'L',
      },
      {
        text: 'D计划',
        value: 'D',
      },
      {
        text: 'G计划',
        value: 'G',
      },
    ],
  },
  {
    title: '是否分件',
    width: 150,
    dataIndex: 'isParts',
    key: 'plan.isParts',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'isParts',
    },
    filters: [
      {
        text: '是',
        value: 1,
      },
      {
        text: '否',
        value: 0,
      },
    ],
  },
  {
    title: '紧急类型',
    width: 150,
    dataIndex: 'emergencyType',
    key: 'task.emgType',
    align: 'center',
    // sorter: true,
    scopedSlots: {
      customRender: 'emergencyType',
    },
    filters: [
      {
        text: '普通',
        value: 'NML',
      },
      {
        text: '必完件',
        value: 'MFN',
      },
      {
        text: '紧急件',
        value: 'UGC',
      },
      {
        text: '插入急件',
        value: 'IUG',
      },
      {
        text: '转入急件',
        value: 'TUG',
      },
    ],
  },
  {
    title: '客户名称',
    width: 150,
    dataIndex: 'client',
    key: 'po.client',
    align: 'center',
    ellipsis: true,
    searchType: 'text',

    // sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '在产数量',
    width: 150,
    dataIndex: 'producingNum',
    key: 'producingNum',
    align: 'center',
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '交货时间',
    width: 200,
    dataIndex: 'deliverTime',
    key: 'plan.deliverTime',
    align: 'center',
    searchType: 'date',
    ellipsis: true,
    // sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务实时位置',
    dataIndex: 'latestOperationName',
    key: 'latestOperationName',
    width: 150,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'latestOperationName',
    },
  },
  {
    title: '未完成工序',
    key: 'process',
    width: 8000,
    scopedSlots: {
      customRender: 'process',
    },
  },
]
