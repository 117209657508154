var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-container" }, [
    _c("div", { attrs: { id: "craft-card-print" } }, [
      _c("div", { staticStyle: { "page-break-after": "always" } }, [
        _c("div", { staticClass: "title" }, [_vm._v("不合格品返工处置通知单")]),
        _c("div", { staticClass: "version" }, [_vm._v("JL-8.7-01")]),
        _c("p", { staticClass: "num" }, [
          _vm._v("NO:" + _vm._s(_vm.data.qualityBaseDTO.defeatReceiptNumber)),
        ]),
        _c("div", { staticClass: "base-info" }, [
          _c("div", { staticClass: "row-1" }, [
            _c("div", { staticClass: "item left" }, [
              _c("div", { staticClass: "label" }, [_vm._v("任务号")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.data.qualityBaseDTO.taskCode)),
              ]),
            ]),
            _c("div", { staticClass: "item right" }, [
              _c("div", { staticClass: "label" }, [_vm._v("责任工序")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.responsibleOperationInfo)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row-1 row-bottom" }, [
            _c("div", { staticClass: "item left" }, [
              _c("div", { staticClass: "label" }, [_vm._v("产品名称")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.data.qualityBaseDTO.productName)),
              ]),
            ]),
            _c("div", { staticClass: "item right" }, [
              _c("div", { staticClass: "label" }, [_vm._v("责任人")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.responsibleUserInfo)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row-2 row-bottom" }, [
            _c("div", { staticClass: "item left" }, [
              _c("div", { staticClass: "label" }, [_vm._v("图号")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.data.qualityBaseDTO.drawingNum)),
              ]),
            ]),
            _c("div", { staticClass: "item middle" }, [
              _c("div", { staticClass: "label" }, [_vm._v("交检数量")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.data.qualityBaseDTO.checkNumber)),
              ]),
            ]),
            _c("div", { staticClass: "item right" }, [
              _c("div", { staticClass: "label" }, [_vm._v("不合格数")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.data.qualityBaseDTO.productAmount)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row-column row-bottom" }, [
            _c("div", { staticClass: "label" }, [_vm._v("不合格原因类别")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.defeatReasonInfo)),
            ]),
          ]),
          _c("div", { staticClass: "hand-check row-bottom" }, [
            _vm._m(0),
            _c("div", { staticClass: "content" }, [
              _c("p", [_vm._v(_vm._s(_vm.data.qualityBaseDTO.defeatDesc))]),
              _c("div", { staticClass: "bottom-check" }, [
                _c("p", [
                  _vm._v("检验员:" + _vm._s(_vm.data.qualityBaseDTO.checker)),
                ]),
                _c("p", [
                  _vm._v(
                    "日期: " + _vm._s(_vm.data.qualityBaseDTO.generateTime)
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "hand-check row-bottom" }, [
            _vm._m(1),
            _c("div", { staticClass: "content content-row" }, [
              _c("div", { staticClass: "content-row-1" }, [
                _c("div", { staticClass: "label" }, [_vm._v("返工要求")]),
                _c("div", { staticClass: "context" }, [
                  _vm._v(_vm._s(_vm.data.qualityBaseDTO.defeatDemand)),
                ]),
              ]),
              _c("div", { staticClass: "content-row-1" }, [
                _c("div", { staticClass: "label" }, [_vm._v("返工工序")]),
                _c("div", { staticClass: "context" }, [
                  _vm._v(_vm._s(_vm.repairOperationInfo)),
                ]),
              ]),
              _c("div", { staticClass: "content-row-1" }, [
                _c("div", { staticClass: "label noborder" }, [
                  _vm._v("返工人"),
                ]),
                _c("div", { staticClass: "context noborder" }, [
                  _vm._v(_vm._s(_vm.repairUserInfo)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "hand-check row-bottom" }, [
            _c("div", { staticClass: "label" }, [_vm._v("处置效果")]),
            _c("div", { staticClass: "content" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.data.qualityBaseDTO.repairEffect === "OK"
                      ? "合格"
                      : "再次返修"
                  )
                ),
              ]),
              _c("div", { staticClass: "bottom-check" }, [
                _c("p", [
                  _vm._v("检验员:" + _vm._s(_vm.data.qualityBaseDTO.checker)),
                ]),
                _c("p", [_vm._v("日期:")]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "a-button",
          {
            directives: [
              {
                name: "print",
                rawName: "v-print",
                value: "craft-card-print",
                expression: "'craft-card-print'",
              },
            ],
            staticClass: "btn",
            attrs: { type: "primary", block: "" },
          },
          [_vm._v("打印")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label" }, [
      _vm._v(" 不合格 "),
      _c("p", [_vm._v("详情")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label" }, [
      _vm._v(" 不合格处 "),
      _c("p", [_vm._v("置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }