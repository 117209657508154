var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: "外购明细",
        "mask-closable": false,
        width: "800px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          staticClass: "cost-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "89px" },
              attrs: {
                label: "是否全外购",
                labelCol: { span: 8 },
                prop: "isAll",
              },
            },
            [
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.handleIsAllChange },
                  model: {
                    value: _vm.ifAll,
                    callback: function ($$v) {
                      _vm.ifAll = $$v
                    },
                    expression: "ifAll",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: true } }, [_vm._v("是")]),
                  _c("a-radio", { attrs: { value: false } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "89px" },
              attrs: {
                label: "产品名称",
                labelCol: { span: 8 },
                prop: "productInfo.name",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入材料名称" },
                model: {
                  value: _vm.form.productInfo.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.productInfo,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.productInfo.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "89px" },
              attrs: {
                label: "图号",
                labelCol: { span: 8 },
                prop: "productInfo.drawingNum",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入图号" },
                model: {
                  value: _vm.form.productInfo.drawingNum,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.productInfo,
                      "drawingNum",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.productInfo.drawingNum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "89px" },
              attrs: {
                label: "规格",
                labelCol: { span: 8 },
                prop: "productInfo.specification",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入规格" },
                model: {
                  value: _vm.form.productInfo.specification,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.productInfo,
                      "specification",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.productInfo.specification",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "89px" },
              attrs: {
                label: "单位",
                labelCol: { span: 8 },
                prop: "productInfo.unit",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入单位" },
                model: {
                  value: _vm.form.productInfo.unit,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.productInfo,
                      "unit",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.productInfo.unit",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "89px" },
              attrs: {
                label: "供应商名称",
                labelCol: { span: 8 },
                prop: "companyInfo.name",
              },
            },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    "show-search": "",
                    "default-active-first-option": false,
                    placeholder: "请输入供应商名称",
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": _vm.fetching ? undefined : null,
                  },
                  on: {
                    search: _vm.fetchTeantsByName,
                    change: function (value, option) {
                      return _vm.handleTenantsChange(value, option)
                    },
                  },
                  model: {
                    value: _vm.form.companyInfo.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.companyInfo, "name", $$v)
                    },
                    expression: "form.companyInfo.name",
                  },
                },
                [
                  _vm.fetching
                    ? _c("a-spin", {
                        attrs: { slot: "notFoundContent", size: "small" },
                        slot: "notFoundContent",
                      })
                    : _vm._e(),
                  _vm._l(_vm.tenants, function (ten) {
                    return _c("a-select-option", { key: ten.name }, [
                      _vm._v(_vm._s(ten.name) + "(" + _vm._s(ten.code) + ")"),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "89px" },
              attrs: {
                label: "单价",
                labelCol: { span: 8 },
                prop: "unitPrice",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入单价" },
                model: {
                  value: _vm.form.unitPrice,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "unitPrice",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.unitPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "89px" },
              attrs: { label: "数量", labelCol: { span: 8 }, prop: "quantity" },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入数量" },
                model: {
                  value: _vm.form.quantity,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "quantity",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.quantity",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }