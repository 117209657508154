import request from '@/utils/api.request'

/**
 * 分页获取计划信息
 * @param data {Object}
 * @param params {Object}
 * @returns {AxiosPromise}
 */
export function getPlans(data) {
  return request({
    url: `/plans`,
    method: 'get',
    params: data,
  })
}

/**
 * 根据id获取指定的计划信息
 * @param {number} id
 */
export function getPlanById(id) {
  return request({
    url: `/plans/${id}`,
    method: 'get',
  })
}

/**
 * 更新指定id的计划
 * @param {number} id
 * @param {object} data
 */
export function updatePlan(id, data) {
  return request({
    url: `/plans/${id}`,
    method: 'put',
    data,
  })
}

/**
 * 删除指定id的计划
 * @param {number} id
 */
export function deletePlan(id) {
  return request({
    url: `/plans/${id}`,
    method: 'delete',
  })
}

/**
 * 通过计划id获取对应计划的工艺信息
 * @param {number} id
 */
export function getCraftsByPlan(id) {
  return request({
    url: `/plans/${id}/crafts`,
    method: 'get',
  })
}

/**
 * 新增计划
 */
export function addPlan(data) {
  return request({
    url: `/plans`,
    method: 'post',
    data,
  })
}

/**
 * 通过计划id新增对应计划的工艺信息
 * @param {number} id
 */
export function addCraftByPlan(id, data) {
  return request({
    url: `/plans/${id}/crafts`,
    method: 'post',
    data,
  })
}

/**
 * 通过计划id获取对应计划的任务信息
 * @param {number} id
 */
export function getTasksByPlan(id) {
  return request({
    url: `/plans/${id}/tasks`,
    method: 'get',
  })
}

/**
 * 通过计划id新增对应计划的任务信息
 * @param {number} id
 */
export function addTaskByPlan(id, data) {
  return request({
    url: `/plans/${id}/tasks`,
    method: 'post',
    data,
  })
}

/**
 * 通过计划id获取对应计划的记录信息
 * @param {number} id
 */
export function getRecordsByPlan(id) {
  return request({
    url: `/plans/${id}/records`,
    method: 'get',
  })
}

/**
 * 通过计划id新增对应计划的记录信息
 * @param {number} id
 */
export function addRecordByPlan(id, data) {
  return request({
    url: `/plans/${id}/records`,
    method: 'post',
    data,
  })
}

/**
 * 通过计划id获取对应计划的记录信息
 * @param {number} id
 */
export function getPlansByProduct(id) {
  return request({
    url: `/products/${id}/plan`,
    method: 'get',
  })
}

/**
 * 删除指定的工艺流转卡
 * @param id
 * @returns {AxiosPromise}
 */
export function deleteCraftCard(id) {
  return request({
    url: `/plans/${id}/process-flow-card`,
    method: 'delete',
  })
}

/**
 * 新增分件计划
 */
export function addPartPlan(data) {
  return request({
    url: `/plans/parts`,
    method: 'post',
    data,
  })
}

/**
 * 获取主件计划所有分件计划任务
 */
export function getPlanSubTasks(planId) {
  return request({
    url: `/plans/${planId}/sub-tasks`,
    method: 'get',
  })
}

/**
 * 制定计划
 */
export function makePlan(id, data) {
  return request({
    url: `/plans/${id}/plan-make`,
    method: 'put',
    data,
  })
}
/**
 * 导出所有计划
 * @param {*} data 导出计划参数
 */
export function exportAllPlans(data) {
  return request({
    url: `/plans/exports/all`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}

/**
 * 导出选择计划
 * @param {*} data 导出计划参数
 */
export function exportPlansByIds(data) {
  return request({
    url: `/plans/exports/ids`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}

/**
 * 导出筛选计划
 * @param {*} data 导出计划参数
 */
export function exportFilteredPlans(data) {
  return request({
    url: `/plans/exports/filtered`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}
/**
 * 导出当前页计划
 * @param {*} data 导出计划参数
 */
export function exportCurrentPagePlans(data) {
  return request({
    url: `/plans/exports/current-page`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}

/**
 * 通过计划类型获取对应计划的最新编号
 */
export function getLatestPlanCode(planType) {
  return request({
    url: `/plans/${planType}/latest-code`,
    method: 'get',
  })
}

/**
 * 暂停、取消、恢复计划
 * @param id
 * @param data
 * @returns {AxiosPromise}
 */
export function switchPlanStatus(planId, statusCode) {
  return request({
    url: `/plans/${planId}/status/${statusCode}`,
    method: 'put',
  })
}

/**
 * 根据id列表获取指定的下达计划交接表信息
 */
export function getPlanCardInfoByIds(data) {
  return request({
    url: `/plans/production-plan-delivery-receipts`,
    method: 'get',
    params: data,
  })
}

/**
 * 获取产品库存数量
 */
export function getPlanInventory(data) {
  return request({
    url: `/warehouses/product-count`,
    method: 'get',
    params: data,
  })
}

/**
 * 获取产品库存数量（新）
 */
export function getPlanInventoryNew(data) {
  return request({
    url: `/warehouses/fuzzy-products`,
    method: 'get',
    params: data,
  })
}

/**
 * 获取某天所有计划产值总金额
 */
export function getTotalProductionPriceByDeliveryDateTime(data) {
  return request({
    url: `/plans/total-production-price`,
    method: 'get',
    params: data,
  })
}
