<template>
  <a-modal
    class="modal-container"
    :visible="true"
    :title="isAdd ? '新增产品' : '更新产品'"
    :mask-closable="false"
    width="800px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>

    <a-form-model ref="ruleForm" :model="record" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">

      <a-form-model-item label="产品名称" :labelCol="{span: 8}" style="margin-left: 89px;" prop="name" class="customOperation seqNum">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="record.name" placeholder="请输入产品名称" />
      </a-form-model-item>

      <a-form-model-item label="产品图号" :labelCol="{span: 8}" style="margin-left: 89px;" prop="drawingNum" class="customOperation seqNum">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="record.drawingNum" placeholder="请输入产品图号" />
      </a-form-model-item>

      <a-form-model-item label="产品规格" :labelCol="{span: 8}" style="margin-left: 89px;" prop="specification" class="customOperation seqNum">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="record.specification" placeholder="请输入产品规格" />
      </a-form-model-item>

      <a-form-model-item label="产品单位" :labelCol="{span: 8}" style="margin-left: 89px;" prop="unit" class="customOperation seqNum">
        <a-input style="width: 300px" :maxLength="2" v-model.trim="record.unit" placeholder="请输入产品单位" />
      </a-form-model-item>

      <a-form-model-item label="备注" :labelCol="{span: 8}" style="margin-left: 89px;" class="customOperation seqNum" prop="comment">
        <a-textarea style="width: 300px" :max-length="500" v-model="record.comment" placeholder="请输入备注" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { addProduct, updateProduct } from '@/api/productLibrary'
export default {
  props: {
    type: String,
    productRecord: Object
  },
  data() {
    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      record: this.productRecord || {
        code: '',
        name: '',
        drawingNum: '',
        specification: '',
        unit: '',
        comment: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入产品名称',
            trigger: 'blur'
          }
        ],
        drawingNum: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入产品图号'
          }
        ],
        specification: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入产品规格'
          }
        ],
        unit: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入产品单位'
          }
        ]
      }
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function() {
      return this.type === 'add'
    },
    isUpdate: function() {
      return this.type === 'update'
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.type === 'add') {
            this.add()
          } else {
            this.update()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    add() {
      this.loading = true
      addProduct(this.record)
        .then(res => {
          this.$emit('confirm')
          this.$message.success('操作成功')
        })
        .finally(() => {
          this.loading = false
        })
    },
    update() {
      this.loading = true
      updateProduct(this.productRecord.code, this.record)
        .then(res => {
          this.$emit('confirm')
          this.$message.success('操作成功')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
</style>
