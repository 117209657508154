import upload from './upload'
import select from './select'

const packages = {}
packages.install = (VueInstance) => {
  VueInstance.use(upload)
  VueInstance.use(select)
}

export default packages
