export const logColumns = [
  {
    title: '目标名称',
    dataIndex: 'targetName',
    key: 'targetName',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '目标开始时间',
    dataIndex: 'targetStartTime',
    key: 'targetStartTime',
    align: 'center',
    searchType: 'date',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '目标类型',
    dataIndex: 'targetType',
    key: 'targetType',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'targetType',
    },
    filters: [
      {
        text: '月目标',
        value: 'M',
      },
      {
        text: '周目标',
        value: 'W',
      },
      {
        text: '日目标',
        value: 'D',
      },
    ],
  },
  {
    title: '任务初项',
    dataIndex: 'createdNum',
    key: 'createdNum',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '任务增项',
    dataIndex: 'addNum',
    key: 'addNum',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '任务减项',
    dataIndex: 'deletedNum',
    key: 'deletedNum',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'deleteNum',
    },
  },
  {
    title: '目标数量调整项',
    dataIndex: 'updateNum',
    key: 'updateNum',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'updateNum',
    },
  },
  {
    title: '任务完成项',
    dataIndex: 'finishNum',
    key: 'finishNum',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
]

export const groupColumns = [
  {
    title: '班组名称',
    dataIndex: 'groupInfo.groupName',
    key: 'groupInfo.groupName',
    align: 'center',
    scopedSlots: {
      customRender: 'groupName',
    },
  },
  {
    title: '目标名称',
    dataIndex: 'targetInfo.targetName',
    key: 'targetInfo.targetName',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '任务项数总计',
    dataIndex: 'tasksStatisticsInfo.totalCount',
    key: 'tasksStatisticsInfo.totalCount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '任务完成项数',
    dataIndex: 'tasksStatisticsInfo.finishedCount',
    key: 'tasksStatisticsInfo.finishedCount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '任务完成率',
    dataIndex: 'tasksStatisticsInfo.finishedRate',
    key: 'tasksStatisticsInfo.finishedRate',
    align: 'center',
    scopedSlots: {
      customRender: 'rate',
    },
  },
  {
    title: '产品数量总计',
    dataIndex: 'productStatisticsInfo.totalCount',
    key: 'productStatisticsInfo.totalCount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '产品完成数量',
    dataIndex: 'productStatisticsInfo.finishedCount',
    key: 'productStatisticsInfo.finishedCount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '产品完成率',
    dataIndex: 'productStatisticsInfo.finishedRate',
    key: 'productStatisticsInfo.finishedRate',
    align: 'center',
    scopedSlots: {
      customRender: 'rate',
    },
  },
  {
    title: '金额总计',
    dataIndex: 'amountStatisticsInfo.totalAmount',
    key: 'amountStatisticsInfo.totalAmount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '完成金额',
    dataIndex: 'amountStatisticsInfo.finishedAmount',
    key: 'amountStatisticsInfo.finishedAmount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '金额完成率',
    dataIndex: 'amountStatisticsInfo.finishedRate',
    key: 'amountStatisticsInfo.finishedRate',
    align: 'center',
    scopedSlots: {
      customRender: 'rate',
    },
  },
]

export const memberColumns = [
  {
    title: '员工名称',
    dataIndex: 'memberInfo.realName',
    key: 'memberInfo.realName',
    align: 'center',
    scopedSlots: {
      customRender: 'userName',
    },
  },
  {
    title: '目标名称',
    dataIndex: 'targetInfo.targetName',
    key: 'targetInfo.targetName',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '任务总项数',
    dataIndex: 'tasksStatisticsInfo.totalCount',
    key: 'tasksStatisticsInfo.totalCount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '任务完成项数',
    dataIndex: 'tasksStatisticsInfo.finishedCount',
    key: 'tasksStatisticsInfo.finishedCount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '任务完成率',
    dataIndex: 'tasksStatisticsInfo.finishedRate',
    key: 'tasksStatisticsInfo.finishedRate',
    align: 'center',
    scopedSlots: {
      customRender: 'rate',
    },
  },
  {
    title: '产品数量总计',
    dataIndex: 'productStatisticsInfo.totalCount',
    key: 'productStatisticsInfo.totalCount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '产品完成数量',
    dataIndex: 'productStatisticsInfo.finishedCount',
    key: 'productStatisticsInfo.finishedCount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '产品完成率',
    dataIndex: 'productStatisticsInfo.finishedRate',
    key: 'productStatisticsInfo.finishedRate',
    align: 'center',
    scopedSlots: {
      customRender: 'rate',
    },
  },
  {
    title: '金额总计',
    dataIndex: 'amountStatisticsInfo.totalAmount',
    key: 'amountStatisticsInfo.totalAmount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '完成金额',
    dataIndex: 'amountStatisticsInfo.finishedAmount',
    key: 'amountStatisticsInfo.finishedAmount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '金额完成率',
    dataIndex: 'amountStatisticsInfo.finishedRate',
    key: 'amountStatisticsInfo.finishedRate',
    align: 'center',
    scopedSlots: {
      customRender: 'rate',
    },
  },
]

export const targetRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({
    props: {
      disabled: record.invoiceNumber,
    },
  }),
}
