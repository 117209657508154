var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "a-tabs",
        {
          attrs: { "active-key": _vm.currentActiveKey, type: "card" },
          on: { change: _vm.handleTabChange },
        },
        [
          _c("a-tab-pane", { key: "ALL", attrs: { tab: "所有急件" } }),
          _c("a-tab-pane", { key: "UGC", attrs: { tab: "紧急件" } }),
          _c("a-tab-pane", { key: "TUG", attrs: { tab: "转入急件" } }),
          _c("a-tab-pane", { key: "IUG", attrs: { tab: "插入急件" } }),
          _c("a-tab-pane", { key: "MFN", attrs: { tab: "必完件" } }),
        ],
        1
      ),
      _c("a-table", {
        key: _vm.tableKey,
        attrs: {
          "row-key": "id",
          bordered: "",
          scroll: { x: 2000 },
          columns: _vm.taskColumns,
          "data-source": _vm.taskList,
          pagination: _vm.pagination,
          loading: _vm.loading,
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.key
                              )
                            },
                          },
                        }),
                      ]
                    : column.searchType === "numberInput"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "ant-ref",
                                rawName: "v-ant-ref",
                                value: function (c) {
                                  return (_vm.searchInput = c)
                                },
                                expression: "c => (searchInput = c)",
                              },
                            ],
                            staticStyle: {
                              width: "188px",
                              "margin-bottom": "8px",
                              display: "block",
                            },
                            attrs: {
                              value: selectedKeys[0],
                              placeholder: "搜索 " + column.title,
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys(e ? [e] : [])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "190px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "emgType",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  _c("a-tag", { attrs: { color: "volcano" } }, [
                    _vm._v(_vm._s(_vm.EmergencyType[text])),
                  ]),
                ],
                1
              )
            },
          },
          {
            key: "process",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-process" },
                [
                  column.remainProcessRoute.length > 0
                    ? [
                        _c(
                          "a-steps",
                          {
                            attrs: {
                              current: column.remainProcessRoute.length - 1,
                              status: "finish",
                              size: "small",
                            },
                          },
                          _vm._l(column.remainProcessRoute, function (process) {
                            return _c(
                              "a-step",
                              {
                                key: process.id,
                                attrs: { title: process.operationName },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "process-desc",
                                    attrs: { slot: "description" },
                                    slot: "description",
                                  },
                                  [
                                    process.operationType > 0 &&
                                    process.verifyProcessHour
                                      ? _c(
                                          "p",
                                          { staticClass: "description-font" },
                                          [
                                            _c("b", [_vm._v("加工工时：")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  process.verifyProcessHour
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    process.operationType > 0 &&
                                    process.verifyTransferOutTime
                                      ? _c(
                                          "p",
                                          { staticClass: "description-font" },
                                          [
                                            _c("b", [_vm._v("转出日期：")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  process.verifyTransferOutTime
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "actualUseMaterialList",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-tooltip",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        _vm._l(text, function (item, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              " " +
                                _vm._s(item.materialName) +
                                "(" +
                                _vm._s(item.materialCode) +
                                ") "
                            ),
                            index < text.length - 1
                              ? _c("span", [_vm._v(",")])
                              : _vm._e(),
                          ])
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "table-ellis" },
                        _vm._l(text, function (item, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              _vm._s(item.materialName) +
                                "(" +
                                _vm._s(item.materialCode) +
                                "),"
                            ),
                          ])
                        }),
                        0
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            },
          },
          {
            key: "operation",
            fn: function (text, column) {
              return _c(
                "span",
                { staticClass: "table-operation" },
                [
                  _c(
                    "a-dropdown",
                    { attrs: { placement: "bottomRight" } },
                    [
                      _c(
                        "a-menu",
                        {
                          staticClass: "ant-dropdown-link",
                          attrs: { slot: "overlay" },
                          slot: "overlay",
                        },
                        [
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleReadNegotiates(column.taskId)
                                },
                              },
                            },
                            [_vm._v("查看协商记录")]
                          ),
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddNegotiates(column.taskId)
                                },
                              },
                            },
                            [_vm._v("新增协商记录")]
                          ),
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpdateNegotiates(
                                    column.taskId
                                  )
                                },
                              },
                            },
                            [_vm._v("更新协商记录")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        { staticClass: "operation-btn" },
                        [
                          _vm._v(" 操作 "),
                          _c("a-icon", { attrs: { type: "down" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  [
                    text === 0
                      ? [
                          _c("a-badge", { attrs: { status: "default" } }),
                          _vm._v("准备中 "),
                        ]
                      : _vm._e(),
                    text === 1
                      ? [
                          _c("a-badge", { attrs: { status: "default" } }),
                          _vm._v("未开始 "),
                        ]
                      : _vm._e(),
                    text === 2
                      ? [
                          _c("a-badge", { attrs: { status: "processing" } }),
                          _vm._v("进行中 "),
                        ]
                      : _vm._e(),
                    text === 4
                      ? [
                          _c("a-badge", { attrs: { status: "error" } }),
                          _vm._v("超期进行中 "),
                        ]
                      : _vm._e(),
                    text === 5
                      ? [
                          _c("a-badge", { attrs: { status: "warning" } }),
                          _vm._v("即将超期 "),
                        ]
                      : _vm._e(),
                    text === 3
                      ? [
                          _c("a-badge", { attrs: { status: "success" } }),
                          _vm._v("已完成 "),
                        ]
                      : _vm._e(),
                    text === 9
                      ? [
                          _c("a-badge", { attrs: { status: "warning" } }),
                          _vm._v("暂停中 "),
                        ]
                      : _vm._e(),
                    text === 8
                      ? [
                          _c("a-badge", { attrs: { status: "error" } }),
                          _vm._v("已取消 "),
                        ]
                      : _vm._e(),
                    text === 10
                      ? [
                          _c("a-badge", { attrs: { status: "error" } }),
                          _vm._v("异常终止 "),
                        ]
                      : _vm._e(),
                  ],
                ],
                2
              )
            },
          },
          {
            key: "isParts",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0
                    ? [_c("a-tag", { attrs: { color: "red" } }, [_vm._v("否")])]
                    : _vm._e(),
                  text === 1
                    ? [
                        _c("a-tag", { attrs: { color: "green" } }, [
                          _vm._v("是"),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
        ]),
      }),
      _c(
        "el-dropdown",
        {
          staticClass: "export-plan",
          on: { command: _vm.handleExportTaskCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出任务")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选任务"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.NegotiatesEditModalVisible
        ? _c("NegotiatesEditModal", {
            attrs: {
              "task-id": _vm.NegotiatesEditTaskId,
              type: _vm.NegotiatesEditType,
            },
            on: { confirm: _vm.confirmed, cancel: _vm.canceled },
          })
        : _vm._e(),
      _vm.NegotiatesListModalVisible
        ? _c("NegotiatesListModal", {
            attrs: { "task-id": _vm.NegotiatesEditTaskId },
            on: { confirm: _vm.canceled, cancel: _vm.canceled },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }