<template>
  <a-upload v-bind="$attrs" v-on="$listeners" :headers="{'authorization': token, 'appId': appId}">
    <slot name="content"></slot>
  </a-upload>
</template>

<script>
import {
  getTokenByLocalStorage,
  setTokenToLocalStorage,
  getAppIdByLocalStorage,
  removeTokenFromLocalStorage,
  removeAppIdFromLocalStorage
} from '@/utils/auth'
export default {
  name: 'c-upload',
  data() {
    return {
      token: '',
      appId: ''
    }
  },
  created() {
    if (this.$store.getters.token) {
      // let each request carry token
      // ['authorization'] is a custom headers key
      // please modify it according to the actual situation
      this.token = getTokenByLocalStorage()
    }
    if (this.$store.getters.appId) {
      this.appId = getAppIdByLocalStorage()
    }
  }
}
</script>

<style>
</style>