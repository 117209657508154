<template>
  <a-modal class="modal-container" :visible="true" :title="code + '产品项明细'" :mask-closable="false" width="1200px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleCancel">确定</a-button>
    </template>
    <a-table
      row-key="id"
      bordered
      class="users-table"
      :columns="
      offerDetailColumns"
      :data-source="list"
      :loading="loading"
      :pagination="pagination"
      :scroll="{ x: 1700 }"
      @change="handleTableChange"
    >
    <div slot="taxRate" slot-scope="text">
        <span>{{ text ? text * 100 + '%' : '' }}</span>
      </div>
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <div v-if="column.searchType===&quot;number&quot;">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px"
            :ranges="{
                今天: [$moment(), $moment()],
                本月: [$moment().startOf('month'), $moment().endOf('month')],
              }"
            @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />

      <div slot="status" slot-scope="text">
        <span v-if="text === 'WSO'">待报出</span>
        <span v-if="text === 'WDL'">待成交</span>
        <span v-if="text === 'CNL'">已取消</span>
        <span v-if="text === 'DEL'">已成交</span>
        <span v-if="text === 'PDL'">部分成交</span>
        <span v-if="text === 'NDL'">未成交</span>
        <span v-if="text === 'RVC'">已撤销</span>
      </div>
    </a-table>
  </a-modal>
</template>
<script>
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { offerDetailColumns } from './dataLogic'
import {
  getQuotationsDetails,
  revocationQuotations,
  putQuotations
} from '@/api/offer'
import { downloadTemplate } from '@/common/templateDownload'

export default {
  props: {
    code: String
  },
  data() {
    return {
      loading: false,
      uploading: false,
      list: [],

      offerDetailColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentEditType: '',
      currentUser: {},
      editUserModalVisible: false,
      userRoleModalVisible: false,
      userOperationModalVisible: false,
      columnSearchParams: {},
      uploadUrl: process.env.VUE_APP_BASE_API,
      exportLoading: false
    }
  },
  created() {
    this.getQuotations(this.initQueryParams)
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    getQuotations(data) {
      this.loading = true
      getQuotationsDetails(this.code, data)
        .then(res => {
          this.list = res.data.records.map((item, index) => {
            item.index =
              (this.currentQueryParams.pageNum - 1) *
                this.currentQueryParams.pageSize +
              index +
              1
            return item
          })
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getQuotationsByParams() {
      const cQueryParams = deepClone(this.currentQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getQuotations(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentQueryParams.pageNum = pagination.current
      this.currentQueryParams.pageSize = pagination.pageSize
      this.getQuotationsByParams()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      let _dataIndex = dataIndex
      if (dataIndex === 'productInfo.name') {
        _dataIndex = 'productName'
      }

      if (dataIndex === 'productInfo.drawingNum') {
        _dataIndex = 'drawingNum'
      }
      this.columnSearchParams[_dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[_dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[_dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      let _dataIndex = dataIndex
      if (dataIndex === 'productInfo.name') {
        _dataIndex = 'productName'
      }

      if (dataIndex === 'productInfo.drawingNum') {
        _dataIndex = 'drawingNum'
      }
      this.columnSearchParams[_dataIndex] = null
    },
    handleDownloadTemplate() {
      downloadTemplate('user')
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`批量导入失败，请稍后再试`)
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      this.$info({
        title: '员工信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入数据总计：' + response.totalNum + '条;'),
          h('p', '非法数据条数：' + response.failedNum + ';'),
          h('p', '导入成功数据条数：' + response.successfulNum + ';'),
          h('p', '非法数据信息:'),
          h('p', response.invalidMessages.join(' ||  '))
        ]),
        onOk: () => {
          this.uploading = false
          this.getUsers(this.currentUserListQueryParams)
        }
      })
    },

    downloadInquiry(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.USER_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleRevocation() {},
    handleSubmit() {}
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
}
</style>
