export const roleColumns = [
  {
    title: '角色编号',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '角色名称',
    dataIndex: 'name',
    key: 'name',
    searchType: 'text',
    sorter: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    },
  },
  {
    title: '角色状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status'
    },
    filters: [
      {
        text: '正常',
        value: 1
      },
      {
        text: '已删除',
        value: 2
      },
    ]
  },
  {
    title: '操作',
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate'
    }
  }
]
