<template>
  <a-modal class="modal-container" visible="true" :title="title" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>

    <a-form-model ref="ruleForm" :model="material" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="材料类别" prop="categoryCode">
        <a-select
          v-model="material.categoryCode"
          show-search
          placeholder="请选择材料类别"
          option-filter-prop="children"
          :filter-option="filterUser"
        >
          <a-select-option v-for="type in currentType" :key="type.parameterValue" :value="type.parameterCode">{{ type.parameterValue }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="材料牌号" prop="materialCode">
        <a-input v-model.trim="material.materialCode" max-length="20" placeholder="请输入材料图号" />
      </a-form-model-item>
      <a-form-model-item label="材料名称" prop="materialName">
        <a-input v-model.trim="material.materialName" max-length="20" placeholder="请输入材料名称" />
      </a-form-model-item>
      <a-form-model-item label="采购规格" prop="purchaseSpecification">
        <a-input v-model.trim="material.purchaseSpecification" placeholder="请输入采购规格" />
      </a-form-model-item>
      <a-form-model-item label="内部编号" prop="innerCode">
        <a-input v-model.trim="material.innerCode" max-length="20" placeholder="请输入内部编号" />
      </a-form-model-item>
      <a-form-model-item label="密度(g/cm3)" prop="density">
        <a-input-number style="width: 350px" v-model="material.density" :precision="2" :min="0" placeholder="请输入密度(g/cm3)" />
      </a-form-model-item>
      <a-form-model-item label="最新单价" prop="unitPrice">
        <a-input-number style="width: 350px" v-model="material.unitPrice" :precision="2" :min="0" placeholder="请输入最新单价" />
      </a-form-model-item>
      <a-form-model-item label="计价单位" prop="unit">
        <a-input v-model.trim="material.unit" max-length="20" placeholder="请输入计价单位" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import * as R from 'ramda'
import { addMaterial, updateMaterial } from '@/api/material'
import { getAllParametersByParentCode } from '@/api/parameter'
export default {
  props: {
    modalTitle: String,
    materialForm: Object
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      material: {
        id: this.materialForm.id,
        materialCode: this.materialForm.materialCode,
        materialName: this.materialForm.materialName,
        categoryCode: this.materialForm.categoryCode,
        innerCode: this.materialForm.innerCode,
        density: this.materialForm.density,
        unit: this.materialForm.unit,
        unitPrice: this.materialForm.unitPrice,
        purchaseSpecification: this.materialForm.purchaseSpecification
      },
      rules: {
        materialCode: [
          {
            required: true,
            message: '请输入材料牌号',
            trigger: 'blur'
          }
        ],
        materialName: [
          {
            required: true,
            message: '请输入材料名称',
            trigger: 'blur'
          }
        ],
        categoryCode: [
          {
            required: true,
            message: '请输入材料类别',
            trigger: 'change'
          }
        ]
      },
      currentType: []
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function() {
      return this.modalTitle === '新增材料'
    },
    isUpdate: function() {
      return this.modalTitle === '更新材料'
    }
  },
  mounted() {
    this.handleGetMaterialType()
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleGetMaterialType() {
      getAllParametersByParentCode('RT_MC').then(res => {
        if (res.data) {
          this.currentType = res.data
        }
      })
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.addMaterial(this.material)
          } else if (this.isUpdate) {
            this.updateMaterial(this.material)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updateMaterial(materialFormData) {
      this.loading = true
      updateMaterial(materialFormData.id, materialFormData)
        .then(res => {
          if (res.status === 200) {
            const successMessage = '更新材料成功！'
            this.$emit('confirm')
            this.$message.success(successMessage)
          } else {
            const failMessage = '更新材料失败，请重试！'
            this.$message.error(failMessage)
          }
        })
        .catch(
          // 工序失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    addMaterial(materialFormData) {
      this.loading = true
      const submitData = R.clone(materialFormData)
      submitData.id = null
      addMaterial(submitData)
        .then(res => {
          if (res.status === 201) {
            this.$emit('confirm')
            this.$message.success('新增材料成功')
          } else {
            this.$message.error('新增材料失败，请重试！')
          }
        })
        .catch(
          // 工序失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    }
  }
}
</script>

<style scoped>
</style>
