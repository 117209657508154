var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.title,
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleCancel } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "list" },
        [
          _vm.list.length
            ? _c(
                "a-timeline",
                _vm._l(_vm.list, function (item) {
                  return _c("a-timeline-item", { key: item.id }, [
                    _c("p", [
                      _vm._v(
                        "客户要求时间: " + _vm._s(item.clientDeliveryTime)
                      ),
                    ]),
                    _c("p", [
                      _vm._v("车间反馈时间: " + _vm._s(item.workshopTime)),
                    ]),
                    _c("p", [
                      _vm._v("最终确认时间: " + _vm._s(item.finalDeliveryTime)),
                    ]),
                    _c("p", [
                      _vm._v("完成情况分析: " + _vm._s(item.negotiateContent)),
                    ]),
                    _c("p", [_vm._v("记录时间: " + _vm._s(item.createTime))]),
                  ])
                }),
                1
              )
            : _c("a-empty"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }