<template>
  <div class="card-container">
    <div id="craft-card-print" class="custom-table">
      <div v-for="(item) in data" :key="index">
        <div style="page-break-after:always">
          <div class="title">
            <div class="left">
              <img src="@/assets/logo.png" alt style="width: 100%; height: 100%" />
            </div>
            <div class="middle">
              <p>重庆市DBR刀具有限公司</p>
              <p>产品对账单</p>
            </div>
          </div>
          <div class="info">
            <div class="client">客户名称: {{ item.client }}</div>
            <div class="settlementNumber">结算单号: {{ item.settlementNumber }}</div>
            <div class="date">挂账期: {{ item.pendingPeriod }}</div>
          </div>
          <a-table :columns="columns" :data-source="item.settleItems" bordered :pagination="false" />
          <div class="bottom-info">
            <div>审核</div>
            <div>制表</div>
            <div>附件: ____ 张</div>
          </div>
        </div>
      </div>
    </div>
    <a-button v-print="'craft-card-print'" type="primary" block>打印</a-button>
  </div>
</template>

<script>
import { getSettlementByIdAll } from '@/api/settlements'
const columns = [
  {
    title: '序号',
    width: 50,
    dataIndex: 'seq',
    key: 'seq',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'seq',
    },
  },
  {
    title: '出库单号',
    width: 50,
    dataIndex: 'takeOutNumber',
    key: 'takeOutNumber',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'takeOutNumber',
    },
  },
  {
    title: '出库日期',
    width: 50,
    dataIndex: 'takeOutTime',
    key: 'takeOutTime',
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务编码',
    width: 100,
    dataIndex: 'taskCode',
    key: 'taskCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'taskCode',
    },
  },
  {
    title: '产品名称',
    width: 100,
    dataIndex: 'productName',
    key: 'productName',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'productName',
    },
  },
  {
    title: '图号',
    width: 100,
    dataIndex: 'drawingNum',
    key: 'drawingNum',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'drawingNum',
    },
  },
  {
    title: '规格',
    width: 100,
    dataIndex: 'specification',
    key: 'specification',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'specification',
    },
  },
  {
    title: '单位',
    width: 50,
    dataIndex: 'unit',
    key: 'unit',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'unit',
    },
  },
  {
    title: '数量',
    width: 50,
    dataIndex: 'waitSettleCount',
    key: 'waitSettleCount',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'waitSettleCount',
    },
  },
  {
    title: '单价(无税)',
    width: 50,
    dataIndex: 'settleUnitPrice',
    key: 'settleUnitPrice',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '金额(无税)',
    width: 50,
    dataIndex: 'totalTaxFreePrice',
    key: 'totalTaxFreePrice',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'totalTaxFreePrice',
    },
  },
  {
    title: '税率',
    width: 50,
    dataIndex: 'settleTaxRate',
    key: 'settleTaxRate',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'taxRate',
    },
  },

  {
    title: '单价(含税)',
    width: 50,
    dataIndex: 'taxPrice',
    key: 'taxPrice',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'taxPrice',
    },
  },
  {
    title: '金额(含税)',
    width: 50,
    dataIndex: 'totalTaxPrice',
    key: 'totalTaxPrice',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'totalTaxPrice',
    },
  },
  {
    title: '备注',
    width: 100,
    key: 'settleComment',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
]
export default {
  data() {
    return {
      columns,
      data: [],
      totalAmount: '',
    }
  },
  created() {
    getSettlementByIdAll({
      ids: this.$route.query.ids,
    }).then((res) => {
      if (res.data) {
        // const list = []
        // if(res.data.length) {
        //   res.data.map(item => {
        //     if(item.settleItems.length) {
        //       list.push(...item.settleItems)
        //     }
        //   })
        // }
        this.data = res.data
        let total = 1
        this.data.forEach((dataItem, dataItemIndex) => {
          dataItem.settleItems = dataItem.settleItems.map((item, index) => {
            const _item = {
              seq: total,
              ...item,
            }
            total++
            return _item
          })

          const sumInfo = {
            takeOutNumber: '合计',
            waitSettleCount: dataItem.sumWaitSettleCount,
            totalTaxFreePrice: dataItem.sumTaxFreePrice,
            totalTaxPrice: dataItem.sumTaxPrice,
          }
          dataItem.settleItems.push(sumInfo)
        })
      } else {
        this.$message.error('获取废品入库单失败，请重试！')
      }
    })
  },
}
</script>
<style scoped lang="scss">
.card-container {
  width: 80%;
  margin: auto;
  margin-top: 100px;
}

.sum {
  width: 100%;
  height: 50px;
  margin-top: -10px;
  border: 1px solid black;
  border-top: none;
}

.title {
  width: 100%;
  height: 80px;
  /* border: 2px solid #000; */
  position: relative;
  color: #000;
  font-weight: bold;
  .left {
    width: 100px;
    height: 50px;
    position: absolute;
    top: 0px;
    left: 100px;
  }
  .middle {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #000;
  }
  .right {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 16px;
  }
}
.info {
  font-weight: bold;
  width: 100%;
  display: flex;
  margin-bottom: -10px;
  font-size: 16px;
  justify-content: space-between;
  color: #000;
}
/deep/ .ant-table {
  padding: 10px 0px;
  color: black;
}

/deep/ .ant-descriptions-item-label {
  color: black;
}

/deep/ .ant-descriptions-item-content {
  color: black;
}

.card-title >>> .ant-descriptions-item-label {
  font-size: 20px;
}
.amount-font {
  font-size: 18px;
  margin: 0;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
  border: 1px solid black;
  text-align: center;
  padding: 1px 8px;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
  border: 1px solid black;
  text-align: center;
  padding: 1px 8px;
}

/deep/ .ant-table-thead th {
  padding: 8px 8px;
  text-align: center;
  border: 1px solid black;
  color: black;
}

/deep/ .ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border: 1px solid black;
}

/deep/ .ant-table-tbody td {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 2px 8px;
  text-align: center;
}

/deep/ .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid black;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-view {
  border: 0px;
}

.footer-form {
  width: 100%;
  height: 100px;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 14px;
  color: #000;
  .top {
    flex: 1;
    border-bottom: 2px solid #000;
    border: 1px solid #000;
    display: flex;
    .top-left {
      flex: 1.17;
    }
    .top-right {
      flex: 1;
      border-left: 2px solid #000;
      display: flex;
      .label {
        flex: 1;
        border-right: 2px solid #000;
        text-align: center;
        line-height: 50px;
      }
      .content {
        flex: 2.1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .bottom {
    flex: 1;
    display: flex;
    align-items: center;
    .middle {
      margin-left: 200px;
    }
    .right {
      margin-left: 480px;
    }
  }
}

.bottom-line {
  width: 100px;
  height: 20px;
  margin-right: 10px;

  display: inline-block;
}
.custom-table {
  margin-bottom: 40px;
}
.bottom-info {
  width: 100%;
  justify-content: space-between;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  /* .gap {
    border-bottom: ;
  } */
}
</style>
