<template>
  <a-modal v-drag-modal class="modal-container" visible="true" :title="title" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="craftForm" :model="craft" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="工序" prop="operationId">
        <a-select
          v-model="choseCraftName"
          label-in-value
          show-search
          placeholder="请选择工序"
          style="width: 300px"
          option-filter-prop="children"
          @change="handleOprationChange"
        >
          <a-select-option
            v-for="operation in currentOperationList"
            :key="operation.id"
            :value="operation.id"
          >{{ operation.operationName }}({{ operation.operationCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="工序名称及内容" prop="content">
        <a-textarea v-model.trim="craft.content" max-length="1000" placeholder="请输入工序名称及内容(最多1000字)" />
      </a-form-model-item>
      <a-form-model-item label="检测工序" prop="testName">
        <a-select
          v-model="craft.testName"
          :disabled="true"
          show-search
          placeholder="请选择工序"
          style="width: 300px"
          option-filter-prop="children"
        >
          <a-select-option
            v-for="operation in currentOperationList"
            :key="operation.id"
            :value="operation.id"
          >{{ operation.operationName }}({{ operation.operationCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="检测工序内容" prop="testDesc">
        <a-textarea v-model.trim="craft.testDesc" maxlength="1000" placeholder="请输入工序名称及内容(最多1000字)" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import * as R from 'ramda'
import { getAllOperations } from '@/api/operation'
export default {
  props: {
    modalType: String,
    craftForm: Object,
  },
  data() {
    return {
      title: '工序管理',
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      loading: false,
      currentOperationList: [],
      choseCraftName: '',
      craft: {
        operationLabel: '',
        operationId: this.modalType === 'add' ? '' : this.craftForm.operationId,
        content: this.modalType === 'add' ? '' : this.craftForm.content,
        testLabel: '检',
        testName: '',
        testDesc: this.modalType === 'add' ? '' : this.craftForm.testDesc,
      },
      rules: {
        operationId: [
          {
            required: true,
            message: '请输入工种',
            trigger: 'blur',
          },
        ],
        content: [
          {
            required: false,
            message: '请输入工序名称及内容',
            trigger: 'blur',
          },
        ],
        testName: [
          {
            required: true,
            message: '请输入工种',
            trigger: 'blur',
          },
        ],
        testDesc: [
          {
            required: false,
            message: '请输入工序名称及内容',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function () {
      return this.modalTitle === '新增'
    },
    isUpdate: function () {
      return this.modalTitle === '更新'
    },
  },
  created() {
    console.log(this.craftForm)
    this.getAllOperations()
    if (this.modalType === 'edit') {
      this.choseCraftName = {
        key: this.craftForm.operationId,
        label: this.craftForm.operationLabel,
      }
    }
  },
  methods: {
    getAllOperations() {
      getAllOperations(this.operationQueryParams).then((res) => {
        if (res.data.operationList) {
          if (R.isEmpty(this.operationQueryParams)) {
            this.allOperationList = R.clone(res.data.operationList)
          }
          this.currentOperationList = R.clone(res.data.operationList)
        }
        this.currentOperationList.map((val) => {
          if (val.operationName === '检') {
            this.craft.testName = val.id
          }
        })
      })
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.craftForm.validate((valid) => {
        if (valid) {
          if (this.modalType === 'edit') {
            if (!this.craft.operationId) {
              this.craft.operationId = this.craftForm.operationId
            }
            if (!this.craft.operationLabel) {
              this.craft.operationLabel = this.craftForm.operationName
            }
            this.craft.sequenceNum = this.craftForm.sequenceNum
            this.$emit('confirm', this.craft)
          } else {
            this.$emit('confirm', this.craft)
          }
        }
      })
    },
    handleOprationChange(value) {
      console.log(value)
      this.craft.operationLabel = value.label.split('(')[0]
      this.craft.operationId = value.key
    },
  },
}
</script>

<style scoped>
</style>
