import request from '@/utils/api.request'

/**
 * 分页获取急件任务信息
 * @param {object} data
 */
export function getEmgTasks(emgType, data) {
  return request({
    url: `/tasks/exigences/${emgType}`,
    method: 'get',
    params: data
  })
}

/**
 * 导出急件任务
 * @param {*} data
 */
 export function exportEmgTasks(emgType, data) {
    return request({
      url: `/tasks/exigences/${emgType}/exports`,
      method: 'get',
      responseType: 'blob',
      params: data
    })
  }


/**
 * 新增任务交货时间协商记录
 * @param {number} id
 */
export function addNegotiates(taskId, data) {
	return request({
		url: `/tasks/${taskId}/negotiates`,
		method: 'post',
		data
	})
}


/**
 * 更新任务交货时间协商记录
 * @param {number} id
 * @param {object} data
 */
 export function updateNegotiates(id, data) {
  return request({
    url: `/tasks/negotiates/${id}`,
    method: 'put',
    data
  })
}

/**
 * 获取任务交货时间协商记录
 * @param {object} data
 */
 export function getNegotiates(taskId) {
  return request({
    url: `/tasks/${taskId}/negotiates`,
    method: 'get',
  })
}

/**
 * 获取最新协商记录
 * @param {object} data
 */
 export function getLatestNegotiates(taskId) {
  return request({
    url: `/tasks/${taskId}/latest-negotiates`,
    method: 'get',
  })
}

