export const monthColumns = [
  {
    title: '任务编码',
    width: 150,
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '计划类型',
    width: 150,
    dataIndex: 'productType',
    key: 'product.type',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'productType',
    },
    filters: [
      {
        text: 'L计划',
        value: 'L',
      },
      {
        text: 'D计划',
        value: 'D',
      },
      {
        text: 'G计划',
        value: 'G',
      },
    ],
  },
  {
    title: '是否分件',
    width: 150,
    dataIndex: 'isParts',
    key: 'plan.isParts',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'isParts',
    },
    filters: [
      {
        text: '是',
        value: 1,
      },
      {
        text: '否',
        value: 0,
      },
    ],
  },
  {
    title: '紧急类型',
    width: 150,
    dataIndex: 'emergencyType',
    key: 'emergencyType',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'emergencyType',
    },
    filters: [
      {
        text: '普通',
        value: 'NML',
      },
      {
        text: '必完件',
        value: 'MFN',
      },
      {
        text: '紧急件',
        value: 'UGC',
      },
      {
        text: '插入急件',
        value: 'IUG',
      },
      {
        text: '转入急件',
        value: 'TUG',
      },
    ],
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    width: 150,
    key: 'task.status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '进行中',
        value: 2,
      },
      {
        text: '已完成',
        value: 3,
      },
      {
        text: '超期进行中',
        value: 4,
      },
      {
        text: '即将超期',
        value: 5,
      },
      {
        text: '暂停中',
        value: 9,
      },
      {
        text: '已取消',
        value: 8,
      },
      {
        text: '异常终止',
        value: 10,
      },
    ],
  },
  {
    title: '产品名称',
    width: 150,
    dataIndex: 'productName',
    key: 'product.productName',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户名称',
    width: 150,
    dataIndex: 'client',
    key: 'client',
    align: 'center',
    searchType: 'text',
    sorter: true,
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '在产数量',
    width: 150,
    dataIndex: 'producingNum',
    key: 'producingNum',
    align: 'center',
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '交货时间',
    dataIndex: 'deliverTime',
    key: 'deliverTime',
    width: 150,
    align: 'center',
    searchType: 'date',
    ellipsis: true,
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务实时位置',
    dataIndex: 'latestOperationName',
    key: 'task.latestOperationName',
    width: 150,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '未完成工序',
    key: 'process',
    width: 8000,
    scopedSlots: {
      customRender: 'process',
    },
  },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    fixed: 'right',
    width: 80,
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

export const targetRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({
    props: {
      disabled: record.status !== 2&&record.status !== 4&&record.status !== 5,
    },
  }),
}
