var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "a-button",
        {
          staticClass: "batch-button",
          attrs: { type: "primary", loading: _vm.searchLoading },
          on: {
            click: function ($event) {
              return _vm.handlerAddBatchRecord()
            },
          },
        },
        [_vm._v("批量录入")]
      ),
      _c("a-input-search", {
        ref: "search",
        staticClass: "input-search",
        attrs: {
          size: "large",
          "max-length": 20,
          "allow-clear": true,
          placeholder: "请输入任务编码或扫描二维码查询任务",
          loading: _vm.searchLoading,
          "enter-button": "搜索",
        },
        on: { search: _vm.onSearch },
        model: {
          value: _vm.searchTaskCode,
          callback: function ($$v) {
            _vm.searchTaskCode = $$v
          },
          expression: "searchTaskCode",
        },
      }),
      _c("a-table", {
        staticClass: "tasks-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          columns: _vm.taskColumns,
          "data-source": _vm.taskList,
          loading: _vm.loading,
          scroll: { x: 2700, y: 650 },
          pagination: _vm.pagination,
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "type",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0 ? [_c("span", [_vm._v("普通任务")])] : _vm._e(),
                  text === 1 ? [_c("span", [_vm._v("返修任务")])] : _vm._e(),
                  text === 2
                    ? [_c("span", [_vm._v("生产补投任务")])]
                    : _vm._e(),
                  text === 3
                    ? [_c("span", [_vm._v("退库补投任务")])]
                    : _vm._e(),
                  text === 4 ? [_c("span", [_vm._v("分批任务")])] : _vm._e(),
                  text === 5 ? [_c("span", [_vm._v("其他任务")])] : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("准备中 "),
                      ]
                    : _vm._e(),
                  text === 1
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("未开始 "),
                      ]
                    : _vm._e(),
                  text === 2
                    ? [
                        _c("a-badge", { attrs: { status: "processing" } }),
                        _vm._v("进行中 "),
                      ]
                    : _vm._e(),
                  text === 3
                    ? [
                        _c("a-badge", { attrs: { status: "success" } }),
                        _vm._v("正常完成 "),
                      ]
                    : _vm._e(),
                  text === 4
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("超期进行中 "),
                      ]
                    : _vm._e(),
                  text === 5
                    ? [
                        _c("a-badge", { attrs: { status: "warning" } }),
                        _vm._v("即将超期 "),
                      ]
                    : _vm._e(),
                  text === 6
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("超期完成 "),
                      ]
                    : _vm._e(),
                  text === 8
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("已取消 "),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "operation",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-operation" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handlerAddRecord(column)
                        },
                      },
                    },
                    [_vm._v("生产数据录入")]
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
      _vm.editModalVisible
        ? _c("RecordEdit", {
            attrs: {
              "modal-title": _vm.currentEditType,
              "record-form": _vm.currentRecord,
            },
            on: {
              cancel: _vm.handleRecordModalCanceled,
              confirm: _vm.handleRecordModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.batchEditModalVisible
        ? _c("BatchRecordEdit", {
            on: {
              cancel: _vm.handleBatchRecordModalCanceled,
              confirm: _vm.handleBatchRecordModalConfirmed,
            },
          })
        : _vm._e(),
      _c("CertificatesEdit", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.certificatesEditVisible,
            expression: "certificatesEditVisible",
          },
        ],
        attrs: { id: _vm.editCertificateTaskId },
        on: {
          cancel: _vm.handleCertificatesModalCanceled,
          confirm: _vm.handleCertificatesModalConfirmed,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }