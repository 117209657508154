import request from '@/utils/api.request'

/**
 * 分页获取模板信息
 * @param {object} data
 */
export function getTemplates(data) {
  return request({
    url: `/crafts/operation-cards/templates`,
    method: 'get',
    params: data
  })
}

/**
 * 根据查询参数获取所有模板信息
 * @param {object} data
 */
export function getAllTemplates(data) {
  return request({
    url: `/crafts/operation-cards/templates/all`,
    method: 'get',
    params: data
  })
}

/**
 * 根据id获取指定的模板信息
 * @param {number} id
 */
export function getTemplateById(id) {
  return request({
    url: `/crafts/operation-cards/templates/${id}`,
    method: 'get'
  })
}

/**
 * 增加模板
 * @param {object} data
 */
export function addTemplates(data) {
  return request({
    url: `/crafts/operation-cards/templates`,
    method: 'post',
    data
  })
}

/**
 * 导入模板加工路线
 * @param {object} data
 */
export function importTemplateOperationRoute(templateId, data) {
  return request({
    url: `/crafts/operation-cards/templates/${templateId}/process-route/imports`,
    method: 'post',
    data
  })
}

/**
 * 更新指定id的模板
 * @param {number} id
 * @param {object} data
 */
export function updateTemplate(id, data) {
  return request({
    url: `/crafts/operation-cards/templates/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除指定id的模板
 * @param {number} id
 */
export function deleteTemplate(id) {
  return request({
    url: `/crafts/operation-cards/templates/${id}`,
    method: 'delete'
  })
}

/**
 * 删除指定id的模板工艺路线
 * @param {number} templateId
 */
export function deleteTemplateOperationRoute(templateId) {
  return request({
    url: `/crafts/operation-cards/templates/${templateId}/process-route`,
    method: 'delete'
  })
}

/**
 * 获取材料信息导入模板
 */
export function getCardTemplateImportTemplate() {
  return request({
    url: `/crafts/operation-cards/templates/import-template`,
    method: 'get',
    responseType: 'blob'
  })
}


export function updateTemplateType(data) {
  return request({
    url: `/crafts/operation-cards/templates/types`,
    method: 'patch',
    data,
  })
}