export const taskColumns = [
  {
    title: '计划编码',
    dataIndex: 'planCode',
    key: 'plan.code',
    fixed: 'left',
    align: 'center',
    searchType: 'text',
    sorter: true,
    width: 150,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务编码',
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
    align: 'center',
    searchType: 'text',
    sorter: true,
    width: 150,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务类型',
    dataIndex: 'type',
    width: 150,
    key: 'type',
    align: 'center',
    scopedSlots: {
      customRender: 'type',
    },
    filters: [
      {
        text: '普通任务',
        value: 0,
      },
      {
        text: '返修任务',
        value: 1,
      },
      {
        text: '生产补投任务',
        value: 2,
      },
      {
        text: '协作任务',
        value: 3,
      },
      {
        text: '普通补投任务',
        value: 4,
      },
      {
        text: '库存转入任务',
        value: 5,
      },
    ],
  },
  {
    title: '紧急类型',
    dataIndex: 'emgType',
    key: 'emgType',
    width: 150,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'emgType',
    },
    filters: [
      {
        text: '普通',
        value: 'NML',
      },
      {
        text: '必完件',
        value: 'MFN',
      },
      {
        text: '紧急件',
        value: 'UGC',
      },
      {
        text: '插入急件',
        value: 'IUG',
      },
      {
        text: '转入急件',
        value: 'TUG',
      },
    ],
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    width: 200,
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '进行中',
        value: 2,
      },
      {
        text: '已完成',
        value: 3,
      },
      {
        text: '超期进行中',
        value: 4,
      },
      {
        text: '即将超期',
        value: 5,
      },
      {
        text: '暂停中',
        value: 9,
      },
      {
        text: '已取消',
        value: 8,
      },
      {
        text: '异常终止',
        value: 10,
      },
    ],
  },
  {
    title: '计划启动日期',
    dataIndex: 'startTime',
    key: 'plan.startTime',
    searchType: 'date',
    align: 'center',
    ellipsis: true,
    sorter: true,
    width: 200,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务启动日期',
    dataIndex: 'createTime',
    key: 'task.createTime',
    searchType: 'date',
    align: 'center',
    ellipsis: true,
    sorter: true,
    width: 200,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务截止日期',
    dataIndex: 'taskDeliveryTime',
    key: 'task.taskDeliveryTime',
    searchType: 'date',
    width: 150,
    align: 'center',
    sorter: true,
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '超期天数',
    dataIndex: 'overdueDay',
    key: 'overdueDay',
    width: 150,
    sorter: true,
    ellipsis: true,
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '交期紧急指数',
    dataIndex: 'deliveryProgress',
    key: 'deliveryProgress',
    width: 150,
    ellipsis: true,
    sorter: true,
    align: 'center',
  },
  {
    title: '交期紧急类别',
    dataIndex: 'deliveryStatus',
    key: 'deliveryStatus',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'deliveryStatus',
    },
    filters: [
      {
        text: '绿单',
        value: 'GREEN',
      },
      {
        text: '黄单',
        value: 'YELLOW',
      },
      {
        text: '红单',
        value: 'RED',
      },
      {
        text: '黑单',
        value: 'BLACK',
      },
    ],
  },
  {
    title: '订单编号',
    dataIndex: 'orderCode',
    key: 'productOrder.orderCode',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '合同签订日期',
    dataIndex: 'signTime',
    key: 'signTime',
    width: 200,
    ellipsis: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '合同交货期',
    dataIndex: 'deadline',
    key: 'deadline',
    width: 200,
    ellipsis: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '实发材料',
    dataIndex: 'actualUseMaterialList',
    key: 'actualUseMaterialList',
    width: 200,
    ellipsis: false,
    align: 'center',
    scopedSlots: {
      customRender: 'actualUseMaterialList',
    },
  },
  {
    title: '当前位置',
    dataIndex: 'latestOperationName',
    key: 'latestOperationName',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '当前班组',
    dataIndex: 'currentGroupName',
    key: 'teamGroup.id',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'teamGroup',
    },
  },
  {
    title: '停留时间(小时)',
    dataIndex: 'stayTime',
    key: 'stayTime',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '通过瓶颈标识',
    dataIndex: 'bottleneckFlag',
    ellipsis: true,
    width: 150,
    key: 'bottleneckFlag',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '进入瓶颈时间',
    dataIndex: 'bottleneckTime',
    key: 'bottleneckTime',
    width: 200,
    ellipsis: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  
  {
    title: '客户名称',
    dataIndex: 'client',
    ellipsis: true,
    width: 150,
    key: 'productOrder.client',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    key: 'product.productName',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '图号',
    dataIndex: 'drawingNum',
    key: 'product.drawingNum',
    ellipsis: true,
    width: 150,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    sorter: true,
  },
  {
    title: '规格',
    dataIndex: 'specification',
    key: 'product.specification',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    sorter: true,
  },
  {
    title: '投产数量',
    dataIndex: 'productionNum',
    width: 150,
    key: 'productionNum',
    searchType: 'number',
    align: 'center',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '入库数量',
    dataIndex: 'putInNum',
    width: 150,
    key: 'putInNum',
    searchType: 'number',
    align: 'center',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '在产数量',
    dataIndex: 'producingNum',
    width: 150,
    key: 'task.producingNum',
    searchType: 'number',
    align: 'center',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '期望下料时间',
    dataIndex: 'expectMaterialTime',
    key: 'task.expectMaterialTime',
    searchType: 'date',
    width: 150,
    align: 'center',
    sorter: true,
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '实际下料时间',
    dataIndex: 'feedMaterialTime',
    key: 'task.feedMaterialTime',
    searchType: 'date',
    width: 150,
    align: 'center',
    sorter: true,
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '完成日期',
    dataIndex: 'finishTime',
    key: 'task.finishTime',
    searchType: 'date',
    width: 150,
    align: 'center',
    sorter: true,
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '出库数量',
    dataIndex: 'takeOutNum',
    key: 'takeOutNum',
    width: 150,
    searchType: 'number',
    align: 'center',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '出库日期',
    dataIndex: 'takeOutTime',
    key: 'task.takeOutTime',
    width: 150,
    searchType: 'date',
    align: 'center',
    sorter: true,
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '报废数量',
    dataIndex: 'abandonNum',
    key: 'abandonNum',
    width: 150,
    searchType: 'number',
    align: 'center',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '退货数量',
    dataIndex: 'returnedNum',
    key: 'returnedNum',
    width: 150,
    searchType: 'number',
    align: 'center',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '返修数量',
    dataIndex: 'repairedNum',
    key: 'repairedNum',
    searchType: 'number',
    width: 150,
    align: 'center',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '退货时间',
    dataIndex: 'returnedTime',
    key: 'task.returnedTime',
    width: 150,
    searchType: 'date',
    align: 'center',
    ellipsis: true,
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '超差数量',
    dataIndex: 'outOfToleranceNum',
    key: 'outOfToleranceNum',
    width: 150,
    searchType: 'number',
    align: 'center',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务备注',
    dataIndex: 'comment',
    key: 'task.comment',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    align: 'center',
    width: 80,
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
