var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c(
        "div",
        { staticClass: "custom-table", attrs: { id: "craft-card-print" } },
        _vm._l(_vm.data, function (item) {
          return _c("div", { key: _vm.index }, [
            _c(
              "div",
              { staticStyle: { "page-break-after": "always" } },
              [
                _vm._m(0, true),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "client" }, [
                    _vm._v("客户名称: " + _vm._s(item.client)),
                  ]),
                  _c("div", { staticClass: "settlementNumber" }, [
                    _vm._v("结算单号: " + _vm._s(item.settlementNumber)),
                  ]),
                  _c("div", { staticClass: "date" }, [
                    _vm._v("挂账期: " + _vm._s(item.pendingPeriod)),
                  ]),
                ]),
                _c("a-table", {
                  attrs: {
                    columns: _vm.columns,
                    "data-source": item.settleItems,
                    bordered: "",
                    pagination: false,
                  },
                }),
                _vm._m(1, true),
              ],
              1
            ),
          ])
        }),
        0
      ),
      _c(
        "a-button",
        {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: "craft-card-print",
              expression: "'craft-card-print'",
            },
          ],
          attrs: { type: "primary", block: "" },
        },
        [_vm._v("打印")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "left" }, [
        _c("img", {
          staticStyle: { width: "100%", height: "100%" },
          attrs: { src: require("@/assets/logo.png"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "middle" }, [
        _c("p", [_vm._v("重庆市DBR刀具有限公司")]),
        _c("p", [_vm._v("产品对账单")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom-info" }, [
      _c("div", [_vm._v("审核")]),
      _c("div", [_vm._v("制表")]),
      _c("div", [_vm._v("附件: ____ 张")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }