var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 2 } },
            [
              _c(
                "c-upload",
                {
                  attrs: {
                    name: "file",
                    accept: ".xlsx",
                    "show-upload-list": false,
                    action: _vm.uploadUrl + "inquiries/imports",
                  },
                  on: { change: _vm.handleFileChange },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "header-button",
                      attrs: {
                        slot: "content",
                        type: "primary",
                        loading: _vm.uploading,
                        icon: "el-icon-circle-plus-outline",
                      },
                      slot: "content",
                    },
                    [_vm._v("导入询价单")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "header-button",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline",
                  },
                  on: { click: _vm.handleAddInquirys },
                },
                [_vm._v("新增询价单")]
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 3, offset: 17 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-download" },
                  on: { click: _vm.handleDownloadTemplate },
                },
                [_vm._v("点击下载询价单导入模板")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        staticClass: "users-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          columns: _vm.inquiryColumns,
          "data-source": _vm.inquiryList,
          loading: _vm.loading,
          pagination: _vm.pagination,
          scroll: { x: 1500 },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.dataIndex
                              )
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                  column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "190px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.dataIndex
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.dataIndex
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "code",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCheckDetail(text)
                        },
                      },
                    },
                    [_vm._v(_vm._s(text))]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === "WCA"
                    ? [
                        _c("a-tag", { attrs: { color: "blue" } }, [
                          _vm._v("待核价"),
                        ]),
                      ]
                    : _vm._e(),
                  text === "CAC"
                    ? [
                        _c("a-tag", { attrs: { color: "orange" } }, [
                          _vm._v("核价中"),
                        ]),
                      ]
                    : _vm._e(),
                  text === "CNL"
                    ? [
                        _c("a-tag", { attrs: { color: "red" } }, [
                          _vm._v("已取消"),
                        ]),
                      ]
                    : _vm._e(),
                  text === "CCK"
                    ? [
                        _c("a-tag", { attrs: { color: "yellow" } }, [
                          _vm._v("审价中"),
                        ]),
                      ]
                    : _vm._e(),
                  text === "QUT"
                    ? [
                        _c("a-tag", { attrs: { color: "cyan" } }, [
                          _vm._v("待报出"),
                        ]),
                      ]
                    : _vm._e(),
                  text === "PDL"
                    ? [
                        _c("a-tag", { attrs: { color: "lime" } }, [
                          _vm._v("部分成交"),
                        ]),
                      ]
                    : _vm._e(),
                  text === "ADL"
                    ? [
                        _c("a-tag", { attrs: { color: "green" } }, [
                          _vm._v("全部成交"),
                        ]),
                      ]
                    : _vm._e(),
                  text === "BGN"
                    ? [
                        _c("a-tag", { attrs: { color: "gold" } }, [
                          _vm._v("已报出"),
                        ]),
                      ]
                    : _vm._e(),
                  text === "DNL"
                    ? [
                        _c("a-tag", { attrs: { color: "red" } }, [
                          _vm._v("未成交"),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "waitCostAccountingCount",
            fn: function (text, column) {
              return _c(
                "div",
                {},
                [
                  text > 0
                    ? [
                        _c(
                          "a-badge",
                          { attrs: { count: text } },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: _vm.small,
                                  loading: _vm.startAccountLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleStartCostAccount(
                                      column.code
                                    )
                                  },
                                },
                              },
                              [_vm._v("点击全部开始")]
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c("a-tag", { attrs: { color: "green" } }, [
                          _vm._v("已全部启动"),
                        ]),
                      ],
                ],
                2
              )
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "operate",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-user" },
                [
                  column.status === "CAC" || column.status === "WCA"
                    ? _c(
                        "a-dropdown",
                        [
                          _c(
                            "a-menu",
                            {
                              staticClass: "ant-dropdown-link",
                              attrs: { slot: "overlay" },
                              slot: "overlay",
                            },
                            [
                              column.status === "WCA" || column.status === "CAC"
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEditInquiry(column)
                                        },
                                      },
                                    },
                                    [_vm._v("更新询价单")]
                                  )
                                : _vm._e(),
                              column.status === "CAC"
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCheckProduct(
                                            column.code
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("提交审批")]
                                  )
                                : _vm._e(),
                              _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCancelInquiry(column)
                                    },
                                  },
                                },
                                [_vm._v("取消询价单")]
                              ),
                              column.status === "WCA"
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeleteInquiry(column)
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("删除询价单")])]
                                  )
                                : _vm._e(),
                              column.status === "WCA" || column.status === "CAC"
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleAddInquirysProduct(
                                            column.code
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("添加产品")]
                                  )
                                : _vm._e(),
                              column.waitCostAccountingCount > 0
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleStartCostAccount(
                                            column.code
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("开始核价")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "a",
                            { staticClass: "user-btn" },
                            [
                              _vm._v(" 操作 "),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c(
        "el-dropdown",
        {
          staticClass: "export-record",
          on: { command: _vm.handleExportRecordCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出询价单记录")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选记录"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.inquirysModalVisible
        ? _c("CreateInquirysModal", {
            attrs: { record: _vm.editInquiry, type: _vm.editInquiryType },
            on: {
              cancel: _vm.handleAddInquirysCancel,
              confirm: _vm.handleAddInquirysConfirm,
            },
          })
        : _vm._e(),
      _vm.inquirysProductModalVisible
        ? _c("CreateInquiryProductModal", {
            attrs: {
              inquiryCode: _vm.inquiryCode,
              editRecord: "{}",
              type: "add",
            },
            on: {
              cancel: _vm.handleAddInquirysProductCancel,
              confirm: _vm.handleAddInquirysProductConfirm,
            },
          })
        : _vm._e(),
      _vm.startCostAccountModalVisible
        ? _c("StartCostAccountModal", {
            attrs: { code: _vm.inquiryCode },
            on: {
              cancel: _vm.handleAccountStartCancel,
              confirm: _vm.handleAccountStartConfirm,
            },
          })
        : _vm._e(),
      _vm.productLibraryDetailModalVisible
        ? _c("ProductLibraryDetailModal", {
            attrs: { code: _vm.productDetailCode },
            on: {
              refresh: _vm.getInquirysByParams,
              cancel: _vm.handleProductDetailCancel,
              confirm: _vm.handleProductDetailConfirm,
            },
          })
        : _vm._e(),
      _vm.cancelModalVisible
        ? _c("InquiryCancelModal", {
            attrs: { recordsData: _vm.currentInquiry },
            on: {
              confirm: _vm.handleCancelInquiryConfirm,
              cancel: _vm.handleCancelInquiryCancel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }