var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: "选择模板",
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.template,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "模板", prop: "id" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    "show-search": "",
                    placeholder: "请输入模板编码",
                    "option-filter-prop": "children",
                  },
                  on: { search: _vm.fetchTemplateByCode },
                  model: {
                    value: _vm.template.id,
                    callback: function ($$v) {
                      _vm.$set(_vm.template, "id", $$v)
                    },
                    expression: "template.id",
                  },
                },
                _vm._l(_vm.currentTemplateList, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.templateId, attrs: { value: item.templateId } },
                    [_vm._v(_vm._s(item.templateCode))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }