<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" title="外购明细" :mask-closable="false" width="800px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>

    <a-form-model ref="ruleForm" class="cost-form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="是否全外购" :labelCol="{span: 8}" style="margin-left: 89px;" prop="isAll">
        <a-radio-group v-model="ifAll" @change="handleIsAllChange">
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="产品名称" :labelCol="{span: 8}" style="margin-left: 89px;" prop="productInfo.name">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.productInfo.name" placeholder="请输入材料名称" />
      </a-form-model-item>
      <a-form-model-item label="图号" :labelCol="{span: 8}" style="margin-left: 89px;" prop="productInfo.drawingNum">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.productInfo.drawingNum" placeholder="请输入图号" />
      </a-form-model-item>
      <a-form-model-item label="规格" :labelCol="{span: 8}" style="margin-left: 89px;" prop="productInfo.specification">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.productInfo.specification" placeholder="请输入规格" />
      </a-form-model-item>
      <a-form-model-item label="单位" :labelCol="{span: 8}" style="margin-left: 89px;" prop="productInfo.unit">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.productInfo.unit" placeholder="请输入单位" />
      </a-form-model-item>
      <a-form-model-item label="供应商名称" :labelCol="{span: 8}" style="margin-left: 89px;" prop="companyInfo.name">
        <a-select
          v-model="form.companyInfo.name"
          style="width: 300px"
          show-search
          :default-active-first-option="false"
          placeholder="请输入供应商名称"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @search="fetchTeantsByName"
          @change="(value, option)  => handleTenantsChange(value, option)"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="ten in tenants" :key="ten.name">{{ ten.name }}({{ ten.code }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="单价" :labelCol="{span: 8}" style="margin-left: 89px;" prop="unitPrice">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.unitPrice" placeholder="请输入单价" />
      </a-form-model-item>
      <a-form-model-item label="数量" :labelCol="{span: 8}" style="margin-left: 89px;" prop="quantity">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.quantity" placeholder="请输入数量" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { getAllSuppliers } from '@/api/suppliers'
import * as R from 'ramda'
import debounce from 'lodash/debounce'
export default {
  props: {
    record: Object,
    originRecord: Object
  },
  data() {
    this.fetchTeantsByName = debounce(this.fetchTenantsByName, 800)

    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      ifAll: false,
      form: this.record || {
        sequence: '',
        productInfo: {
          code: '',
          name: '',
          drawingNum: '',
          specification: '',
          unit: ''
        },
        companyInfo: {
          name: '',
          code: '',
          nickName: ''
        },
        unitPrice: '',
        quantity: ''
      },
      rules: {
        'productInfo.name': [
          {
            required: true,
            message: '请输入产品名称',
            trigger: 'blur'
          }
        ],
        'productInfo.drawingNum': [
          {
            required: true,
            message: '请输入图号',
            trigger: 'blur'
          }
        ],
        'productInfo.unit': [
          {
            required: true,
            message: '请输入单位',
            trigger: 'blur'
          }
        ],
        'productInfo.specification': [
          {
            required: true,
            message: '请输入规格',
            trigger: 'blur'
          }
        ],
        'companyInfo.name': [
          {
            required: true,
            message: '请输入供应商名称',
            trigger: 'blur'
          }
        ],
        unitPrice: [
          {
            required: true,
            message: '请输入单价',
            trigger: 'blur'
          }
        ],
        quantity: [
          {
            required: true,
            message: '请输入数量',
            trigger: 'blur'
          }
        ]
      },
      tenants: [],
      fetching: false,
      lastFetchId: 0
    }
  },
  computed: {
    // 计算属性的 getter
    isUpdate: function() {
      return this.record
    },
    isAdd: function() {
      return !this.record
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.$emit('add', this.form)
          } else {
            this.$emit('update', this.form)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleIsAllChange(e) {
      if (this.ifAll) {
        this.form.productInfo.name = this.originRecord.inquiryItem.productInfo.name
        this.form.productInfo.drawingNum = this.originRecord.inquiryItem.productInfo.drawingNum
        this.form.productInfo.specification = this.originRecord.inquiryItem.productInfo.specification
        this.form.productInfo.unit = this.originRecord.inquiryItem.productInfo.unit
        this.form.quantity = this.originRecord.inquiryItem.quantity
      } else {
        this.form.productInfo.name = ''
        this.form.productInfo.drawingNum = ''
        this.form.productInfo.specification = ''
        this.form.productInfo.unit = ''
        this.form.quantity = ''
      }
    },
    fetchTenantsByName(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.tenants = []
      this.fetching = true
      getAllSuppliers({
        name: 'like:' + value
      })
        .then(result => {
          if (fetchId !== this.lastFetchId) {
            return
          }
          this.tenants = result.data
          this.fetching = false
        })
        .finally(() => {
          this.fetching = false
        })
    },
    handleTenantsChange(value, option) {
      this.tenants.forEach(item => {
        if (item.name === value) {
          this.form.companyInfo.name = value
          this.form.companyInfo.code = item.code
          this.form.companyInfo.nickName = item.nickName
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
