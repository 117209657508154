export const productColumns = [
  {
    title: '序号',
    width: 100,
    dataIndex: 'index',
    key: 'index',
    align: 'center',
  },
  {
    title: '产品名称',
    width: 200,
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'name',
    },
  },
  {
    title: '产品图号',
    dataIndex: 'drawingNum',
    key: 'drawingNum',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'drawingNum',
    },
  },
  {
    title: '产品规格',
    width: 200,
    dataIndex: 'specification',
    key: 'specification',
    searchType: 'date',
    sorter: false,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'specification',
    },
  },
  {
    title: '单位',
    dataIndex: 'unit',
    key: 'unit',
    searchType: 'date',
    sorter: false,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '操作',
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
