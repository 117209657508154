<template>
  <div class="app-container">
    <a-row>
      <a-col :span="2">
        <el-button class="header-button" type="primary" icon="el-icon-circle-plus-outline" @click="handleAddGroup">新增班组</el-button>
      </a-col>
    </a-row>
    <a-table
      row-key="id"
      bordered
      class="users-table"
      :columns="groupColumns"
      :data-source="groupList"
      :loading="loading"
      :pagination="pagination"
      :scroll="{y: 590 }"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>
      <span slot="leaderIds" slot-scope="text,column">
        <a-tag v-for="leader in column.groupLeaders" :key="leader.id">{{ leader.realName }}</a-tag>
      </span>
      <span slot="memberIds" slot-scope="text,column">
        <a-tag v-for="member in column.groupMembers" :key="member.id">{{ member.realName }}</a-tag>
      </span>
      <span slot="operationIds" slot-scope="text,column">
        <a-tag v-for="operation in column.groupOperations" :key="operation.id">{{ operation.operationName }}</a-tag>
      </span>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operate" slot-scope="text, column" class="table-user">
        <a-dropdown v-if="column.status!==2">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleUpdateGroup(column)">更新班组</a-menu-item>
            <a-menu-item @click="handleDeleteGroup(column)">删除班组</a-menu-item>
          </a-menu>
          <a class="user-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <GroupEdit
      v-if="editGroupModalVisible"
      :group-form="currentGroup"
      :modal-title="currentEditType"
      @cancel="handlGroupModalCanceled"
      @confirm="handleGroupModalConfirmed"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { groupColumns } from './dataLogic'
import GroupEdit from '@/components/GroupEdit'
import { getGroups, deleteGroup } from '@/api/group'

export default {
  components: {
    GroupEdit
  },
  data() {
    return {
      loading: false,
      uploading: false,
      groupList: [],
      groupColumns,
      selectedRowKeys: [],
      searchInput: null,
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initUserListQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentUserListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentEditType: '',
      currentGroup: {},
      editGroupModalVisible: false,
      columnSearchParams: {}
    }
  },
  created() {
    this.getGroups(this.initUserListQueryParams)
  },
  methods: {
    getGroups(data) {
      this.loading = true
      getGroups(data)
        .then(res => {
          this.groupList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getGroupsByParams() {
      const cQueryParams = deepClone(this.currentUserListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getGroups(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('sex')) {
        delete this.columnSearchParams['sex']
        if (filters['sex'].length > 0) {
          this.columnSearchParams['sex'] = `in:${filters['sex'].toString()}`
        } else {
          delete this.columnSearchParams['sex']
        }
      }
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['user.status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['user.status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['user.status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentUserListQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentUserListQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentUserListQueryParams.pageNum = pagination.current
      this.currentUserListQueryParams.pageSize = pagination.pageSize
      this.getGroupsByParams()
    },
    // 删除班组
    handleDeleteGroup(column) {
      this.$confirm({
        content: '确认删除班组?',
        onOk: () => {
          deleteGroup(column.id).then(() => {
            this.$message.success('删除成功！')
            this.getGroupsByParams()
          })
        }
      })
    },
    handleAddGroup() {
      this.currentEditType = '新增'
      this.currentGroup = {}
      this.editGroupModalVisible = true
    },
    // 更新员工
    handleUpdateGroup(column) {
      this.currentEditType = '更新'
      this.currentGroup = deepClone(column)
      this.editGroupModalVisible = true
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      if (dataIndex === 'roles') {
        dataIndex = 'role.name'
      }
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleGroupModalConfirmed() {
      this.editGroupModalVisible = false
      this.getGroupsByParams()
    },
    handlGroupModalCanceled() {
      this.editGroupModalVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
}
</style>
