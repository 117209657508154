<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    :visible="true"
    :title="isAdd ? '新增询价单' : '更新询价单'"
    :maskClosable="false"
    @cancel="handleCancel"
    width="800px"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model class="inquiry-form" ref="ruleForm" :model="inquiry" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="客户名称" prop="clientName">
        <c-select
          v-model.trim="inquiry.clientName"
          placeholder="请输入客户名称"
          :getList="getAllTenants"
          searchKey="name"
          :searchFormat="(value) => { return { name: 'like:' + value  } }"
          :showLabel="(item => { return `${item.name}(${item.code})` })"
          :onChange="(value, option) =>  onChange(value, option) "
        />
        <!-- <a-input style="width: 300px" :maxLength="50" v-model.trim="inquiry.clientName" placeholder="请输入客户名称" /> -->
      </a-form-model-item>
      <a-form-model-item label="客户询价单号" prop="clientInquiryCode">
        <a-input style="width: 300px" :maxLength="50" v-model.trim="inquiry.clientInquiryCode" placeholder="请输入客户询价单号" />
      </a-form-model-item>
      <a-form-model-item label="询价时间" prop="queryTime">
        <a-date-picker v-model.trim="inquiry.queryTime" show-time value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择询价时间" />
      </a-form-model-item>
      <a-form-model-item label="截止时间" prop="expirationTime">
        <a-date-picker v-model.trim="inquiry.expirationTime" show-time value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择截止时间" />
      </a-form-model-item>
      <a-form-model-item label="特殊情况说明" prop="remark">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="inquiry.remark" placeholder="请输入特殊情况说明" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="comment">
        <a-textarea style="width: 300px" v-model.trim="inquiry.comment" :max-length="500" placeholder="请输入备注" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addInquiryList, updateInquiryList } from '@/api/inquirylist'
import { getAllTenants } from '@/api/tenants'
import moment from 'moment'
export default {
  props: {
    record: Object,
    type: String
  },
  data() {
    return {
      getAllTenants,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      loading: false,
      inquiry: {
        code: this.record.code || '',
        clientInquiryCode: this.record.clientInquiryCode || '',
        clientCode: this.record.clientCode || '',
        clientName: this.record.clientName || '',
        clientNickName: this.record.clientNickName || '',
        queryTime:
          this.record.queryTime ||
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        expirationTime: this.record.expirationTime || '',
        remark: this.record.remark || '',
        comment: this.record.comment || ''
      },
      rules: {
        code: [
          {
            required: false,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (value && (value.length < 5 || value.length > 20)) {
                return callback(new Error('询价单号需在5-20个字符之间'))
              }
              callback()
            }
          }
        ],
        clientName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入客户名称'
          }
        ],
        queryTime: [
          {
            required: true,
            trigger: 'change',
            message: '请选择询价时间'
          }
        ],
        name: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.inquiry.productItemList[0].productInfo.name) {
                return callback(new Error('请输入产品名称'))
              }
              callback()
            }
          }
        ],
        drawingNum: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入产品图号',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.inquiry.productItemList[0].productInfo.drawingNum) {
                return callback(new Error('请输入产品图号'))
              }
              callback()
            }
          }
        ],
        specification: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.inquiry.productItemList[0].productInfo.specification) {
                return callback(new Error('请输入产品规格'))
              }
              callback()
            }
          }
        ],
        unit: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.inquiry.productItemList[0].productInfo.unit) {
                return callback(new Error('请输入产品单位'))
              }
              callback()
            }
          }
        ],
        quantity: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入询价数量',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.inquiry.productItemList[0].quantity) {
                return callback(new Error('请输入询价数量'))
              }
              callback()
            }
          }
        ]
      }
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function() {
      return this.type === 'add'
    },
    isUpdate: function() {
      return this.type === 'edit'
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            addInquiryList({
              ...this.inquiry
            }).then(
              res => {
                this.loading = false
                this.$message.success('操作成功')
                this.$emit('confirm')
              },
              error => {
                this.loading = false
              }
            )
          } else {
            updateInquiryList(this.record.code, {
              ...this.inquiry
            }).then(
              res => {
                this.loading = false
                this.$message.success('更新成功')
                this.$emit('confirm')
              },
              error => {
                this.loading = false
              }
            )
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleAddInquiryProducItem() {
      this.settle.settleReceipts.push({
        receipeNumber: '',
        receiptType: 'IVC'
      })
      this.rules[`receipeNumber${this.settle.settleReceipts.length - 1}`] = [
        {
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            // console.log('opera', value)
            if (
              !this.settle.settleReceipts[this.settle.settleReceipts.length - 1]
                .receipeNumber
            ) {
              return callback(new Error('请输入凭据号'))
            }
            callback()
          }
        }
      ]
      this.rules[`receiptType${this.settle.settleReceipts.length - 1}`] = [
        {
          required: true,
          trigger: 'change',
          validator: (rule, value, callback) => {
            if (
              !this.settle.settleReceipts[this.settle.settleReceipts.length - 1]
                .receiptType
            ) {
              return callback(new Error('请选择凭据类型'))
            }
            callback()
          }
        }
      ]
    },
    handleDeleteInquiryProducItem(index) {
      this.settle.settleReceipts.splice(index, 1)
      delete this.rules[`receipeNumber${index}`]
      delete this.rules[`receiptType${index}`]
    },
    onChange(value, option) {
      this.inquiry.clientCode = option.code
      this.inquiry.clientName = value
      this.inquiry.clientNickName = option.nickName
    }
  }
}
</script>
<style scoped>
/deep/ .ant-form-item {
  margin-bottom: 2px;
}
.modal-container {
  height: 400px;
}
.customOperation >>> .ant-form-item-children {
  display: flex;
  align-items: center;
}
.customOperation >>> .ant-form-item-control-wrapper {
  display: flex;
  align-items: center;
}
/* .seqNum >>> .ant-form-item-control {
  margin-left: 21px;
} */
.inquiry-form >>> .ant-col-offset-1 {
  margin-left: 0px;
}
</style>
<style lang="less" scoped>
.customOperation {
  width: 30%;
  display: inline-flex;
  .ant-form-item-control {
    display: flex;
  }
}
.taskInfo {
  padding-left: 185px;
  display: flex;
  .item {
    display: flex;
  }
}
</style>
