export const outProcessingColumns = [
  {
    title: '顺序号',
    dataIndex: 'sequence',
    key: 'sequence',
    align: 'center',
  },
  {
    title: '工序编码',
    dataIndex: 'operationInfo.code',
    key: 'operationInfo.code',
    align: 'center',
  },
  {
    title: '工序名称',
    dataIndex: 'operationInfo.name',
    key: 'operationInfo.name',
    align: 'center',
  },
  {
    title: '工序类型',
    dataIndex: 'operationInfo.type',
    key: 'operationInfo.type',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'operationInfoType',
    },
  },
  {
    title: '外协单价',
    dataIndex: 'operationInfo.unitPrice',
    key: 'operationInfo.unitPrice',
    align: 'center',
  },
  {
    title: '供应商名称',
    dataIndex: 'companyInfo.name',
    key: 'companyInfo.name',
    align: 'center',
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
  },
  {
    title: '操作',
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
