export const operationColumns = [
  {
    title: '工序编号',
    dataIndex: 'operationCode',
    key: 'operationCode',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '工序名称',
    dataIndex: 'operationName',
    key: 'operationName',
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '工序类型',
    dataIndex: 'operationType',
    key: 'operationType',
    align: 'center',
    scopedSlots: {
      customRender: 'operationType',
    },
    filters: [
      {
        text: '自有工序',
        value: 1,
      },
      {
        text: '外协工序',
        value: 2,
      },
    ],
  },
  {
    title: '最新单价(元/小时)',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
