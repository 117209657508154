var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title + "工序",
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.operation,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "工序编号", prop: "operationCode" } },
            [
              _c("a-input", {
                attrs: { maxlength: "20", placeholder: "请输入工序编号" },
                model: {
                  value: _vm.operation.operationCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.operation,
                      "operationCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "operation.operationCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "工序名称", prop: "operationName" } },
            [
              _c("a-input", {
                attrs: { maxlength: "20", placeholder: "请输入工序名称" },
                model: {
                  value: _vm.operation.operationName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.operation,
                      "operationName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "operation.operationName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "工序类型", prop: "operationType" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.operation.operationType,
                    callback: function ($$v) {
                      _vm.$set(_vm.operation, "operationType", $$v)
                    },
                    expression: "operation.operationType",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("本部工序")]),
                  _c("a-radio", { attrs: { value: 2 } }, [_vm._v("委外工序")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "最新单价(元/小时)", prop: "unitPrice" } },
            [
              _c("a-input-number", {
                attrs: {
                  placeholder: "请输入最新单价",
                  min: 0,
                  max: 999999,
                  precision: 2,
                },
                model: {
                  value: _vm.operation.unitPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.operation, "unitPrice", $$v)
                  },
                  expression: "operation.unitPrice",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }