<template>
  <div class="card-container">
    <div id="craft-card-print">
      <div style="page-break-after:always">
        <div class="title">报价单</div>
        <div class="base-info">
          <div class="row-1">
            <div class="item left">
              <div class="label">报价单号</div>
              <div class="content">{{ data.code }}</div>
            </div>
            <div class="item right">
              <div class="label">报价日期</div>
              <div class="content">{{ data.startTime }}</div>
            </div>
          </div>
          <div class="row-1 row-bottom">
            <div class="item left">
              <div class="label">供应商</div>
              <div class="content">重庆市DBR刀具有限公司</div>
            </div>
            <div class="item right">
              <div class="label">采购方</div>
              <div class="content">{{ data.clientName }}</div>
            </div>
          </div>
          <div class="row-1 row-bottom">
            <div class="item left">
              <div class="label">联系人</div>
              <div class="content">XXXXX</div>
            </div>
            <div class="item right">
              <div class="label">联系人</div>
              <div class="content">{{ }}</div>
            </div>
          </div>
          <div class="row-1 row-bottom">
            <div class="item left">
              <div class="label">地址</div>
              <div class="content">XXXXX</div>
            </div>
            <div class="item right">
              <div class="label">地址</div>
              <div class="content">{{ }}</div>
            </div>
          </div>
          <div class="row-1 row-bottom">
            <div class="item left">
              <div class="label">电话</div>
              <div class="content">023-XXXXXXXXX转0</div>
            </div>
            <div class="item right">
              <div class="label">电话</div>
              <div class="content">{{ }}</div>
            </div>
          </div>
          <div class="row-1 row-bottom">
            <div class="item left">
              <div class="label">传真</div>
              <div class="content">023-XXXXX</div>
            </div>
            <div class="item right">
              <div class="label">传真</div>
              <div class="content">{{ }}</div>
            </div>
          </div>
          <div class="row-1 row-bottom">
            <div class="item left">
              <div class="label">付款方式</div>
              <div class="content">{{ PAT_MAP[data.payType] }}</div>
            </div>
            <div class="item right">
              <div class="label">交货方式</div>
              <div class="content">{{ DELIVERY_MAP[data.deliveryType] }}</div>
            </div>
          </div>
          <div class="table">
            <div class="table-row">
              <div class="cell">序号</div>
              <div class="cell">产品名称</div>
              <div class="cell">产品规格</div>
              <div class="cell">单位</div>
              <div class="cell">数量</div>
              <div class="cell">单价</div>
              <div class="cell">金额</div>
              <div class="cell">周期</div>
              <div class="cell">备注</div>
            </div>
            <div class="table-row" v-for="item in list" :key="item.index">
              <div class="cell">{{item.index}}</div>
              <div class="cell">{{item.productInfo.name}}</div>
              <div class="cell">{{item.productInfo.specification}}</div>
              <div class="cell">{{item.productInfo.unit}}</div>
              <div class="cell">{{item.quantity}}</div>
              <div class="cell">{{item.unitPriceWithTax}}</div>
              <div class="cell">{{item.totalAmountWithTax}}</div>
              <div class="cell">{{item.leadTime}}</div>
              <div class="cell">{{item.remark}}</div>
            </div>
            <div class="table-row">
              <div class="cell6">
                <p>合计</p>
              </div>
              <div class="cell">{{data.totalAmountWithTax}}</div>
              <div class="cell"></div>
              <div class="cell"></div>
            </div>
          </div>
          <div class="desc">注:如需订货，在双方签订正式合同后，请签字及盖章确认后回传，并缴纳总价50%预付款，交货时付清余款。（报价时效：180天）</div>
          <div class="desc">
            <div class="desc-left">供应商确认（盖章）</div>
            <div class="desc-right">签字：</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button v-print="'craft-card-print'" type="primary" class="btn" block>打印</a-button>
    </div>
  </div>
</template>
<script>
import { getQuotationsPrintInfo } from '@/api/offer'

const PAT_MAP = {
  CSH: '现金',
  BNK: '银行转账',
  CRD: '挂账'
}

const DELIVERY_MAP = {
  SND: '送货',
  SLF: '自取',
  EXP: '快递'
}

export default {
  data() {
    return {
      PAT_MAP,
      DELIVERY_MAP,
      data: {},
      list: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      getQuotationsPrintInfo(this.$route.query.code).then(res => {
        if (res.data) {
          this.data = res.data
          this.list = res.data.quotationItemList.map((item, index) => {
            return {
              ...item,
              index: index + 1
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.card-container {
  width: 80%;
  margin: auto;
  position: relative;
}

.page {
  margin-bottom: 100px;
}
.title {
  height: 50px;
  display: flex;
  justify-content: center;
  font-family: SimHei;
  font-size: 30px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 8px;
  color: black;
}

.card-title >>> .ant-descriptions-item-label {
  font-family: SimHei;
  font-size: 20px;
}
.version {
  font-size: 30px;
  position: absolute;
  top: 0;
  left: 0;
  color: black;
}

.amount-font {
  font-family: SimHei;
  font-size: 17px;
  margin: 0;
}

/deep/ .ant-table {
  padding: 10px 0px;
  color: black;
}

/deep/ .ant-descriptions-item-label {
  color: black;
}

/deep/ .ant-descriptions-item-content {
  color: black;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
  text-align: center;
  border: 1px solid black;
  padding: 1px 2px;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
  text-align: center;
  border: 1px solid black;
  font-family: SimHei;
  font-size: 20px;
  font-weight: bold;
  padding: 1px 2px;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-view {
  border: 0px;
}

/deep/ .ant-table-row {
  height: 43px !important;
  font-family: SimHei;
  font-weight: bold;
}
/deep/ .ant-descriptions-item-label {
  font-family: SimHei;
  font-size: 21px;
  font-weight: bold;
}

.footer {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.base-info {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  .row-1 {
    min-height: 40px;
    border: 2px solid #000;
    display: flex;
    word-break: break-all;
    .left {
      flex: 1;
      display: flex;
      .label {
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
      }
      .content {
        width: 45%;
        text-align: center;
        line-height: 38px;
        border-right: 2px solid #000;
      }
    }
    .right {
      flex: 1.8;
      display: flex;
      .label {
        width: 20%;
        text-align: center;
        line-height: 38px;
        border-right: 2px solid #000;
      }
      .content {
        width: 80%;
        text-align: center;
        line-height: 38px;
      }
    }
  }
  .row-2 {
    min-height: 40px;
    border: 2px solid #000;
    display: flex;
    word-break: break-all;
    .left {
      flex: 1.5;
      display: flex;
      .label {
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
      }
      .content {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
        padding: 0 8px;
      }
    }
    .middle {
      flex: 1.4;
      display: flex;
      .label {
        width: 44%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
      }
      .content {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
      }
    }
    .right {
      flex: 1.3;
      display: flex;
      .label {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #000;
      }
      .content {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .row-column {
    min-height: 40px;
    border: 2px solid #000;
    display: flex;
    .label {
      width: 19.7%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 2px solid #000;
    }
    .content {
      width: 45%;
      text-align: center;
      line-height: 38px;
    }
  }
  .hand-check {
    width: 100%;
    min-height: 100px;
    border: 2px solid #000;
    display: flex;
    .label {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      border-right: 2px solid #000;
    }
    .content {
      flex: 8;
      position: relative;
    }
    .bottom-check {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 10px;
      flex-wrap: nowrap;
      p {
        width: 300px;
      }
    }
    .content-row {
      display: flex;
      flex-direction: column;
      .content-row-1 {
        width: 100%;
        flex: 1;
        display: flex;
        .label {
          flex: 1;
          border-bottom: 2px solid #000;
        }
        .context {
          box-sizing: border-box;
          padding-left: 8px;
          flex: 7;
          border-bottom: 2px solid #000;
        }
        .noborder {
          border-bottom: none;
        }
      }
    }
  }
  .row-bottom {
    border-top: none;
  }
}
.num {
  box-sizing: border-box;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  text-align: right;
}
.table {
  width: 100%;
  &-row {
    height: 40px;
    display: flex;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    /* border-right: 2px solid #000; */
    .cell {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 2px solid #000;
    }
    .cell6 {
      flex: 6.08;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-right: 2px solid #000;
      box-sizing: border-box;
      p {
        margin-right: 20px;
      }
    }
  }
}
.desc {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-left {
    margin-left: 50px;
  }
  &-right {
    margin-right: 150px;
  }
}
</style>
