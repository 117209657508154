<template>
  <div class="app-container">
    <div class="top-tip">
      <div class="selected-wrapper">
        <span v-if="hasSelected">
          已选中
          <strong>{{ selectedRowKeys.length }}</strong> 项任务
        </span>
        <a-dropdown v-if="hasSelected">
          <a class="batch-operate-link" @click="(e) => e.preventDefault()">
            <a-icon type="edit" />批量操作
          </a>

          <a-menu v-if="currentActiveKey === 'FG'" slot="overlay">
            <a-menu-item>
              <a @click="handleBatchCreateCard('rework')">批量打印返工处置通知单</a>
            </a-menu-item>
          </a-menu>
          <a-menu v-if="currentActiveKey === 'BF'" slot="overlay">
            <a-menu-item>
              <a @click="handleBatchCreateCard('scrap')">批量打印产品报废单</a>
            </a-menu-item>
          </a-menu>
          <a-menu v-if="currentActiveKey === 'TH'" slot="overlay">
            <a-menu-item>
              <a @click="handleBatchCreateReturnCard(selectedRowKeys)">生成退库交接单</a>
            </a-menu-item>
          </a-menu>
          <a-menu v-if="currentActiveKey === 'CC'" slot="overlay">
            <a-menu-item>
              <a @click="handleBatchCreateCard('overTolerance')">批量打印超差处置单</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-tabs :active-key="currentActiveKey" type="card" @change="handleTabChange">
      <a-tab-pane key="FG" tab="返工返修" />
      <a-tab-pane key="BF" tab="生产报废" />
      <a-tab-pane key="TH" tab="退货统计" />
      <a-tab-pane key="CC" tab="超差处理" />
    </a-tabs>
    <a-table
      :key="tableKey"
      row-key="id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: inventoryRowSelection.getCheckboxProps,
      }"
      :columns="columns"
      :data-source="qualities"
      :loading="loading"
      :pagination="pagination"
      bordered
      :scroll="{ x: 3000, y: 590 }"
      class="plans-table"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-if="column.searchType === 'text'"
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`搜索 ${column.title}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
        />
        <div v-if="column.searchType === 'number'">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([e, selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([selectedKeys[0], e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-if="column.searchType === 'date'">
          <a-range-picker
            :show-time="{ format: 'HH:mm:ss' }"
            size="small"
            style="width: 330px; margin-bottom: 8px"
            :ranges="{
              今天: [$moment(), $moment()],
              本月: [$moment().startOf('month'), $moment().endOf('month')],
            }"
            @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="generateTimeTitle">
        <template v-if="currentActiveKey === 'FG'">返工日期</template>
        <template v-if="currentActiveKey === 'BF'">报废日期</template>
        <template v-if="currentActiveKey === 'TH'">退货日期</template>
        <template v-if="currentActiveKey === 'CC'">超差日期</template>
      </div>
      <template slot="returnReceiptNumber" slot-scope="text, column">
        <template v-if="column.returnReceiptNumber">
          <el-link type="primary" @click="hadnleCheckCard(column)">
            {{
            text
            }}
          </el-link>
        </template>
        <template v-else>
          <el-link type="primary" @click="handleCreateSingleCard(column)">点击生成</el-link>
        </template>
      </template>
      <template slot="defeatReceiptNumber" slot-scope="text, column">
        <template v-if="column.defeatReceiptNumber">
          <el-link type="primary" @click="handleJumpAbandonCard(column)">
            {{
            text
            }}
          </el-link>
        </template>
      </template>
      <div slot="hasAction" slot-scope="text">
        <a-tag v-if="text === 'Y'" color="blue">需要</a-tag>
        <a-tag v-if="text === 'N'" color="red">不需要</a-tag>
      </div>
      <div slot="actualUseMaterialList" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span v-for="(item, index) in text" :key="index">
              {{item.materialName}}({{item.materialCode}})
              <span v-if="index < text.length - 1">,</span>
            </span>
          </template>
          <div class="table-ellis">
            <span v-for="(item, index) in text" :key="index">{{item.materialName}}({{item.materialCode}}),</span>
          </div>
        </a-tooltip>
      </div>
      <div v-if="currentActiveKey=== 'BF'" slot="defeatDemand" slot-scope="text, column">
        <a-tag v-if="text" color="green">已下达</a-tag>
        <el-link v-else type="primary" @click="handleCreateTask(column)">点击下达</el-link>
      </div>

      <div slot="redone-repairEffect" slot-scope="text" v-if="currentActiveKey === 'FG'">
        <a-tag v-if="text === 'OK'" color="blue">合格</a-tag>
        <a-tag v-if="text === 'RE'" color="red">再次返修</a-tag>
        <a-tag v-if="text === 'BF'" color="yellow">报废</a-tag>
        <a-tag v-if="text === 'CC'" color="yellow">超差</a-tag>
      </div>
      <div v-if="currentActiveKey === 'TH'" slot="actionEffect" slot-scope="text">
        <a-tag v-if="text === 'OK'" color="green">合格</a-tag>
        <a-tag v-if="text === 'BF'" color="red">报废</a-tag>
        <a-tag v-if="text === 'CC'" color="yellow">超差</a-tag>
      </div>
      <div v-if="currentActiveKey === 'TH'" slot="repairEffect" slot-scope="text">
        <a-tag v-if="text === 'FX'" color="blue">返修</a-tag>
        <a-tag v-if="text === 'BT'" color="red">报废</a-tag>
        <a-tag v-if="text === 'TH'" color="orange">退回客户</a-tag>
      </div>
      <div slot="taskCode" slot-scope="text, column">
        <!-- <a-tooltip placement="top">
          <template slot="title">
            <span>点击查看缺陷详情</span>
          </template>
          <el-link type="primary" @click="handleCheckDetail(column)">{{ text }}</el-link>
        </a-tooltip>-->
        <a href="#" title="点击查看缺陷详情" style="color: #409eff" @click="handleCheckDetail(column)">{{ text }}</a>
      </div>
      <div slot="defeatType" slot-scope="text">
        <a-tag>{{ typeMap[text] }}</a-tag>
      </div>
      <div slot="taskStatus" slot-scope="text">
        <template v-if="text === 0">
          <a-badge status="default" />准备中
        </template>
        <template v-if="text === 1">
          <a-badge status="default" />未开始
        </template>
        <template v-if="text === 2">
          <a-badge status="processing" />进行中
        </template>
        <template v-if="text === 3">
          <a-badge status="success" />已完成
        </template>
        <template v-if="text === 9">
          <a-badge status="warning" />暂停中
        </template>
        <template v-if="text === 8">
          <a-badge status="error" />已取消
        </template>
        <template v-if="text === 10">
          <a-badge status="error" />异常终止
        </template>
      </div>
      <span slot="operation" slot-scope="text, column" class="table-operation">
        <a-dropdown placement="topRight">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <!-- <a-menu-item @click="handleDeleteQualities(column)">删除记录</a-menu-item> -->
            <a-menu-item @click="handleUpdateQualities(column)">更新记录</a-menu-item>
          </a-menu>
          <a>
            操作
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </span>
    </a-table>
    <el-dropdown class="export-record" @command="handleExportRecordCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出质量记录</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="FILTERED">导出当前筛选记录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <QualitiesEditModal
      v-if="qualitiesEditModalVisible"
      :type="currentEditType"
      :record="currentRecord"
      @cancel="handleUpdateQualitiesCancel"
      @confirm="handleUpdateQualitiesConfirm"
    />
    <CreateReturnCardModal
      v-if="modalVisible"
      :records-data="recordIds"
      @cancel="handleInventoryRecordsModalCanceled"
      @confirm="handleInventoryRecordsModalConfirmed"
    />
    <TaskEdit
      v-if="editTaskModalVisible"
      :modal-title="currentEditType"
      :task-form="currentTask"
      current-task-type="BF"
      @cancel="handleTaskModalCanceled"
      @confirm="handleTaskModalConfirmed"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import {
  getQualities,
  deleteQuality,
  exportFilteredQualities
} from '@/api/qualities'
import QualitiesEditModal from '@/components/QualitiesEditModal'
import * as R from 'ramda'
import {
  redoneColumns,
  worthlessColumns,
  returnColumns,
  overproofColumns,
  inventoryRowSelection
} from './dataLogic.js'
import { CONSTANTS } from '@/utils/constants'
import { downloadItem } from '@/utils/api.request'
import CreateReturnCardModal from '@/components/CreateReturnCardModal'
import TaskEdit from '@/components/TaskEdit'

export default {
  components: {
    QualitiesEditModal,
    CreateReturnCardModal,
    TaskEdit
  },
  data() {
    return {
      inventoryRowSelection,
      tableKey: 0,
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        showQuickJumper: true
      },
      qualities: [],
      currentRecord: '',
      columns: '',
      redoneColumns,
      worthlessColumns,
      returnColumns,
      overproofColumns,
      currentActiveKey: 'FG',
      selectedRowKeys: [],
      exportIds: [],
      loading: false,
      exportLoading: false,
      qualitiesEditModalVisible: false,
      initQualitiesListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        defeatType: 'eq:FG'
      },
      currentQualitiesListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        defeatType: 'eq:FG'
      },
      columnSearchParams: {},
      otherFilterParams: null,
      columnFilterParams: null,
      currentEditType: '',
      typeMap: {
        FG: '返工返修',
        BF: '生产报废',
        TH: '退货',
        CC: '超差'
      },
      modalVisible: false,
      recordIds: [],
      selectReceiptNumber: [],
      editTaskModalVisible: false,
      editTaskModalVisible: '',
      currentTask: ''
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  created() {
    this.columns = this.redoneColumns
    this.getQualities(this.initQualitiesListQueryParams)
  },
  methods: {
    handleTabChange(activeKey) {
      this.initQualitiesListQueryParams['defeatType'] = 'eq:' + activeKey
      this.currentQualitiesListQueryParams = R.clone(
        this.initQualitiesListQueryParams
      )
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getQualities(this.initQualitiesListQueryParams)
      this.currentActiveKey = activeKey
      console.log('active', activeKey)
      switch (activeKey) {
        case 'FG':
          this.columns = this.redoneColumns
          break
        case 'BF':
          this.columns = this.worthlessColumns
          break
        case 'TH':
          this.columns = this.returnColumns
          break
        case 'CC':
          this.columns = this.overproofColumns
          break
      }
      this.selectedRowKeys = []
      this.tableKey += 1
    },
    async getQualities(data) {
      this.loading = true
      try {
        const res = await getQualities(data)
        this.qualities = res.data.records
        this.pagination.total = res.data.total
        this.loading = false
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    },
    handleUpdateQualities(column) {
      this.currentEditType = 'update'
      this.currentRecord = R.clone(column)
      this.qualitiesEditModalVisible = true
    },
    handleCheckDetail(column) {
      this.currentEditType = 'check'
      this.currentRecord = R.clone(column)
      this.qualitiesEditModalVisible = true
    },
    handleDeleteQualities(column) {
      this.$confirm({
        content: '确认删除记录?',
        onOk: () => {
          deleteQuality(column.id).then(res => {
            if (res) {
              this.$message.success('删除记录成功！')
              this.getQualities(this.currentQualitiesListQueryParams)
            }
          })
        }
      })
    },
    handleUpdateQualitiesConfirm() {
      this.qualitiesEditModalVisible = false
      this.getQualities(this.currentQualitiesListQueryParams)
    },
    handleUpdateQualitiesCancel() {
      this.qualitiesEditModalVisible = false
    },
    onSelectChange(selectedRowKeys, selectRows) {
      this.selectedRowKeys = selectedRowKeys
      if (['FG', 'BF', 'CC'].indexOf(this.currentActiveKey) > -1) {
        selectRows.map(item => {
          this.selectReceiptNumber.push(item.defeatReceiptNumber)
        })
      }
      console.log('selectedRowKeys changed: ', selectRows)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)

      if (filtersKeys.includes('defeatDemand')) {
        delete this.columnSearchParams['defeatDemand']
        if (filters['defeatDemand'].length > 0) {
          this.columnSearchParams['defeatDemand'] = `eq:${filters[
            'defeatDemand'
          ].toString()}`
        } else {
          delete this.columnSearchParams['defeatDemand']
        }
      }

      if (filtersKeys.includes('hasAction')) {
        delete this.columnSearchParams['hasAction']
        if (filters['hasAction'].length > 0) {
          this.columnSearchParams['hasAction'] = `in:${filters[
            'hasAction'
          ].toString()}`
        } else {
          delete this.columnSearchParams['hasAction']
        }
      }
      if (filtersKeys.includes('repairEffect')) {
        delete this.columnSearchParams['repairEffect']
        if (filters['repairEffect'].length > 0) {
          this.columnSearchParams['repairEffect'] = `in:${filters[
            'repairEffect'
          ].toString()}`
        } else {
          delete this.columnSearchParams['repairEffect']
        }
      }
      if (filtersKeys.includes('actionEffect')) {
        delete this.columnSearchParams['actionEffect']
        if (filters['actionEffect'].length > 0) {
          this.columnSearchParams['actionEffect'] = `in:${filters[
            'actionEffect'
          ].toString()}`
        } else {
          delete this.columnSearchParams['actionEffect']
        }
      }
      if (filtersKeys.includes('task.status')) {
        delete this.columnSearchParams['task.status']
        if (filters['task.status'].length > 0) {
          this.columnSearchParams['task.status'] = `in:${filters[
            'task.status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentQualitiesListQueryParams.sort_by =
          sortType + sorter.columnKey
      } else {
        this.currentQualitiesListQueryParams.sort_by = null
      }
      this.pagination = pagination
      this.currentQualitiesListQueryParams.pageNum = pagination.current
      this.currentQualitiesListQueryParams.pageSize = pagination.pageSize
      this.getQualitiesByParams()
    },
    getQualitiesByParams() {
      const cQueryParams = deepClone(this.currentQualitiesListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getQualities(queryParams)
    },
    handleExportRecordCommand(command) {
      const queryParams = Object.assign(
        this.currentQualitiesListQueryParams,
        this.columnSearchParams
      )
      switch (command) {
        case 'FILTERED':
          this.exportLoading = true
          exportFilteredQualities(queryParams)
            .then(res => {
              this.downloadRecord(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })

          break
        default:
          break
      }
    },
    downloadRecord(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.QUALITY_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleCreateSingleCard(column) {
      this.recordIds = [column.id]
      this.modalVisible = true
    },

    handleBatchCreateCard(type) {
      const route = this.$router.resolve({
        path: `/${type}-card`,
        query: {
          ids: this.selectedRowKeys.join(',')
        }
      })
      window.open(route.href, '_blank')
    },
    handleInventoryRecordsModalCanceled() {
      this.modalVisible = false
    },
    handleInventoryRecordsModalConfirmed() {
      this.selectedRowKeys = []
      this.recordIds = []
      this.modalVisible = false
      this.getQualitiesByParams()
    },
    hadnleCheckCard(column) {
      const route = this.$router.resolve({
        path: '/return-inventory-card',
        query: {
          receiptNumber: column.returnReceiptNumber
        }
      })
      window.open(route.href, '_blank')
    },
    handleJumpAbandonCard(column) {
      let type = ''
      switch (this.currentActiveKey) {
        case 'FG':
          type = 'rework'
          break
        case 'CC':
          type = 'overTolerance'
          break
        case 'BF':
          type = 'scrap'
          break
        case 'TH':
          type = 'afterSale'
      }

      const route = this.$router.resolve({
        path: `/${type}-card`,
        query: {
          ids: column.defeatReceiptNumber
        }
      })
      window.open(route.href, '_blank')
    },
    handleBatchCreateReturnCard(selectedKeys) {
      this.recordIds = [...selectedKeys]
      this.modalVisible = true
    },
    handleTaskModalCanceled() {
      this.editTaskModalVisible = false
    },
    handleTaskModalConfirmed() {
      this.editTaskModalVisible = false
      this.getQualitiesByParams()
    },
    handleCreateTask(column) {
      this.currentEditType = '新增'
      this.currentTask = column
      this.editTaskModalVisible = true
    }
  }
}
</script>
<style scoped lang="scss">
.batch-operate-link {
  padding-left: 20px;
  cursor: pointer;
  color: #409eff;
}
.app-container {
  position: relative;
  .export-record {
    float: right;
    bottom: 46px;
    right: 0px;
  }
}

/deep/ .ant-table-pagination,
.ant-pagination {
  float: left;
}
.table-ellis {
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}
</style>
