<template>
  <div class="container">
    <div class="left">
      <div class="container-top">
        <a-input-search class="input-search" placeholder="搜索" @change="onSearchChange" />
      </div>
      <div class="menu">
        <a-tree
          :tree-data="treeData"
          :selected-keys="selectedKeys"
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          @select="handleSelect"
          @rightClick="handleRightClick"
          @expand="onExpand"
        >
          <template #title="{ key: treeKey, title}">
            <!-- <template v-if="title.indexOf(searchValue) > -1">
              <span>
                {{ title.substr(0, title.indexOf(searchValue)) }}
                <span style="color: #f50">{{ searchValue }}</span>
                {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
              </span>
            </template>-->
            <a-dropdown :trigger="['contextmenu']">
              <span v-if="title.indexOf(searchValue) > -1">
                {{ title.substr(0, title.indexOf(searchValue)) }}
                <span style="color: #f50">{{ searchValue }}</span>
                {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
              </span>
              <span v-else>{{ title }}</span>
              <template #overlay>
                <a-menu @click="({ key: menuKey }) => onContextMenuClick(treeKey, menuKey)">
                  <a-menu-item
                    v-if="treeKey.indexOf('month') > -1 && treeKey.indexOf('week') === -1 && treeKey.indexOf('group') === -1"
                    key="addWeek"
                  >添加周目标</a-menu-item>
                  <template v-if="treeKey.indexOf('week') !== -1 && treeKey.indexOf('group') === -1">
                    <a-menu-item key="editWeek">更新周目标</a-menu-item>
                    <a-menu-item key="editWeekTask">规划周任务</a-menu-item>
                  </template>
                  <a-menu-item v-if="treeKey.indexOf('group') !== -1" key="editGroup">规划班组任务</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
        </a-tree>
      </div>
    </div>
    <div class="right">
      <template v-if="taskList.length || columnSearchParams !== {}">
        <div class="top-tip" style="margin-top: 16px;">
          <div class="selected-wrapper">
            <span v-if="hasSelected">
              已选中
              <strong>{{ selectedRowKeys.length }}</strong> 项任务
            </span>
            <a-dropdown v-if="hasSelected && selectedKeys[0].indexOf('week') > 1">
              <a class="batch-operate-link" @click="e => e.preventDefault()">
                <a-icon type="edit" />批量操作
              </a>

              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="handleDeleteTask(selectedTaskIds)">批量删除任务</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
        <a-table
          v-if="selectedKeys.length"
          :key="tableKey"
          style="margin-top:16px;"
          row-key="id"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: targetRowSelection.getCheckboxProps }"
          bordered
          :scroll="{ x: 2000 }"
          :columns="selectedKeys[0].indexOf('week') > 1 ? taskColumns : monthColumns"
          :data-source="taskList"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <div slot="isParts" slot-scope="text">
            <template v-if="text === 0">
              <a-tag color="red">否</a-tag>
            </template>
            <template v-if="text === 1">
              <a-tag color="green">是</a-tag>
            </template>
          </div>
          <span slot="emergencyType" slot-scope="text">
            <a-tag color="volcano">{{ EmergencyType[text] }}</a-tag>
          </span>
          <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
            <template v-if="column.searchType === 'text'">
              <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`搜索 ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
            </template>
            <div v-else-if="column.searchType === 'number'">
              <a-input-number
                v-model="selectedKeys[0]"
                size="small"
                placeholder="最小值"
                :min="0"
                :max="999999"
                style="width: 95px; margin-bottom: 8px; display: inline-block;"
                @change="e => setSelectedKeys([e,selectedKeys[1]])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
              <span>-</span>
              <a-input-number
                v-model="selectedKeys[1]"
                size="small"
                placeholder="最大值"
                :min="selectedKeys[0]"
                :max="999999"
                style="width: 95px; margin-bottom: 8px; display: inline-block;"
                @change="e => setSelectedKeys([selectedKeys[0],e])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
              />
            </div>
            <div v-else-if="column.searchType === 'date'">
              <a-range-picker
                :show-time="{ format: 'HH:mm:ss' }"
                size="small"
                style="width: 330px; margin-bottom: 8px;"
                :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
                @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
              />
            </div>
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm, column.key)"
            >搜索</a-button>
            <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
          </div>
          <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
          <span
            v-if="(column.status === 2 ||column.status === 4||column.status === 5|| column.status === 8) && selectedKeys[0].indexOf('week') > 1"
            slot="operation"
            slot-scope="text, column"
            class="table-operation"
          >
            <a-dropdown placement="bottomRight">
              <a-menu slot="overlay" class="ant-dropdown-link">
                <a-menu-item @click="handleDeleteTask(column.taskId)">删除任务</a-menu-item>
                <a-menu-item v-if="selectedKeys[0].indexOf('group') > 1" @click="handleUpdateTargetNum(column)">更新目标数量</a-menu-item>
              </a-menu>
              <a class="operation-btn">
                操作
                <a-icon type="down" />
              </a>
            </a-dropdown>
          </span>
          <div slot="fileName" slot-scope="text, column" class="file-wrapper">
            <template v-if="column.fileName">
              <div class="filename-wrapper">
                <span @click="handleDownloadFile(column.craftId, column.fileName)" class="file-link">{{ column.fileName }}</span>
              </div>
            </template>
            <template v-else>
              <span>暂无工艺文件</span>
            </template>
          </div>
          <div slot="status" slot-scope="text, column">
            <el-link
              v-if="selectedKeys.length && selectedKeys[0].indexOf('week') > 1"
              type="success"
              @click="handleShowProcessRoute(column.code, column.taskId)"
            >
              <template v-if="text === 0">
                <a-badge status="default" />准备中
              </template>
              <template v-if="text === 1">
                <a-badge status="default" />未开始
              </template>
              <template v-if="text === 2">
                <a-badge status="processing" />进行中
              </template>
              <template v-if="text === 3">
                <a-badge status="success" />已完成
              </template>
              <template v-if="text === 4">
              <a-badge status="error" />超期进行中
            </template>
            <template v-if="text === 5">
              <a-badge status="warning" />即将超期
            </template>
              <template v-if="text === 9">
                <a-badge status="warning" />暂停中
              </template>
              <template v-if="text === 8">
                <a-badge status="error" />已取消
              </template>
              <template v-if="text === 10">
                <a-badge status="error" />异常终止
              </template>
            </el-link>
            <template v-else>
              <template v-if="text === 0">
                <a-badge status="default" />准备中
              </template>
              <template v-if="text === 1">
                <a-badge status="default" />未开始
              </template>
              <template v-if="text === 2">
                <a-badge status="processing" />进行中
              </template>
              <template v-if="text === 3">
                <a-badge status="success" />已完成
              </template>
              <template v-if="text === 4">
              <a-badge status="error" />超期进行中
            </template>
            <template v-if="text === 5">
              <a-badge status="warning" />即将超期
            </template>
              <template v-if="text === 9">
                <a-badge status="warning" />暂停中
              </template>
              <template v-if="text === 8">
                <a-badge status="error" />已取消
              </template>
              <template v-if="text === 10">
                <a-badge status="error" />异常终止
              </template>
            </template>
          </div>
          <div slot="process" slot-scope="text, column" class="table-process">
            <template v-if="column.remainProcessRoute.length > 0">
              <a-steps :current="column.remainProcessRoute.length-1" status="finish" size="small">
                <a-step v-for="process in column.remainProcessRoute" :key="process.id" :title="process.operationName">
                  <span slot="description" class="process-desc">
                    <p v-if="process.operationType>0&&process.verifyProcessHour" class="description-font">
                      <b>加工工时：</b>
                      {{ process.verifyProcessHour }}
                    </p>
                    <p v-if="process.operationType>0&&process.verifyTransferOutTime" class="description-font">
                      <b>转出日期：</b>
                      {{ process.verifyTransferOutTime }}
                    </p>
                  </span>
                </a-step>
              </a-steps>
            </template>
          </div>
        </a-table>
        <template v-if="selectedKeys.length">
          <el-dropdown
            v-if="selectedKeys[0].indexOf('week') > 1 || selectedKeys[0].indexOf('group') > 1"
            class="export-plan"
            @command="handleExportPlanCommand"
          >
            <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出目标</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="FILTERED">导出当前筛选目标</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </template>
      <template v-else>
        <template v-if="targetList.length">
          <a-empty class="empty">
            <span slot="description">暂无任务信息</span>
          </a-empty>
        </template>
        <template v-else>
          <a-empty class="empty">
            <span slot="description">暂无目标信息</span>
            <a-button type="primary" @click="handleAddTarget">添加目标</a-button>
          </a-empty>
        </template>
      </template>
    </div>
    <TargetEdit
      v-if="taskEditModalVisible"
      :modal-title="editType"
      :target-form="editTarget"
      :father-target-id="Number(activeTargetKey.split('-')[1])"
      target-type="week"
      @confirm="targetConfirm"
      @cancel="targetCancel"
    />
    <TargetTaskEdit
      v-if="targetTaskModalVisible"
      :id="editWeekTargetId"
      :title="editTitle"
      :group-id="editGroupId"
      @confirm="taskEditConfirm"
      @cancel="taskEditCancel"
    />
    <TargetNumEditModal
      v-if="targetNumEditModalVisible"
      :target-form="targetNumEditItem"
      @confirm="targetNumEditConfirm"
      @cancel="targetNumEditCancel"
    />
    <TargetProcessRouteModal
      v-if="targetProcessRouteModalVisible"
      :target-id="selectedKeys[0].split('-')[3]"
      :task-id="processRouteTaskId"
      :task-code="processRouteTaskCode"
      @confirm="handleShowProcessRouteConfirm"
    />
  </div>
</template>

<script>
import {
  getMonthTargetTree,
  getWeekEnactedTasks,
  deleteWeekTarget,
  deleteWeekTargetTask,
  deleteWeekGroupTargetTask,
  getTasksById,
  getWeekGroupTasks,
  exportFilteredTarget
} from '@/api/target'
import { CONSTANTS } from '@/utils/constants'
import { EmergencyType } from '@/enums/index'
import { taskColumns, targetRowSelection } from './dataLogic'
import { monthColumns } from '../month/dataLogic'
import TargetEdit from '@/components/TargetEdit'
import TargetTaskEdit from '@/components/TargetTaskEdit'
import TargetNumEditModal from '../components/TargetNumEditModal'
import TargetProcessRouteModal from '../components/TargetProcessRouteModal'
import { downloadItem, handleDownLoad } from '@/utils/api.request'
import moment from 'moment'

const getParentKey = (key, tree) => {
  let parentKey
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey
}
const dataList = []
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i]
    const key = node.key
    dataList.push({ key, title: node.title })
    if (node.children) {
      generateList(node.children)
    }
  }
}

export default {
  name: 'TargetStatistics',
  components: {
    TargetEdit,
    TargetTaskEdit,
    TargetNumEditModal,
    TargetProcessRouteModal
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      EmergencyType,
      targetRowSelection,
      // 目标的树形结构信息
      treeData: [],
      expandedKeys: [],
      selectedKeys: [],
      autoExpandParent: true,
      searchValue: '',
      targetRowSelection,
      tableKey: 0,

      // 目标的页码信息
      targetsPagination: {
        pageNum: 1,
        pageSize: 50
      },
      targetTotalPages: '',
      targetList: [],
      activeTargetKey: '',
      taskList: [],
      taskColumns,
      monthColumns,

      // 已规划的任务页码信息
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      currentTaskParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      columnSearchParams: {},
      loading: false,
      taskEditModalVisible: false,
      editType: '',
      editTarget: {},
      editTitle: '',

      targetTaskModalVisible: false,
      editWeekTargetId: '', // 编辑任务的周目标ID
      editGroupId: '', // 编辑任务的班组id

      selectedTaskIds: [],
      selectedRowKeys: [],

      exportLoading: false,
      targetNumEditModalVisible: false,
      targetNumEditItem: {},

      targetProcessRouteModalVisible: false,
      processRouteTaskId: '',
      processRouteTaskCode: ''
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  mounted() {
    this.getTargets(this.targetsPagination)
  },
  methods: {
    initTargets() {
      this.targetsPagination.pageNum = 1
      this.treeData = []
      this.getTargets(this.targetsPagination, this.activeTargetKey)
    },
    getTargets(data, activeId) {
      getMonthTargetTree(data).then(res => {
        const _records = res.data.records
        _records.map(month => {
          month.key = 'month-' + month.id
          month.title = month.targetName
          month.weekTargets.map(week => {
            week.key = 'month-' + month.id + '-week-' + week.id
            week.title = week.targetName
            week.groups.map(group => {
              group.key =
                'month-' + month.id + '-week-' + week.id + '-group-' + group.id
              group.title = group.groupName
            })
            week.children = week.groups
          })
          month.children = month.weekTargets
        })
        generateList(_records)
        this.treeData.push(..._records)
        this.targetTotalPages = res.data.pages

        if (activeId) {
          this.selectedKeys = [activeId]
        } else {
          this.selectedKeys = [this.treeData[0].key]
        }
        this.handleGetTask()
      })
    },
    handleSelect(keys, e) {
      console.log(keys)
      this.selectedKeys = keys
      this.selectedRowKeys = []
      this.handleInitTask()
      this.handleGetTask()
    },
    handleGetTask() {
      const value = this.selectedKeys[0]
      if (value.indexOf('group') > -1) {
        const weekId = value.split('-')[3]
        const groupId = value.split('-')[5]
        this.getGroupTaskList(weekId, groupId)
      } else if (value.indexOf('week') > -1) {
        const weekId = value.split('-')[3]
        this.getWeekTaskList(weekId)
      } else {
        this.getMonthTaskList(value.split('-')[1])
      }
    },
    getMonthTaskList(id) {
      if (this.loading) return
      this.loading = true
      getTasksById({
        targetId: id,
        ...this.currentTaskParams,
        ...this.columnSearchParams
      }).then(
        res => {
          this.taskList = res.data.records
          this.pagination.total = res.data.total
          this.loading = false
        },
        error => {
          this.loading = false
        }
      )
    },
    getWeekTaskList(id) {
      if (this.loading) return
      this.loading = true
      getWeekEnactedTasks(id, {
        ...this.currentTaskParams,
        ...this.columnSearchParams
      }).then(
        res => {
          this.taskList = res.data.records
          this.pagination.total = res.data.total
          this.loading = false
        },
        error => {
          this.loading = false
        }
      )
    },
    getGroupTaskList(weekId, groupId) {
      if (this.loading) return
      this.loading = true
      getWeekGroupTasks(weekId, groupId, {
        ...this.currentTaskParams,
        ...this.columnSearchParams
      }).then(
        res => {
          this.taskList = res.data.records
          this.pagination.total = res.data.total
          this.loading = false
        },
        error => {
          this.loading = false
        }
      )
    },
    handleInitTask() {
      this.tableKey += 1
      this.currentTaskParams.pageNum = 1
      this.currentTaskParams.sort_by = null
      this.columnSearchParams = {}
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      console.log(filtersKeys, filters)
      if (filtersKeys.includes('task.emgType')) {
        delete this.columnSearchParams['task.emgType']
        if (filters['task.emgType'].length > 0) {
          this.columnSearchParams['task.emgType'] = `in:${filters[
            'task.emgType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.emgType']
        }
      }
      if (filtersKeys.includes('emergencyType')) {
        delete this.columnSearchParams['emergencyType']
        if (filters['emergencyType'].length > 0) {
          this.columnSearchParams['emergencyType'] = `in:${filters[
            'emergencyType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['emergencyType']
        }
      }
      if (filtersKeys.includes('task.status')) {
        delete this.columnSearchParams['task.status']
        if (filters['task.status'].length > 0) {
          this.columnSearchParams['task.status'] = `in:${filters[
            'task.status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.status']
        }
      }
      if (filtersKeys.includes('product.type')) {
        delete this.columnSearchParams['product.type']
        if (filters['product.type'].length > 0) {
          this.columnSearchParams['product.type'] = `in:${filters[
            'product.type'
          ].toString()}`
        } else {
          delete this.columnSearchParams['product.type']
        }
      }
      if (filtersKeys.includes('plan.isParts')) {
        delete this.columnSearchParams['plan.isParts']
        if (filters['plan.isParts'].length > 0) {
          this.columnSearchParams['plan.isParts'] = `in:${filters[
            'plan.isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['plan.isParts']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        if (sorter.columnKey === 'code') {
          sorter.columnKey = 'task.code'
        }
        this.currentTaskParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentTaskParams.sort_by = null
      }

      this.pagination = pagination
      this.currentTaskParams.pageNum = pagination.current
      this.currentTaskParams.pageSize = pagination.pageSize
      this.handleGetTask()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          if (dataIndex === 'code') {
            this.columnSearchParams['task.code'] =
              'like:' + selectedKeys[0].trim()
          } else {
            this.columnSearchParams[dataIndex] =
              'like:' + selectedKeys[0].trim()
          }
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          if (dataIndex === 'deliverTime') {
            this.columnSearchParams['plan.deliverTime'] =
              'btn:' + selectedKeys.toString()
          } else if (dataIndex === 'producingNum') {
            this.columnSearchParams['task.producingNum'] =
              'btn:' + selectedKeys.toString()
          } else {
            this.columnSearchParams[dataIndex] =
              'btn:' + selectedKeys.toString()
          }
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      if (dataIndex === 'code') {
        this.columnSearchParams['task.code'] = null
      } else if (dataIndex === 'deliverTime') {
        this.columnSearchParams['plan.deliverTime'] = null
      } else if (dataIndex === 'producingNum') {
        this.columnSearchParams['task.producingNum'] = null
      } else {
        this.columnSearchParams[dataIndex] = null
      }
    },
    // 监听滚动到底部
    handleScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      // console.log(scrollTop, clientHeight, scrollHeight)
      if (scrollTop + clientHeight === scrollHeight) {
        // alert('滚动到底部啦')
        if (this.targetsPagination.pageNum < this.targetTotalPages) {
          this.targetsPagination.pageNum++
          this.getTargets(this.targetsPagination)
        }
      }
    },

    handleDeleteTarget(id) {
      this.$confirm({
        content: '确认删除此目标?',
        onOk: () => {
          deleteWeekTarget(id).then(res => {
            if (res.status === 204) {
              this.$message.success('删除成功')
              this.initTargets()
            }
          })
        }
      })
    },
    targetConfirm() {
      this.initTargets()
      this.handleGetTask()
      this.expandedKeys = [this.activeTargetKey]
      this.taskEditModalVisible = false
    },
    targetCancel() {
      this.taskEditModalVisible = false
    },

    handleUpdateTask() {
      this.targetTaskModalVisible = true
    },
    taskEditConfirm() {
      this.handleInitTask()
      this.handleGetTask()
      this.targetTaskModalVisible = false
    },

    taskEditCancel() {
      this.targetTaskModalVisible = false
    },
    onContextMenuClick(treeKey, menuKey) {
      if (menuKey === 'addWeek') {
        this.editType = '新增周目标'
        this.activeTargetKey = treeKey
        this.editTarget = {}
        this.taskEditModalVisible = true
      }
      if (menuKey === 'editWeek') {
        this.editType = '编辑周目标'
        this.taskEditModalVisible = true
      }

      if (menuKey === 'editWeekTask') {
        this.editGroupId = 'week'
        this.handleUpdateTask()
      }

      if (menuKey === 'deleteWeek') {
        this.handleDeleteTarget(Number(treeKey.split('-')[3]))
      }
      if (menuKey === 'editGroup') {
        this.handleUpdateTask()
      }
    },
    handleRightClick({ event, node }) {
      const key = node.eventKey
      this.selectedKeys = [node.eventKey]
      // 右键周目标
      if (key.indexOf('week') > -1 && key.indexOf('group') === -1) {
        this.editTarget = node.dataRef
        this.activeTargetKey = node.$parent.eventKey
        this.editWeekTargetId = node.dataRef.id
      }

      // 右键班组
      if (key.indexOf('group') > -1) {
        this.editGroupId = Number(key.split('-')[5])
        this.editWeekTargetId = node.$parent.dataRef.id
        this.editTitle = `${node.$parent.dataRef.title} - ${node.dataRef.title}`
      }
    },
    onSearchChange(e) {
      const value = e.target.value
      const expandedKeys = dataList
        .map(item => {
          if (item.title.indexOf(value) > -1) {
            return getParentKey(item.key, this.treeData)
          }
          return null
        })
        .filter((item, i, self) => item && self.indexOf(item) === i)
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true
      })
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedTaskIds = selectedRows.map(val => val.taskId)
    },
    handleDeleteTask(ids) {
      const key = this.selectedKeys[0]
      const arr = Array.isArray(ids) ? ids : [ids]
      this.$confirm({
        content: '确认删除选中任务?',
        onOk: () => {
          if (key.indexOf('group') > 0) {
            const groupId = key.split('-')[5]
            const weekId = key.split('-')[3]
            deleteWeekGroupTargetTask(weekId, groupId, arr).then(res => {
              this.$message.success('删除任务成功')
              this.handleGetTask()
            })
          } else {
            const weekId = key.split('-')[3]
            deleteWeekTargetTask(weekId, arr).then(res => {
              this.$message.success('删除任务成功')
              this.handleGetTask()
            })
          }
        }
      })
    },
    handleExportPlanCommand(command) {
      const keys = this.selectedKeys[0]
      let weekId, groupId
      if (keys.indexOf('group') > 0) {
        weekId = keys.split('-')[3]
        groupId = keys.split('-')[5]
      } else {
        weekId = keys.split('-')[3]
      }
      console.log(weekId)
      this.exportLoading = true
      let baseSearchParams = {}
      if (groupId) {
        baseSearchParams = {
          groupId: 'eq:' + groupId,
          ...this.columnSearchParams
        }
      }
      switch (command) {
        case 'FILTERED':
          exportFilteredTarget(weekId, baseSearchParams)
            .then(res => {
              this.exportLoading = false
              this.downloadPlan(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        default:
          this.exportLoading = false
          break
      }
    },
    downloadPlan(blobData) {
      const currentDate = +Date.now()
      downloadItem(blobData, 'application/vnd.ms-excel', '周目标任务数据表')
    },
    handleUpdateTargetNum(column) {
      const value = this.selectedKeys[0]
      const targetId = value.split('-')[3]
      const groupId = value.split('-')[5]
      this.targetNumEditItem = {
        targetNum: column.targetProductNum,
        taskId: column.taskId,
        targetId,
        groupId
      }
      this.targetNumEditModalVisible = true
    },
    targetNumEditConfirm() {
      this.targetNumEditModalVisible = false
      this.handleGetTask()
    },
    targetNumEditCancel() {
      this.targetNumEditModalVisible = false
    },

    handleShowProcessRoute(code, taskId) {
      this.processRouteTaskCode = code
      this.processRouteTaskId = taskId
      this.targetProcessRouteModalVisible = true
    },
    handleShowProcessRouteConfirm() {
      this.targetProcessRouteModalVisible = false
    },
    async handleDownloadFile(id, fileName) {
      this.loading = true
      let url = `/crafts/${id}/file`
      await handleDownLoad(url, '', fileName)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../target.scss';
</style>
