<template>
  <a-modal class="modal-container" visible="true" :title="title + '工序'" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="operation" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="工序编号" prop="operationCode">
        <a-input v-model.trim="operation.operationCode" maxlength="20" placeholder="请输入工序编号" />
      </a-form-model-item>
      <a-form-model-item label="工序名称" prop="operationName">
        <a-input v-model.trim="operation.operationName" maxlength="20" placeholder="请输入工序名称" />
      </a-form-model-item>
      <a-form-model-item label="工序类型" prop="operationType">
        <a-radio-group v-model="operation.operationType">
          <a-radio :value="1">本部工序</a-radio>
          <a-radio :value="2">委外工序</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="最新单价(元/小时)" prop="unitPrice">
        <a-input-number v-model="operation.unitPrice" placeholder="请输入最新单价" :min="0" :max="999999" :precision="2" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updateOperation, addOperation } from '@/api/operation'
import * as R from 'ramda'
export default {
  props: {
    modalTitle: String,
    operationForm: Object
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 7,
        offset: 2
      },
      wrapperCol: {
        span: 12,
        offset: 1
      },
      loading: false,
      operation: {
        id: this.operationForm.id,
        operationType: this.operationForm.operationType,
        operationCode: this.operationForm.operationCode,
        operationName: this.operationForm.operationName,
        unitPrice: this.operationForm.unitPrice
      },
      rules: {
        operationType: [
          {
            required: true,
            message: '请选择工序类型',
            trigger: 'change'
          }
        ],
        operationCode: [
          {
            required: true,
            message: '请输入工序编号',
            trigger: 'blur'
          }
        ],
        operationName: [
          {
            required: true,
            message: '请输入工序名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function() {
      return this.modalTitle === '新增'
    },
    isUpdate: function() {
      return this.modalTitle === '更新'
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      console.log(this.operation)
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.addOperation(this.operation)
          } else if (this.isUpdate) {
            this.updateOperation(this.operation)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updateOperation(operationFormData) {
      this.loading = true
      updateOperation(operationFormData.id, operationFormData)
        .then(res => {
          if (res.data.id) {
            const successMessage = '更新工序成功'
            const failMessage = '更新工序失败，请重试！'
            this.$emit('confirm')
            this.$message.success(successMessage)
          } else {
            this.$message.error(failMessage)
          }
        })
        .catch(
          // 工序失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    addOperation(operationFormData) {
      this.loading = true
      const submitData = R.clone(operationFormData)
      submitData.id = null
      addOperation(submitData)
        .then(res => {
          if (res.data.id) {
            this.$emit('confirm')
            this.$message.success('新增工序成功')
          } else {
            this.$message.error('新增工序失败，请重试！')
          }
        })
        .catch(
          // 工序失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    }
  }
}
</script>

<style scoped>
</style>
