import { notification } from 'ant-design-vue'

/**
 * 文件上传
 * @param {Object} file 文件对象
 */
export const uploadFile = (_this, file, getData) => {
  _this.loading = true

  // 成功处理
  if (file.status === 'done') {
    notification.success({
      message: '上传文件成功',
      description: `上传的文件: ${file.name}`,
      duration: 3
    })
    getData()
  }

  // 失败处理
  if (file.status === 'error') {
    notification.error({
      message: '上传文件失败',
      description: '上传文件失败，请稍后重试',
      duration: 3
    })
    _this.loading = false
  }
}
