<!-- 结算管理 -->
<template>
  <div class="app-container">
    <div class="top-tip">
      <div class="selected-wrapper">
        <span v-if="hasSelected">
          已选中
          <strong>{{ selectedRowKeys.length }}</strong> 项
        </span>
        <a-dropdown v-if="hasSelected">
          <a class="batch-operate-link" @click="e => e.preventDefault()">
            <a-icon type="edit" />批量操作
          </a>

          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="handleBatchPrint()">批量打印对账单</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-table
      :key="tableKey"
      row-key="id"
      bordered
      :scroll="{ x: 1700 }"
      :columns="settlementColumns"
      :data-source="settlementList"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: inventoryRowSelection.getCheckboxProps }"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-else-if="column.searchType === 'number'">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px;"
            :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
            @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <div slot="settlementNumber" slot-scope="text, column">
        <el-link type="primary" @click="handleCheckDetail(column)">{{ text }}</el-link>
      </div>
      <span slot="warehouseType" slot-scope="text">
        <a-tag v-if="text===1" color="green">半成品</a-tag>
        <a-tag v-if="text===0" color="red">成品</a-tag>
        <a-tag v-if="text===2" color="red">工装</a-tag>
        <a-tag v-if="text===3" color="red">材料</a-tag>
      </span>
      <span slot="isParts" slot-scope="text">
        <a-tag v-if="text===0" color="green">否</a-tag>
        <a-tag v-if="text===1" color="red">是</a-tag>
      </span>
      <span slot="settleType" slot-scope="text">
        <a-tag v-if="text===0" color="red">挂账</a-tag>
        <a-tag v-if="text===1" color="green">现款</a-tag>
      </span>
      <span slot="settleStatus" slot-scope="text">
        <a-tag v-if="text===1" color="blue">预对账</a-tag>
        <a-tag v-if="text===2" color="orange">已开票</a-tag>
        <a-tag v-if="text===3" color="green">已结算</a-tag>
      </span>
      <div slot="settleReceiptNumbers" slot-scope="text">{{ text.join(',') }}</div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <template slot="invoiceNumber" slot-scope="text, column">
        <template v-if="column.invoiceNumber">
          <span>{{ text }}</span>
        </template>
        <template v-else>
          <el-link type="primary" @click="handleSettleSingleProducts(column)">点击录入</el-link>
        </template>
      </template>
      <div slot="operation" slot-scope="text, column">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item v-if="column.settleStatus === 2" @click="handleReturnSettle(column, '开票')">开票撤销</a-menu-item>
            <a-menu-item v-if="column.settleStatus === 3" @click="handleReturnSettle(column, '结算')">结算撤销</a-menu-item>
            <a-menu-item v-if="column.settleStatus !== 1 && column.settleStatus !== 3" @click="handleUpadteStatus(column)">结算信息录入</a-menu-item>
            <a-menu-item v-if="column.settleStatus !== 2 && column.settleStatus !== 3" @click="handleSaveSettleProof(column)">开票信息录入</a-menu-item>
            <a-menu-item @click="handleCheckLog(column)">查看操作日志</a-menu-item>
          </a-menu>
          <a>
            操作
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <el-dropdown class="export-craft" @command="handleExportCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出对账记录</el-button>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item command="SELECTED">导出当前已选工艺</el-dropdown-item>
        <el-dropdown-item command="PAGE">导出当前页工艺</el-dropdown-item>-->
        <el-dropdown-item command="FILTERED">导出当前筛选记录</el-dropdown-item>
        <!-- <el-dropdown-item command="ALL">导出所有工艺</el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
    <SettlementStatus
      v-if="settleStatusVisible"
      :id="updateStatusId"
      :account="updateStatusAccount"
      :update-status-number="updateStatusNumber"
      @confirm="handleSettlementsStatusConfirmed"
      @cancel="handleSettlementsStatusCancel"
    />
    <SettleProofModal
      v-if="settleProofVisible"
      :id="proofId"
      :proof-number="proofNumber"
      @confirm="handleSettlementsProofConfirmed"
      @cancel="handleSettlementsProofCancel"
    />
    <SettleDetailModal
      v-if="detailModalVisible"
      :id="detailId"
      :settlement-number="detailNumber"
      :detail-status="detailStatus"
      @confirm="handleSettlementsDetailConfirmed"
    />
    <SettlementReturn
      v-if="settlementReturnVisible"
      :settlement-ids="retutnNumber"
      :type="returnType"
      @cancel="settlementReturnCancel"
      @confirm="settlementReturnConfirm"
    />
    <LogModal v-if="logModalVisible" :id="logId" type="settlement" @cancel="handleLogModalConfirmed" />
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { settlementColumns, inventoryRowSelection } from './dataLogic'
import { downloadItem } from '@/utils/api.request'
import { getSettlements, exportAllSettlements } from '@/api/settlements'
import SettlementStatus from '@/components/SettlementStatus'
import SettleProofModal from '@/components/SettleProofModal'
import SettleDetailModal from '@/components/SettleDetailModal'
import SettlementReturn from '@/components/SettlementReturn'
import LogModal from '@/components/LogModal'

export default {
  components: {
    SettlementStatus,
    SettleProofModal,
    SettleDetailModal,
    SettlementReturn,
    LogModal,
  },
  data() {
    return {
      tableKey: 0,
      loading: false,
      settlementList: [],
      settlementColumns,
      inventoryRowSelection,
      selectedRowKeys: [],
      selectedRowPlanIds: [],
      searchInput: null,
      baseURL: '',
      fileURL: '',
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      currentQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
      },
      currentEditType: '更新',
      currentCraft: {},
      currentPlanCode: '',
      currentProcessRoute: [],
      visible: false,
      routeModalVisible: false,
      columnSearchParams: {},
      ifShowSettleRecordModal: false,
      selectedSettleRows: [],
      settleIds: [],
      exportLoading: false,
      settleStatusVisible: false,
      updateStatusId: '',
      updateStatusNumber: '',
      updateStatusAccount: '',

      proofId: '',
      proofNumber: '',
      settleProofVisible: false,

      detailId: '',
      detailNumber: '',
      detailModalVisible: false,
      detailStatus: '',

      logId: '',
      logModalVisible: false,

      settlementReturnVisible: false,
      retutnNumber: '',
      returnType: '',
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
  },
  created() {
    // 初始化加载数据
    this.baseURL = process.env.VUE_APP_BASE_API
    this.getSettlements(this.currentQueryParams)
  },
  methods: {
    // 获取工艺数据
    getSettlements(data) {
      this.loading = true
      getSettlements(data)
        .then((res) => {
          this.settlementList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getSettlementsByParams() {
      const cQueryParams = deepClone(this.currentQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getSettlements(queryParams)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    // 重置搜索筛选条件
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedSettleRows = selectedRows
      this.selectedRowKeys = selectedRowKeys
      console.log(selectedRows, selectedRowKeys)
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      console.log(filtersKeys)
      if (filtersKeys.includes('settleStatus')) {
        delete this.columnSearchParams['settleStatus']
        if (filters['settleStatus'].length > 0) {
          this.columnSearchParams['settleStatus'] = `in:${filters[
            'settleStatus'
          ].toString()}`
        } else {
          delete this.columnSearchParams['settleStatus']
        }
      }

      if (filtersKeys.includes('settleType')) {
        delete this.columnSearchParams['settleType']
        if (filters['settleType'].length > 0) {
          this.columnSearchParams['settleType'] = `in:${filters[
            'settleType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['settleType']
        }
      }
      if (filtersKeys.includes('recordWarehouseType')) {
        delete this.columnSearchParams['recordWarehouseType']
        if (filters['recordWarehouseType'].length > 0) {
          this.columnSearchParams['recordWarehouseType'] = `in:${filters[
            'recordWarehouseType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['recordWarehouseType']
        }
      }

      this.pagination = pagination
      this.currentQueryParams.pageNum = pagination.current
      this.currentQueryParams.pageSize = pagination.pageSize

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentQueryParams.sort_by = null
      }

      this.getSettlementsByParams()
    },
    handleSettleSingleProducts(column) {
      this.settleIds = [column.id]
      this.ifShowSettleRecordModal = true
    },
    handleSettleProducts() {
      this.settleIds = this.selectedRowKeys
      this.ifShowSettleRecordModal = true
    },
    handleSettleProductssModalCanceled() {
      this.ifShowSettleRecordModal = false
    },
    handleSettleProductsModalConfirmed() {
      this.settleIds = []
      this.selectedRowKeys = []
      console.log(this.selectedKeys, this.selectedRowKeys)
      this.ifShowSettleRecordModal = false
      this.getSettlementsByParams()
    },
    handleExportCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'FILTERED':
          exportAllSettlements({
            ...this.columnSearchParams,
          })
            .then((res) => {
              this.exportLoading = false
              this.download(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        default:
          this.exportLoading = false
          break
      }
    },
    download(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        '结算记录表' + currentDate
      )
    },
    handleUpadteStatus(column) {
      this.updateStatusAccount = column.settleMoney
      this.updateStatusNumber = column.settlementNumber
      this.updateStatusId = column.id
      this.settleStatusVisible = true
    },
    handleSettlementsStatusConfirmed() {
      this.settleStatusVisible = false
      this.getSettlementsByParams()
    },
    handleSettlementsStatusCancel() {
      this.settleStatusVisible = false
    },
    handleCheckDetail(column) {
      this.detailId = column.id
      this.detailNumber = column.settlementNumber
      this.detailStatus = column.settleStatus
      this.detailModalVisible = true
    },
    handleSaveSettleProof(column) {
      this.proofId = column.id
      this.proofNumber = column.settlementNumber
      this.settleProofVisible = true
    },
    handleSettlementsProofConfirmed() {
      this.settleProofVisible = false
      this.getSettlementsByParams()
    },
    handleSettlementsProofCancel() {
      this.settleProofVisible = false
    },
    handleSettlementsDetailConfirmed() {
      this.detailModalVisible = false
    },
    handleCheckLog(column) {
      this.logId = column.id
      this.logModalVisible = true
    },
    handleLogModalConfirmed() {
      this.logModalVisible = false
    },
    handleReturnSettle(column, type) {
      this.returnType = type
      this.retutnNumber = column.id
      this.settlementReturnVisible = true
    },
    settlementReturnCancel() {
      this.settlementReturnVisible = false
    },
    settlementReturnConfirm() {
      this.settlementReturnVisible = false
      this.getSettlementsByParams()
    },
    handleBatchPrint() {
      const route = this.$router.resolve({
        path: `/account-check-card`,
        query: {
          ids: this.selectedRowKeys.join(','),
        },
      })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-container {
  .file-wrapper {
    width: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .filename-wrapper {
      display: inline-block;
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 10px;

      a {
        color: #67c23a;
      }
    }

    .filename-delete {
      color: #ff4d4f;
      margin-right: 8px;
    }
  }
  .batch-operate-link {
    padding-left: 20px;
    cursor: pointer;
    color: #409eff;
  }

  .file-link {
    color: #409eff;
  }

  .craft-download-button {
    position: absolute;
    right: 20px;
    top: 70px;
  }
  .operation {
    .operation-btn {
      cursor: pointer;
      color: #409eff;
    }
  }

  .export-craft {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }
}
</style>
