<template>
  <div class="container">
    <a-row class="row-wrapper" type="flex" justify="space-between" align="middle">
      <a-col :span="12">
        <div class="trends-change-wrapper">
          <div class="target-statistics">
            <div class="filter">
              <span class="title">各班组产品平均停留时间</span>
              <div class="date-container">
                <p>日期:</p>
                <a-range-picker v-model="taskTrendsDate" value-format="YYYY-MM-DD" :allowClear="false" />
              </div>
            </div>
            <div class="trends-change-inner">
              <div class="target-content" ref="taskTrends">
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="trends-change-wrapper">
          <div class="target-statistics">
            <div class="filter">
              <span class="title">各工序产品平均停留时间</span>
              <div class="date-container">
                <p>日期:</p>
                <a-range-picker v-model="taskTrendsChangeDate" value-format="YYYY-MM-DD" :allowClear="false" />
              </div>
            </div>
            <div class="trends-change-inner">
              <div class="target-content" ref="taskTrendsChange">
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

    <div class="trends-change-wrapper">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">各班组产品平均停留时间趋势</span>
          <div class="date-container">
            <a-radio-group class="date-type" v-model="costDimension">
              <a-radio-button value="WEEK">周</a-radio-button>
              <a-radio-button value="MONTH">月</a-radio-button>
            </a-radio-group>
            <p>日期:</p>
            <a-range-picker v-model="costDate" value-format="YYYY-MM-DD" :allowClear="false" />
          </div>
        </div>
        <div class="trends-change-inner">
          <div class="target-content" ref="cost">
          </div>
        </div>
      </div>
    </div>
    <div ref="scrapAmountWrapper" class="trends-change-wrapper">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">各工序产品平均停留时间趋势</span>
          <div class="date-container">
            <a-radio-group class="date-type" v-model="scrapAmountDimension">
              <a-radio-button value="WEEK">周</a-radio-button>
              <a-radio-button value="MONTH">月</a-radio-button>
            </a-radio-group>
            <p>日期:</p>
            <a-range-picker v-model="scrapAmountDate" value-format="YYYY-MM-DD" :allowClear="false" />
          </div>
        </div>
        <div class="scrap-amount-inner">
          <div class="target-content" ref="scrapAmount">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { Chart } from '@antv/g2';
import { getProductStayTime, getProductTechStayTime, getTaskTrends } from '@/api/tocDbr'
import { EParamsPrefix, EStaticParamsDimension, EStaticParamsType } from '@/enums'
import { DefaultOnlyDateFormat, lastHalfYearDateArr, lastOneMonthDateArr, lastOneYearDateArr } from '@/common/consts'

export default {
  name: 'IntelligentScheduling',
  data() {
    return {
      taskTrendsDate: lastOneMonthDateArr,
      taskTrendsData: [],
      taskTrendsChangeDate: lastOneMonthDateArr,
      taskTrendsChangeData: [],
      costDate: lastHalfYearDateArr,
      costDimension: EStaticParamsDimension.WEEK,
      costData: [],
      scrapAmountDate: lastHalfYearDateArr,
      scrapAmountDimension: EStaticParamsDimension.WEEK,
      scrapAmountData: [],
    }
  },
  computed: {
    taskTrendsDateRangeFormat() {
      return (this.taskTrendsDate || lastOneMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    },
    taskTrendsDateChangeRangeFormat() {
      return (this.taskTrendsChangeDate || lastOneMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    },
    costDateRangeFormat() {
      return (this.costDate || lastOneMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    },
    scrapAmountDateRangeFormat() {
      return (this.scrapAmountDate || lastOneMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    }
  },
  mounted() {
    // this.taskTrendsChart = this.renderBarChart(this.$refs.taskTrends);
    // this.taskTrendsChangeChart = this.renderBarChangeChart(this.$refs.taskTrendsChange);

    this.getTaskTrendsData()
    this.getTaskTrendsChangeData()
    this.getCostData()
    this.getScrapAmountData()
  },
  watch: {
    taskTrendsDate() {
      this.getTaskTrendsData()
    },
    taskTrendsData() {
      // this.updateBarChart(this.taskTrendsChart, newVal || [])
      this.renderBarChart()
    },
    taskTrendsChangeDate() {
      this.getTaskTrendsChangeData()
    },
    taskTrendsChangeData() {
      this.renderBarChangeChart()
    },
    costDate() {
      this.getCostData()
    },
    costDimension(newVal) {
      if (newVal === EStaticParamsDimension.WEEK) {
        this.costDate = lastHalfYearDateArr
      } else if (newVal === EStaticParamsDimension.MONTH) {
        this.costDate = lastOneYearDateArr
      } else {
        this.getCostData()
      }
    },
    costData() {
      this.renderCostChart()
    },
    scrapAmountDate() {
      this.getScrapAmountData()
    },
    scrapAmountDimension(newVal) {
      if (newVal === EStaticParamsDimension.WEEK) {
        this.scrapAmountDate = lastHalfYearDateArr
      } else if (newVal === EStaticParamsDimension.MONTH) {
        this.scrapAmountDate = lastOneYearDateArr
      } else {
        this.getScrapAmountData()
      }
    },
    scrapAmountData() {
      this.renderScrapAmountChart()
    },
  },
  methods: {
    renderBarChart(container) {
      if (this.taskTrendsChart) {
        this.taskTrendsChart.destroy()
      }

      this.taskTrendsChart = new Chart({
        container: this.$refs.taskTrends,
        autoFit: true,
      });

      this.taskTrendsChart
        .interval()
        .data(this.taskTrendsData)
        .encode('x', 'groupName')
        .encode('y', 'avgStayHour')
        .encode('color', 'groupName')
        .axis('x', { title: false })
        .axis('y', { title: '平均停留时间（小时）' })
        .tooltip((data) => ({
          name: '平均停留时间',
          value: `${data.avgStayHour}（小时）`,
        }))

      this.taskTrendsChart.render();
    },
    renderBarChangeChart(container) {
      if (this.taskTrendsChangeChart) {
        this.taskTrendsChangeChart.destroy()
      }

      this.taskTrendsChangeChart = new Chart({
        container: this.$refs.taskTrendsChange,
        autoFit: true,
        insetRight: 10,
      });

      this.taskTrendsChangeChart
        .interval()
        .data(this.taskTrendsChangeData)
        .encode('x', 'operationName')
        .encode('y', 'avgStayHour')
        .encode('color', 'operationName')
        .axis('x', { title: false })
        .axis('y', { title: '平均停留时间（小时）' })
        .tooltip((data) => ({
          name: '平均停留时间',
          value: `${data.avgStayHour}（小时）`,
        }))

      // 渲染可视化
      this.taskTrendsChangeChart.render();
    },
    renderCostChart(container) {
      if (this.costChart) {
        this.costChart.destroy()
      }

      this.costChart = new Chart({
        container: this.$refs.cost,
        autoFit: true,
        insetRight: 10,
      });

      this.costChart
        .line()
        .data(this.costData)
        .encode('x', (d) => moment(d.date).format(DefaultOnlyDateFormat))
        .encode('y', 'avgStayTime')
        .axis('y', { title: '平均停留时间（小时）' })
        // .encode('series', 'type')
        .encode('color', 'type')
        // .scale('y', { type: 'log' })
        .label({
          text: 'type',
          selector: 'last',
          fontSize: 10,
        })
        .tooltip({ channel: 'y' });

      // this.costChart.interaction('tooltip', { filter: (d, i) => i < 10 })

      // 渲染可视化
      this.costChart.render();
    },
    renderScrapAmountChart(container) {
      if (this.scrapAmountChart) {
        this.scrapAmountChart.destroy()
      }

      this.scrapAmountChart = new Chart({
        container: this.$refs.scrapAmount,
        autoFit: true,
        height: 700
        // insetRight: 10,
      });

      this.scrapAmountChart
        .line()
        .data(this.scrapAmountData)
        .encode('x', (d) => moment(d.date).format(DefaultOnlyDateFormat))
        .encode('y', 'avgStayTime')
        .axis('y', { title: '平均停留时间（小时）' })
        // .encode('series', 'type')
        .encode('color', 'type')
        .scale('y', {
          // type: 'point',
          type: 'linear',
          tickCount: 10,
          // nice: true
        })
        .label({
          text: 'type',
          selector: 'last',
          fontSize: 10,
          transform: [{ type: 'overlapDodgeY' }],
        })
        // .scrollbar({
        //   y: true
        // })
        .tooltip({
          channel: 'y',
        })
        .interaction({
          tooltip: {
            shared: true,
            mount: this.$refs.scrapAmountWrapper,
            css: {
              '.g2-tooltip': {
                'min-width': '660px !important',
                'border-radius': ' 0.25em !important',
              },
              '.g2-tooltip-list': {
                display: 'flex',
                'justify-content': 'flex-start',
                'flex-wrap': 'wrap',
              },
              '.g2-tooltip-list-item': {
                width: '200px',
                'padding-right': '42px',
              },
            },
          },
        })

      // this.scrapAmountChart.interaction('tooltip', { filter: (d, i) => i < 10 })

      // 渲染可视化
      this.scrapAmountChart.render();
    },
    updateBarChart(chart, newData) {
      // 获得 Interval Mark
      const interval = chart.getNodesByType('interval')[0];

      interval.data(newData);
      // 重新渲染
      chart.render();
    },
    getTaskTrendsData() {
      getProductStayTime({
        startTime: this.taskTrendsDateRangeFormat[0],
        endTime: this.taskTrendsDateRangeFormat[1]
      }).then((res) => {
        this.taskTrendsData = res.data || []
      }).catch(e => {
        console.error(e)
      })
    },
    getTaskTrendsChangeData() {
      getProductTechStayTime({
        startTime: this.taskTrendsDateChangeRangeFormat[0],
        endTime: this.taskTrendsDateChangeRangeFormat[1]
      }).then((res) => {
        this.taskTrendsChangeData = res.data || []
      }).catch(e => {
        console.error(e)
      })
    },
    getCostData() {
      getTaskTrends({
        type: `${EParamsPrefix.EQ}${EStaticParamsType.PGS}`,
        dimension: `${EParamsPrefix.EQ}${this.costDimension}`,
        generateTime: `${EParamsPrefix.BTN}${this.costDateRangeFormat.join(',')}`
      }).then((res) => {
        const newData = res.data || [];
        const processData = newData.flatMap(item => item.extendInfo.map(info => ({
          date: item.generateTime,
          type: info.groupName,
          avgStayTime: info.avgStayHour || 0
        })));

        this.costData = processData;
      }).catch(e => {
        console.error(e)
      })
    },
    getScrapAmountData() {
      getTaskTrends({
        type: `${EParamsPrefix.EQ}${EStaticParamsType.POS}`,
        dimension: `${EParamsPrefix.EQ}${this.scrapAmountDimension}`,
        generateTime: `${EParamsPrefix.BTN}${this.scrapAmountDateRangeFormat.join(',')}`
      }).then((res) => {
        const newData = res.data || [];
        const processData = newData.flatMap(item => item.extendInfo.map(info => ({
          date: item.generateTime,
          type: info.operationName,
          avgStayTime: info.avgStayHour || 0
        })))

        this.scrapAmountData = processData
      }).catch(e => {
        console.error(e)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  border-radius: 8px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 6px rgb(206, 206, 206);
}

/* 非激活窗口 */
::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(206, 206, 206);
}

.container {
  width: 100%;
  padding-bottom: 30px;
  background: rgb(240, 242, 245);
  display: flex;
  flex-direction: column;
  align-items: center;

  .row-wrapper {
    width: 100%;
    padding-left: 2.5%;

    .trends-change-wrapper {
      margin-top: 20px;
      background: white;
      position: relative;
    }
  }

  .trends-change-wrapper {
    width: 95%;
    margin-top: 20px;
    background: white;
    position: relative;

    .filter {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid rgb(212, 212, 212);
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 60px;
      }

      .date-container {
        display: flex;
        align-items: center;
        font-size: 16px;

        .date-type {
          margin-right: 20px;
        }

        p {
          margin-right: 20px;
        }
      }
    }

    .trends-change-inner {
      height: 530px;
      overflow: auto;
      position: relative;
      padding: 18px;
    }

    .scrap-amount-inner {
      height: 760px;
      overflow: auto;
      position: relative;
      padding: 18px;
    }
  }


}

/deep/ .ant-table-pagination,
.ant-pagination {
  float: left;
}

.target-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .target-content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    position: relative;
  }
}

.page-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  /deep/ .anticon {
    font-size: 48px;
    color: white;
  }
}
</style>
