import request from '@/utils/api.request'

export function getRoutes() {
  return request({
    url: '/routes',
    method: 'get'
  })
}

export function getRoles(data) {
  return request({
    url: `/roles`,
    method: 'get',
    params: data
  })
}

export function getAllRoles(data) {
  return request({
    url: `/roles/all`,
    method: 'get',
    params: data
  })
}

export function addRole(data) {
  return request({
    url: '/roles',
    method: 'post',
    data
  })
}

export function updateRole(id, data) {
  return request({
    url: `/roles/${id}`,
    method: 'put',
    data
  })
}

export function deleteRole(id) {
  return request({
    url: `/roles/${id}`,
    method: 'delete'
  })
}

export function getRoleById(id) {
  return request({
    url: `/roles/${id}`,
    method: 'get'
  })
}

export function getResourceTree() {
  return request({
    url: `/roles/resource-tree`,
    method: 'get'
  })
}

export function getResourcesByRoleId(id) {
  return request({
    url: `/roles/${id}/resources`,
    method: 'get'
  })
}

export function updateResourcesByRoleId(id, data) {
  return request({
    url: `/roles/${id}/resources`,
    method: 'put',
    data
  })
}
