import Cookies from 'js-cookie'

const TokenKey = 'jwtToken'

export function getTokenByCookies() {
  return Cookies.get(TokenKey)
}

export function getTokenByLocalStorage() {
  return localStorage.getItem(TokenKey)
}

export function getAppIdByLocalStorage() {
  return localStorage.getItem('appId')
}

export function setTokenToCookies(token) {
  return Cookies.set(TokenKey, token)
}

export function setTokenToLocalStorage(token) {
  return localStorage.setItem(TokenKey, token)
}

export function setAppIdToLocalStorage(appId) {
  return localStorage.setItem('appId', appId)
}

export function removeTokenFromCookies() {
  return Cookies.remove(TokenKey)
}

export function removeTokenFromLocalStorage() {
  return localStorage.removeItem(TokenKey)
}

export function removeAppIdFromLocalStorage() {
  return localStorage.removeItem('appId')
}
