var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-pagination", {
    staticClass: "el-pagination",
    attrs: {
      background: "",
      layout: "total, sizes, prev, pager, next, jumper",
      total: _vm.total,
      "current-page": _vm.cPage,
      "page-sizes": [5, 10, 20, 30, 40, 50],
      "page-size": _vm.pageSize,
    },
    on: {
      "current-change": _vm.currentChange,
      "size-change": _vm.handleSizeChange,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }