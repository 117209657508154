var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: { visible: true, title: _vm.title, width: "900px" },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          !_vm.standardProcessRoute.length && !_vm.actualProcessRoute.length
            ? _c("a-empty")
            : _vm._e(),
          _vm.standardProcessRoute.length
            ? [
                _c(
                  "div",
                  { staticClass: "wrap" },
                  [
                    _c("p", { staticClass: "title" }, [_vm._v("标准加工路线")]),
                    _c(
                      "a-steps",
                      { attrs: { size: "small", direction: "vertical" } },
                      _vm._l(
                        _vm.standardProcessRoute,
                        function (process, index) {
                          return _c("a-step", {
                            key: index,
                            attrs: {
                              title:
                                "第" +
                                (index + 1) +
                                "步——" +
                                process.operationName,
                              "sub-title":
                                process.verifyProcessHour &&
                                process.verifyTransferOutTime
                                  ? "  核定工时：" +
                                    process.verifyProcessHour +
                                    "小时 " +
                                    "     核定转出日期：" +
                                    process.verifyTransferOutTime
                                  : "",
                              status: "finish",
                              description: process.content,
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.actualProcessRoute.length
            ? [
                _c(
                  "div",
                  { staticClass: "wrap" },
                  [
                    _c("p", { staticClass: "title" }, [_vm._v("实际加工路线")]),
                    _c(
                      "a-steps",
                      { attrs: { size: "small", direction: "vertical" } },
                      _vm._l(_vm.actualProcessRoute, function (process, index) {
                        return _c(
                          "a-step",
                          {
                            key: index,
                            attrs: {
                              title:
                                "第" +
                                (index + 1) +
                                "步——" +
                                process.operationName +
                                "(" +
                                process.realName +
                                ")",
                              status: "finish",
                              description: process.content,
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "subTitle" }, slot: "subTitle" },
                              [
                                process.duration && process.timeRate
                                  ? [
                                      process.duration >
                                      _vm.standardProcessRoute[index]
                                        .verifyProcessHour
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [
                                              _vm._v(
                                                "加工耗时" +
                                                  _vm._s(process.duration) +
                                                  "小时"
                                              ),
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              "加工耗时" +
                                                _vm._s(process.duration) +
                                                "小时"
                                            ),
                                          ]),
                                      _vm._v(
                                        " 耗时占比" +
                                          _vm._s(process.timeRate) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }