var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title,
        "mask-closable": false,
        width: "800px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            { attrs: { loading: _vm.loading }, on: { click: _vm.handleReset } },
            [_vm._v("重置")]
          ),
          _c(
            "a-button",
            {
              attrs: { loading: _vm.loading },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("取消")]
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-transfer",
        {
          staticClass: "tree-transfer",
          attrs: {
            "data-source": _vm.resourcesData,
            "list-style": {
              width: "355px",
              height: "500px",
            },
            "show-search": "",
            "target-keys": _vm.targetKeys,
            titles: ["未拥有权限", "已拥有权限"],
            render: function (item) {
              return "" + item.title
            },
          },
          on: {
            change: _vm.handleChange,
            search: _vm.handleSearch,
            selectChange: _vm.handleSelectChange,
          },
          scopedSlots: _vm._u([
            {
              key: "children",
              fn: function (ref) {
                var ref_props = ref.props
                var direction = ref_props.direction
                var selectedKeys = ref_props.selectedKeys
                var itemSelectAll = ref.on.itemSelectAll
                return [
                  direction === "left"
                    ? _c("a-tree", {
                        attrs: {
                          checkable: "",
                          "expanded-keys": _vm.expandedKeys,
                          "auto-expand-parent": _vm.autoExpandParent,
                          "selected-keys": selectedKeys,
                          "tree-data": _vm.treeData,
                        },
                        on: {
                          expand: _vm.onExpand,
                          check: function (_, props) {
                            _vm.onCheck(
                              _,
                              props,
                              _vm.checkedKeys,
                              itemSelectAll
                            )
                          },
                        },
                        model: {
                          value: _vm.checkedKeys,
                          callback: function ($$v) {
                            _vm.checkedKeys = $$v
                          },
                          expression: "checkedKeys",
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "span",
            { attrs: { slot: "notFoundContent" }, slot: "notFoundContent" },
            [_vm._v("暂无数据")]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }