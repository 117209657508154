export const inquiryColumns = [
  {
    title: '序号',
    width: 80,
    dataIndex: 'index',
    key: 'index',
    align: 'center',
    fixed: 'left',
  },
  {
    title: '询价单号',
    width: 160,
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
    searchType: 'text',
    sorter: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'code',
    },
  },
  {
    title: '报价状态',
    dataIndex: 'status',
    width: 120,
    key: 'status',
    searchType: 'text',
    fixed: 'left',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '待核价',
        value: 'WCA',
      },
      {
        text: '核价中',
        value: 'CAC',
      },
      {
        text: '已取消',
        value: 'CNL',
      },
      {
        text: '审价中',
        value: 'CCK',
      },
      {
        text: '待报出',
        value: 'QUT',
      },
      {
        text: '部分成交',
        value: 'PDL',
      },
      {
        text: '全部成交',
        value: 'ADL',
      },
      {
        text: '已报出',
        value: 'BGN',
      },
      {
        text: '未成交',
        value: 'DNL',
      },
    ],
  },
  {
    title: '客户名称',
    dataIndex: 'clientName',
    key: 'clientName',
    align: 'center',
    width: 200,
    ellipsis: true,
    searchType: 'text',
    sorter: false,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientName',
    },
  },
  {
    title: '客户询价单号',
    dataIndex: 'clientInquiryCode',
    key: 'clientInquiryCode',
    align: 'center',
    width: 200,
    ellipsis: true,
    searchType: 'text',
    sorter: false,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientName',
    },
  },
  {
    title: '询价时间',
    width: 180,
    dataIndex: 'queryTime',
    key: 'queryTime',
    searchType: 'date',
    sorter: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '截止时间',
    width: 180,
    dataIndex: 'expirationTime',
    key: 'expirationTime',
    searchType: 'date',
    sorter: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '距离截止天数',
    width: 130,
    dataIndex: 'daysUntilDueTime',
    key: 'daysUntilDueTime',
    searchType: 'date',
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '产品项数',
    width: 100,
    dataIndex: 'productItemCount',
    key: 'productItemCount',
    searchType: 'number',
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '待核价项数',
    width: 150,
    dataIndex: 'waitCostAccountingCount',
    key: 'waitCostAccountingCount',
    searchType: 'date',
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'waitCostAccountingCount',
    },
  },
  {
    title: '最近报出时间',
    dataIndex: 'latestQuoteTime',
    width: 180,
    key: 'latestQuoteTime',
    searchType: 'date',
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '报价单号',
    width: 200,
    ellipsis: true,
    dataIndex: 'quotationNumberList',
    key: 'quotationNumberList',
    searchType: 'text',
    sorter: false,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '成交项数',
    dataIndex: 'dealCount',
    width: 100,
    key: 'dealCount',
    searchType: 'number',
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '成交率',
    width: 100,
    dataIndex: 'dealRate',
    key: 'dealRate',
    searchType: 'text',
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '谈价次数',
    dataIndex: 'bargainingCount',
    width: 100,
    key: 'bargainingCount',
    searchType: 'number',
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '成交金额',
    dataIndex: 'dealAmount',
    width: 100,
    key: 'dealAmount',
    searchType: 'number',
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '特殊情况说明',
    dataIndex: 'remark',
    key: 'remark',
    align: 'center',
    width: 200,
    ellipsis: true,
    scopedSlots: {
      customRender: 'remark',
    },
  },
  {
    title: '备注',
    dataIndex: 'comment',
    key: 'comment',
    align: 'center',
    width: 200,
    ellipsis: true,
    scopedSlots: {
      customRender: 'remark',
    },
  },
  {
    title: '操作',
    width: 80,
    key: 'operate',
    align: 'center',
    fixed: 'right',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
