<template>
  <div class="app-container">
    <a-table
      :key="tableKey"
      row-key="id"
      bordered
      :scroll="{ x: 1700 }"
      :columns="inventoryColumns"
      :data-source="inventoryList"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <template slot="productName" slot-scope="text, column">
        <el-link type="primary" @click="jumpToOutInventory(column)">{{ text }}</el-link>
      </template>
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-else-if="column.searchType === 'number'">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px;"
            :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
            @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <span slot="warehouseType" slot-scope="text">
        <a-tag v-if="text === 1" color="orange">半成品</a-tag>
        <a-tag v-if="text === 0" color="green">成品</a-tag>
        <a-tag v-if="text === 2" color="blue">工装</a-tag>
        <a-tag v-if="text === 4" color="red">废品</a-tag>
        <a-tag v-if="text === 5" color="red">来料退回库</a-tag>
        <a-tag v-if="text === 3" color="orange">材料</a-tag>
      </span>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
    </a-table>
    <el-dropdown class="export-craft" @command="handleExportCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出库存记录</el-button>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item command="SELECTED">导出当前已选工艺</el-dropdown-item>
        <el-dropdown-item command="PAGE">导出当前页工艺</el-dropdown-item>-->
        <el-dropdown-item command="FILTERED">导出当前筛选记录</el-dropdown-item>
        <!-- <el-dropdown-item command="ALL">导出所有工艺</el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
    <CraftEditModal :modal-title="currentEditType" :craft-form="currentCraft" :visible="visible" />
    <ProcessRouteCard
      v-if="routeModalVisible"
      :modal-title="currentPlanCode"
      :record="currentProcessRoute"
      @cancel="handleRouteModalCanceled"
      @confirm="handleRouteModalConfirmed"
    />
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { inventoryColumns, inventoryRowSelection } from './dataLogic'
import CraftEditModal from '@/components/CraftEditModal'
import ProcessRouteCard from '@/components/ProcessRouteCard'
import { getInventorys, exportInventoryRecord } from '@/api/inventory'
import { getProducts } from '@/api/product'
import { downloadItem } from '@/utils/api.request'

export default {
  components: {
    CraftEditModal,
    ProcessRouteCard,
  },
  data() {
    return {
      tableKey: 0,
      loading: false,
      exportLoading: false,
      inventoryList: [],
      inventoryColumns,
      inventoryRowSelection,
      selectedRowKeys: [],
      selectedRowPlanIds: [],
      searchInput: null,
      baseURL: '',
      fileURL: '',
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      currentInventoryListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
        // 'product.type': 'eq:L',
      },
      currentActiveKey: 'L',
      currentEditType: '更新',
      currentCraft: {},
      currentPlanCode: '',
      currentProcessRoute: [],
      visible: false,
      routeModalVisible: false,
      columnSearchParams: {},
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
  },
  created() {
    // 初始化加载数据
    this.baseURL = process.env.VUE_APP_BASE_API
    this.getInventorys(this.currentInventoryListQueryParams)
  },
  methods: {
    // 获取工艺数据
    getInventorys(data) {
      this.loading = true
      getInventorys(data)
        .then((res) => {
          this.inventoryList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getInventorysByParams() {
      const cQueryParams = deepClone(this.currentInventoryListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getInventorys(queryParams)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    // 重置搜索筛选条件
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowPlanIds = selectedRows.map((val) => {
        return val.planId
      })
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      console.log(filtersKeys)
      if (filtersKeys.includes('warehouseType')) {
        delete this.columnSearchParams['warehouseType']
        if (filters['warehouseType'].length > 0) {
          this.columnSearchParams['warehouseType'] = `in:${filters[
            'warehouseType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['warehouseType']
        }
      }

      this.pagination = pagination
      this.currentInventoryListQueryParams.pageNum = pagination.current
      this.currentInventoryListQueryParams.pageSize = pagination.pageSize

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentInventoryListQueryParams.sort_by =
          sortType + sorter.columnKey
      } else {
        this.currentInventoryListQueryParams.sort_by = null
      }

      this.getInventorysByParams()
    },
    jumpToOutInventory(column) {
      getProducts({
        pageNum: 1,
        pageSize: 10,
        productName: `eq:${column.productName}`,
      }).then((res) => {
        this.$router.push({
          name: 'OutInventory',
          query: {
            productName: column.productName,
            productType: res.data.records[0].type,
            drawingNum: res.data.records[0].drawingNum,
            specification: res.data.records[0].specification,
          },
        })
      })
    },
    handleExportCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'FILTERED':
        const cQueryParams = deepClone(this.currentInventoryListQueryParams)
        const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
        exportInventoryRecord({
            ...queryParams
          })
            .then((res) => {
              this.exportLoading = false
              this.download(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        default:
          this.exportLoading = false
          break
      }
    },
    download(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        '库存记录表' + currentDate
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.app-container {
  .file-wrapper {
    width: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .filename-wrapper {
      display: inline-block;
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 10px;

      a {
        color: #67c23a;
      }
    }

    .filename-delete {
      color: #ff4d4f;
      margin-right: 8px;
    }
  }

  .file-link {
    color: #409eff;
  }

  .craft-download-button {
    position: absolute;
    right: 20px;
    top: 70px;
  }
  .operation {
    .operation-btn {
      cursor: pointer;
      color: #409eff;
    }
  }

  .export-craft {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }
}
</style>
