<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" :title="title" :mask-closable="false" width="1500px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" :loading="submitLoading" @click="handleConfirm">确定</a-button>
    </template>
    <div class="table-container">
      <div class="top-tip">
        <div class="selected-wrapper">
          <span v-if="hasSelected">
            已选中
            <strong>{{ selectedRowKeys.length }}</strong> 项任务
          </span>
        </div>
      </div>
      <a-table
        :key="tableKey"
        row-key="taskId"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, }"
        bordered
        :scroll="{ x: 2000, y: 630 }"
        :columns="taskColumns"
        :data-source="allTaskList"
        :loading="fetchLoading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <div slot="isParts" slot-scope="text">
          <template v-if="text === 0">
            <a-tag color="red">否</a-tag>
          </template>
          <template v-if="text === 1">
            <a-tag color="green">是</a-tag>
          </template>
        </div>
        <div slot="code" slot-scope="text, column">
          <template v-if="memberId && column.targetId">
            <a-badge
              count="周目标"
              :offset="[10, -10]"
              :number-style="{
                fontSize: '12px',
                backgroundColor: '#fff',
                color: 'red',
                boxShadow: '0 0 0 1px #d9d9d9 inset'
              }"
            >
              <span>{{ text }}</span>
            </a-badge>
          </template>
          <template v-else>{{ text }}</template>
        </div>
        <span slot="emergencyType" slot-scope="text">
          <a-tag color="volcano">{{ EmergencyType[text] }}</a-tag>
        </span>
        <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
          <template v-if="column.searchType === 'text'">
            <a-input
              v-ant-ref="c => (searchInput = c)"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
            />
          </template>
          <div v-else-if="column.searchType === 'number'">
            <a-input-number
              v-model="selectedKeys[0]"
              size="small"
              placeholder="最小值"
              :min="0"
              :max="999999"
              style="width: 95px; margin-bottom: 8px; display: inline-block;"
              @change="e => setSelectedKeys([e,selectedKeys[1]])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
            />
            <span>-</span>
            <a-input-number
              v-model="selectedKeys[1]"
              size="small"
              placeholder="最大值"
              :min="selectedKeys[0]"
              :max="999999"
              style="width: 95px; margin-bottom: 8px; display: inline-block;"
              @change="e => setSelectedKeys([selectedKeys[0],e])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
            />
          </div>
          <div v-else-if="column.searchType === 'date'">
            <!-- <a-range-picker
              :show-time="{ format: 'HH:mm:ss' }"
              size="small"
              style="width: 330px; margin-bottom: 8px;"
              :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
              @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
            />-->
            <a-date-picker
              size="small"
              style="width: 200px; margin-bottom: 8px;"
              @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
            />
          </div>
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.key)"
          >搜索</a-button>
          <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
        </div>
        <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        <span v-if="column.status === 2" slot="operation" slot-scope="text, column" class="table-operation">
          <a-dropdown placement="bottomRight">
            <a-menu slot="overlay" class="ant-dropdown-link">
              <a-menu-item @click="handleEditEmergencyType(column)">设置紧急类型</a-menu-item>
            </a-menu>
            <a class="operation-btn">
              操作
              <a-icon type="down" />
            </a>
          </a-dropdown>
        </span>
        <div slot="fileName" slot-scope="text, column" class="file-wrapper">
          <template v-if="column.fileName">
            <div class="filename-wrapper">
              <span @click="handleDownloadFile(column.craftId, column.fileName)" class="file-link">{{ column.fileName }}</span>
            </div>
          </template>
          <template v-else>
            <span>暂无工艺文件</span>
          </template>
        </div>
        <div slot="status" slot-scope="text">
          <template v-if="text === 0">
            <a-badge status="default" />准备中
          </template>
          <template v-if="text === 1">
            <a-badge status="default" />未开始
          </template>
          <template v-if="text === 2">
            <a-badge status="processing" />进行中
          </template>
          <template v-if="text === 3">
            <a-badge status="success" />已完成
          </template>
          <template v-if="text === 9">
            <a-badge status="warning" />暂停中
          </template>
          <template v-if="text === 8">
            <a-badge status="error" />已取消
          </template>
          <template v-if="text === 10">
            <a-badge status="error" />异常终止
          </template>
        </div>
        <div slot="process" slot-scope="text, column" class="table-process">
          <template v-if="column.remainProcessRoute && column.remainProcessRoute.length">
            <a-steps :current="column.remainProcessRoute.length-1" status="finish" size="small">
              <a-step v-for="process in column.remainProcessRoute" :key="process.id" :title="process.operationName">
                <span slot="description" class="process-desc">
                  <p v-if="process.operationType>0&&process.verifyProcessHour" class="description-font">
                    <b>加工工时：</b>
                    {{ process.verifyProcessHour }}
                  </p>
                  <p v-if="process.operationType>0&&process.verifyTransferOutTime" class="description-font">
                    <b>转出日期：</b>
                    {{ process.verifyTransferOutTime }}
                  </p>
                </span>
              </a-step>
            </a-steps>
          </template>
        </div>
        <div slot="targetNum" slot-scope="text, column">
          <a-input
            v-if="editableId === column.taskId"
            style="margin: -5px 0"
            :value="text"
            @change="e => handleChange(e.target.value, record.key, col)"
          />
          <template v-else>{{ column.producingNum }}</template>
          <span>编辑</span>
          <span>取消</span>
        </div>
      </a-table>
    </div>
  </a-modal>
</template>
<script>
import { deepClone } from '@/utils'
import { downloadItem, handleDownLoad } from '@/utils/api.request'

import {
  // 月目标相关接口
  getAllTasks,
  getTasksById,
  batchTasksById,
  updateTasksById,

  // 周目标相关接口
  getWeekTargetTasks,
  getWeekGroupTargetTasks,
  addGroupTasks,
  addWeekTasks,

  // 日目标相关接口
  getAllDayTargetTasks,
  getDayMemberTasks,
  addDayMemberTasks
} from '@/api/target'
import { taskColumns } from './dataLogic'
import { EmergencyType } from '@/enums/index'
export default {
  props: {
    id: Number, // 目标id
    title: String,
    groupId: Number || String, // 班组id
    memberId: Number // 成员id
  },
  data() {
    return {
      EmergencyType,
      baseURL: process.env.VUE_APP_BASE_API,
      tableKey: 0,
      taskColumns,
      allTaskList: [],
      selectedRowKeys: [],
      targetKeys: [],
      columnSearchParams: {},
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      currentTaskParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      totalPages: '',
      fetchLoading: false,
      submitLoading: false,

      totalUserPages: '',
      fetchUserLoading: false,
      editableId: ''
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  mounted() {
    this.getAllTasks(this.currentTaskParams)
    // this.getUserTasks()
  },
  methods: {
    // 根据当前分页数据和搜索条件查询数据
    getTasksByParams() {
      const cQueryParams = deepClone(this.currentTaskParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getAllTasks(queryParams)
    },
    getAllTasks(data, ifChange) {
      if (this.fetchLoading) return
      this.fetchLoading = true
      if (this.memberId) {
        // 获取日目标任务
        this.pagination.pageSize = 10
        this.pagination.pageSizeOptions = ['10', '20', '30']
        this.currentTaskParams.pageSize = 10
        getAllDayTargetTasks(this.id, this.groupId, this.memberId, data)
          .then(res => {
            this.allTaskList = res.data.records
            this.pagination.total = res.data.total
            this.fetchLoading = false
            this.currentUserParams.pageNum = this.currentTaskParams.pageNum
          })
          .catch(err => {
            this.fetchLoading = false
          })
      } else if (this.groupId) {
        if (this.groupId === 'week') {
          // 获取班组的所有可指定任务
          getWeekTargetTasks(this.id, data)
            .then(res => {
              this.allTaskList = res.data.records
              this.pagination.total = res.data.total
              this.fetchLoading = false
              this.currentUserParams.pageNum = this.currentTaskParams.pageNum
            })
            .catch(err => {
              this.fetchLoading = false
            })
        } else {
          // 获取班组的所有可指定任务
          getWeekGroupTargetTasks(this.id, this.groupId, data)
            .then(res => {
              this.allTaskList = res.data.records
              this.pagination.total = res.data.total
              this.fetchLoading = false
              this.currentUserParams.pageNum = this.currentTaskParams.pageNum
            })
            .catch(err => {
              this.fetchLoading = false
            })
        }
      } else {
        getAllTasks(this.id, data)
          .then(res => {
            this.allTaskList = res.data.records
            this.pagination.total = res.data.total
            this.fetchLoading = false
            this.currentUserParams.pageNum = this.currentTaskParams.pageNum
          })
          .catch(err => {
            this.fetchLoading = false
          })
      }
    },
    getUserTasks() {
      if (this.fetchUserLoading) return
      this.fetchUserLoading = true
      if (this.memberId) {
        getDayMemberTasks(
          this.id,
          this.groupId,
          this.memberId,
          this.currentUserParams
        ).then(
          res => {
            res.data.records.map(task => {
              this.targetKeys.push(task.taskId)
            })
            console.log(this.targetKeys)
            this.totalUserPages = res.data.pages
            this.fetchUserLoading = false
            if (this.allTaskList.length - this.targetKeys.length <= 5) {
              if (this.currentTaskParams.pageNum < this.totalPages) {
                this.currentTaskParams.pageNum++
                this.getTasksByParams()
              }
            }
          },
          error => {
            this.fetchUserLoading = false
            return error
          }
        )
      } else if (this.groupId) {
        getWeekGroupTasks(this.id, this.groupId, this.currentUserParams).then(
          res => {
            res.data.records.map(task => {
              this.targetKeys.push(task.taskId)
            })
            console.log(this.targetKeys)
            this.totalUserPages = res.data.pages
            this.fetchUserLoading = false
            if (this.allTaskList.length - this.targetKeys.length <= 5) {
              if (this.currentTaskParams.pageNum < this.totalPages) {
                this.currentTaskParams.pageNum++
                this.getTasksByParams()
              }
            }
          },
          error => {
            this.fetchUserLoading = false
            return error
          }
        )
      } else {
        batchTasksById({ targetId: this.id, ...this.currentUserParams }).then(
          res => {
            res.data.records.map(task => {
              this.targetKeys.push(task.taskId)
            })
            console.log(this.targetKeys)
            this.totalUserPages = res.data.pages
            this.fetchUserLoading = false
            if (this.allTaskList.length - this.targetKeys.length <= 5) {
              if (this.currentTaskParams.pageNum < this.totalPages) {
                this.currentTaskParams.pageNum++
                this.getTasksByParams()
              }
            }
          },
          error => {
            this.fetchUserLoading = false
            return error
          }
        )
      }
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      console.log(this.targetKeys)
      this.submitLoading = true
      if (this.memberId) {
        addDayMemberTasks(
          this.id,
          this.groupId,
          this.memberId,
          this.selectedRowKeys
        ).then(
          res => {
            this.$message.success('任务规划成功')
            this.submitLoading = false
            this.$emit('confirm')
          },
          error => {
            this.submitLoading = false
          }
        )
      } else if (this.groupId) {
        if (this.groupId === 'week') {
          addWeekTasks(this.id, this.selectedRowKeys).then(
            res => {
              this.$message.success('任务规划成功')
              this.submitLoading = false
              this.$emit('confirm')
            },
            error => {
              this.submitLoading = false
            }
          )
        } else {
          addGroupTasks(this.id, this.groupId, this.selectedRowKeys).then(
            res => {
              this.$message.success('任务规划成功')
              this.submitLoading = false
              this.$emit('confirm')
            },
            error => {
              this.submitLoading = false
            }
          )
        }
      } else {
        updateTasksById(this.id, this.selectedRowKeys).then(
          res => {
            this.$message.success('任务规划成功')
            this.submitLoading = false
            this.$emit('confirm')
          },
          error => {
            this.submitLoading = false
          }
        )
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      console.log(selectedKeys, dataIndex)
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          if (dataIndex === 'code') {
            this.columnSearchParams['task.code'] =
              'like:' + selectedKeys[0].trim()
          } else if (dataIndex === 'plan.deliverTime') {
            this.columnSearchParams['plan.deliverTime'] =
              'lt:' + selectedKeys[0].trim()
          } else {
            this.columnSearchParams[dataIndex] =
              'like:' + selectedKeys[0].trim()
          }
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          if (dataIndex === 'deliverTime') {
            this.columnSearchParams['plan.deliverTime'] =
              'btn:' + selectedKeys.toString()
          } else if (dataIndex === 'producingNum') {
            this.columnSearchParams['task.producingNum'] =
              'btn:' + selectedKeys.toString()
          } else {
            this.columnSearchParams[dataIndex] =
              'btn:' + selectedKeys.toString()
          }
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      if (dataIndex === 'code') {
        this.columnSearchParams['task.code'] = null
      } else if (dataIndex === 'deliverTime') {
        this.columnSearchParams['plan.deliverTime'] = null
      } else if (dataIndex === 'producingNum') {
        this.columnSearchParams['task.producingNum'] = null
      } else {
        this.columnSearchParams[dataIndex] = null
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('task.emgType')) {
        delete this.columnSearchParams['task.emgType']
        if (filters['task.emgType'].length > 0) {
          this.columnSearchParams['task.emgType'] = `in:${filters[
            'task.emgType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.emgType']
        }
      }
      if (filtersKeys.includes('product.type')) {
        delete this.columnSearchParams['product.type']
        if (filters['product.type'].length > 0) {
          this.columnSearchParams['product.type'] = `in:${filters[
            'product.type'
          ].toString()}`
        } else {
          delete this.columnSearchParams['product.type']
        }
      }
      if (filtersKeys.includes('plan.isParts')) {
        delete this.columnSearchParams['plan.isParts']
        if (filters['plan.isParts'].length > 0) {
          this.columnSearchParams['plan.isParts'] = `in:${filters[
            'plan.isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['plan.isParts']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        if (sorter.columnKey === 'code') {
          sorter.columnKey = 'task.code'
        }
        this.currentTaskParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentTaskParams.sort_by = null
      }
      this.pagination = pagination
      this.currentTaskParams.pageNum = pagination.current
      this.currentTaskParams.pageSize = pagination.pageSize
      this.getTasksByParams()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    async handleDownloadFile(id, fileName) {
      this.fetchLoading = true
      let url = `/crafts/${id}/file`
      await handleDownLoad(url, '', fileName)
      this.fetchLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-modal-body {
  height: 800px;
  overflow-y: scroll;
}
/deep/ .ant-checkbox-inner {
  border-color: black;
}
/deep/ .ant-modal {
  top: 50px;
  left: 100px;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}
.modal-container {
  overflow-y: scroll;
  .table-container {
    width: 100%;
    height: 620px;
    .table-process {
      float: left;
      width: auto;
    }

    .process-desc p {
      margin: 0;
      width: 250px;
    }

    .description-font {
      color: rgb(31, 29, 29);
    }
  }
}
/deep/ .ant-steps-item-content {
  width: 250px;
}
.filename-wrapper {
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .file-link {
    color: #409eff;
    text-decoration: underline;
  }
}
</style>
