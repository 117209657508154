import request from '@/utils/api.request'

/**
 * 分页获取材料信息
 * @param {object} data
 */
export function getMaterials(data) {
  return request({
    url: `/materials`,
    method: 'get',
    params: data,
  })
}

/**
 * 根据id获取指定的材料信息
 * @param {number} id
 */
export function getMaterialById(id) {
  return request({
    url: `/materials/${id}`,
    method: 'get',
  })
}

/**
 * 增加材料
 * @param {object} data
 */
export function addMaterial(data) {
  return request({
    url: `/materials`,
    method: 'post',
    data,
  })
}

/**
 * 更新指定id的材料
 * @param {number} id
 * @param {object} data
 */
export function updateMaterial(id, data) {
  return request({
    url: `/materials/${id}`,
    method: 'put',
    data,
  })
}

/**
 * 删除指定id的材料
 * @param {number} id
 */
export function deleteMaterial(id) {
  return request({
    url: `/materials/${id}`,
    method: 'delete',
  })
}

/**
 * 根据名字获取材料
 * @param {number} id
 */
export function getMaterialByMaterialName(name) {
  return request({
    url: `/materials/all`,
    method: 'get',
    params: {
      materialName: `like:${name}`,
    },
  })
}

/**
 * 根据图号获取材料
 * @param {number} id
 */
export function getMaterialByMaterialCode(name) {
  return request({
    url: `/materials/all`,
    method: 'get',
    params: {
      materialCode: `like:${name}`,
    },
  })
}

/**
 * 根据图号获取材料
 * @param {number} id
 */
export function getMaterialByMaterialInnerCode(name) {
  return request({
    url: `/materials/all`,
    method: 'get',
    params: {
      innerCode: `like:${name}`,
    },
  })
}

/**
 * 根据图号获取材料
 * @param {number} id
 */
export function getAllMaterialByMaterialCode() {
  return request({
    url: `/materials/all`,
    method: 'get',
  })
}

/**
 * 获取材料信息导入模板
 */
export function getMaterialImportTemplate() {
  return request({
    url: `/materials/import-template`,
    method: 'get',
    responseType: 'blob',
  })
}

/**
 * 模糊搜索
 * @param {object} data
 */
export function fuzzyMaterial(data) {
  return request({
    url: `/materials/all-fuzzy`,
    method: 'get',
    params: data,
  })
}

/**
 * 导出所有材料
 * @param {*} data
 */
export function exportAllMaterials(data) {
  return request({
    url: `/materials/exports/all`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}
