// GREEN 绿单
// RED 红单
// YELLOW 黄单
// BLACK 黑单
export const ETaskColor = {
  GREEN: 'green',
  RED: 'red',
  YELLOW: 'yellow',
  BLACK: 'black',
}

export const ETaskColorTextMap = {
  GREEN: '绿单',
  RED: '红单',
  YELLOW: '黄单',
  BLACK: '黑单',
}

export const ETaskColorMapArr = [
  ['绿单', ETaskColor.GREEN],
  ['红单', ETaskColor.RED],
  ['黄单', ETaskColor.YELLOW],
  ['黑单', ETaskColor.BLACK],
]

export const ScrapAmountTextMap = {
  normalRecordAmount: '普通报废金额',
  bottleneckRecordAmount: '瓶颈报废金额',
}
