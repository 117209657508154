var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: "批量录入加工工序记录",
        "mask-closable": false,
        width: "1000px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.batchTasksRecord,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-bottom": "5px" },
              attrs: { label: "记录人员", prop: "userId" },
            },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    "show-search": "",
                    placeholder: "请选择记录人员",
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterUser,
                  },
                  on: { select: _vm.handleUserSelected },
                  model: {
                    value: _vm.batchTasksRecord.userId,
                    callback: function ($$v) {
                      _vm.$set(_vm.batchTasksRecord, "userId", $$v)
                    },
                    expression: "batchTasksRecord.userId",
                  },
                },
                _vm._l(_vm.currentUserList, function (user) {
                  return _c(
                    "a-select-option",
                    { key: user.id, attrs: { value: user.id } },
                    [
                      _vm._v(
                        _vm._s(user.realName) + "(" + _vm._s(user.uid) + ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-bottom": "5px" },
              attrs: { label: "工序", prop: "operationId" },
            },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    "show-search": "",
                    placeholder: "请选择工序",
                    "option-filter-prop": "children",
                  },
                  model: {
                    value: _vm.batchTasksRecord.operationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.batchTasksRecord, "operationId", $$v)
                    },
                    expression: "batchTasksRecord.operationId",
                  },
                },
                _vm._l(_vm.currentOperationList, function (operation) {
                  return _c(
                    "a-select-option",
                    { key: operation.id, attrs: { value: operation.id } },
                    [
                      _vm._v(
                        _vm._s(operation.operationName) +
                          "(" +
                          _vm._s(operation.operationCode) +
                          ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.batchTasksRecord.operationId === 263
            ? _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-bottom": "5px" },
                  attrs: { label: "入库库房", prop: "warehouseType" },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择入库库房" },
                      model: {
                        value: _vm.batchTasksRecord.warehouseType,
                        callback: function ($$v) {
                          _vm.$set(_vm.batchTasksRecord, "warehouseType", $$v)
                        },
                        expression: "batchTasksRecord.warehouseType",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("成品库"),
                      ]),
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("半成品库"),
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("工装库"),
                      ]),
                      _c("a-select-option", { attrs: { value: 3 } }, [
                        _vm._v("材料库"),
                      ]),
                      _c("a-select-option", { attrs: { value: 4 } }, [
                        _vm._v("废品库"),
                      ]),
                      _c("a-select-option", { attrs: { value: 5 } }, [
                        _vm._v("来料退回库"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-bottom": "5px" },
              attrs: { label: "任务编码(批量)", prop: "selectedTasks" },
            },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    mode: "multiple",
                    "label-in-value": "",
                    value: _vm.batchTasksRecord.selectedTasks,
                    placeholder: "请扫描或输入任务编号",
                    "filter-option": false,
                    "not-found-content": _vm.fetching ? undefined : null,
                  },
                  on: { search: _vm.fetchTask, change: _vm.handleChange },
                },
                [
                  _vm.fetching
                    ? _c("a-spin", {
                        attrs: { slot: "notFoundContent", size: "small" },
                        slot: "notFoundContent",
                      })
                    : _vm._e(),
                  _vm._l(_vm.tasks, function (task) {
                    return _c("a-select-option", { key: task.id }, [
                      _vm._v(" " + _vm._s(task.code) + " "),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.recordSet, function (record, index) {
        return _c(
          "a-row",
          { key: index },
          [
            _c("div", { staticClass: "desc" }, [
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "label" }, [_vm._v("任务编码:")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.recordSet[index].code)),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "label" }, [_vm._v("产品名称:")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.recordSet[index].productName)),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "label" }, [_vm._v("图号:")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.recordSet[index].drawingNum)),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "label" }, [_vm._v("规格:")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.recordSet[index].specification)),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "label" }, [_vm._v("应交检数量:")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.recordSet[index].producingNum)),
                ]),
              ]),
            ]),
            _c(
              "a-form-model",
              {
                ref: "recordForm" + index,
                refInFor: true,
                attrs: {
                  layout: "inline",
                  model: _vm.recordSet[index],
                  rules: _vm.setRules[index],
                },
              },
              [
                _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      label: "实际交检数量",
                      prop: "actualCheckCount",
                      "label-col": _vm.lineLabelCol,
                      "wrapper-col": _vm.lineWrapperCol,
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "130px" },
                      attrs: { "max-length": 10 },
                      model: {
                        value: _vm.recordSet[index].actualCheckCount,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.recordSet[index],
                            "actualCheckCount",
                            _vm._n($$v)
                          )
                        },
                        expression: "recordSet[index].actualCheckCount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "60px" },
                    attrs: {
                      label: "是否自检",
                      "label-col": _vm.lineLabelCol,
                      "wrapper-col": _vm.lineWrapperCol,
                    },
                  },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "是",
                        "un-checked-children": "否",
                        "default-checked": false,
                      },
                      on: {
                        change: function (val) {
                          return _vm.handleContralCheck(val, index)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm.recordSet[index].isSelfCheck
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "下一加工工序",
                          "label-col": _vm.lineLabelCol,
                          "wrapper-col": _vm.lineWrapperCol,
                          prop: "nextOperationId",
                        },
                      },
                      [
                        _c(
                          "a-select",
                          {
                            staticStyle: { width: "150px" },
                            attrs: {
                              "show-search": "",
                              placeholder: "请选择下一加工工序",
                              "option-filter-prop": "children",
                            },
                            model: {
                              value: _vm.recordSet[index].nextOperationId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.recordSet[index],
                                  "nextOperationId",
                                  $$v
                                )
                              },
                              expression: "recordSet[index].nextOperationId",
                            },
                          },
                          _vm._l(_vm.allOperationList, function (operation) {
                            return _c(
                              "a-select-option",
                              {
                                key: operation.id,
                                attrs: { value: operation.id },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(operation.operationName) +
                                    "(" +
                                    _vm._s(operation.operationCode) +
                                    ") "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.recordSet.length > 1 ? _c("a-divider") : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }