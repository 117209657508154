var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "a-tabs",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "active-key": _vm.currentActiveKey, type: "card" },
          on: { change: _vm.handleTabChange },
        },
        [
          _c("a-tab-pane", { key: "L", attrs: { tab: "L计划" } }),
          _c("a-tab-pane", { key: "D", attrs: { tab: "D计划" } }),
          _c("a-tab-pane", { key: "G", attrs: { tab: "G计划" } }),
        ],
        1
      ),
      _c("a-table", {
        key: _vm.tableKey,
        staticClass: "dashboard-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          columns: _vm.taskColumns,
          "data-source": _vm.taskList,
          loading: _vm.loading,
          pagination: _vm.pagination,
          scroll: { x: 5000, y: 670 },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "codeTag",
            fn: function (text, column) {
              return _c(
                "a-tag",
                {
                  attrs: {
                    color:
                      column.taskStatus === 4
                        ? "red"
                        : column.taskStatus === 5
                        ? "orange"
                        : "",
                  },
                },
                [_vm._v(_vm._s(text))]
              )
            },
          },
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.key
                              )
                            },
                          },
                        }),
                      ]
                    : column.searchType === "numberInput"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "ant-ref",
                                rawName: "v-ant-ref",
                                value: function (c) {
                                  return (_vm.searchInput = c)
                                },
                                expression: "c => (searchInput = c)",
                              },
                            ],
                            staticStyle: {
                              width: "188px",
                              "margin-bottom": "8px",
                              display: "block",
                            },
                            attrs: {
                              value: selectedKeys[0],
                              placeholder: "搜索 " + column.title,
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys(e ? [e] : [])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "190px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "emgType",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === "NML"
                    ? _c("a-tag", { attrs: { color: "volcano" } }, [
                        _vm._v("普通"),
                      ])
                    : _vm._e(),
                  text === "MFN"
                    ? _c("a-tag", { attrs: { color: "cyan" } }, [
                        _vm._v("必完件"),
                      ])
                    : _vm._e(),
                  text === "UGC"
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("紧急件"),
                      ])
                    : _vm._e(),
                  text === "IUG"
                    ? _c("a-tag", { attrs: { color: "purple" } }, [
                        _vm._v("插入急件"),
                      ])
                    : _vm._e(),
                  text === "TUG"
                    ? _c("a-tag", { attrs: { color: "blue" } }, [
                        _vm._v("转入急件"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("准备中 "),
                      ]
                    : _vm._e(),
                  text === 1
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("未开始 "),
                      ]
                    : _vm._e(),
                  text === 2
                    ? [
                        _c("a-badge", { attrs: { status: "processing" } }),
                        _vm._v("进行中 "),
                      ]
                    : _vm._e(),
                  text === 3
                    ? [
                        _c("a-badge", { attrs: { status: "success" } }),
                        _vm._v("已完成 "),
                      ]
                    : _vm._e(),
                  text === 4
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("超期进行中 "),
                      ]
                    : _vm._e(),
                  text === 5
                    ? [
                        _c("a-badge", { attrs: { status: "warning" } }),
                        _vm._v("即将超期 "),
                      ]
                    : _vm._e(),
                  text === 8
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("已取消 "),
                      ]
                    : _vm._e(),
                  text === 9
                    ? [
                        _c("a-badge", { attrs: { status: "warning" } }),
                        _vm._v("暂停中 "),
                      ]
                    : _vm._e(),
                  text === 10
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("异常终止 "),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "taskType",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0 ? [_c("span", [_vm._v("普通任务")])] : _vm._e(),
                  text === 1 ? [_c("span", [_vm._v("返修任务")])] : _vm._e(),
                  text === 2
                    ? [_c("span", [_vm._v("生产补投任务")])]
                    : _vm._e(),
                  text === 3
                    ? [_c("span", [_vm._v("退库补投任务")])]
                    : _vm._e(),
                  text === 4 ? [_c("span", [_vm._v("分批任务")])] : _vm._e(),
                  text === 5 ? [_c("span", [_vm._v("其他任务")])] : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "isParts",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0
                    ? [_c("a-tag", { attrs: { color: "red" } }, [_vm._v("否")])]
                    : _vm._e(),
                  text === 1
                    ? [
                        _c("a-tag", { attrs: { color: "green" } }, [
                          _vm._v("是"),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "fileName",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "file-wrapper" },
                [
                  column.fileName
                    ? [
                        _c("div", { staticClass: "filename-wrapper" }, [
                          _c(
                            "span",
                            {
                              staticClass: "file-link",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDownloadFile(
                                    column.craftId,
                                    column.fileName
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(column.fileName))]
                          ),
                        ]),
                      ]
                    : [_c("span", [_vm._v("暂无工艺文件")])],
                ],
                2
              )
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "standardProcess",
            fn: function (text, column) {
              return _c(
                "el-link",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleShowStandardProcess(column)
                    },
                  },
                },
                [_vm._v("点击查看")]
              )
            },
          },
          {
            key: "process",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-process" },
                [
                  column.actualProcessRoute.length > 0
                    ? [
                        _c(
                          "a-steps",
                          {
                            attrs: {
                              current: column.actualProcessRoute.length - 1,
                              status: "finish",
                              size: "small",
                            },
                          },
                          [
                            _vm._l(
                              column.actualProcessRoute,
                              function (process) {
                                return _c(
                                  "a-step",
                                  {
                                    key: process.id,
                                    attrs: {
                                      title: process.operationName,
                                      "sub-title": process.realName,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "process-desc",
                                        attrs: { slot: "description" },
                                        slot: "description",
                                      },
                                      [
                                        process.recordStartTime
                                          ? _c(
                                              "p",
                                              {
                                                staticClass: "description-font",
                                              },
                                              [
                                                _c("b", [_vm._v("转入时间：")]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      process.recordStartTime
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "p",
                                          { staticClass: "description-font" },
                                          [
                                            _c("b", [_vm._v("转出时间：")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(process.generateTime) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        process.duration !== null
                                          ? _c(
                                              "p",
                                              {
                                                staticClass: "description-font",
                                              },
                                              [
                                                _c("b", [_vm._v("停留时间：")]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(process.duration) +
                                                    "小时 "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        process.recordType === 1
                                          ? _c("p", [
                                              _c("b", [_vm._v("流转数：")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(process.checkCount) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        process.recordType === 2
                                          ? _c(
                                              "p",
                                              { staticClass: "red-font" },
                                              [
                                                _c("b", [_vm._v("报废数：")]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      process.abandonCount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        process.recordType === 2
                                          ? _c("p", [
                                              _c("b", [_vm._v("返工数：")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    process.repairedCount
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        process.recordType === 2
                                          ? _c("p", [
                                              _c("b", [_vm._v("超差数：")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    process.outOfToleranceCount
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            column.nextOperation &&
                            column.nextOperation.operationType !== null
                              ? _c(
                                  "a-step",
                                  {
                                    attrs: {
                                      status:
                                        column.nextOperation.operationType ===
                                        -1
                                          ? "error"
                                          : column.nextOperation
                                              .operationType === 0
                                          ? "finish"
                                          : "process",
                                      title: column.nextOperation.operationName,
                                    },
                                  },
                                  [
                                    column.nextOperation.operationType === 0 ||
                                    column.nextOperation.operationType === -1
                                      ? _c("a-icon", {
                                          attrs: { slot: "icon", type: "stop" },
                                          slot: "icon",
                                        })
                                      : column.nextOperation.operationType ===
                                        -2
                                      ? _c("a-icon", {
                                          attrs: {
                                            slot: "icon",
                                            type: "pause-circle",
                                          },
                                          slot: "icon",
                                        })
                                      : _c("a-icon", {
                                          attrs: {
                                            slot: "icon",
                                            type: "loading",
                                          },
                                          slot: "icon",
                                        }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "process-desc",
                                        attrs: { slot: "description" },
                                        slot: "description",
                                      },
                                      [
                                        column.nextOperation.operationType >
                                          0 &&
                                        column.nextOperation.verifyProcessHour
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("核定加工工时："),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    column.nextOperation
                                                      .verifyProcessHour
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        column.nextOperation.operationType >
                                          0 &&
                                        column.nextOperation
                                          .verifyTransferOutTime
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("核定转出日期："),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    column.nextOperation
                                                      .verifyTransferOutTime
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    : [
                        _c(
                          "a-steps",
                          {
                            attrs: {
                              current: 0,
                              status: "process",
                              size: "small",
                            },
                          },
                          [
                            _c(
                              "a-step",
                              {
                                attrs: {
                                  title: "下料",
                                  description: "等待下料",
                                },
                              },
                              [
                                _c("a-icon", {
                                  attrs: { slot: "icon", type: "loading" },
                                  slot: "icon",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                ],
                2
              )
            },
          },
        ]),
      }),
      _vm.routeModalVisible
        ? _c("ProcessRouteCard", {
            attrs: {
              "modal-title": _vm.currentTaskCode,
              "select-visible": _vm.processRouteSelectVisiable,
              record: _vm.currentProcessRoute,
              "craft-data": _vm.craftCardData,
            },
            on: {
              cancel: _vm.handleRouteModalCanceled,
              confirm: _vm.handleRouteModalConfirmed,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }