<template>
  <div class="app-container">
    <a-table
      :key="tableKey"
      row-key="id"
      :columns="recordColumns"
      :data-source="records"
      :loading="loading"
      :expanded-row-keys="expandedRowKeys"
      :pagination="pagination"
      bordered
      :scroll="{ x: 1800, y: 700 }"
      class="records-table"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <a-input
          v-if="column.searchType===&quot;text&quot;"
          v-ant-ref="c => (searchInput = c)"
          :placeholder="`搜索 ${column.title}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block;"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
        />
        <div v-if="column.searchType===&quot;number&quot;">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-if="column.searchType===&quot;date&quot;">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px;"
            :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
            @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys,clearFilters,column.key)">重置</a-button>
      </div>
      <span slot="recordType" slot-scope="text">
        <a-tag v-if="text===1" color="volcano">加工记录</a-tag>
        <a-tag v-if="text===2" color="geekblue">检验记录</a-tag>
        <a-tag v-if="text===3" color="cyan">返工加工记录</a-tag>
        <a-tag v-if="text===4" color="pink">返工检验记录</a-tag>
      </span>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <span slot="operation" slot-scope="text, column" class="table-operation">
        <a-dropdown placement="topRight">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleUpdateRecord(column)">更新记录</a-menu-item>
            <a-menu-item>
              <a-popconfirm title="确定删除该记录？" ok-text="确定" cancel-text="取消" placement="bottom" @confirm="handleDeleteRecord(column.id)">
                <a-icon slot="icon" type="question-circle-o" style="color: red" />删除记录
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
          <a>
            操作
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </span>
    </a-table>
    <el-dropdown class="export-record" @command="handleExportRecordCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出生产记录</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="FILTERED">导出当前筛选记录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <RecordEdit
      v-if="editModalVisible"
      :modal-title="currentEditType"
      :record-form="currentRecord"
      @cancel="handleRecordModalCanceled"
      @confirm="handleRecordModalConfirmed"
    />
  </div>
</template>
<script>
import {
  getRecords,
  deleteRecord,
  updateRecord,
  exportFilteredRecords
} from '@/api/record'
import { CONSTANTS } from '@/utils/constants'
import { downloadItem } from '@/utils/api.request'
import RecordEdit from '@/components/RecordEdit'
import * as R from 'ramda'
import { recordColumns } from './dataLogic.js'

export default {
  components: {
    RecordEdit
  },
  data() {
    return {
      tableKey: 0,
      pagination: {
        total: 0,
        pageSize: 20, // 每页中显示20条数据
        showSizeChanger: true,
        pageSizeOptions: ['20', '30', '40', '50'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        showQuickJumper: true
      },
      records: [],
      recordColumns,
      currentRecord: {},
      exportIds: [],
      loading: false,
      exportLoading: false,
      editModalVisible: false,
      editType: '',
      recordDetailModalVisible: false,
      currentRecordListQueryParams: {
        pageNum: 1,
        pageSize: 20
      },
      columnSearchParams: {},
      otherFilterParams: null,
      columnFilterParams: null
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },

  created() {
    this.getRecords(this.currentRecordListQueryParams)
  },
  methods: {
    async getRecords(data) {
      this.loading = true
      try {
        const res = await getRecords(data)
        this.records = res.data.records
        this.pagination.total = res.data.total
        this.loading = false
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    },
    deleteRecord(recordId) {
      deleteRecord(recordId)
        .then(res => {
          if (res) {
            this.$message.success('删除记录成功！')
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getRecords(this.initRecordListQueryParams)
          } else {
            this.$message.error('删除记录失败，请重试！')
          }
        })
        .catch(
          // 记录失败原因
          reason => {
            this.modalConfirmButtonLoading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    handleUpdateRecord(column) {
      this.currentEditType = '更新'
      this.currentRecord = column
      this.currentRecord.isSelfCheck = false
      this.editModalVisible = true
    },
    handleDeleteRecord(recordId) {
      this.deleteRecord(recordId)
    },
    handleRecordModalConfirmed() {
      this.editModalVisible = false
      this.getRecords(this.currentRecordListQueryParams)
    },
    handleRecordModalCanceled() {
      this.editModalVisible = false
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },

    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = ''
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination = pagination
      this.currentRecordListQueryParams.pageNum = pagination.current
      this.currentRecordListQueryParams.pageSize = pagination.pageSize
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentRecordListQueryParams.sort_by = sortType + sorter.columnKey
      }
      this.columnSearchParams['recordType'] = ''
      if (filters.recordType && filters.recordType.length > 0) {
        this.columnSearchParams['recordType'] =
          'in:' + filters.recordType.toString()
      }
      this.currentRecordListQueryParams = R.merge(
        this.currentRecordListQueryParams,
        this.columnSearchParams
      )
      this.currentRecordListQueryParams = R.reject(R.equals(''))(
        this.currentRecordListQueryParams
      )
      this.getRecords(this.currentRecordListQueryParams)
    },
    getExportIdsFromPage() {
      this.exportIds = this.records.map(item => {
        return item.id
      })
    },
    getExportIdsFromSelection() {
      this.exportIds = this.selectedRowKeys
    },
    handleExportRecordCommand(command) {
      switch (command) {
        case 'FILTERED':
          this.exportLoading = true
          exportFilteredRecords(this.currentRecordListQueryParams)
            .then(res => {
              this.downloadRecord(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })

          break
        default:
          break
      }
    },
    downloadRecord(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.RECORD_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    }
  }
}
</script>
<style scoped>
.app-container {
  padding: 50px 20px;

  .records-table {
    width: 100%;
    max-height: 590px;
  }

  .table-operation {
    a {
      cursor: pointer;
      color: #409eff;
    }
  }

  .permission-tree {
    margin-bottom: 30px;
  }
}

.record-table-expand {
  font-size: 0;

  label {
    width: 155px;
    color: #99a9bf;
  }

  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.export-record {
  float: right;
  top: 10px;
  right: 20px;
}

.top-tip {
}

/deep/ .ant-table-pagination,
.ant-pagination {
  float: left;
}
</style>
