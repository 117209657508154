var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "top-tip" }, [
        _c(
          "div",
          { staticClass: "selected-wrapper" },
          [
            _vm.hasSelected
              ? _c("span", [
                  _vm._v(" 已选中 "),
                  _c("strong", [_vm._v(_vm._s(_vm.selectedRowKeys.length))]),
                  _vm._v(" 项 "),
                ])
              : _vm._e(),
            _vm.hasSelected
              ? _c(
                  "a-dropdown",
                  [
                    _c(
                      "a",
                      {
                        staticClass: "batch-operate-link",
                        on: {
                          click: function (e) {
                            return e.preventDefault()
                          },
                        },
                      },
                      [
                        _c("a-icon", { attrs: { type: "edit" } }),
                        _vm._v("批量操作 "),
                      ],
                      1
                    ),
                    _c(
                      "a-menu",
                      { attrs: { slot: "overlay" }, slot: "overlay" },
                      [
                        _c("a-menu-item", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleBatchPrint()
                                },
                              },
                            },
                            [_vm._v("批量打印对账单")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("a-table", {
        key: _vm.tableKey,
        attrs: {
          "row-key": "id",
          bordered: "",
          scroll: { x: 1700 },
          columns: _vm.settlementColumns,
          "data-source": _vm.settlementList,
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            getCheckboxProps: _vm.inventoryRowSelection.getCheckboxProps,
          },
          pagination: _vm.pagination,
          loading: _vm.loading,
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.key
                              )
                            },
                          },
                        }),
                      ]
                    : column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "190px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "settlementNumber",
            fn: function (text, column) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCheckDetail(column)
                        },
                      },
                    },
                    [_vm._v(_vm._s(text))]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "warehouseType",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === 1
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("半成品"),
                      ])
                    : _vm._e(),
                  text === 0
                    ? _c("a-tag", { attrs: { color: "red" } }, [_vm._v("成品")])
                    : _vm._e(),
                  text === 2
                    ? _c("a-tag", { attrs: { color: "red" } }, [_vm._v("工装")])
                    : _vm._e(),
                  text === 3
                    ? _c("a-tag", { attrs: { color: "red" } }, [_vm._v("材料")])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "isParts",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === 0
                    ? _c("a-tag", { attrs: { color: "green" } }, [_vm._v("否")])
                    : _vm._e(),
                  text === 1
                    ? _c("a-tag", { attrs: { color: "red" } }, [_vm._v("是")])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "settleType",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === 0
                    ? _c("a-tag", { attrs: { color: "red" } }, [_vm._v("挂账")])
                    : _vm._e(),
                  text === 1
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("现款"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "settleStatus",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === 1
                    ? _c("a-tag", { attrs: { color: "blue" } }, [
                        _vm._v("预对账"),
                      ])
                    : _vm._e(),
                  text === 2
                    ? _c("a-tag", { attrs: { color: "orange" } }, [
                        _vm._v("已开票"),
                      ])
                    : _vm._e(),
                  text === 3
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("已结算"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "settleReceiptNumbers",
            fn: function (text) {
              return _c("div", {}, [_vm._v(_vm._s(text.join(",")))])
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "invoiceNumber",
            fn: function (text, column) {
              return [
                column.invoiceNumber
                  ? [_c("span", [_vm._v(_vm._s(text))])]
                  : [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleSettleSingleProducts(column)
                            },
                          },
                        },
                        [_vm._v("点击录入")]
                      ),
                    ],
              ]
            },
          },
          {
            key: "operation",
            fn: function (text, column) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-dropdown",
                    [
                      _c(
                        "a-menu",
                        {
                          staticClass: "ant-dropdown-link",
                          attrs: { slot: "overlay" },
                          slot: "overlay",
                        },
                        [
                          column.settleStatus === 2
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleReturnSettle(
                                        column,
                                        "开票"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("开票撤销")]
                              )
                            : _vm._e(),
                          column.settleStatus === 3
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleReturnSettle(
                                        column,
                                        "结算"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("结算撤销")]
                              )
                            : _vm._e(),
                          column.settleStatus !== 1 && column.settleStatus !== 3
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpadteStatus(column)
                                    },
                                  },
                                },
                                [_vm._v("结算信息录入")]
                              )
                            : _vm._e(),
                          column.settleStatus !== 2 && column.settleStatus !== 3
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSaveSettleProof(column)
                                    },
                                  },
                                },
                                [_vm._v("开票信息录入")]
                              )
                            : _vm._e(),
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleCheckLog(column)
                                },
                              },
                            },
                            [_vm._v("查看操作日志")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        [
                          _vm._v(" 操作 "),
                          _c("a-icon", { attrs: { type: "down" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c(
        "el-dropdown",
        {
          staticClass: "export-craft",
          on: { command: _vm.handleExportCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出对账记录")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选记录"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.settleStatusVisible
        ? _c("SettlementStatus", {
            attrs: {
              id: _vm.updateStatusId,
              account: _vm.updateStatusAccount,
              "update-status-number": _vm.updateStatusNumber,
            },
            on: {
              confirm: _vm.handleSettlementsStatusConfirmed,
              cancel: _vm.handleSettlementsStatusCancel,
            },
          })
        : _vm._e(),
      _vm.settleProofVisible
        ? _c("SettleProofModal", {
            attrs: { id: _vm.proofId, "proof-number": _vm.proofNumber },
            on: {
              confirm: _vm.handleSettlementsProofConfirmed,
              cancel: _vm.handleSettlementsProofCancel,
            },
          })
        : _vm._e(),
      _vm.detailModalVisible
        ? _c("SettleDetailModal", {
            attrs: {
              id: _vm.detailId,
              "settlement-number": _vm.detailNumber,
              "detail-status": _vm.detailStatus,
            },
            on: { confirm: _vm.handleSettlementsDetailConfirmed },
          })
        : _vm._e(),
      _vm.settlementReturnVisible
        ? _c("SettlementReturn", {
            attrs: { "settlement-ids": _vm.retutnNumber, type: _vm.returnType },
            on: {
              cancel: _vm.settlementReturnCancel,
              confirm: _vm.settlementReturnConfirm,
            },
          })
        : _vm._e(),
      _vm.logModalVisible
        ? _c("LogModal", {
            attrs: { id: _vm.logId, type: "settlement" },
            on: { cancel: _vm.handleLogModalConfirmed },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }