<template>
  <a-modal class="modal-container" visible="true" :title="title" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item :label="label">
        <span v-if="inventoryData">{{ type === "save" ? inventoryData.putInNumber : inventoryData.takeOutNumber }}</span>
      </a-form-model-item>
      <a-form-model-item v-if="type === 'out'" label="模板" prop="template">
        <a-radio-group v-model="template" style="width: 400px; fontWeight: 400;">
          <a-radio :value="1">模板1</a-radio>
          <a-radio :value="2">模板2（渝江）</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
export default {
  props: {
    type: String,
    inventoryData: Object,
  },
  data() {
    return {
      title: this.type === 'save' ? '入库单查看' : '出库单查看',
      label: this.type === 'save' ? '入库单号' : '出库单号',
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      template: 1,
      loading: false,
      form: {
        isParts: this.inventoryData.isParts,
      },
      rules: {
        isParts: [
          {
            required: true,
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type === 'save') {
            if(this.inventoryData.recordWarehouseType === 4) {
              const route = this.$router.resolve({
                path: '/waste-receipt-card',
                query: {
                  receiptNumber: this.inventoryData.putInNumber,
                }
              })
              window.open(route.href, '_blank')
            } else {
              const route = this.$router.resolve({
                path: '/save-inventory-card',
                query: {
                  receiptNumber: this.inventoryData.putInNumber,
                },
              })
              window.open(route.href, '_blank')
            }
            this.$emit('confirm')
          } else {
            const route = this.$router.resolve({
              path:
                this.template === 1
                  ? '/out-inventory-card'
                  : '/new-out-inventory-card',
              query: {
                receiptNumber: this.inventoryData.takeOutNumber,
              },
            })
            window.open(route.href, '_blank')
            this.$emit('confirm')
          }
        } else {
          console.log('error submit!!')
          this.loading = false
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
