import request from '@/utils/api.request'

/**
 * 分页获取产品信息
 * @param {object} data
 */
export function getProducts(data) {
  return request({
    url: `/products`,
    method: 'get',
    params: data
  })
}

/**
 * 根据id获取指定的产品信息
 * @param {number} id
 */
export function getProductById(id) {
  return request({
    url: `/products/${id}`,
    method: 'get'
  })
}

/**
 * 增加产品
 * @param {object} data
 */
export function addProduct(data) {
  return request({
    url: `/products`,
    method: 'post',
    data
  })
}

/**
 * 更新指定id的产品
 * @param {number} id
 * @param {object} data
 */
export function updateProduct(id, data) {
  return request({
    url: `/products/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除指定id的产品
 * @param {number} id
 */
export function deleteProduct(id) {
  return request({
    url: `/products/${id}`,
    method: 'delete'
  })
}

/**
 * 根据产品类型获取产品信息
 */
export function getProductsByType(type, orderId) {
  const flag = typeof orderId === 'number'
  return request({
    url: `/products/types/${type}${flag ? `?orderId=${orderId}` : ''}`,
    method: 'get'
  })
}

/**
 * 根据id删除指定产品的工艺文件
 */
export function deleteProductPdf(id) {
  return request({
    url: `/products/${id}/file`,
    method: 'delete'
  })
}

/**
 * 根据订单获取产品信息
 */
export function getProductsByOrder(id, data) {
  return request({
    url: `/orders/${id}/products?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: 'get'
  })
}

/**
 * 获取产品的所有类型
 */
export function getProductTypes() {
  return request({
    url: `/products/types`,
    method: 'get'
  })
}

/**
 * 通过产品id新增对应产品的加工计划
 */
export function addPlanByProduct(id, data) {
  return request({
    url: `/products/${id}/plan`,
    method: 'post',
    data
  })
}

/**
 * 通过产品id下达对应产品的加工计划
 */
export function addProductPlan(id, data) {
  return request({
    url: `/products/${id}/plan`,
    method: 'post',
    data
  })
}

/**
 * 通过产品id预览对应的原始客户工艺文件
 */
export function showProductPdf(id) {
  return request({
    url: `/products/${id}/file`,
    method: 'get'
  })
}

/**
 * 导出所有产品
 * @param {*} data 导出产品参数
 */
export function exportAllProducts(data) {
  return request({
    url: `/products/exports/all`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}

/**
 * 导出选择产品
 * @param {*} data 导出产品参数
 */
export function exportProductsByIds(data) {
  return request({
    url: `/products/exports/ids`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}

/**
 * 导出筛选产品
 * @param {*} data 导出产品参数
 */
export function exportFilteredProducts(data) {
  return request({
    url: `/products/exports/filtered`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}
/**
 * 导出当前页产品
 * @param {*} data 导出产品参数
 */
export function exportCurrentPageProducts(data) {
  return request({
    url: `/products/exports/current-page`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}

export function getAllImportTemplate() {
  return request({
    url: `/products/all-import-template`,
    method: 'get',
    responseType: 'blob'
  })
}

/**
 * 多条件查询
 * @param {object} data
 */
export function getProductsByQuery(data) {
  return request({
    url: `/products/or-query`,
    method: 'get',
    params: data
  })
}
