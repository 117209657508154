var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.title,
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "comment" } },
            [
              _c("a-textarea", {
                attrs: {
                  placeholder: "取消备注",
                  "max-length": 500,
                  "auto-size": { minRows: 3, maxRows: 5 },
                },
                model: {
                  value: _vm.form.comment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "comment",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.comment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }