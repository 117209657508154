var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c(
            "div",
            { staticClass: "container-top", attrs: { bordered: false } },
            [
              _c("p", [_vm._v("月目标")]),
              _c("a-icon", {
                attrs: { type: "plus" },
                on: { click: _vm.handleAddTarget },
              }),
            ],
            1
          ),
          _vm.targetList.length
            ? _c(
                "a-menu",
                {
                  staticClass: "menu",
                  attrs: {
                    "default-selected-keys": [_vm.targetList[0].id],
                    mode: _vm.mode,
                    theme: _vm.theme,
                  },
                  on: { scroll: _vm.handleScroll },
                },
                _vm._l(_vm.targetList, function (item) {
                  return _c(
                    "a-menu-item",
                    {
                      key: item.id,
                      on: {
                        click: function ($event) {
                          return _vm.handleInitTask(item)
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "calendar" } }),
                      _c("span", [_vm._v(_vm._s(item.targetName))]),
                      _c(
                        "a-dropdown",
                        [
                          _c("a-icon", {
                            staticClass: "handleIcon",
                            attrs: { type: "dash" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return (function (e) {
                                  return e.preventDefault()
                                })($event)
                              },
                            },
                          }),
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              _c("a-menu-item", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleUpdateTarget(item)
                                      },
                                    },
                                  },
                                  [_vm._v("更新月目标")]
                                ),
                              ]),
                              _c("a-menu-item", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleUpdateTask(item)
                                      },
                                    },
                                  },
                                  [_vm._v("规划月目标")]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _vm.taskList.length || _vm.columnSearchParams !== {}
            ? [
                _c("div", { staticClass: "top-tip" }, [
                  _c(
                    "div",
                    { staticClass: "selected-wrapper" },
                    [
                      _vm.hasSelected
                        ? _c("span", [
                            _vm._v(" 已选中 "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.selectedRowKeys.length)),
                            ]),
                            _vm._v(" 项任务 "),
                          ])
                        : _vm._e(),
                      _vm.hasSelected
                        ? _c(
                            "a-dropdown",
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "batch-operate-link",
                                  on: {
                                    click: function (e) {
                                      return e.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _vm._v("批量操作 "),
                                ],
                                1
                              ),
                              _c(
                                "a-menu",
                                { attrs: { slot: "overlay" }, slot: "overlay" },
                                [
                                  _c("a-menu-item", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleBatchEditEmergencyType(
                                              _vm.selectedRowKeys
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("批量设置紧急类型")]
                                    ),
                                  ]),
                                  _c("a-menu-item", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDeleteTask(
                                              _vm.selectedTaskIds
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("批量删除任务")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("a-table", {
                  key: _vm.tableKey,
                  attrs: {
                    "row-key": "taskId",
                    "row-selection": {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onSelectChange,
                      getCheckboxProps: _vm.targetRowSelection.getCheckboxProps,
                    },
                    bordered: "",
                    columns: _vm.monthColumns,
                    "data-source": _vm.taskList,
                    scroll: { x: 2000 },
                    loading: _vm.loading,
                    pagination: _vm.pagination,
                  },
                  on: { change: _vm.handleTableChange },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "isParts",
                        fn: function (text) {
                          return _c(
                            "div",
                            {},
                            [
                              text === 0
                                ? [
                                    _c("a-tag", { attrs: { color: "red" } }, [
                                      _vm._v("否"),
                                    ]),
                                  ]
                                : _vm._e(),
                              text === 1
                                ? [
                                    _c("a-tag", { attrs: { color: "green" } }, [
                                      _vm._v("是"),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        },
                      },
                      {
                        key: "emergencyType",
                        fn: function (text) {
                          return _c(
                            "span",
                            {},
                            [
                              _c("a-tag", { attrs: { color: "volcano" } }, [
                                _vm._v(_vm._s(_vm.EmergencyType[text])),
                              ]),
                            ],
                            1
                          )
                        },
                      },
                      {
                        key: "filterDropdown",
                        fn: function (ref) {
                          var setSelectedKeys = ref.setSelectedKeys
                          var selectedKeys = ref.selectedKeys
                          var confirm = ref.confirm
                          var clearFilters = ref.clearFilters
                          var column = ref.column
                          return _c(
                            "div",
                            { staticStyle: { padding: "8px" } },
                            [
                              column.searchType === "text"
                                ? [
                                    _c("a-input", {
                                      directives: [
                                        {
                                          name: "ant-ref",
                                          rawName: "v-ant-ref",
                                          value: function (c) {
                                            return (_vm.searchInput = c)
                                          },
                                          expression: "c => (searchInput = c)",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "188px",
                                        "margin-bottom": "8px",
                                        display: "block",
                                      },
                                      attrs: {
                                        placeholder: "搜索 " + column.title,
                                        value: selectedKeys[0],
                                      },
                                      on: {
                                        change: function (e) {
                                          return setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        },
                                        pressEnter: function () {
                                          return _vm.handleSearch(
                                            selectedKeys,
                                            confirm,
                                            column.key
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                : column.searchType === "number"
                                ? _c(
                                    "div",
                                    [
                                      _c("a-input-number", {
                                        staticStyle: {
                                          width: "95px",
                                          "margin-bottom": "8px",
                                          display: "inline-block",
                                        },
                                        attrs: {
                                          size: "small",
                                          placeholder: "最小值",
                                          min: 0,
                                          max: 999999,
                                        },
                                        on: {
                                          change: function (e) {
                                            return setSelectedKeys([
                                              e,
                                              selectedKeys[1],
                                            ])
                                          },
                                          pressEnter: function () {
                                            return _vm.handleSearch(
                                              selectedKeys,
                                              confirm,
                                              column.key
                                            )
                                          },
                                        },
                                        model: {
                                          value: selectedKeys[0],
                                          callback: function ($$v) {
                                            _vm.$set(selectedKeys, 0, $$v)
                                          },
                                          expression: "selectedKeys[0]",
                                        },
                                      }),
                                      _c("span", [_vm._v("-")]),
                                      _c("a-input-number", {
                                        staticStyle: {
                                          width: "95px",
                                          "margin-bottom": "8px",
                                          display: "inline-block",
                                        },
                                        attrs: {
                                          size: "small",
                                          placeholder: "最大值",
                                          min: selectedKeys[0],
                                          max: 999999,
                                        },
                                        on: {
                                          change: function (e) {
                                            return setSelectedKeys([
                                              selectedKeys[0],
                                              e,
                                            ])
                                          },
                                          pressEnter: function () {
                                            return _vm.handleSearch(
                                              selectedKeys,
                                              confirm,
                                              column.key
                                            )
                                          },
                                        },
                                        model: {
                                          value: selectedKeys[1],
                                          callback: function ($$v) {
                                            _vm.$set(selectedKeys, 1, $$v)
                                          },
                                          expression: "selectedKeys[1]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : column.searchType === "date"
                                ? _c(
                                    "div",
                                    [
                                      _c("a-range-picker", {
                                        staticStyle: {
                                          width: "330px",
                                          "margin-bottom": "8px",
                                        },
                                        attrs: {
                                          "show-time": { format: "HH:mm:ss" },
                                          size: "small",
                                          ranges: {
                                            今天: [
                                              _vm.$moment(),
                                              _vm.$moment(),
                                            ],
                                            本月: [
                                              _vm.$moment().startOf("month"),
                                              _vm.$moment().endOf("month"),
                                            ],
                                          },
                                        },
                                        on: {
                                          change: function (
                                            dates,
                                            dateStrings
                                          ) {
                                            return setSelectedKeys(
                                              [].concat(dateStrings)
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "a-button",
                                {
                                  staticStyle: {
                                    width: "90px",
                                    "margin-right": "8px",
                                  },
                                  attrs: {
                                    type: "primary",
                                    icon: "search",
                                    size: "small",
                                  },
                                  on: {
                                    click: function () {
                                      return _vm.handleSearch(
                                        selectedKeys,
                                        confirm,
                                        column.key
                                      )
                                    },
                                  },
                                },
                                [_vm._v("搜索")]
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { width: "90px" },
                                  attrs: { size: "small" },
                                  on: {
                                    click: function () {
                                      return _vm.handleReset(
                                        selectedKeys,
                                        clearFilters,
                                        column.key
                                      )
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            2
                          )
                        },
                      },
                      {
                        key: "filterIcon",
                        fn: function (filtered) {
                          return _c("a-icon", {
                            style: { color: filtered ? "#108ee9" : undefined },
                            attrs: { type: "search" },
                          })
                        },
                      },
                      {
                        key: "operation",
                        fn: function (text, column) {
                          return column.status === 2 ||
                            column.status === 4 ||
                            column.status === 5 ||
                            column.status === 8
                            ? _c(
                                "span",
                                { staticClass: "table-operation" },
                                [
                                  _c(
                                    "a-dropdown",
                                    { attrs: { placement: "bottomRight" } },
                                    [
                                      _c(
                                        "a-menu",
                                        {
                                          staticClass: "ant-dropdown-link",
                                          attrs: { slot: "overlay" },
                                          slot: "overlay",
                                        },
                                        [
                                          column.status === 2 ||
                                          column.status === 4 ||
                                          column.status === 5
                                            ? _c(
                                                "a-menu-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEditEmergencyType(
                                                        column
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("设置紧急类型")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "a-menu-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDeleteTask(
                                                    column.taskId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除任务")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a",
                                        { staticClass: "operation-btn" },
                                        [
                                          _vm._v(" 操作 "),
                                          _c("a-icon", {
                                            attrs: { type: "down" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e()
                        },
                      },
                      {
                        key: "status",
                        fn: function (text) {
                          return _c(
                            "div",
                            {},
                            [
                              text === 0
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "default" },
                                    }),
                                    _vm._v("准备中 "),
                                  ]
                                : _vm._e(),
                              text === 1
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "default" },
                                    }),
                                    _vm._v("未开始 "),
                                  ]
                                : _vm._e(),
                              text === 2
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "processing" },
                                    }),
                                    _vm._v("进行中 "),
                                  ]
                                : _vm._e(),
                              text === 3
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "success" },
                                    }),
                                    _vm._v("已完成 "),
                                  ]
                                : _vm._e(),
                              text === 4
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "error" },
                                    }),
                                    _vm._v("超期进行中 "),
                                  ]
                                : _vm._e(),
                              text === 5
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "warning" },
                                    }),
                                    _vm._v("即将超期 "),
                                  ]
                                : _vm._e(),
                              text === 9
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "warning" },
                                    }),
                                    _vm._v("暂停中 "),
                                  ]
                                : _vm._e(),
                              text === 8
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "error" },
                                    }),
                                    _vm._v("已取消 "),
                                  ]
                                : _vm._e(),
                              text === 10
                                ? [
                                    _c("a-badge", {
                                      attrs: { status: "error" },
                                    }),
                                    _vm._v("异常终止 "),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        },
                      },
                      {
                        key: "process",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            { staticClass: "table-process" },
                            [
                              column.remainProcessRoute.length > 0
                                ? [
                                    _c(
                                      "a-steps",
                                      {
                                        attrs: {
                                          current:
                                            column.remainProcessRoute.length -
                                            1,
                                          status: "finish",
                                          size: "small",
                                        },
                                      },
                                      _vm._l(
                                        column.remainProcessRoute,
                                        function (process) {
                                          return _c(
                                            "a-step",
                                            {
                                              key: process.id,
                                              attrs: {
                                                title: process.operationName,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "process-desc",
                                                  attrs: {
                                                    slot: "description",
                                                  },
                                                  slot: "description",
                                                },
                                                [
                                                  process.operationType > 0 &&
                                                  process.verifyProcessHour
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "description-font",
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              "加工工时："
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                process.verifyProcessHour
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  process.operationType > 0 &&
                                                  process.verifyTransferOutTime
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "description-font",
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              "转出日期："
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                process.verifyTransferOutTime
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            : [
                _vm.targetList.length
                  ? [
                      _c(
                        "a-empty",
                        { staticClass: "empty" },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "description" },
                              slot: "description",
                            },
                            [_vm._v("暂无任务信息")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpdateTask(
                                    _vm.activeTargetId
                                  )
                                },
                              },
                            },
                            [_vm._v("添加任务")]
                          ),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "a-empty",
                        { staticClass: "empty" },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "description" },
                              slot: "description",
                            },
                            [_vm._v("暂无目标信息")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleAddTarget },
                            },
                            [_vm._v("添加月目标")]
                          ),
                        ],
                        1
                      ),
                    ],
              ],
        ],
        2
      ),
      _vm.taskEditModalVisible
        ? _c("TargetEdit", {
            attrs: {
              "modal-title": _vm.editType,
              "target-form": _vm.editTarget,
              "target-type": "month",
            },
            on: { confirm: _vm.targetConfirm, cancel: _vm.targetCancel },
          })
        : _vm._e(),
      _vm.targetTaskModalVisible
        ? _c("TargetTaskEdit", {
            attrs: { id: _vm.editTaskId, title: _vm.editTitle },
            on: { confirm: _vm.taskEditConfirm, cancel: _vm.taskEditCancel },
          })
        : _vm._e(),
      _vm.emergencyEditVisible
        ? _c("TaskEmergencyEditModal", {
            attrs: {
              record: _vm.emergencyEditColumns,
              ids: _vm.emergencyEditIds,
              "target-id": _vm.activeTargetId,
            },
            on: {
              confirm: _vm.handleTaskEmergencyTypeEditConfirm,
              cancel: _vm.handleTaskEmergencyTypeEditCancel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }