<template>
  <a-modal v-drag-modal class="modal-container" visible="true" :title='title+"订单"' :maskClosable="false"
    @cancel="handleCancel" width="700px">
    <template slot="footer">
      <a-button @click="handleReset">
        重置
      </a-button>
      <a-button @click="handleCancel">
        取消
      </a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">
        确定
      </a-button>
    </template>
    <a-form-model ref="ruleForm" :model="order" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="订单编号" prop="orderCode">
        <a-input maxLength=15 v-model.trim="order.orderCode" placeholder="请输入订单编号" />
      </a-form-model-item>
      <a-form-model-item label="签约时间" prop="signTime">
        <a-date-picker v-model="order.signTime" show-time type="date" placeholder="请选择下达时间" />
      </a-form-model-item>
      <a-form-model-item label="客户名称" prop="client">
        <a-input maxLength=40 v-model.trim="order.client" placeholder="请输入客户名称" />
      </a-form-model-item>
      <a-form-model-item label="合同交货日期" prop="deadline">
        <a-date-picker v-model="order.deadline" show-time type="date" placeholder="请选择合同交货日期" />
      </a-form-model-item>
      <a-form-model-item label="实际交货日期" prop="realDeliverTime">
        <a-date-picker v-model="order.realDeliverTime" show-time type="date" placeholder="请选择实际交货日期" />
      </a-form-model-item>
      <a-form-model-item label="客户合同编码" prop="clientContractCode">
        <a-input maxLength=40 v-model.trim="order.clientContractCode" placeholder="请输入客户合同编码" />
      </a-form-model-item>
      <a-form-model-item label="客户采购订单号" prop="clientPurchaseOrderCode">
        <a-input maxLength=40 v-model.trim="order.clientPurchaseOrderCode" placeholder="请输入订单编号" />
      </a-form-model-item>
      <a-form-model-item label="订单负责人" prop="charger">
        <a-input maxLength=15 v-model.trim="order.charger" placeholder="请输入订单负责人" />
      </a-form-model-item>
      <a-form-model-item label="客户订单编码" prop="clientOrderCode">
        <a-input maxLength=15 v-model.trim="order.clientOrderCode" placeholder="请输入客户订单编码" />
      </a-form-model-item>
      <a-form-model-item label="客户项目编码" prop="clientProjectCode">
        <a-input maxLength=40 v-model.trim="order.clientProjectCode" placeholder="请输入客户项目编码" />
      </a-form-model-item>
      <a-form-model-item label="订单罚金比例" prop="penaltyInterest">
        <a-input-number v-model="order.penaltyInterest" :precision="2" :min="0" :max="1" :step='0.01'
          placeholder="罚金比例" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="comment">
        <a-input maxLength=1024 v-model.trim="order.comment" placeholder="请输入订单备注(不超过1024个字)" type="textarea" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
  import {
    updateOrder,
    addOrder,
  } from '@/api/order'
  import Moment from 'moment'
  export default {
    props: {
      modalTitle: String,
      orderForm: Object,
    },
    data() {
      return {
        title: this.modalTitle,
        labelCol: {
          span: 5,
          offset: 2
        },
        wrapperCol: {
          span: 10,
          offset: 1
        },
        loading: false,
        order: {
          id: this.orderForm.id,
          orderCode: this.orderForm.orderCode,
          client: this.orderForm.client,
          signTime: this.orderForm.signTime,
          deliverTime: this.orderForm.deliverTime,
          deadline: this.orderForm.deadline,
          realDeliverTime: this.orderForm.realDeliverTime,
          clientOrderCode: this.orderForm.clientOrderCode,
          clientPurchaseOrderCode: this.orderForm.clientPurchaseOrderCode,
          charger: this.orderForm.charger,
          clientContractCode: this.orderForm.clientContractCode,
          clientProjectCode: this.orderForm.clientProjectCode,
          penaltyInterest: this.orderForm.penaltyInterest,
          comment: this.orderForm.comment,
        },
        rules: {
          'orderCode': [{
            required: true,
            message: '请输入订单编号',
            trigger: 'blur'
          }],
          'signTime': [{
            required: true,
            message: '请选择签约时间',
            trigger: 'change'
          }],
        },
      }
    },
    computed: {
      // 计算属性的 getter
      isAdd: function () {
        return this.modalTitle === '新增'
      },
      isUpdate: function () {
        return this.modalTitle === '更新'
      }
    },
    methods: {
      handleReset() {
        this.$refs.ruleForm.resetFields();
      },
      handleCancel() {
        this.$emit('cancel');
      },
      handleConfirm() {
        console.log(this.order);
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.order.signTime instanceof Moment) {
              this.order.signTime = this.order.signTime.local().format("YYYY-MM-DD HH:mm:ss")
            };
            if (this.order.deadline instanceof Moment) {
              this.order.deadline = this.order.deadline.local().format("YYYY-MM-DD HH:mm:ss")
            };
            if (this.order.realDeliverTime instanceof Moment) {
              this.order.realDeliverTime = this.order.realDeliverTime.local().format("YYYY-MM-DD HH:mm:ss")
            };
            if (this.isAdd) {
              this.addOrder(this.order);
            } else if (this.isUpdate) {
              this.updateOrder(this.order);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      updateOrder(orderFormData) {
        this.loading = true;
        updateOrder(orderFormData.id, orderFormData).then(res => {
          if (res.data.id) {
            const successMessage = '更新订单成功！';
            const failMessage = '更新订单失败，请重试！';
            this.$emit('confirm');
            this.$message.success(successMessage);
          } else {
            this.$message.error(failMessage)
          }
        }).catch(
          // 记录失败原因
          (reason) => {
            this.loading = false;
            console.log('处理失败的 promise (' + reason + ')');
          });
      },
      addOrder(orderFormData) {
        this.loading = true;
        orderFormData.id = null;
        addOrder(orderFormData).then(res => {
          if (res.data.id) {
            this.$emit('confirm');
            this.$message.success('新增订单成功！');
          } else {
            this.$message.error('新增订单失败，请重试！')
          }
        }).catch(
          // 记录失败原因
          (reason) => {
            this.loading = false;
            console.log('处理失败的 promise (' + reason + ')');
          });
      }
    },

  }

</script>

<style scoped>
  .searchFilterIcon {
    position: absolute;
    top: 10px;
    right: 0;
    padding-right: 5px;
    padding-left: 15px;
    padding-bottom: 20px;
  }

  .searchFilterIcon~.el-table__column-filter-trigger {
    display: none;
  }

  .searchButton {
    width: 90px;
    margin-right: 8px
  }

  .resetButton {
    width: 90px;
  }

  .searchInput {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }

  .divider-text {
    color: grey;
    font-size: 5px;
  }

  .modal-container>>>.ant-modal-body {
    padding-top: 0px;
  }

  .modal-container>>>.ant-form-item {
    margin-bottom: 10px;
  }

</style>
