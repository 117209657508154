import request from '@/utils/api.request'

export function getGroups(data) {
  return request({
    url: `/groups`,
    method: 'get',
    params: data
  })
}

export function getAllGroups(data) {
  return request({
    url: `/groups/all`,
    method: 'get',
    params: data
  })
}

export function addGroup(data) {
  return request({
    url: '/groups',
    method: 'post',
    data
  })
}

export function updateGroup(id, data) {
  return request({
    url: `/groups/${id}`,
    method: 'put',
    data
  })
}

export function deleteGroup(id) {
  return request({
    url: `/groups/${id}`,
    method: 'delete'
  })
}

export function getGroupById(id) {
  return request({
    url: `/groups/${id}`,
    method: 'get'
  })
}

