<template>
  <div class="app-container">
    <div v-if="hasSelected" class="top-tip">
      <div class="selected-wrapper">
        <span v-if="hasSelected">
          已选中
          <strong>{{ selectedRowKeys.length }}</strong> 个模板
        </span>
        <a-dropdown v-if="hasSelected">
          <a class="batch-operate-link" @click="e => e.preventDefault()">
            <a-icon type="edit" />批量操作
          </a>

          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="handleBatchEditType(selectedRowKeys)">批量编辑模板类型</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <el-dropdown class="header-button" @command="handleAddTemplate">
      <el-button type="primary" icon="el-icon-circle-plus-outline">新增模板</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="CUTTER">新增刀具模板</el-dropdown-item>
        <el-dropdown-item command="MEASURE">新增量具模板</el-dropdown-item>
        <el-dropdown-item command="OTHERS">新增其他模板</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <a-tabs :active-key="currentActiveKey" type="card" @change="handleTabChange">
      <a-tab-pane key="11" tab="刀具" />
      <a-tab-pane key="12" tab="量具" />
      <a-tab-pane key="1" tab="其他" />
    </a-tabs>
    <a-table
      row-key="templateId"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: templateRowSelection.getCheckboxProps }"
      bordered
      class="templates-table"
      :columns="templateColumns"
      :data-source="templateList"
      :loading="loading"
      :pagination="pagination"
      :scroll="{y: 700 }"
      @change="handleTableChange"
    >
      <template slot="templateCode" slot-scope="text, column">
        <el-link type="primary" @click="handleCheckTemplateCard(column)">{{ text }}</el-link>
      </template>
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-if="column.searchType === 'date' ">
          <a-range-picker
            :show-time="{ format: 'HH:mm:ss' }"
            size="small"
            style="width: 330px; margin-bottom: 8px;"
            :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
            @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="processRouteTemplate" slot-scope="text, column" class="file-wrapper">
        <template>
          <div class="filename-wrapper">
            <a-button type="link" @click="handleShowProcessRoute(column)">查看工艺路线</a-button>
          </div>
        </template>
      </div>
      <div slot="operation" slot-scope="text, column" class="table-operation">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleUpdateTemplate(column)">更新模板</a-menu-item>
            <a-menu-item @click="handleDeleteTemplate(column)">删除模板</a-menu-item>
            <a-menu-item @click="handleEditTemplateType(column)">编辑模板类型</a-menu-item>
          </a-menu>
          <a class="operation-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <CraftTemplateEdit
      v-if="editModalVisible"
      :modal-title="currentEditType"
      :template-from="currentTemplate"
      @confirm="handleRecordModalConfirmed"
      @cancel="handleRecordModalCanceled"
    />
    <ProcessRouteCard
      v-if="routeModalVisible"
      :modal-title="currentPlanCode"
      :record="currentProcessRoute"
      @cancel="handleProcessRouteModalCanceled"
      @confirm="handleProcessRouteModalConfirmed"
    />
    <TemplateTypeEditModal
      v-if="templateTypeEditModalVisible"
      :ids="editIds"
      :type="editType"
      @cancel="handleTemplateTypeEditModalCanceled"
      @confirm="handleTemplateTypeEditModalConfirmed"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { templateColumns, templateRowSelection } from './dataLogic'
import {
  getTemplates,
  deleteTemplate,
  exportTemplates,
  deleteTemplateOperationRoute,
} from '@/api/template'
import CraftTemplateEdit from '@/components/CraftTemplateEdit'
import ProcessRouteCard from '@/components/ProcessRouteCard'
import TemplateTypeEditModal from '@/components/TemplateTypeEditModal'
import { downloadTemplate } from '@/common/templateDownload'

export default {
  components: {
    CraftTemplateEdit,
    ProcessRouteCard,
    TemplateTypeEditModal,
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API,
      loading: false,
      uploading: false,
      templateList: [],
      templateColumns,
      templateRowSelection,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      initTemplateListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        operationCardType: 'eq:11',
      },
      currentTemplateListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
        operationCardType: 'eq:11',
      },
      currentEditType: '',
      currentTemplate: {},
      editModalVisible: false,
      columnSearchParams: {},
      routeModalVisible: false,
      currentPlanCode: '',
      currentProcessRoute: '',
      baseURL: '',
      currentActiveKey: '11',

      templateTypeEditModalVisible: false,
      editIds: [],
      editType: '',

      selectedRowKeys: [],
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
  },
  created() {
    this.baseURL = process.env.VUE_APP_BASE_API
    this.getTemplates(this.initTemplateListQueryParams)
  },
  methods: {
    handleTabChange(activeKey) {
      this.currentTemplateListQueryParams['operationCardType'] =
        'eq:' + activeKey
      this.currentTemplateListQueryParams.pageNum = 1
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getTemplates(this.currentTemplateListQueryParams)
      this.currentActiveKey = activeKey
      this.tableKey += 1
    },
    getTemplates(data) {
      this.loading = true
      getTemplates(data)
        .then((res) => {
          this.templateList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getTemplatesByParams() {
      const cQueryParams = deepClone(this.currentTemplateListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getTemplates(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)

      if (filtersKeys.includes('templateType')) {
        delete this.columnSearchParams['templateType']
        if (filters['templateType'].length > 0) {
          this.columnSearchParams['templateType'] = `in:${filters[
            'templateType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['templateType']
        }
      }

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentTemplateListQueryParams.sort_by =
          sortType + sorter.columnKey
      } else {
        this.currentTemplateListQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentTemplateListQueryParams.pageNum = pagination.current
      this.currentTemplateListQueryParams.pageSize = pagination.pageSize
      this.getTemplatesByParams()
    },
    // 删除模板
    handleDeleteTemplate(column) {
      this.$confirm({
        content: '确认删除模板?',
        onOk: () => {
          deleteTemplate(column.templateId).then(() => {
            this.$message.success('删除模板成功！')
            this.getTemplatesByParams()
          })
        },
      })
    },
    // 添加模板
    handleAddTemplate(command) {
      let cardType = '11'
      switch (command) {
        case 'CUTTER':
          cardType = '11'
          break
        case 'MEASURE':
          cardType = '12'
          break
        case 'OTHERS':
          cardType = '1'
          break
        default:
          break
      }
      const routeData = this.$router.resolve({
        // name: 'craft-card-manage',
        name: 'template-card',
        query: {
          type: 'add',
          cardType,
        },
      })
      window.open(routeData.href, '_blank')
      this.handleModalTips()
    },
    // 更新模板
    handleUpdateTemplate(column) {
      const routeData = this.$router.resolve({
        // name: 'craft-card-manage',
        name: 'template-card',
        query: {
          type: 'edit',
          ids: column.templateId,
        },
      })
      window.open(routeData.href, '_blank')
      this.handleModalTips()
    },
    // 查看模板
    handleCheckTemplateCard(column) {
      const routeData = this.$router.resolve({
        // name: 'craft-card-manage',
        name: 'template-card',
        query: {
          type: 'check',
          ids: column.templateId,
        },
      })
      window.open(routeData.href, '_blank')
    },
    handleEditCancel() {
      this.visible = false
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    getExportIdsFromSelection() {
      this.exportIds = this.selectedRowKeys
    },
    getExportIdsFromPage() {
      this.exportIds = this.templateList.map((item) => {
        return item.id
      })
    },
    handleExportTemplateCommand(command) {
      switch (command) {
        case 'SELECTED':
          if (this.selectedRowKeys.length === 0) {
            this.$message.warning('选择项不能为空！')
            break
          }
          this.getExportIdsFromSelection()
          exportTemplates({
            ids: this.exportIds.toString(),
          }).then((res) => {
            this.downloadTemplate(res)
          })
          break
        case 'PAGE':
          if (this.templateList.length === 0) {
            this.$message.warning('导出数据不能为！')
            break
          }
          this.getExportIdsFromPage()
          exportTemplates({
            ids: this.exportIds.toString(),
          }).then((res) => {
            this.downloadTemplate(res)
          })
          break
        case 'FILTERED':
          exportTemplates({
            hasConditions: true,
          }).then((res) => {
            this.downloadTemplate(res)
          })
          break
        case 'ALL': {
          exportTemplates().then((res) => {
            this.downloadTemplate(res)
          })
          break
        }
        default:
          break
      }
    },
    handleRecordModalConfirmed() {
      this.editModalVisible = false
      if (this.currentEditTypeb === 'add') {
        this.$message.success('新增模板成功')
      } else {
        this.$message.success('更新模板成功')
      }
      this.getTemplates(this.currentTemplateListQueryParams)
    },
    handleRecordModalCanceled() {
      this.editModalVisible = false
    },
    handleShowProcessRoute(column) {
      this.currentPlanCode = column.templateCode
      this.currentProcessRoute = column.processRouteTemplate
      this.routeModalVisible = true
    },
    handleDeleteProcessRoute(id) {
      deleteTemplateOperationRoute(id).then((res) => {
        if (res) {
          this.$message.success('删除工艺路线成功！')
          this.getTemplatesByParams()
        }
      })
    },
    handleProcessRouteModalConfirmed() {
      this.routeModalVisible = false
    },
    handleProcessRouteModalCanceled() {
      this.routeModalVisible = false
    },
    handleProcessFileChange(info) {
      this.loading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.loading = false
        this.$message.error(info.file.response.apierror.message)
        this.getTemplatesByParams()
      }
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`批量导入失败，请稍后再试`)
      }
      this.uploading = false
    },
    showImportResults(response) {
      const h = this.$createElement
      const that = this
      this.$info({
        title: '工艺卡片模板信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入模板数据总计：' + response.totalNum + '条;'),
          h('p', '非法模板数据条数：' + response.failedNum + ';'),
          h('p', '导入成功模板数据条数：' + response.successfulNum + ';'),
          h('p', '非法模板数据信息:'),
          h('p', response.invalidMessages.join(' ||  ')),
        ]),
        onOk() {
          that.$destroyAll()
          that.loading = false
          that.getTemplatesByParams()
        },
      })
    },
    handleDownloadTemplate() {
      downloadTemplate('cardTemplate')
    },
    handleModalTips() {
      const that = this
      this.$confirm({
        title: '模板是否编辑完成？',
        width: 600,
        content: '',
        okText: '已完成',
        cancelText: '取消',
        onOk() {
          that.$destroyAll()
          that.getTemplatesByParams()
        },
        onCancel() {},
      })
    },
    handleBatchEditType(ids) {
      this.editIds = ids
      this.templateTypeEditModalVisible = true
    },
    handleEditTemplateType(column) {
      this.editIds = [column.templateId]
      this.editType = this.currentActiveKey
      this.templateTypeEditModalVisible = true
    },
    handleTemplateTypeEditModalConfirmed() {
      this.templateTypeEditModalVisible = false
      this.selectedRowKeys = []
      this.getTemplates(this.currentTemplateListQueryParams)
    },
    handleTemplateTypeEditModalCanceled() {
      this.templateTypeEditModalVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.app-container {
  position: relative;
  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
    .batch-operate-link {
      padding-left: 10px;
      cursor: pointer;
      color: #409eff;
    }
  }

  .template-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-template {
    float: right;
    top: -50px;
    right: 20px;
  }

  .filename-delete {
    color: #ff4d4f;
    margin-right: 8px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  /deep/ .ant-table-row-cell-ellipsis {
    white-space: unset;
    text-overflow: unset;
  }

  .header-button {
    position: absolute;
    right: 20px;
    top: 17px;
    z-index: 99;
  }
}
</style>
