<template>
  <a-modal class="modal-container" visible="true" :title="title+&quot;产品&quot;" :mask-closable="false" width="700px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="product" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item v-if="isOrderAdd" label="产品序号" prop="productCode">
        <a-input v-model.trim="product.productCode" max-length="15" placeholder="请输入产品序号" />
      </a-form-model-item>
      <a-form-model-item label="产品名称" prop="productName">
        <a-input v-model.trim="product.productName" max-length="20" placeholder="请输入产品名称" />
      </a-form-model-item>
      <a-form-model-item label="产品类型" prop="type">
        <a-radio-group v-model="product.type">
          <a-radio value="L">L类型</a-radio>
          <a-radio value="D">D类型</a-radio>
          <a-radio value="G">G类型</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="产品类别" prop="productCategory">
        <a-radio-group v-if="product.type==='L'" v-model="product.productCategory">
          <a-radio value="单体轴类">单体轴类</a-radio>
                <a-radio value="单体非轴类">单体非轴类</a-radio>
                <a-radio value="组合">组合</a-radio>
      </a-radio-group>
        <a-radio-group v-else v-model="product.productCategory">
          <a-radio value="单体">单体</a-radio>
          <a-radio value="组合">组合</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="product.type==='D'" label="刀具材料类别" prop="materialCategories">
        <a-select
          v-model="product.materialCategories"
          show-search
          placeholder="请选择刀具材料类别"
          option-filter-prop="children"
          :filter-option="filterMaterialCategoriesOption"
        >
          <a-select-option
            v-for="category in materialCategories"
            :key="category.parameterValue"
            :value="category.parameterCode"
          >{{ category.parameterValue }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="产品图号" prop="drawingNum">
        <a-input v-model.trim="product.drawingNum" max-length="100" placeholder="请输入产品图号" />
      </a-form-model-item>
      <a-form-model-item label="规格" prop="specification">
        <a-input v-model.trim="product.specification" max-length="100" placeholder="请输入产品规格" />
      </a-form-model-item>
      <a-form-model-item label="单位" prop="unit">
        <a-input v-model.trim="product.unit" max-length="5" placeholder="请输入单位" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="订单数量" prop="amount">
        <a-input v-model.number="product.amount" placeholder="请输入订单数量" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="单价（无税）" prop="taxFreePrice">
        <a-input-number v-model="product.taxFreePrice" :precision="2" :min="0" :max="99999" placeholder="请输入无税单价" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="税率" prop="taxRate">
        <a-input-number v-model="product.taxRate" :precision="2" :min="0" :max="1" :step="0.01" placeholder="请输入税率" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="产值单价" prop="productionPrice">
        <a-input-number v-model="product.productionPrice" :precision="2" :min="0" :max="99999" placeholder="请输入产值价格" />
      </a-form-model-item>
      <a-form-model-item label="客户物料代码" prop="clientMaterialCode">
        <a-input v-model.trim="product.clientMaterialCode" max-length="15" placeholder="请输入客户物料代码" />
      </a-form-model-item>
      <a-form-model-item label="客户计划编码" prop="clientPlanCode">
        <a-input v-model.trim="product.clientPlanCode" max-length="15" placeholder="请输入客户计划编码" />
      </a-form-model-item>
      <a-form-model-item label="客户产品库房代码" prop="clientWarehouseCode">
        <a-input v-model.trim="product.clientWarehouseCode" max-length="15" placeholder="请输入客户库房代码" />
      </a-form-model-item>
      <a-form-model-item label="产品客户需求" prop="productClientDemand">
        <a-input v-model.trim="product.productClientDemand" max-length="250" placeholder="请输入产品客户需求(不超过250个字)" type="textarea" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="comment">
        <a-input v-model.trim="product.comment" max-length="1024" placeholder="请输入产品备注(不超过1024个字)" type="textarea" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updateProduct, addProduct } from '@/api/product'
import { addProductByOrder } from '@/api/order'
import { getAllParametersByParentCode } from '@/api/parameter'
import Moment from 'moment'
export default {
  props: {
    modalTitle: String,
    orderId: Number,
    productForm: Object,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      if (value !== 0 && !value) {
        return callback(new Error('请输入数量'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字'))
      } else if (value < 0 || value > 999999) {
        callback(new Error('请输入不超过999999的正整数'))
      } else {
        callback()
      }
    }
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5,
        offset: 2,
      },
      wrapperCol: {
        span: 10,
        offset: 1,
      },
      loading: false,
      materialCategories: [],
      product: {
        id: this.productForm.id,
        productCategory: this.productForm.productCategory,
        materialCategories: this.productForm.materialCategories,
        productCode: this.productForm.productCode,
        productName: this.productForm.productName,
        drawingNum: this.productForm.drawingNum,
        specification: this.productForm.specification,
        unit: this.productForm.unit,
        amount: this.productForm.amount,
        productionPrice: this.productForm.productionPrice,
        taxFreePrice: this.productForm.taxFreePrice,
        taxRate: this.productForm.taxRate,
        clientMaterialCode: this.productForm.clientMaterialCode,
        clientWarehouseCode: this.productForm.clientWarehouseCode,
        clientPlanCode: this.productForm.clientPlanCode,
        productClientDemand: this.productForm.productClientDemand,
        type: this.productForm.type,
        comment: this.productForm.comment,
      },
      rules: {
        productCode: [
          {
            required: true,
            message: '请输入产品序号',
            trigger: 'blur',
          },
        ],
        type: [
          {
            required: true,
            message: '请选择一种类型',
            trigger: 'change',
          },
        ],
        productName: [
          {
            required: true,
            message: '请输入产品名称',
            trigger: 'blur',
          },
        ],
        drawingNum: [
          {
            required: true,
            message: '请输入产品图号',
            trigger: 'blur',
          },
        ],
        specification: [
          {
            required: true,
            message: '请输入产品规格',
            trigger: 'blur',
          },
        ],
        amount: [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function () {
      return this.modalTitle === '新增'
    },
    isOrderAdd: function () {
      return this.modalTitle === '新增订单'
    },
    isUpdate: function () {
      return this.modalTitle === '更新'
    },
  },
  mounted() {
    this.handleGetProductMaterialCategory()
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      console.log(this.product)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addProduct(this.product)
          } else if (this.isUpdate) {
            this.updateProduct(this.product)
          } else if (this.isOrderAdd) {
            this.addOrderProduct(this.product)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleGetProductMaterialCategory() {
      getAllParametersByParentCode('RT_DJMC').then((res) => {
        if (res.data) {
          this.materialCategories = res.data
        }
      })
    },
    filterMaterialCategoriesOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    updateProduct(productFormData) {
      this.loading = true
      updateProduct(productFormData.id, productFormData)
        .then((res) => {
          if (res.data.id) {
            const successMessage = '更新产品成功！'
            const failMessage = '更新产品失败，请重试！'
            this.$emit('confirm')
            this.$message.success(successMessage)
          } else {
            this.$message.error(failMessage)
          }
        })
        .catch(
          // 记录失败原因
          (reason) => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    addProduct(productFormData) {
      this.loading = true
      productFormData.id = null
      addProduct(productFormData)
        .then((res) => {
          if (res.data.id) {
            this.$emit('confirm')
            this.$message.success('新增产品成功！')
          } else {
            this.$message.error('新增产品失败，请重试！')
          }
        })
        .catch(
          // 记录失败原因
          (reason) => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    addOrderProduct(productFormData) {
      this.loading = true
      productFormData.id = null
      if (this.orderId) {
        addProductByOrder(this.orderId, productFormData)
          .then((res) => {
            if (res.data.id) {
              this.$emit('confirm')
              this.$message.success('新增产品成功！')
            } else {
              this.$message.error('新增产品失败，请重试！')
            }
          })
          .catch(
            // 记录失败原因
            (reason) => {
              this.loading = false
              console.log('处理失败的 promise (' + reason + ')')
            }
          )
      } else {
        this.$message.error('新增产品失败，请重试！')
      }
    },
  },
}
</script>

<style scoped>
.searchFilterIcon {
  position: absolute;
  top: 10px;
  right: 0;
  padding-right: 5px;
  padding-left: 15px;
  padding-bottom: 20px;
}

.searchFilterIcon ~ .el-table__column-filter-trigger {
  display: none;
}

.searchButton {
  width: 90px;
  margin-right: 8px;
}

.resetButton {
  width: 90px;
}

.searchInput {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}

.divider-text {
  color: grey;
  font-size: 5px;
}

.modal-container >>> .ant-modal-body {
  padding-top: 0px;
}

.modal-container >>> .ant-form-item {
  margin-bottom: 10px;
}

.modal-container >>> .ant-input-number {
  width: 200px;
}
</style>
