var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: true,
        title: _vm.title + "任务",
        "mask-closable": false,
        width: "700px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v(_vm._s(_vm.modalTitle))]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { ref: "code", attrs: { label: "任务编号", prop: "code" } },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { "max-length": 20, placeholder: "请输入任务编号" },
                model: {
                  value: _vm.form.code,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "code",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.code",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务类型", prop: "type" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: { "default-value": "4" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("a-icon", {
                    attrs: { slot: "suffixIcon", type: "smile" },
                    slot: "suffixIcon",
                  }),
                  _vm.taskType === "BF"
                    ? [
                        _c(
                          "a-select-option",
                          { attrs: { disabled: "", value: 2 } },
                          [_vm._v("生产补投任务")]
                        ),
                      ]
                    : [
                        _c("a-select-option", { attrs: { value: 0 } }, [
                          _vm._v("普通任务"),
                        ]),
                        _c("a-select-option", { attrs: { value: 4 } }, [
                          _vm._v("普通补投任务"),
                        ]),
                        _c("a-select-option", { attrs: { value: 1 } }, [
                          _vm._v("返修任务"),
                        ]),
                        _c("a-select-option", { attrs: { value: 3 } }, [
                          _vm._v("协作任务"),
                        ]),
                        _c("a-select-option", { attrs: { value: 2 } }, [
                          _vm._v("生产补投任务"),
                        ]),
                      ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务紧急类型", prop: "emgType" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  model: {
                    value: _vm.form.emgType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "emgType", $$v)
                    },
                    expression: "form.emgType",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "NML" } }, [
                    _vm._v("普通"),
                  ]),
                  _c("a-select-option", { attrs: { value: "MFN" } }, [
                    _vm._v("必完件"),
                  ]),
                  _c("a-select-option", { attrs: { value: "UGC" } }, [
                    _vm._v("紧急件"),
                  ]),
                  _c("a-select-option", { attrs: { value: "IUG" } }, [
                    _vm._v("插入紧急件"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.type === 1 && _vm.isAdd
            ? _c(
                "a-form-model-item",
                { attrs: { label: "退货原因", prop: "abandonReasonValues" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        "label-in-value": "",
                        mode: "multiple",
                        placeholder: "请选择退货原因",
                      },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.form.abandonReasonValues,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "abandonReasonValues", $$v)
                        },
                        expression: "form.abandonReasonValues",
                      },
                    },
                    _vm._l(_vm.filteredOptions, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.parameterValue))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "处置方式", prop: "repairEffect" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.form.repairEffect,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "repairEffect", $$v)
                        },
                        expression: "form.repairEffect",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "FX" } }, [
                        _vm._v("返修"),
                      ]),
                      _c("a-select-option", { attrs: { value: "BT" } }, [
                        _vm._v("报废补投"),
                      ]),
                      _c("a-select-option", { attrs: { value: "TH" } }, [
                        _vm._v("退回客户"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === 1 && _vm.form.repairEffect === "FX"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "返修要求", prop: "defeatDemand" } },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.form.defeatDemand,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "defeatDemand", $$v)
                      },
                      expression: "form.defeatDemand",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === 1 && _vm.form.repairEffect === "FX"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "返修工序", prop: "repairedOperationIds" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        "show-search": "",
                        mode: "multiple",
                        placeholder: "请选择返修工序",
                        "option-filter-prop": "children",
                      },
                      model: {
                        value: _vm.form.repairedOperationIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "repairedOperationIds", $$v)
                        },
                        expression: "form.repairedOperationIds",
                      },
                    },
                    _vm._l(_vm.currentOperationList, function (operation) {
                      return _c(
                        "a-select-option",
                        { key: operation.id, attrs: { value: operation.id } },
                        [
                          _vm._v(
                            _vm._s(operation.operationName) +
                              "(" +
                              _vm._s(operation.operationCode) +
                              ")"
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "反馈描述", prop: "contactRecord" } },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.form.contactRecord,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contactRecord", $$v)
                      },
                      expression: "form.contactRecord",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "核实情况", prop: "defeatDesc" } },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.form.defeatDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "defeatDesc", $$v)
                      },
                      expression: "form.defeatDesc",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务截止时间", prop: "taskDeliveryTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "300px" },
                attrs: {
                  "show-time": "",
                  format: "YYYY-MM-DD HH:mm:ss",
                  type: "date",
                  placeholder: "请选择任务截止时间",
                },
                model: {
                  value: _vm.form.taskDeliveryTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskDeliveryTime", $$v)
                  },
                  expression: "form.taskDeliveryTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "期望下料时间", prop: "expectMaterialTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "300px" },
                attrs: {
                  "show-time": "",
                  format: "YYYY-MM-DD HH:mm:ss",
                  type: "date",
                  placeholder: "请选择期望下料时间",
                },
                model: {
                  value: _vm.form.expectMaterialTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "expectMaterialTime", $$v)
                  },
                  expression: "form.expectMaterialTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "has-feedback": "",
                label: "投产数量",
                prop: "productionNum",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.form.productionNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "productionNum", _vm._n($$v))
                  },
                  expression: "form.productionNum",
                },
              }),
            ],
            1
          ),
          _vm.form.type === 3
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    "has-feedback": "",
                    label: "产值价格",
                    prop: "taskPrice",
                  },
                },
                [
                  _c("a-input-number", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      precision: 2,
                      min: 0,
                      max: 99999,
                      placeholder: "请输入产值价格",
                    },
                    model: {
                      value: _vm.form.taskPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "taskPrice", $$v)
                      },
                      expression: "form.taskPrice",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "comment" } },
            [
              _c("a-input", {
                attrs: {
                  "max-length": 1024,
                  placeholder: "请输入任务备注(不超过1024个字)",
                  type: "textarea",
                },
                model: {
                  value: _vm.form.comment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "comment",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.comment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }