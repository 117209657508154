var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: "原辅材料",
        "mask-closable": false,
        width: "1000px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          staticClass: "cost-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "70px" },
              attrs: {
                label: "材料编号",
                labelCol: { span: 8 },
                prop: "materialInfo.code",
              },
            },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    value: _vm.form.materialInfo.innerCode,
                    "show-search": "",
                    "default-active-first-option": false,
                    placeholder: "请输入材料编号/材料牌号/名称/规格搜索",
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": _vm.fetching ? undefined : null,
                  },
                  on: {
                    search: _vm.fetchMaterialByInnerCode,
                    change: function (value, option) {
                      return _vm.handleMaterialNameChange(value, option)
                    },
                  },
                },
                [
                  _vm.fetching
                    ? _c("a-spin", {
                        attrs: { slot: "notFoundContent", size: "small" },
                        slot: "notFoundContent",
                      })
                    : _vm._e(),
                  _vm._l(_vm.materials, function (material) {
                    return _c("a-select-option", { key: material.id }, [
                      _vm._v(
                        _vm._s(material.materialName) +
                          "【" +
                          _vm._s(material.purchaseSpecification) +
                          " " +
                          _vm._s(material.materialCode) +
                          "】"
                      ),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "70px" },
              attrs: {
                label: "材料牌号",
                labelCol: { span: 8 },
                prop: "materialInfo.code",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "400px" },
                attrs: { maxLength: 20, placeholder: "请输入材料牌号" },
                model: {
                  value: _vm.form.materialInfo.code,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.materialInfo,
                      "code",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.materialInfo.code",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "70px" },
              attrs: {
                label: "材料名称",
                labelCol: { span: 8 },
                prop: "materialInfo.name",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "400px" },
                attrs: { maxLength: 20, placeholder: "请输入材料名称" },
                model: {
                  value: _vm.form.materialInfo.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.materialInfo,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.materialInfo.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "70px" },
              attrs: {
                label: "采购规格",
                labelCol: { span: 8 },
                prop: "materialInfo.purchaseSpecification",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "400px" },
                attrs: { maxLength: 20, placeholder: "请输入采购规格" },
                model: {
                  value: _vm.form.materialInfo.purchaseSpecification,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.materialInfo,
                      "purchaseSpecification",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.materialInfo.purchaseSpecification",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "70px" },
              attrs: {
                label: "材料单位",
                labelCol: { span: 8 },
                prop: "materialInfo.unit",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入材料单位" },
                model: {
                  value: _vm.form.materialInfo.unit,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.materialInfo,
                      "unit",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.materialInfo.unit",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "70px" },
              attrs: {
                label: "当前最新单价(元/kg)",
                labelCol: { span: 8 },
                prop: "materialInfo.unitPrice",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px", "margin-right": "10px" },
                attrs: { maxLength: 20, placeholder: "请输入当前最新单价" },
                model: {
                  value: _vm.form.materialInfo.unitPrice,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.materialInfo,
                      "unitPrice",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.materialInfo.unitPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "70px" },
              attrs: {
                label: "计价模型",
                labelCol: { span: 8 },
                prop: "pricingModel",
              },
            },
            [
              _c(
                "a-radio-group",
                {
                  staticStyle: { width: "400px", fontWeight: "400" },
                  on: { change: _vm.handleChangePricingModal },
                  model: {
                    value: _vm.form.pricingModel,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "pricingModel", $$v)
                    },
                    expression: "form.pricingModel",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "CBE" } }, [_vm._v("方料")]),
                  _c("a-radio", { attrs: { value: "CLM" } }, [_vm._v("棒料")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-left": "70px" },
              attrs: {
                label: "密度(g/cm3)",
                labelCol: { span: 8 },
                prop: "materialInfo.density",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px", "margin-right": "10px" },
                attrs: { maxLength: 20, placeholder: "请输入密度" },
                model: {
                  value: _vm.form.materialInfo.density,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.materialInfo,
                      "density",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.materialInfo.density",
                },
              }),
            ],
            1
          ),
          _vm.form.pricingModel === "CBE"
            ? [
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "70px" },
                    attrs: {
                      label: "长度(mm)",
                      labelCol: { span: 8 },
                      prop: "consumeSpecification.length",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px", "margin-right": "10px" },
                      attrs: { maxLength: 20, placeholder: "请输入长度" },
                      model: {
                        value: _vm.form.consumeSpecification["length"],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.consumeSpecification,
                            "length",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.consumeSpecification['length']",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "70px" },
                    attrs: {
                      label: "宽度(mm)",
                      labelCol: { span: 8 },
                      prop: "consumeSpecification.width",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px", "margin-right": "10px" },
                      attrs: { maxLength: 20, placeholder: "请输入宽度" },
                      model: {
                        value: _vm.form.consumeSpecification.width,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.consumeSpecification,
                            "width",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.consumeSpecification.width",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "70px" },
                    attrs: {
                      label: "厚度(mm)",
                      labelCol: { span: 8 },
                      prop: "consumeSpecification.height",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px", "margin-right": "10px" },
                      attrs: { maxLength: 20, placeholder: "请输入厚度" },
                      model: {
                        value: _vm.form.consumeSpecification.height,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.consumeSpecification,
                            "height",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.consumeSpecification.height",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.form.pricingModel === "CLM"
            ? [
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "70px" },
                    attrs: {
                      label: "直径(mm)",
                      labelCol: { span: 8 },
                      prop: "consumeSpecification.diameter",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px", "margin-right": "10px" },
                      attrs: { maxLength: 20, placeholder: "请输入长度" },
                      model: {
                        value: _vm.form.consumeSpecification.diameter,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.consumeSpecification,
                            "diameter",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.consumeSpecification.diameter",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticStyle: { "margin-left": "70px" },
                    attrs: {
                      label: "高度(mm)",
                      labelCol: { span: 8 },
                      prop: "consumeSpecification.height",
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "300px", "margin-right": "10px" },
                      attrs: { maxLength: 20, placeholder: "请输入高度" },
                      model: {
                        value: _vm.form.consumeSpecification.height,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.consumeSpecification,
                            "height",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.consumeSpecification.height",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }