var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title,
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "craftForm",
          attrs: {
            model: _vm.craft,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "工序", prop: "operationId" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    "label-in-value": "",
                    "show-search": "",
                    placeholder: "请选择工序",
                    "option-filter-prop": "children",
                  },
                  on: { change: _vm.handleOprationChange },
                  model: {
                    value: _vm.choseCraftName,
                    callback: function ($$v) {
                      _vm.choseCraftName = $$v
                    },
                    expression: "choseCraftName",
                  },
                },
                _vm._l(_vm.currentOperationList, function (operation) {
                  return _c(
                    "a-select-option",
                    { key: operation.id, attrs: { value: operation.id } },
                    [
                      _vm._v(
                        _vm._s(operation.operationName) +
                          "(" +
                          _vm._s(operation.operationCode) +
                          ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "工序名称及内容", prop: "content" } },
            [
              _c("a-textarea", {
                attrs: {
                  "max-length": "1000",
                  placeholder: "请输入工序名称及内容(最多1000字)",
                },
                model: {
                  value: _vm.craft.content,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.craft,
                      "content",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "craft.content",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "检测工序", prop: "testName" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    disabled: true,
                    "show-search": "",
                    placeholder: "请选择工序",
                    "option-filter-prop": "children",
                  },
                  model: {
                    value: _vm.craft.testName,
                    callback: function ($$v) {
                      _vm.$set(_vm.craft, "testName", $$v)
                    },
                    expression: "craft.testName",
                  },
                },
                _vm._l(_vm.currentOperationList, function (operation) {
                  return _c(
                    "a-select-option",
                    { key: operation.id, attrs: { value: operation.id } },
                    [
                      _vm._v(
                        _vm._s(operation.operationName) +
                          "(" +
                          _vm._s(operation.operationCode) +
                          ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "检测工序内容", prop: "testDesc" } },
            [
              _c("a-textarea", {
                attrs: {
                  maxlength: "1000",
                  placeholder: "请输入工序名称及内容(最多1000字)",
                },
                model: {
                  value: _vm.craft.testDesc,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.craft,
                      "testDesc",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "craft.testDesc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }