import moment from 'moment'
import { DefaultDateFormat } from '@/common/consts'

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      )
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

/**
 * 获取指定日期的起始时间和结束时间
 * @param {string} dateString - 日期字符串，例如 "2024-08-12"
 * @returns {Array} - 包含起始时间和结束时间的数组
 */
export function getDateRange(dateString) {
  // 将日期字符串转换为 moment 对象
  const date = moment(dateString, 'YYYY-MM-DD')

  // 获取当天的起始时间
  const startOfDay = date.startOf('day').format(DefaultDateFormat)

  // 获取当天的结束时间
  const endOfDay = date.endOf('day').format(DefaultDateFormat)

  return [startOfDay, endOfDay]
}
