var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "target-current" }, [
          _c(
            "div",
            { staticClass: "target-slogan" },
            [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(
                    _vm.targetStatisticsInfo.targetInfo
                      ? _vm.targetStatisticsInfo.targetInfo.targetName
                      : "暂无目标数据"
                  )
                ),
              ]),
              _c(
                "a-radio-group",
                {
                  staticClass: "tab",
                  on: { change: _vm.onGoingTabChange },
                  model: {
                    value: _vm.onGoingTab,
                    callback: function ($$v) {
                      _vm.onGoingTab = $$v
                    },
                    expression: "onGoingTab",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: "month" } }, [
                    _vm._v("当月"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "week" } }, [
                    _vm._v("当周"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "day" } }, [
                    _vm._v("当日"),
                  ]),
                ],
                1
              ),
              _vm.targetStatisticsInfo.comprehensiveStatisticsInfo
                ? _c("div", { staticClass: "list" }, [
                    _c("div", { staticClass: "item" }, [
                      _c("p", { staticClass: "data" }, [
                        _vm._v(
                          _vm._s(
                            (
                              _vm.targetStatisticsInfo
                                .comprehensiveStatisticsInfo
                                .insertEmergencyRate * 100
                            ).toFixed(0)
                          ) + "%"
                        ),
                      ]),
                      _c("p", { staticClass: "desc" }, [
                        _vm._v("目标插入急件综合占比"),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("p", { staticClass: "data" }, [
                        _vm._v(
                          _vm._s(
                            (
                              _vm.targetStatisticsInfo
                                .comprehensiveStatisticsInfo
                                .insertEmergencyFinishedRate * 100
                            ).toFixed(0)
                          ) + "%"
                        ),
                      ]),
                      _c("p", { staticClass: "desc" }, [
                        _vm._v("实际完成插入急件综合占比"),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("p", { staticClass: "data" }, [
                        _vm._v(
                          _vm._s(
                            (
                              _vm.targetStatisticsInfo
                                .comprehensiveStatisticsInfo
                                .normalFinishedRate * 100
                            ).toFixed(0)
                          ) + "%"
                        ),
                      ]),
                      _c("p", { staticClass: "desc" }, [
                        _vm._v("实际完成考核目标综合占比"),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("p", { staticClass: "data" }, [
                        _vm._v(
                          _vm._s(
                            (
                              _vm.targetStatisticsInfo
                                .comprehensiveStatisticsInfo.finishedRate * 100
                            ).toFixed(0)
                          ) + "%"
                        ),
                      ]),
                      _c("p", { staticClass: "desc" }, [_vm._v("实际完成率")]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "target-tab" },
            [
              _c(
                "a-radio-group",
                {
                  staticClass: "tab",
                  on: {
                    change: function ($event) {
                      return _vm.onTargetTabChange()
                    },
                  },
                  model: {
                    value: _vm.targetTab,
                    callback: function ($$v) {
                      _vm.targetTab = $$v
                    },
                    expression: "targetTab",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: "ALL" } }, [
                    _vm._v("ALL"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "LD" } }, [
                    _vm._v("LD"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "D" } }, [
                    _vm._v("D"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "L" } }, [
                    _vm._v("L"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "G" } }, [
                    _vm._v("G"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "target-content" }, [
                _c(
                  "div",
                  { staticClass: "check" },
                  [
                    _c("p", { staticClass: "title" }, [_vm._v("考核目标")]),
                    _vm.targetStatisticsInfo.normalTargetStatisticsInfo
                      ? _c(
                          "div",
                          { staticClass: "list" },
                          [
                            _c("div", { staticClass: "item" }, [
                              _c("div", {
                                staticClass: "pie",
                                attrs: { id: "taskStatisticsInfo" },
                              }),
                              _c("div", { staticClass: "desc" }, [
                                _c("p", [
                                  _vm._v(
                                    "总计: " +
                                      _vm._s(
                                        _vm.targetStatisticsInfo
                                          .normalTargetStatisticsInfo
                                          .taskStatisticsInfo.totalCount
                                      )
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "完成: " +
                                      _vm._s(
                                        _vm.targetStatisticsInfo
                                          .normalTargetStatisticsInfo
                                          .taskStatisticsInfo.finishedCount
                                      )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "item" }, [
                              _c("div", {
                                staticClass: "pie",
                                attrs: { id: "productStatisticsInfo" },
                              }),
                              _c("div", { staticClass: "desc" }, [
                                _c("p", [
                                  _vm._v(
                                    "总计: " +
                                      _vm._s(
                                        _vm.targetStatisticsInfo
                                          .normalTargetStatisticsInfo
                                          .productStatisticsInfo.totalCount
                                      )
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "完成: " +
                                      _vm._s(
                                        _vm.targetStatisticsInfo
                                          .normalTargetStatisticsInfo
                                          .productStatisticsInfo.finishedCount
                                      )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "item" }, [
                              _c("div", {
                                staticClass: "pie",
                                attrs: { id: "amountStatisticsInfo" },
                              }),
                              _c("div", { staticClass: "desc" }, [
                                _c("p", [
                                  _vm._v(
                                    "总计: " +
                                      _vm._s(
                                        _vm.targetStatisticsInfo
                                          .normalTargetStatisticsInfo
                                          .amountStatisticsInfo.totalAmount
                                      )
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "完成: " +
                                      _vm._s(
                                        _vm.targetStatisticsInfo
                                          .normalTargetStatisticsInfo
                                          .amountStatisticsInfo.finishedAmount
                                      )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm.targetTab !== "ALL" &&
                            _vm.targetTab !== "LD" &&
                            _vm.targetStatisticsInfo.targetInfo &&
                            _vm.targetStatisticsInfo.targetInfo.id
                              ? _c(
                                  "a-button",
                                  {
                                    staticClass: "btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.jumpToTargetManage(
                                          _vm.targetTab + "N"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.targetTab) + "明细统计 "
                                    ),
                                    _c("a-icon", { attrs: { type: "right" } }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.targetTab !== "G"
                      ? [
                          _c("p", { staticClass: "title" }, [
                            _vm._v("插入急件目标"),
                          ]),
                          _vm.targetStatisticsInfo.emergencyTargetStatisticsInfo
                            ? _c(
                                "div",
                                { staticClass: "list" },
                                [
                                  _c("div", { staticClass: "item" }, [
                                    _c("div", {
                                      staticClass: "pie",
                                      attrs: {
                                        id: "emergency-taskStatisticsInfo",
                                      },
                                    }),
                                    _c("div", { staticClass: "desc" }, [
                                      _c("p", [
                                        _vm._v(
                                          "总计: " +
                                            _vm._s(
                                              _vm.targetStatisticsInfo
                                                .emergencyTargetStatisticsInfo
                                                .taskStatisticsInfo.totalCount
                                            )
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "完成: " +
                                            _vm._s(
                                              _vm.targetStatisticsInfo
                                                .emergencyTargetStatisticsInfo
                                                .taskStatisticsInfo
                                                .finishedCount
                                            )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "item" }, [
                                    _c("div", {
                                      staticClass: "pie",
                                      attrs: {
                                        id: "emergency-productStatisticsInfo",
                                      },
                                    }),
                                    _c("div", { staticClass: "desc" }, [
                                      _c("p", [
                                        _vm._v(
                                          "总计: " +
                                            _vm._s(
                                              _vm.targetStatisticsInfo
                                                .emergencyTargetStatisticsInfo
                                                .productStatisticsInfo
                                                .totalCount
                                            )
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "完成: " +
                                            _vm._s(
                                              _vm.targetStatisticsInfo
                                                .emergencyTargetStatisticsInfo
                                                .productStatisticsInfo
                                                .finishedCount
                                            )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "item" }, [
                                    _c("div", {
                                      staticClass: "pie",
                                      attrs: {
                                        id: "emergency-amountStatisticsInfo",
                                      },
                                    }),
                                    _c("div", { staticClass: "desc" }, [
                                      _c("p", [
                                        _vm._v(
                                          "总计: " +
                                            _vm._s(
                                              _vm.targetStatisticsInfo
                                                .emergencyTargetStatisticsInfo
                                                .amountStatisticsInfo
                                                .totalAmount
                                            )
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "完成: " +
                                            _vm._s(
                                              _vm.targetStatisticsInfo
                                                .emergencyTargetStatisticsInfo
                                                .amountStatisticsInfo
                                                .finishedAmount
                                            )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _vm.targetTab !== "ALL" &&
                                  _vm.targetTab !== "LD" &&
                                  _vm.targetTab !== "G" &&
                                  _vm.targetStatisticsInfo.targetInfo &&
                                  _vm.targetStatisticsInfo.targetInfo.id
                                    ? _c(
                                        "a-button",
                                        {
                                          staticClass: "btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.jumpToTargetManage(
                                                _vm.targetTab + "E"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.targetTab) +
                                              "急件统计 "
                                          ),
                                          _c("a-icon", {
                                            attrs: { type: "right" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("div", { staticClass: "urgent" }),
              ]),
              _vm.targetLoading
                ? _c(
                    "div",
                    {
                      staticClass: "page-loading",
                      staticStyle: { height: "380px" },
                    },
                    [
                      _c("a-icon", {
                        staticStyle: { color: "black" },
                        attrs: { type: "loading" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "target-list" }, [
          _c(
            "p",
            { staticClass: "title" },
            [
              _vm._v(" 目标列表 "),
              _c(
                "a-button",
                { staticStyle: { padding: "0 12px" }, on: { click: _vm.jump } },
                [_vm._v("管理目标")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "list", on: { scroll: _vm.handleScroll } },
            [
              _c("a-tree", {
                attrs: {
                  "selected-keys": _vm.targetKey,
                  "tree-data": _vm.targetList,
                  "load-data": _vm.handleGetChildren,
                },
                on: { select: _vm.handleSwitchTarget },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.ifWeek || _vm.ifDay
        ? _c("div", { staticClass: "bottom" }, [
            _c("div", { staticClass: "desc" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(_vm.ifWeek ? "班组周目标统计" : "员工日目标统计")
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "table" },
              [
                _c("a-table", {
                  key: _vm.userTableKey,
                  attrs: {
                    "row-key": "id",
                    bordered: true,
                    columns: _vm.ifWeek ? _vm.groupColumns : _vm.memberColumns,
                    "data-source": _vm.userStatisticList,
                    loading: _vm.userTableLoading,
                    pagination: _vm.userPagination,
                  },
                  on: { change: _vm.handleUserTableChange },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "filterDropdown",
                        fn: function (ref) {
                          var setSelectedKeys = ref.setSelectedKeys
                          var selectedKeys = ref.selectedKeys
                          var confirm = ref.confirm
                          var clearFilters = ref.clearFilters
                          var column = ref.column
                          return _c(
                            "div",
                            { staticStyle: { padding: "8px" } },
                            [
                              column.searchType === "text"
                                ? [
                                    _c("a-input", {
                                      directives: [
                                        {
                                          name: "ant-ref",
                                          rawName: "v-ant-ref",
                                          value: function (c) {
                                            return (_vm.searchInput = c)
                                          },
                                          expression: "c => (searchInput = c)",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "188px",
                                        "margin-bottom": "8px",
                                        display: "block",
                                      },
                                      attrs: {
                                        placeholder: "搜索 " + column.title,
                                        value: selectedKeys[0],
                                      },
                                      on: {
                                        change: function (e) {
                                          return setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        },
                                        pressEnter: function () {
                                          return _vm.handleSearch(
                                            selectedKeys,
                                            confirm,
                                            column.key
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                : column.searchType === "number"
                                ? _c(
                                    "div",
                                    [
                                      _c("a-input-number", {
                                        staticStyle: {
                                          width: "95px",
                                          "margin-bottom": "8px",
                                          display: "inline-block",
                                        },
                                        attrs: {
                                          size: "small",
                                          placeholder: "最小值",
                                          min: 0,
                                          max: 999999,
                                        },
                                        on: {
                                          change: function (e) {
                                            return setSelectedKeys([
                                              e,
                                              selectedKeys[1],
                                            ])
                                          },
                                          pressEnter: function () {
                                            return _vm.handleSearch(
                                              selectedKeys,
                                              confirm,
                                              column.key
                                            )
                                          },
                                        },
                                        model: {
                                          value: selectedKeys[0],
                                          callback: function ($$v) {
                                            _vm.$set(selectedKeys, 0, $$v)
                                          },
                                          expression: "selectedKeys[0]",
                                        },
                                      }),
                                      _c("span", [_vm._v("-")]),
                                      _c("a-input-number", {
                                        staticStyle: {
                                          width: "95px",
                                          "margin-bottom": "8px",
                                          display: "inline-block",
                                        },
                                        attrs: {
                                          size: "small",
                                          placeholder: "最大值",
                                          min: selectedKeys[0],
                                          max: 999999,
                                        },
                                        on: {
                                          change: function (e) {
                                            return setSelectedKeys([
                                              selectedKeys[0],
                                              e,
                                            ])
                                          },
                                          pressEnter: function () {
                                            return _vm.handleSearch(
                                              selectedKeys,
                                              confirm,
                                              column.key
                                            )
                                          },
                                        },
                                        model: {
                                          value: selectedKeys[1],
                                          callback: function ($$v) {
                                            _vm.$set(selectedKeys, 1, $$v)
                                          },
                                          expression: "selectedKeys[1]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : column.searchType === "date"
                                ? _c(
                                    "div",
                                    [
                                      _c("a-range-picker", {
                                        staticStyle: {
                                          width: "330px",
                                          "margin-bottom": "8px",
                                        },
                                        attrs: {
                                          "show-time": { format: "HH:mm:ss" },
                                          size: "small",
                                          ranges: {
                                            今天: [
                                              _vm.$moment(),
                                              _vm.$moment(),
                                            ],
                                            本月: [
                                              _vm.$moment().startOf("month"),
                                              _vm.$moment().endOf("month"),
                                            ],
                                          },
                                        },
                                        on: {
                                          change: function (
                                            dates,
                                            dateStrings
                                          ) {
                                            return setSelectedKeys(
                                              [].concat(dateStrings)
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "a-button",
                                {
                                  staticStyle: {
                                    width: "90px",
                                    "margin-right": "8px",
                                  },
                                  attrs: {
                                    type: "primary",
                                    icon: "search",
                                    size: "small",
                                  },
                                  on: {
                                    click: function () {
                                      return _vm.handleSearch(
                                        selectedKeys,
                                        confirm,
                                        column.key
                                      )
                                    },
                                  },
                                },
                                [_vm._v("搜索")]
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { width: "90px" },
                                  attrs: { size: "small" },
                                  on: {
                                    click: function () {
                                      return _vm.handleReset(
                                        selectedKeys,
                                        clearFilters,
                                        column.key
                                      )
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            2
                          )
                        },
                      },
                      {
                        key: "filterIcon",
                        fn: function (filtered) {
                          return _c("a-icon", {
                            style: { color: filtered ? "#108ee9" : undefined },
                            attrs: { type: "search" },
                          })
                        },
                      },
                      {
                        key: "userName",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "a-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    title: "点击查看详情统计",
                                  },
                                },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.memberJump(column)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(text))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                      {
                        key: "groupName",
                        fn: function (text, column) {
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "a-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    title: "点击查看详情统计",
                                  },
                                },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.groupJump(column)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(text))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                      {
                        key: "rate",
                        fn: function (text, column) {
                          return _c("div", {}, [
                            _c("p", [
                              _vm._v(_vm._s((text * 100).toFixed(0)) + "%"),
                            ]),
                          ])
                        },
                      },
                    ],
                    null,
                    false,
                    4171925782
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "target-statistics" }, [
          _c("div", { staticClass: "filter" }, [
            _c("span", { staticClass: "title" }, [_vm._v("生产情况概览")]),
            _c(
              "div",
              { staticClass: "date-container" },
              [
                _c("p", [_vm._v("订单交货日期/任务创建日期:")]),
                _c(
                  "a-range-picker",
                  {
                    staticClass: "range",
                    attrs: {
                      "default-value": [
                        _vm.moment(
                          new Date(
                            new Date().getTime() - 3600 * 1000 * 24 * 30
                          ),
                          _vm.dateFormat
                        ),
                        _vm.moment(new Date(), _vm.dateFormat),
                      ],
                    },
                    on: { change: _vm.onDateChange },
                  },
                  [
                    _c("a-icon", {
                      attrs: { slot: "suffixIcon", type: "calendar" },
                      slot: "suffixIcon",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "target-tab" },
            [
              _c(
                "a-radio-group",
                {
                  staticClass: "tab",
                  on: { change: _vm.getTaskStatisticsByTime },
                  model: {
                    value: _vm.taskTab,
                    callback: function ($$v) {
                      _vm.taskTab = $$v
                    },
                    expression: "taskTab",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: "ALL" } }, [
                    _vm._v("ALL"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "LD" } }, [
                    _vm._v("LD"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "D" } }, [
                    _vm._v("D"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "L" } }, [
                    _vm._v("L"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "G" } }, [
                    _vm._v("G"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "target-content" }, [
                _c("div", { staticClass: "check" }, [
                  _c("p", { staticClass: "title" }, [
                    _vm._v("订单准交情况统计"),
                  ]),
                  _vm.orderStatisticInfo.normalTargetStatisticsInfo
                    ? _c("div", { staticClass: "list" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", {
                            staticClass: "pie",
                            attrs: { id: "order-taskStatisticsInfo" },
                          }),
                          _c("div", { staticClass: "desc" }, [
                            _c("p", [
                              _vm._v(
                                "总计: " +
                                  _vm._s(
                                    _vm.orderStatisticInfo
                                      .normalTargetStatisticsInfo
                                      .taskStatisticsInfo.totalCount
                                  )
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "完成: " +
                                  _vm._s(
                                    _vm.orderStatisticInfo
                                      .normalTargetStatisticsInfo
                                      .taskStatisticsInfo.finishedCount
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _c("div", {
                            staticClass: "pie",
                            attrs: { id: "order-productStatisticsInfo" },
                          }),
                          _c("div", { staticClass: "desc" }, [
                            _c("p", [
                              _vm._v(
                                "总计: " +
                                  _vm._s(
                                    _vm.orderStatisticInfo
                                      .normalTargetStatisticsInfo
                                      .productStatisticsInfo.totalCount
                                  )
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "完成: " +
                                  _vm._s(
                                    _vm.orderStatisticInfo
                                      .normalTargetStatisticsInfo
                                      .productStatisticsInfo.finishedCount
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _c("div", {
                            staticClass: "pie",
                            attrs: { id: "order-amountStatisticsInfo" },
                          }),
                          _c("div", { staticClass: "desc" }, [
                            _c("p", [
                              _vm._v(
                                "总计: " +
                                  _vm._s(
                                    _vm.orderStatisticInfo
                                      .normalTargetStatisticsInfo
                                      .amountStatisticsInfo.totalAmount
                                  )
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "完成: " +
                                  _vm._s(
                                    _vm.orderStatisticInfo
                                      .normalTargetStatisticsInfo
                                      .amountStatisticsInfo.finishedAmount
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "check" }, [
                  _c("p", { staticClass: "title" }, [
                    _vm._v("任务完成情况统计"),
                  ]),
                  _vm.taskInfo.normalTargetStatisticsInfo
                    ? _c("div", { staticClass: "list" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", {
                            staticClass: "pie",
                            attrs: { id: "task-taskStatisticsInfo" },
                          }),
                          _c("div", { staticClass: "desc" }, [
                            _c("p", [
                              _vm._v(
                                "总计: " +
                                  _vm._s(
                                    _vm.taskInfo.normalTargetStatisticsInfo
                                      .taskStatisticsInfo.totalCount
                                  )
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "完成: " +
                                  _vm._s(
                                    _vm.taskInfo.normalTargetStatisticsInfo
                                      .taskStatisticsInfo.finishedCount
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _c("div", {
                            staticClass: "pie",
                            attrs: { id: "task-productStatisticsInfo" },
                          }),
                          _c("div", { staticClass: "desc" }, [
                            _c("p", [
                              _vm._v(
                                "总计: " +
                                  _vm._s(
                                    _vm.taskInfo.normalTargetStatisticsInfo
                                      .productStatisticsInfo.totalCount
                                  )
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "完成: " +
                                  _vm._s(
                                    _vm.taskInfo.normalTargetStatisticsInfo
                                      .productStatisticsInfo.finishedCount
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _c("div", {
                            staticClass: "pie",
                            attrs: { id: "task-amountStatisticsInfo" },
                          }),
                          _c("div", { staticClass: "desc" }, [
                            _c("p", [
                              _vm._v(
                                "总计: " +
                                  _vm._s(
                                    _vm.taskInfo.normalTargetStatisticsInfo
                                      .amountStatisticsInfo.totalAmount
                                  )
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "完成: " +
                                  _vm._s(
                                    _vm.taskInfo.normalTargetStatisticsInfo
                                      .amountStatisticsInfo.finishedAmount
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "urgent" }),
              ]),
              _vm.taskLoading
                ? _c(
                    "div",
                    {
                      staticClass: "page-loading",
                      staticStyle: { height: "390px" },
                    },
                    [
                      _c("a-icon", {
                        staticStyle: { color: "black" },
                        attrs: { type: "loading" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "bottom" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c("a-table", {
              key: _vm.tableKey,
              attrs: {
                "row-key": "id",
                bordered: true,
                columns: _vm.logColumns,
                "data-source": _vm.logList,
                loading: _vm.tableLoading,
                pagination: _vm.pagination,
              },
              on: { change: _vm.handleTableChange },
              scopedSlots: _vm._u([
                {
                  key: "filterDropdown",
                  fn: function (ref) {
                    var setSelectedKeys = ref.setSelectedKeys
                    var selectedKeys = ref.selectedKeys
                    var confirm = ref.confirm
                    var clearFilters = ref.clearFilters
                    var column = ref.column
                    return _c(
                      "div",
                      { staticStyle: { padding: "8px" } },
                      [
                        column.searchType === "text"
                          ? [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "ant-ref",
                                    rawName: "v-ant-ref",
                                    value: function (c) {
                                      return (_vm.searchInput = c)
                                    },
                                    expression: "c => (searchInput = c)",
                                  },
                                ],
                                staticStyle: {
                                  width: "188px",
                                  "margin-bottom": "8px",
                                  display: "block",
                                },
                                attrs: {
                                  placeholder: "搜索 " + column.title,
                                  value: selectedKeys[0],
                                },
                                on: {
                                  change: function (e) {
                                    return setSelectedKeys(
                                      e.target.value ? [e.target.value] : []
                                    )
                                  },
                                  pressEnter: function () {
                                    return _vm.handleSearch(
                                      selectedKeys,
                                      confirm,
                                      column.key
                                    )
                                  },
                                },
                              }),
                            ]
                          : column.searchType === "number"
                          ? _c(
                              "div",
                              [
                                _c("a-input-number", {
                                  staticStyle: {
                                    width: "95px",
                                    "margin-bottom": "8px",
                                    display: "inline-block",
                                  },
                                  attrs: {
                                    size: "small",
                                    placeholder: "最小值",
                                    min: 0,
                                    max: 999999,
                                  },
                                  on: {
                                    change: function (e) {
                                      return setSelectedKeys([
                                        e,
                                        selectedKeys[1],
                                      ])
                                    },
                                    pressEnter: function () {
                                      return _vm.handleSearch(
                                        selectedKeys,
                                        confirm,
                                        column.key
                                      )
                                    },
                                  },
                                  model: {
                                    value: selectedKeys[0],
                                    callback: function ($$v) {
                                      _vm.$set(selectedKeys, 0, $$v)
                                    },
                                    expression: "selectedKeys[0]",
                                  },
                                }),
                                _c("span", [_vm._v("-")]),
                                _c("a-input-number", {
                                  staticStyle: {
                                    width: "95px",
                                    "margin-bottom": "8px",
                                    display: "inline-block",
                                  },
                                  attrs: {
                                    size: "small",
                                    placeholder: "最大值",
                                    min: selectedKeys[0],
                                    max: 999999,
                                  },
                                  on: {
                                    change: function (e) {
                                      return setSelectedKeys([
                                        selectedKeys[0],
                                        e,
                                      ])
                                    },
                                    pressEnter: function () {
                                      return _vm.handleSearch(
                                        selectedKeys,
                                        confirm,
                                        column.key
                                      )
                                    },
                                  },
                                  model: {
                                    value: selectedKeys[1],
                                    callback: function ($$v) {
                                      _vm.$set(selectedKeys, 1, $$v)
                                    },
                                    expression: "selectedKeys[1]",
                                  },
                                }),
                              ],
                              1
                            )
                          : column.searchType === "date"
                          ? _c(
                              "div",
                              [
                                _c("a-range-picker", {
                                  staticStyle: {
                                    width: "330px",
                                    "margin-bottom": "8px",
                                  },
                                  attrs: {
                                    "show-time": { format: "HH:mm:ss" },
                                    size: "small",
                                    ranges: {
                                      今天: [_vm.$moment(), _vm.$moment()],
                                      本月: [
                                        _vm.$moment().startOf("month"),
                                        _vm.$moment().endOf("month"),
                                      ],
                                    },
                                  },
                                  on: {
                                    change: function (dates, dateStrings) {
                                      return setSelectedKeys(
                                        [].concat(dateStrings)
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "a-button",
                          {
                            staticStyle: {
                              width: "90px",
                              "margin-right": "8px",
                            },
                            attrs: {
                              type: "primary",
                              icon: "search",
                              size: "small",
                            },
                            on: {
                              click: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                        _c(
                          "a-button",
                          {
                            staticStyle: { width: "90px" },
                            attrs: { size: "small" },
                            on: {
                              click: function () {
                                return _vm.handleReset(
                                  selectedKeys,
                                  clearFilters,
                                  column.key
                                )
                              },
                            },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      2
                    )
                  },
                },
                {
                  key: "filterIcon",
                  fn: function (filtered) {
                    return _c("a-icon", {
                      style: { color: filtered ? "#108ee9" : undefined },
                      attrs: { type: "search" },
                    })
                  },
                },
                {
                  key: "targetType",
                  fn: function (text) {
                    return _c(
                      "span",
                      {},
                      [
                        text === "M"
                          ? _c("a-tag", { attrs: { color: "volcano" } }, [
                              _vm._v("月目标"),
                            ])
                          : _vm._e(),
                        text === "W"
                          ? _c("a-tag", { attrs: { color: "cyan" } }, [
                              _vm._v("周目标"),
                            ])
                          : _vm._e(),
                        text === "D"
                          ? _c("a-tag", { attrs: { color: "green" } }, [
                              _vm._v("日目标"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "deleteNum",
                  fn: function (text, column) {
                    return _c(
                      "span",
                      {},
                      [
                        text > 0
                          ? [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleShowLogDetail(
                                        column,
                                        "D"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(text))]
                              ),
                            ]
                          : [_vm._v(_vm._s(text))],
                      ],
                      2
                    )
                  },
                },
                {
                  key: "updateNum",
                  fn: function (text, column) {
                    return _c(
                      "span",
                      {},
                      [
                        text > 0
                          ? [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleShowLogDetail(
                                        column,
                                        "U"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(text))]
                              ),
                            ]
                          : [_vm._v(_vm._s(text))],
                      ],
                      2
                    )
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm.logDetailModalVisible
        ? _c("LogDetailModal", {
            attrs: {
              "target-id": _vm.logTargetId,
              "operate-type": _vm.logType,
            },
            on: { confirm: _vm.logDetailConfirm, cancel: _vm.logDetailConfirm },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("p", { staticClass: "title" }, [_vm._v("任务规划情况统计")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }