import request from '@/utils/api.request'

export function getTokenKey() {
  return request({
    url: '/login?tokenKey=get',
    method: 'get'
  })
}

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

export function getInfoByAppId() {
  return request({
    url: '/userinfo',
    method: 'get'
  })
}

export function updatePassword(userId, password, userKey) {
  return request({
    url: `/users/${userId}/password`,
    method: 'put',
    data: {
      newPassword: password,
      userKey: userKey
    }
  })
}
