var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
          staticClass: "modal-container",
          attrs: {
            visible: true,
            title: _vm.title + _vm.planType + "计划",
            "mask-closable": false,
            width: "700px",
          },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.handleReset } }, [
                _vm._v("重置"),
              ]),
              _c("a-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消"),
              ]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "div",
                [
                  _c("a-divider", [
                    _c("p", { staticClass: "divider-text" }, [
                      _vm._v("计划信息"),
                    ]),
                  ]),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "code",
                      attrs: { label: "计划编号", prop: "plan.code" },
                    },
                    [
                      _vm.isAdd
                        ? _c("a-input", {
                            attrs: {
                              "max-length": 20,
                              placeholder: "请输入计划编号",
                            },
                            model: {
                              value: _vm.form.plan.code,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.plan,
                                  "code",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.plan.code",
                            },
                          })
                        : _c("a-input", {
                            attrs: {
                              "max-length": 20,
                              disabled: true,
                              placeholder: "请输入计划编号",
                            },
                            model: {
                              value: _vm.form.plan.code,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.plan,
                                  "code",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.plan.code",
                            },
                          }),
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "是否分件计划",
                            prop: "plan.whetherParts",
                          },
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value: _vm.form.plan.whetherParts,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.plan, "whetherParts", $$v)
                                },
                                expression: "form.plan.whetherParts",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: false } }, [
                                _vm._v("否"),
                              ]),
                              _c("a-radio", { attrs: { value: true } }, [
                                _vm._v("是"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMake || _vm.isUpdate
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: "当前库存数量" } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v("点击进行库存匹配"),
                              ]),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: { click: _vm.handleEditWareHouse },
                                },
                                [_vm._v(_vm._s(_vm.currentTurnsIntoNum))]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMake || _vm.isUpdate
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "库存转入数量",
                            prop: "plan.inventoryTurnsIntoNum",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入库存转入数量" },
                            model: {
                              value: _vm.form.plan.inventoryTurnsIntoNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.plan,
                                  "inventoryTurnsIntoNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "form.plan.inventoryTurnsIntoNum",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMake || _vm.isUpdate
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            "has-feedback": "",
                            label: "报废不需补投数量",
                            prop: "plan.abandonNotComplementNum",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入报废不需补投数量" },
                            model: {
                              value: _vm.form.plan.abandonNotComplementNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.plan,
                                  "abandonNotComplementNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "form.plan.abandonNotComplementNum",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "has-feedback": "",
                        label: "产品备投数量",
                        prop: "plan.prepareNum",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入产品备投数量" },
                        model: {
                          value: _vm.form.plan.prepareNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.plan, "prepareNum", _vm._n($$v))
                          },
                          expression: "form.plan.prepareNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isAdd
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "计划下达时间",
                            prop: "plan.commandTime",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              "show-time": "",
                              disabled: "",
                              type: "date",
                              placeholder: "请选择下达时间",
                            },
                            model: {
                              value: _vm.form.plan.commandTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.plan, "commandTime", $$v)
                              },
                              expression: "form.plan.commandTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  false
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "计划启动时间",
                            prop: "plan.startTime",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              "show-time": "",
                              disabled: "",
                              type: "date",
                              placeholder: "请选择启动时间",
                            },
                            model: {
                              value: _vm.form.plan.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.plan, "startTime", $$v)
                              },
                              expression: "form.plan.startTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMake
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "合同交货日期",
                            prop: "plan.deadline",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              "show-time": "",
                              disabled: "",
                              type: "date",
                              placeholder: "暂无合同交货日期",
                            },
                            model: {
                              value: _vm.form.plan.deadline,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.plan, "deadline", $$v)
                              },
                              expression: "form.plan.deadline",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "产品要求交货时间",
                        prop: "plan.deliverTime",
                      },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          "show-time": "",
                          format: "YYYY-MM-DD HH:mm:ss",
                          type: "date",
                          placeholder: "请选择交货时间",
                        },
                        on: { change: _vm.deliverDateConfirmed },
                        model: {
                          value: _vm.form.plan.deliverTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.plan, "deliverTime", $$v)
                          },
                          expression: "form.plan.deliverTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.plan.deliverTime
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: "当日累计产值金额" } },
                        [_c("span", [_vm._v(_vm._s(_vm.totalProductionPrice))])]
                      )
                    : _vm._e(),
                  _vm.isMake
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "期望下料时间",
                            prop: "plan.expectMaterialTime",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              "show-time": "",
                              format: "YYYY-MM-DD HH:mm:ss",
                              type: "date",
                              placeholder: "请选择期望下料时间",
                            },
                            model: {
                              value: _vm.form.plan.expectMaterialTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.plan,
                                  "expectMaterialTime",
                                  $$v
                                )
                              },
                              expression: "form.plan.expectMaterialTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isUpdate
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: "任务紧急类型", prop: "emgType" } },
                        [
                          _c(
                            "a-select",
                            {
                              model: {
                                value: _vm.form.emgType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "emgType", $$v)
                                },
                                expression: "form.emgType",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "NML" } },
                                [_vm._v("普通")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "MFN" } },
                                [_vm._v("必完件")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "UGC" } },
                                [_vm._v("紧急件")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "IUG" } },
                                [_vm._v("插入紧急件")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isUpdate
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: "客户需求" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.planForm.clientDemand)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.isUpdate
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "客户需求",
                            prop: "plan.clientDemand",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "max-length": 1024,
                              placeholder: "请输入计划客户需求(不超过1024个字)",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.form.plan.clientDemand,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.plan,
                                  "clientDemand",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.plan.clientDemand",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "备注", prop: "plan.comment" } },
                    [
                      _c("a-input", {
                        attrs: {
                          "max-length": 1024,
                          placeholder: "请输入计划备注(不超过1024个字)",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.form.plan.comment,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.plan,
                              "comment",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.plan.comment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isUpdate
                ? _c(
                    "div",
                    [
                      _c("a-divider", [
                        _c("p", { staticClass: "divider-text" }, [
                          _vm._v("产品信息"),
                        ]),
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "产品名称",
                            prop: "product.productName",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "max-length": 200,
                              placeholder: "请输入产品名称",
                            },
                            model: {
                              value: _vm.form.product.productName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.product,
                                  "productName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.product.productName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isAdd
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "产品类型",
                                prop: "product.type",
                              },
                            },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  model: {
                                    value: _vm.form.product.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form.product, "type", $$v)
                                    },
                                    expression: "form.product.type",
                                  },
                                },
                                [
                                  _c("a-radio", { attrs: { value: "L" } }, [
                                    _vm._v("L类型"),
                                  ]),
                                  _c("a-radio", { attrs: { value: "D" } }, [
                                    _vm._v("D类型"),
                                  ]),
                                  _c("a-radio", { attrs: { value: "G" } }, [
                                    _vm._v("G类型"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "产品图号",
                            prop: "product.drawingNum",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "max-length": 200,
                              placeholder: "请输入产品图号",
                            },
                            model: {
                              value: _vm.form.product.drawingNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.product,
                                  "drawingNum",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.product.drawingNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "产品规格",
                            prop: "product.specification",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "max-length": 200,
                              placeholder: "请输入产品规格",
                            },
                            model: {
                              value: _vm.form.product.specification,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.product,
                                  "specification",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.product.specification",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            "has-feedback": "",
                            label: "订单数量",
                            prop: "product.amount",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              disabled:
                                _vm.isMake && _vm.form.plan.whetherParts === 0,
                              placeholder: "请输入订单数量",
                            },
                            model: {
                              value: _vm.form.product.amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.product,
                                  "amount",
                                  _vm._n($$v)
                                )
                              },
                              expression: "form.product.amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "产品类型",
                            prop: "product.productCategory",
                          },
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value: _vm.form.product.productCategory,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.product,
                                    "productCategory",
                                    $$v
                                  )
                                },
                                expression: "form.product.productCategory",
                              },
                            },
                            [
                              _vm.planType === "L"
                                ? [
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "单体轴类" } },
                                      [_vm._v("单体轴类")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "单体非轴类" } },
                                      [_vm._v("单体非轴类")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "组合" } },
                                      [_vm._v("组合")]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "单体" } },
                                      [_vm._v("单体")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "组合" } },
                                      [_vm._v("组合")]
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.planForm.whetherParts === 1
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                "has-feedback": "",
                                label: "单位",
                                prop: "product.unit",
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入产品单位" },
                                model: {
                                  value: _vm.form.product.unit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.product, "unit", $$v)
                                  },
                                  expression: "form.product.unit",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.product.type === "D"
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "刀具材料类别",
                                prop: "product.materialCategories",
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请选择刀具材料类别",
                                    "option-filter-prop": "children",
                                  },
                                  model: {
                                    value: _vm.form.product.materialCategories,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.product,
                                        "materialCategories",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.product.materialCategories",
                                  },
                                },
                                _vm._l(
                                  _vm.materialCategories,
                                  function (category) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: category.parameterValue,
                                        attrs: {
                                          value: category.parameterCode,
                                        },
                                      },
                                      [_vm._v(_vm._s(category.parameterValue))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
      _vm.warehouseModalVisible
        ? _c("PlanWareHouseModal", {
            attrs: {
              source: _vm.warehouseData,
              title: _vm.warehouseTitle,
              productName: _vm.planForm.productName,
              drawingNum: _vm.planForm.drawingNum,
              specification: _vm.planForm.specification,
            },
            on: {
              cancel: _vm.handleWareHouseCancel,
              confirm: _vm.handleWareHouseConfirm,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }