<template>
  <a-modal
    class="modal-container"
    :visible="true"
    title="存在多个已完成的任务，请选择要生成实际工艺的任务"
    :mask-closable="false"
    width="600px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" :disabled="!task.taskId" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="task" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="任务编号" prop="taskId">
        <a-select v-model="task.taskId" show-search placeholder="请选择任务" option-filter-prop="children" :filter-option="filterUser">
          <a-select-option v-for="task in taskList" :key="task.taskId" :value="task.taskId">{{ task.taskCode }}</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { getTaskProcessRoute } from '@/api/craft'
export default {
  props: {
    planId: Number,
  },
  data() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      loading: false,
      taskList: [],
      task: {
        taskId: '',
      },
      rules: {
        taskId: [
          {
            required: true,
            message: '请选择任务',
            trigger: 'change',
          },
        ],
      },
    }
  },
  mounted() {
    this.handleGetTask()
  },
  methods: {
    handleGetTask() {
      getTaskProcessRoute(this.planId).then((res) => {
        if (res.data.length) {
          this.taskList = res.data.map((item) => {
            return item.taskInfo
          })
        } else {
          this.$message.error('相关任务不存在')
        }
      })
    },
    filterUser(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // this.taskList.map((val) => {
          //   if (val.id === this.task.taskId) {
          //     this.$emit('confirm', val)
          //   }
          // })
          this.$emit('confirm', this.task.taskId)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
