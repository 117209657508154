var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: "结算信息录入",
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c("a-form-model-item", { attrs: { label: "已选金额" } }, [
            _c("span", [_vm._v(_vm._s(_vm.amount))]),
          ]),
          _c(
            "a-form-model-item",
            { attrs: { label: "实际结算金额", prop: "settlePrice" } },
            [
              _c("a-input", {
                attrs: { "max-length": 20, placeholder: "请输入实际结算金额" },
                model: {
                  value: _vm.form.settlePrice,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "settlePrice",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.settlePrice",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "发票号/单据号", prop: "invoiceNumber" } },
            [
              _c("a-input", {
                attrs: { "max-length": 20, placeholder: "请输入发票号/单据号" },
                model: {
                  value: _vm.form.invoiceNumber,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "invoiceNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.invoiceNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "结算类型", prop: "settleType" } },
            [
              _c(
                "a-radio-group",
                {
                  staticStyle: { width: "400px", fontWeight: "400" },
                  model: {
                    value: _vm.form.settleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "settleType", $$v)
                    },
                    expression: "form.settleType",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("现款")]),
                  _c("a-radio", { attrs: { value: 0 } }, [_vm._v("挂账")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "结算时间", prop: "settleTime" } },
            [
              _c("a-date-picker", {
                attrs: {
                  "show-time": "",
                  type: "date",
                  placeholder: "请选择结算时间",
                },
                model: {
                  value: _vm.form.settleTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "settleTime", $$v)
                  },
                  expression: "form.settleTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "settleComment" } },
            [
              _c("a-textarea", {
                attrs: { "max-length": "500", placeholder: "请输入结算备注" },
                model: {
                  value: _vm.form.settleComment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "settleComment",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.settleComment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }