<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    :visible="true"
    title="批量录入加工工序记录"
    :mask-closable="false"
    width="1000px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="batchTasksRecord" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item style="margin-bottom: 5px" label="记录人员" prop="userId">
        <a-select
          v-model="batchTasksRecord.userId"
          show-search
          placeholder="请选择记录人员"
          style="width: 300px"
          option-filter-prop="children"
          :filter-option="filterUser"
          @select="handleUserSelected"
        >
          <a-select-option v-for="user in currentUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item style="margin-bottom: 5px" label="工序" prop="operationId">
        <a-select v-model="batchTasksRecord.operationId" show-search placeholder="请选择工序" style="width: 300px" option-filter-prop="children">
          <a-select-option
            v-for="operation in currentOperationList"
            :key="operation.id"
            :value="operation.id"
          >{{ operation.operationName }}({{ operation.operationCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item style="margin-bottom: 5px" v-if="batchTasksRecord.operationId === 263" label="入库库房" prop="warehouseType">
        <a-select v-model="batchTasksRecord.warehouseType" placeholder="请选择入库库房">
          <a-select-option :value="0">成品库</a-select-option>
          <a-select-option :value="1">半成品库</a-select-option>
          <a-select-option :value="2">工装库</a-select-option>
          <a-select-option :value="3">材料库</a-select-option>
          <a-select-option :value="4">废品库</a-select-option>
          <a-select-option :value="5">来料退回库</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item style="margin-bottom: 5px" label="任务编码(批量)" prop="selectedTasks">
        <a-select
          mode="multiple"
          label-in-value
          :value="batchTasksRecord.selectedTasks"
          placeholder="请扫描或输入任务编号"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @search="fetchTask"
          @change="handleChange"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="task in tasks" :key="task.id">
            {{
            task.code
            }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <a-row v-for="(record, index) in recordSet" :key="index">
      <div class="desc">
        <div class="item">
          <span class="label">任务编码:</span>
          <span class="value">{{ recordSet[index].code }}</span>
        </div>
        <div class="item">
          <span class="label">产品名称:</span>
          <span class="value">{{ recordSet[index].productName }}</span>
        </div>
        <div class="item">
          <span class="label">图号:</span>
          <span class="value">{{ recordSet[index].drawingNum }}</span>
        </div>
        <div class="item">
          <span class="label">规格:</span>
          <span class="value">{{ recordSet[index].specification }}</span>
        </div>
        <div class="item">
          <span class="label">应交检数量:</span>
          <span class="value">{{ recordSet[index].producingNum }}</span>
        </div>
      </div>
      <a-form-model :ref="'recordForm' + index" layout="inline" :model="recordSet[index]" :rules="setRules[index]">
        <a-form-model-item label="实际交检数量" :prop="'actualCheckCount'" :label-col="lineLabelCol" :wrapper-col="lineWrapperCol">
          <a-input v-model.number="recordSet[index].actualCheckCount" style="width: 130px" :max-length="10" />
        </a-form-model-item>

        <a-form-model-item style="margin-left: 60px" label="是否自检" :label-col="lineLabelCol" :wrapper-col="lineWrapperCol">
          <a-switch
            checked-children="是"
            un-checked-children="否"
            :default-checked="false"
            @change="(val) => handleContralCheck(val, index)"
          />
        </a-form-model-item>

        <a-form-model-item
          v-if="recordSet[index].isSelfCheck"
          label="下一加工工序"
          :label-col="lineLabelCol"
          :wrapper-col="lineWrapperCol"
          :prop="'nextOperationId'"
        >
          <a-select
            v-model="recordSet[index].nextOperationId"
            show-search
            placeholder="请选择下一加工工序"
            style="width: 150px"
            option-filter-prop="children"
          >
            <a-select-option v-for="operation in allOperationList" :key="operation.id" :value="operation.id">
              {{ operation.operationName }}({{
              operation.operationCode
              }})
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <a-divider v-if="recordSet.length > 1" />
    </a-row>
  </a-modal>
</template>
<script>
import { getAllUsers } from '@/api/user'
import { getAllOperations } from '@/api/operation'
import { getTaskByTaskCode, addBatchTasksRecord } from '@/api/task'
import * as R from 'ramda'
import debounce from 'lodash/debounce'
export default {
  data() {
    const checkArrayLength = (rule, value, callback) => {
      if (R.isEmpty(value)) {
        return callback(new Error('请选择任务'))
      } else {
        callback()
      }
    }
    this.lastFetchId = 0
    this.fetchTask = debounce(this.fetchTask, 800)
    return {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      spanLabelCol: {
        span: 12
      },
      spanWrapperCol: {
        span: 11
      },
      lineLabelCol: {
        span: 16
      },
      lineWrapperCol: {
        span: 8
      },
      data: [],
      fetching: false,
      userQueryParams: {},
      operationQueryParams: {},
      loading: false,
      allUserList: [],
      selectedTaskIdList: [],
      currentUserList: [],
      allOperationList: [],
      currentOperationList: [],
      tasks: [],
      batchTasksRecord: {
        userId: null,
        operationId: null,
        taskIds: [],
        selectedTasks: [],
        warehouseType: 0
      },
      rules: {
        userId: [
          {
            required: true,
            message: '请选择记录人员',
            trigger: 'change'
          }
        ],
        operationId: [
          {
            required: true,
            message: '请选择工序',
            trigger: 'change'
          }
        ],
        warehouseType: [
          {
            required: true,
            message: '请选择入库库房',
            trigger: 'change'
          }
        ],
        selectedTasks: [
          {
            required: true,
            validator: checkArrayLength,
            trigger: 'change'
          }
        ],
        isSelfCheck: [
          {
            required: true,
            message: '请确认是否自检',
            trigger: 'change'
          }
        ]
      },
      setRules: [],
      recordSet: [],
      searchRecord: {}
    }
  },
  created() {
    this.getAllUsers()
    this.getAllOperations()
  },
  methods: {
    getAllUsers() {
      getAllUsers(this.userQueryParams).then(res => {
        if (res.data && res.data.accountList) {
          if (R.isEmpty(this.userQueryParams)) {
            this.allUserList = R.clone(res.data.accountList)
          }
          this.currentUserList = R.clone(res.data.accountList)
        }
      })
    },
    getAllOperations() {
      getAllOperations(this.operationQueryParams).then(res => {
        if (res.data.operationList) {
          if (R.isEmpty(this.operationQueryParams)) {
            this.allOperationList = R.clone(res.data.operationList)
          }
          this.currentOperationList = R.clone(res.data.operationList)
        }
      })
    },
    fetchTask(value) {
      console.log('fetching task', value)
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.task = {}
      this.fetching = true
      const params = value.replace(/\//g, '%2F')
      getTaskByTaskCode(params).then(result => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.tasks = result.data
        this.searchRecord[value] = result.data[0]
        this.fetching = false
      })
    },
    handleChange(value) {
      Object.assign(this, {
        tasks: [],
        fetching: false
      })
      this.batchTasksRecord.selectedTasks = value
      const currentList = []
      this.batchTasksRecord.selectedTasks.map(val => {
        let ifExit = false
        if (this.recordSet.length) {
          this.recordSet.map(record => {
            if (record.taskId === val.key) {
              currentList.push(record)
              ifExit = true
            }
          })
        }
        if (!ifExit) {
          const _item = this.searchRecord[val.label.trim()]
          currentList.unshift({
            taskId: val.key,
            producingNum: _item.producingNum,
            actualCheckCount: _item.producingNum,
            isSelfCheck: false,
            nextOperationId: '',
            productName: _item.productName,
            specification: _item.specification,
            drawingNum: _item.drawingNum,
            code: val.label
          })
        }
      })
      currentList.forEach((item, index) => {
        this.setRules[index] = {}
        this.setRules[index][`actualCheckCount`] = [
          {
            required: true,
            message: '请输入实际交检数量',
            trigger: 'blur'
          }
        ]
        this.setRules[index][`nextOperationId`] = [
          {
            required: true,
            message: '请选择下一加工工序',
            trigger: 'blur'
          }
        ]
      })
      this.recordSet = currentList
      console.log(currentList)
    },
    handleContralCheck(val, index) {
      const data = this.recordSet[index]
      data.isSelfCheck = val
      this.$set(this.recordSet, index, data)
      console.log(val, index)
    },
    handleUserSelected(value) {
      this.operationQueryParams.userId = 'eq:' + value
      this.getAllOperations()
    },
    handleOperationSelected(value) {
      this.userQueryParams.operationId = 'eq:' + value
      this.getAllUsers()
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      let error = false
      this.recordSet.forEach((item, index) => {
        this.$refs[`recordForm${index}`][0].validate(valid => {
          if (valid) {
          } else {
            console.log('error++')
            error = true
            return
          }
        })
      })
      if (error) {
        return false
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.batchTasksRecord.taskIds = []
          this.batchTasksRecord.selectedTasks.forEach(selectedTask => {
            this.batchTasksRecord.taskIds.push(selectedTask.key)
          })
          this.batchTasksRecord.recordVOSet = this.recordSet
          addBatchTasksRecord(this.batchTasksRecord)
            .then(res => {
              if (res.status === 200) {
                this.loading = false
                this.$emit('confirm')
              }
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    filterUser(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>

<style scoped>
.modal-container >>> .ant-modal-body {
  max-height: 400px;
  overflow-y: scroll;
}
</style>

<style lang="scss" scoped>
.desc {
  width: 100%;
  display: flex;
  padding-left: 92px;
  .item {
    margin-right: 20px;
    .label {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 700;
      line-height: 39.9999px;
      font-size: 14px;
      display: inline-block;
      margin-right: 7px;
    }
  }
}
</style>
