import Vue from 'vue'
import Router from 'vue-router'

// 路由懒加载
const Login = () => import('@/views/login/index')
const NotFound = () => import('@/views/error_page/404')
const PermissionError = () => import('@/views/error_page/401')
const Dashboard = () => import('@/views/dashboard/index')
const Craft = () => import('@/views/craft/index')
const Operation = () => import('@/views/operation/index')
const SearchTask = () => import('@/views/search_task/index')
const Task = () => import('@/views/task/index')
const EmgTask = () => import('@/views/emgTask/index')
const Plan = () => import('@/views/plan/index')
const Order = () => import('@/views/order/orderList/index')
const Product = () => import('@/views/order/product/index')
const Record = () => import('@/views/record/index')
const User = () => import('@/views/user/index')
const Group = () => import('@/views/group/index')
const Role = () => import('@/views/role/index')
const Resource = () => import('@/views/resource/index')
const CraftCard = () => import('@/views/craft-card/index')
const PlanDeliveryCard = () => import('@/views/plan-delivery-card/index')
const CraftDeliveryCard = () => import('@/views/craft-delivery-card/index')
const Inventory = () => import('@/views/inventory/index')
const SaveInventory = () => import('@/views/save-inventory/index')
const OutInventory = () => import('@/views/out-inventory/index')
const SettleAccountsInventory = () =>
  import('@/views/settle-accounts-inventory/index')
const SaveInventoryCard = () => import('@/views/save-inventory-card/index')
const OutInventoryCard = () => import('@/views/out-inventory-card/index')
const OperationCard = () => import('@/views/craft-card-manage/operationCard')
const OperationCardPrint = () =>
  import('@/views/craft-card-manage/operationCardPrint')
const OperationCardTemplate = () =>
  import('@/views/operation-card-template/index')
const Material = () => import('@/views/material/index')
const Parameter = () => import('@/views/parameter/index')
const Certificate = () => import('@/views/certificate/index')
const TemplateCard = () => import('@/views/operation-card-template/template')
const NewOutInventoryCard = () => import('@/views/new-out-inventory-card/index')
const Qualities = () => import('@/views/qualities/index')
const MonthTargetManage = () => import('@/views/targetManage/month/index')
const WeekTargetManage = () => import('@/views/targetManage/week/index')
const DayTargetManage = () => import('@/views/targetManage/day/index')
const UserTarget = () => import('@/views/targetManage/user/index')
const TargetStatistics = () => import('@/views/targetStatistics/index')
const TargetDetail = () => import('@/views/targetDetail/index')
const ManageBoard = () => import('@/views/manage-board/index')
const EfficiencyInsight = () => import('@/views/efficiencyInsight/index')
const BottleneckInsight = () => import('@/views/bottleneckInsight/index')
const IntelligentScheduling = () => import('@/views/intelligentScheduling/index')
const AbandonCard = () => import('@/views/waste-receipt-card/index')
const ReturnInventoryCard = () => import('@/views/return-inventory-card/index')
const ReworkCard = () => import('@/views/disqualification/rework')
const OverToleranceCard = () => import('@/views/disqualification/overTolerance')
const ScrapCard = () => import('@/views/disqualification/scrap')
const AfterSaleCard = () => import('@/views/disqualification/afterSale')
const Settlements = () => import('@/views/settlements/index')
const AccountChecking = () => import('@/views/accountCheck/index')
const ProductLibrary = () => import('@/views/product')
const InquiryList = () => import('@/views/inquiry-list')
const CostAccounting = () => import('@/views/cost-accounting')
const Approval = () => import('@/views/approval')
const Offer = () => import('@/views/offer')
const OfferCard = () => import('@/views/offer-card')
const CostStatement = () => import('@/views/cost-statement')
const QuoteCard = () => import('@/views/quote-card')
const Tenants = () => import('@/views/tenants')
const Suppliers = () => import('@/views/suppliers')

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import SubLayout from '@/layout/subLayout'

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    component: Login,
    hidden: true,
  },
  {
    path: '/404',
    component: NotFound,
    hidden: true,
  },
  {
    path: '/401',
    component: PermissionError,
    hidden: true,
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          title: '工作台',
          icon: 'dashboard',
        },
      },
    ],
  },
  {
    path: '/plan-delivery-card',
    name: 'plan-delivery-card',
    component: PlanDeliveryCard,
    hidden: true,
    meta: {
      title: '任务车间交接表打印',
    },
  },
  {
    path: '/craft-delivery-card',
    name: 'craft-delivery-card',
    component: CraftDeliveryCard,
    hidden: true,
    meta: {
      title: '工艺交接表打印',
    },
  },

  {
    path: '/save-inventory-card',
    name: 'save-inventory-card',
    component: SaveInventoryCard,
    hidden: true,
    meta: {
      title: '入库单',
    },
  },
  {
    path: '/waste-receipt-card',
    name: 'waste-receipt-card',
    component: AbandonCard,
    hidden: true,
    meta: {
      title: '废品入库单',
    },
  },
  {
    path: '/out-inventory-card',
    name: 'out-inventory-card',
    component: OutInventoryCard,
    hidden: true,
    meta: {
      title: '出库单',
    },
  },
  {
    path: '/return-inventory-card',
    name: 'return-inventory-card',
    component: ReturnInventoryCard,
    hidden: true,
    meta: {
      title: '退库交接单',
    },
  },
  {
    path: '/account-check-card',
    name: 'account-check-card',
    component: AccountChecking,
    hidden: true,
    meta: {
      title: '产品对账单',
    },
  },
  {
    path: '/new-out-inventory-card',
    name: 'new-out-inventory-card',
    component: NewOutInventoryCard,
    hidden: true,
    meta: {
      title: '出库单',
    },
  },
  {
    path: '/rework-card',
    name: 'rework-card',
    component: ReworkCard,
    hidden: true,
    meta: {
      title: '不合格返工处置单',
    },
  },
  {
    path: '/overTolerance-card',
    name: 'overTolerance-card',
    component: OverToleranceCard,
    hidden: true,
    meta: {
      title: '不合格品超差处置通知单',
    },
  },
  {
    path: '/scrap-card',
    name: 'scrap-card',
    component: ScrapCard,
    hidden: true,
    meta: {
      title: '产品报废单',
    },
  },
  {
    path: '/afterSale-card',
    name: 'afterSale-card',
    component: AfterSaleCard,
    hidden: true,
    meta: {
      title: '售后服务处置通知单',
    },
  },
  {
    path: '/operation-card',
    name: 'operation-card',
    component: OperationCard,
    hidden: true,
    meta: {
      title: '工艺卡片',
    },
  },
  {
    path: '/operation-card-print',
    name: 'operation-card-print',
    component: OperationCardPrint,
    hidden: true,
    meta: {
      title: '批量打印工艺卡片',
    },
  },
  {
    path: '/template-card',
    name: 'template-card',
    component: TemplateCard,
    hidden: true,
    meta: {
      title: '工艺卡片模板',
    },
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: Certificate,
    hidden: true,
    meta: {
      title: '合格证',
    },
  },
  {
    path: '/all-screen',
    name: 'allScreen',
    component: ManageBoard,
    hidden: true,
    meta: {
      title: '大屏数据展示',
    },
  },
  {
    path: '/offer-card',
    name: 'offerCard',
    component: OfferCard,
    hidden: true,
    meta: {
      title: '报价单',
    },
  },
  {
    path: '/cost-statement',
    name: 'CostStatement',
    component: CostStatement,
    hidden: true,
    meta: {
      title: '成本明细',
    },
  },
  {
    path: '/quote-card',
    name: 'QuoteCard',
    component: QuoteCard,
    hidden: true,
    meta: {
      title: '报价单',
    },
  },
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  // 目标管理
  {
    path: '/target',
    component: Layout,
    name: 'Target',
    meta: {
      title: '经营驾驶舱服务',
      icon: 'chart',
    },
    children: [
      {
        path: 'statistics',
        name: 'TargetStatistics',
        component: TargetStatistics,
        meta: {
          title: '数据统计',
          icon: 'example',
        },
      },
      {
        path: 'screen',
        name: 'ManageBoard',
        component: ManageBoard,
        meta: {
          title: '车间大屏',
          icon: 'international',
        },
      },
      {
        path: 'detail',
        name: 'TargetDetail',
        component: TargetDetail,
        meta: {
          title: '数据明细',
          icon: 'excel',
        },
      },
      {
        path: 'efficiency-insight',
        name: 'EfficiencyInsight',
        component: EfficiencyInsight,
        meta: {
          title: '效能洞察',
          icon: 'eye-open',
        },
      },
      {
        path: 'bottleneck-insight',
        name: 'BottleneckInsight',
        component: BottleneckInsight,
        meta: {
          title: '瓶颈洞察',
          icon: 'skill',
        },
      },
      {
        path: 'intelligent-scheduling',
        name: 'IntelligentScheduling',
        component: IntelligentScheduling,
        meta: {
          title: '智能排程',
          icon: 'tree',
        },
      },
    ],
  },
  {
    path: '/target-manage',
    component: Layout,
    name: 'TargetManage',
    meta: {
      title: '绩效考核服务',
      icon: 'guide',
    },
    redirect: '/target-manage/month',
    children: [
      {
        path: 'user-target',
        name: 'UserTarget',
        component: UserTarget,
        meta: {
          title: '个人日目标',
          icon: 'star',
        },
      },
      {
        path: 'month',
        name: 'MonthTargetManage',
        component: MonthTargetManage,
        meta: {
          title: '月目标制定',
          icon: 'list',
        },
      },
      {
        path: 'week',
        name: 'WeekTargetManage',
        component: WeekTargetManage,
        meta: {
          title: '周目标制定',
          icon: 'peoples',
        },
      },
      {
        path: 'day',
        name: 'DayTargetManage',
        component: DayTargetManage,
        meta: {
          title: '日目标制定',
          icon: 'people',
        },
      },
    ],
  },
  {
    path: '/quotation',
    name: 'Quotation',
    component: Layout,
    meta: {
      title: '报价接单服务',
      icon: 'shopping',
    },
    children: [
      {
        path: 'management',
        name: 'Management',
        meta: {
          title: '报价管理',
          icon: 'money',
        },
        component: SubLayout,
        children: [
          {
            path: 'inquiry-list',
            component: InquiryList,
            name: 'InquiryList',
            meta: {
              title: '询价管理',
              icon: 'wechat',
            },
          },

          {
            path: 'cost-accounting',
            component: CostAccounting,
            name: 'CostAccounting',
            meta: {
              title: '成本核算管理',
              icon: 'excel',
            },
          },
          {
            path: 'approval',
            component: Approval,
            name: 'Approval',
            meta: {
              title: '审价管理',
              icon: 'edit',
            },
          },
          {
            path: 'offer',
            component: Offer,
            name: 'Offer',
            meta: {
              title: '报价管理',
              icon: 'email',
            },
          },
          {
            path: 'product-library',
            component: ProductLibrary,
            name: 'ProductLibrary',
            meta: {
              title: 'BOM管理',
              icon: 'star',
            },
          },
        ],
      },
      {
        path: 'order',
        name: 'OrderManage',
        component: Order,
        meta: {
          title: '订单管理',
          icon: 'shopping',
        },
      },
      {
        path: 'product',
        name: 'ProductManage',
        component: Product,
        meta: {
          title: '产品项管理',
          icon: 'component',
        },
      },
    ],
  },
  {
    path: '/scheduling',
    component: Layout,
    name: 'Scheduling',
    meta: {
      title: '计划排产服务',
      icon: 'table',
    },
    children: [
      {
        path: 'craft',
        name: 'Craft',
        component: SubLayout,
        meta: {
          title: '工艺管理',
          icon: 'documentation',
        },
        children: [
          {
            path: 'index',
            name: 'CraftManage',
            component: Craft,
            meta: {
              title: '卡片管理',
              icon: 'skill',
            },
          },
          {
            path: 'template',
            name: 'CraftTemplateManage',
            component: OperationCardTemplate,
            meta: {
              title: '模板管理',
              icon: 'example',
            },
          },
        ],
      },
      {
        path: 'plan',
        component: Plan,
        name: 'PlanManage',
        meta: {
          title: '计划管理',
          icon: 'table',
        },
      },
      {
        path: 'task',
        name: 'Task',
        meta: {
          title: '任务管理',
          icon: 'list',
        },
        component: SubLayout,
        children: [
          {
            path: 'gtask',
            component: Task,
            name: 'TaskManage',
            meta: {
              title: '所有任务管理',
              icon: 'nested',
            },
          },
          {
            path: 'emg-task',
            component: EmgTask,
            name: 'EmgTaskManage',
            meta: {
              title: '急件任务管理',
              icon: 'form',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/manufacturing',
    component: Layout,
    name: 'Manufacturing',
    meta: {
      title: '生产制造服务',
      icon: 'edit',
    },
    children: [
      {
        path: 'search-task',
        component: SearchTask,
        name: 'SearchTaskManage',
        meta: {
          title: '生产记录录入',
          icon: 'edit',
        },
      },
      {
        path: 'qualities',
        component: Qualities,
        name: 'QualitiesManage',
        meta: {
          title: '质量管理',
          icon: 'bug',
        },
      },
      {
        path: 'record',
        name: 'RecordManage',
        component: Record,
        meta: {
          title: '生产记录管理',
          icon: 'form',
        },
      },
    ],
  },
  {
    path: '/craft-card',
    name: 'craft-card',
    component: CraftCard,
    hidden: true,
    meta: {
      title: '工艺流转卡',
    },
  },
  {
    path: '/inventory',
    component: Layout,
    name: 'Inventory',
    meta: {
      title: '仓储物流服务',
      icon: 'form',
    },
    redirect: '/inventory/index',
    children: [
      {
        path: 'save-inventory',
        name: 'SaveInventory',
        component: SaveInventory,
        meta: {
          title: '入库管理',
          icon: 'form',
        },
      },
      {
        path: 'out-inventory',
        name: 'OutInventory',
        component: OutInventory,
        meta: {
          title: '出库管理',
          icon: 'form',
        },
      },

      {
        path: 'index',
        name: 'InventoryManage',
        component: Inventory,
        meta: {
          title: '库存管理',
          icon: 'chart',
        },
      },
    ],
  },
  {
    path: '/finance',
    component: Layout,
    name: 'Finance',
    meta: {
      title: '对账结算服务',
      icon: 'money',
    },
    children: [
      {
        path: 'settle-accounts-inventory',
        name: 'SettleAccountsInventory',
        component: SettleAccountsInventory,
        meta: {
          title: '结算管理',
          icon: 'form',
        },
      },
      {
        path: 'settlements',
        name: 'Settlements',
        component: Settlements,
        meta: {
          title: '对账管理',
          icon: 'eye-open',
        },
      },
    ],
  },

  // {
  //   path: '/purchase',
  //   component: Layout,
  //   name: 'Purchase',
  //   meta: {
  //     title: '外协采购服务',
  //     icon: 'money',
  //   },
  //   children: [
  //     {
  //       path: 'purchase-plan',
  //       meta: {
  //         title: '采购计划管理',
  //         icon: 'form',
  //       },
  //       children: [
  //         {
  //           path: 'enquiry',
  //           name:'Enquiry',
  //           meta: {
  //             title: '询价管理',
  //             icon: 'form',
  //           },
  //         },
  //         {
  //           path: 'contract',
  //           name:'Contract',
  //           meta: {
  //             title: '合同管理',
  //             icon: 'form',
  //           },
  //         },
  //       ]
  //     },
  //     {
  //       path: 'supplier',
  //       name: 'Supplier',
  //       meta: {
  //         title: '供应商管理',
  //         icon: 'form',
  //       },
  //     },
  //   ]
  // },

  {
    path: '/integration',
    component: Layout,
    name: 'Integration',
    meta: {
      title: '基础数据服务',
      icon: 'user',
    },
    children: [
      {
        path: 'tenants',
        component: Tenants,
        name: 'TenantsManage',
        meta: {
          title: '客户管理',
          icon: 'wechat',
        },
      },
      {
        path: 'suppliers',
        component: Suppliers,
        name: 'SuppliersManage',
        meta: {
          title: '供应商管理',
          icon: 'example',
        },
      },
      {
        path: 'user',
        component: User,
        name: 'UserManage',
        meta: {
          title: '员工管理',
          icon: 'peoples',
        },
      },
      {
        path: 'role',
        component: Role,
        name: 'RoleManage',
        meta: {
          title: '角色管理',
          icon: 'people',
        },
      },
      {
        path: 'operation',
        name: 'OperationManage',
        component: Operation,
        meta: {
          title: '工序管理',
          icon: 'list',
        },
      },
      {
        path: 'group',
        component: Group,
        name: 'GroupManage',
        meta: {
          title: '班组管理',
          icon: 'tree',
        },
      },
      {
        path: 'resource',
        component: Resource,
        name: 'ResourceManage',
        meta: {
          title: '权限管理',
          icon: 'tab',
        },
      },
      {
        path: 'material',
        component: Material,
        name: 'MaterialManage',
        meta: {
          title: '材料管理',
          icon: 'component',
        },
      },
      {
        path: 'parameter',
        component: Parameter,
        name: 'ParameterManage',
        meta: {
          title: '参数管理',
          icon: 'password',
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
