var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.ifBorderVisible
      ? _c("div", { staticClass: "card-container" }, [
          _c(
            "div",
            { attrs: { id: "craft-card-print" } },
            [
              _vm._m(0),
              _c(
                "a-descriptions",
                { staticClass: "desc", attrs: { bordered: "" } },
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "产品名称", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.productName))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "任务号", span: 3 } },
                    [_vm._v(_vm._s(_vm.data.taskCode))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "产品图号", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.drawingNum))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "产品规格", span: 3 } },
                    [_vm._v(_vm._s(_vm.data.specification))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "数量", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.producingNum))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "单位", span: 3 } },
                    [_vm._v(_vm._s(_vm.data.unit))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "结论", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.checkResult))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "客户名称", span: 3 } },
                    [
                      _vm.ifClientVisible
                        ? [_vm._v(_vm._s(_vm.data.client))]
                        : [
                            _c(
                              "span",
                              { staticStyle: { color: "white", opacity: "0" } },
                              [_vm._v(_vm._s(_vm.data.client))]
                            ),
                          ],
                    ],
                    2
                  ),
                  _c("a-descriptions-item", {
                    attrs: { label: "检验员", span: 2 },
                  }),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "日期", span: 3 } },
                    [_vm._v(_vm._s(_vm.data.checkTime))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "div",
                { staticClass: "radio" },
                [
                  _vm._v(" 是否显示边框 "),
                  _c("a-switch", {
                    attrs: { "default-checked": "" },
                    on: { change: _vm.onSwitchChange },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "radio" },
                [
                  _vm._v(" 是否显示客户名称 "),
                  _c("a-switch", {
                    attrs: { "default-checked": "" },
                    on: { change: _vm.onClientSwitchChange },
                  }),
                ],
                1
              ),
              _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "print",
                      rawName: "v-print",
                      value: "craft-card-print",
                      expression: "'craft-card-print'",
                    },
                  ],
                  staticClass: "btn",
                  attrs: { type: "primary", block: "" },
                },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
        ])
      : _c("div", { staticClass: "card-container container-noborder" }, [
          _c(
            "div",
            { attrs: { id: "craft-card-print" } },
            [
              _vm._m(1),
              _c(
                "a-descriptions",
                { staticClass: "desc-without-border", attrs: { bordered: "" } },
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "产品名称", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.productName))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "任务号", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.taskCode))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "产品图号", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.drawingNum))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "产品规格", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.specification))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "数量", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.producingNum))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "单位", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.unit))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "结论", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.checkResult))]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "客户名称", span: 2 } },
                    [
                      _vm.ifClientVisible
                        ? [_vm._v(_vm._s(_vm.data.client))]
                        : [
                            _c(
                              "span",
                              { staticStyle: { color: "white", opacity: "0" } },
                              [_vm._v(_vm._s(_vm.data.client))]
                            ),
                          ],
                    ],
                    2
                  ),
                  _c("a-descriptions-item", {
                    attrs: { label: "检验员", span: 2 },
                  }),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "日期", span: 2 } },
                    [_vm._v(_vm._s(_vm.data.checkTime))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "div",
                { staticClass: "radio" },
                [
                  _vm._v(" 是否显示边框 "),
                  _c("a-switch", {
                    attrs: { "default-checked": "" },
                    on: { change: _vm.onSwitchChange },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "radio" },
                [
                  _vm._v(" 是否显示客户名称 "),
                  _c("a-switch", {
                    attrs: { "default-checked": "" },
                    on: { change: _vm.onClientSwitchChange },
                  }),
                ],
                1
              ),
              _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "print",
                      rawName: "v-print",
                      value: "craft-card-print",
                      expression: "'craft-card-print'",
                    },
                  ],
                  staticClass: "btn",
                  attrs: { type: "primary", block: "" },
                },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("img", { attrs: { src: require("@/assets/logo.png"), alt: "" } }),
      _c("div", { staticClass: "title" }, [
        _c("p", { staticClass: "company" }, [_vm._v("重庆市DBR刀具公司")]),
        _c("p", { staticClass: "desc" }, [_vm._v("产品合格证")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-no-border-header" }, [
      _c("img", { attrs: { src: require("@/assets/logo.png"), alt: "" } }),
      _c("div", { staticClass: "title" }, [
        _c("p", [_vm._v("重庆市DBR刀具公司产品合格证")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }