<template>
  <a-modal class="modal-container" :visible="true" :title="title" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="备注" prop="comment">
        <a-textarea v-model="form.comment" :max-length="200" placeholder="请输入审批备注" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { checkResultApproval } from '@/api/approval'

export default {
  props: {
    code: String,
    type: String
  },
  data() {
    return {
      title: this.type === 'approval' ? '审批通过' : '审批驳回',
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      form: {
        isApprove: this.type === 'approval',
        comment: '',
        itemList: [
          {
            receiptCode: this.code
          }
        ]
      },
      rules: {}
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          checkResultApproval(this.form)
            .then(res => {
              this.$message.success('操作成功')
              this.$emit('confirm')
            })
            .catch(err => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
