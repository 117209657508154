<template>
  <div class="card-container">
    <template v-if="cardList.length">
      <div id="craft-card-print">
        <div v-for="(item, index) in cardList" :key="index">
          <div style="page-break-after:always">
            <div class="header">
              <div class="left">
                <img src="@/assets/logo.png" alt style="width: 100%; height: 100%" />
              </div>
              <p>重庆市DBR刀具有限公司</p>
              <p>产品报废单</p>
              <p class="num">NO:{{ item.qualityBaseDTO.defeatReceiptNumber }}</p>
            </div>
            <div class="base-info">
              <div class="row-1">
                <div class="item left">
                  <div class="label">任务号</div>
                  <div class="content">{{item.qualityBaseDTO.taskCode}}</div>
                </div>
                <div class="item right">
                  <div class="label">报废发现工序</div>
                  <div class="content">{{ item.qualityBaseDTO.defeatFindOperation }}</div>
                </div>
              </div>
              <div class="row-1 row-bottom">
                <div class="item left">
                  <div class="label">图号</div>
                  <div class="content">{{item.qualityBaseDTO.drawingNum}}</div>
                </div>
                <div class="item right">
                  <div class="label">产品名称</div>
                  <div class="content">{{item.qualityBaseDTO.productName}}</div>
                </div>
              </div>
              <div class="row-1 row-bottom">
                <div class="item left">
                  <div class="label">材料牌号</div>
                  <div class="content">{{ item.qualityBaseDTO.materialCode }}</div>
                </div>
                <div class="item right">
                  <div class="label">报废数量</div>
                  <div class="content">{{ item.qualityBaseDTO.productAmount }}</div>
                </div>
              </div>
              <div class="row-1 row-bottom">
                <div class="item left">
                  <div class="label">材料规格</div>
                  <div class="content">{{ item.qualityBaseDTO.contactRecord }}</div>
                </div>
                <div class="item right">
                  <div class="label">报废原因类别</div>
                  <div class="content">{{ item.defeatReasonInfo }}</div>
                </div>
              </div>
              <div class="row-1 row-bottom">
                <div class="item left">
                  <div class="label">报废产值</div>
                  <div class="content">{{ item.qualityBaseDTO.defeatProductionPrice }}</div>
                </div>
                <div class="item right">
                  <div class="label">损失金额</div>
                  <div class="content"></div>
                </div>
              </div>
              <div class="hand-check row-bottom">
                <p>报废品描述：{{ item.qualityBaseDTO.defeatDesc }}</p>
              </div>
              <div class="row-1 row-bottom">
                <div class="item left">
                  <div class="label">责任工序</div>
                  <div class="content">{{ item.responsibleOperationInfo }}</div>
                </div>
                <div class="item right">
                  <div class="label">责任人</div>
                  <div class="content">{{ item.responsibleUserInfo }}</div>
                </div>
              </div>
              <div class="row-1 row-bottom">
                <div class="item left">
                  <div class="label">检验员</div>
                  <div class="content">{{ item.qualityBaseDTO.checker }}</div>
                </div>
                <div class="item right">
                  <div class="label">日期</div>
                  <div class="content">{{ item.qualityBaseDTO.generateTime }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="data">
      <div id="craft-card-print">
        <div style="page-break-after:always">
          <div class="header">
            <div class="left">
              <img src="@/assets/logo.png" alt style="width: 100%; height: 100%" />
            </div>
            <p>重庆市DBR刀具有限公司</p>
            <p>产品报废单</p>
            <p class="num">NO:{{ data.qualityBaseDTO.defeatReceiptNumber }}</p>
          </div>
          <div class="base-info">
            <div class="row-1">
              <div class="item left">
                <div class="label">任务号</div>
                <div class="content">{{data.qualityBaseDTO.taskCode}}</div>
              </div>
              <div class="item right">
                <div class="label">报废发现工序</div>
                <div class="content">{{ data.qualityBaseDTO.defeatFindOperation }}</div>
              </div>
            </div>
            <div class="row-1 row-bottom">
              <div class="item left">
                <div class="label">图号</div>
                <div class="content">{{data.qualityBaseDTO.drawingNum}}</div>
              </div>
              <div class="item right">
                <div class="label">产品名称</div>
                <div class="content">{{data.qualityBaseDTO.productName}}</div>
              </div>
            </div>
            <div class="row-1 row-bottom">
              <div class="item left">
                <div class="label">实发材料</div>
                <div class="content" v-if="data.qualityBaseDTO && data.qualityBaseDTO.actualUseMaterialList">
                  <span v-for="(item, index) in data.qualityBaseDTO.actualUseMaterialList" :key="index">
                    {{item.materialName}}({{item.materialCode}})
                    <span v-if="index < data.qualityBaseDTO.actualUseMaterialList.length - 1">,</span>
                  </span>
                </div>
              </div>
              <div class="item right">
                <div class="label">报废数量</div>
                <div class="content">{{ data.qualityBaseDTO.productAmount }}</div>
              </div>
            </div>
            <div class="row-1 row-bottom">
              <div class="item left">
                <div class="label">材料规格</div>
                <div class="content">{{ data.qualityBaseDTO.contactRecord }}</div>
              </div>
              <div class="item right">
                <div class="label">报废原因类别</div>
                <div class="content">{{ defeatReasonInfo }}</div>
              </div>
            </div>
            <div class="row-1 row-bottom">
              <div class="item left">
                <div class="label">报废产值</div>
                <div class="content">{{ data.qualityBaseDTO.defeatProductionPrice }}</div>
              </div>
              <div class="item right">
                <div class="label">损失金额</div>
                <div class="content"></div>
              </div>
            </div>
            <div class="hand-check row-bottom">
              <p>报废品描述：{{ data.qualityBaseDTO.defeatDesc }}</p>
            </div>
            <div class="row-1 row-bottom">
              <div class="item left">
                <div class="label">责任工序</div>
                <div class="content">{{ responsibleOperationInfo }}</div>
              </div>
              <div class="item right">
                <div class="label">责任人</div>
                <div class="content">{{ responsibleUserInfo }}</div>
              </div>
            </div>
            <div class="row-1 row-bottom">
              <div class="item left">
                <div class="label">检验员</div>
                <div class="content">{{ data.qualityBaseDTO.checker }}</div>
              </div>
              <div class="item right">
                <div class="label">日期</div>
                <div class="content">{{ data.qualityBaseDTO.generateTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="footer">
      <a-button v-print="'craft-card-print'" type="primary" class="btn" block>打印</a-button>
    </div>
  </div>
</template>
<script>
import { getQualitiesReceipt, getBatchQualitiesReceipt } from '@/api/qualities'

export default {
  data() {
    return {
      data: '',
      defeatReasonInfo: '',
      responsibleOperationInfo: '',
      responsibleUserInfo: '',
      defeatFindOperation: '',
      cardList: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const ids = this.$route.query.ids.split(',')
      if (ids.length <= 1) {
        getQualitiesReceipt(this.$route.query.ids).then(res => {
          if (res.data) {
            this.data = res.data
            if (
              this.data.qualityDetailDTO.defeatFindOperation &&
              this.data.qualityDetailDTO.defeatFindOperation.length
            ) {
              this.data.qualityDetailDTO.defeatFindOperation.map(item => {
                if (this.defeatFindOperation) {
                  this.defeatFindOperation += ','
                }
                this.defeatFindOperation += item.operationName + ' '
              })
            }
            if (
              this.data.qualityDetailDTO.defeatReasonInfo &&
              this.data.qualityDetailDTO.defeatReasonInfo.length
            ) {
              this.data.qualityDetailDTO.defeatReasonInfo.map(item => {
                if (this.defeatReasonInfo) {
                  this.defeatReasonInfo += ','
                }
                this.defeatReasonInfo += item.parameterName + ' '
              })
            }
            if (
              this.data.qualityDetailDTO.responsibleOperationInfo &&
              this.data.qualityDetailDTO.responsibleOperationInfo.length
            ) {
              this.data.qualityDetailDTO.responsibleOperationInfo.map(item => {
                if (this.responsibleOperationInfo) {
                  this.responsibleOperationInfo += ','
                }
                this.responsibleOperationInfo += item.operationName + ' '
              })
            }
            if (
              this.data.qualityDetailDTO.responsibleUserInfo &&
              this.data.qualityDetailDTO.responsibleUserInfo.length
            ) {
              this.data.qualityDetailDTO.responsibleUserInfo.map(item => {
                if (this.responsibleUserInfo) {
                  this.responsibleUserInfo += ','
                }
                this.responsibleUserInfo += item.realName + ' '
              })
            }
          } else {
            this.$message.error('获取数据失败')
          }
        })
      } else {
        getBatchQualitiesReceipt({ ids: this.$route.query.ids }).then(res => {
          this.cardList = res.data
          this.cardList.map(card => {
            if (
              card.qualityDetailDTO.defeatFindOperation &&
              card.qualityDetailDTO.defeatFindOperation.length
            ) {
              card.defeatFindOperation = ''
              card.qualityDetailDTO.defeatFindOperation.map(item => {
                if (card.defeatFindOperation) {
                  card.defeatFindOperation += ','
                }
                card.defeatFindOperation += item.operationName + ' '
              })
            }
            if (
              card.qualityDetailDTO.defeatReasonInfo &&
              card.qualityDetailDTO.defeatReasonInfo.length
            ) {
              card.defeatReasonInfo = ''
              card.qualityDetailDTO.defeatReasonInfo.map(item => {
                if (card.defeatReasonInfo) {
                  card.defeatReasonInfo += ','
                }
                card.defeatReasonInfo += item.parameterName + ' '
              })
            }
            if (
              card.qualityDetailDTO.responsibleOperationInfo &&
              card.qualityDetailDTO.responsibleOperationInfo.length
            ) {
              card.responsibleOperationInfo = ''
              card.qualityDetailDTO.responsibleOperationInfo.map(item => {
                if (card.responsibleOperationInfo) {
                  card.responsibleOperationInfo += ','
                }
                card.responsibleOperationInfo += item.operationName + ' '
              })
            }
            if (
              card.qualityDetailDTO.responsibleUserInfo &&
              card.qualityDetailDTO.responsibleUserInfo.length
            ) {
              card.responsibleUserInfo = ''
              card.qualityDetailDTO.responsibleUserInfo.map(item => {
                if (card.responsibleUserInfo) {
                  card.responsibleUserInfo += ','
                }
                card.responsibleUserInfo += item.realName + ' '
              })
            }
          })
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.card-container {
  width: 80%;
  margin: auto;
}

.page {
  margin-bottom: 100px;
}

.header {
  box-sizing: border-box;
  padding-top: 10px;
  width: 100%;
  height: 100px;
  border: 2px solid #000;
  border-bottom: none;
  font-size: 22px;
  color: #000;
  font-weight: bold;
  text-align: center;
  position: relative;
  .num {
    position: absolute;
    bottom: 0;
    right: 10%;
  }

  .left {
    width: 100px;
    height: 50px;
    position: absolute;
    top: 10px;
    left: 5%;
  }
}

.footer {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.base-info {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  .row-1 {
    min-height: 40px;
    border: 2px solid #000;
    display: flex;
    word-break: break-all;
    .left {
      flex: 1;
      display: flex;
      .label {
        width: 30%;
        text-align: center;
        line-height: 38px;
        border-right: 2px solid #000;
      }
      .content {
        width: 70%;
        text-align: center;
        line-height: 38px;
        border-right: 2px solid #000;
      }
    }
    .right {
      flex: 1;
      display: flex;
      .label {
        width: 30%;
        text-align: center;
        line-height: 38px;
        border-right: 2px solid #000;
      }
      .content {
        width: 70%;
        text-align: center;
        line-height: 38px;
      }
    }
  }
  .hand-check {
    width: 100%;
    height: 15vh;
    border: 2px solid #000;
    box-sizing: border-box;
    padding-left: 10px;
  }
  .row-bottom {
    border-top: none;
  }
}
.num {
  box-sizing: border-box;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  text-align: right;
  padding-right: 100px;
}
</style>
  