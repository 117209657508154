<template>
  <div class="card-container">
    <div id="craft-card-print">
      <div style="page-break-after:always">
        <div class="title">
          <div class="left">工艺设计计划编制交接表</div>
        </div>
        <a-table :columns="columns" style="width: 100%;" :data-source="taskDatas" bordered :pagination="false" />
      </div>
    </div>
    <a-button v-print="'craft-card-print'" type="primary" style="margin-top: 30px;" block>打印</a-button>
  </div>
</template>
<script>
import Moment from 'moment'
import { getCraftDisignCardInfoByIds } from '@/api/craft'
const columns = [
  {
    title: '任务号',
    dataIndex: 'code',
    width: 100,
  },
  {
    title: '合同编号',
    dataIndex: 'orderCode',
    width: 100,
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    width: 100,
  },
  {
    title: '产品图号',
    dataIndex: 'drawingNum',
    width: 200,
  },
  {
    title: '单位',
    dataIndex: 'unit',
  },
  {
    title: '订单数量',
    dataIndex: 'amount',
  },
  {
    title: '下达日期',
    dataIndex: 'commandTime',
  },
  {
    title: '合同交货日期',
    dataIndex: 'deadline',
  },
  {
    title: '客户名称',
    dataIndex: 'client',
  },
  {
    title: '无税单价',
    dataIndex: 'taxFreePrice',
  },
  {
    title: '工艺编制期限',
    dataIndex: 'processPreparationPeriod',
  },
  {
    title: '工艺编制截止日期',
    dataIndex: 'processPreparationDeadline',
  },
  {
    title: '技术主管签字及日期',
  },
  {
    title: '工艺接收人',
  },
  {
    title: '计划员接受图纸日期',
  },
]
export default {
  data() {
    return {
      columns,
      taskCode: null,
      taskDatas: [],
      time: '',
    }
  },
  created() {
    this.time = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    const planIds = this.$route.query.ids
    getCraftDisignCardInfoByIds({
      planIds,
    }).then((res) => {
      if (res.data) {
        this.taskDatas = res.data
      } else {
        this.$message.error('获取工艺设计交接表数据失败，请重试！')
      }
    })
  },
}
</script>
<style scoped lang="scss">
.card-container {
  width: 85%;
  margin: auto;
}

.title {
  height: 50px;
  display: flex;
  font-size: 20px;
  line-height: 50px;
  margin-bottom: 2px;
  border: 1px solid black;
  .left {
    background-color: #fafafa;
    border-right: 1px solid black;
    flex: 4.3;
    text-align: center;
  }
  .right {
    flex: 1;
    text-align: center;
  }
}
.card-title >>> .ant-descriptions-item-label {
  font-size: 20px;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
  border: 1px solid black;
  text-align: center;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
  border: 1px solid black;
  text-align: center;
  font-weight: bold;
}

/deep/ .ant-table-thead th {
  border: 1px solid black;
  padding: 8px 8px;
  text-align: center;
}

/deep/ .ant-table-tbody td {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 8px 8px;
  text-align: center;
}

/deep/ .ant-table-content {
  border-right: 1px solid black;
}

.footer-form {
  float: right;
  margin-top: 15px;
  margin-bottom: 20px;
}

.bottom-line {
  width: 100px;
  height: 20px;
  margin-right: 10px;

  display: inline-block;
  border-bottom: 1px solid grey;
}
</style>
