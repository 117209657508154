<template>
  <el-upload
    :limit="1"
    :action="action"
    :accept="accept"
    :before-upload="beforeUploadFile"
    :on-success="uploadSuc"
    :on-error="uploadErr"
    :data="data"
    :on-progress="progressChange"
    multiple
    :show-file-list="false"
  >
    <span>
      <i class="el-icon-upload" />上传{{ desc === 'Excel' ? 'Excel文件导入产品' : desc }}
    </span>
  </el-upload>
</template>

<script>
export default {
  name: 'Upload',
  props: {
    action: {
      type: String,
      required: true
    },
    accept: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 200
    },
    data: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  methods: {
    beforeUploadFile(file) {
      const size = file.size / 1024 / 1024
      const isLt200M = size <= this.size
      if (size > this.size) {
        this.$notify.warning({
          title: '警告',
          message: `文件大小不得超过${this.size}M`
        })
      }
      return isLt200M
    },
    uploadSuc(res) {
      this.$notify({
        title: `${this.desc === 'Excel' ? `Excel文件上传成功` : `上传${this.desc}成功`}`,
        dangerouslyUseHTMLString: true,
        message: `<div>${this.desc === 'Excel' ? `成功通过Excel导入产品` : `上传的${this.desc}: ${res.fileName}`}</div>`,
        type: 'success'
      })
      this.$emit('refreshTable')
    },
    uploadErr(err) {
      err = err + ''
      const errMessage = JSON.parse(err.slice(7)).apierror.message
      this.$notify({
        title: `上传${this.desc}失败`,
        dangerouslyUseHTMLString: true,
        message: `<div>失败原因: ${errMessage}</div>`,
        type: 'error'
      })
      this.$emit('refreshTable')
    },
    progressChange(e) {
      const percent = e.percent
    }
  }
}
</script>

<style scoped></style>
