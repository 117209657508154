export const craftColumns = [
  {
    title: '文件编号',
    dataIndex: 'craftCode',
    width: 100,
    key: 'craftCode',
    ellipsis: true,
    sorter: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'craftCode',
    },
  },
  {
    title: '计划编号',
    dataIndex: 'code',
    key: 'plan.code',
    width: 100,
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'text-filter',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户需求',
    width: 150,
    dataIndex: 'clientDemand',
    key: 'clientDemand',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    key: 'product.productName',
    width: 150,
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'productName',
    },
  },
  {
    title: '产品图号',
    dataIndex: 'drawingNum',
    key: 'product.drawingNum',
    align: 'center',
    width: 200,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '工艺图文件',
    dataIndex: 'fileName',
    key: 'fileName',
    width: 120,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'fileName',
    },
  },
  {
    title: '规格',
    dataIndex: 'specification',
    key: 'product.specification',
    align: 'center',
    width: 120,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },

  {
    title: '单位',
    dataIndex: 'unit',
    width: 50,
    key: 'unit',
    align: 'center',
  },
  {
    title: '是否分件',
    dataIndex: 'isParts',
    key: 'isParts',
    width: 100,
    align: 'center',
    scopedSlots: {
      customRender: 'isParts',
    },
    filters: [
      {
        text: '是',
        value: 1,
      },
      {
        text: '否',
        value: 0,
      },
    ],
  },
  {
    title: '订单数量',
    dataIndex: 'amount',
    width: 100,
    key: 'amount',
    align: 'center',
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    key: 'craft.startTime',
    align: 'center',
    ellipsis: true,
    sorter: true,
    width: 100,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '交货日期',
    dataIndex: 'deadline',
    width: 100,
    key: 'deadline',
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },

  {
    title: '金额(无税)',
    dataIndex: 'totalMoneyTaxExclude',
    key: 'totalMoneyTaxExclude',
    align: 'center',
    width: 100,
    sorter: true,
  },
  {
    title: '工艺状态',
    dataIndex: 'craftStatus',
    width: 100,
    key: 'craftStatus',
    align: 'center',
    scopedSlots: {
      customRender: 'craftStatus',
    },
    filters: [
      {
        text: '进行中',
        value: 2,
      },
      {
        text: '已完成',
        value: 3,
      },
      {
        text: '超期进行中',
        value: 4,
      },
      {
        text: '即将超期',
        value: 5,
      },
      {
        text: '暂停中',
        value: 9,
      },
      {
        text: '已取消',
        value: 8,
      },
      {
        text: '异常终止',
        value: 10,
      },
    ],
  },
  {
    title: '截止时间',
    dataIndex: 'craftDeadline',
    key: 'craftDeadline',
    align: 'center',
    width: 100,
    ellipsis: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '超期天数',
    dataIndex: 'overdueDays',
    key: 'overdueDays',
    align: 'center',
    width: 80,
  },
  {
    title: '完成时间',
    dataIndex: 'finishTime',
    key: 'craft.finishTime',
    align: 'center',
    width: 100,
    ellipsis: true,
    sorter: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户名称',
    dataIndex: 'client',
    ellipsis: true,
    width: 150,
    key: 'product_order.client',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '订单编号',
    dataIndex: 'orderCode',
    width: 120,
    key: 'product_order.orderCode',
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '工艺产品名称',
    dataIndex: 'craftProductName',
    key: 'craftProductName',
    width: 200,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '工艺产品图号',
    dataIndex: 'craftDrawingNum',
    key: 'craftDrawingNum',
    align: 'center',
    width: 200,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '工艺产品规格',
    dataIndex: 'craftSpecification',
    key: 'craftSpecification',
    align: 'center',
    width: 200,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '编制人',
    dataIndex: 'charger',
    key: 'craft.charger',
    align: 'center',
    width: 120,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    fixed: 'right',
    width: 80,
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
export const craftCardColumns = [
  {
    title: '操作',
    width: 150,
    key: 'operation',
    dataIndex: 'operation',
    align: 'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
  {
    title: '版本',
    dataIndex:
      'operationCardHeaderDTO.operationCardBaseDTO.operationCardVersion',
    key: 'operationCardVersion',
    align: 'center',
    scopedSlots: {
      customRender: 'operationCardVersion',
    },
  },
  {
    title: '材料名称',
    dataIndex: 'operationCardHeaderDTO.craftMaterialDTO.materialName',
    key: 'materialName',
    align: 'center',
    scopedSlots: {
      customRender: 'materialName',
    },
  },
  {
    title: '材料牌号',
    dataIndex: 'operationCardHeaderDTO.craftMaterialDTO.materialCode',
    key: 'materialCode',
    align: 'center',
    scopedSlots: {
      customRender: 'materialCode',
    },
  },
  {
    title: '材料硬度',
    dataIndex: 'operationCardHeaderDTO.craftProductDTO.operationHardness',
    key: 'hardness',
    align: 'center',
    scopedSlots: {
      customRender: 'hardness',
    },
  },
  {
    title: '卡片状态',
    width: 150,
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '总加工工时(H)',
    dataIndex: 'operationCardHeaderDTO.operationCardBaseDTO.totalProcessHour',
    key: 'totalProcessHour',
    align: 'center',
    scopedSlots: {
      customRender: 'totalProcessHour',
    },
  },
  {
    title: '编制日期',
    dataIndex: 'operationCardFooterDTO.craftDesignDTO.designTime',
    key: 'designTime',
    align: 'center',
    scopedSlots: {
      customRender: 'designTime',
    },
  },
  {
    title: '标准工艺路线',
    dataIndex: 'processRoute',
    key: 'processRoute',
    align: 'center',
    scopedSlots: {
      customRender: 'processRoute',
    },
  },
]

export const craftRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({
    props: {},
  }),
}
