<template>
  <a-modal class="modal-container" visible="true" title="修改密码" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :disabled="disable" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="passwordForm" :model="passwordForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item has-feedback label="新密码" prop="pass">
        <a-input v-model="passwordForm.pass" :max-length="15" type="password" autocomplete="off" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="确认新密码" prop="checkPass">
        <a-input v-model="passwordForm.checkPass" :max-length="15" type="password" autocomplete="off" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updatePassword } from '@/api/account'
import { getTokenKey } from '@/api/account'
import CryptoJS from 'crypto-js'
function encryptPassword(password, tokenKey) {
  const parsePassword = CryptoJS.enc.Utf8.parse(password)
  const parseTokenKey = CryptoJS.enc.Utf8.parse(tokenKey)
  const encryptedPassword = CryptoJS.AES.encrypt(parsePassword, parseTokenKey, {
    iv: parseTokenKey,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString()
  return encryptedPassword
}
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 5) {
        callback(new Error('密码必须大于5位字符'))
      } else {
        if (this.passwordForm.checkPass !== '') {
          this.$refs.passwordForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passwordForm.pass) {
        callback(new Error('前后输入不一致'))
      } else {
        callback()
      }
    }
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 10,
        offset: 1,
      },
      passwordForm: {
        pass: '',
        checkPass: '',
      },
      rules: {
        pass: [{ validator: validatePass, required: true, trigger: 'change' }],
        checkPass: [
          { validator: validatePass2, required: true, trigger: 'blur' },
        ],
      },
      loading: false,
    }
  },
  computed: {
    disable() {
      if (
        this.passwordForm.pass &&
        this.passwordForm.checkPass &&
        this.passwordForm.pass === this.passwordForm.checkPass
      ) {
        return false
      }
      return true
    },
  },
  methods: {
    handleReset() {
      this.$refs.passwordForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.loading = true
          getTokenKey().then((res) => {
            const tokenKey = res.data.data.tokenKey
            const userKey = res.data.data.userKey
            const id = this.$store.getters.userId
            if (res.data.code === 2001) {
              const encryptedPassword = encryptPassword(
                this.passwordForm.pass,
                tokenKey
              )
              if (id) {
                updatePassword(id, encryptedPassword, userKey)
                  .then((res) => {
                    if (res.data.id) {
                      this.$message.success('修改成功')
                      this.$emit('confirm')
                    } else {
                      this.$message.error('修改失败，请重试！')
                    }
                    this.loading = false
                  })
                  .catch(() => {
                    this.loading = false
                  })
                  .finally(() => {
                    this.loading = false
                  })
              }
            }
          })
        } else {
          console.log('error submit!!', valid)
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
