<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" title="外协明细" :mask-closable="false" width="800px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" class="cost-form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="工序编码" :labelCol="{span: 8}" style="margin-left: 89px;" prop="operationInfo.code">
        <a-select
          style="width: 300px"
          :value="form.operationInfo.code"
          show-search
          :default-active-first-option="false"
          placeholder="请输入工序编码"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @search="fetchOperationByCode"
          @change="(value, option) => handleOperationsChange(value, option, index)"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="operation in operations" :key="operation.id">{{ operation.operationName }}({{ operation.operationCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="名称" :labelCol="{span: 8}" style="margin-left: 89px;" prop="operationInfo.name">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.operationInfo.name" placeholder="请输入工序名称" />
      </a-form-model-item>

      <a-form-model-item label="外协单价" :labelCol="{span: 8}" style="margin-left: 89px;" prop="operationInfo.unitPrice">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.operationInfo.unitPrice" placeholder="请输入外协单价" />
      </a-form-model-item>
      <a-form-model-item label="供应商名称" :labelCol="{span: 8}" style="margin-left: 89px;" prop="companyInfo.name">
        <a-select
          style="width: 300px"
          show-search
          :default-active-first-option="false"
          placeholder="请输入供应商名称"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @search="fetchTeantsByName"
          @change="(value, option)  => handleTenantsChange(value)"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="ten in tenants" :key="ten.name">{{ ten.name }}({{ ten.code }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="外协数量" :labelCol="{span: 8}" style="margin-left: 89px;" prop="quantity">
        <a-input style="width: 300px" :maxLength="20" v-model.trim="form.quantity" placeholder="请输入数量" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { fuzzyOperations } from '@/api/operation'
import { getAllSuppliers } from '@/api/suppliers'

import * as R from 'ramda'
import debounce from 'lodash/debounce'
export default {
  props: {
    record: Object
  },
  data() {
    this.fetchTeantsByName = debounce(this.fetchTenantsByName, 800)
    this.fetchOperationByCode = debounce(this.fetchOperationByCode, 800)
    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      form: this.record || {
        sequence: '',
        operationInfo: {
          code: '',
          name: '',
          type: 2,
          unitPrice: ''
        },
        companyInfo: {
          name: '',
          code: '',
          nickName: ''
        },
        quantity: ''
      },
      operations: [],
      fetching: false,
      lastFetchId: 0,
      rules: {
        'operationInfo.code': [
          {
            required: true,
            message: '请输入工序编码',
            trigger: 'blur'
          }
        ],
        'operationInfo.name': [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        'operationInfo.type': [
          {
            required: true,
            message: '请选择类型',
            trigger: 'change'
          }
        ],
        'operationInfo.unitPrice': [
          {
            required: true,
            message: '请输入工序/外协单价',
            trigger: 'blur'
          }
        ],
        'companyInfo.name': [
          {
            required: true,
            message: '请输入供应商名称',
            trigger: 'blur'
          }
        ],
        quantity: [
          {
            required: true,
            message: '请输入外协数量',
            trigger: 'blur'
          }
        ]
      },
      tenants: []
    }
  },
  computed: {
    // 计算属性的 getter
    isUpdate: function() {
      return this.record
    },
    isAdd: function() {
      return !this.record
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.$emit('add', this.form)
          } else {
            this.$emit('update', this.form)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    fetchOperationByCode(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.operations = []
      this.fetching = true
      fuzzyOperations({
        searchText: value,
        type: 2
      })
        .then(result => {
          if (fetchId !== this.lastFetchId) {
            return
          }
          this.operations = result.data
          this.fetching = false
        })
        .finally(() => {
          this.fetching = false
        })
    },
    handleOperationsChange(id, option, index) {
      this.operations.map(val => {
        if (val.id === id) {
          this.form.operationInfo.code = val.operationCode
          this.form.operationInfo.name = val.operationName
          this.form.operationInfo.unitPrice = val.unitPrice
        }
      })
    },
    fetchTenantsByName(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.tenants = []
      this.fetching = true
      getAllSuppliers({
        name: 'like:' + value
      })
        .then(result => {
          if (fetchId !== this.lastFetchId) {
            return
          }
          this.tenants = result.data
          this.fetching = false
        })
        .finally(() => {
          this.fetching = false
        })
    },
    handleTenantsChange(value, option) {
      this.tenants.forEach(item => {
        if (item.name === value) {
          this.form.companyInfo.name = value
          this.form.companyInfo.code = item.code
          this.form.companyInfo.nickName = item.nickName
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
