var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title + "订单",
        maskClosable: false,
        width: "700px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [
            _vm._v(" 重置 "),
          ]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [
            _vm._v(" 取消 "),
          ]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.order,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "订单编号", prop: "orderCode" } },
            [
              _c("a-input", {
                attrs: { maxLength: "15", placeholder: "请输入订单编号" },
                model: {
                  value: _vm.order.orderCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.order,
                      "orderCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "order.orderCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "签约时间", prop: "signTime" } },
            [
              _c("a-date-picker", {
                attrs: {
                  "show-time": "",
                  type: "date",
                  placeholder: "请选择下达时间",
                },
                model: {
                  value: _vm.order.signTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "signTime", $$v)
                  },
                  expression: "order.signTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "客户名称", prop: "client" } },
            [
              _c("a-input", {
                attrs: { maxLength: "40", placeholder: "请输入客户名称" },
                model: {
                  value: _vm.order.client,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.order,
                      "client",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "order.client",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "合同交货日期", prop: "deadline" } },
            [
              _c("a-date-picker", {
                attrs: {
                  "show-time": "",
                  type: "date",
                  placeholder: "请选择合同交货日期",
                },
                model: {
                  value: _vm.order.deadline,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "deadline", $$v)
                  },
                  expression: "order.deadline",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实际交货日期", prop: "realDeliverTime" } },
            [
              _c("a-date-picker", {
                attrs: {
                  "show-time": "",
                  type: "date",
                  placeholder: "请选择实际交货日期",
                },
                model: {
                  value: _vm.order.realDeliverTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "realDeliverTime", $$v)
                  },
                  expression: "order.realDeliverTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "客户合同编码", prop: "clientContractCode" } },
            [
              _c("a-input", {
                attrs: { maxLength: "40", placeholder: "请输入客户合同编码" },
                model: {
                  value: _vm.order.clientContractCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.order,
                      "clientContractCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "order.clientContractCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "客户采购订单号",
                prop: "clientPurchaseOrderCode",
              },
            },
            [
              _c("a-input", {
                attrs: { maxLength: "40", placeholder: "请输入订单编号" },
                model: {
                  value: _vm.order.clientPurchaseOrderCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.order,
                      "clientPurchaseOrderCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "order.clientPurchaseOrderCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "订单负责人", prop: "charger" } },
            [
              _c("a-input", {
                attrs: { maxLength: "15", placeholder: "请输入订单负责人" },
                model: {
                  value: _vm.order.charger,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.order,
                      "charger",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "order.charger",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "客户订单编码", prop: "clientOrderCode" } },
            [
              _c("a-input", {
                attrs: { maxLength: "15", placeholder: "请输入客户订单编码" },
                model: {
                  value: _vm.order.clientOrderCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.order,
                      "clientOrderCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "order.clientOrderCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "客户项目编码", prop: "clientProjectCode" } },
            [
              _c("a-input", {
                attrs: { maxLength: "40", placeholder: "请输入客户项目编码" },
                model: {
                  value: _vm.order.clientProjectCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.order,
                      "clientProjectCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "order.clientProjectCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "订单罚金比例", prop: "penaltyInterest" } },
            [
              _c("a-input-number", {
                attrs: {
                  precision: 2,
                  min: 0,
                  max: 1,
                  step: 0.01,
                  placeholder: "罚金比例",
                },
                model: {
                  value: _vm.order.penaltyInterest,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "penaltyInterest", $$v)
                  },
                  expression: "order.penaltyInterest",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "comment" } },
            [
              _c("a-input", {
                attrs: {
                  maxLength: "1024",
                  placeholder: "请输入订单备注(不超过1024个字)",
                  type: "textarea",
                },
                model: {
                  value: _vm.order.comment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.order,
                      "comment",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "order.comment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }