<template>
  <div class="container">
    <a-tabs v-model="typeTab" class="tab" @change="getStatisticsDetail">
      <a-tab-pane key="DN" tab="D目标明细" />
      <a-tab-pane key="DE" tab="插入急件D目标明细" force-render />
      <a-tab-pane key="LN" tab="L目标明细" />
      <a-tab-pane key="LE" tab="插入急件L目标明细" />
      <a-tab-pane key="GN" tab="G目标明细" />
    </a-tabs>
    <div class="top">
      <span class="title">{{targetName}}{{ $route.query.name }}{{ title }}目标明细</span>
      <a-radio-group v-model="targetTab" class="type" @change="handleChangeStatistics">
        <a-radio-button value="task">项数</a-radio-button>
        <a-radio-button value="product">数量</a-radio-button>
        <a-radio-button value="amount" v-if="targetType !== 'day'">金额</a-radio-button>
      </a-radio-group>
    </div>
    <div class="statistics">
      <div v-if="['DN', 'DE', 'LN', 'LE'].indexOf(typeTab) !== -1" class="left">
        <p class="title">产品类型分析</p>
        <div id="bar" />
        <div v-if="taskLoading" class="page-loading">
          <a-icon type="loading" style="color:black;" />
        </div>
      </div>
      <div v-else id="bar" />
      <div v-if="['DN', 'LN', 'GN'].indexOf(typeTab) !== -1" class="right">
        <p class="title">任务分析</p>
        <div v-if="renderInfo.taskTypeStatisticsList && renderInfo.taskTypeStatisticsList.length" class="list">
          <div v-for="(item, index) of renderInfo.taskTypeStatisticsList" :key="index" class="item">
            <div :id="'task' + index" class="pie" />
            <div class="desc">
              <p>
                总计:
                <template v-if="item.data.totalCount || item.data.totalCount === 0">{{ item.data.totalCount }}</template>
                <template v-else>{{ item.data.totalAmount }}</template>
              </p>
              <p>
                完成:
                <template v-if="item.data.finishedCount || item.data.finishedCount === 0">{{ item.data.finishedCount }}</template>
                <template v-else>{{ item.data.finishedAmount }}</template>
              </p>
            </div>
          </div>
        </div>
        <div v-if="taskLoading" class="page-loading">
          <a-icon type="loading" style="color:black;" />
        </div>
      </div>
    </div>
    <div class="table">
      <a-table
        row-key="id"
        bordered
        :scroll="{ x: 2000 }"
        :columns="targetType === 'week' ? groupColumns : targetType === 'day' ? memberColumns : detailColumns"
        :data-source="taskList"
        :loading="tableLoading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
          <template v-if="column.searchType === 'text'">
            <a-input
              v-ant-ref="c => (searchInput = c)"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
            />
          </template>
          <div v-else-if="column.searchType === 'number'">
            <a-input-number
              v-model="selectedKeys[0]"
              size="small"
              placeholder="最小值"
              :min="0"
              :max="999999"
              style="width: 95px; margin-bottom: 8px; display: inline-block;"
              @change="e => setSelectedKeys([e,selectedKeys[1]])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
            />
            <span>-</span>
            <a-input-number
              v-model="selectedKeys[1]"
              size="small"
              placeholder="最大值"
              :min="selectedKeys[0]"
              :max="999999"
              style="width: 95px; margin-bottom: 8px; display: inline-block;"
              @change="e => setSelectedKeys([selectedKeys[0],e])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
            />
          </div>
          <div v-else-if="column.searchType === 'date'">
            <a-range-picker
              :show-time="{ format: 'HH:mm:ss' }"
              size="small"
              style="width: 330px; margin-bottom: 8px;"
              :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
              @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
            />
          </div>
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.key)"
          >搜索</a-button>
          <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
        </div>
        <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        <span slot="emergencyType" slot-scope="text">
          <a-tag color="volcano">{{ EmergencyType[text] }}</a-tag>
        </span>
        <div slot="isParts" slot-scope="text">
          <template v-if="text === 0">
            <a-tag color="red">否</a-tag>
          </template>
          <template v-if="text === 1">
            <a-tag color="green">是</a-tag>
          </template>
        </div>
        <div slot="type" slot-scope="text">
          <template v-if="text === 0">
            <span>普通任务</span>
          </template>
          <template v-if="text === 1">
            <span>返修任务</span>
          </template>
          <template v-if="text === 2">
            <span>生产补投任务</span>
          </template>
          <template v-if="text === 3">
            <span>协作任务</span>
          </template>
          <template v-if="text === 4">
            <span>普通补投任务</span>
          </template>
          <template v-if="text === 5">
            <span>其他任务</span>
          </template>
        </div>
        <div slot="status" slot-scope="text">
          <template v-if="text === 0">
            <a-badge status="default" />准备中
          </template>
          <template v-if="text === 1">
            <a-badge status="default" />未开始
          </template>
          <template v-if="text === 2">
            <a-badge status="processing" />进行中
          </template>
          <template v-if="text === 3">
            <a-badge status="success" />已完成
          </template>
          <template v-if="text === 9">
            <a-badge status="warning" />暂停中
          </template>
          <template v-if="text === 8">
            <a-badge status="error" />已取消
          </template>
          <template v-if="text === 10">
            <a-badge status="error" />异常终止
          </template>
        </div>
        <div slot="process" slot-scope="text, column" class="table-process">
          <template v-if="column.remainProcessRoute.length > 0">
            <a-steps :current="column.remainProcessRoute.length-1" status="finish" size="small">
              <a-step v-for="process in column.remainProcessRoute" :key="process.id" :title="process.operationName">
                <span slot="description" class="process-desc">
                  <p v-if="process.operationType>0&&process.verifyProcessHour" class="description-font">
                    <b>加工工时：</b>
                    {{ process.verifyProcessHour }}
                  </p>
                  <p v-if="process.operationType>0&&process.verifyTransferOutTime" class="description-font">
                    <b>转出日期：</b>
                    {{ process.verifyTransferOutTime }}
                  </p>
                </span>
              </a-step>
            </a-steps>
          </template>
        </div>
      </a-table>
      <el-dropdown class="export-product" @command="handleExportProductCommand">
        <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出任务</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="FILTERED">导出当前筛选任务</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { Bar, Pie } from '@antv/g2plot'
import { downloadItem } from '@/utils/api.request'
import { getStatisticsDetail } from '@/api/statistics'
import { getTasksById, exportFilteredTasks } from '@/api/target'
import { detailColumns, groupColumns, memberColumns } from './dataLogic'
import { CONSTANTS } from '@/utils/constants'
import { EmergencyType } from '@/enums/index'

export default {
  name: 'TargetDetail',
  components: {},
  data() {
    return {
      EmergencyType,
      title: '',
      groupColumns,
      memberColumns,
      detailColumns,
      typeTab: 'DN',
      targetTab: 'task',
      detailInfo: {}, //  所有tab的数据
      taskInfo: {
        productTypeStatisticsList: [],
        taskTypeStatisticsList: []
      },
      productInfo: {
        productTypeStatisticsList: [],
        taskTypeStatisticsList: []
      },
      amountInfo: {
        productTypeStatisticsList: [],
        taskTypeStatisticsList: []
      },
      renderInfo: {}, // 展示的数据
      bar: '',

      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      currentTaskParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      columnSearchParams: {},
      tableLoading: false,
      taskList: [],
      targetId: '',
      targetType: '',
      targetName: '当前月',

      taskLoading: false,
      exportLoading: false,
      filterParams: {}
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.targetId = this.$route.query.id
    }
    if (this.$route.query.tab) {
      this.typeTab = this.$route.query.tab
    }
    if (this.$route.query.targetType) {
      this.targetType = this.$route.query.targetType
    }
    if (this.$route.query.targetName) {
      this.targetName = this.$route.query.targetName
    }

    this.getStatisticsDetail()
  },
  methods: {
    init() {
      this.detailInfo = {}
      this.taskInfo = {
        productTypeStatisticsList: [],
        taskTypeStatisticsList: []
      }
      this.productInfo = {
        productTypeStatisticsList: [],
        taskTypeStatisticsList: []
      }
      this.amountInfo = {
        productTypeStatisticsList: [],
        taskTypeStatisticsList: []
      }
    },
    getStatisticsDetail() {
      this.taskLoading = true
      this.currentTaskParams = {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      }
      this.columnSearchParams = {}
      const params = {
        targetId: this.targetId
      }
      if (!params.targetId) {
        delete params.targetId
      }
      if (this.targetType === 'day') {
        params.memberId = this.$route.query.memberId
      }

      if (this.targetType === 'week') {
        params.groupId = this.$route.query.groupId
      }
      getStatisticsDetail(this.typeTab, { ...params }).then(
        res => {
          this.init()
          this.targetTab = 'task'
          this.detailInfo = res.data
          this.detailInfo.productTypeStatisticsList.map(item => {
            this.taskInfo.productTypeStatisticsList.push({
              name: item.productTypeName,
              data: item.tasksStatisticsInfo
            })
            this.productInfo.productTypeStatisticsList.push({
              name: item.productTypeName,
              data: item.productStatisticsInfo
            })
            this.amountInfo.productTypeStatisticsList.push({
              name: item.productTypeName,
              data: item.amountStatisticsInfo
            })
          })
          this.detailInfo.taskTypeStatisticsList.map(item => {
            this.taskInfo.taskTypeStatisticsList.push({
              name: item.taskTypeName,
              data: item.tasksStatisticsInfo
            })
            this.productInfo.taskTypeStatisticsList.push({
              name: item.taskTypeName,
              data: item.productStatisticsInfo
            })
            this.amountInfo.taskTypeStatisticsList.push({
              name: item.taskTypeName,
              data: item.amountStatisticsInfo
            })
          })
          this.renderInfo = this.taskInfo
          this.taskLoading = false
          this.$nextTick(() => {
            this.BarEmpty()
            this.BarRender()

            this.pieEmpty()
            this.pieRender()
          })
        },
        error => {
          this.taskLoading = false
        }
      )
      this.getTasks()
    },
    getTasks() {
      if (this.tableLoading) return
      this.tableLoading = true
      let params = {}
      switch (this.typeTab) {
        case 'DN':
          params = {
            'product.type': 'eq:D',
            emergencyType: 'neq:IUG'
          }
          this.title = 'D'
          break
        case 'DE':
          params = {
            'product.type': 'eq:D',
            emergencyType: 'eq:IUG'
          }
          this.title = '插入急件D'
          break
        case 'LN':
          params = {
            'product.type': 'eq:L',
            emergencyType: 'neq:IUG'
          }
          this.title = 'L'
          break
        case 'LE':
          params = {
            'product.type': 'eq:L',
            emergencyType: 'eq:IUG'
          }
          this.title = '插入急件L'
          break
        case 'GN':
          params = {
            'product.type': 'eq:G',
            emergencyType: 'neq:IUG'
          }
          this.title = 'G'
          break
      }
      const _params = {
        targetId: this.targetId
      }
      if (!_params.targetId) {
        delete _params.targetId
      }
      this.filterParams = {
        ...this.currentTaskParams,
        ...this.columnSearchParams,
        ...params,
        ..._params
      }
      if (this.targetType === 'day' || this.targetType === 'week') {
        this.filterParams.emgType = this.filterParams.emergencyType
        delete this.filterParams.emergencyType

        if (this.targetType === 'day') {
          this.filterParams.memberId = this.$route.query.memberId
        }

        if (this.targetType === 'week') {
          this.filterParams.groupId = this.$route.query.groupId
        }
      }
      getTasksById(this.filterParams).then(
        res => {
          this.taskList = res.data.records
          this.pagination.total = res.data.total
          this.tableLoading = false
        },
        error => {
          this.tableLoading = false
        }
      )
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)

      if (filtersKeys.includes('isParts')) {
        delete this.columnSearchParams['isParts']
        if (filters['isParts'].length > 0) {
          this.columnSearchParams['isParts'] = `in:${filters[
            'isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['isParts']
        }
      }

      if (filtersKeys.includes('type')) {
        delete this.columnSearchParams['type']
        if (filters['type'].length > 0) {
          this.columnSearchParams['task.type'] = `in:${filters[
            'type'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.type']
        }
      }
      if (filtersKeys.includes('target_task.emergencyType')) {
        delete this.columnSearchParams['target_task.emergencyType']
        if (filters['target_task.emergencyType'].length > 0) {
          this.columnSearchParams['target_task.emergencyType'] = `in:${filters[
            'target_task.emergencyType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['target_task.emergencyType']
        }
      }

      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['task.status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.status']
        }
      }
      this.pagination = pagination
      this.currentTaskParams.pageNum = pagination.current
      this.currentTaskParams.pageSize = pagination.pageSize
      this.getTasks()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          if (dataIndex === 'code') {
            this.columnSearchParams['task.code'] =
              'like:' + selectedKeys[0].trim()
          } else {
            this.columnSearchParams[dataIndex] =
              'like:' + selectedKeys[0].trim()
          }
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          if (dataIndex === 'deliverTime') {
            this.columnSearchParams['plan.deliverTime'] =
              'btn:' + selectedKeys.toString()
          } else if (dataIndex === 'producingNum') {
            this.columnSearchParams['task.producingNum'] =
              'btn:' + selectedKeys.toString()
          } else {
            this.columnSearchParams[dataIndex] =
              'btn:' + selectedKeys.toString()
          }
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      if (dataIndex === 'code') {
        this.columnSearchParams['task.code'] = null
      } else if (dataIndex === 'deliverTime') {
        this.columnSearchParams['plan.deliverTime'] = null
      } else if (dataIndex === 'producingNum') {
        this.columnSearchParams['task.producingNum'] = null
      } else {
        this.columnSearchParams[dataIndex] = null
      }
    },
    handleChangeStatistics() {
      this.BarEmpty()
      this.pieEmpty()
      switch (this.targetTab) {
        case 'task':
          this.renderInfo = this.taskInfo
          this.BarRender()
          this.pieRender()
          break
        case 'product':
          this.renderInfo = this.productInfo
          this.BarRender()
          this.pieRender()
          break
        case 'amount':
          this.renderInfo = this.amountInfo
          this.BarRender()
          this.pieRender()
          break
      }
    },
    BarEmpty() {
      if (document.getElementById('bar')) {
        document.getElementById('bar').innerHTML = ''
      }
    },
    BarRender() {
      if (!document.getElementById('bar') || this.typeTab === 'GN') {
        return false
      }
      const data = []
      this.renderInfo.productTypeStatisticsList.map(item => {
        console.log(item.data.finishedRate)
        data.push(
          {
            name: item.name,
            value: Number(
              item.data.finishedCount || item.data.finishedCount === 0
                ? item.data.finishedCount
                : item.data.finishedAmount
            ),
            type: '完成'
          },
          {
            name: item.name,
            value:
              Number(
                item.data.totalCount || item.data.totalCount === 0
                  ? item.data.totalCount
                  : item.data.totalAmount
              ) -
              Number(
                item.data.finishedCount || item.data.finishedCount === 0
                  ? item.data.finishedCount
                  : item.data.finishedAmount
              ),
            type: '总计'
          }
        )
      })
      const bar = new Bar('bar', {
        data: data,
        autoFit: true,
        isStack: true,
        xField: 'value',
        yField: 'name',
        xAxis: {
          line: {
            style: {
              stroke: 'rgb(217,217,217)'
            }
          },
          grid: {
            line: null
          }
        },
        yAxis: {
          line: null,
          tickLine: null,
          grid: {
            line: {
              style: {
                stroke: 'rgb(232,232,232)',
                lineWidth: 2,
                lineDash: [4, 5],
                strokeOpacity: 0.7,
                cursor: 'pointer'
              }
            }
          }
        },
        color: ({ type }) => {
          if (type === '完成') {
            return 'rgb(42,152,254)'
          }
          if (type === '总计') {
            return 'rgb(145,199,248)'
          }
        },
        seriesField: 'type',
        label: false,
        legend: false,
        tooltip: {
          // containerTpl:
          //   '<div class="g2-tooltip" style="background: black;">' +
          //   '<div class="g2-tooltip-title" style="margin:10px 0;"></div>' +
          //   '<ul class="g2-tooltip-list"></ul></div>',
          // itemTpl:
          //   '<li data-index={index}>{name}: {value}</span></li>',
          customContent: (name, items) => {
            const container = document.createElement('div')
            container.className = 'g2-tooltip'
            const title = `<div class="g2-tooltip-title" style="margin-top: 12px;margin-bottom: 12px;">${name}</div>`
            const res = []
            let listItem = ''
            items.forEach(item => {
              const val = res[0]
                ? Number(item.value) + Number(res[0])
                : item.value
              listItem += `<li class="g2-tooltip-list-item" data-index={index} style="margin-bottom:4px;display:flex;align-items: center;">
            <span style="background-color:${item.mappingData.color ||
              item.color};" class="g2-tooltip-marker"></span>
            <span style="display:inline-flex;flex:1;justify-content:space-between">
            <span style="margin-right: 16px;">${
              item.name
            }:</span><span>${val}</span>
            </span>
        </li>`
              res.push(val)
            })
            listItem += `<li class="g2-tooltip-list-item" data-index={index} style="margin-bottom:4px;display:flex;align-items: center;">
            <span style="background-color:red;" class="g2-tooltip-marker"></span>
            <span style="display:inline-flex;flex:1;justify-content:space-between">
            <span style="margin-right: 16px;">完成率:</span><span>${
              res[1] === 0 ? 0 : ((res[0] / res[1]) * 100).toFixed(2) + '%'
            }</span>
            </span>
        </li>`
            container.innerHTML = title + listItem
            return container
          }
        }
      })
      console.log(bar)
      bar.render()
    },
    pieEmpty() {
      this.renderInfo.taskTypeStatisticsList.map((item, index) => {
        document.getElementById('task' + index).innerHTML = ''
      })
    },
    pieRender() {
      this.renderInfo.taskTypeStatisticsList.map((item, index) => {
        this.pie('task' + index, item.data.finishedRate, item.name)
      })
    },
    pie(dom, finishedRate, name) {
      const data = [
        { type: name, value: finishedRate * 100 },
        { type: '其他', value: 100 - finishedRate * 100 }
      ]
      const piePlot = new Pie(dom, {
        data,
        angleField: 'value',
        colorField: 'type',
        color: ({ type }) => {
          if (type === '紧急件') {
            return 'rgb(250,82,0)'
          }
          if (type === '必完件') {
            return 'rgb(250,164,0)'
          }
          if (type === '普通件') {
            return 'rgb(0,187,199)'
          }
          return 'rgb(233,233,233)'
        },
        radius: 1,
        innerRadius: 0.6,
        tooltip: false,
        statistic: {
          title: {
            style: {
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: 14
            },
            content: name
          },
          content: {
            style: {
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: 14
            },
            content: (finishedRate * 100).toFixed(0) + '%'
          }
        },
        label: false,
        legend: false
      })

      this.$nextTick(() => {
        piePlot.render()
      })
    },
    handleExportProductCommand(command) {
      this.exportLoading = true
      let params = {
        ...this.filterParams
      }
      if (this.targetType === 'day') {
        params.memberId = this.$route.query.memberId
      }

      if (this.targetType === 'week') {
        params.groupId = this.$route.query.groupId
      }
      switch (command) {
        case 'FILTERED':
          exportFilteredTasks(params)
            .then(res => {
              this.downloadTask(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        default:
          this.exportLoading = false
          break
      }
    },
    downloadTask(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.TARGET_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .ant-tabs-bar {
  margin: 0;
}
/deep/ .g2-tooltip {
  background: rgb(54, 54, 54) !important;
  color: white !important;
  padding: 12px !important;
  min-width: 100px;
}
/deep/ .g2-tooltip-title {
  display: none !important;
}
/deep/ .g2-tooltip-list {
  display: flex !important;
  justify-content: space-between !important;
}
/deep/ .ant-table-row {
  background: white;
}
/deep/ .ant-steps-item {
  width: 300px;
}
/deep/ .ant-table-pagination,
.ant-pagination {
  float: left;
}

.container {
  min-width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  background: rgb(240, 242, 245);
  .tab {
    background: white;
    padding-left: 24px;
  }
  .top {
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    text-align: center;
    position: relative;
    margin-top: 16px;
    .title {
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      left: 24px;
    }
    .type {
      margin: 0 auto;
    }
  }
  .statistics {
    width: 99%;
    box-sizing: border-box;
    padding-left: 24px;
    // padding-right: 88px;
    margin-top: 16px;
    display: flex;
    .left {
      flex: 1.6;
      height: 500px;
      box-sizing: border-box;
      padding: 0 36px;
      background: white;
      margin-right: 15px;
      position: relative;
      .title {
        margin-top: 24px;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .right {
      flex: 1;
      height: 500px;
      box-sizing: border-box;
      padding: 0 36px;
      background: white;
      position: relative;
      overflow-y: scroll;
      .title {
        margin-top: 24px;
        font-size: 18px;
        font-weight: bold;
      }
      .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        .item {
          flex: 1;
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          .pie {
            width: 110px;
            height: 110px;
          }
          .desc {
            margin-left: 12px;
          }
        }
      }
    }
  }
  .table {
    width: 99%;
    height: 300px;
    box-sizing: border-box;
    padding-left: 24px;
    margin-top: 30px;
    position: relative;
    .export-product {
      float: right;
      bottom: 40px;
    }
  }
  .table-process {
    float: left;
    width: auto;
  }

  .process-desc p {
    margin: 0;
    width: 250px;
  }

  .description-font {
    color: rgb(31, 29, 29);
  }
}
.page-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /deep/ .anticon {
    font-size: 48px;
    color: white;
  }
}
</style>
