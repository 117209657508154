export const taskColumns = [
  {
    title: '任务编码',
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
    align: 'center',
    width: 150
  },
  {
    title: '任务类型',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    scopedSlots: {
      customRender: 'type'
    }
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    key: 'productName',
    ellipsis: true,
    align: 'center',
    width: 150
  },
  {
    title: '图号',
    dataIndex: 'drawingNum',
    key: 'drawingNum',
    ellipsis: true,
    align: 'center',
    width: 150
  },
  {
    title: '投产数量',
    dataIndex: 'productionNum',
    key: 'productionNum',
    align: 'center'
  },
  {
    title: '交货日期',
    dataIndex: 'deliverTime',
    key: 'deliverTime',
    ellipsis: true,
    align: 'center'
  },
  {
    title: '入库数量',
    dataIndex: 'putInNum',
    key: 'putInNum',
    align: 'center'
  },
  {
    title: '在产数量',
    dataIndex: 'producingNum',
    key: 'producingNum',
    align: 'center'
  },
  {
    title: '完成日期',
    dataIndex: 'finishTime',
    key: 'finishTime',
    align: 'center'
  },
  {
    title: '出库数量',
    dataIndex: 'takeOutNum',
    key: 'takeOutNum',
    align: 'center'
  },
  {
    title: '出库日期',
    dataIndex: 'takeOutTime',
    key: 'takeOutTime',
    align: 'center'
  },
  {
    title: '报废数量',
    dataIndex: 'abandonNum',
    key: 'abandonNum',
    align: 'center'
  },
  {
    title: '退货数量',
    dataIndex: 'returnedNum',
    key: 'returnedNum',
    align: 'center'
  },
  {
    title: '返工数量',
    dataIndex: 'repairedNum',
    key: 'repairedNum',
    align: 'center'
  },
  {
    title: '退货时间',
    dataIndex: 'returnedTime',
    key: 'returnedTime',
    align: 'center'
  },
  {
    title: '超差数量',
    dataIndex: 'outOfToleranceNum',
    key: 'outOfToleranceNum',
    align: 'center'
  },
  {
    title: '任务备注',
    dataIndex: 'comment',
    key: 'comment',
    ellipsis: true,
    align: 'center'
  },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    align: 'center',
    width: 180,
    scopedSlots: {
      customRender: 'operation'
    }
  }
]
