var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("a-table", {
        staticClass: "users-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          columns: _vm.offerColumns,
          "data-source": _vm.list,
          loading: _vm.loading,
          pagination: _vm.pagination,
          scroll: { x: 1500 },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "taxRate",
            fn: function (text) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text * 100 + "%" : ""))]),
              ])
            },
          },
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.dataIndex
                              )
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                  column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "190px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.dataIndex
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.dataIndex
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "code",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCheckDetail(text)
                        },
                      },
                    },
                    [_vm._v(_vm._s(text))]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === "WSO"
                    ? _c("a-tag", { attrs: { color: "blue" } }, [
                        _vm._v("待报出"),
                      ])
                    : _vm._e(),
                  text === "WDL"
                    ? _c("a-tag", { attrs: { color: "orange" } }, [
                        _vm._v("待成交"),
                      ])
                    : _vm._e(),
                  text === "CNL"
                    ? _c("a-tag", { attrs: { color: "red" } }, [
                        _vm._v("已取消"),
                      ])
                    : _vm._e(),
                  text === "DEL"
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("已成交"),
                      ])
                    : _vm._e(),
                  text === "PDL"
                    ? _c("a-tag", { attrs: { color: "lime" } }, [
                        _vm._v("部分成交"),
                      ])
                    : _vm._e(),
                  text === "NDL"
                    ? _c("a-tag", { attrs: { color: "red" } }, [
                        _vm._v("未成交"),
                      ])
                    : _vm._e(),
                  text === "RVC"
                    ? _c("a-tag", { attrs: { color: "red" } }, [
                        _vm._v("已撤销"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "payType",
            fn: function (text) {
              return _c("div", {}, [
                text === "CSH" ? _c("span", [_vm._v("现金")]) : _vm._e(),
                text === "BNK" ? _c("span", [_vm._v("银行转账")]) : _vm._e(),
                text === "CRD" ? _c("span", [_vm._v("挂账")]) : _vm._e(),
              ])
            },
          },
          {
            key: "deliveryType",
            fn: function (text) {
              return _c("div", {}, [
                text === "SND" ? _c("span", [_vm._v("送货")]) : _vm._e(),
                text === "SLF" ? _c("span", [_vm._v("自取")]) : _vm._e(),
                text === "EXP" ? _c("span", [_vm._v("快递")]) : _vm._e(),
              ])
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "operate",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-user" },
                [
                  _c(
                    "a-dropdown",
                    [
                      _c(
                        "a-menu",
                        {
                          staticClass: "ant-dropdown-link",
                          attrs: { slot: "overlay" },
                          slot: "overlay",
                        },
                        [
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleCheckDetail(column.code)
                                },
                              },
                            },
                            [_vm._v("查看报价单明细")]
                          ),
                          column.status === "WSO"
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOffer(column.code)
                                    },
                                  },
                                },
                                [_vm._v("报出报价单")]
                              )
                            : _vm._e(),
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleCancel(column.code)
                                },
                              },
                            },
                            [_vm._v("撤销报价单")]
                          ),
                          column.status === "WDL" || column.status === "DEL"
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePrintDetail(column.code)
                                    },
                                  },
                                },
                                [_vm._v("打印报价单明细")]
                              )
                            : _vm._e(),
                          column.status === "WDL"
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOrder(column.code)
                                    },
                                  },
                                },
                                [_vm._v("成交报价")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        { staticClass: "user-btn" },
                        [
                          _vm._v(" 操作 "),
                          _c("a-icon", { attrs: { type: "down" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
      _vm.offerModalVisible
        ? _c("OfferDetailModal", {
            attrs: { code: _vm.currentOfferCode },
            on: {
              confirm: _vm.handleOfferModalConfirm,
              cancel: _vm.handleOfferModalConfirm,
            },
          })
        : _vm._e(),
      _vm.quotationsModalVisible
        ? _c("QuotationsModal", {
            attrs: { code: _vm.currentOfferCode },
            on: {
              confirm: _vm.handleQuotationsModalConfirm,
              cancel: _vm.handleQuotationsModalCancel,
            },
          })
        : _vm._e(),
      _vm.revocationModalVisible
        ? _c("RevocationModal", {
            attrs: { code: _vm.currentOfferCode },
            on: {
              confirm: _vm.handleRevocationModalConfirm,
              cancel: _vm.handleRevocationModalCancel,
            },
          })
        : _vm._e(),
      _vm.orderModalVisible
        ? _c("OrderModal", {
            attrs: { code: _vm.currentOfferCode },
            on: {
              confirm: _vm.handleOrderModalConfirm,
              cancel: _vm.handleOrderModalCancel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }