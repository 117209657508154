var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "header-button",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline",
                  },
                  on: { click: _vm.handleAddRole },
                },
                [_vm._v("新增角色")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        staticClass: "roles-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          columns: _vm.roleColumns,
          "data-source": _vm.roleList,
          loading: _vm.loading,
          pagination: _vm.pagination,
          scroll: { y: 590 },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.dataIndex
                              )
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.dataIndex
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.dataIndex
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === 1
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("正常"),
                      ])
                    : _vm._e(),
                  text === 2
                    ? _c("a-tag", { attrs: { color: "red" } }, [
                        _vm._v("已删除"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "operate",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-role" },
                [
                  column.status !== 2
                    ? _c(
                        "a-dropdown",
                        [
                          _c(
                            "a-menu",
                            {
                              staticClass: "ant-dropdown-link",
                              attrs: { slot: "overlay" },
                              slot: "overlay",
                            },
                            [
                              _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdateRole(column)
                                    },
                                  },
                                },
                                [_vm._v("更新角色")]
                              ),
                              _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDeleteRole(column)
                                    },
                                  },
                                },
                                [_vm._v("删除角色")]
                              ),
                              _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRoleResourceSelect(
                                        column
                                      )
                                    },
                                  },
                                },
                                [_vm._v("权限设置")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a",
                            { staticClass: "role-btn" },
                            [
                              _vm._v(" 操作 "),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
      _vm.editRoleModalVisible
        ? _c("RoleEdit", {
            attrs: {
              "role-form": _vm.currentRole,
              "modal-title": _vm.currentEditType,
            },
            on: {
              cancel: _vm.handleRoleModalCanceled,
              confirm: _vm.handleRoleModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.editRoleResourceSelectVisible
        ? _c("RoleResourceSelect", {
            attrs: { "user-id": _vm.currentRole.id },
            on: {
              cancel: _vm.handleResourceSelectModalCanceled,
              confirm: _vm.handleResourceSelectModalConfirm,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }