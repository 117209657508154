export const offerDetailColumns = [
  {
    title: '序号',
    width: 70,
    dataIndex: 'index',
    key: 'index',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '核价单号',
    width: 170,
    fixed: 'left',
    sorter: true,
    searchType: 'text',
    dataIndex: 'costAccountReceiptCode',
    key: 'costAccountReceiptCode',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: '产品名称',
    width: 200,
    ellipsis: true,
    searchType: 'text',
    dataIndex: 'productInfo.name',
    key: 'productInfo.name',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: '产品图号',
    width: 200,
    ellipsis: true,
    searchType: 'text',
    dataIndex: 'productInfo.drawingNum',
    key: 'productInfo.drawingNum',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: '产品规格',
    width: 200,
    ellipsis: true,
    dataIndex: 'productInfo.specification',
    key: 'productInfo.specification',
    align: 'center',
  },
  {
    title: '报价状态',
    width: 120,
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '待报出',
        value: 'WSO',
      },
      {
        text: '待成交',
        value: 'WDL',
      },
      {
        text: '已取消',
        value: 'CNL',
      },
      {
        text: '已成交',
        value: 'DEL',
      },
      {
        text: '部分成交',
        value: 'PDL',
      },
      {
        text: '未成交',
        value: 'NDL',
      },
      {
        text: '已撤销',
        value: 'RVC',
      },
    ],
  },
  {
    title: '单位',
    width: 70,
    dataIndex: 'productInfo.unit',
    key: 'productInfo.unit',
    align: 'center',
  },
  {
    title: '产品类型',
    width: 100,
    dataIndex: 'productInfo.type',
    key: 'productInfo.type',
    align: 'center',
    scopedSlots: {
      customRender: 'type',
    },
  },
  {
    title: '询价数量',
    width: 130,
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'quantity',
    },
  },
  {
    title: '税率',
    width: 70,
    dataIndex: 'taxRate',
    key: 'taxRate',
    align: 'center',
    scopedSlots: {
      customRender: 'taxRate',
    },
  },

  {
    title: '含税单价',
    width: 100,
    dataIndex: 'unitPriceWithTax',
    key: 'unitPriceWithTax',
    align: 'center',
  },
  {
    title: '含税总价',
    width: 100,
    dataIndex: 'totalAmountWithTax',
    key: 'totalAmountWithTax',
    align: 'center',
  },
  {
    title: '生产周期',
    width: 100,
    dataIndex: 'leadTime',
    key: 'leadTime',
    align: 'center',
  },
  {
    title: '订单单号',
    width: 150,
    dataIndex: 'orderCode',
    key: 'orderCode',
    align: 'center',
  },
  {
    title: '备注',
    ellipsis: true,
    width: 200,
    dataIndex: 'remark',
    key: 'remark',
    align: 'center',
  },
]
