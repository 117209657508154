var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-container" }, [
    _c(
      "div",
      { attrs: { id: "craft-card-print" } },
      [
        _vm.receipt <= 1
          ? [
              _c(
                "div",
                { staticStyle: { "page-break-after": "always" } },
                [
                  _c("div", { staticClass: "title" }, [
                    _c("div", { staticClass: "middle" }, [
                      _vm._v("重庆市DBR刀具有限公司产品" + _vm._s(_vm.type)),
                    ]),
                  ]),
                  _c(
                    "a-descriptions",
                    { attrs: { bordered: "" } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "客户名称" } },
                        [
                          _vm._v(
                            _vm._s(_vm.ifClientVisible ? _vm.data.client : "")
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "出库单单号", span: "2" } },
                        [_vm._v(_vm._s(_vm.data.takeOutNumber))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "客户合同编号", span: "1" } },
                        [_vm._v(_vm._s(_vm.data.clientContractCode))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "客户订单编号", span: "2" } },
                        [_vm._v(_vm._s(_vm.data.clientOrderCode))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "IMES订单编号", span: "3" } },
                        [_vm._v(_vm._s(_vm.data.orderCode))]
                      ),
                      _c("a-descriptions-item", { attrs: { label: "地址" } }, [
                        _vm._v("重XXXXXXXXX"),
                      ]),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "电话（传真）" } },
                        [_vm._v("023-XXXXXXXXX XXXXX")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-table",
                    {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": _vm.productTakeOutItemList,
                        bordered: "",
                        pagination: false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "amount",
                            fn: function (text) {
                              return _c("p", { staticClass: "amount-font" }, [
                                _vm._v(_vm._s(text)),
                              ])
                            },
                          },
                          {
                            key: "taxFreePrice",
                            fn: function (text, column) {
                              return _c(
                                "div",
                                {},
                                [
                                  _vm.ifAccountVisible
                                    ? [
                                        _vm.withTax
                                          ? [_vm._v(_vm._s(column.taxPrice))]
                                          : [
                                              _vm._v(
                                                _vm._s(column.taxFreePrice)
                                              ),
                                            ],
                                      ]
                                    : text
                                    ? [_vm._v("***")]
                                    : _vm._e(),
                                ],
                                2
                              )
                            },
                          },
                          {
                            key: "taxFreeUnitPrice",
                            fn: function (text, column) {
                              return _c(
                                "div",
                                {},
                                [
                                  _vm.ifAccountVisible
                                    ? [
                                        _vm.withTax
                                          ? [
                                              _vm._v(
                                                _vm._s(column.taxUnitPrice)
                                              ),
                                            ]
                                          : [_vm._v(_vm._s(text))],
                                      ]
                                    : text
                                    ? [_vm._v("***")]
                                    : _vm._e(),
                                ],
                                2
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        1638153876
                      ),
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: { slot: "customPriceTitle" },
                          slot: "customPriceTitle",
                        },
                        [
                          _vm.withTax
                            ? [_vm._v("金额(含税)")]
                            : [_vm._v("金额(无税)")],
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          attrs: { slot: "customUnitPriceTitle" },
                          slot: "customUnitPriceTitle",
                        },
                        [
                          _vm.withTax
                            ? [_vm._v("单价(含税)")]
                            : [_vm._v("单价(无税)")],
                        ],
                        2
                      ),
                    ]
                  ),
                  _c(
                    "a-descriptions",
                    { attrs: { bordered: "" } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "结算方式" } },
                        [
                          _c(
                            "span",
                            { staticClass: "settle-type" },
                            [
                              _c("a-icon", {
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-right": "3px",
                                },
                                attrs: { type: "border" },
                              }),
                              _vm._v("挂账 "),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "settle-type" },
                            [
                              _c("a-icon", {
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-right": "3px",
                                },
                                attrs: { type: "border" },
                              }),
                              _vm._v("现款 "),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "settle-type" },
                            [
                              _c("a-icon", {
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-right": "3px",
                                },
                                attrs: { type: "border" },
                              }),
                              _vm._v("免费 "),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "数量合计" } },
                        [_c("span", [_vm._v(_vm._s(_vm.data.totalProductNum))])]
                      ),
                      _vm.ifAccountVisible && !_vm.withTax
                        ? _c(
                            "a-descriptions-item",
                            { attrs: { label: "金额(无税)合计" } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.data.totalTaxFreePrice)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.ifAccountVisible && _vm.withTax
                        ? _c(
                            "a-descriptions-item",
                            { attrs: { label: "金额(含税)合计" } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.data.totalTaxPrice)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.ifAccountVisible
                        ? _c(
                            "a-descriptions-item",
                            { attrs: { label: "金额合计" } },
                            [_c("span", [_vm._v("***")])]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "footer-form" }, [
                    _c("span", [
                      _vm._v("制单人：" + _vm._s(_vm.data.takeOutReceiptUser)),
                    ]),
                    _c("div", { staticClass: "bottom-line" }),
                    _c("span", [_vm._v("送货人：")]),
                    _c("div", { staticClass: "bottom-line" }),
                    _c("span", [_vm._v("库房：")]),
                    _c("div", { staticClass: "bottom-line" }),
                    _c("span", [_vm._v("收货人：")]),
                    _c("div", { staticClass: "bottom-line" }),
                    _c("span", { staticStyle: { float: "right" } }, [
                      _vm._v(_vm._s(_vm.data.takeOutTime)),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          : _vm._l(_vm.data, function (item) {
              return _c(
                "div",
                {
                  key: item.takeOutNumber,
                  staticClass: "page",
                  staticStyle: { "page-break-after": "always" },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _c("div", { staticClass: "middle" }, [
                      _vm._v("重庆市DBR刀具有限公司产品" + _vm._s(item.type)),
                    ]),
                  ]),
                  _c(
                    "a-descriptions",
                    { attrs: { bordered: "" } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "客户名称" } },
                        [_vm._v(_vm._s(item.client))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "出库单单号", span: "2" } },
                        [_vm._v(_vm._s(item.takeOutNumber))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "客户合同编号", span: "1" } },
                        [_vm._v(_vm._s(item.clientContractCode))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "客户订单编号", span: "2" } },
                        [_vm._v(_vm._s(item.clientOrderCode))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "IMES订单编号", span: "3" } },
                        [_vm._v(_vm._s(item.orderCode))]
                      ),
                      _c("a-descriptions-item", { attrs: { label: "地址" } }, [
                        _vm._v("重XXXXXXXXX"),
                      ]),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "电话（传真）" } },
                        [_vm._v("023-XXXXXXXXX XXXXX")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-table",
                    {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": item.productTakeOutItemList,
                        bordered: "",
                        pagination: false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "amount",
                            fn: function (text) {
                              return _c("p", { staticClass: "amount-font" }, [
                                _vm._v(_vm._s(text)),
                              ])
                            },
                          },
                          {
                            key: "taxFreePrice",
                            fn: function (text, column) {
                              return _c(
                                "div",
                                {},
                                [
                                  _vm.ifAccountVisible
                                    ? [
                                        _vm.withTax
                                          ? [_vm._v(_vm._s(column.taxPrice))]
                                          : [
                                              _vm._v(
                                                _vm._s(column.taxFreePrice)
                                              ),
                                            ],
                                      ]
                                    : text
                                    ? [_vm._v("***")]
                                    : _vm._e(),
                                ],
                                2
                              )
                            },
                          },
                          {
                            key: "taxFreeUnitPrice",
                            fn: function (text, column) {
                              return _c(
                                "div",
                                {},
                                [
                                  _vm.ifAccountVisible
                                    ? [
                                        _vm.withTax
                                          ? [
                                              _vm._v(
                                                _vm._s(column.taxUnitPrice)
                                              ),
                                            ]
                                          : [_vm._v(_vm._s(text))],
                                      ]
                                    : text
                                    ? [_vm._v("***")]
                                    : _vm._e(),
                                ],
                                2
                              )
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: { slot: "customPriceTitle" },
                          slot: "customPriceTitle",
                        },
                        [
                          _vm.withTax
                            ? [_vm._v("金额(含税)")]
                            : [_vm._v("金额(无税)")],
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          attrs: { slot: "customUnitPriceTitle" },
                          slot: "customUnitPriceTitle",
                        },
                        [
                          _vm.withTax
                            ? [_vm._v("单价(含税)")]
                            : [_vm._v("单价(无税)")],
                        ],
                        2
                      ),
                    ]
                  ),
                  _c(
                    "a-descriptions",
                    { attrs: { bordered: "" } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "结算方式" } },
                        [
                          _c(
                            "span",
                            { staticClass: "settle-type" },
                            [
                              _c("a-icon", {
                                staticStyle: {
                                  display: "inline-block",
                                  margin: "3px",
                                },
                                attrs: { type: "border" },
                              }),
                              _vm._v("挂账 "),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "settle-type" },
                            [
                              _c("a-icon", {
                                staticStyle: {
                                  display: "inline-block",
                                  margin: "3px",
                                },
                                attrs: { type: "border" },
                              }),
                              _vm._v("现款 "),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "settle-type" },
                            [
                              _c("a-icon", {
                                staticStyle: {
                                  display: "inline-block",
                                  margin: "3px",
                                },
                                attrs: { type: "border" },
                              }),
                              _vm._v("免费 "),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "数量合计" } },
                        [_c("span", [_vm._v(_vm._s(item.totalProductNum))])]
                      ),
                      _vm.ifAccountVisible && !_vm.withTax
                        ? _c(
                            "a-descriptions-item",
                            { attrs: { label: "金额(无税)合计" } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.totalTaxFreePrice)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.ifAccountVisible && _vm.withTax
                        ? _c(
                            "a-descriptions-item",
                            { attrs: { label: "金额(含税)合计" } },
                            [_c("span", [_vm._v(_vm._s(item.totalTaxPrice))])]
                          )
                        : _vm._e(),
                      !_vm.ifAccountVisible
                        ? _c(
                            "a-descriptions-item",
                            { attrs: { label: "金额合计" } },
                            [_c("span", [_vm._v("***")])]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "footer-form" }, [
                    _c("span", [
                      _vm._v("制单人：" + _vm._s(item.takeOutReceiptUser)),
                    ]),
                    _c("div", { staticClass: "bottom-line" }),
                    _c("span", [_vm._v("送货人：")]),
                    _c("div", { staticClass: "bottom-line" }),
                    _c("span", [_vm._v("库房：")]),
                    _c("div", { staticClass: "bottom-line" }),
                    _c("span", [_vm._v("收货人：")]),
                    _c("div", { staticClass: "bottom-line" }),
                    _c("span", [_vm._v(_vm._s(item.takeOutTime))]),
                  ]),
                ],
                1
              )
            }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "div",
          { staticClass: "radio" },
          [
            _vm._v(" 是否显示金额 "),
            _c("a-switch", {
              attrs: { "default-checked": "" },
              on: { change: _vm.onSwitchChange },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "radio" },
          [
            _vm._v(" 是否显示客户名称 "),
            _c("a-switch", {
              attrs: { "default-checked": "" },
              on: { change: _vm.onClientSwitchChange },
            }),
          ],
          1
        ),
        _vm.ifAccountVisible
          ? _c(
              "div",
              { staticClass: "type" },
              [
                _c(
                  "a-radio-group",
                  {
                    attrs: { name: "radioGroup" },
                    model: {
                      value: _vm.withTax,
                      callback: function ($$v) {
                        _vm.withTax = $$v
                      },
                      expression: "withTax",
                    },
                  },
                  [
                    _c("a-radio", { attrs: { value: true } }, [_vm._v("含税")]),
                    _c("a-radio", { attrs: { value: false } }, [
                      _vm._v("无税"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "a-button",
          {
            directives: [
              {
                name: "print",
                rawName: "v-print",
                value: "craft-card-print",
                expression: "'craft-card-print'",
              },
            ],
            staticClass: "btn",
            attrs: { type: "primary", block: "" },
          },
          [_vm._v("打印")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }