import request from '@/utils/api.request'

/**
 * 分页获取产品审价信息
 * @param {*} data
 * @returns
 */
export function getApproval(data) {
  return request({
    url: `/product-checks`,
    method: 'get',
    params: data,
  })
}

/**
 * 根据审价单号获取审价单明细
 * @param {*} data
 * @returns
 */
export function geApprovalDetail(costCheckReceiptCode, data) {
  return request({
    url: `/product-checks/${costCheckReceiptCode}/details`,
    method: 'get',
    params: data,
  })
}

/**
 * 调整产品项最终报出价格
 */
export function putPrice(id, data) {
  return request({
    url: `/product-checks/${id}/final-price`,
    method: 'post',
    data,
  })
}

/**
 * 提交审价单审批结果
 */
export function checkResultApproval(data) {
  return request({
    url: `/product-checks/check-results`,
    method: 'post',
    data,
  })
}
