import request from '@/utils/api.request'

/**
 * 分页获取产品成本核算信息
 * @param {*} data
 * @returns
 */
export function getCostAccounting(data) {
  return request({
    url: `/product-costs`,
    method: 'get',
    params: data,
  })
}

/**
 * 根据核算单号获取产品成本明细
 * @param {*} data
 * @returns
 */
export function getCostAccountingDetail(receiptCode) {
  return request({
    url: `/product-costs/details/${receiptCode}`,
    method: 'get',
  })
}

/**
 * 产品成本核算明细信息暂存
 * @param {*} data
 * @returns
 */
export function stagingCostAccountingDetail(receiptCode, data) {
  return request({
    url: `/product-costs/temp-details/${receiptCode}`,
    method: 'post',
    data,
  })
}

/**
 * 获取产品成本核算明细暂存信息
 * @param {*} data
 * @returns
 */
export function getStagingCostAccountingDetail(receiptCode) {
  return request({
    url: `/product-costs/temp-details/${receiptCode}`,
    method: 'get',
  })
}

/**
 * 产品成本明细核算
 * @param {*} data
 * @returns
 */
export function saveCostAccountingDetail(receiptCode, data) {
  return request({
    url: `/product-costs/details/${receiptCode}`,
    method: 'post',
    data,
  })
}

/**
 * 更新产品成本明细核算
 * @param {*} data
 * @returns
 */
export function updateCostAccountingDetail(receiptCode, data) {
  return request({
    url: `/product-costs/details/${receiptCode}`,
    method: 'put',
    data,
  })
}

/**
 * 成本核算记录导出
 * @param {*} data
 */
export function exportCostAccountingList(data) {
  return request({
    url: `/product-costs/exports`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}
