import request from '@/utils/api.request'

/**
 * 分页获取产品库
 * @param {*} data
 * @returns
 */
export function getProductLibrary(data) {
  return request({
    url: `/quote-products`,
    method: 'get',
    params: data,
  })
}

/**
 * 新增产品信息
 * @param {*} data
 * @returns
 */
export function addProduct(data) {
  return request({
    url: `/quote-products`,
    method: 'post',
    data,
  })
}

/**
 * 更新产品信息
 * @param {*} data
 * @returns
 */
export function updateProduct(productCode, data) {
  return request({
    url: `/quote-products/${productCode}`,
    method: 'put',
    data,
  })
}

/**
 * 新增产品库Bom信息
 * @param {*} data
 * @returns
 */
export function addProductBom(data) {
  return request({
    url: `/products/boms`,
    method: 'post',
    params: data,
  })
}

/**
 * 新增产品库Bom信息 树形结构
 * @param {*} data
 * @returns
 */
export function getProductBomByTree(productCode) {
  return request({
    url: `/products/boms/${productCode}/trees`,
    method: 'get',
  })
}

/**
 * 新增产品库Bom信息 列表结构
 * @param {*} data
 * @returns
 */
export function getProductBomByList(productCode) {
  return request({
    url: `/products/boms/${productCode}/lists`,
    method: 'get',
  })
}
