<template>
  <div class="app-container">
    <div class="selected-wrapper">
      <template v-if="hasSelected">
        {{
        '已选中' + selectedRowKeys.length + '项任务'
        }}
      </template>
      <a-dropdown v-if="hasSelected">
        <a class="batch-operate-link" @click="(e) => e.preventDefault()">
          <a-icon type="edit" />批量操作
        </a>

        <a-menu slot="overlay">
          <a-menu-item>
            <a @click="handleCreateCraftCard(selectedRowKeys)">批量生成工艺流转卡</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="handleCreatePlanCard(selectedRowKeys)">任务车间交接表打印</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="handleBatchEmergencyTypeEdit(selectedRowKeys)">设置任务紧急类型</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <a-table
      row-key="id"
      bordered
      class="plans-table"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :columns="taskColumns"
      :data-source="taskList"
      :loading="loading"
      :pagination="pagination"
      :scroll="{ x: 2700, y: 700 }"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-else-if="column.searchType === 'numberInput'">
          <a-input-number
            v-ant-ref="(c) => (searchInput = c)"
            :value="selectedKeys[0]"
            :placeholder="`搜索 ${column.title}`"
            :min="0"
            :max="999999"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="(e) => setSelectedKeys(e ? [e] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'number'">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([e, selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([selectedKeys[0], e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            :show-time="{ format: 'HH:mm:ss' }"
            size="small"
            style="width: 330px; margin-bottom: 8px"
            :ranges="{
              今天: [$moment(), $moment()],
              本月: [$moment().startOf('month'), $moment().endOf('month')],
            }"
            @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="type" slot-scope="text">
        <template v-if="text === 0">
          <span>普通任务</span>
        </template>
        <template v-if="text === 1">
          <span>返修任务</span>
        </template>
        <template v-if="text === 2">
          <span>生产补投任务</span>
        </template>
        <template v-if="text === 3">
          <span>协作任务</span>
        </template>
        <template v-if="text === 4">
          <span>普通补投任务</span>
        </template>
        <template v-if="text === 5">
          <span>库存转入任务</span>
        </template>
      </div>
      <span slot="emgType" slot-scope="text">
        <a-tag v-if="text === 'NML'" color="volcano">普通</a-tag>
        <a-tag v-if="text === 'MFN'" color="cyan">必完件</a-tag>
        <a-tag v-if="text === 'UGC'" color="green">紧急件</a-tag>
        <a-tag v-if="text === 'IUG'" color="purple">插入急件</a-tag>
        <a-tag v-if="text === 'TUG'" color="blue">转入急件</a-tag>
      </span>
      <div slot="status" slot-scope="text">
        <template v-if="text === 0">
          <a-badge status="default" />准备中
        </template>
        <template v-if="text === 1">
          <a-badge status="default" />未开始
        </template>
        <template v-if="text === 2">
          <a-badge status="processing" />进行中
        </template>
        <template v-if="text === 3">
          <a-badge status="success" />已完成
        </template>
        <template v-if="text === 4">
          <a-badge status="error" />超期进行中
        </template>
        <template v-if="text === 5">
          <a-badge status="warning" />即将超期
        </template>
        <template v-if="text === 9">
          <a-badge status="warning" />暂停中
        </template>
        <template v-if="text === 8">
          <a-badge status="error" />已取消
        </template>
        <template v-if="text === 10">
          <a-badge status="error" />异常终止
        </template>
      </div>
      <div slot="deliveryStatus" slot-scope="text">
        <a-tag v-if="text === 'GREEN'" color="green">绿单</a-tag>
        <a-tag v-if="text === 'RED'" color="red">红单</a-tag>
        <a-tag v-if="text === 'YELLOW'" color="orange">黄单</a-tag>
        <a-tag v-if="text === 'BLACK'" color="black">黑单</a-tag>
      </div>
      <div slot="actualUseMaterialList" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span v-for="(item, index) in text" :key="index">
              {{item.materialName}}({{item.materialCode}})
              <span v-if="index < text.length - 1">,</span>
            </span>
          </template>
          <div class="table-ellis">
            <span v-for="(item, index) in text" :key="index">{{item.materialName}}({{item.materialCode}}),</span>
          </div>
        </a-tooltip>
      </div>
      <div slot="operation" slot-scope="text, column" class="table-operation">
        <a-dropdown v-if="column.status !== 3 && column.status !== 10">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item
              v-if="column.status === 2 || column.status === 4|| column.status === 5"
              @click="handleCreateCraftCard([column.id])"
            >生成工艺流转卡</a-menu-item>
            <a-menu-item v-if="column.status === 2 || column.status === 4|| column.status === 5" @click="handleUpdateTask(column)">更新任务</a-menu-item>
            <a-menu-item
              v-if="column.status === 2 || column.status === 4|| column.status === 5 && column.isParts === 1"
              @click="assembleTask(column)"
            >装配任务</a-menu-item>
            <a-menu-item v-if="column.status === 2 || column.status === 4|| column.status === 5" @click="batchTask(column)">任务分批</a-menu-item>
            <a-menu-item
              v-if="
                column.status === 0 ||
                  column.status === 1 ||
                  column.status === 2 || column.status === 4 || column.status === 5
              "
              @click="switchTaskStatus(column, 'c')"
            >取消任务</a-menu-item>
            <a-menu-item v-if="column.status === 2 || column.status === 4|| column.status === 5" @click="switchTaskStatus(column, 's')">暂停任务</a-menu-item>
            <a-menu-item v-if="column.status === 9" @click="switchTaskStatus(column, 'r')">恢复任务</a-menu-item>
            <a-menu-item
              v-if="
                column.status === 8 ||
                  column.status === 0 ||
                  column.status === 1
              "
              @click="handleDeleteTask(column)"
            >删除任务</a-menu-item>
            <a-menu-item v-if="column.checkResult" @click="handleCheckCertificate(column.id)">查看合格证</a-menu-item>
            <a-menu-item
              v-if="column.status === 2 || column.status === 4|| column.status === 5"
              @click="handleTaskEmergencyTypeEdit(column)"
            >设置紧急类型</a-menu-item>
          </a-menu>
          <a class="operation-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <el-dropdown class="export-task" @command="handleExportTaskCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出任务</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="FILTERED">导出当前筛选任务</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <TaskEditModal :task-form="currentTask" v-if="visible" />
    <TaskBatchEdit
      v-if="batchTaskEditVisible"
      :task-form="currentTask"
      @cancel="handleTaskBatchModalCanceled"
      @confirm="handleTaskBatchModalConfirmed"
    />
    <TaskAssembleEdit
      v-if="assembleTaskEditVisible"
      :task-form="currentSelectTaskIds"
      @cancel="handleTaskAssembleModalCanceled"
      @confirm="handleTaskAssembleModalConfirmed"
    />
    <TaskEmergencyEditModal
      v-if="emergencyEditVisible"
      :ids="emergencyEditIds"
      :record="emergencyEditColumn"
      @confirm="handleTaskEmergencyTypeEditConfirm"
      @cancel="handleTaskEmergencyTypeEditCancel"
    />
    <TaskAssembleModal
      v-if="assembleTaskModalVisible"
      :ids="assembleIds"
      @cancel="handleTaskAssembleCancel"
      @confirm="handleTaskAssembleConfirm"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { taskColumns } from './dataLogic'
import TaskEditModal from '@/components/TaskEditModal'
import TaskBatchEdit from '@/components/TaskBatchEdit'
import TaskAssembleEdit from '@/components/TaskAssembleEdit'
import TaskAssembleModal from '@/components/TaskAssembleModal'
import TaskEmergencyEditModal from '@/components/TaskEmergencyEditModal'
import { getTasks, deleteTask, exportTasks, switchTaskStatus } from '@/api/task'
import { getAllGroups } from '@/api/group'

export default {
  components: {
    TaskEditModal,
    TaskBatchEdit,
    TaskAssembleEdit,
    TaskAssembleModal,
    TaskEmergencyEditModal
  },
  data() {
    return {
      loading: false,
      taskList: [],
      taskColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 20,
        showSizeChanger: true,
        pageSizeOptions: ['20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      currentTaskListQueryParams: {
        pageNum: 1,
        pageSize: 20,
        sort_by: null
      },
      currentEditType: '更新',
      currentTask: {},
      currentSelectTaskIds: [],
      visible: false,
      batchTaskEditVisible: false,
      assembleTaskEditVisible: false,
      columnSearchParams: {},

      selectedRowPlanIds: [],
      exportLoading: false,
      emergencyEditVisible: false,
      emergencyEditIds: [],

      assembleIds: '',
      assembleTaskModalVisible: false,
      emergencyEditColumn: {},

      assembleIds: '',
      assembleTaskModalVisible: false
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  created() {
    this.getTasks(this.currentTaskListQueryParams)
    this.getGroups()
  },
  methods: {
    getGroups() {
      getAllGroups({
        withMembers: false
      }).then(res => {
        const filters = res.data.map(item => {
          return {
            text: item.groupName,
            value: item.id
          }
        })
        this.taskColumns.some(item => {
          if (item.key === 'teamGroup.id') {
            item.filters = filters
            return true
          }
        })
      })
    },
    getTasks(data) {
      this.loading = true
      getTasks(data)
        .then(res => {
          this.taskList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getTasksByParams() {
      const cQueryParams = deepClone(this.currentTaskListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getTasks(queryParams)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRowPlanIds = selectedRows.map(val => {
        return val.planId
      })
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)

      if (filtersKeys.includes('type')) {
        delete this.columnSearchParams['type']
        if (filters['type'].length > 0) {
          this.columnSearchParams['task.type'] = `in:${filters[
            'type'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.type']
        }
      }

      if (filtersKeys.includes('emgType')) {
        delete this.columnSearchParams['emgType']
        if (filters['emgType'].length > 0) {
          this.columnSearchParams['emgType'] = `in:${filters[
            'emgType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['emgType']
        }
      }
      if (filtersKeys.includes('code')) {
        delete this.columnSearchParams['code']
        if (filters['code'].length > 0) {
          this.columnSearchParams['task.code'] = `like:${filters[
            'code'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.code']
        }
      }

      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['task.status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.status']
        }
      }


      if (filtersKeys.includes('deliveryStatus')) {
        delete this.columnSearchParams['deliveryStatus']
        if (filters['deliveryStatus'].length > 0) {
          this.columnSearchParams['task.deliveryStatus'] = `in:${filters[
            'deliveryStatus'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.deliveryStatus']
        }
      }

      if (filtersKeys.includes('teamGroup.id')) {
        delete this.columnSearchParams['teamGroup.id']
        if (filters['teamGroup.id'].length > 0) {
          this.columnSearchParams['teamGroup.id'] = `in:${filters[
            'teamGroup.id'
          ].toString()}`
        } else {
          delete this.columnSearchParams['teamGroup.id']
        }
      }

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        if (sorter.columnKey === 'code') {
          sorter.columnKey = 'task.code'
        }
        this.currentTaskListQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentTaskListQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentTaskListQueryParams.pageNum = pagination.current
      this.currentTaskListQueryParams.pageSize = pagination.pageSize
      this.getTasksByParams()
    },
    // 删除任务
    handleDeleteTask(column) {
      const taskCode = column.code ? column.code : ''
      this.$confirm({
        content: '确认删除任务' + taskCode + '?',
        onOk: () => {
          deleteTask(column.id).then(res => {
            if (res) {
              this.$message.success('删除任务成功！')
              this.getTasksByParams()
            }
          })
        }
      })
    },
    // 改变任务状态
    switchTaskStatus(row, statusCode) {
      let actionName = ''
      if (statusCode === 'c') {
        actionName = '取消'
      } else if (statusCode === 's') {
        actionName = '暂停'
      } else if (statusCode === 'r') {
        actionName = '恢复'
      }
      this.$confirm({
        content: '确认' + actionName + '任务' + row.code + '?',
        onOk: () => {
          switchTaskStatus(row.id, statusCode).then(() => {
            this.$message.success(actionName + '任务成功！')
            this.getTasksByParams()
          })
        }
      })
    },
    // 更新任务
    handleUpdateTask(column) {
      this.currentTask = deepClone(column)
      this.visible = true
    },
    batchTask(column) {
      this.batchTaskEditVisible = true
      this.currentTask = deepClone(column)
    },
    assembleTask(column) {
      this.currentSelectTaskIds.push(column.id)
      this.assembleTaskEditVisible = true
    },
    handleTaskBatchModalCanceled() {
      this.batchTaskEditVisible = false
    },
    handleTaskBatchModalConfirmed() {
      this.batchTaskEditVisible = false
      this.$message.success('任务分批成功')
      this.getTasksByParams()
    },
    handleTaskAssembleModalCanceled() {
      this.assembleTaskEditVisible = false
    },
    handleTaskAssembleModalConfirmed() {
      this.assembleTaskEditVisible = false
      this.$message.success('任务装配成功')
      this.getTasksByParams()
    },
    handleEditCancel() {
      this.currentTask = {}
      this.visible = false
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      console.log(selectedKeys)
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          if (dataIndex === 'stayTime') {
            const minTime = this.$moment()
              .subtract(selectedKeys[1], 'hours')
              .format('YYYY-MM-DD HH:mm:ss')
            const maxTime = this.$moment()
              .subtract(selectedKeys[0], 'hours')
              .format('YYYY-MM-DD HH:mm:ss')
            this.columnSearchParams['latestOperationTime'] =
              'btn:' + minTime + ',' + maxTime
          } else {
            this.columnSearchParams[dataIndex] =
              'btn:' + selectedKeys.toString()
          }
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      if (dataIndex === 'stayTime') {
        this.columnSearchParams['latestOperationTime'] = null
      }
      this.columnSearchParams[dataIndex] = null
    },
    getExportIdsFromSelection() {
      this.exportIds = this.selectedRowKeys
    },
    getExportIdsFromPage() {
      this.exportIds = this.taskList.map(item => {
        return item.id
      })
    },
    handleExportTaskCommand(command) {
      const cQueryParams = deepClone(this.currentTaskListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      switch (command) {
        case 'FILTERED':
          this.exportLoading = true
          exportTasks(queryParams)
            .then(res => {
              this.downloadTask(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })

          break
        default:
          break
      }
    },
    downloadTask(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.TASK_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleCreateCraftCard(selectedRowKeys) {
      const newPage = this.$router.resolve({
        path: '/craft-card',
        query: {
          ids: selectedRowKeys.toString()
        }
      })
      window.open(newPage.href, '_blank')
    },
    handleCheckCertificate(id) {
      const routeData = this.$router.resolve({
        name: 'certificate',
        query: {
          id: id
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleCreatePlanCard() {
      const newPage = this.$router.resolve({
        path: '/plan-delivery-card',
        query: {
          ids: this.selectedRowKeys.toString()
        }
      })
      window.open(newPage.href, '_blank')
    },
    handleTaskEmergencyTypeEdit(column) {
      this.emergencyEditVisible = true
      this.emergencyEditIds = [column.id]
      this.emergencyEditColumn = column
    },
    handleBatchEmergencyTypeEdit(ids) {
      this.emergencyEditVisible = true
      this.emergencyEditIds = ids
      this.emergencyEditColumn = {}
    },
    handleTaskEmergencyTypeEditConfirm() {
      this.emergencyEditVisible = false
      this.getTasksByParams()
    },
    handleTaskEmergencyTypeEditCancel() {
      this.emergencyEditVisible = false
    },
    handleTaskAssemble(ids) {
      this.assembleIds = ids
      this.assembleTaskModalVisible = true
    },
    handleTaskAssembleConfirm() {
      this.assembleTaskModalVisible = false
      this.getTasksByParams()
    },
    handleTaskAssembleCancel() {
      this.assembleTaskModalVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }
  .batch-operate-link {
    padding-left: 20px;
    cursor: pointer;
    color: #409eff;
  }

  .operation-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-task {
    float: right;
    top: -50px;
    right: 20px;
  }
}
/deep/ .ant-table-pagination,
.ant-pagination {
  float: left;
}
/deep/ .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: hidden;
}
.table-ellis {
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}
</style>
