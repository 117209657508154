export const taskColumns = [
  {
    title: '日目标名称',
    width: 150,
    dataIndex: 'targetName',
    key: 'targetName',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'targetName',
    },
  },
  {
    title: '日目标日期',
    width: 150,
    dataIndex: 'targetStartTime',
    key: 'targetStartTime',
    align: 'center',
    searchType: 'date',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'targetStartTime',
    },
  },
  {
    title: '班组名称',
    width: 150,
    dataIndex: 'groupName',
    key: 'groupName',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'groupName',
    },
  },
  {
    title: '员工号',
    width: 150,
    dataIndex: 'uid',
    key: 'uid',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'uid',
    },
  },
  {
    title: '员工名称',
    width: 150,
    dataIndex: 'realName',
    key: 'realName',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'realName',
    },
  },
  {
    title: '任务编码',
    width: 150,
    dataIndex: 'code',
    key: 'task.code',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'code',
    },
  },
  {
    title: '紧急类型',
    width: 150,
    dataIndex: 'emergencyType',
    key: 'task.emgType',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'emergencyType',
    },
    filters: [
      {
        text: '普通',
        value: 'NML',
      },
      {
        text: '必完件',
        value: 'MFN',
      },
      {
        text: '紧急件',
        value: 'UGC',
      },
      {
        text: '插入急件',
        value: 'IUG',
      },
      {
        text: '转入急件',
        value: 'TUG',
      },
    ],
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    width: 150,
    key: 'task.status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '进行中',
        value: 2,
      },
      {
        text: '已完成',
        value: 3,
      },
      {
        text: '超期进行中',
        value: 4,
      },
      {
        text: '即将超期',
        value: 5,
      },
      {
        text: '暂停中',
        value: 9,
      },
      {
        text: '已取消',
        value: 8,
      },
      {
        text: '异常终止',
        value: 10,
      },
    ],
  },
  {
    title: '目标状态',
    dataIndex: 'taskStatus',
    width: 150,
    key: 'taskStatus',
    align: 'center',
    scopedSlots: {
      customRender: 'taskStatus',
    },
    filters: [
      {
        text: '未完成',
        value: 'P',
      },
      {
        text: '已完成',
        value: 'F',
      },
      {
        text: '不涉及',
        value: 'X',
      },
    ],
  },
  {
    title: '是否计划外',
    dataIndex: 'taskType',
    width: 150,
    key: 'taskType',
    align: 'center',
    scopedSlots: {
      customRender: 'taskType',
    },
    filters: [
      {
        text: '是',
        value: 1,
      },
      {
        text: '否',
        value: 0,
      },
    ],
  },
  {
    title: '产品名称',
    width: 150,
    dataIndex: 'productName',
    key: 'product.productName',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户名称',
    width: 150,
    dataIndex: 'client',
    key: 'client',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '在产数量',
    width: 150,
    dataIndex: 'producingNum',
    key: 'task.producingNum',
    align: 'center',
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '目标数量',
    width: 150,
    dataIndex: 'targetProductNum',
    key: 'twd_group_user_task.productNum',
    align: 'center',
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '完成数量',
    width: 150,
    dataIndex: 'taskFinishNum',
    key: 'twd_group_user_task.taskFinishNum',
    align: 'center',
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '交货时间',
    dataIndex: 'deliverTime',
    key: 'deliverTime',
    width: 150,
    align: 'center',
    searchType: 'date',
    ellipsis: true,
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '完成时间',
    dataIndex: 'taskFinishTime',
    key: 'taskFinishTime',
    width: 150,
    align: 'center',
    searchType: 'date',
    ellipsis: true,
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务实时位置',
    dataIndex: 'latestOperationName',
    key: 'latestOperationName',
    width: 150,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'latestOperationName',
    },
  },
  {
    title: '未完成工序',
    key: 'process',
    width: 8000,
    scopedSlots: {
      customRender: 'process',
    },
  },
  // {
  //   title: '操作',
  //   key: 'operation',
  //   align: 'center',
  //   width: 80,
  //   fixed: 'right',
  //   scopedSlots: {
  //     customRender: 'operation'
  //   }
  // }
]

export const targetRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({
    props: {
      disabled: record.status !== 2,
    },
  }),
}
