<template>
  <a-modal class="modal-container" visible="true" title="卡片审批" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="card" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="审批人员" prop="approverId">
        <a-select v-model="card.approverId" show-search placeholder="请选择审批人员" option-filter-prop="children" :filter-option="filterUser">
          <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="审批日期" prop="approveTime">
        <a-date-picker v-model="card.approveTime" disabled placeholder="请选择审批时间" value-format="YYYY-MM-DD HH:mm:ss" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { getAllUsers } from '@/api/user'
import { approveCard } from '@/api/craft'

import * as R from 'ramda'
export default {
  props: {
    cardId: String,
  },
  data() {
    return {
      title: this.modalTitle,
      userList: [],
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      card: {
        approverId: '',
        approveTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      },
      loading: false,
      rules: {
        approverId: [
          {
            required: true,
            message: '请选择审批人员',
            trigger: 'change',
          },
        ],
        approveTime: [
          {
            required: true,
            message: '请选择审批时间',
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function () {
      return this.modalTitle === '新增'
    },
    isUpdate: function () {
      return this.modalTitle === '更新'
    },
  },
  mounted() {
    this.getAllUsers()
  },
  methods: {
    getAllUsers() {
      getAllUsers().then((res) => {
        if (res.data && res.data.accountList) {
          this.userList = R.clone(res.data.accountList)
        }
      })
    },
    filterUser(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          approveCard(this.cardId, this.card)
            .then(() => {
              this.loading = false
              this.$emit('confirm')
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
