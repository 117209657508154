var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c(
        "div",
        { attrs: { id: "craft-card-print" } },
        [
          _vm.receipt <= 1
            ? [
                _c(
                  "div",
                  { staticStyle: { "page-break-after": "always" } },
                  [
                    _c(
                      "a-descriptions",
                      { attrs: { bordered: "" } },
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "供货商地址：", span: 2 } },
                          [_vm._v("重庆市渝北区大竹林街道天山大道东段1号")]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "供货商电话：", span: 2 } },
                          [_vm._v("023-XXXXXXXXX")]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "收货地址：", span: 2 } },
                          [_vm._v("重庆市渝北区大竹林街道天山大道东段1号")]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "订单编号：", span: 2 } },
                          [
                            _vm._v(
                              _vm._s(_vm.data.clientOrderCode) +
                                _vm._s(
                                  _vm.productTakeOutItemList[0]
                                    .clientWarehouseCode || ""
                                )
                            ),
                          ]
                        ),
                        _c("a-descriptions-item", {
                          attrs: { label: "送货人：", span: 2 },
                        }),
                        _c("a-descriptions-item", {
                          attrs: { label: "经办人：", span: 2 },
                        }),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "送货日期：", span: 2 } },
                          [_vm._v(_vm._s(_vm.currentDate))]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "联系电话：", span: 2 } },
                          [_vm._v("023-XXXXXXXXX-8000")]
                        ),
                      ],
                      1
                    ),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": _vm.productTakeOutItemList,
                        bordered: "",
                        pagination: false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "amount",
                            fn: function (text) {
                              return _c("p", { staticClass: "amount-font" }, [
                                _vm._v(_vm._s(text)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        1567536805
                      ),
                    }),
                    _vm._m(0),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.reportColumns,
                        "data-source": _vm.checkSelfList,
                        bordered: "",
                        pagination: false,
                      },
                    }),
                    _vm._m(1),
                  ],
                  1
                ),
              ]
            : _vm._l(_vm.data, function (item) {
                return _c(
                  "div",
                  {
                    key: item.takeOutNumber,
                    staticClass: "page",
                    staticStyle: { "page-break-after": "always" },
                  },
                  [
                    _c(
                      "a-descriptions",
                      { attrs: { bordered: "" } },
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "供货商地址：", span: 2 } },
                          [_vm._v("重庆市渝北区大竹林街道天山大道东段1号")]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "供货商电话：", span: 2 } },
                          [_vm._v("023-XXXXXXXXX")]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "收货地址：", span: 2 } },
                          [_vm._v("重庆市渝北区大竹林街道天山大道东段1号")]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "订单编号：", span: 2 } },
                          [
                            _vm._v(
                              _vm._s(item.clientOrderCode) +
                                _vm._s(
                                  item.productTakeOutItemList[0]
                                    .clientWarehouseCode
                                )
                            ),
                          ]
                        ),
                        _c("a-descriptions-item", {
                          attrs: { label: "送货人：", span: 2 },
                        }),
                        _c("a-descriptions-item", {
                          attrs: { label: "经办人：", span: 2 },
                        }),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "送货日期：", span: 2 } },
                          [_vm._v(_vm._s(_vm.currentDate))]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "联系电话：", span: 2 } },
                          [_vm._v("023-XXXXXXXXX-8000")]
                        ),
                      ],
                      1
                    ),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": item.productTakeOutItemList,
                        bordered: "",
                        pagination: false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "amount",
                            fn: function (text) {
                              return _c("p", { staticClass: "amount-font" }, [
                                _vm._v(_vm._s(text)),
                              ])
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._m(2, true),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.reportColumns,
                        "data-source": item.checkSelfList,
                        bordered: "",
                        pagination: false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "amount",
                            fn: function (text) {
                              return _c("p", { staticClass: "amount-font" }, [
                                _vm._v(_vm._s(text)),
                              ])
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._m(3, true),
                  ],
                  1
                )
              }),
        ],
        2
      ),
      _c(
        "a-button",
        {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: "craft-card-print",
              expression: "'craft-card-print'",
            },
          ],
          attrs: { type: "primary", block: "" },
        },
        [_vm._v("打印")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-form" }, [
      _c("span", [_vm._v("渝江收货人及日期：")]),
      _c("div", { staticClass: "bottom-line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-form" }, [
      _c("span", [_vm._v("检测人及日期：")]),
      _c("div", { staticClass: "bottom-line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-form" }, [
      _c("span", [_vm._v("渝江收货人及日期：")]),
      _c("div", { staticClass: "bottom-line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-form" }, [
      _c("span", [_vm._v("检测人及日期：")]),
      _c("div", { staticClass: "bottom-line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }