export const templateColumns = [
  {
    title: '模板编号',
    width: 150,
    dataIndex: 'craftCode',
    key: 'craftCode',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'templateCode'
    }
  },
  {
    title: '工艺产品名称',
    dataIndex: 'craftProductName',
    key: 'craftProductName',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '工艺产品图号',
    dataIndex: 'craftDrawingNum',
    key: 'craftDrawingNum',
    align: 'center',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '工艺产品规格',
    dataIndex: 'craftSpecification',
    key: 'craftSpecification',
    align: 'center',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '编制人员',
    dataIndex: 'designer',
    key: 'user.realName',
    width: 150,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '设计日期',
    dataIndex: 'designTime',
    key: 'designTime',
    width: 150,
    ellipsis: true,
    sorter: true,
    searchType: 'date',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'designTime'
    }
  },
  {
    title: '工艺路线',
    width: 200,
    dataIndex: 'processRouteTemplate',
    key: 'processRouteTemplate',
    align: 'center',
    scopedSlots: {
      customRender: 'processRouteTemplate'
    }
  },
  {
    title: '操作',
    key: 'operation',
    width: 150,
    align: 'center',
    scopedSlots: {
      customRender: 'operation'
    }
  }
]

export const templateRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({
    props: {
    },
  }),
}
