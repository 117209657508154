<template>
  <a-dropdown class="searchFilterIcon" v-model="searchInputVisible" placement="bottomRight" :trigger="['click']">
    <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
    <a-menu slot="overlay">
      <a-menu-item v-if='this.type==="text"'>
        <a-input class="searchInput" :placeholder="'搜索'+label" v-model="searchText" @pressEnter="handleSearch" />
      </a-menu-item>
      <a-menu-item v-if='this.type==="number"'>
        <a-input-number size="small" placeholder="最小值" v-model="minValue" :min="0" :max="999999"
          style="width: 95px; display: inline-block;" @pressEnter="handleSearch" />
        <span>-</span>
        <a-input-number size="small" v-model="maxValue" placeholder="最大值" :min="minValue" :max="999999"
          style="width: 95px; display: inline-block;" @pressEnter="handleSearch" />
      </a-menu-item>
      <a-menu-item v-if='this.type==="date"'>
        <a-range-picker size="small" style="width: 190px;" @change="onDatePickerChange" v-model="moments"
          :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }" />
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a-button class="searchButton" type="primary" icon="search" size="small" @click="handleSearch">
          搜索
        </a-button>
        <a-button class="resetButton" size="small" @click="handleReset">
          重置
        </a-button>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>
<script>
  export default {
    props: {
      label: String,
      columnKey: String,
      searchType: String,
    },
    data() {
      return {
        filtered: false,
        searchInputVisible: false,
        searchText: '',
        minValue: null,
        maxValue: null,
        moments: [],
        dateRange: [],
        type: this.searchType
      }
    },
    methods: {
      handleSearch() {
        this.filtered = true
        if (this.type === 'text' && this.searchText.trim() === '') {
          this.searchText = ''
          this.filtered = false
        }
        if (this.type === 'number' && this.minValue === null && this.maxValue === null) {
          this.filtered = false
        }
        if (this.type === 'date' && this.dateRange.length === 0) {
          this.filtered = false
        }
        this.searchInputVisible = false
        this.emitSearchEvent()
      },
      handleReset() {
        if (this.type === 'text') {
          this.searchText = ''
        }
        if (this.type === 'number') {
          this.minValue = null;
          this.maxValue = null;
        }
        if (this.type === 'date') {
          this.moments = [];
          this.dateRange = [];
        }
        this.emitSearchEvent()
        this.filtered = false
        this.searchInputVisible = false
      },
      onDatePickerChange(dates, dateStrings) {
        this.dateRange = [...dateStrings];
      },
      emitSearchEvent() {
        const searchParam = {};
        searchParam[this.columnKey] = '';
        switch (this.type) {
          case 'text':
            if (this.searchText.trim()) {
              searchParam[this.columnKey] = 'like:' + this.searchText.trim()
            }
            break
          case 'number':
            if (this.minValue || this.maxValue) {
              searchParam[this.columnKey] = 'btn:' + (this.minValue ? this.minValue : '') + ',' + (this.maxValue ? this
                .maxValue : '');
            }
            break
          case 'date':
            if (this.dateRange.length === 2) {
              searchParam[this.columnKey] = 'btn:' + (this.dateRange[0] ? this.dateRange[0] : '') + ',' + (this
                .dateRange[1] ? this.dateRange[1] : '');
            }
            break
          default:
            break
        }
        this.$emit('search', searchParam)
      }
    }

  }

</script>

<style>
  .searchFilterIcon {
    position: absolute;
    top: 10px;
    right: 0;
    padding-right: 5px;
    padding-left: 15px;
    padding-bottom: 20px;
  }

  .searchFilterIcon~.el-table__column-filter-trigger {
    display: none;
  }

  .searchButton {
    width: 90px;
    margin-right: 8px
  }

  .resetButton {
    width: 90px;
  }

  .searchInput {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }

</style>
