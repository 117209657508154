<template>
  <div class="app-container">
    <a-row>
      <a-col :span="2">
        <el-button class="header-button" type="primary" icon="el-icon-circle-plus-outline" @click="handleAddProduct">新增产品</el-button>
      </a-col>
      <!-- <a-col :span="3" :offset="17">
        <el-button type="text" icon="el-icon-download" @click="handleDownloadTemplate">点击下载员工导入模板</el-button>
      </a-col>-->
    </a-row>
    <a-table
      row-key="id"
      bordered
      class="users-table"
      :columns="productColumns"
      :data-source="productList"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <!-- <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>-->
      <!-- <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" /> -->
      <div slot="operate" slot-scope="text, column" class="table-user">
        <a-dropdown v-if="column.status!==2">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleUpdateProduct(column)">更新产品信息</a-menu-item>
          </a-menu>
          <a class="user-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <ProductLibraryModal
      :productRecord="currentEditProduct"
      :type="currentEditType"
      v-if="productModalVisible"
      @confirm="handleProductModalConfirm"
      @cancel="handleProductModalCancel"
    />
    <!-- <BomTree /> -->
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { productColumns } from './dataLogic'
import { exportAllUsers } from '@/api/user'
import { getProductLibrary } from '@/api/productLibrary'
import { downloadTemplate } from '@/common/templateDownload'
import BomTree from '@/components/BomTree'
import ProductLibraryModal from '@/components/ProductLibraryModal'

export default {
  components: {
    BomTree,
    ProductLibraryModal
  },
  data() {
    return {
      loading: false,
      uploading: false,
      productList: [],
      productColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentEditType: '',
      currentUser: {},
      editUserModalVisible: false,
      userRoleModalVisible: false,
      userOperationModalVisible: false,
      columnSearchParams: {},
      uploadUrl: process.env.VUE_APP_BASE_API,
      exportLoading: false,
      productModalVisible: false,
      currentEditProduct: ''
    }
  },
  created() {
    this.getProductLibrary(this.initQueryParams)
  },
  methods: {
    getProductLibrary(data) {
      this.loading = true
      getProductLibrary(data)
        .then(res => {
          this.productList = res.data.records.map((item, index) => {
            item.index =
              (this.currentQueryParams.pageNum - 1) *
                this.currentQueryParams.pageSize +
              index +
              1
            return item
          })
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getProductLibraryByParams() {
      const cQueryParams = deepClone(this.currentQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getProductLibrary(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('sex')) {
        delete this.columnSearchParams['sex']
        if (filters['sex'].length > 0) {
          this.columnSearchParams['sex'] = `in:${filters['sex'].toString()}`
        } else {
          delete this.columnSearchParams['sex']
        }
      }
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['user.status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['user.status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['user.status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentQueryParams.pageNum = pagination.current
      this.currentQueryParams.pageSize = pagination.pageSize
      this.getProductLibraryByParams()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      if (dataIndex === 'roles') {
        dataIndex = 'role.name'
      }
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleDownloadTemplate() {
      downloadTemplate('user')
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`批量导入失败，请稍后再试`)
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      this.$info({
        title: '员工信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入数据总计：' + response.totalNum + '条;'),
          h('p', '非法数据条数：' + response.failedNum + ';'),
          h('p', '导入成功数据条数：' + response.successfulNum + ';'),
          h('p', '非法数据信息:'),
          h('p', response.invalidMessages.join(' ||  '))
        ]),
        onOk: () => {
          this.uploading = false
          this.getUsers(this.currentQueryParams)
        }
      })
    },
    handleExportUserCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'ALL': {
          exportAllUsers()
            .then(res => {
              this.downloadInquiry(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        }
        default:
          this.exportLoading = false
          break
      }
    },
    downloadInquiry(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.USER_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleAddProduct() {
      this.currentEditType = 'add'
      this.productModalVisible = true
    },
    handleUpdateProduct(record) {
      this.currentEditType = 'update'
      this.currentEditProduct = deepClone(record)
      this.productModalVisible = true
    },
    handleProductModalConfirm() {
      this.productModalVisible = false
      this.currentEditProduct = {}
      this.getProductLibraryByParams()
    },
    handleProductModalCancel() {
      this.currentEditProduct = {}
      this.productModalVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
}
</style>
