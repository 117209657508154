<template>
  <a-modal
    v-drag-modal
    :body-style="bodyStyle"
    class="modal-container"
    :visible="true"
    :title="title"
    :mask-closable="false"
    width="1200px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="handleIgnore">忽略</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm" :disabled="!selectedRowKeys.length">强制结束</a-button>
    </template>
    <a-table
      row-key="id"
      bordered
      class="tasks-table"
      :scroll="{ x: 2700, y: 700 }"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :columns="columns"
      :data-source="taskList"
      :pagination="false"
      :loading="tableLoading"
    >
        <span slot="type" slot-scope="text">
          <a-tag v-if="text===0" color="volcano">普通任务</a-tag>
          <a-tag v-if="text===1" color="geekblue">返修任务</a-tag>
          <a-tag v-if="text===2" color="green">生产补投任务</a-tag>
          <a-tag v-if="text===3" color="grey">协作任务</a-tag>
          <a-tag v-if="text===4" color="red">普通补投任务</a-tag>
          <a-tag v-if="text===5" color="orange">库存转入任务</a-tag>
        </span>
        <span slot="emgType" slot-scope="text">
          <a-tag color="volcano">{{ EmergencyType[text] }}</a-tag>
        </span>
        <div slot="status" slot-scope="text">
          <template v-if="text === 0">
            <a-badge status="default" />准备中
          </template>
          <template v-if="text === 1">
            <a-badge status="default" />未开始
          </template>
          <template v-if="text === 2">
            <a-badge status="processing" />进行中
          </template>
          <template v-if="text === 3">
            <a-badge status="success" />已完成
          </template>
          <template v-if="text === 9">
            <a-badge status="warning" />暂停中
          </template>
          <template v-if="text === 8">
            <a-badge status="error" />已取消
          </template>
          <template v-if="text === 10">
            <a-badge status="error" />异常终止
          </template>
        </div>
    </a-table>
  </a-modal>
</template>
<script>
import { getPartsTaskByPlan, finishNoCheck } from '@/api/record'
import { assembleProducts } from '@/api/task'
import { taskColumns } from './dataLogic'
import { EmergencyType } from '@/enums/index'

export default {
  props: {
    taskFinishPlanId: Number,
    taskFinishUserId: Number,
  },
  data() {
    return {
      bodyStyle: {
        height: '500px',
        overflowY: 'scroll',
      },
      columns: taskColumns,
      title: '存在尚未结束的分件任务，请选择是否进行强制结束',
      loading: false,
      tableLoading: false,
      taskList: [],
      selectedRowKeys: [],
      selectedRowTaskIds: [],
      taskAssembleDTO: {},
      EmergencyType
    }
  },
  created() {
    this.getPartsTaskByPlan()
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleIgnore() {
      this.$emit('ignore')
    },
    getPartsTaskByPlan() {
      this.tableLoading = true
      getPartsTaskByPlan(this.taskFinishPlanId)
        .then((res) => {
          this.taskList = res.data
          if (this.taskList.length === 0) {
            this.handleConfirm()
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleConfirm() {
      this.loading = true
      if (this.taskList.length === 0 || this.selectedRowKeys.length === 0) {
        this.$emit('confirm')
      } else {
        finishNoCheck({
          userId: this.taskFinishUserId,
          taskIds: this.selectedRowKeys,
        })
          .then((res) => {
            this.$emit('confirm')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
  },
}
</script>

<style scoped>
</style>
