var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.title,
        maskClosable: false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.group,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "班组名称", prop: "groupName" } },
            [
              _c("a-input", {
                attrs: { maxlength: "20", placeholder: "请输入班组名称" },
                model: {
                  value: _vm.group.groupName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.group,
                      "groupName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "group.groupName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "班组长列表" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    mode: "multiple",
                    "show-search": "",
                    placeholder: "请选择班组长列表",
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterUser,
                  },
                  on: { change: _vm.handleUserChange },
                  model: {
                    value: _vm.group.leaderIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.group, "leaderIds", $$v)
                    },
                    expression: "group.leaderIds",
                  },
                },
                _vm._l(_vm.currentUserList, function (user) {
                  return _c(
                    "a-select-option",
                    { key: user.id, attrs: { value: user.id } },
                    [
                      _vm._v(
                        _vm._s(user.realName) + "(" + _vm._s(user.uid) + ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "成员列表" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    mode: "multiple",
                    "show-search": "",
                    placeholder: "请选择成员列表",
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterUser,
                  },
                  model: {
                    value: _vm.group.memberIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.group, "memberIds", $$v)
                    },
                    expression: "group.memberIds",
                  },
                },
                _vm._l(_vm.currentUserList, function (user) {
                  return _c(
                    "a-select-option",
                    { key: user.id, attrs: { value: user.id } },
                    [
                      _vm._v(
                        _vm._s(user.realName) + "(" + _vm._s(user.uid) + ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "工序列表" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    mode: "multiple",
                    placeholder: "请选择工序",
                    "option-filter-prop": "children",
                  },
                  model: {
                    value: _vm.group.operationIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.group, "operationIds", $$v)
                    },
                    expression: "group.operationIds",
                  },
                },
                _vm._l(_vm.currentOperationList, function (operation) {
                  return _c(
                    "a-select-option",
                    { key: operation.id, attrs: { value: operation.id } },
                    [
                      _vm._v(
                        _vm._s(operation.operationName) +
                          "(" +
                          _vm._s(operation.operationCode) +
                          ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }