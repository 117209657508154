var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title + "产品",
        "mask-closable": false,
        width: "700px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.product,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _vm.isOrderAdd
            ? _c(
                "a-form-model-item",
                { attrs: { label: "产品序号", prop: "productCode" } },
                [
                  _c("a-input", {
                    attrs: {
                      "max-length": "15",
                      placeholder: "请输入产品序号",
                    },
                    model: {
                      value: _vm.product.productCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.product,
                          "productCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "product.productCode",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "产品名称", prop: "productName" } },
            [
              _c("a-input", {
                attrs: { "max-length": "20", placeholder: "请输入产品名称" },
                model: {
                  value: _vm.product.productName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.product,
                      "productName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "product.productName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "产品类型", prop: "type" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.product.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.product, "type", $$v)
                    },
                    expression: "product.type",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "L" } }, [_vm._v("L类型")]),
                  _c("a-radio", { attrs: { value: "D" } }, [_vm._v("D类型")]),
                  _c("a-radio", { attrs: { value: "G" } }, [_vm._v("G类型")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "产品类别", prop: "productCategory" } },
            [
              _vm.product.type === "L"
                ? _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.product.productCategory,
                        callback: function ($$v) {
                          _vm.$set(_vm.product, "productCategory", $$v)
                        },
                        expression: "product.productCategory",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "单体轴类" } }, [
                        _vm._v("单体轴类"),
                      ]),
                      _c("a-radio", { attrs: { value: "单体非轴类" } }, [
                        _vm._v("单体非轴类"),
                      ]),
                      _c("a-radio", { attrs: { value: "组合" } }, [
                        _vm._v("组合"),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.product.productCategory,
                        callback: function ($$v) {
                          _vm.$set(_vm.product, "productCategory", $$v)
                        },
                        expression: "product.productCategory",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "单体" } }, [
                        _vm._v("单体"),
                      ]),
                      _c("a-radio", { attrs: { value: "组合" } }, [
                        _vm._v("组合"),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm.product.type === "D"
            ? _c(
                "a-form-model-item",
                {
                  attrs: { label: "刀具材料类别", prop: "materialCategories" },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "show-search": "",
                        placeholder: "请选择刀具材料类别",
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterMaterialCategoriesOption,
                      },
                      model: {
                        value: _vm.product.materialCategories,
                        callback: function ($$v) {
                          _vm.$set(_vm.product, "materialCategories", $$v)
                        },
                        expression: "product.materialCategories",
                      },
                    },
                    _vm._l(_vm.materialCategories, function (category) {
                      return _c(
                        "a-select-option",
                        {
                          key: category.parameterValue,
                          attrs: { value: category.parameterCode },
                        },
                        [_vm._v(_vm._s(category.parameterValue))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "产品图号", prop: "drawingNum" } },
            [
              _c("a-input", {
                attrs: { "max-length": "100", placeholder: "请输入产品图号" },
                model: {
                  value: _vm.product.drawingNum,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.product,
                      "drawingNum",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "product.drawingNum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "规格", prop: "specification" } },
            [
              _c("a-input", {
                attrs: { "max-length": "100", placeholder: "请输入产品规格" },
                model: {
                  value: _vm.product.specification,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.product,
                      "specification",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "product.specification",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "单位", prop: "unit" } },
            [
              _c("a-input", {
                attrs: { "max-length": "5", placeholder: "请输入单位" },
                model: {
                  value: _vm.product.unit,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.product,
                      "unit",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "product.unit",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { "has-feedback": "", label: "订单数量", prop: "amount" },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入订单数量" },
                model: {
                  value: _vm.product.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "amount", _vm._n($$v))
                  },
                  expression: "product.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "has-feedback": "",
                label: "单价（无税）",
                prop: "taxFreePrice",
              },
            },
            [
              _c("a-input-number", {
                attrs: {
                  precision: 2,
                  min: 0,
                  max: 99999,
                  placeholder: "请输入无税单价",
                },
                model: {
                  value: _vm.product.taxFreePrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "taxFreePrice", $$v)
                  },
                  expression: "product.taxFreePrice",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "has-feedback": "", label: "税率", prop: "taxRate" } },
            [
              _c("a-input-number", {
                attrs: {
                  precision: 2,
                  min: 0,
                  max: 1,
                  step: 0.01,
                  placeholder: "请输入税率",
                },
                model: {
                  value: _vm.product.taxRate,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "taxRate", $$v)
                  },
                  expression: "product.taxRate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "has-feedback": "",
                label: "产值单价",
                prop: "productionPrice",
              },
            },
            [
              _c("a-input-number", {
                attrs: {
                  precision: 2,
                  min: 0,
                  max: 99999,
                  placeholder: "请输入产值价格",
                },
                model: {
                  value: _vm.product.productionPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "productionPrice", $$v)
                  },
                  expression: "product.productionPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "客户物料代码", prop: "clientMaterialCode" } },
            [
              _c("a-input", {
                attrs: {
                  "max-length": "15",
                  placeholder: "请输入客户物料代码",
                },
                model: {
                  value: _vm.product.clientMaterialCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.product,
                      "clientMaterialCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "product.clientMaterialCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "客户计划编码", prop: "clientPlanCode" } },
            [
              _c("a-input", {
                attrs: {
                  "max-length": "15",
                  placeholder: "请输入客户计划编码",
                },
                model: {
                  value: _vm.product.clientPlanCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.product,
                      "clientPlanCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "product.clientPlanCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "客户产品库房代码", prop: "clientWarehouseCode" },
            },
            [
              _c("a-input", {
                attrs: {
                  "max-length": "15",
                  placeholder: "请输入客户库房代码",
                },
                model: {
                  value: _vm.product.clientWarehouseCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.product,
                      "clientWarehouseCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "product.clientWarehouseCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "产品客户需求", prop: "productClientDemand" } },
            [
              _c("a-input", {
                attrs: {
                  "max-length": "250",
                  placeholder: "请输入产品客户需求(不超过250个字)",
                  type: "textarea",
                },
                model: {
                  value: _vm.product.productClientDemand,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.product,
                      "productClientDemand",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "product.productClientDemand",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "comment" } },
            [
              _c("a-input", {
                attrs: {
                  "max-length": "1024",
                  placeholder: "请输入产品备注(不超过1024个字)",
                  type: "textarea",
                },
                model: {
                  value: _vm.product.comment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.product,
                      "comment",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "product.comment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }