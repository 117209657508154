<template>
  <a-modal
    class="modal-container"
    :body-style="bodyStyle"
    :visible="true"
    :title="title"
    :mask-closable="false"
    width="800px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="任务号">
        <span>{{ record.taskCode }}</span>
      </a-form-model-item>
      <a-form-model-item label="缺陷产生日期">
        <span>{{ record.generateTime }}</span>
      </a-form-model-item>
      <a-form-model-item label="产品名称">
        <span>{{ record.productName }}</span>
      </a-form-model-item>
      <a-form-model-item label="图号">
        <span>{{ record.drawingNum }}</span>
      </a-form-model-item>
      <a-form-model-item label="规格">
        <span>{{ record.specification }}</span>
      </a-form-model-item>
      <a-form-model-item label="客户名称">
        <span>{{ record.client }}</span>
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType !== 'TH'" label="检验员">
        <span>{{ record.checker }}</span>
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'FG' || record.defeatType === 'CC'" label="不合格详情">
        <a-input v-model="record.defeatDesc" :disabled="ifCheck" @blur="handleUpdateBaseInfo('defeatDesc')" />
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'FG'" label="返工要求">
        <a-input v-model="record.defeatDemand" :disabled="ifCheck" @blur="handleUpdateBaseInfo('defeatDesc')" />
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'BF'" label="报废品描述">
        <a-input v-model="record.defeatDesc" :disabled="ifCheck" @blur="handleUpdateBaseInfo('defeatDesc')" />
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'BF'" label="材料规格">
        <a-input v-model="record.contactRecord" :disabled="ifCheck" @blur="handleUpdateBaseInfo('contactRecord')" />
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'CC'" label="顾客意见">
        <a-input v-model="record.contactRecord" :disabled="ifCheck" @blur="handleUpdateBaseInfo('contactRecord')" />
      </a-form-model-item>

      <a-form-model-item label="缺陷数量">
        <a-input v-model="record.productAmount" :disabled="ifCheck" @blur="handleUpdateBaseInfo('productAmount')" />
      </a-form-model-item>
      <a-form-model-item label="缺陷详情">
        <c-upload
          :disabled="ifCheck"
          :action="`${baseURL}qualities/${record.id}/files/QX`"
          :file-list="form.defeatDetailInfo"
          accept="image/*, .pdf"
          :before-upload="beforeUpload"
          @change="handleDefectUploadChange"
        >
          <a-button slot="content" :disabled="ifCheck || defeatOutLength">
            <a-icon type="upload" />点击上传
          </a-button>
        </c-upload>
      </a-form-model-item>
      <template v-if="record.defeatType === 'FG'">
        <a-form-model-item label="返工人">
          <a-select
            v-model="form.repairUserInfo"
            :disabled="ifCheck"
            mode="multiple"
            show-search
            placeholder="请选择返工人"
            option-filter-prop="children"
            @change="handleUserSelectChange('FG')"
          >
            <a-select-option v-for="user in currentUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="返工工序">
          <a-select
            v-model="form.repairOperationInfo"
            :disabled="ifCheck"
            mode="multiple"
            show-search
            placeholder="请选择工序"
            option-filter-prop="children"
            @change="handleOperationChange('FG')"
          >
            <a-select-option
              v-for="operation in currentOperationList"
              :key="operation.id"
              :value="operation.id"
            >{{ operation.operationName }}({{ operation.operationCode }})</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="返工效果">
          <a-radio-group v-model="record.repairEffect" :disabled="ifCheck" name="radioGroup" @change="handleUpdateBaseInfo('repairEffect')">
            <a-radio value="OK">合格</a-radio>
            <a-radio value="BF">报废</a-radio>
            <a-radio value="CC">超差</a-radio>
            <a-radio value="RE">再次返修</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </template>
      <a-form-model-item label="缺陷根本原因">
        <a-select
          v-model="form.defeatReasonInfo"
          :disabled="ifCheck"
          mode="multiple"
          show-search
          placeholder="请选择缺陷根本原因"
          option-filter-prop="children"
          @change="handleReasonInfoChange"
        >
          <a-select-option v-for=" reason in allDefeatReasons" :key="reason.id" :value="reason.id">{{ reason.parameterValue }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item v-if="record.defeatType === 'TH'" label="客户反馈描述">
        <a-input v-model="record.contactRecord" :disabled="ifCheck" @blur="handleUpdateBaseInfo('contactRecord')" />
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'TH'" label="核实情况">
        <a-input v-model="record.defeatDesc" :disabled="ifCheck" @blur="handleUpdateBaseInfo('defeatDesc')" />
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'TH'" label="处置方式" prop="repairEffect">
        <a-select v-model="record.repairEffect" :disabled="ifCheck" style="width: 300px" @change="handleUpdateBaseInfo('repairEffect')">
          <a-select-option value="FX">返修</a-select-option>
          <a-select-option value="BT">报废补投</a-select-option>
          <a-select-option value="TH">退回客户</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'TH' && record.repairEffect === 'FX'" label="返修要求">
        <a-input v-model="record.defeatDemand" :disabled="ifCheck" style="width: 300px" @blur="handleUpdateBaseInfo('defeatDemand')" />
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'TH' && record.repairEffect === 'FX'" label="返修工序">
        <a-select
          v-model="form.repairOperationInfo"
          :disabled="ifCheck"
          show-search
          mode="multiple"
          placeholder="请选择返修工序"
          option-filter-prop="children"
          @change="handleOperationChange('FG')"
        >
          <a-select-option
            v-for="operation in currentOperationList"
            :key="operation.id"
            :value="operation.id"
          >{{ operation.operationName }}({{ operation.operationCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'TH'&& record.repairEffect === 'FX'" label="返工效果">
        <a-radio-group v-model="record.actionEffect" :disabled="ifCheck" name="radioGroup" @change="handleUpdateBaseInfo('actionEffect')">
          <a-radio value="OK">合格</a-radio>
          <a-radio value="BF">报废</a-radio>
          <a-radio value="CC">超差</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'CC'" label="客户">
        <a-input v-model="record.relatedClient" :disabled="ifCheck" style="width: 300px" @blur="handleUpdateBaseInfo('relatedClient')" />
      </a-form-model-item>
      <a-form-model-item v-if="record.defeatType === 'CC'" label="沟通人">
        <a-input v-model="record.relatedStaff" :disabled="ifCheck" style="width: 300px" @blur="handleUpdateBaseInfo('relatedStaff')" />
      </a-form-model-item>
      <a-form-model-item label="责任人">
        <a-select
          v-model="form.responsibleUserInfo"
          :disabled="ifCheck"
          mode="multiple"
          show-search
          placeholder="请选择缺陷责任人"
          option-filter-prop="children"
          :filter-option="filterUser"
          @change="handleUserSelectChange('ZR')"
        >
          <a-select-option v-for="user in currentUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="责任工序">
        <a-select
          v-model="form.responsibleOperationInfo"
          :disabled="ifCheck"
          mode="multiple"
          show-search
          placeholder="请选择缺陷责任工序"
          option-filter-prop="children"
          @change="handleOperationChange('ZR')"
        >
          <a-select-option
            v-for="operation in currentOperationList"
            :key="operation.id"
            :value="operation.id"
          >{{ operation.operationName }}({{ operation.operationCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <template v-if="record.defeatType === 'CC'">
        <a-form-model-item label="客户沟通记录">
          <a-textarea v-model="record.contactRecord" :disabled="ifCheck" :max-length="1000" @blur="handleUpdateBaseInfo('contactRecord')" />
        </a-form-model-item>
      </template>
      <a-form-model-item label="是否需要采取预防措施">
        <a-radio-group v-model="record.hasAction" :disabled="ifCheck" name="radioGroup" @change="handleUpdateBaseInfo('hasAction')">
          <a-radio value="Y">需要</a-radio>
          <a-radio value="N">不需要</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="record.hasAction==='Y'" label="预防措施详情">
        <c-upload
          :disabled="ifCheck"
          :action="`${baseURL}qualities/${record.id}/files/CS`"
          :file-list="form.actionDetailInfo"
          accept="image/*, .pdf"
          :before-upload="beforeUpload"
          @change="handleActionUploadChange"
        >
          <a-button slot="content" :disabled="ifCheck || actionOutLength">
            <a-icon type="upload" />点击上传
          </a-button>
        </c-upload>
      </a-form-model-item>
      <a-form-model-item v-if="record.hasAction==='Y'" label="措施负责人">
        <a-select
          v-model="record.actionChargerId"
          :disabled="ifCheck"
          show-search
          placeholder="请选择措施负责人"
          option-filter-prop="children"
          :filter-option="filterUser"
          @change="handleUpdateBaseInfo('actionChargerId')"
        >
          <a-select-option v-for="user in currentUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="record.hasAction==='Y'" label="措施效果">
        <a-input
          v-model="record.actionEffect"
          :disabled="ifCheck"
          :max-length="20"
          placeholder="请输入措施效果"
          @blur="handleUpdateBaseInfo('actionEffect')"
        />
      </a-form-model-item>
      <a-form-model-item label="备注">
        <a-textarea
          v-model="record.comment"
          :disabled="ifCheck"
          :max-length="1000"
          placeholder="请输入备注"
          @blur="handleUpdateBaseInfo('comment')"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {
  updateQualityUserInfo,
  updateQualityOperationInfo,
  updateQualityReasonInfo,
  updateQualityBaseInfo,
  getQualityById,
  getFileView,
  deleteFile,
} from '@/api/qualities'
import { getAllUsers } from '@/api/user'
import { getAllParametersByParentCode } from '@/api/parameter'
import { getAllOperations } from '@/api/operation'
import * as R from 'ramda'

export default {
  props: {
    record: Object,
    type: String,
  },
  data() {
    return {
      title: '',
      ifCheck: false,
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      bodyStyle: {
        maxHeight: '700px',
        overflowY: 'scroll',
      },
      loading: false,
      baseURL: '',
      defectFilesList: [],
      preventiveFilesList: [],
      form: {
        productAmount: this.record.productAmount,
        defeatDesc: this.record.defeatDesc,
        defeatDemand: this.record.defeatDemand,
        contactRecord: this.record.contactRecord,
      },
      rules: {
        checkerId: [
          {
            required: true,
            message: '请输入检验员',
            trigger: 'blur',
          },
        ],
        checkResult: [
          {
            required: true,
            message: '请输入结论',
            trigger: 'blur',
          },
        ],
      },
      currentUserList: [],
      currentOperationList: [],
      allDefeatReasons: [],
      defeatOutLength: false,
      actionOutLength: false,
    }
  },
  mounted() {
    if (this.type === 'update') {
      this.title = '更新质量记录'
    } else if (this.type === 'check') {
      this.ifCheck = true
      this.title = '查看质量记录详情'
    }
    this.baseURL = process.env.VUE_APP_BASE_API
    this.getAllReasons()
    this.getAllUsers()
    this.getAllOperations()
    this.getQuality()
  },
  methods: {
    filterUser(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getAllUsers() {
      getAllUsers(this.userQueryParams).then((res) => {
        if (res.data && res.data.accountList) {
          if (R.isEmpty(this.userQueryParams)) {
            this.allUserList = R.clone(res.data.accountList)
          }
          this.currentUserList = R.clone(res.data.accountList)
        }
      })
    },
    getAllOperations() {
      getAllOperations(this.operationQueryParams).then((res) => {
        if (res.data.operationList) {
          if (R.isEmpty(this.operationQueryParams)) {
            this.allOperationList = R.clone(res.data.operationList)
          }
          this.currentOperationList = R.clone(res.data.operationList)
        }
      })
    },
    getAllReasons() {
      getAllParametersByParentCode('RT_QXYY').then((res) => {
        if (res.data) {
          this.allDefeatReasons = res.data
        }
      })
    },
    getQuality() {
      getQualityById(this.record.id).then((res) => {
        this.form = {
          ...this.form,
          ...res.data,
        }
        if (
          this.form.defeatDetailInfo !== null &&
          this.form.defeatDetailInfo.length
        ) {
          this.form.defeatDetailInfo.map(async (val) => {
            val.uid = val.id
            val.name = val.fileName
            val.url = `${this.baseURL}qualities/files/${val.id}`
          })
          if (this.form.defeatDetailInfo.length >= 5) {
            this.defeatOutLength = true
          }
        }
        if (
          this.form.actionDetailInfo !== null &&
          this.form.actionDetailInfo.length
        ) {
          this.form.actionDetailInfo.map(async (val) => {
            val.uid = val.id
            val.name = val.fileName
            val.url = `${this.baseURL}qualities/files/${val.id}`
          })
          if (this.form.actionDetailInfo.length >= 5) {
            this.actionOutLength = true
          }
        }
        if (
          this.form.repairUserInfo !== null &&
          this.form.repairUserInfo.length
        ) {
          const arr = []
          this.form.repairUserInfo.map((val) => {
            arr.push(val.id)
          })
          this.form.repairUserInfo = arr
        }
        if (
          this.form.responsibleUserInfo !== null &&
          this.form.responsibleUserInfo.length
        ) {
          const arr = []
          this.form.responsibleUserInfo.map((val) => {
            arr.push(val.id)
          })
          this.form.responsibleUserInfo = arr
        }
        if (
          this.form.repairOperationInfo !== null &&
          this.form.repairOperationInfo.length
        ) {
          const arr = []
          this.form.repairOperationInfo.map((val) => {
            arr.push(val.id)
          })
          this.form.repairOperationInfo = arr
        }
        if (
          this.form.responsibleOperationInfo !== null &&
          this.form.responsibleOperationInfo.length
        ) {
          const arr = []
          this.form.responsibleOperationInfo.map((val) => {
            arr.push(val.id)
          })
          this.form.responsibleOperationInfo = arr
        }
        if (
          this.form.defeatReasonInfo !== null &&
          this.form.defeatReasonInfo.length
        ) {
          const arr = []
          this.form.defeatReasonInfo.map((val) => {
            arr.push(val.id)
          })
          this.form.defeatReasonInfo = arr
        }
      })
    },
    getFileView(id) {
      return new Promise((resolve, reject) => {
        getFileView(id).then((res) => {
          console.log(res)
          resolve(res)
        })
      })
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    beforeUpload(file, fileList) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'application/pdf'
      if (!isJpgOrPng) {
        this.$message.error('请上传图片或PDF文件')
        fileList.pop()
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('文件最大仅支持5M')
        fileList.pop()
      }
      return isJpgOrPng && isLt5M
    },
    handleDefectUploadChange(info) {
      console.log(info)
      const fileList = [...info.fileList]
      if (info.fileList.length >= 5) {
        this.defeatOutLength = true
      } else {
        this.defeatOutLength = false
      }
      if (info.file.status === 'removed') {
        const id = info.file.id || info.file.response.id
        deleteFile(id).then((res) => {
          return res
        })
      }
      this.form.defeatDetailInfo = fileList
    },
    handleActionUploadChange(info) {
      console.log(info)
      const fileList = [...info.fileList]
      if (info.fileList.length >= 5) {
        this.actionOutLength = true
      } else {
        this.actionOutLength = false
      }
      if (info.file.status === 'removed') {
        const id = info.file.id || info.file.response.id
        deleteFile(id).then((res) => {
          return res
        })
      }
      this.form.actionDetailInfo = fileList
    },
    handleUserSelectChange(type) {
      const user =
        type === 'FG' ? this.form.repairUserInfo : this.form.responsibleUserInfo
      updateQualityUserInfo(this.record.id, type, user).then((res) => {
        console.log(res)
      })
    },
    handleOperationChange(type) {
      const operation =
        type === 'FG' || type === 'TH'
          ? this.form.repairOperationInfo
          : this.form.responsibleOperationInfo
      updateQualityOperationInfo(this.record.id, type, operation).then(
        (res) => {
          console.log(res)
        }
      )
    },
    handleReasonInfoChange() {
      console.log(this.form.defeatReasonInfo)
      updateQualityReasonInfo(this.record.id, this.form.defeatReasonInfo).then(
        (res) => {
          return res
        }
      )
    },
    handleAbandonReasonChange() {},
    handleUpdateBaseInfo(paramName) {
      const data = {
        [paramName]: this.record[paramName],
      }
      updateQualityBaseInfo(this.record.id, data).then((res) => {
        console.log(res)
      })
    },
    handleConfirm() {
      if (this.ifCheck) {
        this.$emit('cancel')
      } else {
        this.$emit('confirm')
      }
    },
    handleCancel() {
      if (this.ifCheck) {
        this.$emit('cancel')
      } else {
        this.$emit('confirm')
      }
    },
  },
}
</script>

<style scoped>
.modal-container {
  height: 400px;
}
/* /deep/ .ant-modal-content {
  overflow-y: scroll;
  height: 800px;
} */
/deep/ .ant-form-item {
  margin-bottom: 2px;
}
</style>
