/* eslint-disable max-classes-per-file */
import { ColCell, CornerCell, DataCell, RowCell } from '@antv/s2'

class CustomCornerCell extends CornerCell {
  getTextStyle() {
    const defaultTextStyle = super.getTextStyle()
    if (
      this.meta.field === 'productName' ||
      this.meta.field === 'productType' ||
      this.meta.field === 'productWeight'
    ) {
      return {
        ...defaultTextStyle,
        textAlign: 'center',
        fontSize: 20,
      }
    }

    if (this.meta.field === 'totalCost') {
      return {
        ...defaultTextStyle,
        textAlign: 'center',
        fontSize: 16,
      }
    }

    if (this.meta.label === '项目' || this.meta.label === '构成') {
      return {
        ...defaultTextStyle,
        textAlign: 'center',
      }
    }

    return super.getTextStyle()
  }
}

class CustomColCell extends ColCell {
  getTextStyle() {
    // console.log(this.meta)
    const defaultTextStyle = super.getTextStyle()
    return {
      ...defaultTextStyle,
      textAlign: 'center',
    }
    // 使用默认处理
    return super.getTextStyle()
  }
}

class CustomDataCell extends DataCell {
  getTextStyle() {
    const defaultTextStyle = super.getTextStyle()

    return {
      ...defaultTextStyle,
      textAlign: 'center',
    }
  }
}

class CustomRowCell extends RowCell {
  getTextStyle() {
    const defaultTextStyle = super.getTextStyle()
    // 指定列
    // if (this.meta.label === '制造成本' || this.meta.label === '期间费用') {
    //   return {
    //     ...defaultTextStyle,
    //     textAlign: 'right',
    //   }
    // }
    return {
      ...defaultTextStyle,
      textAlign: 'center',
    }
  }
}

export { CustomCornerCell, CustomColCell, CustomDataCell, CustomRowCell }
