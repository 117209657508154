export const inventoryColumns = [
  {
    title: '计划编码',
    width: 150,
    dataIndex: 'planCode',
    key: 'plan.code',
    align: 'center',
    sorter: true,
    fixed: 'left',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'planCode',
    },
  },
  {
    title: '任务编码',
    width: 150,
    dataIndex: 'taskCode',
    key: 'task.code',
    align: 'center',
    sorter: true,
    fixed: 'left',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'taskCode',
    },
  },
  {
    title: '对账单号',
    width: 170,
    dataIndex: 'settlementNumber',
    key: 'settlement.settlementNumber',
    align: 'center',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'settlementNumber',
    },
  },
  {
    title: '对账状态',
    width: 130,
    dataIndex: 'settleStatus',
    key: 'settlement.settleStatus',
    align: 'center',
    scopedSlots: {
      customRender: 'settleStatus',
    },
  },
  {
    title: '出库单号',
    width: 160,
    dataIndex: 'takeOutNumber',
    key: 'inOutRecord.takeOutNumber',
    align: 'center',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'takeOutNumber',
    },
  },
  {
    title: '出库时间',
    width: 170,
    dataIndex: 'takeOutTime',
    key: 'inOutRecord.takeOutTime',
    align: 'center',
    sorter: true,
    ellipsis: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },

  {
    title: '产品名称',
    width: 150,
    dataIndex: 'productName',
    key: 'product.productName',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'productName',
    },
  },
  {
    title: '图号',
    width: 200,
    dataIndex: 'drawingNum',
    key: 'product.drawingNum',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'drawingNum',
    },
  },
  {
    title: '规格',
    width: 150,
    ellipsis: true,
    dataIndex: 'specification',
    key: 'product.specification',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'specification',
    },
  },
  {
    title: '单位',
    width: 100,
    dataIndex: 'unit',
    key: 'unit',
    align: 'center',
  },
  {
    title: '数量',
    width: 100,
    dataIndex: 'waitSettleCount',
    key: 'waitSettleCount',
    align: 'center',
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'waitSettleCount',
    },
  },
  {
    title: '单价(无税)',
    width: 150,
    dataIndex: 'settleUnitPrice',
    key: 'settleUnitPrice',
    align: 'center',
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '金额(无税)',
    width: 150,
    dataIndex: 'totalTaxFreePrice',
    key: 'totalTaxFreePrice',
    align: 'center',
  },
  {
    title: '税率',
    width: 100,
    dataIndex: 'settleTaxRate',
    key: 'settleTaxRate',
    align: 'center',
  },

  {
    title: '单价(含税)',
    width: 150,
    dataIndex: 'taxPrice',
    key: 'taxPrice',
    align: 'center',
  },
  {
    title: '金额(含税)',
    width: 150,
    dataIndex: 'totalTaxPrice',
    key: 'totalTaxPrice',
    align: 'center',
    sorter: true,
  },
  {
    title: '客户名称',
    width: 150,
    dataIndex: 'client',
    key: 'productOrder.client',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'client',
    },
  },
  {
    title: '订单编码',
    width: 150,
    dataIndex: 'orderCode',
    key: 'productOrder.orderCode',
    align: 'center',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'orderCode',
    },
  },
  {
    title: '客户物料代码',
    width: 170,
    dataIndex: 'clientMaterialCode',
    key: 'product.clientMaterialCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientMaterialCode',
    },
  },
  {
    title: '客户计划编号',
    width: 170,
    dataIndex: 'clientPlanCode',
    key: 'product.clientPlanCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientPlanCode',
    },
  },
  {
    title: '客户合同编码',
    width: 170,
    dataIndex: 'clientContractCode',
    key: 'productOrder.clientContractCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientContractCode',
    },
  },
  {
    title: '客户采购订单编码',
    width: 200,
    dataIndex: 'clientPurchaseOrderCode',
    key: 'productOrder.clientPurchaseOrderCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientPurchaseOrderCode',
    },
  },
  {
    title: '客户订单编码',
    width: 170,
    dataIndex: 'clientOrderCode',
    key: 'productOrder.clientOrderCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientOrderCode',
    },
  },
  {
    title: '客户产品库房号码',
    width: 200,
    dataIndex: 'clientWarehouseCode',
    key: 'product.clientWarehouseCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientWarehouseCode',
    },
  },
  {
    title: '是否分件',
    width: 130,
    dataIndex: 'isParts',
    key: 'plan.isParts',
    align: 'center',
    scopedSlots: {
      customRender: 'isParts',
    },
  },
  {
    title: '库房类型',
    width: 150,
    dataIndex: 'recordWarehouseType',
    key: 'inOutRecord.recordWarehouseType',
    align: 'center',
    ellipsis: true,
    scopedSlots: {
      customRender: 'warehouseType',
    },
  },
  {
    title: '备注',
    width: 150,
    dataIndex: 'settleComment',
    key: 'settleComment',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '操作',
    width: 80,
    fixed: 'right',
    key: 'operation',
    align: 'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

export const inventoryRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({
    props: {
      disabled: !!record.settlementNumber,
    },
  }),
}
