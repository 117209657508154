<template>
  <a-modal :visible="true" :title="modalTitle + '任务'" :mask-closable="false" width="700px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleUpdateTask">{{ modalTitle }}</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item ref="code" label="任务编号" prop="code">
        <a-input v-model.trim="form.code" max-length="100" placeholder="请输入任务编号" />
      </a-form-model-item>
      <a-form-model-item label="任务类型" prop="type">
        <a-select v-model="form.type" disabled style="width: 200px">
          <a-select-option :value="0">普通任务</a-select-option>
          <a-select-option :value="4">普通补投任务</a-select-option>
          <a-select-option :value="1">返修任务</a-select-option>
          <a-select-option :value="2">生产补投任务</a-select-option>
          <a-select-option :value="3">协作任务</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.type===1&&isAdd" label="退货原因" prop="abandonReason">
        <a-select v-model="form.abandonReason" default-value="1" style="width: 200px">
          <a-icon slot="suffixIcon" type="smile" />
          <a-select-option :value="1">客户因素</a-select-option>
          <a-select-option :value="3">错漏检问题</a-select-option>
          <a-select-option :value="4">工艺技术问题</a-select-option>
          <a-select-option :value="5">检验误差问题</a-select-option>
          <a-select-option :value="6">生产制造因素</a-select-option>
          <a-select-option :value="8">外协厂家因素</a-select-option>
          <a-select-option :value="9">其他</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="实发材料" prop="actualUseMaterial">
        <a-select
          mode="multiple"
          v-model="actualUseMaterial"
          :loading="checkLoading"
          show-search
          :default-active-first-option="false"
          placeholder="请选择实发材料"
          style="width: 300px"
          option-filter-prop="children"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @focus="handleFetchALlMaterial"
          @search="fetchMaterialByCode"
          @select="(value, option) => handleMaterialNameChange(value, option)"
          @deselect="(value, option) => handleMaterialNameChange(value, option)"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option
            v-for="material in materials"
            :key="material.id"
          >{{ material.innerCode }} {{ material.materialName }}({{ material.materialCode }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item has-feedback label="投产数量" prop="productionNum">
        <a-input v-model.number="form.productionNum" />
      </a-form-model-item>
      <a-form-model-item label="期望下料时间" prop="expectMaterialTime">
        <a-date-picker
          v-model="form.expectMaterialTime"
          style="width: 300px"
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          type="date"
          placeholder="请选择期望下料时间"
        />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="comment">
        <a-input v-model.trim="form.comment" max-length="1024" placeholder="请输入任务备注(不超过1024个字)" type="textarea" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updateTask } from '@/api/task'
import { deepClone } from '@/utils'
import { notification } from 'ant-design-vue'
import debounce from 'lodash/debounce'
import Moment from 'moment'
import {
  getMaterialByMaterialCode,
  getAllMaterialByMaterialCode
} from '@/api/material'

export default {
  props: {
    modalTitle: {
      type: String,
      default: '更新'
    },
    taskForm: {
      type: Object,
      required: true
    }
  },
  data() {
    this.fetchMaterialByCode = debounce(this.fetchMaterialByCode, 800)

    const checkNumber = (rule, value, callback) => {
      if (value !== 0 && !value) {
        return callback(new Error('请输入数量'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字'))
      } else if (value <= 0 || value > 999999) {
        callback(new Error('请输入不超过999999的正整数'))
      } else {
        callback()
      }
    }
    return {
      labelCol: {
        span: 5,
        offset: 2
      },
      wrapperCol: {
        span: 10,
        offset: 1
      },
      actualUseMaterial: [],
      loading: false,
      form: {},
      rules: {
        code: [
          {
            required: true,
            message: '请输入任务编号',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择类型',
            trigger: 'change'
          }
        ],
        abandonReason: [
          {
            required: true,
            message: '请选择一种原因',
            trigger: 'change'
          }
        ],
        productionNum: [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }
        ]
      },
      lastFetchId: 0,
      materials: {},
      fetching: false
    }
  },
  created() {
    if (
      this.taskForm.actualUseMaterialList &&
      this.taskForm.actualUseMaterialList.length
    ) {
      this.actualUseMaterial = this.taskForm.actualUseMaterialList.map(item => {
        return item.id
      })
    }
    this.form = this.taskForm
    this.handleFetchALlMaterial()
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$parent.handleEditCancel()
    },
    handleUpdateTask() {

      if (this.form.expectMaterialTime) {
            this.form.expectMaterialTime = Moment(
              this.form.expectMaterialTime
            ).format('YYYY-MM-DD HH:mm:ss')
      }
      const task = deepClone(this.form)
      const { id } = task

      delete task.updateTime
      delete task.createTime


      this.loading = true

      updateTask(id, task)
        .then(res => {
          if (res.data) {
            const successMessage = this.modalTitle + '任务成功！'
            const failMessage = this.modalTitle + '任务失败，请重试！'
            this.loading = false
            this.$message.success(successMessage)
            this.$parent.handleEditCancel()
            this.$parent.getTasksByParams()
          } else {
            this.loading = false
            this.$message.error(successMessage)
          }
        })
        .catch(reason => {
          this.loading = false
          console.log('处理失败的 promise (' + reason + ')')
        })
    },
    fetchMaterialByCode(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      if (value) {
        getMaterialByMaterialCode(value).then(result => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
          }
          this.materials = result.data
          this.fetching = false
        })
      } else {
        getAllMaterialByMaterialCode().then(result => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
          }
          this.materials = result.data
          this.fetching = false
        })
      }
    },
    handleMaterialNameChange(id, value) {
      const _actualUseMaterial = []
      this.materials.map(val => {
        if (this.actualUseMaterial.indexOf(val.id) !== -1) {
          _actualUseMaterial.push(val)
        }
      })
      this.form.actualUseMaterialList = _actualUseMaterial
    },
    handleFetchALlMaterial() {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      getAllMaterialByMaterialCode().then(result => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.materials = result.data
        this.fetching = false
      })
    }
  }
}
</script>

<style>
.searchFilterIcon {
  position: absolute;
  top: 10px;
  right: 0;
  padding-right: 5px;
  padding-left: 15px;
  padding-bottom: 20px;
}

.searchFilterIcon ~ .el-table__column-filter-trigger {
  display: none;
}

.searchButton {
  width: 90px;
  margin-right: 8px;
}

.resetButton {
  width: 90px;
}

.searchInput {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}
</style>
