var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.title,
        "mask-closable": false,
        width: "1500px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                disabled: !_vm.selectedRowKeys.length,
                loading: _vm.submitLoading,
              },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("div", { staticClass: "top-tip" }, [
            _c("div", { staticClass: "selected-wrapper" }, [
              _vm.hasSelected
                ? _c("span", [
                    _vm._v(" 已选中 "),
                    _c("strong", [_vm._v(_vm._s(_vm.selectedRowKeys.length))]),
                    _vm._v(" 项任务 "),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("a-table", {
            key: _vm.tableKey,
            attrs: {
              "row-key": "taskId",
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              },
              bordered: "",
              scroll: { x: 2000, y: 630 },
              columns: _vm.taskColumns,
              "data-source": _vm.allTaskList,
              loading: _vm.fetchLoading,
              pagination: _vm.pagination,
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u(
              [
                {
                  key: "isParts",
                  fn: function (text) {
                    return _c(
                      "div",
                      {},
                      [
                        text === 0
                          ? [
                              _c("a-tag", { attrs: { color: "red" } }, [
                                _vm._v("否"),
                              ]),
                            ]
                          : _vm._e(),
                        text === 1
                          ? [
                              _c("a-tag", { attrs: { color: "green" } }, [
                                _vm._v("是"),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  },
                },
                {
                  key: "code",
                  fn: function (text, column) {
                    return _c(
                      "div",
                      {},
                      [
                        _vm.memberId && column.targetId
                          ? [
                              _c(
                                "a-badge",
                                {
                                  attrs: {
                                    count: "周目标",
                                    offset: [10, -10],
                                    "number-style": {
                                      fontSize: "12px",
                                      backgroundColor: "#fff",
                                      color: "red",
                                      boxShadow: "0 0 0 1px #d9d9d9 inset",
                                    },
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(text))])]
                              ),
                            ]
                          : [_vm._v(_vm._s(text))],
                      ],
                      2
                    )
                  },
                },
                {
                  key: "emergencyType",
                  fn: function (text) {
                    return _c(
                      "span",
                      {},
                      [
                        _c("a-tag", { attrs: { color: "volcano" } }, [
                          _vm._v(_vm._s(_vm.EmergencyType[text])),
                        ]),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "filterDropdown",
                  fn: function (ref) {
                    var setSelectedKeys = ref.setSelectedKeys
                    var selectedKeys = ref.selectedKeys
                    var confirm = ref.confirm
                    var clearFilters = ref.clearFilters
                    var column = ref.column
                    return _c(
                      "div",
                      { staticStyle: { padding: "8px" } },
                      [
                        column.searchType === "text"
                          ? [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "ant-ref",
                                    rawName: "v-ant-ref",
                                    value: function (c) {
                                      return (_vm.searchInput = c)
                                    },
                                    expression: "c => (searchInput = c)",
                                  },
                                ],
                                staticStyle: {
                                  width: "188px",
                                  "margin-bottom": "8px",
                                  display: "block",
                                },
                                attrs: {
                                  placeholder: "搜索 " + column.title,
                                  value: selectedKeys[0],
                                },
                                on: {
                                  change: function (e) {
                                    return setSelectedKeys(
                                      e.target.value ? [e.target.value] : []
                                    )
                                  },
                                  pressEnter: function () {
                                    return _vm.handleSearch(
                                      selectedKeys,
                                      confirm,
                                      column.key
                                    )
                                  },
                                },
                              }),
                            ]
                          : column.searchType === "number"
                          ? _c(
                              "div",
                              [
                                _c("a-input-number", {
                                  staticStyle: {
                                    width: "95px",
                                    "margin-bottom": "8px",
                                    display: "inline-block",
                                  },
                                  attrs: {
                                    size: "small",
                                    placeholder: "最小值",
                                    min: 0,
                                    max: 999999,
                                  },
                                  on: {
                                    change: function (e) {
                                      return setSelectedKeys([
                                        e,
                                        selectedKeys[1],
                                      ])
                                    },
                                    pressEnter: function () {
                                      return _vm.handleSearch(
                                        selectedKeys,
                                        confirm,
                                        column.key
                                      )
                                    },
                                  },
                                  model: {
                                    value: selectedKeys[0],
                                    callback: function ($$v) {
                                      _vm.$set(selectedKeys, 0, $$v)
                                    },
                                    expression: "selectedKeys[0]",
                                  },
                                }),
                                _c("span", [_vm._v("-")]),
                                _c("a-input-number", {
                                  staticStyle: {
                                    width: "95px",
                                    "margin-bottom": "8px",
                                    display: "inline-block",
                                  },
                                  attrs: {
                                    size: "small",
                                    placeholder: "最大值",
                                    min: selectedKeys[0],
                                    max: 999999,
                                  },
                                  on: {
                                    change: function (e) {
                                      return setSelectedKeys([
                                        selectedKeys[0],
                                        e,
                                      ])
                                    },
                                    pressEnter: function () {
                                      return _vm.handleSearch(
                                        selectedKeys,
                                        confirm,
                                        column.key
                                      )
                                    },
                                  },
                                  model: {
                                    value: selectedKeys[1],
                                    callback: function ($$v) {
                                      _vm.$set(selectedKeys, 1, $$v)
                                    },
                                    expression: "selectedKeys[1]",
                                  },
                                }),
                              ],
                              1
                            )
                          : column.searchType === "date"
                          ? _c(
                              "div",
                              [
                                _c("a-date-picker", {
                                  staticStyle: {
                                    width: "200px",
                                    "margin-bottom": "8px",
                                  },
                                  attrs: { size: "small" },
                                  on: {
                                    change: function (dates, dateStrings) {
                                      return setSelectedKeys(
                                        [].concat(dateStrings)
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "a-button",
                          {
                            staticStyle: {
                              width: "90px",
                              "margin-right": "8px",
                            },
                            attrs: {
                              type: "primary",
                              icon: "search",
                              size: "small",
                            },
                            on: {
                              click: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                        _c(
                          "a-button",
                          {
                            staticStyle: { width: "90px" },
                            attrs: { size: "small" },
                            on: {
                              click: function () {
                                return _vm.handleReset(
                                  selectedKeys,
                                  clearFilters,
                                  column.key
                                )
                              },
                            },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      2
                    )
                  },
                },
                {
                  key: "filterIcon",
                  fn: function (filtered) {
                    return _c("a-icon", {
                      style: { color: filtered ? "#108ee9" : undefined },
                      attrs: { type: "search" },
                    })
                  },
                },
                {
                  key: "operation",
                  fn: function (text, column) {
                    return column.status === 2
                      ? _c(
                          "span",
                          { staticClass: "table-operation" },
                          [
                            _c(
                              "a-dropdown",
                              { attrs: { placement: "bottomRight" } },
                              [
                                _c(
                                  "a-menu",
                                  {
                                    staticClass: "ant-dropdown-link",
                                    attrs: { slot: "overlay" },
                                    slot: "overlay",
                                  },
                                  [
                                    _c(
                                      "a-menu-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEditEmergencyType(
                                              column
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("设置紧急类型")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a",
                                  { staticClass: "operation-btn" },
                                  [
                                    _vm._v(" 操作 "),
                                    _c("a-icon", { attrs: { type: "down" } }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e()
                  },
                },
                {
                  key: "fileName",
                  fn: function (text, column) {
                    return _c(
                      "div",
                      { staticClass: "file-wrapper" },
                      [
                        column.fileName
                          ? [
                              _c("div", { staticClass: "filename-wrapper" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "file-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDownloadFile(
                                          column.craftId,
                                          column.fileName
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(column.fileName))]
                                ),
                              ]),
                            ]
                          : [_c("span", [_vm._v("暂无工艺文件")])],
                      ],
                      2
                    )
                  },
                },
                {
                  key: "status",
                  fn: function (text) {
                    return _c(
                      "div",
                      {},
                      [
                        text === 0
                          ? [
                              _c("a-badge", { attrs: { status: "default" } }),
                              _vm._v("准备中 "),
                            ]
                          : _vm._e(),
                        text === 1
                          ? [
                              _c("a-badge", { attrs: { status: "default" } }),
                              _vm._v("未开始 "),
                            ]
                          : _vm._e(),
                        text === 2
                          ? [
                              _c("a-badge", {
                                attrs: { status: "processing" },
                              }),
                              _vm._v("进行中 "),
                            ]
                          : _vm._e(),
                        text === 3
                          ? [
                              _c("a-badge", { attrs: { status: "success" } }),
                              _vm._v("已完成 "),
                            ]
                          : _vm._e(),
                        text === 9
                          ? [
                              _c("a-badge", { attrs: { status: "warning" } }),
                              _vm._v("暂停中 "),
                            ]
                          : _vm._e(),
                        text === 8
                          ? [
                              _c("a-badge", { attrs: { status: "error" } }),
                              _vm._v("已取消 "),
                            ]
                          : _vm._e(),
                        text === 10
                          ? [
                              _c("a-badge", { attrs: { status: "error" } }),
                              _vm._v("异常终止 "),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  },
                },
                {
                  key: "process",
                  fn: function (text, column) {
                    return _c(
                      "div",
                      { staticClass: "table-process" },
                      [
                        column.remainProcessRoute &&
                        column.remainProcessRoute.length
                          ? [
                              _c(
                                "a-steps",
                                {
                                  attrs: {
                                    current:
                                      column.remainProcessRoute.length - 1,
                                    status: "finish",
                                    size: "small",
                                  },
                                },
                                _vm._l(
                                  column.remainProcessRoute,
                                  function (process) {
                                    return _c(
                                      "a-step",
                                      {
                                        key: process.id,
                                        attrs: { title: process.operationName },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "process-desc",
                                            attrs: { slot: "description" },
                                            slot: "description",
                                          },
                                          [
                                            process.operationType > 0 &&
                                            process.verifyProcessHour
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "description-font",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("加工工时："),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          process.verifyProcessHour
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            process.operationType > 0 &&
                                            process.verifyTransferOutTime
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "description-font",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("转出日期："),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          process.verifyTransferOutTime
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  },
                },
                {
                  key: "targetNum",
                  fn: function (text, column) {
                    return _c(
                      "div",
                      {},
                      [
                        _vm.editableId === column.taskId
                          ? _c("a-input", {
                              staticStyle: { margin: "-5px 0" },
                              attrs: { value: text },
                              on: {
                                change: function (e) {
                                  return _vm.handleChange(
                                    e.target.value,
                                    _vm.record.key,
                                    _vm.col
                                  )
                                },
                              },
                            })
                          : [_vm._v(_vm._s(column.producingNum))],
                        _c("span", [_vm._v("编辑")]),
                        _c("span", [_vm._v("取消")]),
                      ],
                      2
                    )
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }