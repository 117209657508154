<template>
  <a-select
    style="width: 300px"
    show-search
    :default-active-first-option="false"
    :placeholder="placeholder"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="fetching ? undefined : null"
    @search="debounceFetch"
    @change="(value, option)  => onSelect(value, option)"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
    <a-select-option v-for="item in list" :key="item[searchKey]">{{ onShow(item) }}</a-select-option>
  </a-select>
</template>

<script>
import * as R from 'ramda'
import debounce from 'lodash/debounce'
export default {
  props: {
    searchKey: String,
    searchFormat: Function,
    getList: Function,
    onChange: Function,
    showLabel: Function,
    placeholder: String
  },
  data() {
    this.debounceFetch = debounce(this.onFetch, 800)
    return {
      list: [],
      fetching: false,
      lastFetchId: 0
    }
  },
  methods: {
    onFetch(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.list = []
      this.fetching = true

      const searchParams = this.searchFormat(value)

      this.getList(searchParams)
        .then(result => {
          if (fetchId !== this.lastFetchId) {
            return
          }
          this.list = result.data
          this.fetching = false
        })
        .finally(() => {
          this.fetching = false
        })
    },
    onShow(item) {
      return this.showLabel && this.showLabel(item)
    },
    onSelect(value) {
      let options = ''
      this.list.forEach(item => {
        if (item[this.searchKey] === value) {
          options = item
        }
      })
      this.onChange && this.onChange(value, options)
    }
  }
}
</script>

<style>
</style>