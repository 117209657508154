<template>
  <div class="app-container">
    <a-table
      row-key="id"
      bordered
      class="users-table"
      :columns="
        approvalColumns"
      :data-source="list"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-if="column.searchType===&quot;number&quot;">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px"
            :ranges="{
              今天: [$moment(), $moment()],
              本月: [$moment().startOf('month'), $moment().endOf('month')],
            }"
            @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <div slot="code" slot-scope="text, column">
        <el-link type="primary" @click="handleCheckApprovalDetail(text, column.status)">{{text}}</el-link>
      </div>
      <div slot="status" slot-scope="text">
        <a-tag color="blue" v-if="text === 'CCK'">审价中</a-tag>
        <a-tag color="green" v-if="text === 'AGR'">已通过</a-tag>
        <a-tag color="red" v-if="text === 'RJT'">已驳回</a-tag>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operate" slot-scope="text, column" class="table-user">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleCheckApprovalDetail(column.code)">查看审价单明细</a-menu-item>
          </a-menu>
          <a class="user-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <ApprovalDetailModal
      v-if="approvalDetailModalVisible"
      :code="approvalDetailCode"
      :status="approvalDetailStatus"
      @confirm="handleApprovalDetailModalConfirm"
      @cancel="handleApprovalDetailModalCancel"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { approvalColumns } from './dataLogic'
import { exportAllUsers } from '@/api/user'
import { getApproval } from '@/api/approval'
import { downloadTemplate } from '@/common/templateDownload'
import ApprovalDetailModal from '@/components/ApprovalDetailModal/index.vue'

export default {
  data() {
    return {
      loading: false,
      uploading: false,
      list: [],
      approvalColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentUser: {},
      columnSearchParams: {},
      uploadUrl: process.env.VUE_APP_BASE_API,
      exportLoading: false,
      approvalDetailModalVisible: false,
      approvalDetailCode: '',
      approvalDetailStatus: ''
    }
  },
  components: {
    ApprovalDetailModal
  },
  created() {
    this.getApprovalList(this.initQueryParams)
  },
  methods: {
    getApprovalList(data) {
      this.loading = true
      getApproval(data)
        .then(res => {
          this.list = res.data.records.map((item, index) => {
            item.index =
              (this.currentQueryParams.pageNum - 1) *
                this.currentQueryParams.pageSize +
              index +
              1
            return item
          })
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getApprovalListByParams() {
      const cQueryParams = deepClone(this.currentQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getApprovalList(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentQueryParams.pageNum = pagination.current
      this.currentQueryParams.pageSize = pagination.pageSize
      this.getApprovalListByParams()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      if (dataIndex === 'roles') {
        dataIndex = 'role.name'
      }
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleDownloadTemplate() {
      downloadTemplate('user')
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`批量导入失败，请稍后再试`)
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      this.$info({
        title: '员工信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入数据总计：' + response.totalNum + '条;'),
          h('p', '非法数据条数：' + response.failedNum + ';'),
          h('p', '导入成功数据条数：' + response.successfulNum + ';'),
          h('p', '非法数据信息:'),
          h('p', response.invalidMessages.join(' ||  '))
        ]),
        onOk: () => {
          this.uploading = false
          this.getUsers(this.currentUserListQueryParams)
        }
      })
    },
    handleExportUserCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'ALL': {
          exportAllUsers()
            .then(res => {
              this.downloadInquiry(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        }
        default:
          this.exportLoading = false
          break
      }
    },
    downloadInquiry(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.USER_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleCheckApprovalDetail(code, status) {
      this.approvalDetailModalVisible = true
      this.approvalDetailCode = code
      this.approvalDetailStatus = status
    },
    handleApprovalDetailModalConfirm() {
      this.approvalDetailCode = ''
      this.approvalDetailStatus = ''
      this.approvalDetailModalVisible = false
      this.getApprovalListByParams()
    },
    handleApprovalDetailModalCancel() {
      this.approvalDetailCode = ''
      this.approvalDetailStatus = ''
      this.approvalDetailModalVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
}
</style>
