<template>
  <a-modal :visible="visible" width="700px" :title="`${modalTitle}工艺`" :mask-closable="false" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" class="craft-form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item class="craft-form-item" label="工艺文件编号" prop="craft.craftCode">
        <a-input v-model.trim="form.craft.craftCode" max-length="30" placeholder="请输入工艺文件编号" />
      </a-form-model-item>
      <a-form-model-item class="craft-form-item" label="工艺产品名称" prop="craft.craftProductName">
        <a-input v-model.trim="form.craft.craftProductName" max-length="30" placeholder="请输入工艺产品名称" />
      </a-form-model-item>
      <a-form-model-item class="craft-form-item" label="工艺产品图号" prop="craft.craftDrawingNum">
        <a-input v-model.trim="form.craft.craftDrawingNum" max-length="30" placeholder="请输入工艺产品图号" />
      </a-form-model-item>
      <a-form-model-item class="craft-form-item" label="工艺产品规格" prop="craft.craftSpecification">
        <a-input v-model.trim="form.craft.craftSpecification" max-length="30" placeholder="请输入工艺产品规格" />
      </a-form-model-item>
      <a-form-model-item v-if="form.craft.isParts===1" class="craft-form-item" label="计划编码" prop="craft.code">
        <a-input v-model.trim="form.craft.code" max-length="30" placeholder="请输入计划编码" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { notification } from 'ant-design-vue'

import { updateCraft } from '@/api/craft'
import { deepClone } from '@/utils'

export default {
  props: {
    modalTitle: {
      type: String,
      required: true,
      default: '更新',
    },
    craftForm: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      labelCol: {
        span: 5,
        offset: 2,
      },
      wrapperCol: {
        span: 10,
        offset: 1,
      },
      loading: false,
      form: {
        craft: {},
      },
      rules: {
        'craft.craftCode': [
          {
            required: true,
            message: '请输入工艺文件编号',
            trigger: 'change',
          },
        ],
        'craft.craftProductName': [
          {
            required: true,
            message: '请输入工艺产品名称',
            trigger: 'change',
          },
        ],
        'craft.craftSpecification': [
          {
            required: true,
            message: '请输入工艺产品规格',
            trigger: 'change',
          },
        ],
        'craft.code': [
          {
            required: true,
            message: '请输入计划编码',
            trigger: 'change',
          },
        ],
      },
    }
  },
  watch: {
    craftForm(craft) {
      this.form.craft = craft
    },
  },
  methods: {
    designTimeChange(date, dateString) {
      this.form.craft.designTime = dateString
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$parent.handleEditCancel()
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const craft = deepClone(this.form.craft)
          const { id } = craft
          this.loading = true

          updateCraft(id, craft)
            .then(() => {
              notification.success({
                message: '更新工艺成功',
                duration: 3,
              })
            })
            .finally(() => {
              this.loading = false
              this.$parent.handleEditCancel()
              this.$parent.getCraftsByParams()
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.craft-form {
  padding-top: 14px;
  .craft-form-item {
    margin-bottom: 14px;
  }
}
</style>
