export const settlementColumns = [
  {
    title: '对账单号',
    width: 130,
    dataIndex: 'settlementNumber',
    key: 'settlement.settlementNumber',
    align: 'center',
    sorter: true,
    fixed: 'left',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'settlementNumber',
    },
  },
  {
    title: '对账金额(无税)',
    width: 170,
    dataIndex: 'settleMoneyTaxFree',
    key: 'settleMoneyTaxFree',
    align: 'center',
  },
  {
    title: '对账金额(含税)',
    width: 170,
    dataIndex: 'settleMoney',
    key: 'settleMoney',
    align: 'center',
  },
  {
    title: '结算金额(含税)',
    width: 170,
    dataIndex: 'settlePrice',
    key: 'settlePrice',
    align: 'center',
  },
  {
    title: '客户名称',
    width: 150,
    dataIndex: 'client',
    key: 'client',
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '挂账期(天)',
    width: 140,
    dataIndex: 'pendingPeriod',
    key: 'pendingPeriod',
    align: 'center',
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'pendingPeriod',
    },
  },
  {
    title: '项数',
    width: 100,
    dataIndex: 'count',
    key: 'count',
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'count',
    },
  },
  {
    title: '对账状态',
    width: 130,
    dataIndex: 'settleStatus',
    key: 'settleStatus',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'settleStatus',
    },
    filters: [
      {
        text: '预对账',
        value: 1,
      },
      {
        text: '已开票',
        value: 2,
      },
      {
        text: '已结算',
        value: 3,
      },
    ],
  },
  {
    title: '结算凭据号',
    width: 150,
    dataIndex: 'settleReceiptNumbers',
    key: 'settleReceiptNumbers',
    align: 'center',
    // ellipsis: true,
    scopedSlots: {
      customRender: 'settleReceiptNumbers',
    },
  },
  {
    title: '开单时间',
    width: 150,
    dataIndex: 'billTime',
    key: 'billTime',
    align: 'center',
    ellipsis: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'billTime',
    },
  },
  {
    title: '开票时间',
    width: 150,
    dataIndex: 'receiptTime',
    key: 'receiptTime',
    ellipsis: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'receiptTime',
    },
  },
  {
    title: '结算时间',
    width: 150,
    dataIndex: 'settleTime',
    key: 'settleTime',
    align: 'center',
    ellipsis: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'settleTime',
    },
  },

  {
    title: '开单备注',
    width: 150,
    dataIndex: 'billComment',
    key: 'billComment',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '开票备注',
    width: 150,
    dataIndex: 'receiptComment',
    key: 'receiptComment',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '结算备注',
    width: 150,
    dataIndex: 'settleComment',
    key: 'settleComment',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '操作',
    fixed: 'right',
    width: 80,
    key: 'operation',
    align: 'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

export const inventoryRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({
    props: {
      disabled: record.invoiceNumber,
    },
  }),
}
