<template>
  <a-modal class="modal-container" :visible="true" title="选择模板类型" :maskClosable="false" @cancel="handleCancel" width="600px">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="template" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="模板类型" prop="type">
        <a-radio-group v-model="template.type">
          <a-radio value="11">刀具</a-radio>
          <a-radio value="12">量具</a-radio>
          <a-radio value="1">其他</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updateTemplateType } from '@/api/template'
export default {
  props: {
    ids: Array,
    type: String,
  },
  data() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      loading: false,
      template: {
        type: this.type || '',
      },
      rules: {
        type: [
          {
            required: true,
            message: '请选择模板类型',
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          updateTemplateType({
            targetCardType: this.template.type,
            templateIdSet: this.ids,
          })
            .then((res) => {
              this.$emit('confirm')
              this.$message.success('更新成功')
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
