<template>
  <a-modal class="modal-container" visible="true" :title="title" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>

    <a-form-model ref="ruleForm" :model="record" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="客户要求时间" prop="clientDeliveryTime">
        <a-date-picker v-model.trim="record.clientDeliveryTime" show-time value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择客户要求时间" />
      </a-form-model-item>
      <a-form-model-item label="车间反馈时间" prop="workshopTime">
        <a-date-picker v-model.trim="record.workshopTime" show-time value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择车间反馈时间" />
      </a-form-model-item>
      <a-form-model-item label="最终确认时间" prop="finalDeliveryTime">
        <a-date-picker v-model.trim="record.finalDeliveryTime" show-time value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择最终确认时间" />
      </a-form-model-item>
      <a-form-model-item label="完成情况分析" prop="negotiateContent">
        <a-textarea v-model.trim="record.negotiateContent" placeholder="请输入完成情况分析" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {
  addNegotiates,
  updateNegotiates,
  getLatestNegotiates
} from '@/api/emgtask'
export default {
  props: {
    type: String,
    taskId: Number
  },
  data() {
    return {
      title: this.type === 'add' ? '新增协商记录' : '更新协商记录',
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      record: {
        clientDeliveryTime: '',
        workshopTime: '',
        finalDeliveryTime: '',
        negotiateContent: ''
      },
      rules: {
        clientDeliveryTime: [
          {
            required: true,
            message: '请选择客户要求时间',
            trigger: 'change'
          }
        ]
      },
      currentType: []
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function() {
      return this.type === 'add'
    },
    isUpdate: function() {
      return this.type === 'update'
    }
  },
  mounted() {
    if (this.isUpdate) {
      this.handleGetLatestNegotiates()
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleGetLatestNegotiates() {
      getLatestNegotiates(this.taskId).then(res => {
        if (res.data) {
          this.record = res.data
        }
      })
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.add()
          } else if (this.isUpdate) {
            this.update()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    update() {
      this.loading = true
      const _id = this.record.id
      updateNegotiates(_id, this.record)
        .then(res => {
          this.$emit('confirm')
          this.$message.success('更新成功')
        })
        .finally(() => {
          this.loading = false
        })
    },
    add() {
      this.loading = true
      addNegotiates(this.taskId, this.record)
        .then(res => {
          this.$emit('confirm')
          this.$message.success('操作成功')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
</style>
