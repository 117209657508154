import request from '@/utils/api.request'

/**
 * 分页获取工序信息
 * @param {object} data
 */
export function getOperations(data) {
  return request({
    url: `/operations`,
    method: 'get',
    params: data,
  })
}

/**
 * 根据查询参数获取所有工序信息
 * @param {object} data
 */
export function getAllOperations(data) {
  return request({
    url: `/operations/all`,
    method: 'get',
    params: data,
  })
}

/**
 * 根据id获取指定的工序信息
 * @param {number} id
 */
export function getOperationById(id) {
  return request({
    url: `/operations/${id}`,
    method: 'get',
  })
}

/**
 * 增加工序
 * @param {object} data
 */
export function addOperation(data) {
  return request({
    url: `/operations`,
    method: 'post',
    data,
  })
}

/**
 * 更新指定id的工序
 * @param {number} id
 * @param {object} data
 */
export function updateOperation(id, data) {
  return request({
    url: `/operations/${id}`,
    method: 'put',
    data,
  })
}

/**
 * 删除指定id的工序
 * @param {number} id
 */
export function deleteOperation(id) {
  return request({
    url: `/operations/${id}`,
    method: 'delete',
  })
}

/**
 * 获取工序信息导入模板
 */
export function getOperationImportTemplate() {
  return request({
    url: `/operations/import-template`,
    method: 'get',
    responseType: 'blob',
  })
}

/**
 * 导出所有工序
 * @param {*} data 导出工序参数
 */
export function exportAllOperations(data) {
  return request({
    url: `/operations/exports/all`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}

/**
 * 分页获取工序信息
 * @param {object} data
 */
export function getLastestCheckOperations(taskId) {
  return request({
    url: `/records/${taskId}/latest-check-record`,
    method: 'get',
  })
}

/**
 * 模糊搜索
 * @param {object} data
 */
export function fuzzyOperations(data) {
  return request({
    url: `/operations/all-fuzzy`,
    method: 'get',
    params: data,
  })
}
