<template>
  <a-modal class="modal-container" visible="true" :title="title" :maskClosable="false" @cancel="handleCancel" width="600px">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="user" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="员工编号" prop="uid">
        <a-input maxlength="20" v-model.trim="user.uid" placeholder="请输入员工编号" />
      </a-form-model-item>
      <a-form-model-item label="员工名称" prop="realName">
        <a-input maxlength="20" v-model.trim="user.realName" placeholder="请输入员工名称" />
      </a-form-model-item>
      <a-form-model-item label="员工性别" prop="sex">
        <a-radio-group v-model="user.sex">
          <a-radio :value="1">男</a-radio>
          <a-radio :value="2">女</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="phone">
        <a-input maxlength="20" v-model.trim="user.phone" placeholder="请输入联系电话" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updateUser, addUser } from '@/api/user'
import * as R from 'ramda'
export default {
  props: {
    modalTitle: String,
    userForm: Object,
  },
  data() {
    return {
      title: this.modalTitle + '员工',
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      loading: false,
      user: {
        id: this.userForm.id,
        sex: this.userForm.sex,
        uid: this.userForm.uid,
        realName: this.userForm.realName,
        phone: this.userForm.phone,
      },
      rules: {
        sex: [
          {
            required: true,
            message: '请选择员工性别',
            trigger: 'change',
          },
        ],
        uid: [
          {
            required: true,
            message: '请输入员工编号',
            trigger: 'blur',
          },
        ],
        realName: [
          {
            required: true,
            message: '请输入员工名称',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function () {
      return this.modalTitle === '新增'
    },
    isUpdate: function () {
      return this.modalTitle === '更新'
    },
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      console.log(this.user)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addUser(this.user)
          } else if (this.isUpdate) {
            this.updateUser(this.user)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updateUser(userFormData) {
      this.loading = true
      updateUser(userFormData.id, userFormData)
        .then((res) => {
          if (res.data.id) {
            const successMessage = '更新员工成功！'
            const failMessage = '更新员工失败，请重试！'
            this.$emit('confirm')
            this.$message.success(successMessage)
          } else {
            this.$message.error(failMessage)
          }
        })
        .catch(
          // 员工失败原因
          (reason) => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    addUser(userFormData) {
      this.loading = true
      let submitData = R.clone(userFormData)
      submitData.id = null
      addUser(submitData)
        .then((res) => {
          if (res.data.id) {
            this.$message.success('新增员工成功')
            this.$emit('confirm')
          } else {
            this.$message.error('新增员工失败，请重试！')
          }
        })
        .catch(
          // 员工失败原因
          (reason) => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
  },
}
</script>

<style scoped>
</style>
