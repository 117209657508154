var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "selected-wrapper" },
        [
          _vm.hasSelected
            ? [
                _vm._v(
                  " " +
                    _vm._s("已选中" + _vm.selectedRowKeys.length + "项任务") +
                    " "
                ),
              ]
            : _vm._e(),
          _vm.hasSelected
            ? _c(
                "a-dropdown",
                [
                  _c(
                    "a",
                    {
                      staticClass: "batch-operate-link",
                      on: {
                        click: function (e) {
                          return e.preventDefault()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "edit" } }),
                      _vm._v("批量操作 "),
                    ],
                    1
                  ),
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      _c("a-menu-item", [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleCreateCraftCard(
                                  _vm.selectedRowKeys
                                )
                              },
                            },
                          },
                          [_vm._v("批量生成工艺流转卡")]
                        ),
                      ]),
                      _c("a-menu-item", [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleCreatePlanCard(
                                  _vm.selectedRowKeys
                                )
                              },
                            },
                          },
                          [_vm._v("任务车间交接表打印")]
                        ),
                      ]),
                      _c("a-menu-item", [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleBatchEmergencyTypeEdit(
                                  _vm.selectedRowKeys
                                )
                              },
                            },
                          },
                          [_vm._v("设置任务紧急类型")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("a-table", {
        staticClass: "plans-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
          },
          columns: _vm.taskColumns,
          "data-source": _vm.taskList,
          loading: _vm.loading,
          pagination: _vm.pagination,
          scroll: { x: 2700, y: 700 },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "(c) => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.key
                              )
                            },
                          },
                        }),
                      ]
                    : column.searchType === "numberInput"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "ant-ref",
                                rawName: "v-ant-ref",
                                value: function (c) {
                                  return (_vm.searchInput = c)
                                },
                                expression: "(c) => (searchInput = c)",
                              },
                            ],
                            staticStyle: {
                              width: "188px",
                              "margin-bottom": "8px",
                              display: "block",
                            },
                            attrs: {
                              value: selectedKeys[0],
                              placeholder: "搜索 " + column.title,
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys(e ? [e] : [])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "330px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              "show-time": { format: "HH:mm:ss" },
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "type",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0 ? [_c("span", [_vm._v("普通任务")])] : _vm._e(),
                  text === 1 ? [_c("span", [_vm._v("返修任务")])] : _vm._e(),
                  text === 2
                    ? [_c("span", [_vm._v("生产补投任务")])]
                    : _vm._e(),
                  text === 3 ? [_c("span", [_vm._v("协作任务")])] : _vm._e(),
                  text === 4
                    ? [_c("span", [_vm._v("普通补投任务")])]
                    : _vm._e(),
                  text === 5
                    ? [_c("span", [_vm._v("库存转入任务")])]
                    : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "emgType",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === "NML"
                    ? _c("a-tag", { attrs: { color: "volcano" } }, [
                        _vm._v("普通"),
                      ])
                    : _vm._e(),
                  text === "MFN"
                    ? _c("a-tag", { attrs: { color: "cyan" } }, [
                        _vm._v("必完件"),
                      ])
                    : _vm._e(),
                  text === "UGC"
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("紧急件"),
                      ])
                    : _vm._e(),
                  text === "IUG"
                    ? _c("a-tag", { attrs: { color: "purple" } }, [
                        _vm._v("插入急件"),
                      ])
                    : _vm._e(),
                  text === "TUG"
                    ? _c("a-tag", { attrs: { color: "blue" } }, [
                        _vm._v("转入急件"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("准备中 "),
                      ]
                    : _vm._e(),
                  text === 1
                    ? [
                        _c("a-badge", { attrs: { status: "default" } }),
                        _vm._v("未开始 "),
                      ]
                    : _vm._e(),
                  text === 2
                    ? [
                        _c("a-badge", { attrs: { status: "processing" } }),
                        _vm._v("进行中 "),
                      ]
                    : _vm._e(),
                  text === 3
                    ? [
                        _c("a-badge", { attrs: { status: "success" } }),
                        _vm._v("已完成 "),
                      ]
                    : _vm._e(),
                  text === 4
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("超期进行中 "),
                      ]
                    : _vm._e(),
                  text === 5
                    ? [
                        _c("a-badge", { attrs: { status: "warning" } }),
                        _vm._v("即将超期 "),
                      ]
                    : _vm._e(),
                  text === 9
                    ? [
                        _c("a-badge", { attrs: { status: "warning" } }),
                        _vm._v("暂停中 "),
                      ]
                    : _vm._e(),
                  text === 8
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("已取消 "),
                      ]
                    : _vm._e(),
                  text === 10
                    ? [
                        _c("a-badge", { attrs: { status: "error" } }),
                        _vm._v("异常终止 "),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
          {
            key: "deliveryStatus",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === "GREEN"
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("绿单"),
                      ])
                    : _vm._e(),
                  text === "RED"
                    ? _c("a-tag", { attrs: { color: "red" } }, [_vm._v("红单")])
                    : _vm._e(),
                  text === "YELLOW"
                    ? _c("a-tag", { attrs: { color: "orange" } }, [
                        _vm._v("黄单"),
                      ])
                    : _vm._e(),
                  text === "BLACK"
                    ? _c("a-tag", { attrs: { color: "black" } }, [
                        _vm._v("黑单"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "actualUseMaterialList",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-tooltip",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        _vm._l(text, function (item, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              " " +
                                _vm._s(item.materialName) +
                                "(" +
                                _vm._s(item.materialCode) +
                                ") "
                            ),
                            index < text.length - 1
                              ? _c("span", [_vm._v(",")])
                              : _vm._e(),
                          ])
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "table-ellis" },
                        _vm._l(text, function (item, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              _vm._s(item.materialName) +
                                "(" +
                                _vm._s(item.materialCode) +
                                "),"
                            ),
                          ])
                        }),
                        0
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            },
          },
          {
            key: "operation",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-operation" },
                [
                  column.status !== 3 && column.status !== 10
                    ? _c(
                        "a-dropdown",
                        [
                          _c(
                            "a-menu",
                            {
                              staticClass: "ant-dropdown-link",
                              attrs: { slot: "overlay" },
                              slot: "overlay",
                            },
                            [
                              column.status === 2 ||
                              column.status === 4 ||
                              column.status === 5
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCreateCraftCard([
                                            column.id,
                                          ])
                                        },
                                      },
                                    },
                                    [_vm._v("生成工艺流转卡")]
                                  )
                                : _vm._e(),
                              column.status === 2 ||
                              column.status === 4 ||
                              column.status === 5
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdateTask(column)
                                        },
                                      },
                                    },
                                    [_vm._v("更新任务")]
                                  )
                                : _vm._e(),
                              column.status === 2 ||
                              column.status === 4 ||
                              (column.status === 5 && column.isParts === 1)
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.assembleTask(column)
                                        },
                                      },
                                    },
                                    [_vm._v("装配任务")]
                                  )
                                : _vm._e(),
                              column.status === 2 ||
                              column.status === 4 ||
                              column.status === 5
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.batchTask(column)
                                        },
                                      },
                                    },
                                    [_vm._v("任务分批")]
                                  )
                                : _vm._e(),
                              column.status === 0 ||
                              column.status === 1 ||
                              column.status === 2 ||
                              column.status === 4 ||
                              column.status === 5
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.switchTaskStatus(
                                            column,
                                            "c"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消任务")]
                                  )
                                : _vm._e(),
                              column.status === 2 ||
                              column.status === 4 ||
                              column.status === 5
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.switchTaskStatus(
                                            column,
                                            "s"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("暂停任务")]
                                  )
                                : _vm._e(),
                              column.status === 9
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.switchTaskStatus(
                                            column,
                                            "r"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("恢复任务")]
                                  )
                                : _vm._e(),
                              column.status === 8 ||
                              column.status === 0 ||
                              column.status === 1
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeleteTask(column)
                                        },
                                      },
                                    },
                                    [_vm._v("删除任务")]
                                  )
                                : _vm._e(),
                              column.checkResult
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCheckCertificate(
                                            column.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("查看合格证")]
                                  )
                                : _vm._e(),
                              column.status === 2 ||
                              column.status === 4 ||
                              column.status === 5
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTaskEmergencyTypeEdit(
                                            column
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("设置紧急类型")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "a",
                            { staticClass: "operation-btn" },
                            [
                              _vm._v(" 操作 "),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c(
        "el-dropdown",
        {
          staticClass: "export-task",
          on: { command: _vm.handleExportTaskCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出任务")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选任务"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.visible
        ? _c("TaskEditModal", { attrs: { "task-form": _vm.currentTask } })
        : _vm._e(),
      _vm.batchTaskEditVisible
        ? _c("TaskBatchEdit", {
            attrs: { "task-form": _vm.currentTask },
            on: {
              cancel: _vm.handleTaskBatchModalCanceled,
              confirm: _vm.handleTaskBatchModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.assembleTaskEditVisible
        ? _c("TaskAssembleEdit", {
            attrs: { "task-form": _vm.currentSelectTaskIds },
            on: {
              cancel: _vm.handleTaskAssembleModalCanceled,
              confirm: _vm.handleTaskAssembleModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.emergencyEditVisible
        ? _c("TaskEmergencyEditModal", {
            attrs: {
              ids: _vm.emergencyEditIds,
              record: _vm.emergencyEditColumn,
            },
            on: {
              confirm: _vm.handleTaskEmergencyTypeEditConfirm,
              cancel: _vm.handleTaskEmergencyTypeEditCancel,
            },
          })
        : _vm._e(),
      _vm.assembleTaskModalVisible
        ? _c("TaskAssembleModal", {
            attrs: { ids: _vm.assembleIds },
            on: {
              cancel: _vm.handleTaskAssembleCancel,
              confirm: _vm.handleTaskAssembleConfirm,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }