var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.code + "产品项明细",
        "mask-closable": false,
        width: "1200px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c("a-table", {
        staticClass: "users-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          columns: _vm.offerDetailColumns,
          "data-source": _vm.list,
          loading: _vm.loading,
          pagination: _vm.pagination,
          scroll: { x: 1700 },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "taxRate",
            fn: function (text) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text * 100 + "%" : ""))]),
              ])
            },
          },
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.dataIndex
                              )
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                  column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "190px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.dataIndex
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.dataIndex
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c("div", {}, [
                text === "WSO" ? _c("span", [_vm._v("待报出")]) : _vm._e(),
                text === "WDL" ? _c("span", [_vm._v("待成交")]) : _vm._e(),
                text === "CNL" ? _c("span", [_vm._v("已取消")]) : _vm._e(),
                text === "DEL" ? _c("span", [_vm._v("已成交")]) : _vm._e(),
                text === "PDL" ? _c("span", [_vm._v("部分成交")]) : _vm._e(),
                text === "NDL" ? _c("span", [_vm._v("未成交")]) : _vm._e(),
                text === "RVC" ? _c("span", [_vm._v("已撤销")]) : _vm._e(),
              ])
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }