import { render, staticRenderFns } from "./index.vue?vue&type=template&id=75a07d78&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75a07d78",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/whitewe_jisheng-mes-ui_CPvN/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('75a07d78')) {
      api.createRecord('75a07d78', component.options)
    } else {
      api.reload('75a07d78', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=75a07d78&scoped=true", function () {
      api.rerender('75a07d78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MaterialEdit/index.vue"
export default component.exports