export const taskColumns = [
  {
    title: '任务编码',
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
    align: 'center',
    searchType: 'text',
    width: 150,
    scopedSlots: {

      customRender: 'customRender',
    },
  },
  {
    title: '任务类型',
    dataIndex: 'type',
    width: 150,
    key: 'type',
    align: 'center',
    scopedSlots: {
      customRender: 'type',
    },
  },
  {
    title: '紧急类型',
    dataIndex: 'emgType',
    key: 'emgType',
    width: 150,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'emgType',
    },
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    width: 200,
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '当前位置',
    dataIndex: 'latestOperationName',
    key: 'latestOperationName',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {

      customRender: 'customRender',
    },
  },
  {
    title: '当前班组',
    dataIndex: 'currentGroupName',
    key: 'teamGroup.groupName',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {

      customRender: 'customRender',
    },
  },
  {
    title: '计划启动日期',
    dataIndex: 'startTime',
    key: 'plan.startTime',
    searchType: 'date',
    align: 'center',
    ellipsis: true,
    width: 200,
    scopedSlots: {

      customRender: 'customRender',
    },
  },
  {
    title: '任务启动日期',
    dataIndex: 'createTime',
    key: 'task.createTime',
    searchType: 'date',
    align: 'center',
    ellipsis: true,
    width: 200,
    scopedSlots: {

      customRender: 'customRender',
    },
  },
  {
    title: '任务截止日期',
    dataIndex: 'taskDeliveryTime',
    key: 'task.taskDeliveryTime',
    searchType: 'date',
    width: 150,
    align: 'center',
    ellipsis: true,
    scopedSlots: {

      customRender: 'customRender',
    },
  },
  {
    title: '客户名称',
    dataIndex: 'client',
    ellipsis: true,
    width: 150,
    key: 'productOrder.client',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    key: 'product.productName',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '图号',
    dataIndex: 'drawingNum',
    key: 'product.drawingNum',
    ellipsis: true,
    width: 150,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '规格',
    dataIndex: 'specification',
    key: 'product.specification',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '投产数量',
    dataIndex: 'productionNum',
    width: 150,
    key: 'productionNum',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '入库数量',
    dataIndex: 'putInNum',
    width: 150,
    key: 'putInNum',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '在产数量',
    dataIndex: 'producingNum',
    width: 150,
    key: 'task.producingNum',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },

  {
    title: '完成日期',
    dataIndex: 'finishTime',
    key: 'task.finishTime',
    width: 150,
    align: 'center',
    ellipsis: true,
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '出库数量',
    dataIndex: 'takeOutNum',
    key: 'takeOutNum',
    width: 150,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '出库日期',
    dataIndex: 'takeOutTime',
    key: 'task.takeOutTime',
    width: 150,
    align: 'center',
    ellipsis: true,
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '报废数量',
    dataIndex: 'abandonNum',
    key: 'abandonNum',
    width: 150,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '退货数量',
    dataIndex: 'returnedNum',
    key: 'returnedNum',
    width: 150,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '返修数量',
    dataIndex: 'repairedNum',
    key: 'repairedNum',
    width: 150,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '退货时间',
    dataIndex: 'returnedTime',
    key: 'task.returnedTime',
    width: 150,
    align: 'center',
    ellipsis: true,
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '超差数量',
    dataIndex: 'outOfToleranceNum',
    key: 'outOfToleranceNum',
    width: 150,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '任务备注',
    dataIndex: 'comment',
    key: 'task.comment',
    width: 150,
    ellipsis: true,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
]
