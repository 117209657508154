export const handleFormMixin = {
  data() {
    return {
      typeOptions: [
        {
          value: 1,
          label: '普通任务'
        },
        {
          value: 2,
          label: '返修任务'
        },
        {
          value: 3,
          label: '分批任务'
        },
        {
          value: 4,
          label: '退库补投任务'
        },
        {
          value: 5,
          label: '生产补投任务'
        }
      ],
      recordOptions: [
        {
          value: 1,
          label: '入库记录'
        },
        {
          value: 2,
          label: '出库记录'
        },
        {
          value: 3,
          label: '超差记录'
        },
        {
          value: 4,
          label: '报废记录'
        },
        {
          value: 5,
          label: '返修记录'
        },
        {
          value: 6,
          label: '合格记录'
        },
        {
          value: 7,
          label: '退库补投记录'
        },
        {
          value: 8,
          label: '退库返修记录'
        },
        {
          value: 9,
          label: '加工完成记录'
        },
        {
          value: 10,
          label: '生产补投记录'
        },
        {
          value: 11,
          label: '下料记录'
        }
      ],
      planOptions: [
        {
          value: 1,
          label: '未开始'
        },
        {
          value: 2,
          label: '进行中'
        },
        {
          value: 3,
          label: '已完成'
        },
        {
          value: 4,
          label: '已超期'
        }
      ]
    }
  },
  methods: {
    /**
     * 处理新增、更新逻辑和完成新增、更新后的提示信息
     * @param qId {number} 需更新元素的id
     * @param qBody {Object} 需更新或新增元素的请求体
     * @param updateCallback {function} 更新接口处理函数
     * @param addCallback {function} 新增接口处理函数
     * @param getInfoCallback {function} 获取处理完成后的信息处理函数
     * @param keyName {string} 处理的是什么功能 如订单、任务、计划等等
     * @param displayField {Array} 需要在Tip Message里面展示的信息
     * @param isEdit {boolean} 是更新还是新增
     * @returns {Promise<void>}
     */
    async handleEditTip(qId, qBody, updateCallback, addCallback, getInfoCallback, keyName, displayField, isEdit = true) {
      this.loading = true
      this.dialogVisible = false
      try {
        const res = await (isEdit ? updateCallback(qId, qBody) : addCallback(qBody))
        const infoRes = getInfoCallback ? await getInfoCallback(res.data.id) : ''
        let message = ''
        displayField.forEach(item => {
          message = infoRes ? message + `<div>${isEdit ? '更新' : '新增'}${item[Object.keys(item)[0]]}: ${infoRes.data[Object.keys(item)[0]]}</div>` : ''
        })
        this.$notify({
          title: `${isEdit ? '更新' : '新增'}${keyName}成功`,
          dangerouslyUseHTMLString: true,
          message: message,
          type: 'success'
        })
        this.refreshTable()
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    /**
     * 处理在某个功能下新增
     * @param qId
     * @param qBody
     * @param addCallback
     * @param keyName
     * @returns {Promise<void>}
     */
    async handleAddTip(qId, qBody, addCallback, keyName) {
      this.loading = true
      try {
        const res = await addCallback(qId, qBody)
        const message = `<div>新增${keyName}id: ${res.data.id}</div>`
        this.$notify({
          title: `新增${keyName}成功`,
          dangerouslyUseHTMLString: true,
          message: message,
          type: 'success'
        })
        this.refreshTable()
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    /**
     * 处理删除逻辑和删除后的提示信息
     * @param id {number} 需删除元素的id
     * @param deleteCallback {function} 删除接口处理函数
     * @param isFile {boolean} 判断是删除表格内容还是删除文件
     * @param message {string} 删除后提示的信息
     * @returns {Promise<void>}
     */
    async handleDeleteTip(id, deleteCallback, isFile = false, message = '删除成功！') {
      this.loading = true
      try {
        await deleteCallback(id)
        this.$notify({
          title: '操作成功',
          type: 'success',
          dangerouslyUseHTMLString: true,
          message: `<div>${message}</div>`
        })
        isFile ? this.$refs.pagination.currentChange(this.$refs.pagination.cPage) : this.$refs.pagination.currentChange(1)
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    /**
     * 请求成功后刷新当前页表格信息
     * @param isDelete{boolean} 判断是删除内容后刷新还是其他操作刷新
     */
    refreshTable(isDelete = false) {
      isDelete ? this.$refs.pagination.currentChange(1) : this.$refs.pagination.currentChange(this.$refs.pagination.cPage)
    }
  }
}
