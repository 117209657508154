import { formatDateTime } from '@/utils/date-format-util'

/**
 * 处理数字类型区间参数
 * @param {Object} data lte gte参数对象
 */
export function ltegte(data) {
  const { lte, gte } = data

  if (!lte && !gte) {
    return false
  }

  if (lte && gte && lte !== gte) {
    return `btn:${lte},${gte}`
  } else if (lte && !gte) {
    return `gte:${lte}`
  } else if (!lte && gte) {
    return `lte:${gte}`
  } else if (lte === gte) {
    return `eq:${lte}`
  } else {
    return false
  }
}

/**
 * 处理日期类型区间参数
 * @param {Object} data lte gte参数对象
 */
export function ltegteDate(data) {
  const { lte, gte } = data

  if (!lte && !gte) {
    return false
  }

  const resultLte = lte ? formatDateTime(lte.getTime()).substring(0, 10) : lte
  const resultGte = gte ? formatDateTime(gte.getTime()).substring(0, 10) : gte

  if (resultLte && resultGte && resultLte !== resultGte) {
    return `btn:${resultLte},${resultGte}`
  } else if (resultLte && !resultGte) {
    return `gte:${resultLte}`
  } else if (!resultLte && resultGte) {
    return `lte:${resultGte}`
  } else if (resultLte === resultGte) {
    return `eq:${resultLte}`
  } else {
    return false
  }
}

/**
 * 处理数组类型 在...之中
 * @param {Array} data 目标数组
 */
export function inFn(data) {
  if (!data.length) {
    return false
  }
  let str = 'in:'
  if (data.length === 1) {
    str = 'eq:'
  }
  for (let i = 0; i < data.length; i++) {
    if (i === data.length - 1) {
      str = str + data[i]
    } else {
      str = str + data[i] + ','
    }
  }
  return str
}
