import request from '@/utils/api.request'

/**
 * 分页获取任务信息
 * @param {object} data
 */
export function getTasks(data) {
  return request({
    url: `/tasks`,
    method: 'get',
    params: data
  })
}

/**
 * 分页获取任务信息
 * @param {object} data
 */
export function getTaskPlanProducts(data) {
  return request({
    url: `/tasks/task-plan-product`,
    method: 'get',
    params: data
  })
}

/**
 * 根据id获取指定的任务信息
 * @param {number} id
 */
export function getTaskById(id) {
  return request({
    url: `/tasks/${id}`,
    method: 'get'
  })
}

/**
 * 更新指定id的任务
 * @param {number} id
 * @param {object} data
 */
export function updateTask(id, data) {
  return request({
    url: `/tasks/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除指定id的任务
 * @param {number} id
 */
export function deleteTask(id) {
  return request({
    url: `/tasks/${id}`,
    method: 'delete'
  })
}

/**
 * 通过任务id新增对应任务的记录信息
 * @param {number} id
 */
export function addRecordByTask(id, data) {
  return request({
    url: `/tasks/${id}/records`,
    method: 'post',
    data
  })
}

/**
 * 记录信息并对任务分批
 * @param {number} id
 */
export function addRecordAndBatchTask(id, batchNum, data) {
  return request({
    url: `/tasks/${id}/records-and-batches/${batchNum}`,
    method: 'post',
    data
  })
}

/**
 * 通过计划id获取对应计划的任务信息
 * @param {number} id
 */
export function getTasksByPlan(id, data) {
  return request({
    url: `/plans/${id}/tasks?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: 'get'
  })
}

/**
 * 通过任务id获取对应任务加工过程
 * @param {number} id
 */
export function getProcess(id) {
  return request({
    url: `/tasks/${id}/process`,
    method: 'get'
  })
}

/**
 * 通过任务id对对应任务进行分批操作
 * @param {number} id
 */
export function batchTask(id, batchNum) {
  return request({
    url: `/tasks/${id}/batches/${batchNum}`,
    method: 'post'
  })
}

/**
 * 导出任务
 * @param {*} data
 */
export function exportTasks(data) {
  return request({
    url: `/tasks/exports/filtered`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}

/**
 * 暂停、取消、恢复任务
 * @param id
 * @param data
 * @returns {AxiosPromise}
 */
export function switchTaskStatus(taskId, statusCode) {
  return request({
    url: `/tasks/${taskId}/status/${statusCode}`,
    method: 'put'
  })
}

/**
 * 根据id列表获取指定的工艺流转卡信息
 */
export function getCraftCardInfoByIds(data) {
  return request({
    url: `/tasks/craft-card-info`,
    method: 'get',
    params: data
  })
}

/**
 * 批量录入记录信息
 */
export function addBatchTasksRecord(data) {
  return request({
    url: `/tasks/records`,
    method: 'post',
    data
  })
}

/**
 * 根据任务编码获取任务
 */
export function getTaskByTaskCode(taskCode) {
  return request({
    url: `/tasks/codes/${taskCode}`,
    method: 'get'
  })
}

/**
 * 新增合格证信息
 */
export function addCertificatesByTaskCode(taskCode, data) {
  return request({
    url: `/tasks/${taskCode}/certificates`,
    method: 'post',
    data
  })
}

/**
 * 获取合格证信息
 */
export function getCertificatesByTaskCode(taskCode) {
  return request({
    url: `/tasks/${taskCode}/certificates`,
    method: 'get'
  })
}
/**
 * 校验录入工序是否合法
 */
export function getOperationCheck(taskCode, data) {
  return request({
    url: `/tasks/${taskCode}/operation-check`,
    method: 'get',
    params: data
  })
}

/**
 * 组装产品
 */
export function assembleProducts(data) {
  return request({
    url: `/tasks/assemble-finish`,
    method: 'post',
    data
  })
}

/**
 * 更新任务紧急类型信息
 */
 export function updateEmergencyType(ids,data) {
  return request({
    url: `/tasks?ids=${ids}`,
    method: 'patch',
    data
  })
}

/**
 * 批量装配任务
 */
 export function batchTaskAssemble(data) {
  return request({
    url: `/tasks/assemble-finish-nocheck`,
    method: 'post',
    data
  })
}
