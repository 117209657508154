import request from '@/utils/api.request'

/**
 * 分页获取产品审价信息
 * @param {*} data
 * @returns
 */
export function getOffers(data) {
  return request({
    url: `/quotations`,
    method: 'get',
    params: data,
  })
}

/**
 * 根据报价单号分页获取报价单明细
 * @param {*} data
 * @returns
 */
export function getQuotationsDetails(code, data) {
  return request({
    url: `/quotations/${code}/details`,
    method: 'get',
    params: data,
  })
}
/**
 * 报价通过，生成销售订单
 * @param {*} data
 * @returns
 */
export function submitQuotationsOrder() {
  return request({
    url: `/quotations/orders`,
    method: 'post',
  })
}

/**
 * 报出报价单
 * @param {*} data
 * @returns
 */
export function putQuotations(data) {
  return request({
    url: `/quotations`,
    method: 'put',
    data,
  })
}

/**
 *
 * @param 根据报价单号获取报价单明细(打印) code
 * @returns
 */
export function getQuotationsPrintInfo(code) {
  return request({
    url: `/quotations/${code}`,
    method: 'get',
  })
}

/**
 * 撤销报价单
 * @param {*} data
 * @returns
 */
export function revocationQuotations(data) {
  return request({
    url: `/quotations/revocation`,
    method: 'put',
    data,
  })
}

/**
 * 报价成交
 * @param {*} data
 * @returns
 */
export function orderQuotations(data) {
  return request({
    url: `/quotations/orders`,
    method: 'post',
    data,
  })
}
