<template>
  <div class="app-container">
    <a-row>
      <a-col :span="2" style="margin-right: 30px; ">
        <c-upload
          name="file"
          :accept="&quot;.xlsx&quot;"
          :show-upload-list="false"
          :action="`${uploadUrl}orders/products/imports`"
          @change="handleFileChange"
        >
          <el-button slot="content" type="primary" :loading="uploading" icon="el-icon-circle-plus-outline">导入订单与产品</el-button>
        </c-upload>
      </a-col>
      <a-col :span="9" style="margin-left: 300px; ">
        <a-input-search
          v-model="searchValue"
          :allow-clear="true"
          placeholder="请输入产品名称/规格/图号/计划编码查询"
          enter-button="查找"
          size="large"
          @search="handleSearch"
        />
      </a-col>
      <a-col :span="4" style="margin-top: 10px;">
        <span>是否展示分件：</span>
        <a-switch checked-children="是" un-checked-children="否" :default-checked="false" :loading="switchLoading" @change="handleShowPart" />
      </a-col>
      <a-col :span="3" style="float: right; margin-right: 18px;">
        <el-button type="text" icon="el-icon-download" @click="handleDownloadTemplate">点击下载订单产品导入模板</el-button>
      </a-col>
    </a-row>
    <!--<el-button type="primary" icon="el-icon-search" @click="searchDrawer = true">筛选产品</el-button>-->
    <el-table
      ref="productTable"
      v-loading="loading"
      element-loading-text="加载中..."
      :row-key="getRowKey"
      :data="productList"
      :show-overflow-tooltip="true"
      style="width: 100%;margin-top:30px;"
      border
      max-height="700"
      :header-cell-class-name="handleHeaderClass"
      @selection-change="handleSelectionChange"
      @select="handleSelect"
      @sort-change="handleSortChange"
      @filter-change="onFilterChange"
    >
      <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
      <el-table-column fixed="left" align="center" label="序号" type="index" width="70" :index="indexMethod" class="border-top-solid" />
      <el-table-column align="center" label="订单编号" prop="orderCode" column-key="orderCode" width="130" sortable="custom" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            column-key="product_order.orderCode"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="签约日期" prop="signTime" column-key="signTime" width="160" sortable="custom" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            column-key="product_order.signTime"
            search-type="date"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" prop="productName" column-key="productName" width="260" sortable="custom" :filters="[]">
        <template slot-scope="scope">{{ scope.row.productName }}</template>
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column label="产品状态" prop="statusText" column-key="statusText" width="110" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.statusTag.type">{{ scope.row.statusTag.label }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="计划编码"
        prop="isPlanExisted"
        column-key="isPlanExisted"
        width="200"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown :label="scope.column.label" column-key="plan.code" search-type="text" @search="handleColumnSearch" />
        </template>
        <template slot-scope="scope">
          <el-link
            v-if="scope.row.isPlanExisted===1"
            type="primary"
            title="点击查看计划详情"
            @click="jumpToDashboardPage(scope.row.planCode, scope.row.type)"
          >{{ scope.row.planCode }}</el-link>
          <el-popconfirm v-else title="确定下达该产品计划？" placement="top" @onConfirm="addProductPlan(scope)">
            <el-button slot="reference" type="primary" size="mini" :loading="scope.row.isButtonLoading" round>请点击下达计划</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="产品类型"
        prop="type"
        column-key="type"
        width="100"
        :filters="[{text: &quot;L类型&quot;, value: &quot;L&quot;},{text: &quot;D类型&quot;, value: &quot;D&quot; },{text: &quot;G类型&quot;, value: &quot;G&quot; }]"
        :filter-multiple="true"
      />
      <el-table-column align="center" label="图号" prop="drawingNum" column-key="drawingNum" width="150" sortable="custom" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="规格"
        width="200"
        sortable="custom"
        prop="specification"
        column-key="specification"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="单位" prop="unit" column-key="unit" width="50" />
      <el-table-column align="center" label="订单数量" prop="amount" column-key="amount" width="120" sortable="custom" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="number"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="不含税单价"
        prop="taxFreePrice"
        column-key="taxFreePrice"
        width="130"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="number"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="税率" prop="taxRate" column-key="taxRate" width="50">
        <template slot-scope="scope">{{ scope.row.taxRate }}</template>
      </el-table-column>
      <el-table-column align="center" label="含税单价" prop="taxPrice" column-key="taxPrice" width="120" sortable="custom" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="number"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="含税金额"
        prop="totalMoneyTaxInclued"
        column-key="totalMoneyTaxInclued"
        width="150"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="number"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="不含税金额"
        prop="totalMoneyTaxExclued"
        column-key="totalMoneyTaxExclued"
        width="150"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="number"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="产品交货日期"
        prop="deliverTime"
        column-key="deliverTime"
        width="160"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown :label="scope.column.label" :column-key="plan.deliverTime" search-type="date" @search="handleColumnSearch" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="产值单价"
        prop="productionPrice"
        column-key="productionPrice"
        width="130"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="number"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="客户原始工艺文件" prop="fileName" width="300">
        <template slot-scope="scope">
          <span v-if="scope.row.fileName">
            <el-link :underline="false" type="success" @click="viewFile(scope.row.id)">{{ scope.row.fileName }}</el-link>&nbsp;
            <a-popconfirm title="您确定要删除工艺文件？" ok-text="确定" cancel-text="取消" @confirm="handleDeleteCraftFile(scope.row.id)">
              <a class="filename-delete">
                <a-icon type="delete" />
              </a>
            </a-popconfirm>
          </span>
          <c-upload
            v-else
            name="file"
            :show-upload-list="false"
            :action="`${uploadUrl}products/${scope.row.id}/file`"
            @change="uploadCraftSuc"
          >
            <el-link slot="content" type="primary" :underline="false">上传工艺文件</el-link>
          </c-upload>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="产品客户需求"
        prop="productClientDemand"
        column-key="productClientDemand"
        width="150"
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="客户物料代码"
        prop="clientMaterialCode"
        column-key="clientMaterialCode"
        width="150"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="客户计划编码"
        prop="clientPlanCode"
        column-key="clientPlanCode"
        width="150"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="客户合同编号"
        prop="clientContractCode"
        column-key="clientContractCode"
        width="150"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            column-key="product_order.clientContractCode"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="客户采购编号"
        prop="clientPurchaseOrderCode"
        column-key="clientPurchaseOrderCode"
        width="150"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            column-key="product_order.clientPurchaseOrderCode"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="客户订单编号"
        prop="clientOrderCode"
        column-key="clientOrderCode"
        width="150"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            column-key="product_order.clientOrderCode"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="客户库房编号"
        prop="clientWarehouseCode"
        column-key="clientWarehouseCode"
        width="150"
        sortable="custom"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            column-key="clientWarehouseCode"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="客户名称" prop="client" column-key="client" width="130" sortable="custom" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            column-key="product_order.client"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="产品类别"
        prop="productCategory"
        column-key="productCategory"
        width="100"
        :filters="[{ text: '单体', value: 'MLJ' },{ text: '单体轴类', value: 'ZLJ' }, { text: '单体非轴类', value: 'OLJ' }, { text: '组合', value: 'CLJ' }]"
      >
        <template slot-scope="scope">{{ scope.row.productCategory }}</template>
      </el-table-column>
      <el-table-column align="center" label="材料类别" prop="materialCategoryValue" column-key="materialCategoryValue" width="110">
        <template slot-scope="scope">{{ scope.row.materialCategoryValue }}</template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="comment" width="100" />
      <el-table-column align="center" label="操作" fixed="right">
        <template slot-scope="scope">
          <el-dropdown trigger="click" @command="((value) => {handleCommand(value, scope)})">
            <span class="el-dropdown-link">
              操作
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-edit" command="update">更新产品信息</el-dropdown-item>
              <el-dropdown-item icon="el-icon-delete" command="delete">删除产品</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-dropdown class="export-product" @command="handleExportProductCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出产品</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="SELECTED">导出当前已选产品</el-dropdown-item>
        <el-dropdown-item command="PAGE">导出当前页产品</el-dropdown-item>
        <el-dropdown-item command="FILTERED">导出当前筛选产品</el-dropdown-item>
        <el-dropdown-item command="ALL">导出所有产品</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <ProductEdit
      v-if="addProductModalVisible"
      :modal-title="currentEditType"
      :product-form="currentProduct"
      @cancel="handleProductModalCanceled"
      @confirm="handleProductModalConfirmed"
    />
    <Pagination ref="pagination" :total="total" @getData="(data) => {searchValue ? onProductSearch(data) : getProducts(data)}" />
  </div>
</template>

<script>
import '@/utils/directives'
import '@/utils/dateFormat'
import { downloadItem, handleDownLoad } from '@/utils/api.request'
import {
  deepClone,
  sortMap,
  getSortParams,
  removePropertyOfNull,
} from '@/utils'
import * as R from 'ramda'
import { downloadTemplate } from '@/common/templateDownload'
import { CONSTANTS } from '@/utils/constants'
import { formatDateTime, handleTimeZone } from '@/utils/date-format-util'
import { ltegte, ltegteDate, inFn } from '@/utils/conditionQuery'
import {
  getProducts,
  getProductById,
  addProduct,
  updateProduct,
  deleteProduct,
  getProductsByType,
  deleteProductPdf,
  addPlanByProduct,
  addProductPlan,
  exportAllProducts,
  exportProductsByIds,
  exportFilteredProducts,
  exportCurrentPageProducts,
  getProductsByQuery,
} from '@/api/product'
import Pagination from '@/components/Pagination'
import ProductEdit from '@/components/ProductEdit'
import Upload from '@/components/Upload'
import SearchFilterDropdown from '@/components/SearchFilterDropdown'
import { handleFormMixin } from '@/utils/mixin'

const defaultProduct = {
  productName: '',
  craftDrawingNum: '',
  specification: '',
  unit: '',
  taxPrice: '',
  taxFreePrice: '',
  selType: '',
}

export default {
  components: {
    Pagination,
    Upload,
    SearchFilterDropdown,
    ProductEdit,
  },
  mixins: [handleFormMixin],
  data() {
    // 数字类型验证
    var numberValidate = (rule, value, callback) => {
      if (typeof value === 'number' || !value) {
        callback()
      } else {
        if (typeof (value * 1) === 'number' && value * 1 >= 0) {
          callback()
        } else {
          callback(new Error('请输入大于等于0范围内的数字'))
        }
      }
    }
    var totalNumValidate = (rule, value, callback) => {
      if (typeof value === 'number') {
        callback()
      } else if (value === '' || value === null || value === undefined) {
        callback(new Error('请输入生产产品数量'))
      } else {
        if (typeof (value * 1) === 'number' && value * 1 >= 0) {
          callback()
        } else {
          callback(new Error('请输入大于等于0范围内的数字'))
        }
      }
    }
    return {
      total: 0,
      uploading: false,
      loading: true,
      exportLoading: false,
      searchValue: '',
      ifShowParts: 'N',
      switchLoading: false,
      plan: {},
      addPlan: false,
      orderCode: '',
      queryId: null,
      selType: '',
      selSearchType: '',
      options: [],
      product: Object.assign({}, defaultProduct),
      productList: [],
      dialogVisible: false,
      dialogType: 'new',
      checkStrictly: false,
      isUpdate: false,
      fileStatus: 0,
      uploadUrl: process.env.VUE_APP_BASE_API,
      searchDrawer: false,
      deliveryTimeLt: null,
      deliveryTimeGt: null,
      currentPageSize: 0,
      currentPageNum: 0,
      multipleSelection: [],
      exportIds: [],
      sortByMap: new Map(),
      columnFilterParams: {},
      currentEditType: '',
      currentProduct: {},
      addProductModalVisible: false,
      searchForm: {
        orderCode: '',
        productName: '',
        drawingNum: '',
        specification: '',
        unit: '',
        client: '',
        clientMaterialCode: '',
        clientPlanCode: '',
        clientContractCode: '',
        clientPurchaseOrderCode: '',
        clientOrderCode: '',
        amountLt: '',
        amountGt: '',
        taxFreePriceLt: '',
        taxFreePriceGt: '',
        taxRateLt: '',
        taxRateGt: '',
        taxPriceLt: '',
        taxPriceGt: '',
        totalMoneyTaxExcluedLt: '',
        totalMoneyTaxExcluedGt: '',
        totalMoneyTaxIncluedLt: '',
        totalMoneyTaxIncluedGt: '',
        isPlanExistedList: [],
      },
      rules: {
        productName: [
          {
            required: true,
            message: '请输入产品名称',
            trigger: 'change',
          },
        ],
        taxPrice: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        taxFreePrice: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        amount: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
      },
      planRules: {
        code: [
          {
            required: true,
            message: '请输入计划编码',
            trigger: 'change',
          },
        ],
        totalNum: [
          {
            validator: totalNumValidate,
            required: true,
            trigger: 'change',
          },
        ],
        inventoryNum: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        spareProductNum: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        spareStorageNum: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        finishedNum: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        producingNum: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        totalAbandonNum: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        totalPutInNum: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        totalTakeOutNum: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        deliveryTime: [
          {
            required: true,
            message: '请选择合同约定交货日期',
            trigger: 'change',
          },
        ],
        penalty: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
      },
      productSearchRules: {
        amountLt: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        amountGt: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        taxPriceLt: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        taxPriceGt: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        taxFreePriceLt: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
        taxFreePriceGt: [
          {
            validator: numberValidate,
            trigger: 'change',
          },
        ],
      },
      dropScope: {},
    }
  },
  // 监听页面路由参数变化 根据是否传参数来展示具体的数据
  watch: {
    $route() {
      this.orderCode = this.$route.query.orderCode
    },
    orderCode() {
      const data = {
        pageNum: 1,
        pageSize: 10,
      }
      this.getProducts(data)
    },
    // 监听数据变化时候，改变选择
    productList(val) {
      val.forEach((item, i) => {
        this.multipleSelection.forEach((multItem, j) => {
          if (item.id === multItem.id) {
            this.$refs.productTable.toggleRowSelection(item, true) // 这步操作是让  页面显示选中的数据
          }
        })
      })
    },
  },
  created() {
    this.initTable()
  },
  mounted() {
    // this.$refs.fileLink.querySelector('a').setAttribute('target', '_blank')
  },
  methods: {
    initTable() {
      const data = {
        pageNum: 1,
        pageSize: 10,
      }
      this.getProducts(data)
    },
    indexMethod(index) {
      return (this.currentPageNum - 1) * this.currentPageSize + (index + 1)
    },
    getRowKey(row) {
      return row.id
    },
    async getProducts(data) {
      this.loading = true
      this.currentPageSize = data.pageSize
      this.currentPageNum = data.pageNum
      const filterSortParams = this.getFilterSortParams()
      const obj = {
        0: {
          label: '计划制定中',
          type: 'info',
        },
        1: {
          label: '工艺编制中',
          type: 'info',
        },
        2: {
          label: '进行中',
          type: '',
        },
        3: {
          label: '已完成',
          type: 'success',
        },
        4: {
          label: '超期进行中',
          type: 'danger',
        },
        5: {
          label: '即将超期',
          type: 'danger',
        },
        6: {
          label: '超期完成',
          type: 'warning',
        },
        7: {
          label: '超期取消',
          type: 'info',
        },
        8: {
          label: '已取消',
          type: 'info',
        },
        9: {
          label: '暂停中',
          type: 'info',
        },
        10: {
          label: '计划待下达',
          type: '',
        },
      }
      const params = Object.assign(data, filterSortParams)
      data.showParts = this.ifShowParts
      if (this.$route.query.orderCode || this.$route.query.orderCode === 0) {
        try {
          const res = await getProducts({
            orderCode: `eq:${this.$route.query.orderCode}`,
            ...data,
          })
          res.data.records.forEach((item) => {
            item.statusTag = obj[item.status]
          })
          this.total = res.data.total
          this.productList = res.data.records
          this.loading = false
        } catch (e) {
          this.loading = false
          console.log(e)
        }
      } else {
        try {
          const res = await getProducts(data)
          res.data.records.forEach((item) => {
            item.statusTag = obj[item.status]
          })
          this.productList = res.data.records
          this.total = res.data.total
          this.loading = false
        } catch (e) {
          this.loading = false
          console.log(e)
        }
      }
    },
    handleSearch() {
      this.initTable()
      this.$refs.pagination.currentChange(1, {})
    },
    async handleShowPart(val) {
      if (this.switchLoading) {
        return
      }
      this.switchLoading = true
      if (val) {
        this.ifShowParts = 'Y'
      } else {
        this.ifShowParts = 'N'
      }
      const data = {
        pageNum: 1,
        pageSize: 10,
      }
      if (this.searchValue) {
        await this.onProductSearch(data)
        this.switchLoading = false
      } else {
        await this.getProducts(data)
        this.switchLoading = false
      }
    },
    onProductSearch(data) {
      this.loading = true
      getProductsByQuery({
        pageNum: data.pageNum,
        pageSize: data.pageSize,
        queryValue: this.searchValue,
        showParts: this.ifShowParts,
      })
        .then((res) => {
          this.productList = res.data.records
          this.currentPageNum = res.data.current
          this.currentPageSize = res.data.size
          this.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    addProductPlan(scope) {
      const commandTime = handleTimeZone(formatDateTime(+Date())).substring(
        0,
        19
      )
      this.$set(scope.row, 'isButtonLoading', true)
      const res = addProductPlan(scope.row.id, {
        commandTime: commandTime,
      })
        .then((res) => {
          if (res) {
            this.$message.success('计划下达成功！')
            this.$set(scope.row, 'isButtonLoading', false)
            this.$refs.pagination.currentChange(this.currentPageNum)
          }
        })
        .catch((err) => {
          this.$set(scope.row, 'isButtonLoading', false)
        })
    },
    jumpToPlanPage(planId) {
      this.$router.push({
        path: '/plan',
        query: {
          planId: planId,
        },
      })
    },
    getExportIdsFromSelection() {
      this.exportIds = R.uniq(
        this.multipleSelection.map((item) => {
          return item.id
        })
      )
    },
    getExportIdsFromPage() {
      this.exportIds = this.productList.map((item) => {
        return item.id
      })
    },
    handleExportProductCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'SELECTED':
          if (this.multipleSelection.length === 0) {
            this.$message.warning('选择项不能为空！')
            this.exportLoading = false
            break
          }
          this.getExportIdsFromSelection()
          exportProductsByIds({
            ids: this.exportIds.toString(),
          })
            .then((res) => {
              this.downloadProduct(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        case 'PAGE':
          if (this.productList.length === 0) {
            this.$message.warning('导出数据不能为！')
            break
          }
          exportCurrentPageProducts({
            pageNum: this.currentPageNum,
            pageSize: this.currentPageSize,
          })
            .then((res) => {
              this.downloadProduct(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        case 'FILTERED':
          exportFilteredProducts(this.columnFilterParams)
            .then((res) => {
              this.downloadProduct(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        case 'ALL': {
          exportAllProducts()
            .then((res) => {
              this.downloadProduct(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        }
        default:
          this.exportLoading = false
          break
      }
    },
    downloadProduct(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.PRODUCT_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    uploadCraftSuc(info) {
      console.log('上传', info)
      if (info.file.status === 'done') {
        this.$notify({
          title: '上传原始客户工艺文件成功！',
          dangerouslyUseHTMLString: true,
          message: `<div>上传的文件：${info.file.name}</div>`,
          type: 'success',
        })
        this.$refs.pagination.currentChange(this.currentPageNum)
      }
    },
    async viewFile(id) {
      this.loading = true
      const url = `${this.uploadUrl}products/${id}/file`
      await handleDownLoad(url)
      this.loading = false
    },
    handleDeleteCraftFile(id) {
      deleteProductPdf(id).then((res) => {
        this.$message.success('删除客户工艺文件成功')
        this.$refs.pagination.currentChange(this.currentPageNum)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleSelect(selection, row) {
      console.log(row)
    },
    // 设置列的排序为我们自定义的排序
    handleHeaderClass({ column }) {
      column.order = column.multiOrder
    },
    handleSortChange({ column }) {
      console.log('coluumn', column)
      // 有些列不需要排序，提前返回
      if (column.sortable !== 'custom') {
        return
      }
      const order = column.order
      column.multiOrder = order
      const key = column.property
      this.sortByMap.set(key, order)
      const filterSortParams = this.getFilterSortParams()
      this.searchValue = ''
      this.$refs.pagination.currentChange(1, filterSortParams)
    },
    handleColumnSearch(data) {
      this.columnFilterParams = R.merge(this.columnFilterParams, data)
      const filterSortParams = this.getFilterSortParams()
      this.searchValue = ''
      this.$refs.pagination.currentChange(1, filterSortParams)
    },
    onFilterChange(object) {
      console.log('coluumn', object)
      const newObject = {}
      if (object.isPlanExisted) {
        if (object.isPlanExisted.length === 1) {
          newObject.isPlanExisted = 'eq:' + object.isPlanExisted
        } else {
          newObject.isPlanExisted = null
        }
      }
      if (object.productCategory.length > 0) {
        newObject.productCategory = 'in:' + object.productCategory.toString()
      } else {
        newObject.productCategory = null
      }
      if (object.type) {
        if (object.type.length > 0) {
          newObject.type = 'in:' + object.type.toString()
        } else {
          newObject.type = null
        }
      }
      this.handleColumnSearch(newObject)
    },
    getFilterSortParams() {
      const sortParams = getSortParams(this.sortByMap)
      const filterSortParams = R.merge(sortParams, this.columnFilterParams)
      return R.reject(R.equals(''))(filterSortParams)
    },

    handleCommand(value, scope) {
      switch (value) {
        case 'update':
          this.handleUpdateProduct(scope)
          break
        case 'delete':
          this.handleDeleteProduct(scope.row)
          break
        case 'deletePdf':
          this.handleDeleteFile(scope.row.id)
          break
        case 'showPdf':
          this.handleShowFile(scope.row.id)
          break
        case 'addPlan':
          this.handleAddPlan(scope)
          break
        case 'showPlans':
          this.handleShowPlans(scope.row.id)
          break
      }
    },

    handleAddPlan(scope) {
      this.addPlan = true
      this.product = deepClone(scope.row)
      this.plan.productId = scope.row.id
    },
    handleShowPlans(id) {
      this.$router.push({
        name: 'PlanManage',
        query: {
          productId: 'eq:' + id,
        },
      })
    },
    handleDeleteProduct(column) {
      const productName = column.productName ? column.productName : ''
      this.$confirm({
        content: '确认删除产品' + productName + '?',
        onOk: () => {
          deleteProduct(column.id).then((res) => {
            if (res) {
              this.$message.success('删除产品成功！')
              this.$refs.pagination.currentChange(this.currentPageNum)
            }
          })
        },
      })
    },
    handleDeleteFile(id) {
      // 这个ajax是为了判断当前项是否有文件
      const ajax = new XMLHttpRequest()
      ajax.open('GET', `${process.env.VUE_APP_BASE_API}products/${id}/file`)
      ajax.send()
      ajax.onreadystatechange = () => {
        if (ajax.status === 200 && ajax.readyState === 2) {
          ajax.abort() // 中断对文件的下载请求，防止浪费资源
          this.$confirm('确认删除此产品的原始客户工艺文件?', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.handleDeleteTip(
                id,
                deleteProductPdf,
                true,
                '删除原始客户工艺文件成功'
              )
            })
            .catch((err) => {
              console.log(err)
            })
        } else if (ajax.status === 400 && ajax.readyState === 2) {
          this.$notify({
            title: '文件不存在',
            dangerouslyUseHTMLString: true,
            message: `<div>当前产品没有原始客户工艺文件，无法删除</div>`,
            type: 'warning',
          })
        } else if (
          ajax.status !== 200 &&
          ajax.status !== 400 &&
          ajax.status !== 0
        ) {
          this.$notify({
            title: ajax.status,
            dangerouslyUseHTMLString: true,
            message: `<div>当前请求失败，请稍后重试</div>`,
            type: 'error',
          })
        }
      }
    },
    handleShowFile(id) {
      // 这个ajax是为了判断当前项是否有文件
      const ajax = new XMLHttpRequest()
      ajax.open('GET', `${process.env.VUE_APP_BASE_API}products/${id}/file`)
      ajax.send()
      ajax.onreadystatechange = () => {
        if (ajax.status === 200 && ajax.readyState === 2) {
          ajax.abort() // 中断对文件的下载请求，防止浪费资源
          window.open(
            `${process.env.VUE_APP_BASE_API}products/${id}/file`,
            '_blank'
          )
        } else if (ajax.status === 400 && ajax.readyState === 2) {
          this.$notify({
            title: '文件不存在',
            dangerouslyUseHTMLString: true,
            message: `<div>当前产品没有原始客户工艺文件，无法预览</div>`,
            type: 'warning',
          })
        } else if (
          ajax.status !== 200 &&
          ajax.status !== 400 &&
          ajax.status !== 0
        ) {
          this.$notify({
            title: ajax.status,
            dangerouslyUseHTMLString: true,
            message: `<div>当前请求失败，请稍后重试</div>`,
            type: 'error',
          })
        }
      }
    },
    handleProductModalConfirmed() {
      this.addProductModalVisible = false
      this.$refs.pagination.currentChange(this.currentPageNum)
    },
    handleProductModalCanceled() {
      this.addProductModalVisible = false
    },
    handleAddProduct() {
      this.currentEditType = '新增'
      this.currentProduct = {}
      this.addProductModalVisible = true
    },
    handleUpdateProduct(scope) {
      this.currentEditType = '更新'
      this.currentProduct = R.clone(scope.row)
      this.addProductModalVisible = true
    },
    confirm(formName) {
      const isEdit = this.dialogType === 'edit'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const displayField = [
            {
              productName: '产品名称',
            },
          ]
          delete this.product.createTime
          delete this.product.updateTime
          if (this.product.deliveryTime) {
            this.product.deliveryTime = handleTimeZone(
              formatDateTime(this.product.deliveryTime)
            ).substring(0, 19)
          }
          this.handleEditTip(
            this.product.id,
            this.product,
            updateProduct,
            addProduct,
            getProductById,
            '产品',
            displayField,
            isEdit
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    addPlanConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const clonePlan = deepClone(this.plan)
          if (this.plan.deliveryTime.getTime) {
            clonePlan.deliveryTime = handleTimeZone(
              formatDateTime(this.plan.deliveryTime.getTime())
            )
          }
          if (this.plan.deadline && this.plan.deadline.getTime) {
            clonePlan.deadline = handleTimeZone(
              formatDateTime(this.plan.deadline.getTime())
            )
          }
          if (this.plan.finishTime && this.plan.finishTime.getTime) {
            clonePlan.finishTime = handleTimeZone(
              formatDateTime(this.plan.finishTime.getTime())
            )
          }
          this.addPlan = false
          this.handleAddTip(
            this.product.id,
            clonePlan,
            addPlanByProduct,
            '计划'
          )
          this.plan = {}
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleDownloadTemplate() {
      downloadTemplate('orderProduct')
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`批量导入失败，请稍后再试`)
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      this.$info({
        title: '订单产品数据导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入数据总计：' + response.totalNum + '条;'),
          h('p', '非法数据条数：' + response.failedNum + ';'),
          h('p', '导入成功数据条数：' + response.successfulNum + ';'),
          h('p', '订单导入成功条数：' + response.orderSuccessNum + ';'),
          h('p', '产品导入成功条数：' + response.productSuccessNum + ';'),
          h('p', '非法数据信息:'),
          h('p', response.invalidMessages.join(' ||  ')),
        ]),
        onOk: () => {
          this.uploading = false
          this.$refs.pagination.currentChange(this.currentPageNum)
        },
      })
    },
    jumpToDashboardPage(planCode, type) {
      this.$router.push({
        path: '/dashboard',
        query: {
          planCode: planCode,
          tabType: type,
        },
      })
    },
    jumpToDashboardPageForPart(planId) {
      this.$router.push({
        path: '/dashboard',
        query: {
          planId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.app-container {
  .roles-table {
    margin-top: 30px;
  }

  /deep/ .el-table th {
    border: 1px solid #ebeef5;
  }

  .permission-tree {
    margin-bottom: 30px;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .disSel {
    display: none;
  }

  .export-product {
    float: right;
    bottom: -16px;
    right: 0px;
  }

  .demo-drawer {
    .demo-drawer__content {
      margin: 30px;

      .el-drawer__body {
        overflow: auto;
      }
    }

    .demo-drawer__footer {
      display: flex;
      justify-content: center;

      button {
        width: 47%;
      }
    }

    .el-drawer__body {
      overflow: auto;
    }
  }

  .search-drawer {
    /deep/ .el-drawer__body {
      overflow: auto;
    }
  }

  .el-icon-delete {
    cursor: pointer;

    &:hover {
      color: #409eff;
    }
  }

  .fixed-position {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .inline-button {
    display: inline-block;
  }

  .filename-delete {
    color: #ff4d4f;
  }
}
</style>
