import request from '@/utils/api.request'

/**
 * 分页获取客户
 * @returns
 */
export function getTenants(data) {
  return request({
    url: `/tenants/clients`,
    method: 'get',
    params: data,
  })
}

export function getAllTenants(data) {
  return request({
    url: `/tenants/clients/all`,
    method: 'get',
    params: data,
  })
}

export function addTenants(data) {
  return request({
    url: '/tenants',
    method: 'post',
    data,
  })
}

export function updateTenants(id, data) {
  return request({
    url: `/tenants/${id}`,
    method: 'put',
    data,
  })
}

export function deleteTenants(id) {
  return request({
    url: `/tenants/${id}`,
    method: 'delete',
  })
}

export function getTenantsImportTemplate() {
  return request({
    url: `/tenants/clients/import-template`,
    method: 'get',
    responseType: 'blob',
  })
}

/**
 * 导出所有员工
 * @param {*} data 导出员工参数
 */
export function exportAllClients(data) {
  return request({
    url: `/tenants/clients/exports`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}
