<template>
  <a-modal class="modal-container" visible="true" :title="title" :mask-closable="false" width="800px" @cancel="handleCancel">
    <template slot="footer">
      <a-button :loading="loading" @click="handleReset">重置</a-button>
      <a-button :loading="loading" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-transfer
      class="tree-transfer"
      :data-source="resourcesData"
      :list-style="{
        width: '355px',
        height: '500px'
      }"
      show-search
      :target-keys="targetKeys"
      :titles="['未拥有权限', '已拥有权限']"
      :render="item => `${item.title}`"
      @change="handleChange"
      @search="handleSearch"
      @selectChange="handleSelectChange"
    >
      <template slot="children" slot-scope="{ props: { direction, selectedKeys }, on: { itemSelectAll } }">
        <a-tree
          v-if="direction === 'left'"
          v-model="checkedKeys"
          checkable
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :selected-keys="selectedKeys"
          :tree-data="treeData"
          @expand="onExpand"
          @check="(_, props) => {onCheck(_,props,checkedKeys, itemSelectAll)}"
        />
      </template>
      <span slot="notFoundContent">暂无数据</span>
    </a-transfer>
  </a-modal>
</template>
<script>
let resourcesData = []
let orginTreeData = []
function flatten(list = []) {
  list.forEach((item) => {
    resourcesData.push(item)
    flatten(item.children)
  })
}
function flattenChildKey(list) {
  let keys = []
  // 获取树形数组所有的key
  function _flatten(list) {
    list.forEach((item) => {
      if (item.children.length) {
        _flatten(item.children)
      }
      keys.push(item.key)
    })
  }
  _flatten(list)

  // 获取重复项
  function _filter(list) {
    keys = list.filter((item, index, self) => {
      return self.indexOf(item) !== index
    })
  }
  _filter(keys)

  const res = []
  list.forEach((val) => {
    console.log(val)
    if (keys.indexOf(val.key) === -1) {
      res.push(val)
    }
  })
  return res
}
function handleTreeData(data, targetKeys = []) {
  data.forEach((item) => {
    item['disabled'] = targetKeys.includes(item.key)
    if (item.children) {
      handleTreeData(item.children, targetKeys)
    }
  })
  return data
}
import {
  getResourceTree,
  getResourcesByRoleId,
  updateResourcesByRoleId,
} from '@/api/role'
export default {
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      title: '权限设置',
      loading: false,
      resourcesData: resourcesData,
      targetKeys: [],
      treeData: [],
      searchData: [],
      expandedKeys: [],
      autoExpandParent: false,
      checkedKeys: [],
      lastCheckedKeys: [],
      selectedKeys: [],
    }
  },
  mounted() {
    if (this.userId) {
      this.getResourcesData()
    }
  },
  methods: {
    clearData() {
      resourcesData = []
      this.treeData = []
      this.checkedKeys = []
      this.lastCheckedKeys = []
      this.selectedKeys = []
    },
    getResourcesData() {
      getResourceTree().then((res) => {
        if (res) {
          this.treeData = res.data
          orginTreeData = this.treeData
          flatten(JSON.parse(JSON.stringify(this.treeData)))
        }
        getResourcesByRoleId(this.userId).then((res) => {
          if (res.data.length) {
            this.targetKeys = res.data
            this.treeData = handleTreeData(this.treeData, this.targetKeys)
          }
        })
      })
    },
    setUserResourcesByUserId() {
      this.loading = true
      const successMessage = '设置成功'
      const failMessage = '设置失败，请稍后重试'
      updateResourcesByRoleId(this.userId, this.targetKeys).then((res) => {
        if (res.data && res.data.id) {
          this.$emit('confirm')
          this.clearData()
          this.$message.success(successMessage)
        } else {
          this.$message.error(failMessage)
        }
      })
    },
    handleReset() {
      this.getResourcesData()
    },
    handleConfirm() {
      this.setUserResourcesByUserId()
    },
    handleCancel() {
      this.clearData()
      this.$emit('cancel')
    },
    handleChange(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys
      // 更新左侧筛选栏可选中状态
      this.treeData = handleTreeData(this.treeData, this.targetKeys)

      // 更新源数据可选中状态
      this.resourcesData.map((val) => {
        if (val['disabled']) {
          val['disabled'] = false
        }
      })
      console.log('resourceData', this.resourcesData)
      // 回退操作时，取消选中状态
      if (direction === 'left') {
        const arr = this.checkedKeys.filter(
          (item) => moveKeys.indexOf(item) === -1
        )
        this.checkedKeys = arr
        this.lastCheckedKeys = []
      }
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck(_, e, checkedKeys, itemSelectAll) {
      // 选中某项
      if (this.checkedKeys.length > this.lastCheckedKeys.length) {
        this.lastCheckedKeys = this.checkedKeys
        itemSelectAll(this.checkedKeys, true)
      } else {
        // 过滤出取消选择的项
        const arr = this.lastCheckedKeys.filter(
          (item) => this.checkedKeys.indexOf(item) === -1
        )
        this.lastCheckedKeys = this.checkedKeys
        itemSelectAll(arr, false)
      }
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys]
      this.checkedKeys = this.selectedKeys
      this.lastCheckedKeys = this.checkedKeys
    },
    handleSearch(dir, value) {
      if (dir === 'left') {
        let res = []
        if (!value) {
          this.treeData = handleTreeData(orginTreeData, this.targetKeys)
          return
        }
        this.resourcesData.map((val) => {
          if (val.title.indexOf(value) !== -1) {
            res.push(val)
          }
        })
        // 去重
        res = flattenChildKey(res)
        console.log(res)
        // this.treeData = handleTreeData(res, this.targetKeys)
        this.treeData = res
        console.log(res, this.resourcesData)
      }
    },
  },
}
</script>
<style scoped>
.tree-transfer >>> .ant-transfer-list-body {
  overflow-y: scroll;
}

.tree-transfer >>> .ant-transfer-list-content {
  overflow: unset;
}
</style>
