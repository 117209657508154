var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.title,
        maskClosable: false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.tenants,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "企业名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: { maxLength: 20, placeholder: "请输入企业名称" },
                model: {
                  value: _vm.tenants.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.tenants,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "tenants.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "企业编码", prop: "code" } },
            [
              _c("a-input", {
                attrs: { maxLength: 20, placeholder: "请输入企业编码" },
                model: {
                  value: _vm.tenants.code,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.tenants,
                      "code",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "tenants.code",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "信用代码", prop: "creditCode" } },
            [
              _c("a-input", {
                attrs: { maxLength: 20, placeholder: "请输入信用代码" },
                model: {
                  value: _vm.tenants.creditCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.tenants,
                      "creditCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "tenants.creditCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "企业简称", prop: "nickName" } },
            [
              _c("a-input", {
                attrs: { maxLength: 20, placeholder: "请输入企业简称" },
                model: {
                  value: _vm.tenants.nickName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.tenants,
                      "nickName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "tenants.nickName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "企业地址", prop: "address" } },
            [
              _c("a-input", {
                attrs: { maxLength: 20, placeholder: "请输入企业地址" },
                model: {
                  value: _vm.tenants.address,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.tenants,
                      "address",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "tenants.address",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "企业电话", prop: "telephone" } },
            [
              _c("a-input", {
                attrs: { maxLength: 20, placeholder: "请输入企业电话" },
                model: {
                  value: _vm.tenants.telephone,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.tenants,
                      "telephone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "tenants.telephone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "传真", prop: "fax" } },
            [
              _c("a-input", {
                attrs: { maxLength: 20, placeholder: "请输入传真" },
                model: {
                  value: _vm.tenants.fax,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.tenants,
                      "fax",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "tenants.fax",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "联系人姓名", prop: "contactsInfo.name" } },
            [
              _c("a-input", {
                attrs: { maxLength: 20, placeholder: "请输入联系人姓名" },
                model: {
                  value: _vm.tenants.contactsInfo[0].name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.tenants.contactsInfo[0],
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "tenants.contactsInfo[0].name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "联系人电话", prop: "contactsInfo.telephone" } },
            [
              _c("a-input", {
                attrs: { maxLength: 20, placeholder: "请输入联系人电话" },
                model: {
                  value: _vm.tenants.contactsInfo[0].telephone,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.tenants.contactsInfo[0],
                      "telephone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "tenants.contactsInfo[0].telephone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "联系人地址", prop: "contactsInfo.address" } },
            [
              _c("a-input", {
                attrs: { maxLength: 20, placeholder: "请输入联系人地址" },
                model: {
                  value: _vm.tenants.contactsInfo[0].address,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.tenants.contactsInfo[0],
                      "address",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "tenants.contactsInfo[0].address",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }