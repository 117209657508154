/*!
 * 计划页 Table表头展示数据
 */

// 计划表头数据列
export const planColumns = [
  {
    title: '计划编号',
    dataIndex: 'code',
    key: 'plan.code',
    width: 150,
    fixed: "left",
    align: 'center',
    sorter: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'name',
    },
  },
  {
    title: '产品名称',
    width: 150,
    fixed: "left",
    dataIndex: 'productName',
    key: 'productName',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '图号',
    width: 250,
    fixed: "left",
    dataIndex: 'drawingNum',
    key: 'drawingNum',
    ellipsis: false,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    sorter: true,
  },
  {
    title: '计划状态',
    width: 150,
    fixed: "left",
    dataIndex: 'status',
    key: 'status',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '制定中',
        value: 0,
      },
      {
        text: '工艺编制中',
        value: 1,
      },
      {
        text: '进行中',
        value: 2,
      },
      {
        text: '已完成',
        value: 3,
      },
      {
        text: '已取消',
        value: 8,
      },
      {
        text: '暂停中',
        value: 9,
      },
    ],
    align: 'center',
  },
  {
    title: '订单数量',
    width: 150,
    fixed: "left",
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '订单编号',
    dataIndex: 'orderCode',
    key: 'orderCode',
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    sorter: true,
  },
  {
    title: '客户需求',
    dataIndex: 'clientDemand',
    key: 'clientDemand',
    align: 'center',
    ellipsis: true,
  },



  {
    title: '规格',
    dataIndex: 'specification',
    key: 'specification',
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    sorter: true,
  },
  {
    title: '单位',
    dataIndex: 'unit',
    key: 'unit',
    align: 'center',
  },
  {
    title: '客户名称',
    dataIndex: 'client',
    key: 'client',
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产值单价',
    dataIndex: 'productionPrice',
    key: 'productionPrice',
    align: 'center',
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '金额(无税)',
    dataIndex: 'totalMoneyTaxExclued',
    key: 'totalMoneyTaxExclued',
    align: 'center',
    sorter: true,
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '订单签约日期',
    dataIndex: 'signTime',
    key: 'signTime',
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '合同交货日期',
    dataIndex: 'deadline',
    key: 'deadline',
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '启动生产日期',
    dataIndex: 'startTime',
    key: 'plan.startTime',
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品交货日期',
    dataIndex: 'deliverTime',
    key: 'deliverTime',
    align: 'center',
    ellipsis: true,
    sorter: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户订单号',
    dataIndex: 'clientOrderCode',
    key: 'clientOrderCode',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品物料编码',
    dataIndex: 'clientMaterialCode',
    key: 'clientMaterialCode',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品计划号',
    dataIndex: 'clientPlanCode',
    key: 'clientPlanCode',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '库房号',
    dataIndex: 'clientWarehouseCode',
    key: 'clientWarehouseCode',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户合同号',
    dataIndex: 'clientContractCode',
    key: 'clientContractCode',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品类别',
    dataIndex: 'productCategory',
    key: 'productCategory',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      customRender: 'productCategory',
    },
    filters: [
      {
        text: '单体',
        value: 'MLJ',
      },
      {
        text: '单体轴类',
        value: 'ZLJ',
      },
      {
        text: '单体非轴类',
        value: 'OLJ',
      },
      {
        text: '组合',
        value: 'CLJ',
      },
    ],
  },
  {
    title: '材料类别',
    dataIndex: 'materialCategories',
    key: 'materialCategories',
    width: 150,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '备注',
    dataIndex: 'comment',
    key: 'comment',
    width: 100,
    ellipsis: true,
    searchType: 'text',
    align: 'center',
  },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    align: 'center',
    width: 80,
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

// 任务表头数据列
export const taskColumns = [
  {
    title: '任务编码',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    scopedSlots: {
      customRender: 'name',
    },
  },
  {
    title: '任务类型',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    scopedSlots: {
      customRender: 'tags',
    },
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '投产数量',
    dataIndex: 'productionNum',
    key: 'productionNum',
    align: 'center',
  },
  {
    title: '入库数量',
    dataIndex: 'putInNum',
    key: 'putInNum',
    align: 'center',
  },
  {
    title: '在产数量',
    dataIndex: 'producingNum',
    key: 'producingNum',
    align: 'center',
  },
  {
    title: '完成日期',
    dataIndex: 'finishTime',
    key: 'finishTime',
    align: 'center',
  },
  {
    title: '出库数量',
    dataIndex: 'takeOutNum',
    key: 'takeOutNum',
    align: 'center',
  },
  {
    title: '出库日期',
    dataIndex: 'takeOutTime',
    key: 'takeOutTime',
    align: 'center',
  },
  {
    title: '报废数量',
    dataIndex: 'abandonNum',
    key: 'abandonNum',
    align: 'center',
  },
  {
    title: '退货数量',
    dataIndex: 'returnedNum',
    key: 'returnedNum',
    align: 'center',
  },
  {
    title: '返修数量',
    dataIndex: 'repairedNum',
    key: 'repairedNum',
    align: 'center',
  },
  {
    title: '退货时间',
    dataIndex: 'returnedTime',
    key: 'returnedTime',
    align: 'center',
  },
  {
    title: '超差数量',
    dataIndex: 'outOfToleranceNum',
    key: 'outOfToleranceNum',
    align: 'center',
  },
  {
    title: '任务备注',
    dataIndex: 'comment',
    key: 'comment',
    width: 300,
    ellipsis: true,
  },
]
