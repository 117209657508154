<template>
  <a-modal :visible="true" :title="title+&quot;任务&quot;" :mask-closable="false" width="700px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">{{ modalTitle }}</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item ref="code" label="任务编号" prop="code">
        <a-input v-model.trim="form.code" :max-length="20" placeholder="请输入任务编号" style="width: 300px" />
      </a-form-model-item>
      <a-form-model-item label="任务类型" prop="type">
        <a-select v-model="form.type" default-value="4" style="width: 300px">
          <a-icon slot="suffixIcon" type="smile" />
          <template v-if="taskType === 'BF'">
            <a-select-option disabled :value="2">生产补投任务</a-select-option>
          </template>
          <template v-else>
            <a-select-option :value="0">普通任务</a-select-option>
            <a-select-option :value="4">普通补投任务</a-select-option>
            <a-select-option :value="1">返修任务</a-select-option>
            <a-select-option :value="3">协作任务</a-select-option>
            <a-select-option :value="2">生产补投任务</a-select-option>
          </template>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="任务紧急类型" prop="emgType">
        <a-select v-model="form.emgType" style="width: 300px">
          <a-select-option value="NML">普通</a-select-option>
          <a-select-option value="MFN">必完件</a-select-option>
          <a-select-option value="UGC">紧急件</a-select-option>
          <a-select-option value="IUG">插入紧急件</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.type===1&&isAdd" label="退货原因" prop="abandonReasonValues">
        <a-select
          v-model="form.abandonReasonValues"
          label-in-value
          mode="multiple"
          placeholder="请选择退货原因"
          style="width: 300px"
          @change="handleChange"
        >
          <a-select-option v-for="item in filteredOptions" :key="item.id" :value="item.id">{{ item.parameterValue }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item v-if="form.type===1" label="处置方式" prop="repairEffect">
        <a-select v-model="form.repairEffect" style="width: 300px">
          <a-select-option value="FX">返修</a-select-option>
          <a-select-option value="BT">报废补投</a-select-option>
          <a-select-option value="TH">退回客户</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item v-if="form.type===1 && form.repairEffect === 'FX'" label="返修要求" prop="defeatDemand">
        <a-input v-model="form.defeatDemand" style="width: 300px" />
      </a-form-model-item>

      <a-form-model-item v-if="form.type===1 && form.repairEffect === 'FX'" label="返修工序" prop="repairedOperationIds">
        <a-select
          v-model="form.repairedOperationIds"
          show-search
          mode="multiple"
          placeholder="请选择返修工序"
          style="width: 300px"
          option-filter-prop="children"
        >
          <a-select-option
            v-for="operation in currentOperationList"
            :key="operation.id"
            :value="operation.id"
          >{{ operation.operationName }}({{ operation.operationCode }})</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item v-if="form.type===1" label="反馈描述" prop="contactRecord">
        <a-input v-model="form.contactRecord" style="width: 300px" />
      </a-form-model-item>

      <a-form-model-item v-if="form.type===1" label="核实情况" prop="defeatDesc">
        <a-input v-model="form.defeatDesc" style="width: 300px" />
      </a-form-model-item>
      <a-form-model-item label="任务截止时间" prop="taskDeliveryTime">
        <a-date-picker
          v-model="form.taskDeliveryTime"
          style="width: 300px"
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          type="date"
          placeholder="请选择任务截止时间"
        />
      </a-form-model-item>
      <a-form-model-item label="期望下料时间" prop="expectMaterialTime">
        <a-date-picker
          v-model="form.expectMaterialTime"
          style="width: 300px"
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          type="date"
          placeholder="请选择期望下料时间"
        />
      </a-form-model-item>
      <a-form-model-item has-feedback label="投产数量" prop="productionNum">
        <a-input v-model.number="form.productionNum" style="width: 300px" />
      </a-form-model-item>
      <a-form-model-item v-if="form.type===3" has-feedback label="产值价格" prop="taskPrice">
        <a-input-number v-model="form.taskPrice" style="width: 300px" :precision="2" :min="0" :max="99999" placeholder="请输入产值价格" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="comment">
        <a-input v-model.trim="form.comment" :max-length="1024" placeholder="请输入任务备注(不超过1024个字)" type="textarea" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import * as R from 'ramda'

import { updateTask } from '@/api/task'
import { addTaskByPlan } from '@/api/plan'
import { getAllOperations } from '@/api/operation'
import { getAllParametersByParentCode } from '@/api/parameter'
import Moment from 'moment'
export default {
  props: {
    modalTitle: String,
    taskForm: Object,
    currentTaskType: String,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      if (value !== 0 && !value) {
        return callback(new Error('请输入数量'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字'))
      } else if (value <= 0 || value > 999999) {
        callback(new Error('请输入不超过999999的正整数'))
      } else {
        callback()
      }
    }
    return {
      title: this.modalTitle,
      taskType: this.currentTaskType,
      labelCol: {
        span: 5,
        offset: 2,
      },
      wrapperCol: {
        span: 10,
        offset: 1,
      },
      loading: false,
      allDefeatReasons: [],
      form: {
        id: this.taskForm.id,
        planId: this.taskForm.planId,
        code:
          this.currentTaskType === 'BF'
            ? this.taskForm.taskCode
            : this.taskForm.code,
        type: this.currentTaskType === 'BF' ? 2 : this.taskForm.type,
        productionNum:
          this.currentTaskType === 'BF'
            ? this.taskForm.productAmount
            : this.taskForm.productionNum,
        status:
          this.currentTaskType === 'BF'
            ? this.taskForm.taskStatus
            : this.taskForm.status,
        abandonReasonIds: [],
        abandonReasonValues: [],
        taskPrice: this.taskForm.taskPrice,
        taskDeliveryTime: this.taskForm.taskDeliveryTime,
        expectMaterialTime: this.taskForm.expectMaterialTime,
        comment: this.taskForm.comment,
        emgType: this.taskForm.emgType || 'NML', // NML - 普通 MFN - 必完件 UGC - 紧急件 IUG - 插入紧急件
        repairEffect: this.taskForm.repairEffect,
        contactRecord: this.taskForm.contactRecord,
        defeatDesc: this.taskForm.defeatDesc,
        defeatDemand: this.taskForm.defeatDemand,
        repairedOperationIds: this.taskForm.repairedOperationIds,
      },
      currentOperationList: [],
      rules: {
        code: [
          {
            required: true,
            message: '请输入任务编号',
            trigger: 'blur',
          },
        ],
        type: [
          {
            required: true,
            message: '请选择一种类型',
            trigger: 'change',
          },
        ],
        productionNum: [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change',
          },
        ],
        taskPrice: [
          {
            required: true,
            trigger: 'change',
          },
        ],
        taskDeliveryTime: [
          {
            required: true,
            message: '请选择日期',
            trigger: 'change',
          },
        ],
        emgType: [
          {
            required: true,
            message: '请选择任务紧急类型',
            trigger: 'blur',
          },
        ],
        contactRecord: [
          {
            required: true,
            message: '请选择反馈描述',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function () {
      return this.modalTitle === '新增'
    },
    isUpdate: function () {
      return this.modalTitle === '更新'
    },
    filteredOptions() {
      return this.allDefeatReasons.filter(
        (o) => !this.form.abandonReasonValues.includes(o.id)
      )
    },
  },
  mounted() {
    this.getAllOperations()
    this.handleGetDefeatParameter()
  },

  methods: {
    getAllOperations() {
      getAllOperations(this.operationQueryParams).then((res) => {
        if (res.data.operationList) {
          if (R.isEmpty(this.operationQueryParams)) {
            this.allOperationList = R.clone(res.data.operationList)
          }
          this.currentOperationList = R.clone(res.data.operationList)
        }
      })
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      console.log(this.form)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.taskDeliveryTime) {
            this.form.taskDeliveryTime = Moment(
              this.form.taskDeliveryTime
            ).format('YYYY-MM-DD HH:mm:ss')
          }
          if (this.form.expectMaterialTime) {
            this.form.expectMaterialTime = Moment(
              this.form.expectMaterialTime
            ).format('YYYY-MM-DD HH:mm:ss')
          }
          if (this.isAdd) {
            this.handleAddTask()
          } else if (this.isUpdate) {
            this.handleUpdateTask()
          }
        } else {
          return false
        }
      })
    },
    handleAddTask() {
      this.loading = true
      if (this.form.abandonReasonValues.length > 0) {
        this.form.abandonReasonIds = this.form.abandonReasonValues.map(
          (item) => {
            return item.key
          }
        )
      }
      const _form = {
        ...this.form,
      }
      if (this.taskType === 'BF') {
        _form.qualityId = this.taskForm.id
      }
      addTaskByPlan(this.form.planId, _form)
        .then((res) => {
          if (res.data) {
            const successMessage = this.modalTitle + '任务成功！'
            const failMessage = this.modalTitle + '任务失败，请重试！'
            this.loading = false
            this.$message.success(successMessage)
            this.$emit('confirm')
          } else {
            this.loading = false
            this.$message.error(successMessage)
          }
        })
        .catch((reason) => {
          this.loading = false
          console.log('处理失败的 promise (' + reason + ')')
        })
    },
    handleUpdateTask() {
      this.loading = true
      updateTask(this.form.id, this.form)
        .then((res) => {
          if (res.data) {
            const successMessage = this.modalTitle + '任务成功！'
            const failMessage = this.modalTitle + '任务失败，请重试！'
            this.loading = false
            this.$message.success(successMessage)
            this.$emit('confirm')
          } else {
            this.loading = false
            this.$message.error(successMessage)
          }
        })
        .catch((reason) => {
          this.loading = false
          console.log('处理失败的 promise (' + reason + ')')
        })
    },
    handleGetDefeatParameter() {
      getAllParametersByParentCode('RT_QXYY').then((res) => {
        if (res.data) {
          this.allDefeatReasons = res.data
        }
      })
    },
    handleChange(selectedItems) {
      this.abandonReasonValues = selectedItems
    },
  },
}
</script>

<style scoped>
.searchFilterIcon {
  position: absolute;
  top: 10px;
  right: 0;
  padding-right: 5px;
  padding-left: 15px;
  padding-bottom: 20px;
}

.searchFilterIcon ~ .el-table__column-filter-trigger {
  display: none;
}

.searchButton {
  width: 90px;
  margin-right: 8px;
}

.resetButton {
  width: 90px;
}

.searchInput {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}
/deep/ .ant-form-item {
  margin-bottom: 8px;
}
</style>
