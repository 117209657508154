export const columns = [
  {
    title: '产品名称',
    dataIndex: 'productName',
    key: 'productName',
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '图号',
    dataIndex: 'drawingNum',
    key: 'drawingNum',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '规格',
    dataIndex: 'specification',
    key: 'specification',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '库存数量',
    dataIndex: 'totalStockCount',
    key: 'totalStockCount',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
]
