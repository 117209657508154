export const resourceColumns = [
  {
    title: '资源编码',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '资源描述',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '资源地址',
    dataIndex: 'uri',
    key: 'uri',
    sorter: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '资源类型',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    scopedSlots: {
      customRender: 'resourceType'
    },
    filters: [
      {
        text: '根节点',
        value: 0
      },
      {
        text: '页面',
        value: 1
      },
      {
        text: 'API',
        value: 2
      },
      {
        text: '父模块',
        value: 3
      }
    ]
  },
  {
    title: '资源请求方法',
    dataIndex: 'method',
    key: 'method',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '操作',
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate'
    }
  }
]
