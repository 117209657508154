var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "header-button",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline",
                  },
                  on: { click: _vm.handleAddProduct },
                },
                [_vm._v("新增产品")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        staticClass: "users-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          columns: _vm.productColumns,
          "data-source": _vm.productList,
          loading: _vm.loading,
          pagination: _vm.pagination,
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "operate",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-user" },
                [
                  column.status !== 2
                    ? _c(
                        "a-dropdown",
                        [
                          _c(
                            "a-menu",
                            {
                              staticClass: "ant-dropdown-link",
                              attrs: { slot: "overlay" },
                              slot: "overlay",
                            },
                            [
                              _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdateProduct(column)
                                    },
                                  },
                                },
                                [_vm._v("更新产品信息")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a",
                            { staticClass: "user-btn" },
                            [
                              _vm._v(" 操作 "),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
      _vm.productModalVisible
        ? _c("ProductLibraryModal", {
            attrs: {
              productRecord: _vm.currentEditProduct,
              type: _vm.currentEditType,
            },
            on: {
              confirm: _vm.handleProductModalConfirm,
              cancel: _vm.handleProductModalCancel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }