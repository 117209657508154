export const columns = [
  {
    title: '出库单号',
    width: 170,
    dataIndex: 'takeOutNumber',
    key: 'takeOutNumber',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'takeOutNumber',
    },
  },
  {
    title: '出库时间',
    width: 170,
    dataIndex: 'takeOutTime',
    key: 'takeOutTime',
    align: 'center',
    ellipsis: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务编码',
    width: 150,
    dataIndex: 'taskCode',
    key: 'taskCode',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'taskCode',
    },
  },
  {
    title: '产品名称',
    width: 150,
    dataIndex: 'productName',
    key: 'productName',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'productName',
    },
  },
  {
    title: '图号',
    width: 150,
    dataIndex: 'drawingNum',
    key: 'drawingNum',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'drawingNum',
    },
  },
  {
    title: '规格',
    width: 150,
    dataIndex: 'specification',
    key: 'specification',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'specification',
    },
  },
  {
    title: '单位',
    width: 150,
    dataIndex: 'unit',
    key: 'unit',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'unit',
    },
  },
  {
    title: '数量',
    width: 150,
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'amount',
    },
  },
  {
    title: '单价(无税)',
    width: 150,
    dataIndex: 'taxFreePrice',
    key: 'taxFreePrice',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '金额(无税)',
    width: 150,
    dataIndex: 'totalTaxFreePrice',
    key: 'totalTaxFreePrice',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'totalTaxFreePrice',
    },
  },
  {
    title: '税率',
    width: 150,
    dataIndex: 'taxRate',
    key: 'taxRate',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'taxRate',
    },
  },

  {
    title: '单价(含税)',
    width: 130,
    dataIndex: 'taxPrice',
    key: 'taxPrice',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'taxPrice',
    },
  },
  {
    title: '金额(含税)',
    width: 130,
    dataIndex: 'totalTaxPrice',
    key: 'totalTaxPrice',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'totalTaxPrice',
    },
  },
  {
    title: '订单编码',
    width: 150,
    dataIndex: 'orderCode',
    key: 'orderCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'orderCode',
    },
  },
  {
    title: '客户物料代码',
    width: 170,
    dataIndex: 'clientMaterialCode',
    key: 'clientMaterialCode',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientMaterialCode',
    },
  },
  {
    title: '客户计划编号',
    width: 150,
    dataIndex: 'clientPlanCode',
    key: 'clientPlanCode',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientPlanCode',
    },
  },
  {
    title: '客户合同编码',
    width: 150,
    dataIndex: 'clientContractCode',
    key: 'clientContractCode',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientContractCode',
    },
  },
  {
    title: '客户采购订单编码',
    width: 200,
    dataIndex: 'clientPurchaseOrderCode',
    key: 'clientPurchaseOrderCode',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientPurchaseOrderCode',
    },
  },
  {
    title: '客户订单编码',
    width: 150,
    dataIndex: 'clientOrderCode',
    key: 'clientOrderCode',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientOrderCode',
    },
  },
  {
    title: '客户产品库房号码',
    width: 200,
    dataIndex: 'clientWarehouseCode',
    key: 'clientWarehouseCode',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'clientWarehouseCode',
    },
  },
  {
    title: '客户名称',
    width: 150,
    dataIndex: 'client',
    key: 'client',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'client',
    },
  },
  {
    title: '计划编码',
    width: 150,
    dataIndex: 'planCode',
    key: 'planCode',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'planCode',
    },
  },

  {
    title: '是否分件',
    width: 130,
    dataIndex: 'isParts',
    key: 'isParts',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'isParts',
    },
  },
  {
    title: '库房类型',
    width: 150,
    dataIndex: 'recordWarehouseType',
    key: 'recordWarehouseType',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      customRender: 'warehouseType',
    },
  },

  {
    title: '备注',
    width: 150,
    key: 'settleComment',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
]

export const inventoryRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({

  }),
}
