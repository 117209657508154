var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title,
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c("a-form-model-item", { attrs: { label: _vm.label } }, [
            _vm.inventoryData
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.type === "save"
                        ? _vm.inventoryData.putInNumber
                        : _vm.inventoryData.takeOutNumber
                    )
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.type === "out"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "模板", prop: "template" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      staticStyle: { width: "400px", fontWeight: "400" },
                      model: {
                        value: _vm.template,
                        callback: function ($$v) {
                          _vm.template = $$v
                        },
                        expression: "template",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("模板1")]),
                      _c("a-radio", { attrs: { value: 2 } }, [
                        _vm._v("模板2（渝江）"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }