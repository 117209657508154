var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.code + "报出",
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "税率", prop: "taxRate" } },
            [
              _c("a-input-number", {
                staticStyle: {
                  width: "200px",
                  "min-height": "30px",
                  "line-height": "30px",
                },
                attrs: {
                  size: "small",
                  placeholder: "请输入税率",
                  min: 0,
                  max: 1,
                  precision: 2,
                },
                model: {
                  value: _vm.form.taxRate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taxRate", $$v)
                  },
                  expression: "form.taxRate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "付款方式", prop: "payType" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.form.payType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "payType", $$v)
                    },
                    expression: "form.payType",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "CSH" } }, [_vm._v("现金")]),
                  _c("a-radio", { attrs: { value: "BNK" } }, [
                    _vm._v("银行转账"),
                  ]),
                  _c("a-radio", { attrs: { value: "CRD" } }, [_vm._v("挂账")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "交货方式", prop: "deliveryType" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.form.deliveryType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "deliveryType", $$v)
                    },
                    expression: "form.deliveryType",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "SND" } }, [_vm._v("送货")]),
                  _c("a-radio", { attrs: { value: "SLF" } }, [_vm._v("自取")]),
                  _c("a-radio", { attrs: { value: "EXP" } }, [_vm._v("快递")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }