import request from '@/utils/api.request'

/**
 * 月目标模块
 */

/**
 * 分页获取目标信息
 * @param {object} data
 */
export function getTargets(data) {
  return request({
    url: `/targets`,
    method: 'get',
    params: data
  })
}

/**
 * 新增目标信息
 * @param {object} data
 */
export function addTarget(data) {
  return request({
    url: `/targets`,
    method: 'post',
    data
  })
}

/**
 * 更新指定目标信息
 * @param {object} data
 */
export function updateTarget(id, data) {
  return request({
    url: `/targets/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除指定目标
 * @param {number} id
 */
export function deleteTarget(id) {
  return request({
    url: `/targets/${id}`,
    method: 'delete'
  })
}

/**
 * 删除指定月目标的任务
 * @param {number} id
 */
export function deleteMonthTargetTask(id, data) {
  return request({
    url: `/targets/${id}/tasks`,
    method: 'delete',
    data
  })
}

/**
 * 获取任务信息
 * @param {number} id
 */
export function getAllTasks(targetId, data) {
  return request({
    url: `/targets/${targetId}/tasks`,
    method: 'get',
    params: data
  })
}

/**
 * 获取某个目标的任务
 * @param {number} id
 */
export function getTasksById(data) {
  return request({
    url: `/targets/tasks/enacted`,
    method: 'get',
    params: {
      ...data
    }
  })
}

/**
 * 大批量获取目标的任务
 * @param {number} id
 */
export function batchTasksById(data) {
  return request({
    url: `/targets/tasks/simple-enacted`,
    method: 'get',
    params: {
      ...data
    }
  })
}

/**
 * 添加任务
 * @param {number} id
 */
export function updateTasksById(id, data) {
  return request({
    url: `/targets/${id}/tasks`,
    method: 'post',
    data
  })
}

/**
 * 导出筛选任务
 * @param {*} data 导出任务参数
 */
export function exportFilteredTasks(data) {
  return request({
    url: `/targets/tasks/exports/filtered`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}

/**
 * 更新目标关联任务 紧急类型信息
 */
export function updateTaskEmergencyType(data) {
  return request({
    url: `/targets/tasks`,
    method: 'patch',
    data
  })
}

/**
 * 周目标模块
 */

/**
 * 分页获取月目标周目标与班组树形结构信息
 * @param {number} id
 */
export function getMonthTargetTree(data) {
  return request({
    url: `/targets/weeks`,
    method: 'get',
    params: data
  })
}

/**
 * 获取月目标内的所有周目标
 * @param {number} id
 */
export function getAllWeekTarget(mouthTargetId) {
  return request({
    url: `/targets/${mouthTargetId}/weeks/all`,
    method: 'get'
  })
}

/**
 * 新增周目标
 * @param {number} id
 */
export function addWeekTarget(id, data) {
  return request({
    url: `/targets/${id}/weeks`,
    method: 'post',
    data
  })
}

/**
 * 根据id更新周目标信息
 * @param {number} id
 */
export function updateWeekTarget(id, data) {
  return request({
    url: `/targets/weeks/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除指定目标
 * @param {number} id
 */
export function deleteWeekTarget(id) {
  return request({
    url: `/targets/weeks/${id}`,
    method: 'delete'
  })
}

/**
 * 删除指定周目标的任务
 * @param {number} id
 */
export function deleteWeekTargetTask(id, data) {
  return request({
    url: `/targets/weeks/${id}/tasks`,
    method: 'delete',
    data
  })
}

/**
 * 删除指定周目标下班组的任务
 * @param {number} id
 */
export function deleteWeekGroupTargetTask(targetId, groupId, data) {
  return request({
    url: `/targets/weeks/${targetId}/groups/${groupId}/tasks`,
    method: 'delete',
    data
  })
}

/**
 * 获取所有可制定周目标的任务信息
 * @param {number} id
 */
export function getWeekTargetTasks(id, data) {
  return request({
    url: `/targets/weeks/${id}/tasks`,
    method: 'get',
    params: data
  })
}

/**
 * 获取所有班组可制定周目标的任务信息
 * @param {number} id
 */
export function getWeekGroupTargetTasks(targetId, groupId, data) {
  return request({
    url: `/targets/weeks/${targetId}/groups/${groupId}/tasks`,
    method: 'get',
    params: data
  })
}

/**
 * 获取已制定周目标的任务信息  简单信息
 * @param {number} id
 */
export function getWeekSimpleEnactedTasks(id, data) {
  return request({
    url: `/targets/weeks/${id}/tasks/simple-enacted`,
    method: 'get',
    params: data
  })
}

/**
 * 获取已制定周目标的任务信息  详细信息
 * @param {number} id
 */
export function getWeekEnactedTasks(id, data) {
  return request({
    url: `/targets/weeks/${id}/tasks/enacted`,
    method: 'get',
    params: data
  })
}

/**
 * 获取班组对应的任务信息
 * @param {number} id
 */
export function getWeekGroupTasks(weekId, groupId, data) {
  return request({
    url: `/targets/weeks/${weekId}/groups/${groupId}/tasks/enacted`,
    method: 'get',
    params: data
  })
}

/**
 * 添加任务信息至指定周目标
 * @param {number} id
 */
export function addWeekTasks(targetId, data) {
  return request({
    url: `/targets/weeks/${targetId}/tasks`,
    method: 'post',
    data
  })
}

/**
 * 添加任务信息至对应班组
 * @param {number} id
 */
export function addGroupTasks(targetId, groupId, data) {
  return request({
    url: `/targets/weeks/${targetId}/groups/${groupId}/tasks`,
    method: 'post',
    data
  })
}

/**
 * 修改班组的目标数量
 * @param {number} id
 */
export function updateGroupTargetNum(targetId, groupId, taskId, data) {
  return request({
    url: `/targets/weeks/${targetId}/groups/${groupId}/tasks/${taskId}`,
    method: 'put',
    data
  })
}

/**
 * 日目标模块
 */

/**
 * 分页获取日目标周目标与班组树形结构信息
 * @param {number} id
 */
export function getWeekDayTargetTree(data) {
  return request({
    url: `/targets/days`,
    method: 'get',
    params: data
  })
}

/**
 * 分页获取日目标周目标与班组树形结构信息
 * @param {number} id
 */
export function getAllDayTarget(weekTargetId) {
  return request({
    url: `/targets/${weekTargetId}/days/all`,
    method: 'get'
  })
}

/**
 * 新增日目标
 * @param {number} id
 */
export function addDayTarget(id, data) {
  return request({
    url: `/targets/weeks/${id}/days`,
    method: 'post',
    data
  })
}

/**
 * 根据id更新日目标信息
 * @param {number} id
 */
export function updateDayTarget(id, data) {
  return request({
    url: `/targets/days/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除指定日目标
 * @param {number} id
 */
export function deleteDayTarget(id) {
  return request({
    url: `/targets/days/${id}`,
    method: 'delete'
  })
}

/**
 * 删除指定日目标的任务
 * @param {number} id
 */
export function deleteDayTargetTask(targetId, groupId, memberId, data) {
  return request({
    url: `/targets/days/${targetId}/groups/${groupId}/members/${memberId}/tasks`,
    method: 'delete',
    data
  })
}

/**
 * 获取所有可制定日目标的任务信息
 * @param {number} id
 */
export function getAllDayTargetTasks(targetId, groupId, memberId, data) {
  return request({
    url: `/targets/days/${targetId}/groups/${groupId}/members/${memberId}/tasks`,
    method: 'get',
    params: data
  })
}

/**
 * 获取已制定周目标的任务信息  简单信息
 * @param {number} id
 */
export function getDaySimpleEnactedTasks(targetId, groupId, memberId, data) {
  return request({
    url: `/targets/days/${targetId}/groups/${groupId}/members/${memberId}/tasks/simple-enacted`,
    method: 'get',
    params: data
  })
}

/**
 * 获取已制定日目标的任务信息  详细信息
 * @param {number} id
 */
export function getDayEnactedTasks(id, data) {
  return request({
    url: `/targets/days/${id}/tasks/enacted`,
    method: 'get',
    params: data
  })
}

/**
 * 获取日目标 班组对应的任务信息
 * @param {number} id
 */
export function getDayGroupTasks(targetId, groupId, data) {
  return request({
    url: `/targets/days/${targetId}/groups/${groupId}/tasks/enacted`,
    method: 'get',
    params: data
  })
}

/**
 * 获取班组成员对应的任务信息
 * @param {number} id
 */
export function getDayMemberTasks(targetId, groupId, memberId, data) {
  return request({
    url: `/targets/days/${targetId}/groups/${groupId}/members/${memberId}/tasks/enacted`,
    method: 'get',
    params: data
  })
}

/**
 * 添加任务信息至对应班组成员
 * @param {number} id
 */
export function addDayMemberTasks(targetId, groupId, memberId, data) {
  return request({
    url: `/targets/days/${targetId}/groups/${groupId}/members/${memberId}/tasks`,
    method: 'post',
    data
  })
}

/**
 * 导出筛选的目标
 * @param {number} id
 */
export function exportFilteredTarget(targetId, data) {
  return request({
    url: `/targets/${targetId}/tasks/enacted/exports`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}

/**
 * 获取加工路线
 * @param {number} id
 */
export function getTargetProcessRoutes(targetId, taskId) {
  return request({
    url: `/targets/${targetId}/tasks/${taskId}/process-routes`,
    method: 'get'
  })
}

/**
 * 修改班组成员的日目标数量
 * @param {number} id
 */
export function updateMemberDayTargetNum(
  targetId,
  groupId,
  memberId,
  taskId,
  data
) {
  return request({
    url: `/targets/weeks/${targetId}/groups/${groupId}/members/${memberId}/tasks/${taskId}`,
    method: 'put',
    data
  })
}

/**
 * 获取员工个人的任务
 * @param {number} id
 */
export function getUserDayTasks(data) {
  return request({
    url: `/targets/days/tasks/enacted`,
    method: 'get',
    params: data
  })
}

/**
 * 导出员工筛选的目标
 * @param {number} id
 */
export function exportUserFilteredTarget(data) {
  return request({
    url: `/targets/days/tasks/enacted/exports`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}
