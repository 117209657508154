<template>
  <div class="container">
    <div class="trends-change-wrapper">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">有效产出趋势</span>
          <div class="date-container">
            <a-radio-group class="date-type" v-model="validDimension">
              <a-radio-button value="DAY">日</a-radio-button>
              <a-radio-button value="WEEK">周</a-radio-button>
              <a-radio-button value="MONTH">月</a-radio-button>
            </a-radio-group>
            <p>日期:</p>
            <a-range-picker v-model="validDate" value-format="YYYY-MM-DD" :allowClear="false" />
          </div>
        </div>
        <div class="trends-change-inner">
          <div class="target-content" ref="valid">
          </div>
        </div>
      </div>
    </div>
    <div class="trends-change-wrapper">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">库存指数趋势</span>
          <div class="date-container">
            <p>日期:</p>
            <a-range-picker v-model="taskTrendsDate" value-format="YYYY-MM-DD" :allowClear="false" />
          </div>
        </div>
        <div class="trends-change-inner">
          <div class="target-content" ref="taskTrends">
          </div>
        </div>
      </div>
    </div>
    <div class="trends-change-wrapper">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">营运费用趋势</span>
          <div class="date-container">
            <a-radio-group class="date-type" v-model="costDimension">
              <a-radio-button value="DAY">日</a-radio-button>
              <a-radio-button value="WEEK">周</a-radio-button>
              <a-radio-button value="MONTH">月</a-radio-button>
            </a-radio-group>
            <p>日期:</p>
            <a-range-picker v-model="costDate" value-format="YYYY-MM-DD" :allowClear="false" />
          </div>
        </div>
        <div class="trends-change-inner">
          <div class="target-content" ref="cost">
          </div>
        </div>
      </div>
    </div>
    <div class="trends-change-wrapper">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">产品报废金额趋势</span>
          <div class="date-container">
            <a-radio-group class="date-type" v-model="scrapAmountDimension">
              <a-radio-button value="DAY">日</a-radio-button>
              <a-radio-button value="WEEK">周</a-radio-button>
              <a-radio-button value="MONTH">月</a-radio-button>
            </a-radio-group>
            <p>日期:</p>
            <a-range-picker v-model="scrapAmountDate" value-format="YYYY-MM-DD" :allowClear="false" />
          </div>
        </div>
        <div class="trends-change-inner">
          <div class="target-content" ref="scrapAmount">
          </div>
        </div>
      </div>
    </div>
    <div class="trends-change-wrapper">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">订单延误指数趋势</span>
          <div class="date-container">
            <p>日期:</p>
            <a-range-picker v-model="taskTrendsChangeDate" value-format="YYYY-MM-DD" :allowClear="false" />
          </div>
        </div>
        <div class="trends-change-inner">
          <div class="target-content" ref="taskTrendsChange">
          </div>
        </div>
      </div>
    </div>
    <div class="trends-change-wrapper">
      <div class="target-statistics">
        <div class="filter">
          <span class="title">平均生产周期趋势</span>
          <div class="date-container">
            <a-radio-group class="date-type" v-model="avgDateDimension">
              <a-radio-button value="WEEK">周</a-radio-button>
              <a-radio-button value="MONTH">月</a-radio-button>
            </a-radio-group>
            <p>日期:</p>
            <a-range-picker v-model="avgDate" value-format="YYYY-MM-DD" :allowClear="false" />
          </div>
        </div>
        <div class="trends-change-inner">
          <div class="target-content" ref="avgDate">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { Chart } from '@antv/g2';
import { getTaskTrends } from '@/api/tocDbr'
import { EParamsPrefix, EStaticParamsDimension, EStaticParamsType } from '@/enums'
import { DefaultOnlyDateFormat, lastHalfMonthDateArr, lastHalfYearDateArr, lastOneMonthDateArr, lastOneYearDateArr } from '@/common/consts'
import { ScrapAmountTextMap } from '@/enums/task';

export default {
  name: 'IntelligentScheduling',
  data() {
    return {
      taskTrendsDate: lastHalfMonthDateArr,
      taskTrendsData: [],
      taskTrendsChangeDate: lastHalfMonthDateArr,
      taskTrendsChangeData: [],
      costDate: lastHalfMonthDateArr,
      costDimension: EStaticParamsDimension.DAY,
      costData: [],
      scrapAmountDate: lastHalfMonthDateArr,
      scrapAmountDimension: EStaticParamsDimension.DAY,
      scrapAmountData: [],
      avgDate: lastHalfYearDateArr,
      avgDateDimension: EStaticParamsDimension.WEEK,
      avgDateData: [],
      validDate: lastOneMonthDateArr,
      validDimension: EStaticParamsDimension.DAY,
      validData: [],
    }
  },
  computed: {
    taskTrendsDateRangeFormat() {
      return (this.taskTrendsDate || lastHalfMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    },
    taskTrendsDateChangeRangeFormat() {
      return (this.taskTrendsChangeDate || lastHalfMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    },
    costDateRangeFormat() {
      return (this.costDate || lastHalfMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    },
    scrapAmountDateRangeFormat() {
      return (this.scrapAmountDate || lastHalfMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    },
    avgDateRangeFormat() {
      return (this.avgDate || lastOneMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    },
    validDateRangeFormat() {
      return (this.validDate || lastHalfMonthDateArr).map(d => moment(d).format(DefaultOnlyDateFormat))
    }
  },
  mounted() {
    // this.taskTrendsChart = this.renderBarChart(this.$refs.taskTrends);
    // this.taskTrendsChangeChart = this.renderBarChangeChart(this.$refs.taskTrendsChange);

    this.getTaskTrendsData()
    this.getTaskTrendsChangeData()
    this.getCostData()
    this.getScrapAmountData()
    this.getAvgDateData()
    this.getValidData()
  },
  watch: {
    taskTrendsDate() {
      this.getTaskTrendsData()
    },
    taskTrendsData() {
      // this.updateBarChart(this.taskTrendsChart, newVal || [])
      this.renderBarChart()
    },
    taskTrendsChangeDate() {
      this.getTaskTrendsChangeData()
    },
    taskTrendsChangeData() {
      this.renderBarChangeChart()
    },
    costDate() {
      this.getCostData()
    },
    costDimension(newVal) {
      if (newVal === EStaticParamsDimension.WEEK) {
        this.costDate = lastHalfYearDateArr
      } else if (newVal === EStaticParamsDimension.MONTH) {
        this.costDate = lastOneYearDateArr
      } else {
        this.costDate = lastHalfMonthDateArr
      }
    },
    costData() {
      this.renderCostChart()
    },
    scrapAmountDate() {
      this.getScrapAmountData()
    },
    scrapAmountDimension(newVal) {
      if (newVal === EStaticParamsDimension.WEEK) {
        this.scrapAmountDate = lastHalfYearDateArr
      } else if (newVal === EStaticParamsDimension.MONTH) {
        this.scrapAmountDate = lastOneYearDateArr
      } else {
        this.scrapAmountDate = lastHalfMonthDateArr
      }
    },
    scrapAmountData() {
      this.renderScrapAmountChart()
    },
    avgDate() {
      this.getAvgDateData()
    },
    avgDateDimension(newVal) {
      if (newVal === EStaticParamsDimension.WEEK) {
        this.avgDate = lastHalfYearDateArr
      } else if (newVal === EStaticParamsDimension.MONTH) {
        this.avgDate = lastOneYearDateArr
      } else {
        this.getAvgDateData()
      }
    },
    avgDateData() {
      this.renderAvgDateChart()
    },
    validDate() {
      this.getValidData()
    },
    validDimension(newVal) {
      if (newVal === EStaticParamsDimension.WEEK) {
        this.validDate = lastHalfYearDateArr
      } else if (newVal === EStaticParamsDimension.MONTH) {
        this.validDate = lastOneYearDateArr
      } else {
        this.validDate = lastOneMonthDateArr
      }
    },
    validData() {
      this.renderValidChart()
    },
  },
  methods: {
    renderBarChart(container) {
      if (this.taskTrendsChart) {
        this.taskTrendsChart.destroy()
      }

      this.taskTrendsChart = new Chart({
        container: this.$refs.taskTrends,
        autoFit: true,
      });

      this.taskTrendsChart
        .data(this.taskTrendsData)
        .encode('x', (d) => moment(d.generateTime).format(DefaultOnlyDateFormat))
        .encode('y', 'value')
        .axis('y', { title: '库存(元*天)' })
        .scale('x', {
          range: [0, 1],
        })
        .scale('y', {
          // domainMin: 0,
          nice: true,
        });

      this.taskTrendsChart.line().encode('shape', 'smooth');

      this.taskTrendsChart.point().encode('shape', 'point').tooltip(false);

      // 下面两个方法为对应的折线图

      // this.taskTrendsChart.line().label({
      //   text: 'value',
      //   style: {
      //     dx: -10,
      //     dy: -12,
      //   },
      // });

      // this.taskTrendsChart.point().style('fill', 'white').tooltip(false);

      this.taskTrendsChart.render();
    },
    renderBarChangeChart(container) {
      if (this.taskTrendsChangeChart) {
        this.taskTrendsChangeChart.destroy()
      }

      this.taskTrendsChangeChart = new Chart({
        container: this.$refs.taskTrendsChange,
        autoFit: true,
        insetRight: 10,
      });

      this.taskTrendsChangeChart
        .data(this.taskTrendsChangeData)
        .encode('x', (d) => moment(d.generateTime).format(DefaultOnlyDateFormat))
        .encode('y', 'value')
        .axis('y', { title: '订单延误(元*天)' })
        .scale('x', {
          range: [0, 1],
        })
        .scale('y', {
          // domainMin: 0,
          nice: true,
        });

      this.taskTrendsChangeChart.line().encode('shape', 'smooth');

      this.taskTrendsChangeChart.point().encode('shape', 'point').tooltip(false);

      // 下面两个方法为对应的折线图

      // this.taskTrendsChangeChart.line().label({
      //   text: 'value',
      //   style: {
      //     dx: -10,
      //     dy: -12,
      //   },
      // });

      // this.taskTrendsChangeChart.point().style('fill', 'white').tooltip(false);

      // 渲染可视化
      this.taskTrendsChangeChart.render();
    },
    renderCostChart(container) {
      if (this.costChart) {
        this.costChart.destroy()
      }

      this.costChart = new Chart({
        container: this.$refs.cost,
        autoFit: true,
        insetRight: 10,
      });

      this.costChart
        .data(this.costData)
        .encode('x', (d) => moment(d.generateTime).format(DefaultOnlyDateFormat))
        .encode('y', 'value')
        .axis('y', { title: '营运费用(元)' })
        .scale('x', {
          range: [0, 1],
        })
        .scale('y', {
          // domainMin: 0,
          nice: true,
        });

      this.costChart.line().encode('shape', 'smooth');

      this.costChart.point().encode('shape', 'point').tooltip(false);

      // 下面两个方法为对应的折线图

      // this.costChart.line().label({
      //   text: 'value',
      //   style: {
      //     dx: -10,
      //     dy: -12,
      //   },
      // });

      // this.costChart.point().style('fill', 'white').tooltip(false);

      // 渲染可视化
      this.costChart.render();
    },
    renderScrapAmountChart(container) {
      if (this.scrapAmountChart) {
        this.scrapAmountChart.destroy()
      }

      this.scrapAmountChart = new Chart({
        container: this.$refs.scrapAmount,
        autoFit: true,
        // insetRight: 10,
      });

      this.scrapAmountChart
        .data(this.scrapAmountData)
        .encode('x', (d) => moment(d.generateTime).format(DefaultOnlyDateFormat))
        .encode('y', 'value')
        .encode('color', d => ScrapAmountTextMap[d.type])
        .axis('x', { title: false })
        .axis('y', { title: '报废金额(元)' })

      this.scrapAmountChart.area().style('fillOpacity', 0.3);

      this.scrapAmountChart.line().style('strokeWidth', 2).tooltip(false);

      // 渲染可视化
      this.scrapAmountChart.render();
    },
    renderAvgDateChart(container) {
      if (this.avgDateChart) {
        this.avgDateChart.destroy()
      }

      this.avgDateChart = new Chart({
        container: this.$refs.avgDate,
        autoFit: true,
        insetRight: 10,
      });

      this.avgDateChart
        .data(this.avgDateData)
        .encode('x', (d) => moment(d.generateTime).format(DefaultOnlyDateFormat))
        .encode('y', 'value')
        .axis('y', { title: '平均生产周期(天)' })
        .scale('x', {
          range: [0, 1],
        })
        .scale('y', {
          // domainMin: 0,
          nice: true,
        });

      this.avgDateChart.line().encode('shape', 'smooth');

      this.avgDateChart.point().encode('shape', 'point').tooltip(false);

      // 下面两个方法为对应的折线图

      // this.costChart.line().label({
      //   text: 'value',
      //   style: {
      //     dx: -10,
      //     dy: -12,
      //   },
      // });

      // this.costChart.point().style('fill', 'white').tooltip(false);

      // 渲染可视化
      this.avgDateChart.render();
    },
    renderValidChart(container) {
      if (this.validChart) {
        this.validChart.destroy()
      }

      this.validChart = new Chart({
        container: this.$refs.valid,
        autoFit: true,
        insetRight: 10,
      });

      this.validChart
        .data(this.validData)
        .encode('x', (d) => moment(d.generateTime).format(DefaultOnlyDateFormat))
        .encode('y', 'value')
        .axis('y', { title: '有效产出(元)' })
        .scale('x', {
          range: [0, 1],
        })
        .scale('y', {
          // domainMin: 0,
          nice: true,
        });

      this.validChart.line().encode('shape', 'smooth');

      this.validChart.point().encode('shape', 'point').tooltip(false);

      // 渲染可视化
      this.validChart.render();
    },
    updateBarChart(chart, newData) {
      // 获得 Interval Mark
      const interval = chart.getNodesByType('interval')[0];

      interval.data(newData);
      // 重新渲染
      chart.render();
    },
    getTaskTrendsData() {
      getTaskTrends({
        type: `${EParamsPrefix.EQ}${EStaticParamsType.WHI}`,
        dimension: `${EParamsPrefix.EQ}${EStaticParamsDimension.DAY}`,
        generateTime: `${EParamsPrefix.BTN}${this.taskTrendsDateRangeFormat.join(',')}`
      }).then((res) => {
        this.taskTrendsData = (res.data || []).map(d => ({
          ...d,
          // 直接转换为 Bigint 不支持，后续需要考虑此数字是否溢出
          // value: toBigInt(d.value)
          value: Number(d.value)
        }))
      }).catch(e => {
        console.error(e)
      })
    },
    getTaskTrendsChangeData() {
      getTaskTrends({
        type: `${EParamsPrefix.EQ}${EStaticParamsType.ODI}`,
        dimension: `${EParamsPrefix.EQ}${EStaticParamsDimension.DAY}`,
        generateTime: `${EParamsPrefix.BTN}${this.taskTrendsDateChangeRangeFormat.join(',')}`
      }).then((res) => {
        this.taskTrendsChangeData = (res.data || []).map(d => ({
          ...d,
          // 直接转换为 Bigint 不支持，后续需要考虑此数字是否溢出
          // value: toBigInt(d.value)
          value: Number(d.value)
        }));
      }).catch(e => {
        console.error(e)
      })
    },
    getCostData() {
      getTaskTrends({
        type: `${EParamsPrefix.EQ}${EStaticParamsType.TAA}`,
        dimension: `${EParamsPrefix.EQ}${this.costDimension}`,
        generateTime: `${EParamsPrefix.BTN}${this.costDateRangeFormat.join(',')}`
      }).then((res) => {
        this.costData = (res.data || []).map(d => ({
          ...d,
          // 直接转换为 Bigint 不支持，后续需要考虑此数字是否溢出
          // value: toBigInt(d.value)
          value: Number(d.value)
        }));
      }).catch(e => {
        console.error(e)
      })
    },
    getScrapAmountData() {
      getTaskTrends({
        type: `${EParamsPrefix.EQ}${EStaticParamsType.TAA}`,
        dimension: `${EParamsPrefix.EQ}${this.scrapAmountDimension}`,
        generateTime: `${EParamsPrefix.BTN}${this.scrapAmountDateRangeFormat.join(',')}`
      }).then((res) => {
        this.scrapAmountData = (res.data || [])
          .filter(d => d?.extendInfo)
          .reduce((all, d) => {

            return [
              ...all,
              {
                generateTime: d.generateTime,
                type: 'bottleneckRecordAmount',
                value: _.get(d?.extendInfo, 'bottleneckRecordAmount')
              },
              {
                generateTime: d.generateTime,
                type: 'normalRecordAmount',
                value: _.get(d?.extendInfo, 'normalRecordAmount')
              },
            ]
          }, []);
      }).catch(e => {
        console.error(e)
      })
    },
    getAvgDateData() {
      getTaskTrends({
        type: `${EParamsPrefix.EQ}${EStaticParamsType.TPT}`,
        dimension: `${EParamsPrefix.EQ}${this.avgDateDimension}`,
        generateTime: `${EParamsPrefix.BTN}${this.avgDateRangeFormat.join(',')}`
      }).then((res) => {
        this.avgDateData = (res.data || []).map(d => ({
          ...d,
          // 直接转换为 Bigint 不支持，后续需要考虑此数字是否溢出
          // value: toBigInt(d.value)
          value: Number(d.value)
        }));
      }).catch(e => {
        console.error(e)
      })
    },
    getValidData() {
      getTaskTrends({
        type: `${EParamsPrefix.EQ}${EStaticParamsType.EFP}`,
        dimension: `${EParamsPrefix.EQ}${this.validDimension}`,
        generateTime: `${EParamsPrefix.BTN}${this.validDateRangeFormat.join(',')}`
      }).then((res) => {
        this.validData = (res.data || []).map(d => ({
          ...d,
          // 直接转换为 Bigint 不支持，后续需要考虑此数字是否溢出
          // value: toBigInt(d.value)
          value: Number(d.value)
        }));
      }).catch(e => {
        console.error(e)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  border-radius: 8px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 6px rgb(206, 206, 206);
}

/* 非激活窗口 */
::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(206, 206, 206);
}

.container {
  width: 100%;
  padding-bottom: 30px;
  background: rgb(240, 242, 245);
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 95%;
    height: 480px;
    margin-top: 20px;
    background: white;
    position: relative;

    .filter {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid rgb(212, 212, 212);
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 60px;
      }

      .date-container {
        display: flex;
        align-items: center;
        font-size: 16px;

        p {
          margin-right: 20px;
        }
      }
    }

    .target-tab {
      height: 420px;
      position: relative;

      .target-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .list {
          .item {
            margin-right: 215px;
          }
        }
      }
    }
  }

  .trends-change-wrapper {
    width: 95%;
    margin-top: 20px;
    background: white;
    position: relative;

    .filter {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid rgb(212, 212, 212);
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 60px;
      }

      .date-container {
        display: flex;
        align-items: center;
        font-size: 16px;

        .date-type {
          margin-right: 20px;
        }

        p {
          margin-right: 20px;
        }
      }
    }

    .trends-change-inner {
      height: 530px;
      overflow: auto;
      position: relative;
      padding: 18px;
    }
  }


}

/deep/ .ant-table-pagination,
.ant-pagination {
  float: left;
}

.target-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .target-content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    position: relative;
  }
}

.page-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  /deep/ .anticon {
    font-size: 48px;
    color: white;
  }
}
</style>
