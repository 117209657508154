<template>
  <a-modal class="modal-container" :visible="true" title='分批' :maskClosable="false"
    @cancel="handleCancel" width="600px">
    <template slot="footer">
      <a-button @click="handleReset">
        重置
      </a-button>
      <a-button @click="handleCancel">
        取消
      </a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">
        确定
      </a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="分批数量" prop="batchNum">
        <a-input-number style="width: 300px" v-model.number="form.batchNum" :max="maxCount" placeholder='请输入分批数量' />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

  import {
    batchSettlements,
  } from '@/api/settlements'

  export default {
    props: {
      id: Number,
      maxCount: Number
    },
    data() {
      return {
        labelCol: {
          span: 5
        },
        wrapperCol: {
          span: 15,
          offset: 1
        },
        loading: false,
        form: {
          batchNum: null,
        },
        rules: {
          batchNum: [{
            required: true,
            message: "请输入分批数量",
            trigger: 'blur'
          }],
        },
      }
    },
    methods: {
      handleReset() {
        this.$refs.ruleForm.resetFields();
      },
      handleCancel() {
        this.$emit('cancel');
      },
      handleConfirm() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.batchSettlements();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      batchSettlements() {
        this.loading = true;
        batchSettlements(this.id, {
          splitCount: this.form.batchNum
        }).then(res => {
          this.$emit('confirm');
        }).catch(
          // 工序失败原因
          (reason) => {
            this.loading = false;
            console.log('处理失败的 promise (' + reason + ')');
        }).finally(() => {
          this.loading = false
        });
      },
    }
  }

</script>

<style scoped>


</style>
