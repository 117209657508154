<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" title="更新目标数量" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="target" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="目标数量" prop="targetNum">
        <a-input v-model.number="target.targetNum" placeholder="请输入目标数量" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updateGroupTargetNum, updateMemberDayTargetNum } from '@/api/target'
export default {
  props: {
    targetForm: Object,
  },
  data() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      loading: false,
      target: {
        targetNum: this.targetForm.targetNum,
      },
      rules: {
        targetNum: [
          {
            required: true,
            trigger: 'change',
            message: '请输入目标数量',
          },
        ],
      },
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.targetForm.memberId) {
            updateMemberDayTargetNum(
              this.targetForm.targetId,
              this.targetForm.groupId,
              this.targetForm.memberId,
              this.targetForm.taskId,
              {
                productNum: this.target.targetNum,
              }
            ).then((res) => {
              this.$message.success('操作成功')
              this.$emit('confirm')
            })
          } else {
            updateGroupTargetNum(
              this.targetForm.targetId,
              this.targetForm.groupId,
              this.targetForm.taskId,
              {
                productNum: this.target.targetNum,
              }
            ).then((res) => {
              this.$message.success('操作成功')
              this.$emit('confirm')
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
