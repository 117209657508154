<template>
  <a-modal class="modal-container" :visible="true" :title="code + '询价单产品项明细'" :mask-closable="false" width="1200px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleCancel">确定</a-button>
    </template>
    <a-dropdown v-if="hasSelected">
      <a class="batch-operate-link" @click="(e) => e.preventDefault()">
        <a-icon type="edit" />批量操作
      </a>

      <a-menu slot="overlay">
        <a-menu-item>
          <a @click="handleCancelInquiry(currentProductList)">取消报价</a>
        </a-menu-item>
        <a-menu-item>
          <a @click="handleDeleteProduct(currentProductList)">删除产品</a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-table
      row-key="id"
      bordered
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :scroll="{ x: 1370 }"
      class="users-table"
      :columns="
      productDetailColumns"
      :data-source="list"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <div v-if="column.searchType===&quot;number&quot;">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px"
            :ranges="{
              今天: [$moment(), $moment()],
              本月: [$moment().startOf('month'), $moment().endOf('month')],
            }"
            @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>
      <div slot="status" slot-scope="text">
        <a-tag v-if="text === 'WCA'" color="blue">待核价</a-tag>
        <a-tag v-if="text === 'CAC'" color="pink">核价中</a-tag>
        <a-tag v-if="text === 'CNL'" color="red">已取消</a-tag>
        <a-tag v-if="text === 'FIN'" color="green">核价完成</a-tag>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />

      <div slot="operate" slot-scope="text, column" class="table-user">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item v-if="column.status === 'WCA' || column.status === 'CAC'" @click="handleEditInquiryProduct(column)">更新产品</a-menu-item>
            <a-menu-item v-if="column.status === 'WCA' || column.status === 'CAC'" @click="handleCancelInquiry([column.sequence])">取消报价</a-menu-item>
            <a-menu-item v-if="column.status === 'WCA'" @click="handleDeleteProduct([column.sequence])">删除产品</a-menu-item>
          </a-menu>

          <a class="user-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <InquiryCancelProductModal
      v-if="cancelModalVisible"
      :code="code"
      :recordsCode="currentProductList"
      @cancel="handleCancelInquiryCancel"
      @confirm="handleCancelInquiryConfirm"
    />
    <CreateInquiryProductModal
      :inquiryCode="code"
      type="update"
      v-if="inquirysProductModalVisible"
      :editRecord="editInquiryProduct"
      @cancel="handleEditCancel"
      @confirm="handleEditConfirm"
    />
  </a-modal>
</template>
<script>
import { deepClone } from '@/utils'
import { productDetailColumns } from './dataLogic'
import { getProductDetailList, deleteInquiryProduct } from '@/api/inquirylist'
import InquiryCancelProductModal from '@/components/InquiryCancelProductModal'
import CreateInquiryProductModal from '@/components/CreateInquiryProductModal'

export default {
  components: {
    InquiryCancelProductModal,
    CreateInquiryProductModal
  },
  props: {
    code: String
  },
  data() {
    return {
      loading: false,
      uploading: false,
      list: [],
      productDetailColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentEditType: '',
      currentUser: {},
      editUserModalVisible: false,
      userRoleModalVisible: false,
      userOperationModalVisible: false,
      columnSearchParams: {},
      uploadUrl: process.env.VUE_APP_BASE_API,
      exportLoading: false,
      cancelModalVisible: false,
      currentProductList: [],
      editInquiryProductType: '',
      editInquiryProduct: {},
      inquirysProductModalVisible: false
    }
  },
  created() {
    this.getList(this.initQueryParams)
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    getList(data) {
      this.loading = true
      getProductDetailList(this.code, data)
        .then(res => {
          this.list = res.data.records.map((item, index) => {
            item.index =
              (this.currentQueryParams.pageNum - 1) *
                this.currentQueryParams.pageSize +
              index +
              1
            return item
          })
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getListByParams() {
      const cQueryParams = deepClone(this.currentQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getList(queryParams)
    },
    onSelectChange(selectedRowKeys, rows) {
      const list = []
      rows.forEach(item => {
        list.push(item.sequence)
      })
      this.selectedRowKeys = selectedRowKeys
      this.currentProductList = list
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['inquiryItem.status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['inquiryItem.status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['inquiryItem.status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentQueryParams.pageNum = pagination.current
      this.currentQueryParams.pageSize = pagination.pageSize
      this.getListByParams()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      let _dataIndex = dataIndex
      if (dataIndex === 'productInfo.name') {
        _dataIndex = 'productName'
      }

      if (dataIndex === 'productInfo.drawingNum') {
        _dataIndex = 'drawingNum'
      }

      this.columnSearchParams[_dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[_dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[_dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      let _dataIndex = dataIndex
      if (dataIndex === 'productInfo.name') {
        _dataIndex = 'productName'
      }

      if (dataIndex === 'productInfo.drawingNum') {
        _dataIndex = 'drawingNum'
      }
      this.columnSearchParams[_dataIndex] = null
    },
    handleCancelInquiry(keys) {
      this.currentProductList = keys
      this.cancelModalVisible = true
    },
    handleCancelInquiryConfirm() {
      this.currentProductList = {}
      this.selectedRowKeys = []
      this.cancelModalVisible = false
      this.getListByParams()
    },
    handleCancelInquiryCancel() {
      this.cancelModalVisible = false
    },
    handleDeleteProduct(data) {
      this.$confirm({
        content: `确定删除询价单${this.code}的产品吗`,
        onOk: () => {
          deleteInquiryProduct(this.code, data).then(res => {
            this.$message.success('删除成功')
            this.getListByParams()
            this.$emit('refresh')
          })
        },
        cancelText: '取消',
        onCancel() {}
      })
    },
    handleEditInquiryProduct(column) {
      this.editInquiryProductType = 'edit'
      this.editInquiryProduct = column
      this.inquirysProductModalVisible = true
    },
    handleEditConfirm() {
      this.inquirysProductModalVisible = false
      this.getListByParams()
      this.editInquiryProductType = ''
      this.editInquiryProduct = {}
    },
    handleEditCancel() {
      this.inquirysProductModalVisible = false
      this.getListByParams()
      this.editInquiryProductType = ''
      this.editInquiryProduct = {}
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
}
</style>
