<template>
  <a-modal
    class="modal-container"
    :visible="true"
    :title="record.productInfo.name + record.productInfo.drawingNum + record.productInfo.specification +  '价格调整'"
    :mask-closable="false"
    width="600px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="最终价格" prop="finalPrice">
        <a-input-number
          v-model="form.finalPrice"
          size="small"
          placeholder="请输入最终价格"
          :min="0"
          :max="999999"
          style="width: 300px;height: 30px; line-height: 30px;"
        />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="comment">
        <a-textarea v-model="form.comment" :max-length="200" placeholder="请输入备注" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { putPrice } from '@/api/approval'

export default {
  props: {
    id: String | Number,
    record: Object
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      form: {
        finalPrice: this.record.summaryCostInfo.totalAmountTaxFree,
        comment: ''
      },
      rules: {
        finalPrice: [
          {
            required: true,
            message: '请输入最终价格',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          putPrice(this.id, this.form)
            .then(res => {
              this.$message.success('调整价格成功')
              this.$emit('confirm')
            })
            .catch(err => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
