var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c("div", { attrs: { id: "craft-card-print" } }, [
        _c(
          "div",
          { staticStyle: { "page-break-after": "always" } },
          [
            _c("div", { staticClass: "title" }, [
              _c("div", { staticClass: "left" }, [_vm._v("下达任务交接表")]),
              _c("div", { staticClass: "right" }, [_vm._v(_vm._s(_vm.time))]),
            ]),
            _c("a-table", {
              attrs: {
                columns: _vm.columns,
                "data-source": _vm.taskDatas,
                bordered: "",
                pagination: false,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "a-button",
        {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: "craft-card-print",
              expression: "'craft-card-print'",
            },
          ],
          attrs: { type: "primary", block: "" },
        },
        [_vm._v("打印")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }