var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title + "角色",
        maskClosable: false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [
            _vm._v(" 重置 "),
          ]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [
            _vm._v(" 取消 "),
          ]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.role,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "角色编号", prop: "code" } },
            [
              _c("a-input", {
                attrs: { maxLength: "20", placeholder: "请输入角色编号" },
                model: {
                  value: _vm.role.code,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.role,
                      "code",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "role.code",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "角色名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: { maxLength: "20", placeholder: "请输入角色名称" },
                model: {
                  value: _vm.role.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.role,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "role.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }