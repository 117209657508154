export const tenantsColumns = [
  {
    title: '供应商编码',
    dataIndex: 'code',
    key: 'code',
    width: 200,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '供应商名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    width: 190,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '信用代码',
    dataIndex: 'creditCode',
    key: 'creditCode',
    ellipsis: true,
    width: 160,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '供应商简称',
    dataIndex: 'nickName',
    ellipsis: true,
    width: 170,
    key: 'nickName',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '供应商地址',
    dataIndex: 'address',
    ellipsis: true,
    width: 170,
    key: 'address',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '供应商电话',
    dataIndex: 'telephone',
    width: 150,
    key: 'telephone',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '传真',
    dataIndex: 'fax',
    ellipsis: true,
    width: 150,
    key: 'fax',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '联系人',
    dataIndex: 'contactsInfo',
    width: 150,
    key: 'contactsInfo',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'contactsInfo',
    },
  },
  {
    title: '操作',
    key: 'operate',
    width: 80,
    align: 'center',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
