export const CONSTANTS = {
  EXPORT_FILE_SUFFIX: '.xlsx',
  ORDER_FILE_NAME: '订单信息表-',
  PRODUCT_FILE_NAME: '产品信息表-',
  PLAN_FILE_NAME: '计划信息表-',
  OPERATION_FILE_NAME: '工序信息表-',
  MARERIALS_FILE_NAME: '材料信息表-',
  CLIENT_FILE_NAME: '客户信息表-',
  SUPPILER_FILE_NAME: '供应商信息表-',
  USER_FILE_NAME: '员工信息表-',
  TASK_FILE_NAME: '任务信息表-',
  CRAFT_FILE_NAME: '工艺信息表-',
  RECORD_FILE_NAME: '生产记录信息表-',
  QUALITY_FILE_NAME: '质量记录信息表-',
  INQUIRY_FILE_NAME: '询价记录信息表-',
  COST_ACCOUNTING_FILE_NAME: '成本核算明细表-',
  TARGET_FILE_NAME: '月目标信息表-',
  PRODUCT_TEMPLATE_FILE_NAME: '产品导入模板',
  ORDER_PRODUCT_TEMPLATE_FILE_NAME: '订单产品导入模板',
  OPERATION_TEMPLATE_FILE_NAME: '工序导入模板',
  PROCESS_ROUTE_TEMPLATE_FILE_NAME: '标准工艺路线导入模板',
  USER_TEMPLATE_FILE_NAME: '员工导入模板',
  CARD_TEMPLATE_FILE_NAME: '工艺卡片模板导入模板',
  MATERIAL_TEMPLATE_FILE_NAME: '材料导入模板',
  PARTS_TEMPLATE_CARFT_FILE_NAME: '分件工艺导入模板',
  SETTLE_TEMPLATE_ACCOUNT_FILE_NAME: '账单导入匹配导入模板',
  TENANTS_TEMPLATE_FILE_NAME: '客户导入模板',
  SUPPLIERS_TEMPLATE_FILE_NAME: '供应商导入模板',
  INQUIRY_TEMPLATE_FILE_NAME: '询价单导入模板',
}
