import moment from 'moment'

export const DefaultDateFormat = 'YYYY-MM-DD HH:mm:ss'

export const DefaultOnlyDateFormat = 'YYYY-MM-DD'

export const curNowDate = moment(new Date(), DefaultDateFormat)

export const lastHalfMonthDateArr = [
  moment(moment().subtract(15, 'days'), DefaultDateFormat),
  moment(new Date(), DefaultDateFormat),
]

export const lastOneMonthDateArr = [
  moment(moment().subtract(30, 'days'), DefaultDateFormat),
  moment(new Date(), DefaultDateFormat),
]

export const lastHalfYearDateArr = [
  moment(moment().subtract(6, 'months'), DefaultDateFormat),
  moment(new Date(), DefaultDateFormat),
]

export const lastOneYearDateArr = [
  moment(moment().subtract(1, 'years'), DefaultDateFormat),
  moment(new Date(), DefaultDateFormat),
]
