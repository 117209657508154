<template>
  <div class="card-container">
    <div id="craft-card-print" class="custom-table">
      <div style="page-break-after:always">
        <div class="title">
          <div class="left">入库类型：{{ data.productType }}</div>
          <div class="middle">重庆市DBR刀具有限公司产成品入库单</div>
          <div class="right">入库单号：{{ data.putInNumber }}</div>
        </div>
        <a-table :columns="columns" :data-source="productList" bordered :pagination="false">
          <p slot="amount" slot-scope="text" class="amount-font">{{ text }}</p>
        </a-table>
        <a-descriptions bordered>
          <a-descriptions-item label="数量合计">
            <p class="amount-font">{{ data.totalPutInAmount }}</p>
          </a-descriptions-item>
          <a-descriptions-item label="金额合计">{{ data.totalProductionPrice }}</a-descriptions-item>
        </a-descriptions>
        <div class="footer-form" style="float: left;">
          <span>制单人：{{ data.putInReceiptUser }}</span>
          <div class="bottom-line" />
        </div>
        <div class="footer-form">
          <span>计划员：</span>
          <div class="bottom-line" />
          <span>日期：</span>
          <span>{{ data.receiptTime }}</span>
        </div>
      </div>
    </div>
    <a-button v-print="'craft-card-print'" type="primary" block>打印</a-button>
  </div>
</template>
<script>
import { getSaveInventorysCheck } from '@/api/inventory'
const columns = [
  {
    title: '序号',
    dataIndex: 'sequenceNum',
    width: 50
  },
  {
    title: '任务号',
    dataIndex: 'taskCode',
    width: 100
  },
  {
    title: '名称',
    dataIndex: 'productName',
    width: 120
  },
  {
    title: '图号',
    dataIndex: 'drawingNum'
  },
  {
    title: '单位',
    dataIndex: 'unit',
    width: 50
  },
  {
    title: '数量',
    dataIndex: 'amount',
    width: 80,
    scopedSlots: {
      customRender: 'amount'
    }
  },
  {
    title: '产值单价',
    dataIndex: 'productionUnitPrice',
    width: 100
  },
  {
    title: '产值金额',
    dataIndex: 'productionPrice',
    width: 100
  },
  {
    title: '客户名称',
    dataIndex: 'client',
    width: 200
  },
  {
    title: '报废数量',
    dataIndex: 'abandonCount',
    width: 100
  }
]
export default {
  data() {
    return {
      columns,
      data: [],
      productList: []
    }
  },
  created() {
    const params = this.$route.query
    getSaveInventorysCheck(params).then(res => {
      if (res.data) {
        this.productList = res.data.productPutInItemList
        this.productList.forEach((item, index) => {
          item.sequenceNum = index + 1
        })
        this.data = res.data
      } else {
        this.$message.error('获取入库单数据失败，请重试！')
      }
    })
  }
}
</script>
<style scoped lang="scss">
.card-container {
  width: 70%;
  margin: auto;
}

.title {
  padding: 10px 5px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 50px;
  margin-bottom: 2px;
  color: black;
  .right {
    text-align: right;
    margin-bottom: -10px;
  }
}

/deep/ .ant-table {
  padding: 10px 0px;
  color: black;
}

/deep/ .ant-descriptions-item-label {
  color: black;
}

/deep/ .ant-descriptions-item-content {
  color: black;
}

.card-title >>> .ant-descriptions-item-label {
  font-size: 20px;
}
.amount-font {
  font-size: 18px;
  margin: 0;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
  border: 1px solid black;
  text-align: center;
  padding: 1px 8px;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
  border: 1px solid black;
  text-align: center;
  padding: 1px 8px;
}

/deep/ .ant-table-thead th {
  padding: 8px 8px;
  text-align: center;
  border: 1px solid black;
  color: black;
}

/deep/ .ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border: 1px solid black;
}

/deep/ .ant-table-tbody td {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 2px 8px;
  text-align: center;
}

/deep/ .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid black;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-view {
  border: 0px;
}

.footer-form {
  float: right;
  margin-top: 15px;
  margin-bottom: 20px;
  color: black;
}

.bottom-line {
  width: 100px;
  height: 20px;
  margin-right: 10px;

  display: inline-block;
}
</style>
