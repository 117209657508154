var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        "body-style": _vm.bodyStyle,
        visible: true,
        title: _vm.title,
        "mask-closable": false,
        width: "1500px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("装配")]
          ),
        ],
        1
      ),
      _c("a-table", {
        staticClass: "tasks-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
          },
          columns: _vm.columns,
          "data-source": _vm.taskList,
          pagination: false,
          loading: _vm.tableLoading,
        },
        scopedSlots: _vm._u([
          {
            key: "type",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === 0 ? [_c("span", [_vm._v("普通任务")])] : _vm._e(),
                  text === 1 ? [_c("span", [_vm._v("返修任务")])] : _vm._e(),
                  text === 2
                    ? [_c("span", [_vm._v("生产补投任务")])]
                    : _vm._e(),
                  text === 3 ? [_c("span", [_vm._v("协作任务")])] : _vm._e(),
                  text === 4
                    ? [_c("span", [_vm._v("普通补投任务")])]
                    : _vm._e(),
                  text === 5 ? [_c("span", [_vm._v("其他任务")])] : _vm._e(),
                ],
                2
              )
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }