var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.type + "撤销",
        maskClosable: false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [
            _vm._v(" 重置 "),
          ]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [
            _vm._v(" 取消 "),
          ]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "撤销原因", prop: "changeReason" } },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { placeholder: "请输入撤销原因" },
                model: {
                  value: _vm.form.changeReason,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "changeReason", $$v)
                  },
                  expression: "form.changeReason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }