var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      { attrs: { id: "craft-card-print" } },
      [
        _vm._l(_vm.batchData, function (item, itemIndex) {
          return _c(
            "div",
            { key: itemIndex },
            _vm._l(
              item.operationCardBodyDTO.craftProcessRouteListDTO,
              function (card, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "card-container",
                    staticStyle: { "page-break-after": "always" },
                  },
                  [
                    _c("div", { staticClass: "card-header-title" }, [
                      _vm._m(0, true),
                      item.operationCardHeaderDTO.craftPlanDTO.planCode
                        ? _c(
                            "div",
                            { staticClass: "code" },
                            [
                              _c("vue-qrcode", {
                                attrs: {
                                  margin: "0",
                                  scale: "2",
                                  value:
                                    item.operationCardHeaderDTO.craftPlanDTO
                                      .planCode,
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "code" }),
                      _vm._m(1, true),
                    ]),
                    _c("div", { staticClass: "card-header-production" }, [
                      _c("div", { staticClass: "product-item" }, [
                        _vm._m(2, true),
                        _c("div", { staticClass: "cell" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  item.operationCardHeaderDTO.craftProductDTO
                                    .craftProductName,
                                expression:
                                  "item.operationCardHeaderDTO.craftProductDTO.craftProductName",
                              },
                            ],
                            style: { "line-height": 1.2 * _vm.pageUnit + "px" },
                            attrs: {
                              disabled:
                                _vm.ifUpdate || _vm.ifCheck || _vm.ifCopy,
                              requierd: "",
                              maxlength: "16",
                              cols: "10",
                            },
                            domProps: {
                              value:
                                item.operationCardHeaderDTO.craftProductDTO
                                  .craftProductName,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  item.operationCardHeaderDTO.craftProductDTO,
                                  "craftProductName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "product-item" }, [
                        _vm._m(3, true),
                        _c("div", { staticClass: "cell" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  item.operationCardHeaderDTO.craftProductDTO
                                    .craftDrawingNum,
                                expression:
                                  "item.operationCardHeaderDTO.craftProductDTO.craftDrawingNum",
                              },
                            ],
                            style: { "line-height": 1.2 * _vm.pageUnit + "px" },
                            attrs: {
                              disabled:
                                _vm.ifUpdate || _vm.ifCheck || _vm.ifCopy,
                              maxlength: "22",
                              rows: "2",
                              cols: "10",
                            },
                            domProps: {
                              value:
                                item.operationCardHeaderDTO.craftProductDTO
                                  .craftDrawingNum,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  item.operationCardHeaderDTO.craftProductDTO,
                                  "craftDrawingNum",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "product-item" }, [
                        _vm._m(4, true),
                        _c("div", { staticClass: "cell" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  item.operationCardHeaderDTO.craftProductDTO
                                    .craftSpecification,
                                expression:
                                  "item.operationCardHeaderDTO.craftProductDTO.craftSpecification",
                              },
                            ],
                            style: { "line-height": 1.2 * _vm.pageUnit + "px" },
                            attrs: {
                              disabled:
                                _vm.ifUpdate || _vm.ifCheck || _vm.ifCopy,
                              maxlength: "18",
                              rows: "2",
                              cols: "10",
                            },
                            domProps: {
                              value:
                                item.operationCardHeaderDTO.craftProductDTO
                                  .craftSpecification,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  item.operationCardHeaderDTO.craftProductDTO,
                                  "craftSpecification",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "product-item" }, [
                        _vm._m(5, true),
                        _c("div", { staticClass: "cell" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  item.operationCardHeaderDTO
                                    .operationCardBaseDTO.craftCode,
                                expression:
                                  "item.operationCardHeaderDTO.operationCardBaseDTO.craftCode",
                              },
                            ],
                            style: { "line-height": 1.2 * _vm.pageUnit + "px" },
                            attrs: {
                              disabled: _vm.ifCheck || _vm.ifCopy,
                              maxlength: "17",
                              rows: "2",
                              cols: "10",
                            },
                            domProps: {
                              value:
                                item.operationCardHeaderDTO.operationCardBaseDTO
                                  .craftCode,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  item.operationCardHeaderDTO
                                    .operationCardBaseDTO,
                                  "craftCode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "product-item" }, [
                        _vm._m(6, true),
                        _c("div", { staticClass: "cell" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  item.operationCardHeaderDTO.craftPlanDTO
                                    .planCode,
                                expression:
                                  "item.operationCardHeaderDTO.craftPlanDTO.planCode",
                              },
                            ],
                            style: { "line-height": 1.2 * _vm.pageUnit + "px" },
                            attrs: {
                              disabled:
                                _vm.ifUpdate ||
                                _vm.ifCheck ||
                                _vm.$route.query.isParts === "0" ||
                                _vm.ifPaste ||
                                _vm.ifCopy,
                              maxlength: "16",
                              rows: "2",
                              cols: "10",
                            },
                            domProps: {
                              value:
                                item.operationCardHeaderDTO.craftPlanDTO
                                  .planCode,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  item.operationCardHeaderDTO.craftPlanDTO,
                                  "planCode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-header-material" }, [
                      _c("div", { staticClass: "material-item" }, [
                        _vm._m(7, true),
                        _c(
                          "div",
                          { staticClass: "cell" },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "materialSelect",
                                staticStyle: {
                                  width: "96%",
                                  "margin-left": "5px",
                                  "margin-top": "3px",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  value:
                                    item.operationCardHeaderDTO.craftMaterialDTO
                                      .materialName,
                                  "show-search": "",
                                  "default-active-first-option": false,
                                  placeholder: "请输入材料名",
                                  "show-arrow": false,
                                  "filter-option": false,
                                  "not-found-content": _vm.fetching
                                    ? undefined
                                    : null,
                                },
                                on: {
                                  search: _vm.fetchMaterialByName,
                                  change: function (value, option) {
                                    return _vm.handleMaterialNameChange(
                                      value,
                                      option,
                                      index
                                    )
                                  },
                                },
                              },
                              [
                                _vm.fetching
                                  ? _c("a-spin", {
                                      attrs: {
                                        slot: "notFoundContent",
                                        size: "small",
                                      },
                                      slot: "notFoundContent",
                                    })
                                  : _vm._e(),
                                _vm._l(_vm.materials, function (material) {
                                  return _c(
                                    "a-select-option",
                                    { key: material.id },
                                    [
                                      _vm._v(
                                        _vm._s(material.materialName) +
                                          "(" +
                                          _vm._s(material.materialCode) +
                                          ")"
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "material-item" }, [
                        _vm._m(8, true),
                        _c(
                          "div",
                          { staticClass: "cell" },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "materialSelect",
                                staticStyle: {
                                  width: "96%",
                                  "margin-left": "5px",
                                  "margin-top": "3px",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  value:
                                    item.operationCardHeaderDTO.craftMaterialDTO
                                      .materialCode,
                                  "show-search": "",
                                  "default-active-first-option": false,
                                  placeholder: "请输入材料图号",
                                  "show-arrow": false,
                                  "filter-option": false,
                                  "not-found-content": _vm.fetching
                                    ? undefined
                                    : null,
                                },
                                on: {
                                  search: _vm.fetchMaterialByCode,
                                  change: function (value, option) {
                                    return _vm.handleMaterialNameChange(
                                      value,
                                      option,
                                      index
                                    )
                                  },
                                },
                              },
                              [
                                _vm.fetching
                                  ? _c("a-spin", {
                                      attrs: {
                                        slot: "notFoundContent",
                                        size: "small",
                                      },
                                      slot: "notFoundContent",
                                    })
                                  : _vm._e(),
                                _vm._l(_vm.materials, function (material) {
                                  return _c(
                                    "a-select-option",
                                    { key: material.id },
                                    [
                                      _vm._v(
                                        _vm._s(material.materialName) +
                                          "(" +
                                          _vm._s(material.materialCode) +
                                          ")"
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "material-item" }, [
                        _vm._m(9, true),
                        _c("div", { staticClass: "cell" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  item.operationCardHeaderDTO.craftProductDTO
                                    .operationHardness,
                                expression:
                                  "item.operationCardHeaderDTO.craftProductDTO.operationHardness",
                              },
                            ],
                            style: {
                              "line-height":
                                item.operationCardHeaderDTO.craftProductDTO &&
                                item.operationCardHeaderDTO.craftProductDTO
                                  .operationHardness &&
                                item.operationCardHeaderDTO.craftProductDTO
                                  .operationHardness.length > 9
                                  ? 0.6 * _vm.pageUnit + "px"
                                  : 1.2 * _vm.pageUnit + "px",
                            },
                            attrs: {
                              disabled: _vm.ifCheck,
                              rows: "2",
                              cols: "10",
                              maxlength: "10",
                            },
                            domProps: {
                              value:
                                item.operationCardHeaderDTO.craftProductDTO
                                  .operationHardness,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  item.operationCardHeaderDTO.craftProductDTO,
                                  "operationHardness",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "material-item" }, [
                        _vm._m(10, true),
                        _c(
                          "div",
                          { staticClass: "cell" },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass:
                                  "materialSelect userSelect versionSelect",
                                staticStyle: {
                                  width: "90%",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck || _vm.ifUpdate,
                                  "option-filter-prop": "children",
                                },
                                model: {
                                  value:
                                    item.operationCardHeaderDTO
                                      .operationCardBaseDTO
                                      .operationCardVersion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardHeaderDTO
                                        .operationCardBaseDTO,
                                      "operationCardVersion",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardHeaderDTO.operationCardBaseDTO.operationCardVersion",
                                },
                              },
                              _vm._l(_vm.versionList, function (i) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: (i + 9).toString(36) + i,
                                    attrs: { value: i },
                                  },
                                  [_vm._v(_vm._s(i))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "material-item" }, [
                        _vm._m(11, true),
                        _c("div", { staticClass: "cell" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  item.operationCardHeaderDTO
                                    .operationCardBaseDTO.comment,
                                expression:
                                  "item.operationCardHeaderDTO.operationCardBaseDTO.comment",
                              },
                            ],
                            attrs: {
                              disabled: _vm.ifCheck,
                              rows: "2",
                              cols: "10",
                            },
                            domProps: {
                              value:
                                item.operationCardHeaderDTO.operationCardBaseDTO
                                  .comment,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  item.operationCardHeaderDTO
                                    .operationCardBaseDTO,
                                  "comment",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        ref: _vm.processRoute + index,
                        refInFor: true,
                        staticClass: "card-header-processRoute",
                        attrs: { id: "processRoute" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-header-processRoute-header" },
                          [
                            _c("div", { staticClass: "num" }, [
                              _vm._v("工序号"),
                            ]),
                            _c("div", { staticClass: "type" }, [
                              _vm._v("工种"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _vm._v(" 工 序 名 称 及 内 容 "),
                                index + 1 ===
                                item.operationCardBodyDTO
                                  .craftProcessRouteListDTO.length
                                  ? [
                                      !_vm.ifPrint && !_vm.ifCheck
                                        ? _c("a-icon", {
                                            attrs: {
                                              type: "plus-circle",
                                              theme: "twoTone",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAddCraft(index)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "time-info" }, [
                              _c("div", { staticClass: "all-time" }, [
                                _vm._m(12, true),
                                _c("div", { staticClass: "data" }, [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        item.operationCardHeaderDTO
                                          .operationCardBaseDTO.totalProcessHour
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._m(13, true),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "card-header-processRoute-content" },
                          [
                            _c(
                              "table",
                              {
                                ref: "processRouteContent" + index + itemIndex,
                                refInFor: true,
                              },
                              [
                                _c(
                                  "tbody",
                                  _vm._l(card.processRoute, function (route) {
                                    return _c(
                                      "tr",
                                      { key: route.id, staticClass: "process" },
                                      [
                                        _c(
                                          "td",
                                          { staticClass: "num" },
                                          [[_vm._v(_vm._s(route.sequenceNum))]],
                                          2
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "type",
                                            style: {
                                              "font-size":
                                                route.operationName &&
                                                route.operationName.length > 3
                                                  ? "12px"
                                                  : "18px",
                                              "line-height":
                                                route.operationName &&
                                                route.operationName.length > 3
                                                  ? "14px"
                                                  : "18px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(route.operationName)
                                            ),
                                          ]
                                        ),
                                        _c("td", { staticClass: "content" }, [
                                          _vm._v(_vm._s(route.content)),
                                        ]),
                                        _c("td", { staticClass: "hour" }, [
                                          _vm._v(
                                            _vm._s(route.verifyProcessHour)
                                          ),
                                        ]),
                                        _c("td", { staticClass: "date" }, [
                                          _vm._v(
                                            _vm._s(route.verifyTransferOutTime)
                                          ),
                                        ]),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                            _c(
                              "table",
                              _vm._l(
                                new Array(card.nullProcessRouteNum),
                                function (item, newindex) {
                                  return _c("tbody", { key: newindex }, [
                                    _vm._m(14, true),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "card-header-bottom" }, [
                      _vm._m(15, true),
                      _vm._m(16, true),
                      _vm._m(17, true),
                      _c("div", { staticClass: "row" }, [
                        _c("div", {
                          staticClass: "column-1 desc",
                          staticStyle: {
                            "border-bottom": "none",
                            "border-right": "none",
                          },
                        }),
                        _c("div", {
                          staticClass: "column-2 desc",
                          staticStyle: {
                            "border-left": "none",
                            "border-bottom": "none",
                          },
                        }),
                        _c("div", { staticClass: "column-3 desc" }, [
                          _vm._v("编制"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "column-4 desc" },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "materialSelect userSelect",
                                staticStyle: {
                                  width: "96%",
                                  height: "94%",
                                  "margin-left": "2px",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  "show-search": "",
                                  "option-filter-prop": "children",
                                  "filter-option": _vm.filterUser,
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO.craftDesignDTO
                                      .designerId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO
                                        .craftDesignDTO,
                                      "designerId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftDesignDTO.designerId",
                                },
                              },
                              _vm._l(_vm.userList, function (user) {
                                return _c(
                                  "a-select-option",
                                  { key: user.id, attrs: { value: user.id } },
                                  [_vm._v(_vm._s(user.realName))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "column-5 desc" },
                          [
                            _c(
                              "a-date-picker",
                              {
                                staticStyle: {
                                  width: "96%",
                                  height: "98%",
                                  "margin-left": "2px",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  placeholder: "",
                                  "value-format": "YYYY-MM-DD HH:mm:ss",
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO.craftDesignDTO
                                      .designTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO
                                        .craftDesignDTO,
                                      "designTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftDesignDTO.designTime",
                                },
                              },
                              [
                                _c("span", {
                                  attrs: { slot: "suffixIcon" },
                                  slot: "suffixIcon",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", {
                          staticClass: "column-6 desc",
                          domProps: {
                            innerHTML: _vm._s(
                              "标&#8197;&#8197;准&#8197;&#8197;化"
                            ),
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "column-7 desc" },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "materialSelect userSelect",
                                staticStyle: {
                                  width: "96%",
                                  height: "98%",
                                  "margin-left": "2px",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  "show-search": "",
                                  "option-filter-prop": "children",
                                  "filter-option": _vm.filterUser,
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO.craftStandardDTO
                                      .standardId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO
                                        .craftStandardDTO,
                                      "standardId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftStandardDTO.standardId",
                                },
                              },
                              _vm._l(_vm.userList, function (user) {
                                return _c(
                                  "a-select-option",
                                  { key: user.id, attrs: { value: user.id } },
                                  [_vm._v(_vm._s(user.realName))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "column-8 desc" },
                          [
                            _c(
                              "a-date-picker",
                              {
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  placeholder: "",
                                  "value-format": "YYYY-MM-DD HH:mm:ss",
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO.craftStandardDTO
                                      .standardTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO
                                        .craftStandardDTO,
                                      "standardTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftStandardDTO.standardTime",
                                },
                              },
                              [
                                _c("span", {
                                  attrs: { slot: "suffixIcon" },
                                  slot: "suffixIcon",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "column-combine desc" }, [
                          _vm._v("阶段标记"),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", {
                          staticClass: "column-1 desc",
                          staticStyle: { "border-bottom": "none" },
                        }),
                        _c("div", {
                          staticClass: "column-2 desc",
                          staticStyle: {
                            "border-left": "none",
                            "border-bottom": "none",
                          },
                        }),
                        _c("div", { staticClass: "column-3 desc" }, [
                          _vm._v("校对"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "column-4 desc" },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "materialSelect userSelect",
                                staticStyle: {
                                  width: "96%",
                                  height: "98%",
                                  "margin-left": "2px",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  "show-search": "",
                                  "option-filter-prop": "children",
                                  "filter-option": _vm.filterUser,
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO
                                      .craftProofreadDTO.collatorId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO
                                        .craftProofreadDTO,
                                      "collatorId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftProofreadDTO.collatorId",
                                },
                              },
                              _vm._l(_vm.userList, function (user) {
                                return _c(
                                  "a-select-option",
                                  { key: user.id, attrs: { value: user.id } },
                                  [_vm._v(_vm._s(user.realName))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "column-5 desc" },
                          [
                            _c(
                              "a-date-picker",
                              {
                                staticStyle: {
                                  width: "96%",
                                  height: "98%",
                                  "margin-left": "2px",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  placeholder: "",
                                  "value-format": "YYYY-MM-DD HH:mm:ss",
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO
                                      .craftProofreadDTO.proofreadTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO
                                        .craftProofreadDTO,
                                      "proofreadTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftProofreadDTO.proofreadTime",
                                },
                              },
                              [
                                _c("span", {
                                  attrs: { slot: "suffixIcon" },
                                  slot: "suffixIcon",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "column-6 desc" }, [
                          _vm._v("质量会签"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "column-7 desc" },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "materialSelect userSelect",
                                staticStyle: {
                                  width: "96%",
                                  height: "98%",
                                  "margin-left": "2px",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  "show-search": "",
                                  "option-filter-prop": "children",
                                  "filter-option": _vm.filterUser,
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO.craftQualityDTO
                                      .qualityId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO
                                        .craftQualityDTO,
                                      "qualityId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftQualityDTO.qualityId",
                                },
                              },
                              _vm._l(_vm.userList, function (user) {
                                return _c(
                                  "a-select-option",
                                  { key: user.id, attrs: { value: user.id } },
                                  [_vm._v(_vm._s(user.realName))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "column-8 desc" },
                          [
                            _c(
                              "a-date-picker",
                              {
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  placeholder: "",
                                  "value-format": "YYYY-MM-DD HH:mm:ss",
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO.craftQualityDTO
                                      .qualityTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO
                                        .craftQualityDTO,
                                      "qualityTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftQualityDTO.qualityTime",
                                },
                              },
                              [
                                _c("span", {
                                  attrs: { slot: "suffixIcon" },
                                  slot: "suffixIcon",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._m(18, true),
                        _vm._m(19, true),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "column-1 desc" }),
                        _c("div", {
                          staticClass: "column-2 desc",
                          staticStyle: { "border-left": "none" },
                        }),
                        _c("div", { staticClass: "column-3 desc" }, [
                          _vm._v("审核"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "column-4 desc" },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "materialSelect userSelect",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  "show-search": "",
                                  "option-filter-prop": "children",
                                  "filter-option": _vm.filterUser,
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO.craftAuditDTO
                                      .auditorId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO.craftAuditDTO,
                                      "auditorId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftAuditDTO.auditorId",
                                },
                              },
                              _vm._l(_vm.userList, function (user) {
                                return _c(
                                  "a-select-option",
                                  { key: user.id, attrs: { value: user.id } },
                                  [_vm._v(_vm._s(user.realName))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "column-5 desc" },
                          [
                            _c(
                              "a-date-picker",
                              {
                                staticStyle: {
                                  width: "96%",
                                  "margin-left": "2px",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: _vm.ifCheck,
                                  placeholder: "",
                                  "value-format": "YYYY-MM-DD HH:mm:ss",
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO.craftAuditDTO
                                      .auditTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO.craftAuditDTO,
                                      "auditTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftAuditDTO.auditTime",
                                },
                              },
                              [
                                _c("span", {
                                  attrs: { slot: "suffixIcon" },
                                  slot: "suffixIcon",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", {
                          staticClass: "column-6 desc",
                          domProps: {
                            innerHTML: _vm._s("批&#12288;&#12288;准"),
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "column-7 desc" },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "materialSelect userSelect",
                                staticStyle: {
                                  width: "96%",
                                  "margin-left": "2px",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: true,
                                  "show-search": "",
                                  "option-filter-prop": "children",
                                  "filter-option": _vm.filterUser,
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO.craftApproveDTO
                                      .approverId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO
                                        .craftApproveDTO,
                                      "approverId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftApproveDTO.approverId",
                                },
                              },
                              _vm._l(_vm.userList, function (user) {
                                return _c(
                                  "a-select-option",
                                  { key: user.id, attrs: { value: user.id } },
                                  [_vm._v(_vm._s(user.realName))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "column-8 desc" },
                          [
                            _c(
                              "a-date-picker",
                              {
                                staticStyle: {
                                  width: "96%",
                                  height: "98%",
                                  "margin-left": "2px",
                                  "text-align": "center",
                                  border: "none",
                                },
                                attrs: {
                                  disabled: true,
                                  placeholder: "",
                                  "value-format": "YYYY-MM-DD HH:mm:ss",
                                },
                                model: {
                                  value:
                                    item.operationCardFooterDTO.craftApproveDTO
                                      .approveTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.operationCardFooterDTO
                                        .craftApproveDTO,
                                      "approveTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.operationCardFooterDTO.craftApproveDTO.approveTime",
                                },
                              },
                              [
                                _c("span", {
                                  attrs: { slot: "suffixIcon" },
                                  slot: "suffixIcon",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "column-9 desc" }, [
                          _vm._v(
                            "共 " +
                              _vm._s(
                                item.operationCardHeaderDTO.operationCardBaseDTO
                                  .totalPages
                              ) +
                              " 页"
                          ),
                        ]),
                        _c("div", { staticClass: "column-10 desc" }, [
                          _vm._v("第 " + _vm._s(index + 1) + " 页"),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }
            ),
            0
          )
        }),
        !_vm.ifPrint
          ? _c(
              "div",
              { staticClass: "card-footer" },
              [
                _c(
                  "a-button",
                  {
                    directives: [
                      {
                        name: "print",
                        rawName: "v-print",
                        value: "craft-card-print",
                        expression: "'craft-card-print'",
                      },
                    ],
                    staticStyle: { "margin-left": "100px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.handlePrint },
                  },
                  [_vm._v("打印")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("@/assets/logo.png"), alt: "" },
      }),
      _c("div", { staticClass: "card-title" }, [_vm._v("工艺卡片")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "unit" }, [_vm._v("单 位")]),
      _c("div", { staticClass: "name" }, [_vm._v("重庆市DBR刀具有限公司")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("产品名称")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("产品图号")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("产品规格")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("p", { staticStyle: { "letter-spacing": "0px" } }, [
        _vm._v("工艺文件编号"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("p", { staticStyle: { "letter-spacing": "5px" } }, [_vm._v("计划号")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("材料")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("材料牌号")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("硬度")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("版本号")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("备注")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("总加工工时")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stand-time" }, [
      _c("div", { staticClass: "ot" }, [_c("p", [_vm._v("核定加工工时")])]),
      _c("div", { staticClass: "date" }, [_c("p", [_vm._v("核定转出日期")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "process null" }, [
      _c("td", { staticClass: "num" }),
      _c("td", { staticClass: "type" }),
      _c("td", { staticClass: "content" }),
      _c("td", { staticClass: "hour" }),
      _c("td", { staticClass: "date" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "column-1 desc" }),
      _c("div", { staticClass: "column-2 desc" }),
      _c("div", { staticClass: "column-3 desc" }),
      _c("div", { staticClass: "column-4 desc" }),
      _c("div", { staticClass: "column-5 desc" }),
      _c("div", { staticClass: "column-6 desc" }),
      _c("div", { staticClass: "column-7 desc" }),
      _c("div", { staticClass: "column-8 desc" }),
      _c("div", { staticClass: "column-9 desc" }),
      _c("div", { staticClass: "column-10 desc" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "column-1 desc" }),
      _c("div", { staticClass: "column-2 desc" }),
      _c("div", { staticClass: "column-3 desc" }),
      _c("div", { staticClass: "column-4 desc" }),
      _c("div", { staticClass: "column-5 desc" }),
      _c("div", { staticClass: "column-6 desc" }),
      _c("div", { staticClass: "column-7 desc" }),
      _c("div", { staticClass: "column-8 desc" }),
      _c("div", { staticClass: "column-9 desc" }),
      _c("div", { staticClass: "column-10 desc" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "column-1 desc" }, [_vm._v("标记")]),
      _c("div", { staticClass: "column-2 desc" }, [_vm._v("处数")]),
      _c("div", { staticClass: "column-3 desc" }, [_vm._v("更改单号")]),
      _c("div", { staticClass: "column-4 desc" }, [_vm._v("签名")]),
      _c("div", { staticClass: "column-5 desc" }, [_vm._v("日期")]),
      _c("div", { staticClass: "column-6 desc" }, [_vm._v("标记")]),
      _c("div", { staticClass: "column-7 desc" }, [_vm._v("处数")]),
      _c("div", { staticClass: "column-8 desc" }, [_vm._v("更改单号")]),
      _c("div", { staticClass: "column-9 desc" }, [_vm._v("签名")]),
      _c("div", { staticClass: "column-10 desc" }, [_vm._v("日期")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-9 desc" }, [
      _c("div", { staticClass: "left" }, [_vm._v("S")]),
      _c("div", { staticClass: "right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-10 desc" }, [
      _c("div", { staticClass: "left" }),
      _c("div", { staticClass: "right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }