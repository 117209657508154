<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    visible="true"
    :title="title+&quot;支付信息&quot;"
    :mask-closable="false"
    width="700px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="order" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="发票代码" prop="invoiceCode">
        <a-input v-model.trim="order.invoiceCode" max-length="15" placeholder="请输入发票代码" />
      </a-form-model-item>
      <a-form-model-item label="结算方式" prop="clearingForm">
        <a-radio-group v-model="order.clearingForm">
          <a-radio value="挂账付款">挂账付款</a-radio>
          <a-radio value="预付货款发货前结清尾款">预付货款发货前结清尾款</a-radio>
          <a-radio value="全款提货">全款提货</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="支付方式" prop="paymentMethod">
        <a-radio-group v-model="order.paymentMethod">
          <a-radio value="现金">现金</a-radio>
          <a-radio value="微信">微信</a-radio>
          <a-radio value="银行">银行</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="发票类型" prop="invoiceType">
        <a-radio-group v-model="order.invoiceType">
          <a-radio value="收据">收据</a-radio>
          <a-radio value="增值税专票">增值税专票</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="开票日期" prop="invoiceDate">
        <a-date-picker v-model="order.invoiceDate" show-time type="date" placeholder="请选择开票日期" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="预付额" prop="advancePayment">
        <a-input-number v-model="order.advancePayment" :precision="2" :min="0" :max="99999" placeholder="请输入预付额" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="尾款金额" prop="balancePayment">
        <a-input-number v-model="order.balancePayment" :precision="2" :min="0" :max="99999" placeholder="请输入尾款金额" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updatePaymentByOrder } from '@/api/order'
import Moment from 'moment'
export default {
  props: {
    modalTitle: String,
    orderId: Number,
    orderForm: Object,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      if (value !== 0 && !value) {
        return callback(new Error('请输入数量'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字'))
      } else if (value < 0 || value > 999999) {
        callback(new Error('请输入不超过999999的正整数'))
      } else {
        callback()
      }
    }
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5,
        offset: 2,
      },
      wrapperCol: {
        span: 10,
        offset: 1,
      },
      loading: false,
      order: {
        orderId: this.orderForm.id,
        invoiceCode: this.orderForm.invoiceCode,
        clearingForm: this.orderForm.clearingForm,
        paymentMethod: this.orderForm.paymentMethod,
        invoiceType: this.orderForm.invoiceType,
        invoiceDate: this.orderForm.invoiceDate
          ? Moment(this.orderForm.invoiceDate)
          : null,
        advancePayment: this.orderForm.advancePayment,
        balancePayment: this.orderForm.balancePayment,
      },
      rules: {},
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function () {
      return this.modalTitle === '新增'
    },
    isUpdate: function () {
      return this.modalTitle === '更新'
    },
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      console.log(this.order)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isUpdate) {
            this.order.invoiceDate =
              this.order.invoiceDate === null
                ? null
                : this.order.invoiceDate.local().format('YYYY-MM-DD HH:mm:ss')
            this.updatePayment(this.order)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updatePayment(orderFormData) {
      this.loading = true
      updatePaymentByOrder(orderFormData.orderId, orderFormData)
        .then((res) => {
          if (res.data.id) {
            const successMessage = '更新支付信息成功！'
            const failMessage = '更新支付信息失败，请重试！'
            this.$emit('confirm')
            this.$message.success(successMessage)
          } else {
            this.$message.error(failMessage)
          }
        })
        .catch(
          // 记录失败原因
          (reason) => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
  },
}
</script>

<style scoped>
.searchFilterIcon {
  position: absolute;
  top: 10px;
  right: 0;
  padding-right: 5px;
  padding-left: 15px;
  padding-bottom: 20px;
}

.searchFilterIcon ~ .el-table__column-filter-trigger {
  display: none;
}

.searchButton {
  width: 90px;
  margin-right: 8px;
}

.resetButton {
  width: 90px;
}

.searchInput {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}

.divider-text {
  color: grey;
  font-size: 5px;
}

.modal-container >>> .ant-modal-body {
  padding-top: 0px;
}

.modal-container >>> .ant-form-item {
  margin-bottom: 10px;
}

.modal-container >>> .ant-input-number {
  width: 200px;
}
</style>
