<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" :title="title" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="form" :rules="rules" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="备注" prop="comment">
        <a-textarea v-model.trim="form.comment" placeholder="取消备注" :max-length="500" :auto-size="{ minRows: 3, maxRows: 5 }" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { cancelInquiryItem } from '@/api/inquirylist'
export default {
  props: {
    recordsData: Array
  },
  data() {
    return {
      title: '取消询价单' + this.recordsData.code,
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 10,
        offset: 1
      },
      template: 1,
      cardType: 0,
      form: {
        comment: ''
      },
      rules: {}
    }
  },
  methods: {
    initData() {
      this.form.comment = ''
    },
    handleCancel() {
      this.initData()
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        const that = this
        if (valid) {
          cancelInquiryItem([
            {
              code: this.recordsData.code,
              comment: this.form.comment
            }
          ])
            .then(res => {
              this.$message.success('取消成功')
              this.$emit('confirm')
            })
            .finally(() => {
              this.initData()
            })
        } else {
          console.log('error submit!!', valid)
          return false
        }
      })
    }
  }
}
</script>

<style scoped></style>
