<template>
  <div class="app-container">
    <a-row>
      <a-col :span="2">
        <c-upload
          name="file"
          :accept="&quot;.xlsx&quot;"
          :show-upload-list="false"
          :action="`${uploadUrl}users/imports`"
          @change="handleFileChange"
          @beforeUpload="beforeUpload"
        >
          <el-button slot="content" class="header-button" type="primary" :loading="uploading" icon="el-icon-circle-plus-outline">导入员工</el-button>
        </c-upload>
      </a-col>
      <a-col :span="2">
        <el-button class="header-button" type="primary" icon="el-icon-circle-plus-outline" @click="handleAddUser">新增员工</el-button>
      </a-col>
      <a-col :span="3" :offset="17">
        <el-button type="text" icon="el-icon-download" @click="handleDownloadTemplate">点击下载员工导入模板</el-button>
      </a-col>
    </a-row>
    <a-table
      row-key="id"
      bordered
      class="users-table"
      :columns="userColumns"
      :data-source="userList"
      :loading="loading"
      :pagination="pagination"
      :scroll="{y: 590 }"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>
      <span slot="sex" slot-scope="text">
        <a-tag v-if="text===1" color="volcano">男</a-tag>
        <a-tag v-if="text===2" color="geekblue">女</a-tag>
      </span>
      <span slot="status" slot-scope="text">
        <a-tag v-if="text===1" color="green">正常</a-tag>
        <a-tag v-if="text===2" color="red">已删除</a-tag>
      </span>
      <span slot="role" slot-scope="text,column">
        <a-tag v-for="role in column.roles">{{ role.name }}</a-tag>
      </span>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operate" slot-scope="text, column" class="table-user">
        <a-dropdown v-if="column.status!==2">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleUpdateUser(column)">更新员工</a-menu-item>
            <a-menu-item @click="handleUpdateUserRole(column)">角色设置</a-menu-item>
            <a-menu-item @click="handleUpdateUserOperation(column)">工种设置</a-menu-item>
            <a-menu-item @click="handleDeleteUser(column)">删除员工</a-menu-item>
          </a-menu>
          <a class="user-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <el-dropdown class="export-plan" @command="handleExportUserCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出员工</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="ALL">导出所有员工</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <UserEdit
      v-if="editUserModalVisible"
      :user-form="currentUser"
      :modal-title="currentEditType"
      @cancel="handleUserModalCanceled"
      @confirm="handleUserModalConfirmed"
    />
    <UserRoleSelect
      v-if="userRoleModalVisible"
      :user-form="currentUser"
      @cancel="handleUserRoleModalCanceled"
      @confirm="handleUserRoleModalConfirmed"
    />
    <UserOperationSelect
      v-if="userOperationModalVisible"
      :user-form="currentUser"
      @cancel="handleUserOperationModalCanceled"
      @confirm="handleUserOperationModalConfirmed"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { userColumns } from './dataLogic'
import UserEdit from '@/components/UserEdit'
import UserRoleSelect from '@/components/UserRoleSelect'
import UserOperationSelect from '@/components/UserOperationSelect'
import { getUsers, deleteUser, exportAllUsers } from '@/api/user'
import { downloadTemplate } from '@/common/templateDownload'

export default {
  components: {
    UserEdit,
    UserRoleSelect,
    UserOperationSelect
  },
  data() {
    return {
      loading: false,
      uploading: false,
      userList: [],
      userColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initUserListQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentUserListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentEditType: '',
      currentUser: {},
      editUserModalVisible: false,
      userRoleModalVisible: false,
      userOperationModalVisible: false,
      columnSearchParams: {},
      uploadUrl: process.env.VUE_APP_BASE_API,
      exportLoading: false
    }
  },
  created() {
    this.getUsers(this.initUserListQueryParams)
  },
  methods: {
    getUsers(data) {
      this.loading = true
      getUsers(data)
        .then(res => {
          this.userList = res.data.userRoleList
          this.pagination.total = res.data.totalCount
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getUsersByParams() {
      const cQueryParams = deepClone(this.currentUserListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getUsers(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('sex')) {
        delete this.columnSearchParams['sex']
        if (filters['sex'].length > 0) {
          this.columnSearchParams['sex'] = `in:${filters['sex'].toString()}`
        } else {
          delete this.columnSearchParams['sex']
        }
      }
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['user.status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['user.status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['user.status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentUserListQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentUserListQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentUserListQueryParams.pageNum = pagination.current
      this.currentUserListQueryParams.pageSize = pagination.pageSize
      this.getUsersByParams()
    },
    // 删除员工
    handleDeleteUser(column) {
      const realName = column.realName ? column.realName : ''
      this.$confirm({
        content: '确认删除员工' + realName + '?',
        onOk: () => {
          deleteUser(column.id).then(() => {
            this.$message.success('删除员工成功！')
            this.getUsersByParams()
          })
        }
      })
    },
    handleAddUser() {
      this.currentEditType = '新增'
      this.currentUser = {}
      this.currentUser.sex = 1
      this.editUserModalVisible = true
    },
    // 更新员工
    handleUpdateUser(column) {
      this.currentEditType = '更新'
      this.currentUser = deepClone(column)
      this.editUserModalVisible = true
    },
    handleUpdateUserRole(column) {
      this.currentUser = deepClone(column)
      this.userRoleModalVisible = true
    },
    handleUpdateUserOperation(column) {
      this.currentUser = deepClone(column)
      this.userOperationModalVisible = true
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      if (dataIndex === 'roles') {
        dataIndex = 'role.name'
      }
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleUserModalConfirmed() {
      this.editUserModalVisible = false
      this.getUsers(this.currentUserListQueryParams)
    },
    handleUserModalCanceled() {
      this.editUserModalVisible = false
    },
    handleUserRoleModalConfirmed() {
      this.userRoleModalVisible = false
      this.getUsers(this.currentUserListQueryParams)
    },
    handleUserRoleModalCanceled() {
      this.userRoleModalVisible = false
    },
    handleUserOperationModalConfirmed() {
      this.userOperationModalVisible = false
    },
    handleUserOperationModalCanceled() {
      this.userOperationModalVisible = false
    },
    handleDownloadTemplate() {
      downloadTemplate('user')
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`批量导入失败，请稍后再试`)
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      this.$info({
        title: '员工信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入数据总计：' + response.totalNum + '条;'),
          h('p', '非法数据条数：' + response.failedNum + ';'),
          h('p', '导入成功数据条数：' + response.successfulNum + ';'),
          h('p', '非法数据信息:'),
          h('p', response.invalidMessages.join(' ||  '))
        ]),
        onOk: () => {
          this.uploading = false
          this.getUsers(this.currentUserListQueryParams)
        }
      })
    },
    handleExportUserCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'ALL': {
          exportAllUsers()
            .then(res => {
              this.downloadUser(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        }
        default:
          this.exportLoading = false
          break
      }
    },
    downloadUser(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.USER_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .user-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-user {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
  .export-plan {
    float: right;
    bottom: 55px;
    right: 0px;
  }
}
</style>
