var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "div",
        { attrs: { id: "craft-card-print" } },
        [
          _vm._l(
            _vm.cardBodyMessage.craftProcessRouteListDTO,
            function (card, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "card-container",
                  staticStyle: { "page-break-after": "always" },
                },
                [
                  _c("div", { staticClass: "card-header-title" }, [
                    _c("div", { staticClass: "card" }, [
                      !_vm.ifPrint
                        ? _c(
                            "div",
                            { staticClass: "template-btn" },
                            [
                              !_vm.ifCheck && !_vm.ifUpdate
                                ? _c(
                                    "a-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.handleChooseTemplet },
                                    },
                                    [_vm._v("选择模板")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("img", {
                        staticClass: "img",
                        attrs: { src: require("@/assets/logo.png"), alt: "" },
                      }),
                      _c(
                        "div",
                        { staticClass: "card-title" },
                        [
                          _vm._v(" 工艺卡片 "),
                          !_vm.ifPrint && _vm.$route.query.isParts === "1"
                            ? _c(
                                "a-tag",
                                {
                                  staticClass: "tag",
                                  attrs: { color: "blue" },
                                },
                                [_vm._v("分件")]
                              )
                            : _vm._e(),
                          !_vm.ifPrint && _vm.ifBatchAdd
                            ? _c(
                                "a-tag",
                                {
                                  staticClass: "tag",
                                  attrs: { color: "blue" },
                                },
                                [_vm._v("批量")]
                              )
                            : _vm._e(),
                          !_vm.ifPrint && _vm.handleType === "taskCard"
                            ? _c(
                                "a-tag",
                                {
                                  staticClass: "tag",
                                  attrs: { color: "blue" },
                                },
                                [_vm._v(_vm._s(_vm.$route.query.code))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm.cardHeaderMessage.craftPlanDTO.planCode
                      ? _c(
                          "div",
                          { staticClass: "code" },
                          [
                            _c("vue-qrcode", {
                              attrs: {
                                margin: 0,
                                scale: 2,
                                value:
                                  _vm.cardHeaderMessage.craftPlanDTO.planCode,
                              },
                            }),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "code" }),
                    _vm._m(0, true),
                  ]),
                  _c("div", { staticClass: "card-header-production" }, [
                    _c("div", { staticClass: "product-item" }, [
                      _vm._m(1, true),
                      _c("div", { staticClass: "cell" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.cardHeaderMessage.craftProductDTO
                                  .craftProductName,
                              expression:
                                "cardHeaderMessage.craftProductDTO.craftProductName",
                            },
                          ],
                          style: { "line-height": 1.2 * _vm.pageUnit + "px" },
                          attrs: {
                            disabled: _vm.ifUpdate || _vm.ifCheck || _vm.ifCopy,
                            requierd: "",
                            maxlength: "16",
                            cols: "10",
                          },
                          domProps: {
                            value:
                              _vm.cardHeaderMessage.craftProductDTO
                                .craftProductName,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cardHeaderMessage.craftProductDTO,
                                "craftProductName",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "product-item" }, [
                      _vm._m(2, true),
                      _c("div", { staticClass: "cell" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.cardHeaderMessage.craftProductDTO
                                  .craftDrawingNum,
                              expression:
                                "cardHeaderMessage.craftProductDTO.craftDrawingNum",
                            },
                          ],
                          style: { "line-height": 1.2 * _vm.pageUnit + "px" },
                          attrs: {
                            disabled: _vm.ifUpdate || _vm.ifCheck || _vm.ifCopy,
                            maxlength: "35",
                            rows: "2",
                            cols: "10",
                          },
                          domProps: {
                            value:
                              _vm.cardHeaderMessage.craftProductDTO
                                .craftDrawingNum,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cardHeaderMessage.craftProductDTO,
                                "craftDrawingNum",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "product-item" }, [
                      _vm._m(3, true),
                      _c("div", { staticClass: "cell" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.cardHeaderMessage.craftProductDTO
                                  .craftSpecification,
                              expression:
                                "cardHeaderMessage.craftProductDTO.craftSpecification",
                            },
                          ],
                          style: { "line-height": 1.2 * _vm.pageUnit + "px" },
                          attrs: {
                            disabled: _vm.ifUpdate || _vm.ifCheck || _vm.ifCopy,
                            maxlength: "25",
                            rows: "2",
                            cols: "10",
                          },
                          domProps: {
                            value:
                              _vm.cardHeaderMessage.craftProductDTO
                                .craftSpecification,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cardHeaderMessage.craftProductDTO,
                                "craftSpecification",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "product-item" }, [
                      _vm._m(4, true),
                      _c("div", { staticClass: "cell" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.cardHeaderMessage.operationCardBaseDTO
                                  .craftCode,
                              expression:
                                "cardHeaderMessage.operationCardBaseDTO.craftCode",
                            },
                          ],
                          style: { "line-height": 1.2 * _vm.pageUnit + "px" },
                          attrs: {
                            disabled: _vm.ifUpdate || _vm.ifCheck || _vm.ifCopy,
                            maxlength: "16",
                            rows: "2",
                            cols: "10",
                          },
                          domProps: {
                            value:
                              _vm.cardHeaderMessage.operationCardBaseDTO
                                .craftCode,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cardHeaderMessage.operationCardBaseDTO,
                                "craftCode",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "product-item" }, [
                      _vm._m(5, true),
                      _c("div", { staticClass: "cell" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.cardHeaderMessage.craftPlanDTO.planCode,
                              expression:
                                "cardHeaderMessage.craftPlanDTO.planCode",
                            },
                          ],
                          style: { "line-height": 1.2 * _vm.pageUnit + "px" },
                          attrs: {
                            disabled:
                              _vm.ifUpdate ||
                              _vm.ifCheck ||
                              _vm.$route.query.isParts === "0" ||
                              _vm.ifPaste ||
                              _vm.ifCopy,
                            maxlength: "16",
                            rows: "2",
                            cols: "10",
                          },
                          domProps: {
                            value: _vm.cardHeaderMessage.craftPlanDTO.planCode,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cardHeaderMessage.craftPlanDTO,
                                "planCode",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "card-header-material" }, [
                    _c("div", { staticClass: "material-item" }, [
                      _vm._m(6, true),
                      _c(
                        "div",
                        { staticClass: "cell" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass: "materialSelect",
                              staticStyle: {
                                width: "96%",
                                "margin-left": "5px",
                                "margin-top": "3px",
                              },
                              attrs: {
                                "allow-clear": "",
                                disabled: _vm.ifCheck,
                                value:
                                  _vm.cardHeaderMessage.craftMaterialDTO
                                    .materialName,
                                "show-search": "",
                                "default-active-first-option": false,
                                placeholder: "请输入材料名",
                                "show-arrow": false,
                                "filter-option": false,
                                "not-found-content": _vm.fetching
                                  ? undefined
                                  : null,
                              },
                              on: {
                                search: _vm.fetchMaterialByName,
                                change: function (value, option) {
                                  return _vm.handleMaterialNameChange(
                                    value,
                                    option,
                                    index
                                  )
                                },
                              },
                            },
                            [
                              _vm.fetching
                                ? _c("a-spin", {
                                    attrs: {
                                      slot: "notFoundContent",
                                      size: "small",
                                    },
                                    slot: "notFoundContent",
                                  })
                                : _vm._e(),
                              _vm._l(_vm.materials, function (material) {
                                return _c(
                                  "a-select-option",
                                  { key: material.id },
                                  [
                                    _vm._v(
                                      _vm._s(material.materialName) +
                                        "(" +
                                        _vm._s(material.materialCode) +
                                        ")"
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "material-item" }, [
                      _vm._m(7, true),
                      _c(
                        "div",
                        { staticClass: "cell" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass: "materialSelect",
                              staticStyle: {
                                width: "96%",
                                "margin-left": "5px",
                                "margin-top": "3px",
                                border: "none",
                              },
                              attrs: {
                                "allow-clear": "",
                                disabled: _vm.ifCheck,
                                value:
                                  _vm.cardHeaderMessage.craftMaterialDTO
                                    .materialCode,
                                "show-search": "",
                                "default-active-first-option": false,
                                placeholder: "请输入材料图号",
                                "show-arrow": false,
                                "filter-option": false,
                                "not-found-content": _vm.fetching
                                  ? undefined
                                  : null,
                              },
                              on: {
                                search: _vm.fetchMaterialByCode,
                                change: function (value, option) {
                                  return _vm.handleMaterialNameChange(
                                    value,
                                    option,
                                    index
                                  )
                                },
                              },
                            },
                            [
                              _vm.fetching
                                ? _c("a-spin", {
                                    attrs: {
                                      slot: "notFoundContent",
                                      size: "small",
                                    },
                                    slot: "notFoundContent",
                                  })
                                : _vm._e(),
                              _vm._l(_vm.materials, function (material) {
                                return _c(
                                  "a-select-option",
                                  { key: material.id },
                                  [
                                    _vm._v(
                                      _vm._s(material.materialName) +
                                        "(" +
                                        _vm._s(material.materialCode) +
                                        ")"
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "material-item" }, [
                      _vm._m(8, true),
                      _c(
                        "div",
                        { staticClass: "cell" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass: "materialSelect",
                              staticStyle: {
                                width: "96%",
                                "margin-left": "5px",
                                "margin-top": "3px",
                              },
                              attrs: {
                                "show-arrow": false,
                                "allow-clear": true,
                                disabled: _vm.ifCheck,
                                "show-search": "",
                                "filter-option": false,
                                "not-found-content": _vm.fetching
                                  ? undefined
                                  : null,
                              },
                              on: { search: _vm.fetchHardness },
                              model: {
                                value:
                                  _vm.cardHeaderMessage.craftProductDTO
                                    .operationHardness,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardHeaderMessage.craftProductDTO,
                                    "operationHardness",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardHeaderMessage.craftProductDTO.operationHardness",
                              },
                            },
                            [
                              _vm.fetching
                                ? _c("a-spin", {
                                    attrs: {
                                      slot: "notFoundContent",
                                      size: "small",
                                    },
                                    slot: "notFoundContent",
                                  })
                                : _vm._e(),
                              _vm._l(_vm.hardnessList, function (hardness) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: hardness.parameterValue,
                                    attrs: { value: hardness.parameterValue },
                                  },
                                  [_vm._v(_vm._s(hardness.parameterValue))]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "material-item" }, [
                      _vm._m(9, true),
                      _c(
                        "div",
                        { staticClass: "cell" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass:
                                "materialSelect userSelect versionSelect",
                              staticStyle: {
                                width: "90%",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                disabled: _vm.ifCheck || _vm.ifUpdate,
                                "option-filter-prop": "children",
                              },
                              model: {
                                value:
                                  _vm.cardHeaderMessage.operationCardBaseDTO
                                    .operationCardVersion,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardHeaderMessage.operationCardBaseDTO,
                                    "operationCardVersion",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardHeaderMessage.operationCardBaseDTO.operationCardVersion",
                              },
                            },
                            _vm._l(_vm.versionList, function (i) {
                              return _c(
                                "a-select-option",
                                {
                                  key: (i + 9).toString(36) + i,
                                  attrs: { value: i },
                                },
                                [_vm._v(_vm._s(i))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "material-item" }, [
                      _vm._m(10, true),
                      _c("div", { staticClass: "cell" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.cardHeaderMessage.operationCardBaseDTO
                                  .comment,
                              expression:
                                "cardHeaderMessage.operationCardBaseDTO.comment",
                            },
                          ],
                          attrs: {
                            disabled: _vm.ifCheck,
                            rows: "2",
                            cols: "10",
                          },
                          domProps: {
                            value:
                              _vm.cardHeaderMessage.operationCardBaseDTO
                                .comment,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cardHeaderMessage.operationCardBaseDTO,
                                "comment",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      ref: "processRoute" + index,
                      refInFor: true,
                      staticClass: "card-header-processRoute",
                      attrs: { id: "processRoute" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-header-processRoute-header" },
                        [
                          _c("div", { staticClass: "num" }, [_vm._v("工序号")]),
                          _c("div", { staticClass: "type" }, [_vm._v("工种")]),
                          _c(
                            "div",
                            { staticClass: "content" },
                            [
                              !_vm.ifPrint && !_vm.ifCheck
                                ? [
                                    _c(
                                      "c-upload",
                                      {
                                        attrs: {
                                          name: "file",
                                          accept: ".xlsx",
                                          multiple: true,
                                          "show-upload-list": false,
                                          action:
                                            _vm.baseURL +
                                            "crafts/operation-cards/process-route/imports",
                                        },
                                        on: {
                                          change: _vm.handleProcessFileChange,
                                        },
                                      },
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "upload" },
                                            }),
                                            _vm._v("导入路线 "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" 工 序 名 称 及 内 容 "),
                              index + 1 ===
                              _vm.cardBodyMessage.craftProcessRouteListDTO
                                .length
                                ? [
                                    !_vm.ifPrint && !_vm.ifCheck
                                      ? _c(
                                          "a-button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAddCraft(index)
                                              },
                                            },
                                          },
                                          [_vm._v("新增工序")]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "time-info" }, [
                            _c("div", { staticClass: "all-time" }, [
                              _vm._m(11, true),
                              _c("div", { staticClass: "data" }, [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.cardHeaderMessage.operationCardBaseDTO
                                        .totalProcessHour
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._m(12, true),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "card-header-processRoute-content" },
                        [
                          _c(
                            "table",
                            {
                              ref: "processRouteContent" + index,
                              refInFor: true,
                            },
                            [
                              _c(
                                "tbody",
                                _vm._l(
                                  card.processRoute,
                                  function (route, routeIndex) {
                                    return _c(
                                      "tr",
                                      { key: route.id, staticClass: "process" },
                                      [
                                        _c(
                                          "td",
                                          { staticClass: "num" },
                                          [
                                            route.sequenceNum % 2 !== 0 &&
                                            !_vm.ifCheck
                                              ? [
                                                  _c(
                                                    "a-dropdown",
                                                    [
                                                      _c(
                                                        "a-menu",
                                                        {
                                                          staticClass:
                                                            "ant-dropdown-link",
                                                          attrs: {
                                                            slot: "overlay",
                                                          },
                                                          slot: "overlay",
                                                        },
                                                        [
                                                          _c(
                                                            "a-menu-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDeleteCraft(
                                                                      route,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("删除工序")]
                                                          ),
                                                          _c(
                                                            "a-menu-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleEditCraft(
                                                                      route,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("编辑工序")]
                                                          ),
                                                          _c(
                                                            "a-menu-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleInsertCraft(
                                                                      route,
                                                                      routeIndex,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("插入工序")]
                                                          ),
                                                          _c(
                                                            "a-menu-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleCopyCraft(
                                                                      route,
                                                                      routeIndex,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("复制工序")]
                                                          ),
                                                          _vm.ifcopyStatus
                                                            ? _c(
                                                                "a-menu-item",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handlePasteCraft(
                                                                          route,
                                                                          routeIndex,
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "粘贴工序"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticStyle: {
                                                            color: "#1890ff",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              route.sequenceNum
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : [
                                                  _vm._v(
                                                    _vm._s(route.sequenceNum)
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "type",
                                            style: {
                                              "font-size":
                                                route.operationName &&
                                                route.operationName.length > 3
                                                  ? "12px"
                                                  : "18px",
                                              "line-height":
                                                route.operationName &&
                                                route.operationName.length > 3
                                                  ? "14px"
                                                  : "18px",
                                            },
                                          },
                                          [_vm._v(_vm._s(route.operationName))]
                                        ),
                                        _c("td", { staticClass: "content" }, [
                                          _vm._v(_vm._s(route.content)),
                                        ]),
                                        _c("td", { staticClass: "hour" }, [
                                          _vm._v(
                                            _vm._s(route.verifyProcessHour)
                                          ),
                                        ]),
                                        _c("td", { staticClass: "date" }, [
                                          _vm._v(
                                            _vm._s(route.verifyTransferOutTime)
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          card.nullProcessRouteNum >= 0
                            ? _c(
                                "table",
                                _vm._l(
                                  new Array(card.nullProcessRouteNum),
                                  function (item, newindex) {
                                    return _c("tbody", { key: newindex }, [
                                      _vm._m(13, true),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "card-header-bottom" }, [
                    _vm._m(14, true),
                    _vm._m(15, true),
                    _vm._m(16, true),
                    _c("div", { staticClass: "row" }, [
                      _c("div", {
                        staticClass: "column-1 desc",
                        staticStyle: {
                          "border-bottom": "none",
                          "border-right": "none",
                        },
                      }),
                      _c("div", {
                        staticClass: "column-2 desc",
                        staticStyle: {
                          "border-left": "none",
                          "border-bottom": "none",
                        },
                      }),
                      _c("div", { staticClass: "column-3 desc" }, [
                        _vm._v("编制"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "column-4 desc" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass: "materialSelect userSelect",
                              staticStyle: {
                                width: "96%",
                                height: "94%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                defaultValue:
                                  _vm.cardFooterMessage.craftDesignDTO.designer,
                                "allow-clear": "",
                                "show-arrow": false,
                                disabled: _vm.ifCheck,
                                "show-search": "",
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterUser,
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftDesignDTO
                                    .designerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftDesignDTO,
                                    "designerId",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftDesignDTO.designerId",
                              },
                            },
                            _vm._l(_vm.userList, function (user) {
                              return _c(
                                "a-select-option",
                                { key: user.id, attrs: { value: user.id } },
                                [_vm._v(_vm._s(user.realName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column-5 desc" },
                        [
                          _c(
                            "a-date-picker",
                            {
                              staticStyle: {
                                width: "96%",
                                height: "94%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                disabled: _vm.ifCheck,
                                placeholder: "",
                                "value-format": "YYYY-MM-DD HH:mm:ss",
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftDesignDTO
                                    .designTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftDesignDTO,
                                    "designTime",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftDesignDTO.designTime",
                              },
                            },
                            [
                              _c("span", {
                                attrs: { slot: "suffixIcon" },
                                slot: "suffixIcon",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "column-6 desc",
                        domProps: {
                          innerHTML: _vm._s(
                            "标&#8197;&#8197;准&#8197;&#8197;化"
                          ),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "column-7 desc" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass: "materialSelect userSelect",
                              staticStyle: {
                                width: "96%",
                                height: "94%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                "allow-clear": "",
                                "show-arrow": false,
                                disabled: _vm.ifCheck,
                                "show-search": "",
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterUser,
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftStandardDTO
                                    .standardId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftStandardDTO,
                                    "standardId",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftStandardDTO.standardId",
                              },
                            },
                            _vm._l(_vm.userList, function (user) {
                              return _c(
                                "a-select-option",
                                { key: user.id, attrs: { value: user.id } },
                                [_vm._v(_vm._s(user.realName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column-8 desc" },
                        [
                          _c(
                            "a-date-picker",
                            {
                              staticStyle: {
                                width: "96%",
                                height: "94%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                disabled: _vm.ifCheck,
                                placeholder: "",
                                "value-format": "YYYY-MM-DD HH:mm:ss",
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftStandardDTO
                                    .standardTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftStandardDTO,
                                    "standardTime",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftStandardDTO.standardTime",
                              },
                            },
                            [
                              _c("span", {
                                attrs: { slot: "suffixIcon" },
                                slot: "suffixIcon",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "column-combine desc" }, [
                        _vm._v("阶段标记"),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", {
                        staticClass: "column-1 desc",
                        staticStyle: { "border-bottom": "none" },
                      }),
                      _c("div", {
                        staticClass: "column-2 desc",
                        staticStyle: {
                          "border-left": "none",
                          "border-bottom": "none",
                        },
                      }),
                      _c("div", { staticClass: "column-3 desc" }, [
                        _vm._v("校对"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "column-4 desc" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass: "materialSelect userSelect",
                              staticStyle: {
                                width: "96%",
                                height: "94%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                "allow-clear": "",
                                "show-arrow": false,
                                disabled: _vm.ifCheck,
                                "show-search": "",
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterUser,
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftProofreadDTO
                                    .collatorId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftProofreadDTO,
                                    "collatorId",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftProofreadDTO.collatorId",
                              },
                            },
                            _vm._l(_vm.userList, function (user) {
                              return _c(
                                "a-select-option",
                                { key: user.id, attrs: { value: user.id } },
                                [_vm._v(_vm._s(user.realName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column-5 desc" },
                        [
                          _c(
                            "a-date-picker",
                            {
                              staticStyle: {
                                width: "96%",
                                height: "94%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                disabled: _vm.ifCheck,
                                placeholder: "",
                                "value-format": "YYYY-MM-DD HH:mm:ss",
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftProofreadDTO
                                    .proofreadTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftProofreadDTO,
                                    "proofreadTime",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftProofreadDTO.proofreadTime",
                              },
                            },
                            [
                              _c("span", {
                                attrs: { slot: "suffixIcon" },
                                slot: "suffixIcon",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "column-6 desc" }, [
                        _vm._v("质量会签"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "column-7 desc" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass: "materialSelect userSelect",
                              staticStyle: {
                                width: "96%",
                                height: "94%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                "allow-clear": "",
                                "show-arrow": false,
                                disabled: _vm.ifCheck,
                                "show-search": "",
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterUser,
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftQualityDTO
                                    .qualityId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftQualityDTO,
                                    "qualityId",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftQualityDTO.qualityId",
                              },
                            },
                            _vm._l(_vm.userList, function (user) {
                              return _c(
                                "a-select-option",
                                { key: user.id, attrs: { value: user.id } },
                                [_vm._v(_vm._s(user.realName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column-8 desc" },
                        [
                          _c(
                            "a-date-picker",
                            {
                              staticStyle: {
                                width: "96%",
                                height: "94%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                disabled: _vm.ifCheck,
                                placeholder: "",
                                "value-format": "YYYY-MM-DD HH:mm:ss",
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftQualityDTO
                                    .qualityTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftQualityDTO,
                                    "qualityTime",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftQualityDTO.qualityTime",
                              },
                            },
                            [
                              _c("span", {
                                attrs: { slot: "suffixIcon" },
                                slot: "suffixIcon",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "column-9 desc" }, [
                        _c("div", { staticClass: "left" }, [_vm._v("S")]),
                        _c("div", { staticClass: "right" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.parseTagList[1],
                                expression: "parseTagList[1]",
                              },
                            ],
                            attrs: {
                              type: "text",
                              disabled: _vm.ifCheck,
                              maxlength: "1",
                            },
                            domProps: { value: _vm.parseTagList[1] },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.parseTagList,
                                    1,
                                    $event.target.value
                                  )
                                },
                                function (e) {
                                  return _vm.handleParseInput(e, 1, "A")
                                },
                              ],
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "column-10 desc" }, [
                        _c("div", { staticClass: "left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.parseTagList[2],
                                expression: "parseTagList[2]",
                              },
                            ],
                            attrs: {
                              type: "text",
                              disabled: !_vm.parseTagList[1] || _vm.ifCheck,
                              maxlength: "1",
                            },
                            domProps: { value: _vm.parseTagList[2] },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.parseTagList,
                                    2,
                                    $event.target.value
                                  )
                                },
                                function (e) {
                                  return _vm.handleParseInput(e, 2, "B")
                                },
                              ],
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "right" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.parseTagList[3],
                                expression: "parseTagList[3]",
                              },
                            ],
                            attrs: {
                              type: "text",
                              disabled: !_vm.parseTagList[2] || _vm.ifCheck,
                              maxlength: "1",
                            },
                            domProps: { value: _vm.parseTagList[3] },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.parseTagList,
                                    3,
                                    $event.target.value
                                  )
                                },
                                function (e) {
                                  return _vm.handleParseInput(e, 3, "C")
                                },
                              ],
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "column-1 desc" }),
                      _c("div", {
                        staticClass: "column-2 desc",
                        staticStyle: { "border-left": "none" },
                      }),
                      _c("div", { staticClass: "column-3 desc" }, [
                        _vm._v("审核"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "column-4 desc" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass: "materialSelect userSelect",
                              staticStyle: {
                                width: "100%",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                "allow-clear": "",
                                "show-arrow": false,
                                disabled: _vm.ifCheck,
                                "show-search": "",
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterUser,
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftAuditDTO.auditorId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftAuditDTO,
                                    "auditorId",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftAuditDTO.auditorId",
                              },
                            },
                            _vm._l(_vm.userList, function (user) {
                              return _c(
                                "a-select-option",
                                { key: user.id, attrs: { value: user.id } },
                                [_vm._v(_vm._s(user.realName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column-5 desc" },
                        [
                          _c(
                            "a-date-picker",
                            {
                              staticStyle: {
                                width: "96%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                disabled: _vm.ifCheck,
                                placeholder: "",
                                "value-format": "YYYY-MM-DD HH:mm:ss",
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftAuditDTO.auditTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftAuditDTO,
                                    "auditTime",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftAuditDTO.auditTime",
                              },
                            },
                            [
                              _c("span", {
                                attrs: { slot: "suffixIcon" },
                                slot: "suffixIcon",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "column-6 desc",
                        domProps: { innerHTML: _vm._s("批&#12288;&#12288;准") },
                      }),
                      _c(
                        "div",
                        { staticClass: "column-7 desc" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass: "materialSelect userSelect",
                              staticStyle: {
                                width: "96%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                "allow-clear": "",
                                "show-arrow": false,
                                disabled: true,
                                "show-search": "",
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterUser,
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftApproveDTO
                                    .approverId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftApproveDTO,
                                    "approverId",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftApproveDTO.approverId",
                              },
                            },
                            _vm._l(_vm.userList, function (user) {
                              return _c(
                                "a-select-option",
                                { key: user.id, attrs: { value: user.id } },
                                [_vm._v(_vm._s(user.realName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column-8 desc" },
                        [
                          _c(
                            "a-date-picker",
                            {
                              staticStyle: {
                                width: "96%",
                                height: "98%",
                                "margin-left": "2px",
                                "text-align": "center",
                                border: "none",
                              },
                              attrs: {
                                disabled: true,
                                placeholder: "",
                                "value-format": "YYYY-MM-DD HH:mm:ss",
                              },
                              model: {
                                value:
                                  _vm.cardFooterMessage.craftApproveDTO
                                    .approveTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardFooterMessage.craftApproveDTO,
                                    "approveTime",
                                    $$v
                                  )
                                },
                                expression:
                                  "cardFooterMessage.craftApproveDTO.approveTime",
                              },
                            },
                            [
                              _c("span", {
                                attrs: { slot: "suffixIcon" },
                                slot: "suffixIcon",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "column-9 desc" }, [
                        _vm._v(
                          "共 " +
                            _vm._s(
                              _vm.cardHeaderMessage.operationCardBaseDTO
                                .totalPages
                            ) +
                            " 页"
                        ),
                      ]),
                      _c("div", { staticClass: "column-10 desc" }, [
                        _vm._v("第 " + _vm._s(index + 1) + " 页"),
                      ]),
                    ]),
                  ]),
                ]
              )
            }
          ),
          !_vm.ifPrint
            ? _c(
                "div",
                { staticClass: "card-footer" },
                [
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "是否确认提交数据？",
                        "ok-text": "确定",
                        "cancel-text": "取消",
                      },
                      on: { confirm: _vm.handleSaveCard },
                    },
                    [
                      !_vm.ifCheck
                        ? _c(
                            "a-button",
                            { attrs: { loading: _vm.saveLoading } },
                            [_vm._v("保存")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确认保存为模版？",
                        "ok-text": "确定",
                        "cancel-text": "取消",
                      },
                      on: {
                        confirm: function () {
                          return (_vm.templateVisible = true)
                        },
                      },
                    },
                    [
                      !_vm.ifCheck
                        ? _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "100px" },
                              attrs: { loading: _vm.saveAsTemplateLoading },
                            },
                            [_vm._v("保存为模版")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "print",
                          rawName: "v-print",
                          value: "craft-card-print",
                          expression: "'craft-card-print'",
                        },
                      ],
                      staticStyle: { "margin-left": "100px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.handlePrint },
                    },
                    [_vm._v("打印")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.uploadLoading
        ? _c(
            "div",
            { staticClass: "page-loading" },
            [_c("a-icon", { attrs: { type: "loading" } })],
            1
          )
        : _vm._e(),
      !_vm.ifPrint && !_vm.ifCheck
        ? _c(
            "div",
            { staticClass: "symbols" },
            [
              _c(
                "a-card",
                {
                  staticStyle: { width: "300px" },
                  attrs: { title: "特殊字符", bordered: true },
                },
                [
                  _c(
                    "div",
                    { staticClass: "symbols-content" },
                    _vm._l(_vm.craftSymbols.split(" "), function (item) {
                      return _c(
                        "p",
                        { key: item, staticClass: "symbols-item" },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: {
            title: "工艺模版编号",
            "ok-text": "确认",
            "cancel-text": "取消",
          },
          on: {
            ok: _vm.handleSaveCardAsTemplate,
            cancel: function () {
              return (_vm.templateVisible = false)
            },
          },
          model: {
            value: _vm.templateVisible,
            callback: function ($$v) {
              _vm.templateVisible = $$v
            },
            expression: "templateVisible",
          },
        },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "工艺模版编号" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入工艺模版编号" },
                    model: {
                      value: _vm.templateCrafeCode,
                      callback: function ($$v) {
                        _vm.templateCrafeCode = $$v
                      },
                      expression: "templateCrafeCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.editModalVisible
        ? _c("CraftProcessEditModal", {
            attrs: {
              "modal-type": _vm.modalType,
              "craft-form": _vm.editProcessRoute,
            },
            on: {
              confirm: _vm.handleConfirmCraftModal,
              cancel: _vm.handleCloseCraftModal,
            },
          })
        : _vm._e(),
      _vm.craftTemplateModalVisible
        ? _c("CraftTemplateChoose", {
            on: {
              confirm: _vm.handleTemplateChooseConfirm,
              cancel: _vm.handleTemplateChooseCancel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "unit" }, [_vm._v("单 位")]),
      _c("div", { staticClass: "name" }, [_vm._v("重庆市DBR刀具有限公司")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("产品名称")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("产品图号")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("产品规格")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("p", { staticStyle: { "letter-spacing": "0px" } }, [
        _vm._v("工艺文件编号"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("p", { staticStyle: { "letter-spacing": "5px" } }, [_vm._v("计划号")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("材料")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("材料牌号")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("硬度")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("版本号")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("备注")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("p", [_vm._v("总加工工时")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stand-time" }, [
      _c("div", { staticClass: "ot" }, [_c("p", [_vm._v("核定加工工时")])]),
      _c("div", { staticClass: "date" }, [_c("p", [_vm._v("核定转出日期")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "process null" }, [
      _c("td", { staticClass: "num" }),
      _c("td", { staticClass: "type" }),
      _c("td", { staticClass: "content" }),
      _c("td", { staticClass: "hour" }),
      _c("td", { staticClass: "date" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "column-1 desc" }),
      _c("div", { staticClass: "column-2 desc" }),
      _c("div", { staticClass: "column-3 desc" }),
      _c("div", { staticClass: "column-4 desc" }),
      _c("div", { staticClass: "column-5 desc" }),
      _c("div", { staticClass: "column-6 desc" }),
      _c("div", { staticClass: "column-7 desc" }),
      _c("div", { staticClass: "column-8 desc" }),
      _c("div", { staticClass: "column-9 desc" }),
      _c("div", { staticClass: "column-10 desc" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "column-1 desc" }),
      _c("div", { staticClass: "column-2 desc" }),
      _c("div", { staticClass: "column-3 desc" }),
      _c("div", { staticClass: "column-4 desc" }),
      _c("div", { staticClass: "column-5 desc" }),
      _c("div", { staticClass: "column-6 desc" }),
      _c("div", { staticClass: "column-7 desc" }),
      _c("div", { staticClass: "column-8 desc" }),
      _c("div", { staticClass: "column-9 desc" }),
      _c("div", { staticClass: "column-10 desc" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "column-1 desc" }, [_vm._v("标记")]),
      _c("div", { staticClass: "column-2 desc" }, [_vm._v("处数")]),
      _c("div", { staticClass: "column-3 desc" }, [_vm._v("更改单号")]),
      _c("div", { staticClass: "column-4 desc" }, [_vm._v("签名")]),
      _c("div", { staticClass: "column-5 desc" }, [_vm._v("日期")]),
      _c("div", { staticClass: "column-6 desc" }, [_vm._v("标记")]),
      _c("div", { staticClass: "column-7 desc" }, [_vm._v("处数")]),
      _c("div", { staticClass: "column-8 desc" }, [_vm._v("更改单号")]),
      _c("div", { staticClass: "column-9 desc" }, [_vm._v("签名")]),
      _c("div", { staticClass: "column-10 desc" }, [_vm._v("日期")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }