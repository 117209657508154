<template>
  <a-modal class="modal-container" :visible="true" title="批量装配任务" :maskClosable="false" @cancel="handleCancel" width="600px">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="task" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="员工" prop="user">
        <a-select v-model="task.user" show-search placeholder="请选择人员" option-filter-prop="children" :filter-option="filterUser">
          <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { batchTaskAssemble } from '@/api/task'
import { getAllUsers } from '@/api/user'
import * as R from 'ramda'
export default {
  props: {
    ids: Array,
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5,
        offset: 2,
      },
      wrapperCol: {
        span: 10,
        offset: 1,
      },
      loading: false,
      userList: [],
      task: {
        user: '',
      },
      rules: {
        user: [
          {
            required: true,
            trigger: 'change',
            message: '请选择员工',
          },
        ],
      },
    }
  },
  mounted() {
    this.getAllUsers()
  },
  methods: {
    getAllUsers() {
      getAllUsers().then((res) => {
        if (res.data && res.data.accountList) {
          this.userList = R.clone(res.data.accountList)
        }
      })
    },
    filterUser(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.batchTaskAssemble(this.task)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    batchTaskAssemble(taskFormData) {
      this.loading = true
      batchTaskAssemble({
        taskIds: this.ids,
        userId: taskFormData.user,
      })
        .then((res) => {
          this.loading = false
          this.$message.success('操作成功')
          this.$emit('confirm')
        })
        .catch(
          // 工序失败原因
          (reason) => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
  },
}
</script>

<style scoped>
</style>
