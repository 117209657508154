<template>
  <div class="app-container">
    <a-row>
      <a-col :span="2">
        <el-button class="header-button" type="primary" icon="el-icon-circle-plus-outline" @click="handleAddRole">新增角色</el-button>
      </a-col>
    </a-row>
    <a-table
      row-key="id"
      bordered
      class="roles-table"
      :columns="roleColumns"
      :data-source="roleList"
      :loading="loading"
      :pagination="pagination"
      :scroll="{y: 590 }"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>
      <span slot="status" slot-scope="text">
        <a-tag v-if="text===1" color="green">正常</a-tag>
        <a-tag v-if="text===2" color="red">已删除</a-tag>
      </span>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operate" slot-scope="text, column" class="table-role">
        <a-dropdown v-if="column.status!==2">
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleUpdateRole(column)">更新角色</a-menu-item>
            <a-menu-item @click="handleDeleteRole(column)">删除角色</a-menu-item>
            <a-menu-item @click="handleRoleResourceSelect(column)">权限设置</a-menu-item>
          </a-menu>
          <a class="role-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <RoleEdit
      v-if="editRoleModalVisible"
      :role-form="currentRole"
      :modal-title="currentEditType"
      @cancel="handleRoleModalCanceled"
      @confirm="handleRoleModalConfirmed"
    />
    <RoleResourceSelect
      v-if="editRoleResourceSelectVisible"
      :user-id="currentRole.id"
      @cancel="handleResourceSelectModalCanceled"
      @confirm="handleResourceSelectModalConfirm"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { CONSTANTS } from '@/utils/constants'
import { message } from 'ant-design-vue'
import { roleColumns } from './dataLogic'
import RoleEdit from '@/components/RoleEdit'
import RoleResourceSelect from '@/components/RoleResourceSelect'
import { getRoles, deleteRole } from '@/api/role'

export default {
  components: {
    RoleEdit,
    RoleResourceSelect
  },
  data() {
    return {
      loading: false,
      roleList: [],
      roleColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initRoleListQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentRoleListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentEditType: '',
      currentRole: {},
      editRoleModalVisible: false,
      editRoleResourceSelectVisible: false,
      columnSearchParams: {},
      uploadUrl: process.env.VUE_APP_BASE_API
    }
  },
  created() {
    this.getRoles(this.initRoleListQueryParams)
  },
  methods: {
    getRoles(data) {
      this.loading = true
      getRoles(data)
        .then(res => {
          this.roleList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getRolesByParams() {
      const cQueryParams = deepClone(this.currentRoleListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getRoles(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['status']
        }
      }
      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentRoleListQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentRoleListQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentRoleListQueryParams.pageNum = pagination.current
      this.currentRoleListQueryParams.pageSize = pagination.pageSize
      this.getRolesByParams()
    },
    // 删除角色
    handleDeleteRole(column) {
      const realName = column.realName ? column.realName : ''
      this.$confirm({
        content: '确认删除角色' + realName + '?',
        onOk: () => {
          deleteRole(column.id).then(() => {
            this.$message.success('删除角色成功！')
            this.getRolesByParams()
          })
        }
      })
    },
    handleAddRole() {
      this.currentEditType = '新增'
      this.currentRole = {}
      this.editRoleModalVisible = true
    },
    // 更新角色
    handleUpdateRole(column) {
      this.currentEditType = '更新'
      this.currentRole = deepClone(column)
      this.editRoleModalVisible = true
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleRoleModalConfirmed() {
      this.editRoleModalVisible = false
      this.getRoles(this.currentRoleListQueryParams)
    },
    handleRoleModalCanceled() {
      this.editRoleModalVisible = false
    },
    handleRoleResourceSelect(column) {
      this.currentRole = deepClone(column)
      this.editRoleResourceSelectVisible = true
    },
    handleResourceSelectModalCanceled() {
      this.editRoleResourceSelectVisible = false
    },
    handleResourceSelectModalConfirm() {
      this.editRoleResourceSelectVisible = false
      this.getRoles(this.currentRoleListQueryParams)
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 30px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .role-btn {
    cursor: pointer;
    color: #409eff;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
}
</style>
