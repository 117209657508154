var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _vm._m(0),
    _c("div", { staticClass: "content-footer" }, [
      _vm._v("说明: " + _vm._s(_vm.info.remark)),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-header" }, [
      _c("div", { attrs: { id: "container" } }),
      _c("div", { staticClass: "container-wrap" }, [
        _c("div", { staticClass: "container-title" }, [_vm._v("原辅材料明细")]),
        _c("div", { attrs: { id: "materialContainer" } }),
      ]),
      _c("div", { staticClass: "container-wrap" }, [
        _c("div", { staticClass: "container-title" }, [_vm._v("外协明细")]),
        _c("div", { attrs: { id: "outProcessingContainer" } }),
      ]),
      _c("div", { staticClass: "container-wrap" }, [
        _c("div", { staticClass: "container-title" }, [_vm._v("外购明细")]),
        _c("div", { attrs: { id: "outSourceingContainer" } }),
      ]),
      _c("div", { staticClass: "container-wrap" }, [
        _c("div", { staticClass: "container-title" }, [
          _vm._v("直接工资明细费"),
        ]),
        _c("div", { attrs: { id: "processingContainer" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }