var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.title,
        "mask-closable": false,
        width: "1200px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("a-table", {
            key: _vm.tableKey,
            attrs: {
              "row-key": "taskId",
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              },
              bordered: "",
              scroll: { x: 800, y: 500 },
              columns: _vm.columns,
              "data-source": _vm.list,
              loading: _vm.loading,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "filterDropdown",
                fn: function (ref) {
                  var setSelectedKeys = ref.setSelectedKeys
                  var selectedKeys = ref.selectedKeys
                  var confirm = ref.confirm
                  var clearFilters = ref.clearFilters
                  var column = ref.column
                  return _c(
                    "div",
                    { staticStyle: { padding: "8px" } },
                    [
                      column.searchType === "text"
                        ? [
                            _c("a-input", {
                              directives: [
                                {
                                  name: "ant-ref",
                                  rawName: "v-ant-ref",
                                  value: function (c) {
                                    return (_vm.searchInput = c)
                                  },
                                  expression: "c => (searchInput = c)",
                                },
                              ],
                              staticStyle: {
                                width: "188px",
                                "margin-bottom": "8px",
                                display: "block",
                              },
                              attrs: {
                                placeholder: "搜索 " + column.title,
                                value: selectedKeys[0],
                              },
                              on: {
                                change: function (e) {
                                  return setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  )
                                },
                                pressEnter: function () {
                                  return _vm.handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.dataIndex
                                  )
                                },
                              },
                            }),
                          ]
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "90px", "margin-right": "8px" },
                          attrs: {
                            type: "primary",
                            icon: "search",
                            size: "small",
                          },
                          on: {
                            click: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.dataIndex
                              )
                            },
                          },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "90px" },
                          attrs: { size: "small" },
                          on: {
                            click: function () {
                              return _vm.handleReset(
                                selectedKeys,
                                clearFilters,
                                column.dataIndex
                              )
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    2
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }