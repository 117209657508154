<template>
  <div class="app-container">
    <a-tabs :active-key="currentActiveKey" type="card" style="margin-top: 20px;" @change="handleTabChange">
      <a-tab-pane key="L" tab="L计划" />
      <a-tab-pane key="D" tab="D计划" />
      <a-tab-pane key="G" tab="G计划" />
    </a-tabs>
    <a-table
      :key="tableKey"
      row-key="id"
      bordered
      class="dashboard-table"
      :columns="taskColumns"
      :data-source="taskList"
      :loading="loading"
      :pagination="pagination"
      :scroll="{ x: 5000, y: 670 }"
      @change="handleTableChange"
    >
      <a-tag slot="codeTag" slot-scope="text, column" :color="column.taskStatus===4?'red':column.taskStatus===5?'orange':''">{{ text }}</a-tag>
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-else-if="column.searchType === 'numberInput'">
          <a-input-number
            :value="selectedKeys[0]"
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :min="0"
            :max="999999"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e ? [e] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'number'">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px;"
            :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
            @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <span slot="emgType" slot-scope="text">
        <a-tag v-if="text === 'NML'" color="volcano">普通</a-tag>
        <a-tag v-if="text === 'MFN'" color="cyan">必完件</a-tag>
        <a-tag v-if="text === 'UGC'" color="green">紧急件</a-tag>
        <a-tag v-if="text === 'IUG'" color="purple">插入急件</a-tag>
        <a-tag v-if="text === 'TUG'" color="blue">转入急件</a-tag>
      </span>
      <div slot="status" slot-scope="text">
        <template v-if="text === 0">
          <a-badge status="default" />准备中
        </template>
        <template v-if="text === 1">
          <a-badge status="default" />未开始
        </template>
        <template v-if="text === 2">
          <a-badge status="processing" />进行中
        </template>
        <template v-if="text === 3">
          <a-badge status="success" />已完成
        </template>
        <template v-if="text === 4">
          <a-badge status="error" />超期进行中
        </template>
        <template v-if="text === 5">
          <a-badge status="warning" />即将超期
        </template>
        <template v-if="text === 8">
          <a-badge status="error" />已取消
        </template>
        <template v-if="text === 9">
          <a-badge status="warning" />暂停中
        </template>
        <template v-if="text === 10">
          <a-badge status="error" />异常终止
        </template>
      </div>
      <div slot="taskType" slot-scope="text">
        <template v-if="text === 0">
          <span>普通任务</span>
        </template>
        <template v-if="text === 1">
          <span>返修任务</span>
        </template>
        <template v-if="text === 2">
          <span>生产补投任务</span>
        </template>
        <template v-if="text === 3">
          <span>退库补投任务</span>
        </template>
        <template v-if="text === 4">
          <span>分批任务</span>
        </template>
        <template v-if="text === 5">
          <span>其他任务</span>
        </template>
      </div>
      <div slot="isParts" slot-scope="text">
        <template v-if="text === 0">
          <a-tag color="red">否</a-tag>
        </template>
        <template v-if="text === 1">
          <a-tag color="green">是</a-tag>
        </template>
      </div>
      <div slot="fileName" slot-scope="text, column" class="file-wrapper">
        <template v-if="column.fileName">
          <div class="filename-wrapper">
            <span @click="handleDownloadFile(column.craftId, column.fileName)" class="file-link">{{ column.fileName }}</span>
          </div>
        </template>
        <template v-else>
          <span>暂无工艺文件</span>
        </template>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <el-link slot="standardProcess" slot-scope="text, column" type="primary" @click="handleShowStandardProcess(column)">点击查看</el-link>
      <div slot="process" slot-scope="text, column" class="table-process">
        <template v-if="column.actualProcessRoute.length > 0">
          <a-steps :current="column.actualProcessRoute.length-1" status="finish" size="small">
            <a-step
              v-for="process in column.actualProcessRoute"
              :key="process.id"
              :title="process.operationName"
              :sub-title="process.realName"
            >
              <span slot="description" class="process-desc">
                <p v-if="process.recordStartTime" class="description-font">
                  <b>转入时间：</b>
                  {{ process.recordStartTime }}
                </p>
                <p class="description-font">
                  <b>转出时间：</b>
                  {{ process.generateTime }}
                </p>
                <p v-if="process.duration!==null" class="description-font">
                  <b>停留时间：</b>
                  {{ process.duration }}小时
                </p>
                <p v-if="process.recordType===1">
                  <b>流转数：</b>
                  {{ process.checkCount }}
                </p>
                <p v-if="process.recordType===2" class="red-font">
                  <b>报废数：</b>
                  {{ process.abandonCount }}
                </p>
                <p v-if="process.recordType===2">
                  <b>返工数：</b>
                  {{ process.repairedCount }}
                </p>
                <p v-if="process.recordType===2">
                  <b>超差数：</b>
                  {{ process.outOfToleranceCount }}
                </p>
              </span>
            </a-step>
            <a-step
              v-if="column.nextOperation&&column.nextOperation.operationType!==null"
              :status="column.nextOperation.operationType===-1?'error':(column.nextOperation.operationType===0?'finish':'process')"
              :title="column.nextOperation.operationName"
            >
              <a-icon v-if="column.nextOperation.operationType===0||column.nextOperation.operationType===-1" slot="icon" type="stop" />
              <a-icon v-else-if="column.nextOperation.operationType===-2" slot="icon" type="pause-circle" />
              <a-icon v-else slot="icon" type="loading" />
              <span slot="description" class="process-desc">
                <p v-if="column.nextOperation.operationType>0&&column.nextOperation.verifyProcessHour">
                  <b>核定加工工时：</b>
                  {{ column.nextOperation.verifyProcessHour }}
                </p>
                <p v-if="column.nextOperation.operationType>0&&column.nextOperation.verifyTransferOutTime">
                  <b>核定转出日期：</b>
                  {{ column.nextOperation.verifyTransferOutTime }}
                </p>
              </span>
            </a-step>
          </a-steps>
        </template>
        <template v-else>
          <a-steps :current="0" status="process" size="small">
            <a-step title="下料" description="等待下料">
              <a-icon slot="icon" type="loading" />
            </a-step>
          </a-steps>
        </template>
      </div>
    </a-table>
    <ProcessRouteCard
      v-if="routeModalVisible"
      :modal-title="currentTaskCode"
      :select-visible="processRouteSelectVisiable"
      :record="currentProcessRoute"
      :craft-data="craftCardData"
      @cancel="handleRouteModalCanceled"
      @confirm="handleRouteModalConfirmed"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import * as R from 'ramda'
import { downloadItem, handleDownLoad } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { taskColumns } from './dataLogic'
import { getDashboardTasks } from '@/api/dashboard'
import ProcessRouteCard from '@/components/ProcessRouteCard'
import { getCraftCardVersionMessageByTaskId } from '@/api/craft'

export default {
  components: {
    ProcessRouteCard
  },
  data() {
    return {
      tableKey: 0,
      loading: false,
      taskList: [],
      taskColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      baseURL: process.env.VUE_APP_BASE_API,
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initTaskListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        'product.type': 'eq:L'
      },
      currentTaskListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
        'product.type': 'eq:L'
      },
      currentEditType: '更新',
      currentTask: {},
      currentActiveKey: 'L',
      visible: false,
      columnSearchParams: {},
      currentTaskCode: '',
      currentProcessRoute: [],
      craftCardData: [],
      processRouteSelectVisiable: true,
      routeModalVisible: false
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  created() {
    if (this.$route.query.orderId) {
      this.initTaskListQueryParams['product_order.id'] =
        'eq:' + this.$route.query.orderId
    }
    if (this.$route.query.tabType) {
      this.currentActiveKey = this.$route.query.tabType
      this.initTaskListQueryParams['product.type'] = this.$route.query.tabType
    }
    if (this.$route.query.planCode) {
      this.initTaskListQueryParams['plan.code'] =
        'like:' + this.$route.query.planCode.substring(0, 8)
    }

    if (this.$route.query.planId) {
      this.initTaskListQueryParams['plan.parentPlanId'] =
        'eq:' + this.$route.query.planId
      this.initTaskListQueryParams['task.status'] = 'in:2,4,5,9'
    }
    this.getDashboardTasksData(this.initTaskListQueryParams)
  },
  methods: {
    handleTabChange(activeKey) {
      this.initTaskListQueryParams['product.type'] = 'eq:' + activeKey
      this.currentTaskListQueryParams = R.clone(this.initTaskListQueryParams)
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getDashboardTasksByParams(this.initTaskListQueryParams)
      this.currentActiveKey = activeKey
      this.tableKey += 1
    },
    getDashboardTasksData(data) {
      this.loading = true
      getDashboardTasks(data)
        .then(res => {
          this.taskList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
          this.searchLoading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getDashboardTasksByParams() {
      const cQueryParams = deepClone(this.currentTaskListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getDashboardTasksData(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)

      if (filtersKeys.includes('taskType')) {
        delete this.columnSearchParams['taskType']
        if (filters['taskType'].length > 0) {
          this.columnSearchParams['task.type'] = `in:${filters[
            'taskType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.type']
        }
      }
      if (filtersKeys.includes('emgType')) {
        delete this.columnSearchParams['emgType']
        if (filters['emgType'].length > 0) {
          this.columnSearchParams['emgType'] = `in:${filters[
            'emgType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['emgType']
        }
      }
      if (filtersKeys.includes('code')) {
        delete this.columnSearchParams['code']
        if (filters['code'].length > 0) {
          this.columnSearchParams['task.code'] = `like:${filters[
            'code'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.code']
        }
      }

      if (filtersKeys.includes('task.status')) {
        delete this.columnSearchParams['task.status']
        if (filters['task.status'].length > 0) {
          this.columnSearchParams['task.status'] = `in:${filters[
            'task.status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['task.status']
        }
      }
      if (filtersKeys.includes('plan.isParts')) {
        delete this.columnSearchParams['plan.isParts']
        if (filters['plan.isParts'].length > 0) {
          this.columnSearchParams['plan.isParts'] = `in:${filters[
            'plan.isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['plan.isParts']
        }
      }

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        if (sorter.columnKey === 'code') {
          sorter.columnKey = 'task.code'
        }
        this.currentTaskListQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentTaskListQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentTaskListQueryParams.pageNum = pagination.current
      this.currentTaskListQueryParams.pageSize = pagination.pageSize
      this.getDashboardTasksByParams()
    },
    // 删除任务
    handleDeleteTask(column) {
      const taskCode = column.code ? column.code : ''
      this.$confirm({
        content: '确认删除任务' + taskCode + '?',
        onOk: () => {
          deleteTask(column.id).then(res => {
            if (res) {
              this.$message.success('删除任务成功！')
              this.getDashboardTasksByParams()
            }
          })
        }
      })
    },
    // 更新任务
    handleUpdateTask(column) {
      this.visible = true
      this.currentTask = deepClone(column)
    },
    handleEditCancel() {
      this.visible = false
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim && selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          if (dataIndex === 'stayTime') {
            const minTime = this.$moment()
              .subtract(selectedKeys[1], 'hours')
              .format('YYYY-MM-DD HH:mm:ss')
            const maxTime = this.$moment()
              .subtract(selectedKeys[0], 'hours')
              .format('YYYY-MM-DD HH:mm:ss')
            this.columnSearchParams['latestOperationTime'] =
              'btn:' + minTime + ',' + maxTime
          } else {
            this.columnSearchParams[dataIndex] =
              'btn:' + selectedKeys.toString()
          }
        }
      }
    },
    onSearch(value) {
      this.searchLoading = true
      if (value && R.trim(value) !== '') {
        this.currentTaskListQueryParams['task.code'] = 'like:' + R.trim(value)
        this.getDashboardTasksData(this.currentTaskListQueryParams)
      } else {
        this.currentTaskListQueryParams['task.code'] = null
        this.getDashboardTasksData(this.currentTaskListQueryParams)
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      if (dataIndex === 'stayTime') {
        this.columnSearchParams['latestOperationTime'] = null
      }
      this.columnSearchParams[dataIndex] = null
    },
    getExportIdsFromSelection() {
      this.exportIds = this.selectedRowKeys
    },
    getExportIdsFromPage() {
      this.exportIds = this.taskList.map(item => {
        return item.id
      })
    },
    handleExportTaskCommand(command) {
      switch (command) {
        case 'SELECTED':
          if (this.selectedRowKeys.length === 0) {
            this.$message.warning('选择项不能为空！')
            break
          }
          this.getExportIdsFromSelection()
          exportTasks({
            ids: this.exportIds.toString()
          }).then(res => {
            this.downloadTask(res)
          })
          break
        case 'PAGE':
          if (this.taskList.length === 0) {
            this.$message.warning('导出数据不能为！')
            break
          }
          this.getExportIdsFromPage()
          exportTasks({
            ids: this.exportIds.toString()
          }).then(res => {
            this.downloadTask(res)
          })
          break
        case 'FILTERED':
          exportTasks({
            hasConditions: true
          }).then(res => {
            this.downloadTask(res)
          })
          break
        case 'ALL': {
          exportTasks().then(res => {
            this.downloadTask(res)
          })
          break
        }
        default:
          break
      }
    },
    downloadTask(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.TASK_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    async handleShowStandardProcess(column) {
      if (column.id === null) {
        this.$message.error('当前任务工艺不存在!')
        return
      }
      await this.getCraftCardData(column.id)
      if (this.craftCardData[0]) {
        this.currentProcessRoute = this.craftCardData[0].processRoute
      } else {
        this.currentProcessRoute = []
      }
      this.processRouteSelectVisiable = true
      this.routeModalVisible = true
    },
    getCraftCardData(id) {
      this.loading = true
      return new Promise((resolve, reject) => {
        getCraftCardVersionMessageByTaskId(id).then(res => {
          this.craftCardData = res.data
          this.craftCardData.map(card => {
            const processRoute = []
            if (card.operationCardBodyDTO.craftProcessRouteListDTO.length) {
              card.operationCardBodyDTO.craftProcessRouteListDTO.map(val => {
                val.processRoute.map(route => {
                  processRoute.push(route)
                })
              })
            }
            card.processRoute = processRoute
          })
          this.loading = false
          resolve()
        })
      })
    },
    handleRouteModalConfirmed() {
      this.routeModalVisible = false
    },
    handleRouteModalCanceled() {
      this.routeModalVisible = false
    },
    async handleDownloadFile(id, fileName) {
      this.loading = true
      let url = `/crafts/${id}/file`
      await handleDownLoad(url, '', fileName)
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 0 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .operation-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-task {
    float: right;
    top: -50px;
    right: 20px;
  }

  .input-search {
    padding: 20px 50px;
  }

  .table-process {
    float: left;
    width: auto;
  }

  .process-desc p {
    margin: 0;
  }

  .description-font {
    color: rgb(31, 29, 29);
  }

  .red-font {
    color: red;
  }

  .file-link {
    color: #409eff;
    cursor: pointer;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  /deep/ .ant-steps-item-description {
    max-width: 250px;
  }
}
</style>
