import request from '@/utils/api.request'

export function getInquiryList(data) {
  return request({
    url: `/inquiries`,
    method: 'get',
    params: data,
  })
}

export function addInquiryList(data) {
  return request({
    url: `/inquiries`,
    method: 'post',
    data,
  })
}

export function updateInquiryList(code, data) {
  return request({
    url: `/inquiries/${code}`,
    method: 'put',
    data,
  })
}

export function addInquiryListProduct(inquiryCode, data) {
  return request({
    url: `/inquiries/${inquiryCode}/products`,
    method: 'post',
    data,
  })
}

export function updateInquiryListProduct(inquiryCode, sequence, data) {
  return request({
    url: `/inquiries/${inquiryCode}/products/${sequence}`,
    method: 'put',
    data,
  })
}

export function checkProduct(data) {
  return request({
    url: `/inquiries/product-checks`,
    method: 'post',
    data,
  })
}

/**
 * 开始核价
 */

export function startCostAccounting(data) {
  return request({
    url: `/inquiries/cost-account-receipts`,
    method: 'post',
    data,
  })
}

/**
 * 分页获取询价单产品项明细信息
 * @param {*} data
 * @param {*} inquiryCode
 * @returns
 */
export function getProductDetailList(inquiryCode, data) {
  return request({
    url: `/inquiries/${inquiryCode}/products`,
    method: 'get',
    params: data,
  })
}

export function getInquiryListImportTemplate() {
  return request({
    url: `/inquiries/import-template`,
    method: 'get',
    responseType: 'blob',
  })
}

/**
 * 询价单记录导出
 * @param {*} data
 */
export function exportInquiryList(data) {
  return request({
    url: `/inquiries/exports`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}

/**
 *
 * @param {*} inquiryCode
 * @param {*} data
 * @returns
 */
export function cancelInquiryItem(data) {
  return request({
    url: `/inquiries/cancel-status`,
    method: 'put',
    data,
  })
}

/**
 *
 * @param {*} inquiryCode
 * @param {*} data
 * @returns
 */
export function cancelInquiryItemProduct(inquiryCode, data) {
  return request({
    url: `/inquiries/${inquiryCode}/products/cancel-status`,
    method: 'put',
    data,
  })
}

/**
 *
 * @param {*} inquiryCode
 * @param {*} data
 * @returns
 */
export function deleteInquiryItem(code) {
  return request({
    url: `/inquiries/${code}`,
    method: 'delete',
  })
}

/**
 *
 * @param {*} inquiryCode
 * @param {*} data
 * @returns
 */
export function deleteInquiryProduct(code, data) {
  return request({
    url: `/inquiries/${code}/products`,
    method: 'delete',
    data,
  })
}
