<template>
  <a-modal class="modal-container" visible="true" :title='title+"角色"' :maskClosable="false" @cancel="handleCancel"
    width="600px">
    <template slot="footer">
      <a-button @click="handleReset">
        重置
      </a-button>
      <a-button @click="handleCancel">
        取消
      </a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">
        确定
      </a-button>
    </template>
    <a-form-model ref="ruleForm" :model="role" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="角色编号" prop="code">
        <a-input maxLength=20 v-model.trim="role.code" placeholder="请输入角色编号" />
      </a-form-model-item>
      <a-form-model-item label="角色名称" prop="name">
        <a-input maxLength=20 v-model.trim="role.name" placeholder="请输入角色名称" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
  import {
    updateRole,
    addRole
  } from '@/api/role'
  import * as R from 'ramda'
  export default {
    props: {
      modalTitle: String,
      roleForm: Object,
    },
    data() {
      return {
        title: this.modalTitle,
        labelCol: {
          span: 5
        },
        wrapperCol: {
          span: 15,
          offset: 1
        },
        loading: false,
        role: {
          id: this.roleForm.id,
          status: this.roleForm.status,
          code: this.roleForm.code,
          name: this.roleForm.name,
        },
        rules: {
          'code': [{
            required: true,
            message: '请输入角色编号',
            trigger: 'blur'
          }],
          'name': [{
            required: true,
            message: '请输入角色名称',
            trigger: 'blur'
          }],
        },
      }
    },
    computed: {
      // 计算属性的 getter
      isAdd: function () {
        return this.modalTitle === '新增'
      },
      isUpdate: function () {
        return this.modalTitle === '更新'
      },
    },
    methods: {
      handleReset() {
        this.$refs.ruleForm.resetFields();
      },
      handleCancel() {
        this.$emit('cancel');
      },
      handleConfirm() {
        console.log(this.role);
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.isAdd) {
              this.addRole(this.role);
            } else if (this.isUpdate) {
              this.updateRole(this.role);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      updateRole(roleFormData) {
        this.loading = true;
        updateRole(roleFormData.id, roleFormData).then(res => {
          if (res.data.id) {
            const successMessage = '更新角色成功！';
            const failMessage = '更新角色失败，请重试！';
            this.$emit('confirm');
            this.$message.success(successMessage);
          } else {
            this.$message.error(failMessage)
          }
        }).catch(
          // 角色失败原因
          (reason) => {
            this.loading = false;
            console.log('处理失败的 promise (' + reason + ')');
          });
      },
      addRole(roleFormData) {
        this.loading = true;
        let submitData = R.clone(roleFormData);
        submitData.id = null;
        addRole(submitData).then(res => {
          if (res.data.id) {
            this.$message.success('新增角色成功')
            this.$emit('confirm');
          } else {
            this.$message.error('新增角色失败，请重试')
          }
        }).catch(
          // 角色失败原因
          (reason) => {
            this.loading = false;
            console.log('处理失败的 promise (' + reason + ')');
          });
      },
    }
  }

</script>

<style scoped>


</style>
