<template>
  <div class="card-container">
    <div id="craft-card-print">
      <div style="page-break-after:always">
        <div class="title">
          <div class="left">下达任务交接表</div>
          <div class="right">{{ time }}</div>
        </div>
        <a-table :columns="columns" :data-source="taskDatas" bordered :pagination="false" />
      </div>
    </div>
    <a-button v-print="'craft-card-print'" type="primary" block>打印</a-button>
  </div>
</template>
<script>
import { getPlanCardInfoByIds } from '@/api/plan'
import Moment from 'moment'
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
  },
  {
    title: '任务号',
    dataIndex: 'code',
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
  },
  {
    title: '产品图号',
    dataIndex: 'drawingNum',
  },
  {
    title: '产品规格',
    dataIndex: 'specification',
  },
  {
    title: '单位',
    dataIndex: 'unit',
  },
  {
    title: '合同数量',
    dataIndex: 'amount',
  },
  {
    title: '下达日期',
    dataIndex: 'commandTime',
  },
  {
    title: '交货日期',
    dataIndex: 'deadline',
  },
  {
    title: '客户名称',
    dataIndex: 'client',
  },
  {
    title: '接收人签字',
  },
  {
    title: '需求',
  },
  {
    title: '周转天数',
    dataIndex: 'daysOfTurnover',
  },
  {
    title: '材料',
    dataIndex: 'materialCategories',
  },
  {
    title: '备注',
  },
]
export default {
  data() {
    return {
      columns,
      taskCode: null,
      taskDatas: [],
      time: '',
    }
  },
  created() {
    const taskIds = this.$route.query.ids
    this.time = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    getPlanCardInfoByIds({
      taskIds: taskIds,
    }).then((res) => {
      if (res.data) {
        res.data.map((val, index) => {
          val.index = index + 1
        })
        this.taskDatas = res.data
      } else {
        this.$message.error('获取计划交接表数据失败，请重试！')
      }
    })
  },
}
</script>
<style scoped lang="scss">
.card-container {
  width: 70%;
  margin: auto;
}

.title {
  height: 50px;
  display: flex;
  font-size: 20px;
  line-height: 50px;
  margin-bottom: 2px;
  border: 1px solid black;
  .left {
    background-color: #fafafa;
    border-right: 1px solid black;
    flex: 4.3;
    text-align: center;
  }
  .right {
    flex: 1;
    text-align: center;
  }
}
.card-title >>> .ant-descriptions-item-label {
  font-size: 20px;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
  border: 1px solid black;
  text-align: center;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
  border: 1px solid black;
  text-align: center;
  font-weight: bold;
}

/deep/ .ant-table-thead th {
  border: 1px solid black;
  padding: 8px 8px;
  text-align: center;
}

/deep/ .ant-table-tbody td {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 8px 8px;
  text-align: center;
}

/deep/ .ant-table-content {
  border-right: 1px solid black;
}

.footer-form {
  float: right;
  margin-top: 15px;
  margin-bottom: 20px;
}

.bottom-line {
  width: 100px;
  height: 20px;
  margin-right: 10px;

  display: inline-block;
  border-bottom: 1px solid grey;
}
</style>
