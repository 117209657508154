export const taskColumns = [
  {
    title: '任务编码',
    dataIndex: 'code',
    key: 'task.code',
    fixed: 'left',
    align: 'center',
    searchType: 'text',
    sorter: true,
    width: 150,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '紧急类型',
    width: 150,
    dataIndex: 'emgType',
    key: 'emgType',
    align: 'center',
    // sorter: true,
    scopedSlots: {
      customRender: 'emgType',
    },
  },
  {
    title: '计划类型',
    width: 150,
    dataIndex: 'productType',
    key: 'product.type',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'productType',
    },
    filters: [
      {
        text: 'L计划',
        value: 'L',
      },
      {
        text: 'D计划',
        value: 'D',
      },
      {
        text: 'G计划',
        value: 'G',
      },
    ],
  },
  {
    title: '是否分件',
    width: 120,
    dataIndex: 'isParts',
    key: 'plan.isParts',
    align: 'center',
    searchType: 'text',
    ellipsis: true,
    scopedSlots: {
      customRender: 'isParts',
    },
    filters: [
      {
        text: '否',
        value: 0,
      },
      {
        text: '是',
        value: 1,
      },
    ],
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    width: 150,
    key: 'task.status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '进行中',
        value: 2,
      },
      {
        text: '已完成',
        value: 3,
      },
      {
        text: '超期进行中',
        value: 4,
      },
      {
        text: '即将超期',
        value: 5,
      },
      {
        text: '暂停中',
        value: 9,
      },
      {
        text: '已取消',
        value: 8,
      },
      {
        text: '异常终止',
        value: 10,
      },
    ],
  },
  {
    title: '客户名称',
    dataIndex: 'client',
    ellipsis: true,
    width: 150,
    key: 'productOrder.client',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    key: 'product.productName',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '图号',
    dataIndex: 'drawingNum',
    key: 'product.drawingNum',
    ellipsis: true,
    width: 150,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务启动时间',
    dataIndex: 'taskStartTime',
    key: 'task.createTime',
    searchType: 'date',
    align: 'center',
    ellipsis: true,
    sorter: true,
    width: 200,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '超期天数',
    dataIndex: 'overdueDay',
    key: 'overdueDay',
    width: 150,
    sorter: true,
    ellipsis: true,
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '订单编号',
    dataIndex: 'orderCode',
    key: 'orderCode',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '合同签订日期',
    dataIndex: 'signTime',
    key: 'signTime',
    width: 200,
    ellipsis: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '合同交货期',
    dataIndex: 'deadline',
    key: 'deadline',
    width: 200,
    ellipsis: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '订单数量',
    dataIndex: 'amount',
    width: 150,
    key: 'product.amount',
    searchType: 'number',
    align: 'center',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },

  {
    title: '任务截止日期',
    dataIndex: 'taskDeliveryTime',
    key: 'task.taskDeliveryTime',
    searchType: 'date',
    width: 150,
    align: 'center',
    sorter: true,
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '工艺完成时间',
    dataIndex: 'craftFinishTime',
    key: 'craft.finishTime',
    searchType: 'date',
    align: 'center',
    ellipsis: true,
    sorter: true,
    width: 200,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '计划交货期',
    dataIndex: 'deliverTime',
    key: 'deliverTime',
    searchType: 'date',
    align: 'center',
    ellipsis: true,
    sorter: true,
    width: 200,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '最终约定交货期',
    dataIndex: 'finalDeliveryTime',
    key: 'finalDeliveryTime',
    searchType: 'date',
    align: 'center',
    ellipsis: true,
    sorter: true,
    width: 200,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'finalDeliveryTime',
    },
  },
  {
    title: '实发材料',
    dataIndex: 'actualUseMaterialList',
    key: 'actualUseMaterialList',
    width: 200,
    align: 'center',
    scopedSlots: {
      customRender: 'actualUseMaterialList',
    },
  },
  {
    title: '当前位置',
    dataIndex: 'latestOperationName',
    key: 'latestOperationName',
    width: 150,
    ellipsis: true,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '停留时间(小时)',
    dataIndex: 'stayTime',
    key: 'stayTime',
    width: 150,
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '入库日期',
    dataIndex: 'putInTime',
    key: 'in_out_record.putInTime',
    width: 150,
    searchType: 'date',
    align: 'center',
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '出库日期',
    dataIndex: 'takeOutTime',
    key: 'in_out_record.takeOutTime',
    width: 150,
    searchType: 'date',
    align: 'center',
    sorter: true,
    ellipsis: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '待加工工序',
    key: 'process',
    width: 8000,
    scopedSlots: {
      customRender: 'process',
    },
  },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    align: 'center',
    width: 80,
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

export const inventoryRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record) => ({
    props: {
      disabled: record.putInNumber,
    },
  }),
}
