<template>
  <a-modal class="modal-container" :visible="true" title="新增合格证" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="检验员" prop="checkerId">
        <a-select
          v-model="form.checkerId"
          show-search
          placeholder="请选择检验员"
          style="width: 300px"
          option-filter-prop="children"
          :filter-option="filterUser"
          @select="handleUserSelected"
        >
          <a-select-option v-for="user in currentUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="结论" prop="checkResult" placeholder="请选择结论">
        <a-select v-model="form.checkResult" style="width: 300px">
          <a-select-option value="合格">合格</a-select-option>
          <a-select-option value="不合格">不合格</a-select-option>
        </a-select>
        <!-- <a-input v-model.trim="form.checkResult" max-length="20" placeholder="请输入结论" /> -->
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { addCertificatesByTaskCode } from '@/api/task'
import { getAllUsers } from '@/api/user'
import * as R from 'ramda'

export default {
  props: {
    id: Number | String
  },
  data() {
    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      form: {
        checkerId: '',
        checkResult: ''
      },
      rules: {
        checkerId: [
          {
            required: true,
            message: '请输入检验员',
            trigger: 'blur'
          }
        ],
        checkResult: [
          {
            required: true,
            message: '请输入结论',
            trigger: 'blur'
          }
        ]
      },
      currentUserList: [],
      userQueryParams: {}
    }
  },
  mounted() {
    this.getAllUsers()
  },
  methods: {
    getAllUsers() {
      getAllUsers(this.userQueryParams).then(res => {
        if (res.data && res.data.accountList) {
          if (R.isEmpty(this.userQueryParams)) {
            this.allUserList = R.clone(res.data.accountList)
          }
          this.currentUserList = R.clone(res.data.accountList)
        }
      })
    },
    handleUserSelected(value) {
      this.getAllUsers()
    },
    filterUser(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          addCertificatesByTaskCode(this.id, this.form).then(res => {
            this.$emit('confirm')
            const routeData = this.$router.resolve({
              name: 'certificate',
              query: {
                id: this.id
              }
            })
            window.open(routeData.href, '_blank')
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
