var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-tabs",
        {
          staticClass: "tab",
          on: { change: _vm.getStatisticsDetail },
          model: {
            value: _vm.typeTab,
            callback: function ($$v) {
              _vm.typeTab = $$v
            },
            expression: "typeTab",
          },
        },
        [
          _c("a-tab-pane", { key: "DN", attrs: { tab: "D目标明细" } }),
          _c("a-tab-pane", {
            key: "DE",
            attrs: { tab: "插入急件D目标明细", "force-render": "" },
          }),
          _c("a-tab-pane", { key: "LN", attrs: { tab: "L目标明细" } }),
          _c("a-tab-pane", { key: "LE", attrs: { tab: "插入急件L目标明细" } }),
          _c("a-tab-pane", { key: "GN", attrs: { tab: "G目标明细" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("span", { staticClass: "title" }, [
            _vm._v(
              _vm._s(_vm.targetName) +
                _vm._s(_vm.$route.query.name) +
                _vm._s(_vm.title) +
                "目标明细"
            ),
          ]),
          _c(
            "a-radio-group",
            {
              staticClass: "type",
              on: { change: _vm.handleChangeStatistics },
              model: {
                value: _vm.targetTab,
                callback: function ($$v) {
                  _vm.targetTab = $$v
                },
                expression: "targetTab",
              },
            },
            [
              _c("a-radio-button", { attrs: { value: "task" } }, [
                _vm._v("项数"),
              ]),
              _c("a-radio-button", { attrs: { value: "product" } }, [
                _vm._v("数量"),
              ]),
              _vm.targetType !== "day"
                ? _c("a-radio-button", { attrs: { value: "amount" } }, [
                    _vm._v("金额"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "statistics" }, [
        ["DN", "DE", "LN", "LE"].indexOf(_vm.typeTab) !== -1
          ? _c("div", { staticClass: "left" }, [
              _c("p", { staticClass: "title" }, [_vm._v("产品类型分析")]),
              _c("div", { attrs: { id: "bar" } }),
              _vm.taskLoading
                ? _c(
                    "div",
                    { staticClass: "page-loading" },
                    [
                      _c("a-icon", {
                        staticStyle: { color: "black" },
                        attrs: { type: "loading" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _c("div", { attrs: { id: "bar" } }),
        ["DN", "LN", "GN"].indexOf(_vm.typeTab) !== -1
          ? _c("div", { staticClass: "right" }, [
              _c("p", { staticClass: "title" }, [_vm._v("任务分析")]),
              _vm.renderInfo.taskTypeStatisticsList &&
              _vm.renderInfo.taskTypeStatisticsList.length
                ? _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(
                      _vm.renderInfo.taskTypeStatisticsList,
                      function (item, index) {
                        return _c("div", { key: index, staticClass: "item" }, [
                          _c("div", {
                            staticClass: "pie",
                            attrs: { id: "task" + index },
                          }),
                          _c("div", { staticClass: "desc" }, [
                            _c(
                              "p",
                              [
                                _vm._v(" 总计: "),
                                item.data.totalCount ||
                                item.data.totalCount === 0
                                  ? [_vm._v(_vm._s(item.data.totalCount))]
                                  : [_vm._v(_vm._s(item.data.totalAmount))],
                              ],
                              2
                            ),
                            _c(
                              "p",
                              [
                                _vm._v(" 完成: "),
                                item.data.finishedCount ||
                                item.data.finishedCount === 0
                                  ? [_vm._v(_vm._s(item.data.finishedCount))]
                                  : [_vm._v(_vm._s(item.data.finishedAmount))],
                              ],
                              2
                            ),
                          ]),
                        ])
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm.taskLoading
                ? _c(
                    "div",
                    { staticClass: "page-loading" },
                    [
                      _c("a-icon", {
                        staticStyle: { color: "black" },
                        attrs: { type: "loading" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c("a-table", {
            attrs: {
              "row-key": "id",
              bordered: "",
              scroll: { x: 2000 },
              columns:
                _vm.targetType === "week"
                  ? _vm.groupColumns
                  : _vm.targetType === "day"
                  ? _vm.memberColumns
                  : _vm.detailColumns,
              "data-source": _vm.taskList,
              loading: _vm.tableLoading,
              pagination: _vm.pagination,
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "filterDropdown",
                fn: function (ref) {
                  var setSelectedKeys = ref.setSelectedKeys
                  var selectedKeys = ref.selectedKeys
                  var confirm = ref.confirm
                  var clearFilters = ref.clearFilters
                  var column = ref.column
                  return _c(
                    "div",
                    { staticStyle: { padding: "8px" } },
                    [
                      column.searchType === "text"
                        ? [
                            _c("a-input", {
                              directives: [
                                {
                                  name: "ant-ref",
                                  rawName: "v-ant-ref",
                                  value: function (c) {
                                    return (_vm.searchInput = c)
                                  },
                                  expression: "c => (searchInput = c)",
                                },
                              ],
                              staticStyle: {
                                width: "188px",
                                "margin-bottom": "8px",
                                display: "block",
                              },
                              attrs: {
                                placeholder: "搜索 " + column.title,
                                value: selectedKeys[0],
                              },
                              on: {
                                change: function (e) {
                                  return setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                  )
                                },
                                pressEnter: function () {
                                  return _vm.handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.key
                                  )
                                },
                              },
                            }),
                          ]
                        : column.searchType === "number"
                        ? _c(
                            "div",
                            [
                              _c("a-input-number", {
                                staticStyle: {
                                  width: "95px",
                                  "margin-bottom": "8px",
                                  display: "inline-block",
                                },
                                attrs: {
                                  size: "small",
                                  placeholder: "最小值",
                                  min: 0,
                                  max: 999999,
                                },
                                on: {
                                  change: function (e) {
                                    return setSelectedKeys([e, selectedKeys[1]])
                                  },
                                  pressEnter: function () {
                                    return _vm.handleSearch(
                                      selectedKeys,
                                      confirm,
                                      column.key
                                    )
                                  },
                                },
                                model: {
                                  value: selectedKeys[0],
                                  callback: function ($$v) {
                                    _vm.$set(selectedKeys, 0, $$v)
                                  },
                                  expression: "selectedKeys[0]",
                                },
                              }),
                              _c("span", [_vm._v("-")]),
                              _c("a-input-number", {
                                staticStyle: {
                                  width: "95px",
                                  "margin-bottom": "8px",
                                  display: "inline-block",
                                },
                                attrs: {
                                  size: "small",
                                  placeholder: "最大值",
                                  min: selectedKeys[0],
                                  max: 999999,
                                },
                                on: {
                                  change: function (e) {
                                    return setSelectedKeys([selectedKeys[0], e])
                                  },
                                  pressEnter: function () {
                                    return _vm.handleSearch(
                                      selectedKeys,
                                      confirm,
                                      column.key
                                    )
                                  },
                                },
                                model: {
                                  value: selectedKeys[1],
                                  callback: function ($$v) {
                                    _vm.$set(selectedKeys, 1, $$v)
                                  },
                                  expression: "selectedKeys[1]",
                                },
                              }),
                            ],
                            1
                          )
                        : column.searchType === "date"
                        ? _c(
                            "div",
                            [
                              _c("a-range-picker", {
                                staticStyle: {
                                  width: "330px",
                                  "margin-bottom": "8px",
                                },
                                attrs: {
                                  "show-time": { format: "HH:mm:ss" },
                                  size: "small",
                                  ranges: {
                                    今天: [_vm.$moment(), _vm.$moment()],
                                    本月: [
                                      _vm.$moment().startOf("month"),
                                      _vm.$moment().endOf("month"),
                                    ],
                                  },
                                },
                                on: {
                                  change: function (dates, dateStrings) {
                                    return setSelectedKeys(
                                      [].concat(dateStrings)
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "90px", "margin-right": "8px" },
                          attrs: {
                            type: "primary",
                            icon: "search",
                            size: "small",
                          },
                          on: {
                            click: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.key
                              )
                            },
                          },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "90px" },
                          attrs: { size: "small" },
                          on: {
                            click: function () {
                              return _vm.handleReset(
                                selectedKeys,
                                clearFilters,
                                column.key
                              )
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    2
                  )
                },
              },
              {
                key: "filterIcon",
                fn: function (filtered) {
                  return _c("a-icon", {
                    style: { color: filtered ? "#108ee9" : undefined },
                    attrs: { type: "search" },
                  })
                },
              },
              {
                key: "emergencyType",
                fn: function (text) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("a-tag", { attrs: { color: "volcano" } }, [
                        _vm._v(_vm._s(_vm.EmergencyType[text])),
                      ]),
                    ],
                    1
                  )
                },
              },
              {
                key: "isParts",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      text === 0
                        ? [
                            _c("a-tag", { attrs: { color: "red" } }, [
                              _vm._v("否"),
                            ]),
                          ]
                        : _vm._e(),
                      text === 1
                        ? [
                            _c("a-tag", { attrs: { color: "green" } }, [
                              _vm._v("是"),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                },
              },
              {
                key: "type",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      text === 0
                        ? [_c("span", [_vm._v("普通任务")])]
                        : _vm._e(),
                      text === 1
                        ? [_c("span", [_vm._v("返修任务")])]
                        : _vm._e(),
                      text === 2
                        ? [_c("span", [_vm._v("生产补投任务")])]
                        : _vm._e(),
                      text === 3
                        ? [_c("span", [_vm._v("协作任务")])]
                        : _vm._e(),
                      text === 4
                        ? [_c("span", [_vm._v("普通补投任务")])]
                        : _vm._e(),
                      text === 5
                        ? [_c("span", [_vm._v("其他任务")])]
                        : _vm._e(),
                    ],
                    2
                  )
                },
              },
              {
                key: "status",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      text === 0
                        ? [
                            _c("a-badge", { attrs: { status: "default" } }),
                            _vm._v("准备中 "),
                          ]
                        : _vm._e(),
                      text === 1
                        ? [
                            _c("a-badge", { attrs: { status: "default" } }),
                            _vm._v("未开始 "),
                          ]
                        : _vm._e(),
                      text === 2
                        ? [
                            _c("a-badge", { attrs: { status: "processing" } }),
                            _vm._v("进行中 "),
                          ]
                        : _vm._e(),
                      text === 3
                        ? [
                            _c("a-badge", { attrs: { status: "success" } }),
                            _vm._v("已完成 "),
                          ]
                        : _vm._e(),
                      text === 9
                        ? [
                            _c("a-badge", { attrs: { status: "warning" } }),
                            _vm._v("暂停中 "),
                          ]
                        : _vm._e(),
                      text === 8
                        ? [
                            _c("a-badge", { attrs: { status: "error" } }),
                            _vm._v("已取消 "),
                          ]
                        : _vm._e(),
                      text === 10
                        ? [
                            _c("a-badge", { attrs: { status: "error" } }),
                            _vm._v("异常终止 "),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                },
              },
              {
                key: "process",
                fn: function (text, column) {
                  return _c(
                    "div",
                    { staticClass: "table-process" },
                    [
                      column.remainProcessRoute.length > 0
                        ? [
                            _c(
                              "a-steps",
                              {
                                attrs: {
                                  current: column.remainProcessRoute.length - 1,
                                  status: "finish",
                                  size: "small",
                                },
                              },
                              _vm._l(
                                column.remainProcessRoute,
                                function (process) {
                                  return _c(
                                    "a-step",
                                    {
                                      key: process.id,
                                      attrs: { title: process.operationName },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "process-desc",
                                          attrs: { slot: "description" },
                                          slot: "description",
                                        },
                                        [
                                          process.operationType > 0 &&
                                          process.verifyProcessHour
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "description-font",
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v("加工工时："),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        process.verifyProcessHour
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          process.operationType > 0 &&
                                          process.verifyTransferOutTime
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "description-font",
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v("转出日期："),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        process.verifyTransferOutTime
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                },
              },
            ]),
          }),
          _c(
            "el-dropdown",
            {
              staticClass: "export-product",
              on: { command: _vm.handleExportProductCommand },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.exportLoading,
                    size: "medium",
                    type: "primary",
                    icon: "el-icon-download",
                  },
                },
                [_vm._v("导出任务")]
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                    _vm._v("导出当前筛选任务"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }