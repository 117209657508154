var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "top-tip" }, [
        _c(
          "div",
          { staticClass: "selected-wrapper" },
          [
            _vm.hasSelected
              ? _c("span", [
                  _vm._v(" 已选中 "),
                  _c("strong", [_vm._v(_vm._s(_vm.selectedRowKeys.length))]),
                  _vm._v(" 项计划 "),
                ])
              : _vm._e(),
            _vm.hasSelected
              ? _c(
                  "a-dropdown",
                  [
                    _c(
                      "a",
                      {
                        staticClass: "batch-operate-link",
                        on: {
                          click: function (e) {
                            return e.preventDefault()
                          },
                        },
                      },
                      [
                        _c("a-icon", { attrs: { type: "edit" } }),
                        _vm._v("批量操作 "),
                      ],
                      1
                    ),
                    _c(
                      "a-menu",
                      { attrs: { slot: "overlay" }, slot: "overlay" },
                      [
                        _c("a-menu-item", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleCreateCraftCard(
                                    _vm.selectedRowKeys
                                  )
                                },
                              },
                            },
                            [_vm._v("工艺交接表打印")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "a-tabs",
        {
          attrs: { "active-key": _vm.currentActiveKey, type: "card" },
          on: { change: _vm.handleTabChange },
        },
        [
          _c("a-tab-pane", { key: "L", attrs: { tab: "L计划" } }),
          _c("a-tab-pane", { key: "D", attrs: { tab: "D计划" } }),
          _c("a-tab-pane", { key: "G", attrs: { tab: "G计划" } }),
        ],
        1
      ),
      _c(
        "a-table",
        {
          key: _vm.tableKey,
          staticClass: "plans-table",
          attrs: {
            "row-key": "id",
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange,
            },
            columns: _vm.planColumns,
            "data-source": _vm.plans,
            loading: _vm.loading,
            "expanded-row-keys": _vm.expandedRowKeys,
            pagination: _vm.pagination,
            bordered: "",
            scroll: { x: 4000, y: 590 },
          },
          on: { change: _vm.handleTableChange, expand: _vm.handleRowExpand },
          scopedSlots: _vm._u(
            [
              {
                key: "name",
                fn: function (text, column, record, index) {
                  return column.status === 0
                    ? _c(
                        "el-link",
                        {
                          attrs: { type: "danger", title: "点击制定计划" },
                          on: {
                            click: function ($event) {
                              return _vm.showDetail(column, record, index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(text))]
                      )
                    : column.status === 1
                    ? _c(
                        "el-link",
                        {
                          attrs: { type: "info", title: "点击查看详情" },
                          on: {
                            click: function ($event) {
                              return _vm.showDetail(column, record, index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(text))]
                      )
                    : _c(
                        "el-link",
                        {
                          attrs: { type: "success", title: "点击查看详情" },
                          on: {
                            click: function ($event) {
                              return _vm.showDetail(column, record, index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(text))]
                      )
                },
              },
              {
                key: "filterDropdown",
                fn: function (ref) {
                  var setSelectedKeys = ref.setSelectedKeys
                  var selectedKeys = ref.selectedKeys
                  var confirm = ref.confirm
                  var clearFilters = ref.clearFilters
                  var column = ref.column
                  return _c(
                    "div",
                    { staticStyle: { padding: "8px" } },
                    [
                      column.searchType === "text"
                        ? _c("a-input", {
                            directives: [
                              {
                                name: "ant-ref",
                                rawName: "v-ant-ref",
                                value: function (c) {
                                  return (_vm.searchInput = c)
                                },
                                expression: "c => (searchInput = c)",
                              },
                            ],
                            staticStyle: {
                              width: "188px",
                              "margin-bottom": "8px",
                              display: "block",
                            },
                            attrs: {
                              placeholder: "搜索 " + column.title,
                              value: selectedKeys[0],
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys(
                                  e.target.value ? [e.target.value] : []
                                )
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      column.searchType === "number"
                        ? _c(
                            "div",
                            [
                              _c("a-input-number", {
                                staticStyle: {
                                  width: "95px",
                                  "margin-bottom": "8px",
                                  display: "inline-block",
                                },
                                attrs: {
                                  size: "small",
                                  placeholder: "最小值",
                                  min: 0,
                                  max: 999999,
                                },
                                on: {
                                  change: function (e) {
                                    return setSelectedKeys([e, selectedKeys[1]])
                                  },
                                  pressEnter: function () {
                                    return _vm.handleSearch(
                                      selectedKeys,
                                      confirm,
                                      column.key
                                    )
                                  },
                                },
                                model: {
                                  value: selectedKeys[0],
                                  callback: function ($$v) {
                                    _vm.$set(selectedKeys, 0, $$v)
                                  },
                                  expression: "selectedKeys[0]",
                                },
                              }),
                              _c("span", [_vm._v("-")]),
                              _c("a-input-number", {
                                staticStyle: {
                                  width: "95px",
                                  "margin-bottom": "8px",
                                  display: "inline-block",
                                },
                                attrs: {
                                  size: "small",
                                  placeholder: "最大值",
                                  min: selectedKeys[0],
                                  max: 999999,
                                },
                                on: {
                                  change: function (e) {
                                    return setSelectedKeys([selectedKeys[0], e])
                                  },
                                  pressEnter: function () {
                                    return _vm.handleSearch(
                                      selectedKeys,
                                      confirm,
                                      column.key
                                    )
                                  },
                                },
                                model: {
                                  value: selectedKeys[1],
                                  callback: function ($$v) {
                                    _vm.$set(selectedKeys, 1, $$v)
                                  },
                                  expression: "selectedKeys[1]",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      column.searchType === "date"
                        ? _c(
                            "div",
                            [
                              _c("a-range-picker", {
                                staticStyle: {
                                  width: "330px",
                                  "margin-bottom": "8px",
                                },
                                attrs: {
                                  "show-time": { format: "HH:mm:ss" },
                                  size: "small",
                                  ranges: {
                                    今天: [_vm.$moment(), _vm.$moment()],
                                    本月: [
                                      _vm.$moment().startOf("month"),
                                      _vm.$moment().endOf("month"),
                                    ],
                                  },
                                },
                                on: {
                                  change: function (dates, dateStrings) {
                                    return setSelectedKeys(
                                      [].concat(dateStrings)
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "90px", "margin-right": "8px" },
                          attrs: {
                            type: "primary",
                            icon: "search",
                            size: "small",
                          },
                          on: {
                            click: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.key
                              )
                            },
                          },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "90px" },
                          attrs: { size: "small" },
                          on: {
                            click: function () {
                              return _vm.handleReset(
                                selectedKeys,
                                clearFilters,
                                column.key
                              )
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "filterIcon",
                fn: function (filtered) {
                  return _c("a-icon", {
                    style: { color: filtered ? "#108ee9" : undefined },
                    attrs: { type: "search" },
                  })
                },
              },
              {
                key: "status",
                fn: function (text) {
                  return _c("span", {}, [
                    text === 0
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "default" } }),
                            _vm._v("未制定 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 1
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "default" } }),
                            _vm._v("工艺编制中 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 2
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "processing" } }),
                            _vm._v("进行中 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 3
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "success" } }),
                            _vm._v("已完成 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 9
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "warning" } }),
                            _vm._v("暂停中 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 8
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "error" } }),
                            _vm._v("已取消 "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                },
              },
              {
                key: "productCategory",
                fn: function (text, column) {
                  return _c("div", {}, [
                    text === "MLJ" ? _c("span", [_vm._v("单体")]) : _vm._e(),
                    text === "ZLJ"
                      ? _c("span", [_vm._v("单体轴类")])
                      : _vm._e(),
                    text === "OLJ"
                      ? _c("span", [_vm._v("单体非轴类")])
                      : _vm._e(),
                    text === "CLJ" ? _c("span", [_vm._v("组合")]) : _vm._e(),
                  ])
                },
              },
              {
                key: "operation",
                fn: function (text, column) {
                  return _c(
                    "span",
                    { staticClass: "table-operation" },
                    [
                      _c(
                        "a-dropdown",
                        { attrs: { placement: "topRight" } },
                        [
                          _c(
                            "a-menu",
                            {
                              staticClass: "ant-dropdown-link",
                              attrs: { slot: "overlay" },
                              slot: "overlay",
                            },
                            [
                              column.status === 2 || column.status === 3
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleAddTask(column.id)
                                        },
                                      },
                                    },
                                    [_vm._v("新增任务")]
                                  )
                                : _vm._e(),
                              column.status === 2
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdatePlan(column)
                                        },
                                      },
                                    },
                                    [_vm._v("更新计划")]
                                  )
                                : _vm._e(),
                              column.status === 0 ||
                              column.status === 1 ||
                              column.status === 2
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.switchPlanStatus(
                                            column,
                                            "c"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消计划")]
                                  )
                                : _vm._e(),
                              column.status === 2
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.switchPlanStatus(
                                            column,
                                            "s"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("暂停计划")]
                                  )
                                : _vm._e(),
                              column.status === 9
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.switchPlanStatus(
                                            column,
                                            "r"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("恢复计划")]
                                  )
                                : _vm._e(),
                              column.status === 8 ||
                              column.status === 0 ||
                              column.status === 1
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeletePlan(column)
                                        },
                                      },
                                    },
                                    [_vm._v("删除计划")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "a",
                            [
                              _vm._v(" 操作 "),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c("a-table", {
            attrs: {
              slot: "expandedRowRender",
              "row-key": "id",
              size: "middle",
              loading: _vm.tasksTableLoading,
              columns: _vm.taskColumns,
              "data-source": _vm.tasks,
              pagination: false,
            },
            slot: "expandedRowRender",
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function (text) {
                  return _c("span", {}, [
                    text === 0
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "default" } }),
                            _vm._v("未制定 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 1
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "default" } }),
                            _vm._v("未开始 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 2
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "processing" } }),
                            _vm._v("进行中 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 3
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "success" } }),
                            _vm._v("已完成 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 4
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "error" } }),
                            _vm._v("超期进行中 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 5
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "warning" } }),
                            _vm._v("即将超期 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 9
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "warning" } }),
                            _vm._v("暂停中 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 8
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "error" } }),
                            _vm._v("已取消 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    text === 10
                      ? _c(
                          "span",
                          [
                            _c("a-badge", { attrs: { status: "error" } }),
                            _vm._v("异常终止 "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                },
              },
              {
                key: "tags",
                fn: function (text) {
                  return _c(
                    "span",
                    {},
                    [
                      text === 0
                        ? _c("a-tag", { attrs: { color: "volcano" } }, [
                            _vm._v("普通任务"),
                          ])
                        : _vm._e(),
                      text === 1
                        ? _c("a-tag", { attrs: { color: "geekblue" } }, [
                            _vm._v("返修任务"),
                          ])
                        : _vm._e(),
                      text === 2
                        ? _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v("生产补投任务"),
                          ])
                        : _vm._e(),
                      text === 3
                        ? _c("a-tag", { attrs: { color: "grey" } }, [
                            _vm._v("协作任务"),
                          ])
                        : _vm._e(),
                      text === 4
                        ? _c("a-tag", { attrs: { color: "red" } }, [
                            _vm._v("普通补投任务"),
                          ])
                        : _vm._e(),
                      text === 5
                        ? _c("a-tag", { attrs: { color: "orange" } }, [
                            _vm._v("库存转入任务"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
              {
                key: "name",
                fn: function (text, column, record, index) {
                  return _c(
                    "el-link",
                    {
                      attrs: {
                        type: "primary",
                        title:
                          column.status === 0 ? "点击编辑任务" : "点击更新任务",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleUpdateTask(column, record, index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(text))]
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dropdown",
        {
          staticClass: "export-plan",
          on: { command: _vm.handleExportPlanCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出计划")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "SELECTED" } }, [
                _vm._v("导出当前已选计划"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "PAGE" } }, [
                _vm._v("导出当前页计划"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选计划"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "ALL" } }, [
                _vm._v("导出所有计划"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.editModalVisible
        ? _c("PlanEdit", {
            attrs: {
              "modal-title": _vm.currentEditType,
              "plan-type": _vm.currentActiveKey,
              "plan-form": _vm.currentPlan,
            },
            on: {
              cancel: _vm.handlePlanModalCanceled,
              confirm: _vm.handlePlanModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.editTaskModalVisible
        ? _c("TaskEdit", {
            attrs: {
              "modal-title": _vm.currentEditType,
              "task-form": _vm.currentTask,
            },
            on: {
              cancel: _vm.handleTaskModalCanceled,
              confirm: _vm.handleTaskModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.isInitModal
        ? _c(
            "a-modal",
            {
              attrs: {
                title: _vm.currentPlanCode + " - 计划详情",
                centered: true,
                width: 1200,
                footer: null,
              },
              on: { ok: _vm.handleOk },
              model: {
                value: _vm.planDetailModalVisible,
                callback: function ($$v) {
                  _vm.planDetailModalVisible = $$v
                },
                expression: "planDetailModalVisible",
              },
            },
            [
              _c(
                "div",
                { staticClass: "modal-div" },
                [
                  _c(
                    "a-descriptions",
                    { attrs: { bordered: "" } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: " 累计投产数量" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.totalNum))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计在产数量" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.producingNum))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: " 累计报废数量" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.totalAbandonNum))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计退货数量" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.totalReturnedNum))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "计划下达日期" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.commandTime))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "合同交货日期" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.deadline))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "单价(含税)" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.taxPrice))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计入库数量" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.totalPutInNum))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计入库金额" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.totalPutInMoney))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "库存转入数量" } },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlanDetail.inventoryTurnsIntoNum)
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "库存数量" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.inventoryNum))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "合同未交数量" } },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlanDetail.contractNoDeliveryNum)
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "报废不需补投数量" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.currentPlanDetail.abandonNotComplementNum
                            )
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "最近完成日期" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.finishTime))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "最近退货日期" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.returnedTime))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计返修数量" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.totalRepairNum))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计投产金额" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.totalMoney))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计报废投产数量" } },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlanDetail.totalAbandonProduceNum)
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "备投数量" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.prepareNum))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计在产金额" } },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlanDetail.totalProducingMoney)
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计报废金额" } },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlanDetail.totalAbandonMoney)
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计退货金额" } },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlanDetail.totalReturnedMoney)
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "实际出库金额" } },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlanDetail.actualTakeOutMoney)
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "超期天数" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.overdueDays))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "累计投产金额" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.totalMoney))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "超期违约金" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.penalty))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "设计人员" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.designer))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "客户物料代码" } },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlanDetail.clientMaterialCode)
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "客户计划编码" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.clientPlanCode))]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "客户合同编码" } },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlanDetail.clientContractCode)
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "客户采购计划编号" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.currentPlanDetail.clientPurchaseOrderCode
                            )
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "材料类别" } },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlanDetail.materialCategories)
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "负责人" } },
                        [_vm._v(_vm._s(_vm.currentPlanDetail.charger))]
                      ),
                      _c("a-descriptions-item", { attrs: { label: "备注" } }, [
                        _vm._v(_vm._s(_vm.currentPlanDetail.comment)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }