var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.hasSelected
        ? _c("div", { staticClass: "top-tip" }, [
            _c(
              "div",
              { staticClass: "selected-wrapper" },
              [
                _vm.hasSelected
                  ? _c("span", [
                      _vm._v(" 已选中 "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.selectedRowKeys.length)),
                      ]),
                      _vm._v(" 个模板 "),
                    ])
                  : _vm._e(),
                _vm.hasSelected
                  ? _c(
                      "a-dropdown",
                      [
                        _c(
                          "a",
                          {
                            staticClass: "batch-operate-link",
                            on: {
                              click: function (e) {
                                return e.preventDefault()
                              },
                            },
                          },
                          [
                            _c("a-icon", { attrs: { type: "edit" } }),
                            _vm._v("批量操作 "),
                          ],
                          1
                        ),
                        _c(
                          "a-menu",
                          { attrs: { slot: "overlay" }, slot: "overlay" },
                          [
                            _c("a-menu-item", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBatchEditType(
                                        _vm.selectedRowKeys
                                      )
                                    },
                                  },
                                },
                                [_vm._v("批量编辑模板类型")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "el-dropdown",
        {
          staticClass: "header-button",
          on: { command: _vm.handleAddTemplate },
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary", icon: "el-icon-circle-plus-outline" } },
            [_vm._v("新增模板")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "CUTTER" } }, [
                _vm._v("新增刀具模板"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "MEASURE" } }, [
                _vm._v("新增量具模板"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "OTHERS" } }, [
                _vm._v("新增其他模板"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-tabs",
        {
          attrs: { "active-key": _vm.currentActiveKey, type: "card" },
          on: { change: _vm.handleTabChange },
        },
        [
          _c("a-tab-pane", { key: "11", attrs: { tab: "刀具" } }),
          _c("a-tab-pane", { key: "12", attrs: { tab: "量具" } }),
          _c("a-tab-pane", { key: "1", attrs: { tab: "其他" } }),
        ],
        1
      ),
      _c("a-table", {
        staticClass: "templates-table",
        attrs: {
          "row-key": "templateId",
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            getCheckboxProps: _vm.templateRowSelection.getCheckboxProps,
          },
          bordered: "",
          columns: _vm.templateColumns,
          "data-source": _vm.templateList,
          loading: _vm.loading,
          pagination: _vm.pagination,
          scroll: { y: 700 },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "templateCode",
            fn: function (text, column) {
              return [
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleCheckTemplateCard(column)
                      },
                    },
                  },
                  [_vm._v(_vm._s(text))]
                ),
              ]
            },
          },
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.key
                              )
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                  column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "330px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              "show-time": { format: "HH:mm:ss" },
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "processRouteTemplate",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "file-wrapper" },
                [
                  [
                    _c(
                      "div",
                      { staticClass: "filename-wrapper" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShowProcessRoute(column)
                              },
                            },
                          },
                          [_vm._v("查看工艺路线")]
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              )
            },
          },
          {
            key: "operation",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-operation" },
                [
                  _c(
                    "a-dropdown",
                    [
                      _c(
                        "a-menu",
                        {
                          staticClass: "ant-dropdown-link",
                          attrs: { slot: "overlay" },
                          slot: "overlay",
                        },
                        [
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpdateTemplate(column)
                                },
                              },
                            },
                            [_vm._v("更新模板")]
                          ),
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleDeleteTemplate(column)
                                },
                              },
                            },
                            [_vm._v("删除模板")]
                          ),
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleEditTemplateType(column)
                                },
                              },
                            },
                            [_vm._v("编辑模板类型")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        { staticClass: "operation-btn" },
                        [
                          _vm._v(" 操作 "),
                          _c("a-icon", { attrs: { type: "down" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
      _vm.editModalVisible
        ? _c("CraftTemplateEdit", {
            attrs: {
              "modal-title": _vm.currentEditType,
              "template-from": _vm.currentTemplate,
            },
            on: {
              confirm: _vm.handleRecordModalConfirmed,
              cancel: _vm.handleRecordModalCanceled,
            },
          })
        : _vm._e(),
      _vm.routeModalVisible
        ? _c("ProcessRouteCard", {
            attrs: {
              "modal-title": _vm.currentPlanCode,
              record: _vm.currentProcessRoute,
            },
            on: {
              cancel: _vm.handleProcessRouteModalCanceled,
              confirm: _vm.handleProcessRouteModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.templateTypeEditModalVisible
        ? _c("TemplateTypeEditModal", {
            attrs: { ids: _vm.editIds, type: _vm.editType },
            on: {
              cancel: _vm.handleTemplateTypeEditModalCanceled,
              confirm: _vm.handleTemplateTypeEditModalConfirmed,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }