/**
 * 时间格式化 YYYY-MM-DD hh:mm:ss
 * @param inputTime
 * @returns {string}
 */
export function formatDateTime(inputTime) {
  var date = inputTime ? new Date(inputTime) : new Date()
  var y = date.getFullYear()
  var m = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  var d = date.getDate()
  d = d < 10 ? ('0' + d) : d
  var h = date.getHours()
  h = h < 10 ? ('0' + h) : h
  var minute = date.getMinutes()
  var second = date.getSeconds()
  minute = minute < 10 ? ('0' + minute) : minute
  second = second < 10 ? ('0' + second) : second
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
}

/**
 * 处理为时区格式
 * @param time
 * @returns {string}
 */
export function handleTimeZone(time) {
  if (typeof time !== 'string') return
  return time + '+08:00'
}
