<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    :visible="true"
    :title="'对账单' + updateStatusNumber + '结算信息录入'"
    :maskClosable="false"
    @cancel="handleCancel"
    width="600px"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm"
        >确定</a-button
      >
    </template>
    <a-form-model
      ref="ruleForm"
      :model="settle"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="结算金额" prop="settlePrice">
        <a-input-number
          style="width: 300px"
          v-model.trim="settle.settlePrice"
          placeholder="请输入结算金额"
        />
      </a-form-model-item>
      <a-form-model-item label="结算时间" prop="settleTime">
        <a-date-picker
          style="width: 300px"
          v-model="settle.settleTime"
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          type="date"
          placeholder="请选择开单时间"
        />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="comment">
        <a-textarea
          style="width: 300px"
          v-model.trim="settle.comment"
          :max-length="1024"
          placeholder="请输入开单备注"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateSettlementStatus } from '@/api/settlements'
import moment from 'moment'
export default {
  props: {
    id: Number,
    updateStatusNumber: String,
    account: String
  },
  data() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      loading: false,
      settle: {
        settleTime: moment(new Date()),
        settlePrice: this.account || '',
        comment: '',
      },
      rules: {
        settlePrice: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入结算金额',
          },
        ],
        settleTime: [
          {
            required: true,
            trigger: 'change',
            message: '请输入结算时间',
          },
        ],
      },
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          updateSettlementStatus(this.id, {
            ...this.settle,
            settleTime: moment(this.settle.settleTime).format('YYYY-MM-DD HH:mm:ss')
          }).then(
            (res) => {
              this.loading = false
              this.$message.success('操作成功')
              this.$emit('confirm')
            },
            (error) => {
              this.loading = false
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style scoped></style>
