var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.inquiryCode + _vm.isAdd ? "新增产品" : "更新产品",
        "mask-closable": false,
        width: "800px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.record,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        _vm._l(_vm.record.productItemList, function (product, index) {
          return _c(
            "a-row",
            { key: index },
            [
              _c(
                "a-form-model-item",
                {
                  staticClass: "customOperation seqNum",
                  staticStyle: { "margin-left": "89px" },
                  attrs: {
                    label: "产品名称",
                    labelCol: { span: 8 },
                    prop: index > 0 ? "name" + index : "name",
                  },
                },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    attrs: { maxLength: 100, placeholder: "请输入产品名称" },
                    model: {
                      value: _vm.record.productItemList[index].productInfo.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.record.productItemList[index].productInfo,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "record.productItemList[index].productInfo.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "customOperation seqNum",
                  staticStyle: { "margin-left": "89px" },
                  attrs: {
                    label: "产品图号",
                    labelCol: { span: 8 },
                    prop: index > 0 ? "drawingNum" + index : "drawingNum",
                  },
                },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    attrs: { maxLength: 100, placeholder: "请输入产品图号" },
                    model: {
                      value:
                        _vm.record.productItemList[index].productInfo
                          .drawingNum,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.record.productItemList[index].productInfo,
                          "drawingNum",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "record.productItemList[index].productInfo.drawingNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "customOperation seqNum",
                  staticStyle: { "margin-left": "89px" },
                  attrs: {
                    label: "产品规格",
                    labelCol: { span: 8 },
                    prop: index > 0 ? "specification" + index : "specification",
                  },
                },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    attrs: { maxLength: 100, placeholder: "请输入产品规格" },
                    model: {
                      value:
                        _vm.record.productItemList[index].productInfo
                          .specification,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.record.productItemList[index].productInfo,
                          "specification",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "record.productItemList[index].productInfo.specification",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "customOperation seqNum",
                  staticStyle: { "margin-left": "89px" },
                  attrs: {
                    label: "产品单位",
                    labelCol: { span: 8 },
                    prop: index > 0 ? "unit" + index : "unit",
                  },
                },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    attrs: { maxLength: 2, placeholder: "请输入产品单位" },
                    model: {
                      value: _vm.record.productItemList[index].productInfo.unit,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.record.productItemList[index].productInfo,
                          "unit",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "record.productItemList[index].productInfo.unit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "customOperation seqNum",
                  staticStyle: { "margin-left": "89px" },
                  attrs: {
                    label: "询价数量",
                    labelCol: { span: 8 },
                    prop: index > 0 ? "quantity" + index : "quantity",
                  },
                },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入询价数量" },
                    model: {
                      value: _vm.record.productItemList[index].quantity,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.record.productItemList[index],
                          "quantity",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "record.productItemList[index].quantity",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "customOperation seqNum",
                  staticStyle: { "margin-left": "89px" },
                  attrs: {
                    label: "产品类型",
                    labelCol: { span: 8 },
                    prop: index > 0 ? "type" + index : "type",
                  },
                },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    attrs: { maxLength: 5, placeholder: "请输入产品类型" },
                    model: {
                      value: _vm.record.productItemList[index].productInfo.type,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.record.productItemList[index].productInfo,
                          "type",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "record.productItemList[index].productInfo.type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "customOperation seqNum",
                  staticStyle: { "margin-left": "89px" },
                  attrs: {
                    label: "备注",
                    labelCol: { span: 8 },
                    prop: index > 0 ? "comment" + index : "comment",
                  },
                },
                [
                  _c("a-textarea", {
                    staticStyle: { width: "300px" },
                    attrs: { "max-length": 500, placeholder: "请输入产品备注" },
                    model: {
                      value: _vm.record.productItemList[index].comment,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.record.productItemList[index],
                          "comment",
                          $$v
                        )
                      },
                      expression: "record.productItemList[index].comment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }