export const rawMaterialColumns = [
  {
    title: '顺序号',
    dataIndex: 'sequence',
    key: 'sequence',
    align: 'center',
  },
  {
    title: '材料编号',
    dataIndex: 'materialInfo.innerCode',
    key: 'materialInfo.innerCode',
    align: 'center',
  },
  {
    title: '材料牌号',
    dataIndex: 'materialInfo.code',
    key: 'materialInfo.code',
    align: 'center',
  },
  {
    title: '材料名称',
    dataIndex: 'materialInfo.name',
    key: 'materialInfo.name',
    align: 'center',
  },
  {
    title: '采购规格',
    dataIndex: 'materialInfo.purchaseSpecification',
    key: 'materialInfo.purchaseSpecification',
    align: 'center',
  },
  {
    title: '密度',
    dataIndex: 'materialInfo.density',
    key: 'materialInfo.density',
    align: 'center',
  },
  {
    title: '材料单位',
    dataIndex: 'materialInfo.unit',
    key: 'materialInfo.unit',
    align: 'center',
  },
  {
    title: '当前最新单价',
    dataIndex: 'materialInfo.unitPrice',
    key: 'materialInfo.unitPrice',
    align: 'center',
  },
  {
    title: '计价模型',
    dataIndex: 'pricingModel',
    key: 'pricingModel',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'pricingModel',
    },
  },
  {
    title: '消耗规格',
    dataIndex: 'materialSpec',
    key: 'materialSpec',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'materialSpec',
    },
  },
  {
    title: '操作',
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
