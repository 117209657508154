export const approvalColumns = [
  {
    title: '序号',
    width: 80,
    dataIndex: 'index',
    key: 'index',
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '审价单号',
    width: 180,
    dataIndex: 'code',
    key: 'code',
    searchType: 'text',
    sorter: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'code',
    },
  },
  {
    title: '客户名称',
    dataIndex: 'clientName',
    key: 'clientName',
    width: 200,
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '询价单号',
    width: 180,
    dataIndex: 'inquiryCode',
    key: 'inquiryCode',
    searchType: 'text',
    sorter: false,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '审价状态',
    width: 120,
    dataIndex: 'status',
    key: 'status',
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '审价中',
        value: 'CCK',
      },
      {
        text: '已通过',
        value: 'AGR',
      },

      {
        text: '已驳回',
        value: 'RJT',
      },
    ],
  },
  {
    title: '提交审核时间',
    width: 180,
    dataIndex: 'startTime',
    key: 'startTime',
    sorter: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '审核结束时间',
    width: 180,
    dataIndex: 'finishTime',
    key: 'costCheck.finishTime',
    sorter: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品项数',
    width: 130,
    dataIndex: 'productItemCount',
    key: 'productItemCount',
    sorter: true,
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '特殊情况说明',
    width: 150,
    ellipsis: true,
    dataIndex: 'remark',
    key: 'remark',
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '审价备注',
    width: 200,
    dataIndex: 'comment',
    key: 'comment',
    ellipsis: true,
    sorter: false,
    align: 'center',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '操作',
    width: 80,
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
