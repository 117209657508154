<template>
  <a-modal
    v-drag-modal
    class="modal-container"
    visible="true"
    :title="type === 'add' ? '新增资源' : '更新资源'"
    :mask-closable="false"
    width="600px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="resourceForm" :rules="rules" :model="resource" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="资源类型" prop="type">
        <a-radio-group v-model="resource.type" style="width: 400px; fontWeight: 400;">
          <a-radio :value="0">根节点</a-radio>
          <a-radio :value="1">页面</a-radio>
          <a-radio :value="2">API</a-radio>
          <a-radio :value="3">父模块</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="resource.type === 1 || resource.type === 2 || resource.type === 3" label="父模块" prop="parentId">
        <a-select v-model="resource.parentId" prop="type">
          <a-select-option v-for="item in parentNode" :key="item.id" :value="item.id">{{ item.description }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="资源编码" prop="code">
        <a-input v-model="resource.code" :max-length="30" placeholder="请输入资源编码" />
      </a-form-model-item>
      <a-form-model-item label="资源描述" prop="description">
        <a-input v-model.trim="resource.description" :max-length="30" placeholder="请输入资源描述" />
      </a-form-model-item>
      <a-form-model-item v-if="resource.type === 1 || resource.type === 2" label="资源地址" prop="uri">
        <a-input v-model.trim="resource.uri" :max-length="30" placeholder="请输入资源地址" />
      </a-form-model-item>
      <a-form-model-item v-if="resource.type === 2" label="资源请求方法" prop="method" :required="resource.type === 2">
        <a-radio-group v-model="resource.method" style="width: 400px; fontWeight: 400;">
          <a-radio value="GET">GET</a-radio>
          <a-radio value="POST">POST</a-radio>
          <a-radio value="PUT">PUT</a-radio>
          <a-radio value="DELETE">DELETE</a-radio>
          <a-radio value="PATCH">PATCH</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {
  addResource,
  updateResource,
  getResourcesParentNode,
} from '@/api/resource'
import * as R from 'ramda'
export default {
  props: {
    type: String,
    resourceForm: Object,
  },
  data() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 10,
        offset: 1,
      },
      resource: {
        code: this.resourceForm.code,
        description: this.resourceForm.description,
        uri: this.resourceForm.uri,
        method: this.resourceForm.method,
        type: this.resourceForm.type,
        parentId: this.resourceForm.parentId,
      },
      rules: {
        code: [
          {
            required: true,
            message: '请输入资源编码',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: '请输入资源描述',
            trigger: 'blur',
          },
        ],
        uri: [
          {
            required: true,
            message: '请输入资源地址',
            trigger: 'blur',
          },
        ],
        type: [
          { required: true, message: '请选择资源类型', trigger: 'change' },
        ],
        parentId: [
          { required: true, message: '请选择父模块', trigger: 'change' },
        ],
        method: [
          {
            required: true,
            message: '请选择请求方法',
            trigger: 'change',
          },
        ],
      },
      parentNode: [],
    }
  },
  mounted() {
    this.handleGetParentNode()
  },
  methods: {
    handleReset() {
      this.$refs.resourceForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.resourceForm.validate((valid) => {
        if (valid) {
          if (this.type === 'add') {
            this.addResource(this.resource)
          } else if (this.type === 'update') {
            this.updateResource(this.resource, this.resourceForm.id)
          }
        } else {
          console.log('error submit!!', valid)
          return false
        }
      })
    },
    handleGetParentNode() {
      getResourcesParentNode().then((res) => {
        this.parentNode = res.data
      })
    },
    addResource(data) {
      this.loading = true
      const submitData = R.clone(data)
      addResource(submitData)
        .then((res) => {
          if (res.data.id) {
            this.$emit('confirm')
            this.$message.success('新增资源成功')
          } else {
            this.$message.error('新增资源失败，请重试！')
          }
        })
        .catch(
          // 工序失败原因
          (reason) => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    updateResource(data, id) {
      this.loading = true
      const successMessage = '更新资源成功！'
      const failMessage = '更新资源失败，请重试！'
      updateResource(id, data)
        .then((res) => {
          if (res.data.id) {
            this.$emit('confirm')
            this.$message.success(successMessage)
          } else {
            this.$message.error(failMessage)
          }
        })
        .catch(
          // 工序失败原因
          (reason) => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
  },
}
</script>

<style scoped>
</style>
