var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: _vm.isAdd ? "新增产品" : "更新产品",
        "mask-closable": false,
        width: "800px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.record,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            {
              staticClass: "customOperation seqNum",
              staticStyle: { "margin-left": "89px" },
              attrs: { label: "产品名称", labelCol: { span: 8 }, prop: "name" },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入产品名称" },
                model: {
                  value: _vm.record.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.record,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "record.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "customOperation seqNum",
              staticStyle: { "margin-left": "89px" },
              attrs: {
                label: "产品图号",
                labelCol: { span: 8 },
                prop: "drawingNum",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入产品图号" },
                model: {
                  value: _vm.record.drawingNum,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.record,
                      "drawingNum",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "record.drawingNum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "customOperation seqNum",
              staticStyle: { "margin-left": "89px" },
              attrs: {
                label: "产品规格",
                labelCol: { span: 8 },
                prop: "specification",
              },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 20, placeholder: "请输入产品规格" },
                model: {
                  value: _vm.record.specification,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.record,
                      "specification",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "record.specification",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "customOperation seqNum",
              staticStyle: { "margin-left": "89px" },
              attrs: { label: "产品单位", labelCol: { span: 8 }, prop: "unit" },
            },
            [
              _c("a-input", {
                staticStyle: { width: "300px" },
                attrs: { maxLength: 2, placeholder: "请输入产品单位" },
                model: {
                  value: _vm.record.unit,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.record,
                      "unit",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "record.unit",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "customOperation seqNum",
              staticStyle: { "margin-left": "89px" },
              attrs: { label: "备注", labelCol: { span: 8 }, prop: "comment" },
            },
            [
              _c("a-textarea", {
                staticStyle: { width: "300px" },
                attrs: { "max-length": 500, placeholder: "请输入备注" },
                model: {
                  value: _vm.record.comment,
                  callback: function ($$v) {
                    _vm.$set(_vm.record, "comment", $$v)
                  },
                  expression: "record.comment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }