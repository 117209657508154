Date.timeFormat = function(time) {
  if (time) {
    var d = new Date(time)

    var year = d.getFullYear() // 年
    var month = d.getMonth() + 1 // 月
    var day = d.getDate() // 日

    var hh = d.getHours() // 时
    var mm = d.getMinutes() // 分
    var ss = d.getSeconds() // 秒

    var clock = year + '年'

    if (month < 10) { clock += '0' }

    clock += month + '月'

    if (day < 10) { clock += '0' }

    clock += day + '日 '

    if (hh < 10) { clock += '0' }

    clock += hh + ':'
    if (mm < 10) clock += '0'
    clock += mm + ':'

    if (ss < 10) clock += '0'
    clock += ss
    return (clock)
  } else {
    return time
  }
}

Date.getDayTime = function(time) {
  if (time) {
    var d = new Date(time)

    var year = d.getFullYear() // 年
    var month = d.getMonth() + 1 // 月
    var day = d.getDate() // 日

    var clock = year + '年'

    if (month < 10) { clock += '0' }

    clock += month + '月'

    if (day < 10) { clock += '0' }

    clock += day + '日 '
    return (clock)
  } else {
    return time
  }
}
