import request from '@/utils/api.request'


// 获取各工序停留产品数据
export function getLargeScreenProducts() {
  return request({
    url: `/large-screen/operations/products`,
    method: 'get',
  })
}

// 入库数据获取
export function getLargeScreenInputInventory() {
  return request({
    url: '/large-screen/warehouses/put-in-products',
    method: 'get',
  })
}

// 任务数据获取
export function getLargeScreenTasks() {
  return request({
    url: `/large-screen/tasks`,
    method: 'get',
  })
}
// 紧急任务获取
export function getLargeScreenEmergencies() {
  return request({
    url: `/large-screen/tasks/emergencies`,
    method: 'get'
  })
}
// 订单数据获取
export function getLargeScreenOrder() {
  return request({
    url: `/large-screen/orders`,
    method: 'get'
  })
}

// 计划数据获取
export function getLargeScreenPlan() {
  return request({
    url: `/large-screen/plans`,
    method: 'get'
  })
}

// 未完紧急任务统计数据获取
export function getLargeScreenEmergenciesData() {
  return request({
    url: `/large-screen/tasks/emergencies`,
    method: 'get'
  })
}
