<template>
  <a-modal
    class="modal-container"
    :visible="true"
    :title="inquiryCode + isAdd ? '新增产品' : '更新产品'"
    :mask-closable="false"
    width="800px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>

    <a-form-model ref="ruleForm" :model="record" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row v-for="(product, index) in record.productItemList" :key="index">
        <a-form-model-item
          label="产品名称"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          :prop="index > 0 ? `name${index}` : 'name'"
          class="customOperation seqNum"
        >
          <a-input
            style="width: 300px"
            :maxLength="100"
            v-model.trim="record.productItemList[index].productInfo.name"
            placeholder="请输入产品名称"
          />
        </a-form-model-item>

        <a-form-model-item
          label="产品图号"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          :prop="index > 0 ? `drawingNum${index}` : 'drawingNum'"
          class="customOperation seqNum"
        >
          <a-input
            style="width: 300px"
            :maxLength="100"
            v-model.trim="record.productItemList[index].productInfo.drawingNum"
            placeholder="请输入产品图号"
          />
        </a-form-model-item>

        <a-form-model-item
          label="产品规格"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          :prop="index > 0 ? `specification${index}` : 'specification'"
          class="customOperation seqNum"
        >
          <a-input
            style="width: 300px"
            :maxLength="100"
            v-model.trim="record.productItemList[index].productInfo.specification"
            placeholder="请输入产品规格"
          />
        </a-form-model-item>

        <a-form-model-item
          label="产品单位"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          :prop="index > 0 ? `unit${index}` : 'unit'"
          class="customOperation seqNum"
        >
          <a-input style="width: 300px" :maxLength="2" v-model.trim="record.productItemList[index].productInfo.unit" placeholder="请输入产品单位" />
        </a-form-model-item>

        <a-form-model-item
          label="询价数量"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          :prop="index > 0 ? `quantity${index}` : 'quantity'"
          class="customOperation seqNum"
        >
          <a-input style="width: 300px" v-model.trim="record.productItemList[index].quantity" placeholder="请输入询价数量" />
        </a-form-model-item>

        <a-form-model-item
          label="产品类型"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          :prop="index > 0 ? `type${index}` : 'type'"
          class="customOperation seqNum"
        >
          <a-input style="width: 300px" :maxLength="5" v-model.trim="record.productItemList[index].productInfo.type" placeholder="请输入产品类型" />
        </a-form-model-item>

        <a-form-model-item
          label="备注"
          :labelCol="{span: 8}"
          style="margin-left: 89px;"
          class="customOperation seqNum"
          :prop="index > 0 ? `comment${index}` : 'comment'"
        >
          <a-textarea style="width: 300px" :max-length="500" v-model="record.productItemList[index].comment" placeholder="请输入产品备注" />
          <!-- <template>
            <a-icon
              v-if="index === record.productItemList.length - 1"
              @click="handleAddInquiryProducItem"
              type="plus-circle"
              style="fontSize: 16px; marginLeft: 20px; cursor: pointer;marginTop: 4px;"
            />
            <a-icon
              v-if="index > 0"
              @click="handleDeleteInquiryProducItem(index)"
              type="minus-circle"
              style="fontSize: 16px; marginLeft: 20px; cursor: pointer;marginTop: 4px;"
            />
          </template>-->
        </a-form-model-item>
      </a-row>
    </a-form-model>
  </a-modal>
</template>
<script>
import {
  addInquiryListProduct,
  updateInquiryListProduct
} from '@/api/inquirylist'
export default {
  props: {
    inquiryCode: String,
    editRecord: Object,
    type: String
  },
  data() {
    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      record: {
        productItemList: [
          {
            sequence: this.editRecord.sequence || '',
            productInfo: this.editRecord.productInfo || {
              code: '',
              name: '',
              drawingNum: '',
              specification: '',
              unit: '',
              type: '',
              comment: ''
            },
            quantity: this.editRecord.quantity || '',
            comment: this.editRecord.comment || ''
          }
        ]
      },
      rules: {
        sequence: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.record.productItemList[0].sequence) {
                return callback(new Error('请输入产品项序号'))
              }
              callback()
            }
          }
        ],
        name: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.record.productItemList[0].productInfo.name) {
                return callback(new Error('请输入产品名称'))
              }
              callback()
            }
          }
        ],
        drawingNum: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入产品图号',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.record.productItemList[0].productInfo.drawingNum) {
                return callback(new Error('请输入产品图号'))
              }
              callback()
            }
          }
        ],
        specification: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.record.productItemList[0].productInfo.specification) {
                return callback(new Error('请输入产品规格'))
              }
              callback()
            }
          }
        ],
        unit: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.record.productItemList[0].productInfo.unit) {
                return callback(new Error('请输入产品单位'))
              }
              callback()
            }
          }
        ],
        quantity: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入询价数量',
            validator: (rule, value, callback) => {
              // console.log('opera', value)
              if (!this.record.productItemList[0].quantity) {
                return callback(new Error('请输入询价数量'))
              }
              callback()
            }
          }
        ]
      }
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function() {
      return this.type === 'add'
    },
    isUpdate: function() {
      return this.type === 'edit'
    }
  },
  mounted() {
    if (this.isUpdate) {
      this.handleGetLatestNegotiates()
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.add()
          } else {
            this.update()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    add() {
      this.loading = true
      addInquiryListProduct(this.inquiryCode, this.record.productItemList)
        .then(res => {
          this.$emit('confirm')
          this.$message.success('操作成功')
        })
        .finally(() => {
          this.loading = false
        })
    },
    update() {
      this.loading = true
      updateInquiryListProduct(
        this.inquiryCode,
        this.editRecord.sequence,
        this.record.productItemList[0]
      )
        .then(res => {
          this.$emit('confirm')
          this.$message.success('操作成功')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 8px;
}
</style>
