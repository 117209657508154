var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: true,
        title: _vm.modalTitle + "任务",
        "mask-closable": false,
        width: "700px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleUpdateTask },
            },
            [_vm._v(_vm._s(_vm.modalTitle))]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { ref: "code", attrs: { label: "任务编号", prop: "code" } },
            [
              _c("a-input", {
                attrs: { "max-length": "100", placeholder: "请输入任务编号" },
                model: {
                  value: _vm.form.code,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "code",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.code",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务类型", prop: "type" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: 0 } }, [
                    _vm._v("普通任务"),
                  ]),
                  _c("a-select-option", { attrs: { value: 4 } }, [
                    _vm._v("普通补投任务"),
                  ]),
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("返修任务"),
                  ]),
                  _c("a-select-option", { attrs: { value: 2 } }, [
                    _vm._v("生产补投任务"),
                  ]),
                  _c("a-select-option", { attrs: { value: 3 } }, [
                    _vm._v("协作任务"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.type === 1 && _vm.isAdd
            ? _c(
                "a-form-model-item",
                { attrs: { label: "退货原因", prop: "abandonReason" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { "default-value": "1" },
                      model: {
                        value: _vm.form.abandonReason,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "abandonReason", $$v)
                        },
                        expression: "form.abandonReason",
                      },
                    },
                    [
                      _c("a-icon", {
                        attrs: { slot: "suffixIcon", type: "smile" },
                        slot: "suffixIcon",
                      }),
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("客户因素"),
                      ]),
                      _c("a-select-option", { attrs: { value: 3 } }, [
                        _vm._v("错漏检问题"),
                      ]),
                      _c("a-select-option", { attrs: { value: 4 } }, [
                        _vm._v("工艺技术问题"),
                      ]),
                      _c("a-select-option", { attrs: { value: 5 } }, [
                        _vm._v("检验误差问题"),
                      ]),
                      _c("a-select-option", { attrs: { value: 6 } }, [
                        _vm._v("生产制造因素"),
                      ]),
                      _c("a-select-option", { attrs: { value: 8 } }, [
                        _vm._v("外协厂家因素"),
                      ]),
                      _c("a-select-option", { attrs: { value: 9 } }, [
                        _vm._v("其他"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "实发材料", prop: "actualUseMaterial" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    mode: "multiple",
                    loading: _vm.checkLoading,
                    "show-search": "",
                    "default-active-first-option": false,
                    placeholder: "请选择实发材料",
                    "option-filter-prop": "children",
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": _vm.fetching ? undefined : null,
                  },
                  on: {
                    focus: _vm.handleFetchALlMaterial,
                    search: _vm.fetchMaterialByCode,
                    select: function (value, option) {
                      return _vm.handleMaterialNameChange(value, option)
                    },
                    deselect: function (value, option) {
                      return _vm.handleMaterialNameChange(value, option)
                    },
                  },
                  model: {
                    value: _vm.actualUseMaterial,
                    callback: function ($$v) {
                      _vm.actualUseMaterial = $$v
                    },
                    expression: "actualUseMaterial",
                  },
                },
                [
                  _vm.fetching
                    ? _c("a-spin", {
                        attrs: { slot: "notFoundContent", size: "small" },
                        slot: "notFoundContent",
                      })
                    : _vm._e(),
                  _vm._l(_vm.materials, function (material) {
                    return _c("a-select-option", { key: material.id }, [
                      _vm._v(
                        _vm._s(material.innerCode) +
                          " " +
                          _vm._s(material.materialName) +
                          "(" +
                          _vm._s(material.materialCode) +
                          ")"
                      ),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "has-feedback": "",
                label: "投产数量",
                prop: "productionNum",
              },
            },
            [
              _c("a-input", {
                model: {
                  value: _vm.form.productionNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "productionNum", _vm._n($$v))
                  },
                  expression: "form.productionNum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "期望下料时间", prop: "expectMaterialTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "300px" },
                attrs: {
                  "show-time": "",
                  format: "YYYY-MM-DD HH:mm:ss",
                  type: "date",
                  placeholder: "请选择期望下料时间",
                },
                model: {
                  value: _vm.form.expectMaterialTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "expectMaterialTime", $$v)
                  },
                  expression: "form.expectMaterialTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "comment" } },
            [
              _c("a-input", {
                attrs: {
                  "max-length": "1024",
                  placeholder: "请输入任务备注(不超过1024个字)",
                  type: "textarea",
                },
                model: {
                  value: _vm.form.comment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "comment",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.comment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }