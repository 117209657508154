import { Bar, Pie } from '@antv/g2plot'
import {
  getLargeScreenTasks,
  deleteMaterial,
  getLargeScreenOrder,
  getLargeScreenInputInventory,
  getLargeScreenPlan,
  getLargeScreenProducts,
  getLargeScreenEmergencies,
  getLargeScreenEmergenciesData
} from '@/api/screen'
import {
  getMonthStatistics,
  getWeekStatistics,
  getGroupStatistics,
  getMemberStatistics,
} from '@/api/statistics'
import { PollingTime, EmergencyType } from '@/enums/index'
import countTo from 'vue-count-to'
export default {
  name: 'manage-board',
  components: {
    countTo,
  },
  data() {
    return {
      EmergencyType,
      date: '',
      dateTimer: '',
      taskData: '',
      showingTaskInfo: {
        show: {},
        all: [],
        index: 0,
        timer: ''
      },
      orderData: '',
      inventoryData: '',
      planData: '',
      productData: {
        page: 1,
        all: [],
        show: [],
        timer: '',
      },
      productTimer: '',

      monthTargetInfo: '',
      weekTargetInfo: '',
      targetTimer: '',
      productType: 'L',
      targetId: '',
      groupTargetInfo: {
        page: 1,
        all: [],
        show: [],
        timer: '',
      },
      memberTargetInfo: {
        page: 1,
        all: [],
        show: [],
        timer: '',
      },
      emgData: [],
      IUG_Data: [],
      TUG_Data: [],
      UGC_Data: [],
      MFN_Data: [],
      pollingInterval: '',
      pollingTimer: '',
      PollingTime,
    }
  },
  created() {
    if (this.$route.name === 'allScreen') {
      document.documentElement.requestFullscreen()
    }
    this.date = this.$moment(new Date()).locale('zh-cn').format('llll')
  },
  mounted() {
    this.init()
    this.pollingInterval = setInterval(() => {
      this.handleClearTimer()
      this.handleClearPie()
      this.handleClearBar()
      this.pollingTimer = setTimeout(() => {
        this.init()
        clearTimeout(this.pollingTimer)
      }, 1000)
    }, this.PollingTime)
    this.dateTimer = setInterval(() => {
      this.date = this.$moment(new Date()).locale('zh-cn').format('llll')
    }, 30000)
  },
  beforeDestroy() {
    this.handleClearTimer()
    this.handleClearPie()
    clearInterval(this.pollingInterval)
    clearInterval(this.dateTimer)
  },
  methods: {
    init() {
      this.getScreenTask()
      this.getLargeScreenOrder()
      this.getLargeScreenInputInventory()
      this.getLargeScreenPlan()
      this.getLargeScreenProducts()
      this.getLargeScreenEmergenciesData()
      this.getGroupStatistics()
      this.getMemberStatistics()
      this.getTargetDetail()
      this.handleTargetTimer()
    },
    getScreenTask() {
      getLargeScreenTasks().then((res) => {
        this.showingTaskInfo.all = res.data
        this.showingTaskInfo.show = res.data[0]
        this.handleTaskTimer()
      })
    },
    getLargeScreenOrder() {
      getLargeScreenOrder().then((res) => {
        this.orderData = res.data
      })
    },
    getLargeScreenInputInventory() {
      getLargeScreenInputInventory().then((res) => {
        this.inventoryData = res.data
      })
    },
    getLargeScreenPlan() {
      getLargeScreenPlan().then((res) => {
        this.planData = res.data
      })
    },
    getLargeScreenProducts() {
      getLargeScreenProducts().then((res) => {
        this.productData.all = res.data
        this.productData.show = this.productData.all.slice(0, 10)
        this.productData.page = 1
        const _data = this.handleProductsInfo(this.productData.show)
        this.BarEmpty('productBar')
        this.$nextTick(() => {
          this.bar('productBar', _data)
          this.handleProductTimer()
        })
      })
    },
    getGroupStatistics() {
      getGroupStatistics({ pageNum: 1, pageSize: 50 }).then((res) => {
        this.groupTargetInfo.all = res.data.records
        this.groupTargetInfo.show = res.data.records.slice(0, 3)
        this.groupTargetInfo.page = 1
        const _data = this.handleGroupInfo(this.groupTargetInfo.show)
        this.$nextTick(() => {
          this.bar('groupBar', _data, 'percent')
          this.handleGroupTimer()
        })
      })
    },
    getMemberStatistics() {
      getMemberStatistics({ pageNum: 1, pageSize: 50 }).then((res) => {
        this.memberTargetInfo.all = res.data.records
        this.memberTargetInfo.show = res.data.records.slice(0, 5)
        this.memberTargetInfo.page = 1
        const _data = this.handleMemberInfo(this.memberTargetInfo.show)
        this.$nextTick(() => {
          if (this.memberTargetInfo.all.length) {
            this.bar('memberBar', _data, 'percent')
            this.handleMemberTimer()
          }
        })
      })
    },
    getLargeScreenEmergencies() {
      getLargeScreenEmergencies().then((res) => {
        // this.emgData.forEach((item) => {
        //   item.all = []
        //   item.show = []
        //   item.page = 1
        // })
        // res.data.map((item) => {
        //   switch (item.emergencyType) {
        //     case 'IUG':
        //       this.emgData[0].all.push(item)
        //       break
        //     case 'UGC':
        //       this.emgData[1].all.push(item)
        //       break
        //     case 'MFN':
        //       this.emgData[2].all.push(item)
        //       break
        //     case 'TUG':
        //       this.emgData[3].all.push(item)
        //       break
        //   }
        // })
        // this.handleEmgTimer()
      })
    },

    getLargeScreenEmergenciesData() {
      getLargeScreenEmergenciesData().then((res) => {
        this.emgData = res.data
      })
    },


    handleProductsInfo(data) {
      const info = []
      data.forEach((item) => {
        info.push(
          {
            label: item.operationName,
            type: '产品项数',
            value: item.productItemCount,
          },
          {
            label: item.operationName,
            type: '产品数量',
            value: item.productCount,
          }
        )
      })
      return info
    },
    handleGroupInfo(data) {
      const info = []
      data.forEach((item) => {
        info.push(
          {
            label: item.groupInfo.groupName,
            type: '任务项数',
            value: Number(item.tasksStatisticsInfo.finishedRate * 100) || null,
          },
          {
            label: item.groupInfo.groupName,
            type: '产品数量',
            value:
              Number(item.productStatisticsInfo.finishedRate * 100) || null,
          },
          {
            label: item.groupInfo.groupName,
            type: '产值金额',
            value: Number(item.amountStatisticsInfo.finishedRate * 100) || null,
          }
        )
      })
      return info
    },
    handleMemberInfo(data) {
      const info = []
      data.forEach((item) => {
        info.push(
          {
            label: item.memberInfo.realName,
            type: '任务项数',
            value:
              parseInt(item.tasksStatisticsInfo.finishedRate * 100) || null,
          },
          {
            label: item.memberInfo.realName,
            type: '产品数量',
            value:
              parseInt(item.productStatisticsInfo.finishedRate * 100) || null,
          },
          {
            label: item.memberInfo.realName,
            type: '产值金额',
            value:
              parseInt(item.amountStatisticsInfo.finishedRate * 100) || null,
          }
        )
      })
      return info
    },
    getTargetDetail() {
      this.handleClearPie()
      getMonthStatistics(this.targetId, this.productType).then((res) => {
        this.monthTargetInfo = res.data.normalTargetStatisticsInfo
        this.handleTargetInfoForPie(this.monthTargetInfo, 'month')
      })
      getWeekStatistics(this.targetId, this.productType).then((res) => {
        this.weekTargetInfo = res.data.normalTargetStatisticsInfo
        this.handleTargetInfoForPie(this.weekTargetInfo, 'week')
      })
    },
    handleClearPie() {
      ;['month', 'week'].forEach((item, index) => {
        document.getElementById(item + 'TaskPie').innerHTML = ''
        document.getElementById(item + 'ProductPie').innerHTML = ''
        document.getElementById(item + 'AmountPie').innerHTML = ''
      })
    },
    handleClearBar() {
      ;['groupBar', 'memberBar', 'productBar'].forEach((item, index) => {
        document.getElementById(item).innerHTML = ''
      })
    },
    handleTargetInfoForPie(info, type) {
      for (const key in info) {
        const item = info[key]
        const _data = [
          { type: key, value: item.finishedRate * 100 },
          { type: 'unfinished', value: 100 - item.finishedRate * 100 },
        ]
        let name, dom
        switch (key) {
          case 'taskStatisticsInfo':
            name = '任务项数'
            dom = type + 'TaskPie'
            break
          case 'productStatisticsInfo':
            name = '产品数量'
            dom = type + 'ProductPie'
            break
          case 'amountStatisticsInfo':
            name = '产值金额'
            dom = type + 'AmountPie'
            break
          default:
            break
        }
        this.pie(_data, dom, name)
      }
    },

    // 启用图表的轮询定时器
    handleEmgTimer() {
      this.emgData.forEach((emg) => {
        emg.show = emg.all.slice(0, 9)
        if (emg.all.length > 9) {
          emg.timer = setInterval(() => {
            if (emg.page < emg.all.length / 9) {
              emg.show = emg.all.slice(emg.page * 9, emg.page * 9 + 9)
              emg.page++
            } else {
              emg.show = emg.all.slice(0, 9)
              emg.page = 1
            }
          }, 5000)
        }
      })
    },
    handleProductTimer() {
      if (this.productData.all.length > 10) {
        this.productData.timer = setInterval(() => {
          if (this.productData.page < this.productData.all.length / 10) {
            this.productData.show = this.productData.all.slice(
              this.productData.page * 10,
              this.productData.page * 10 + 10
            )
            this.productData.page++
          } else {
            this.productData.show = this.productData.all.slice(0, 10)
            this.productData.page = 1
          }
          const _data = this.handleProductsInfo(this.productData.show)
          this.BarEmpty('productBar')
          this.$nextTick(() => {
            this.bar('productBar', _data)
          })
        }, 5000)
      }
    },
    handleTargetTimer() {
      this.targetTimer = setInterval(() => {
        switch (this.productType) {
          case 'L':
            this.productType = 'D'
            this.getTargetDetail()
            break
          case 'D':
            this.productType = 'LD'
            this.getTargetDetail()
            break
          case 'LD':
            this.productType = 'L'
            this.getTargetDetail()
            break
        }
      }, 5000)
    },
    handleMemberTimer() {
      if (this.memberTargetInfo.all.length > 5) {
        this.memberTargetInfo.timer = setInterval(() => {
          if (
            this.memberTargetInfo.page <
            this.memberTargetInfo.all.length / 5
          ) {
            this.memberTargetInfo.show = this.memberTargetInfo.all.slice(
              this.memberTargetInfo.page * 5,
              this.memberTargetInfo.page * 5 + 5
            )
            this.memberTargetInfo.page++
          } else {
            this.memberTargetInfo.show = this.memberTargetInfo.all.slice(0, 5)
            this.memberTargetInfo.page = 1
          }
          const _data = this.handleMemberInfo(this.memberTargetInfo.show)
          this.BarEmpty('memberBar')
          this.$nextTick(() => {
            this.bar('memberBar', _data, 'percent')
          })
        }, 5000)
      }
    },
    handleGroupTimer() {
      if (this.groupTargetInfo.all.length > 3) {
        this.groupTargetInfo.timer = setInterval(() => {
          if (this.groupTargetInfo.page < this.groupTargetInfo.all.length / 3) {
            this.groupTargetInfo.show = this.groupTargetInfo.all.slice(
              this.groupTargetInfo.page * 3,
              this.groupTargetInfo.page * 3 + 3
            )
            this.groupTargetInfo.page++
          } else {
            this.groupTargetInfo.show = this.groupTargetInfo.all.slice(0, 3)
            this.groupTargetInfo.page = 1
          }
          const _data = this.handleGroupInfo(this.groupTargetInfo.show)
          this.BarEmpty('groupBar')
          this.$nextTick(() => {
            this.bar('groupBar', _data, 'percent')
          })
        }, 5000)
      }
    },
    handleTaskTimer() {
      this.showingTaskInfo.timer = setInterval(() => {
        this.showingTaskInfo.index++
        if (this.showingTaskInfo.index === 3) {
          this.showingTaskInfo.index = 0
        }
        this.showingTaskInfo.show = this.showingTaskInfo.all[this.showingTaskInfo.index]

      }, 5000)
    },

    // 清楚图表的轮询定时器
    handleClearTimer() {
      this.emgData.forEach((emg) => {
        if (emg.timer) {
          clearInterval(emg.timer)
        }
      })
      clearInterval(this.targetTimer)
      this.targetTimer = null

      clearInterval(this.productData.timer)
      this.productData.timer = null

      clearInterval(this.memberTargetInfo.timer)
      this.memberTargetInfo.timer = null

      clearInterval(this.groupTargetInfo.timer)
      this.groupTargetInfo.timer = null

      clearInterval(this.showingTaskInfo.timer)
      this.showingTaskInfo.timer = null
    },

    pie(pieData, dom, name) {
      const piePlot = new Pie(dom, {
        data: pieData,
        angleField: 'value',
        colorField: 'type',
        color: ({ type }) => {
          if (type === 'amountStatisticsInfo') {
            return '#00b3bf'
          }
          if (type === 'productStatisticsInfo') {
            return '#fb9920'
          }
          if (type === 'taskStatisticsInfo') {
            return '#fb480f'
          }
          if (type === 'unfinished') {
            return 'rgb(233,233,233)'
          }
        },
        radius: 1,
        innerRadius: 0.6,
        tooltip: false,
        statistic: {
          title: {
            style: {
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: 14,
            },
            content: name,
          },
          content: {
            style: {
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: 14,
            },
            content: pieData[0].value.toFixed(2) + '%',
          },
        },
        label: false,
        legend: false,
        animation: false
      })

      this.$nextTick(() => {
        piePlot.render()
      })
    },
    BarEmpty(dom) {
      if (document.getElementById(dom)) {
        document.getElementById(dom).innerHTML = ''
      }
    },
    bar(dom, domData, barType) {
      const bar = new Bar(dom, {
        data: domData,
        isGroup: true,
        padding: barType === 'percent' ? [0, 50, 50, 50] : [0, 60, 50, 100],
        xAxis: {
          line: {
            style: {
              stroke: 'rgb(217,217,217)',
            },
          },
          grid: {
            line: null,
          },
          max: barType === 'percent' ? 100 : null,
        },
        yAxis: {
          label: {
            style: {
              fill: 'rgb(0,0,0, 0.8)'
            }
          },
          line: null,
          tickLine: null,
          grid: {
            line: {
              style: {
                stroke: 'rgb(232,232,232)',
                lineWidth: 2,
                lineDash: [4, 5],
                strokeOpacity: 0.7,
                cursor: 'pointer',
              },
            },
          },
        },
        xField: 'value',
        yField: 'label',
        minBarWidth: 10,
        maxBarWidth: 20,
        seriesField: 'type',
        marginRatio: 0,
        legend: {
          position: 'bottom',
          flipPage: false,
          itemSpacing: 0
        },
        label: {
          // 可手动配置 label 数据标签位置
          position: 'right', // 'left', 'middle', 'right'
          content: (xValue) => {
            if (barType === 'percent') {
              return parseInt(xValue.value).toFixed(2) + '%'
            }
            return xValue.value
          },
          offset: 4,
        },
        barStyle: { radius: [2, 2, 0, 0] },
        colorField: 'type',
        color: ({ type }) => {
          if (type === '产值金额') {
            return '#00b3bf'
          }
          if (type === '产品数量') {
            return '#fb9920'
          }
          if (type === '任务项数') {
            return '#fb480f'
          }
          if (type === '数量') {
            return '#56d4a3'
          }
          if (type === '产品项数') {
            return '#558bf4'
          }
        },
      })
      bar.render()
    },
  },
}
