<template>
  <div class="app-container">
    <a-row>
      <a-col :span="2">
        <el-button class="header-button" type="primary" icon="el-icon-circle-plus-outline" @click="handleAddParameter">新增参数</el-button>
      </a-col>
    </a-row>
    <a-table
      row-key="id"
      bordered
      class="operations-table"
      :columns="parameterColumns"
      :data-source="parameterList"
      :loading="loading"
      :pagination="pagination"
      :scroll="{y: 590 }"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operation" slot-scope="text, column" class="table-operation">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleUpdateParameter(column)">更新参数</a-menu-item>
            <a-menu-item @click="handleDeleteParameter(column)">删除参数</a-menu-item>
          </a-menu>
          <a class="operation-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
      <div slot="parameterType" slot-scope="text">
        <a-tag v-if="text==='根参数'" color="green">根参数</a-tag>
        <a-tag v-if="text==='父参数'" color="yellow">父参数</a-tag>
        <a-tag v-if="text==='子参数'" color="blue">子参数</a-tag>
        <a-tag v-if="text==='其他'" color="red">其他</a-tag>
      </div>
    </a-table>
    <ParameterEdit
      v-if="editModalVisible"
      :parameter-form="currentParameter"
      :modal-title="currentEditType"
      @cancel="handleRecordModalCanceled"
      @confirm="handleRecordModalConfirmed"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils'
import { parameterColumns } from './dataLogic'
import ParameterEdit from '@/components/ParameterEdit'
import { getParameters, deleteParameter } from '@/api/parameter'

export default {
  components: {
    ParameterEdit,
  },
  data() {
    return {
      loading: false,
      uploading: false,
      parameterList: [],
      parameterColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      initParameterListQueryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      currentParameterListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
      },
      uploadUrl: process.env.VUE_APP_BASE_API,
      currentEditType: '',
      currentParameter: {},
      editModalVisible: false,
      columnSearchParams: {},
    }
  },
  created() {
    this.getParameters(this.initParameterListQueryParams)
  },
  methods: {
    getParameters(data) {
      this.loading = true
      getParameters(data)
        .then((res) => {
          this.parameterList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getParamatersByParams() {
      const cQueryParams = deepClone(this.currentParameterListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getParameters(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)

      if (filtersKeys.includes('parameterType')) {
        delete this.columnSearchParams['parameterType']
        if (filters['parameterType'].length > 0) {
          this.columnSearchParams['parameterType'] = `in:${filters[
            'parameterType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['parameterType']
        }
      }

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentParameterListQueryParams.sort_by =
          sortType + sorter.columnKey
      } else {
        this.currentParameterListQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentParameterListQueryParams.pageNum = pagination.current
      this.currentParameterListQueryParams.pageSize = pagination.pageSize
      this.getParamatersByParams()
    },
    // 删除参数
    handleDeleteParameter(column) {
      const parameterName = column.parameterName ? column.parameterName : ''
      this.$confirm({
        content: '确认删除工序' + parameterName + '?',
        onOk: () => {
          deleteParameter(column.id).then(() => {
            this.$message.success('删除参数成功！')
            this.getParamatersByParams()
          })
        },
      })
    },
    handleAddParameter() {
      this.currentEditType = '新增参数'
      this.currentParameter = {}
      this.currentParameter.parameterType = '其他'
      this.editModalVisible = true
    },
    // 更新
    handleUpdateParameter(column) {
      this.currentEditType = '更新参数'
      this.currentParameter = deepClone(column)
      this.editModalVisible = true
    },
    handleEditCancel() {
      this.visible = false
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    handleRecordModalConfirmed() {
      this.editModalVisible = false
      this.getParameters(this.currentParameterListQueryParams)
    },
    handleRecordModalCanceled() {
      this.editModalVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.app-container {
  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .operation-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-operation {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
}
</style>
