<template>
  <a-modal
    v-drag-modal
    :body-style="bodyStyle"
    class="modal-container"
    :visible="true"
    :title="title"
    :mask-closable="false"
    width="1500px"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">装配</a-button>
    </template>
    <a-table
      row-key="id"
      bordered
      class="tasks-table"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :columns="columns"
      :data-source="taskList"
      :pagination="false"
      :loading="tableLoading"
    >
      <div slot="type" slot-scope="text">
        <template v-if="text === 0">
          <span>普通任务</span>
        </template>
        <template v-if="text === 1">
          <span>返修任务</span>
        </template>
        <template v-if="text === 2">
          <span>生产补投任务</span>
        </template>
        <template v-if="text === 3">
          <span>协作任务</span>
        </template>
        <template v-if="text === 4">
          <span>普通补投任务</span>
        </template>
        <template v-if="text === 5">
          <span>其他任务</span>
        </template>
      </div>
    </a-table>
  </a-modal>
</template>
<script>
import * as R from 'ramda'
import { getPlanSubTasks } from '@/api/plan'
import { assembleProducts } from '@/api/task'

const columns = [
  {
    title: '任务编码',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    width: 150,
  },
  {
    title: '任务类型',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 150,
    scopedSlots: {
      customRender: 'type',
    },
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    key: 'product.productName',
    width: 150,
    align: 'center',
    ellipsis: true,
  },
  {
    title: '图号',
    dataIndex: 'drawingNum',
    key: 'product.drawingNum',
    width: 150,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '规格',
    dataIndex: 'specification',
    key: 'product.specification',
    width: 150,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '投产数量',
    dataIndex: 'productionNum',
    width: 150,
    key: 'productionNum',
    align: 'center',
  },
  {
    title: '在产数量',
    dataIndex: 'producingNum',
    key: 'task.producingNum',
    width: 150,
    align: 'center',
  },
  {
    title: '报废数量',
    dataIndex: 'abandonNum',
    width: 150,
    key: 'abandonNum',
    align: 'center',
  },
  {
    title: '任务备注',
    dataIndex: 'comment',
    key: 'task.comment',
    width: 150,
    ellipsis: true,
    align: 'center',
  },
]

export default {
  props: {
    assembleForm: Object,
  },

  data() {
    return {
      bodyStyle: {
        height: '500px',
        overflowY: 'scroll',
      },
      columns,
      title: this.assembleForm.taskCode + '任务分件装配',
      loading: false,
      tableLoading: false,
      taskList: [],
      selectedRowKeys: [],
      selectedRowTaskIds: [],
      planId: this.assembleForm.planId,
      userId: this.assembleForm.userId,
      taskAssembleDTO: {},
    }
  },
  created() {
    this.getPlanSubTasks()
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    getPlanSubTasks() {
      this.tableLoading = true
      getPlanSubTasks(this.planId)
        .then((res) => {
          this.taskList = res.data
          if (this.taskList.length === 0) {
            this.handleConfirm()
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleConfirm() {
      this.loading = true
      if (this.taskList.length === 0) {
        this.$emit('confirm')
      } else {
        if (this.selectedRowTaskIds.length === 0) {
          this.$message.error('请选择待装配的分件!')
          this.loading = false
          return
        }
        this.taskAssembleDTO.userId = this.userId
        this.taskAssembleDTO.taskIds = this.selectedRowTaskIds
        this.$confirm({
          title: '装配选择确认',
          centered: true,
          content: '分件装配操作不支持撤销，确定是否装配？',
          onOk: () => {
            this.assembleProducts()
          },
          okText: '确认',
          cancelText: '取消',
          onCancel: () => {
            this.loading = false
          },
        })
      }
    },
    assembleProducts() {
      assembleProducts(this.taskAssembleDTO)
        .then((res) => {
          if (res.status === 200) {
            this.$emit('confirm')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRowTaskIds = selectedRows.map((task) => {
        return task.id
      })
    },
  },
}
</script>

<style scoped>
</style>
