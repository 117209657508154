import request from '@/utils/api.request'

/**
 * 分页获取记录信息
 * @param {object} data
 */
export function getRecords(data) {
  return request({
    url: `/records`,
    method: 'get',
    params: data
  })
}

/**
 * 根据id获取指定的记录信息
 * @param {number} id
 */
export function getRecordById(id) {
  return request({
    url: `/records/${id}`,
    method: 'get'
  })
}

/**
 * 更新指定id的记录
 * @param {number} id
 * @param {object} data
 */
export function updateRecord(id, data) {
  return request({
    url: `/records/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除指定id的记录
 * @param {number} id
 */
export function deleteRecord(id) {
  return request({
    url: `/records/${id}`,
    method: 'delete'
  })
}

/**
 * 通过计划id获取对应计划的记录信息
 * @param {number} id
 */
export function getRecordsByPlan(id, data) {
  return request({
    url: `/plans/${id}/records?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: 'get'
  })
}

/**
 * 通过任务id获取对应任务的记录信息
 * @param {number} id
 */
export function getRecordsByTask(id, data) {
  return request({
    url: `/tasks/${id}/records?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: 'get'
  })
}

/**
 * 导出筛选记录
 * @param {*} data 导出记录参数
 */
export function exportFilteredRecords(data) {
  return request({
    url: `/records/exports/filtered`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}


/**
 * 通过计划id获取所有分件任务信息
 * @param {number} id
 */
 export function getPartsTaskByPlan(id) {
  return request({
    url: `/plans/${id}/sub-tasks`,
    method: 'get'
  })
}

/**
 * 通过计划id获取所有分件任务信息
 * @param {number} id
 */
 export function finishNoCheck(data) {
  return request({
    url: `/tasks/assemble-finish-nocheck`,
    method: 'post',
    data
  })
}