<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" title="编辑任务紧急类型" :maskClosable="false" @cancel="handleCancel" width="600px">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="task" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="紧急类型" prop="type">
        <a-radio-group v-model="task.type">
          <a-radio :style="radioStyle" value="NML">普通</a-radio>
          <a-radio :style="radioStyle" value="MFN">必完件</a-radio>
          <a-radio :style="radioStyle" value="UGC">紧急件</a-radio>
          <a-radio :style="radioStyle" value="IUG">插入紧急件</a-radio>
          <a-radio :style="radioStyle" value="TUG">转入急件</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updateTaskEmergencyType } from '@/api/target'
import { updateEmergencyType } from '@/api/task'

export default {
  props: {
    ids: Array || String,
    targetId: Number,
    record: Object,
  },
  data() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 15,
        offset: 1,
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      loading: false,
      task: {
        type: this.record.emgType || this.record.emergencyType || 'NML',
      },
      rules: {
        type: [
          {
            required: true,
            trigger: 'change',
            message: '请选择紧急类型',
          },
        ],
      },
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.targetId) {
            updateTaskEmergencyType({
              taskIds: this.ids,
              emergencyType: this.task.type,
              targetId: this.targetId,
            }).then(
              (res) => {
                this.loading = false
                this.$message.success('操作成功')
                this.$emit('confirm')
              },
              (error) => {
                this.loading = false
              }
            )
          } else {
            console.log(this.ids)
            updateEmergencyType(this.ids.toString(), {
              emgType: this.task.type,
            }).then(
              (res) => {
                this.loading = false
                this.$message.success('操作成功')
                this.$emit('confirm')
              },
              (error) => {
                this.loading = false
              }
            )
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
