var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        width: "700px",
        title: _vm.modalTitle + "工艺",
        "mask-closable": false,
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          staticClass: "craft-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            {
              staticClass: "craft-form-item",
              attrs: { label: "工艺文件编号", prop: "craft.craftCode" },
            },
            [
              _c("a-input", {
                attrs: {
                  "max-length": "30",
                  placeholder: "请输入工艺文件编号",
                },
                model: {
                  value: _vm.form.craft.craftCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.craft,
                      "craftCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.craft.craftCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "craft-form-item",
              attrs: { label: "工艺产品名称", prop: "craft.craftProductName" },
            },
            [
              _c("a-input", {
                attrs: {
                  "max-length": "30",
                  placeholder: "请输入工艺产品名称",
                },
                model: {
                  value: _vm.form.craft.craftProductName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.craft,
                      "craftProductName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.craft.craftProductName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "craft-form-item",
              attrs: { label: "工艺产品图号", prop: "craft.craftDrawingNum" },
            },
            [
              _c("a-input", {
                attrs: {
                  "max-length": "30",
                  placeholder: "请输入工艺产品图号",
                },
                model: {
                  value: _vm.form.craft.craftDrawingNum,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.craft,
                      "craftDrawingNum",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.craft.craftDrawingNum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "craft-form-item",
              attrs: {
                label: "工艺产品规格",
                prop: "craft.craftSpecification",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  "max-length": "30",
                  placeholder: "请输入工艺产品规格",
                },
                model: {
                  value: _vm.form.craft.craftSpecification,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.craft,
                      "craftSpecification",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.craft.craftSpecification",
                },
              }),
            ],
            1
          ),
          _vm.form.craft.isParts === 1
            ? _c(
                "a-form-model-item",
                {
                  staticClass: "craft-form-item",
                  attrs: { label: "计划编码", prop: "craft.code" },
                },
                [
                  _c("a-input", {
                    attrs: {
                      "max-length": "30",
                      placeholder: "请输入计划编码",
                    },
                    model: {
                      value: _vm.form.craft.code,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.craft,
                          "code",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.craft.code",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }