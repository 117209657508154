var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-upload",
    _vm._g(
      _vm._b(
        { attrs: { headers: { authorization: _vm.token, appId: _vm.appId } } },
        "a-upload",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("content")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }