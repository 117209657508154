var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.title + "支付信息",
        "mask-closable": false,
        width: "700px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.order,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "发票代码", prop: "invoiceCode" } },
            [
              _c("a-input", {
                attrs: { "max-length": "15", placeholder: "请输入发票代码" },
                model: {
                  value: _vm.order.invoiceCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.order,
                      "invoiceCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "order.invoiceCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "结算方式", prop: "clearingForm" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.order.clearingForm,
                    callback: function ($$v) {
                      _vm.$set(_vm.order, "clearingForm", $$v)
                    },
                    expression: "order.clearingForm",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "挂账付款" } }, [
                    _vm._v("挂账付款"),
                  ]),
                  _c(
                    "a-radio",
                    { attrs: { value: "预付货款发货前结清尾款" } },
                    [_vm._v("预付货款发货前结清尾款")]
                  ),
                  _c("a-radio", { attrs: { value: "全款提货" } }, [
                    _vm._v("全款提货"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "支付方式", prop: "paymentMethod" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.order.paymentMethod,
                    callback: function ($$v) {
                      _vm.$set(_vm.order, "paymentMethod", $$v)
                    },
                    expression: "order.paymentMethod",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "现金" } }, [_vm._v("现金")]),
                  _c("a-radio", { attrs: { value: "微信" } }, [_vm._v("微信")]),
                  _c("a-radio", { attrs: { value: "银行" } }, [_vm._v("银行")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "发票类型", prop: "invoiceType" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.order.invoiceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.order, "invoiceType", $$v)
                    },
                    expression: "order.invoiceType",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "收据" } }, [_vm._v("收据")]),
                  _c("a-radio", { attrs: { value: "增值税专票" } }, [
                    _vm._v("增值税专票"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "开票日期", prop: "invoiceDate" } },
            [
              _c("a-date-picker", {
                attrs: {
                  "show-time": "",
                  type: "date",
                  placeholder: "请选择开票日期",
                },
                model: {
                  value: _vm.order.invoiceDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "invoiceDate", $$v)
                  },
                  expression: "order.invoiceDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "has-feedback": "",
                label: "预付额",
                prop: "advancePayment",
              },
            },
            [
              _c("a-input-number", {
                attrs: {
                  precision: 2,
                  min: 0,
                  max: 99999,
                  placeholder: "请输入预付额",
                },
                model: {
                  value: _vm.order.advancePayment,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "advancePayment", $$v)
                  },
                  expression: "order.advancePayment",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "has-feedback": "",
                label: "尾款金额",
                prop: "balancePayment",
              },
            },
            [
              _c("a-input-number", {
                attrs: {
                  precision: 2,
                  min: 0,
                  max: 99999,
                  placeholder: "请输入尾款金额",
                },
                model: {
                  value: _vm.order.balancePayment,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "balancePayment", $$v)
                  },
                  expression: "order.balancePayment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }