import request from '@/utils/api.request'

/**
 * 分页获取参数信息
 * @param {object} data
 */
export function getParameters(data) {
  return request({
    url: `/parameters`,
    method: 'get',
    params: data,
  })
}

/**
 * 分页获取参数信息
 * @param {object} data
 */
export function getAllParameters(data) {
  return request({
    url: `/parameters/all`,
    method: 'get',
    params: data,
  })
}

export function getAllParametersByParentCode(parentCode) {
  return request({
    url: `/parameters/parents/${parentCode}/nodes`,
    method: 'get',
  })
}

export function getAllHardnessByParentCode(parentCode, value) {
  return request({
    url: `/parameters/parents/${parentCode}/nodes`,
    method: 'get',
    params: {
      parameterValue: `like:${value}`,
    },
  })
}

/**
 * 根据id获取指定的参数信息
 * @param {number} id
 */
export function getParameterById(id) {
  return request({
    url: `/parameters/${id}`,
    method: 'get',
  })
}

/**
 * 获取当前瓶颈工序列表
 */
export function getBottleneckParameter() {
  return request({
    url: `/parameters/bottlenecks`,
    method: 'get',
  })
}

/**
 * 增加参数
 * @param {object} data
 */
export function addParameter(data) {
  return request({
    url: `/parameters`,
    method: 'post',
    data,
  })
}

/**
 * 更新指定id的参数
 * @param {number} id
 * @param {object} data
 */
export function updateParameter(id, data) {
  return request({
    url: `/parameters/${id}`,
    method: 'put',
    data,
  })
}

/**
 * 删除指定id的参数
 * @param {number} id
 */
export function deleteParameter(id) {
  return request({
    url: `/parameters/${id}`,
    method: 'delete',
  })
}
