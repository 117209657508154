<template>
  <a-modal class="modal-container" :visible="true" :title="code + '撤销'" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="撤销备注" prop="comment">
        <a-textarea v-model="form.comment" :max-length="10" placeholder="请输入撤销备注" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { revocationQuotations } from '@/api/offer'

export default {
  props: {
    code: String
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      form: {
        code: this.code,
        comment: '',
        repealDetail: {}
      }
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          revocationQuotations([this.form])
            .then(res => {
              this.$message.success('撤销成功')
              this.$emit('confirm')
            })
            .catch(err => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
