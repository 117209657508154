import request from '@/utils/api.request'

/**
 * 获取月目标统计信息
 * @param {object} data
 */
export function getMonthStatistics(targetId, productType) {
  return request({
    url: `/statistics/targets/mouths/${productType}`,
    method: 'get',
    params: {
      targetId
    }
  })
}

/**
 * 获取周目标统计信息
 * @param {object} data
 */
export function getWeekStatistics(targetId, productType) {
  return request({
    url: `/statistics/targets/weeks/${productType}`,
    method: 'get',
    params: {
      targetId
    }
  })
}


/**
 * 获取日目标统计信息
 * @param {object} data
 */
export function getDayStatistics(targetId, productType) {
  return request({
    url: `/statistics/targets/days/${productType}`,
    method: 'get',
    params: {
      targetId
    }
  })
}



/**
 * 获取目标各类型产品任务 统计明细信息
 * @param {object} data
 */
export function getStatisticsDetail(detailType, data) {
  return request({
    url: `/statistics/targets/details/${detailType}`,
    method: 'get',
    params: data
  })
}

/**
 * 获取自定义时间范围内统计信息
 * @param {object} data
 */
export function getStatisticsDetailByTime(productType, data) {
  return request({
    url: `/statistics/tasks/${productType}`,
    method: 'get',
    params: data
  })
}

/**
 * 获取目标任务变更统计信息
 * @param {object} data
 */
export function getTargetLog(data) {
  return request({
    url: `/statistics/targets/logs`,
    method: 'get',
    params: data
  })
}

/**
 * 获取目标操作明细
 * @param {object} data
 */
export function getTargetLogDetail(targetId, operateType, data) {
  return request({
    url: `/targets/${targetId}/logs/${operateType}`,
    method: 'get',
    params: data
  })
}

/**
 * 导出目标任务变更统计信息
 * @param {object} data
 */
export function exportTargetLogDetail(targetId, operateType, data) {
  return request({
    url: `/targets/${targetId}/logs/${operateType}/exports`,
    method: 'get',
    responseType: 'blob',
    params: data
  })
}


/**
 * 获取周目标统计信息
 * @param {object} data
 */
export function getGroupStatistics(data) {
  return request({
    url: `/statistics/targets/groups`,
    method: 'get',
    params: data
  })
}

/**
 * 获取日目标统计信息
 * @param {object} data
 */
export function getMemberStatistics(data) {
  return request({
    url: `/statistics/targets/members`,
    method: 'get',
    params: data
  })
}



/**
 * 获取自定义时间范围内订单交货率统计信息
 * @param {object} data
 */
export function getOrderStatisticsDetailByTime(productType, data) {
  return request({
    url: `/statistics/orders/${productType}`,
    method: 'get',
    params: data
  })
}