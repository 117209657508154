<template>
  <a-modal class="modal-container" :visible="true" :title="title" :maskClosable="false" @cancel="handleCancel" width="600px">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="tenants" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="企业名称" prop="name">
        <a-input :maxLength="20" v-model.trim="tenants.name" placeholder="请输入企业名称" />
      </a-form-model-item>
      <a-form-model-item label="企业编码" prop="code">
        <a-input :maxLength="20" v-model.trim="tenants.code" placeholder="请输入企业编码" />
      </a-form-model-item>
      <a-form-model-item label="信用代码" prop="creditCode">
        <a-input :maxLength="20" v-model.trim="tenants.creditCode" placeholder="请输入信用代码" />
      </a-form-model-item>
      <a-form-model-item label="企业简称" prop="nickName">
        <a-input :maxLength="20" v-model.trim="tenants.nickName" placeholder="请输入企业简称" />
      </a-form-model-item>
      <a-form-model-item label="企业地址" prop="address">
        <a-input :maxLength="20" v-model.trim="tenants.address" placeholder="请输入企业地址" />
      </a-form-model-item>
      <a-form-model-item label="企业电话" prop="telephone">
        <a-input :maxLength="20" v-model.trim="tenants.telephone" placeholder="请输入企业电话" />
      </a-form-model-item>
      <a-form-model-item label="传真" prop="fax">
        <a-input :maxLength="20" v-model.trim="tenants.fax" placeholder="请输入传真" />
      </a-form-model-item>
      <a-form-model-item label="联系人姓名" prop="contactsInfo.name">
        <a-input :maxLength="20" v-model.trim="tenants.contactsInfo[0].name" placeholder="请输入联系人姓名" />
      </a-form-model-item>
      <a-form-model-item label="联系人电话" prop="contactsInfo.telephone">
        <a-input :maxLength="20" v-model.trim="tenants.contactsInfo[0].telephone" placeholder="请输入联系人电话" />
      </a-form-model-item>
      <a-form-model-item label="联系人地址" prop="contactsInfo.address">
        <a-input :maxLength="20" v-model.trim="tenants.contactsInfo[0].address" placeholder="请输入联系人地址" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updateTenants, addTenants } from '@/api/tenants'
import * as R from 'ramda'
export default {
  props: {
    modalTitle: String,
    record: Object,
    tenantType: String
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      tenants: {
        name: this.record.name || '',
        type: this.tenantType,
        code: this.record.code || '',
        creditCode: this.record.creditCode || '',
        nickName: this.record.nickName || '',
        address: this.record.address || '',
        telephone: this.record.telephone || '',
        fax: this.record.fax || '',
        stampUrl: this.record.stampUrl || '',
        phone: this.record.phone || '',
        contactsInfo:
          this.record.contactsInfo && this.record.contactsInfo.length
            ? this.record.contactsInfo
            : [
                {
                  name: '',
                  telephone: '',
                  address: ''
                }
              ]
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择企业类型',
            trigger: 'change'
          }
        ]
      }
    }
  },
  mounted() {
    this.title =
      this.modalTitle + (this.tenantType === 'SPL' ? '供应商' : '客户')
  },
  computed: {
    // 计算属性的 getter
    isAdd: function() {
      return this.modalTitle === '新增'
    },
    isUpdate: function() {
      return this.modalTitle === '更新'
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.add(this.tenants)
          } else if (this.isUpdate) {
            this.update(this.tenants)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    update(userFormData) {
      this.loading = true
      updateTenants(this.record.code, userFormData)
        .then(res => {
          this.$emit('confirm')
          this.$message.success('更新成功！')
        })
        .catch(reason => {
          this.loading = false
        })
    },
    add(userFormData) {
      this.loading = true
      let submitData = R.clone(userFormData)
      submitData.id = null
      addTenants(submitData)
        .then(res => {
          this.$message.success('新增成功')
          this.$emit('confirm')
        })
        .catch(reason => {
          this.loading = false
          console.log('处理失败的 promise (' + reason + ')')
        })
    }
  }
}
</script>

<style scoped>
.modal-container >>> .ant-modal-body {
  max-height: 600px;
  overflow-y: scroll;
}
/deep/ .ant-form-item {
  margin-bottom: 8px;
}
</style>
