var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("a-table", {
        staticClass: "users-table",
        attrs: {
          "row-key": "id",
          bordered: "",
          columns: _vm.costAccountingColumns,
          "data-source": _vm.costCountingList,
          loading: _vm.loading,
          pagination: _vm.pagination,
          scroll: { x: 4000 },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.dataIndex
                              )
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                  column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "190px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.dataIndex
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.dataIndex
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "costAccountCode",
            fn: function (text, column) {
              return _c(
                "div",
                {},
                [
                  column.inquiryItem.status === "FIN"
                    ? _c(
                        "a-tooltip",
                        { attrs: { placement: "topLeft" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("span", [_vm._v("点击查看成本明细")]),
                          ]),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCheckCostingDetail(
                                    column.code
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(text))]
                          ),
                        ],
                        2
                      )
                    : column.inquiryItem.status === "CAC" ||
                      column.inquiryItem.status === "WCA"
                    ? _c(
                        "a-tooltip",
                        { attrs: { placement: "topLeft" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("span", [_vm._v("点击进行核价")]),
                          ]),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEditCostingDetail(
                                    column.code,
                                    column
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(text))]
                          ),
                        ],
                        2
                      )
                    : _c("span", [_vm._v(_vm._s(text))]),
                ],
                1
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  text === "WCA"
                    ? _c("a-tag", { attrs: { color: "blue" } }, [
                        _vm._v("待核价"),
                      ])
                    : _vm._e(),
                  text === "CAC"
                    ? _c("a-tag", { attrs: { color: "pink" } }, [
                        _vm._v("核价中"),
                      ])
                    : _vm._e(),
                  text === "CNL"
                    ? _c("a-tag", { attrs: { color: "red" } }, [
                        _vm._v("已取消"),
                      ])
                    : _vm._e(),
                  text === "FIN"
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("核价完成"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "unitCompanyResidueRate",
            fn: function (text) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text * 100 + "%" : ""))]),
              ])
            },
          },
          {
            key: "outsourcingCostRate",
            fn: function (text) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text * 100 + "%" : ""))]),
              ])
            },
          },
          {
            key: "outProcessingCostRate",
            fn: function (text) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text * 100 + "%" : ""))]),
              ])
            },
          },
          {
            key: "materialCostRate",
            fn: function (text) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text * 100 + "%" : ""))]),
              ])
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "operate",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-user" },
                [
                  column.inquiryItem.status === "FIN"
                    ? _c(
                        "a-dropdown",
                        [
                          _c(
                            "a-menu",
                            {
                              staticClass: "ant-dropdown-link",
                              attrs: { slot: "overlay" },
                              slot: "overlay",
                            },
                            [
                              column.inquiryItem.status === "FIN" &&
                              column.inquiryStatus === "CAC"
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdateCostingDetail(
                                            column.code,
                                            column
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("更新核算明细")]
                                  )
                                : _vm._e(),
                              column.inquiryItem.status === "FIN"
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCheckCostingDetail(
                                            column.code
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("查看核算明细")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "a",
                            { staticClass: "user-btn" },
                            [
                              _vm._v(" 操作 "),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c(
        "el-dropdown",
        {
          staticClass: "export-record",
          on: { command: _vm.handleExportRecordCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出成本核算明细记录")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选记录"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.costDetailModalVisible
        ? _c("CostDetailModal", {
            attrs: {
              code: _vm.costDetailCode,
              type: _vm.costDetailType,
              record: _vm.costDetailRecord,
            },
            on: {
              confirm: _vm.handleCostDetailConfirm,
              cancel: _vm.handleCostDetailCancel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }