var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        "body-style": _vm.bodyStyle,
        visible: true,
        title: _vm.title,
        "mask-closable": false,
        width: "800px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c("a-form-model-item", { attrs: { label: "任务号" } }, [
            _c("span", [_vm._v(_vm._s(_vm.record.taskCode))]),
          ]),
          _c("a-form-model-item", { attrs: { label: "缺陷产生日期" } }, [
            _c("span", [_vm._v(_vm._s(_vm.record.generateTime))]),
          ]),
          _c("a-form-model-item", { attrs: { label: "产品名称" } }, [
            _c("span", [_vm._v(_vm._s(_vm.record.productName))]),
          ]),
          _c("a-form-model-item", { attrs: { label: "图号" } }, [
            _c("span", [_vm._v(_vm._s(_vm.record.drawingNum))]),
          ]),
          _c("a-form-model-item", { attrs: { label: "规格" } }, [
            _c("span", [_vm._v(_vm._s(_vm.record.specification))]),
          ]),
          _c("a-form-model-item", { attrs: { label: "客户名称" } }, [
            _c("span", [_vm._v(_vm._s(_vm.record.client))]),
          ]),
          _vm.record.defeatType !== "TH"
            ? _c("a-form-model-item", { attrs: { label: "检验员" } }, [
                _c("span", [_vm._v(_vm._s(_vm.record.checker))]),
              ])
            : _vm._e(),
          _vm.record.defeatType === "FG" || _vm.record.defeatType === "CC"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "不合格详情" } },
                [
                  _c("a-input", {
                    attrs: { disabled: _vm.ifCheck },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("defeatDesc")
                      },
                    },
                    model: {
                      value: _vm.record.defeatDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "defeatDesc", $$v)
                      },
                      expression: "record.defeatDesc",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "FG"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "返工要求" } },
                [
                  _c("a-input", {
                    attrs: { disabled: _vm.ifCheck },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("defeatDesc")
                      },
                    },
                    model: {
                      value: _vm.record.defeatDemand,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "defeatDemand", $$v)
                      },
                      expression: "record.defeatDemand",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "BF"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "报废品描述" } },
                [
                  _c("a-input", {
                    attrs: { disabled: _vm.ifCheck },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("defeatDesc")
                      },
                    },
                    model: {
                      value: _vm.record.defeatDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "defeatDesc", $$v)
                      },
                      expression: "record.defeatDesc",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "BF"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "材料规格" } },
                [
                  _c("a-input", {
                    attrs: { disabled: _vm.ifCheck },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("contactRecord")
                      },
                    },
                    model: {
                      value: _vm.record.contactRecord,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "contactRecord", $$v)
                      },
                      expression: "record.contactRecord",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "CC"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "顾客意见" } },
                [
                  _c("a-input", {
                    attrs: { disabled: _vm.ifCheck },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("contactRecord")
                      },
                    },
                    model: {
                      value: _vm.record.contactRecord,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "contactRecord", $$v)
                      },
                      expression: "record.contactRecord",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "缺陷数量" } },
            [
              _c("a-input", {
                attrs: { disabled: _vm.ifCheck },
                on: {
                  blur: function ($event) {
                    return _vm.handleUpdateBaseInfo("productAmount")
                  },
                },
                model: {
                  value: _vm.record.productAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.record, "productAmount", $$v)
                  },
                  expression: "record.productAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "缺陷详情" } },
            [
              _c(
                "c-upload",
                {
                  attrs: {
                    disabled: _vm.ifCheck,
                    action:
                      _vm.baseURL + "qualities/" + _vm.record.id + "/files/QX",
                    "file-list": _vm.form.defeatDetailInfo,
                    accept: "image/*, .pdf",
                    "before-upload": _vm.beforeUpload,
                  },
                  on: { change: _vm.handleDefectUploadChange },
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        slot: "content",
                        disabled: _vm.ifCheck || _vm.defeatOutLength,
                      },
                      slot: "content",
                    },
                    [
                      _c("a-icon", { attrs: { type: "upload" } }),
                      _vm._v("点击上传 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.record.defeatType === "FG"
            ? [
                _c(
                  "a-form-model-item",
                  { attrs: { label: "返工人" } },
                  [
                    _c(
                      "a-select",
                      {
                        attrs: {
                          disabled: _vm.ifCheck,
                          mode: "multiple",
                          "show-search": "",
                          placeholder: "请选择返工人",
                          "option-filter-prop": "children",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleUserSelectChange("FG")
                          },
                        },
                        model: {
                          value: _vm.form.repairUserInfo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "repairUserInfo", $$v)
                          },
                          expression: "form.repairUserInfo",
                        },
                      },
                      _vm._l(_vm.currentUserList, function (user) {
                        return _c(
                          "a-select-option",
                          { key: user.id, attrs: { value: user.id } },
                          [
                            _vm._v(
                              _vm._s(user.realName) +
                                "(" +
                                _vm._s(user.uid) +
                                ")"
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "返工工序" } },
                  [
                    _c(
                      "a-select",
                      {
                        attrs: {
                          disabled: _vm.ifCheck,
                          mode: "multiple",
                          "show-search": "",
                          placeholder: "请选择工序",
                          "option-filter-prop": "children",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleOperationChange("FG")
                          },
                        },
                        model: {
                          value: _vm.form.repairOperationInfo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "repairOperationInfo", $$v)
                          },
                          expression: "form.repairOperationInfo",
                        },
                      },
                      _vm._l(_vm.currentOperationList, function (operation) {
                        return _c(
                          "a-select-option",
                          { key: operation.id, attrs: { value: operation.id } },
                          [
                            _vm._v(
                              _vm._s(operation.operationName) +
                                "(" +
                                _vm._s(operation.operationCode) +
                                ")"
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "返工效果" } },
                  [
                    _c(
                      "a-radio-group",
                      {
                        attrs: { disabled: _vm.ifCheck, name: "radioGroup" },
                        on: {
                          change: function ($event) {
                            return _vm.handleUpdateBaseInfo("repairEffect")
                          },
                        },
                        model: {
                          value: _vm.record.repairEffect,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "repairEffect", $$v)
                          },
                          expression: "record.repairEffect",
                        },
                      },
                      [
                        _c("a-radio", { attrs: { value: "OK" } }, [
                          _vm._v("合格"),
                        ]),
                        _c("a-radio", { attrs: { value: "BF" } }, [
                          _vm._v("报废"),
                        ]),
                        _c("a-radio", { attrs: { value: "CC" } }, [
                          _vm._v("超差"),
                        ]),
                        _c("a-radio", { attrs: { value: "RE" } }, [
                          _vm._v("再次返修"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "缺陷根本原因" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    disabled: _vm.ifCheck,
                    mode: "multiple",
                    "show-search": "",
                    placeholder: "请选择缺陷根本原因",
                    "option-filter-prop": "children",
                  },
                  on: { change: _vm.handleReasonInfoChange },
                  model: {
                    value: _vm.form.defeatReasonInfo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "defeatReasonInfo", $$v)
                    },
                    expression: "form.defeatReasonInfo",
                  },
                },
                _vm._l(_vm.allDefeatReasons, function (reason) {
                  return _c(
                    "a-select-option",
                    { key: reason.id, attrs: { value: reason.id } },
                    [_vm._v(_vm._s(reason.parameterValue))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.record.defeatType === "TH"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "客户反馈描述" } },
                [
                  _c("a-input", {
                    attrs: { disabled: _vm.ifCheck },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("contactRecord")
                      },
                    },
                    model: {
                      value: _vm.record.contactRecord,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "contactRecord", $$v)
                      },
                      expression: "record.contactRecord",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "TH"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "核实情况" } },
                [
                  _c("a-input", {
                    attrs: { disabled: _vm.ifCheck },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("defeatDesc")
                      },
                    },
                    model: {
                      value: _vm.record.defeatDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "defeatDesc", $$v)
                      },
                      expression: "record.defeatDesc",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "TH"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "处置方式", prop: "repairEffect" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { disabled: _vm.ifCheck },
                      on: {
                        change: function ($event) {
                          return _vm.handleUpdateBaseInfo("repairEffect")
                        },
                      },
                      model: {
                        value: _vm.record.repairEffect,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "repairEffect", $$v)
                        },
                        expression: "record.repairEffect",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "FX" } }, [
                        _vm._v("返修"),
                      ]),
                      _c("a-select-option", { attrs: { value: "BT" } }, [
                        _vm._v("报废补投"),
                      ]),
                      _c("a-select-option", { attrs: { value: "TH" } }, [
                        _vm._v("退回客户"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "TH" && _vm.record.repairEffect === "FX"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "返修要求" } },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    attrs: { disabled: _vm.ifCheck },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("defeatDemand")
                      },
                    },
                    model: {
                      value: _vm.record.defeatDemand,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "defeatDemand", $$v)
                      },
                      expression: "record.defeatDemand",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "TH" && _vm.record.repairEffect === "FX"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "返修工序" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        disabled: _vm.ifCheck,
                        "show-search": "",
                        mode: "multiple",
                        placeholder: "请选择返修工序",
                        "option-filter-prop": "children",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleOperationChange("FG")
                        },
                      },
                      model: {
                        value: _vm.form.repairOperationInfo,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "repairOperationInfo", $$v)
                        },
                        expression: "form.repairOperationInfo",
                      },
                    },
                    _vm._l(_vm.currentOperationList, function (operation) {
                      return _c(
                        "a-select-option",
                        { key: operation.id, attrs: { value: operation.id } },
                        [
                          _vm._v(
                            _vm._s(operation.operationName) +
                              "(" +
                              _vm._s(operation.operationCode) +
                              ")"
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "TH" && _vm.record.repairEffect === "FX"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "返工效果" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { disabled: _vm.ifCheck, name: "radioGroup" },
                      on: {
                        change: function ($event) {
                          return _vm.handleUpdateBaseInfo("actionEffect")
                        },
                      },
                      model: {
                        value: _vm.record.actionEffect,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "actionEffect", $$v)
                        },
                        expression: "record.actionEffect",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "OK" } }, [
                        _vm._v("合格"),
                      ]),
                      _c("a-radio", { attrs: { value: "BF" } }, [
                        _vm._v("报废"),
                      ]),
                      _c("a-radio", { attrs: { value: "CC" } }, [
                        _vm._v("超差"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "CC"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "客户" } },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    attrs: { disabled: _vm.ifCheck },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("relatedClient")
                      },
                    },
                    model: {
                      value: _vm.record.relatedClient,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "relatedClient", $$v)
                      },
                      expression: "record.relatedClient",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.record.defeatType === "CC"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "沟通人" } },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    attrs: { disabled: _vm.ifCheck },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("relatedStaff")
                      },
                    },
                    model: {
                      value: _vm.record.relatedStaff,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "relatedStaff", $$v)
                      },
                      expression: "record.relatedStaff",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "责任人" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    disabled: _vm.ifCheck,
                    mode: "multiple",
                    "show-search": "",
                    placeholder: "请选择缺陷责任人",
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterUser,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleUserSelectChange("ZR")
                    },
                  },
                  model: {
                    value: _vm.form.responsibleUserInfo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "responsibleUserInfo", $$v)
                    },
                    expression: "form.responsibleUserInfo",
                  },
                },
                _vm._l(_vm.currentUserList, function (user) {
                  return _c(
                    "a-select-option",
                    { key: user.id, attrs: { value: user.id } },
                    [
                      _vm._v(
                        _vm._s(user.realName) + "(" + _vm._s(user.uid) + ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "责任工序" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    disabled: _vm.ifCheck,
                    mode: "multiple",
                    "show-search": "",
                    placeholder: "请选择缺陷责任工序",
                    "option-filter-prop": "children",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleOperationChange("ZR")
                    },
                  },
                  model: {
                    value: _vm.form.responsibleOperationInfo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "responsibleOperationInfo", $$v)
                    },
                    expression: "form.responsibleOperationInfo",
                  },
                },
                _vm._l(_vm.currentOperationList, function (operation) {
                  return _c(
                    "a-select-option",
                    { key: operation.id, attrs: { value: operation.id } },
                    [
                      _vm._v(
                        _vm._s(operation.operationName) +
                          "(" +
                          _vm._s(operation.operationCode) +
                          ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.record.defeatType === "CC"
            ? [
                _c(
                  "a-form-model-item",
                  { attrs: { label: "客户沟通记录" } },
                  [
                    _c("a-textarea", {
                      attrs: { disabled: _vm.ifCheck, "max-length": 1000 },
                      on: {
                        blur: function ($event) {
                          return _vm.handleUpdateBaseInfo("contactRecord")
                        },
                      },
                      model: {
                        value: _vm.record.contactRecord,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "contactRecord", $$v)
                        },
                        expression: "record.contactRecord",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否需要采取预防措施" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { disabled: _vm.ifCheck, name: "radioGroup" },
                  on: {
                    change: function ($event) {
                      return _vm.handleUpdateBaseInfo("hasAction")
                    },
                  },
                  model: {
                    value: _vm.record.hasAction,
                    callback: function ($$v) {
                      _vm.$set(_vm.record, "hasAction", $$v)
                    },
                    expression: "record.hasAction",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "Y" } }, [_vm._v("需要")]),
                  _c("a-radio", { attrs: { value: "N" } }, [_vm._v("不需要")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.record.hasAction === "Y"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "预防措施详情" } },
                [
                  _c(
                    "c-upload",
                    {
                      attrs: {
                        disabled: _vm.ifCheck,
                        action:
                          _vm.baseURL +
                          "qualities/" +
                          _vm.record.id +
                          "/files/CS",
                        "file-list": _vm.form.actionDetailInfo,
                        accept: "image/*, .pdf",
                        "before-upload": _vm.beforeUpload,
                      },
                      on: { change: _vm.handleActionUploadChange },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            slot: "content",
                            disabled: _vm.ifCheck || _vm.actionOutLength,
                          },
                          slot: "content",
                        },
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v("点击上传 "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.record.hasAction === "Y"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "措施负责人" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        disabled: _vm.ifCheck,
                        "show-search": "",
                        placeholder: "请选择措施负责人",
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterUser,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleUpdateBaseInfo("actionChargerId")
                        },
                      },
                      model: {
                        value: _vm.record.actionChargerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "actionChargerId", $$v)
                        },
                        expression: "record.actionChargerId",
                      },
                    },
                    _vm._l(_vm.currentUserList, function (user) {
                      return _c(
                        "a-select-option",
                        { key: user.id, attrs: { value: user.id } },
                        [
                          _vm._v(
                            _vm._s(user.realName) + "(" + _vm._s(user.uid) + ")"
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.record.hasAction === "Y"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "措施效果" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.ifCheck,
                      "max-length": 20,
                      placeholder: "请输入措施效果",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.handleUpdateBaseInfo("actionEffect")
                      },
                    },
                    model: {
                      value: _vm.record.actionEffect,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "actionEffect", $$v)
                      },
                      expression: "record.actionEffect",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注" } },
            [
              _c("a-textarea", {
                attrs: {
                  disabled: _vm.ifCheck,
                  "max-length": 1000,
                  placeholder: "请输入备注",
                },
                on: {
                  blur: function ($event) {
                    return _vm.handleUpdateBaseInfo("comment")
                  },
                },
                model: {
                  value: _vm.record.comment,
                  callback: function ($$v) {
                    _vm.$set(_vm.record, "comment", $$v)
                  },
                  expression: "record.comment",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }