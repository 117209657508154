var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: "撤销确认",
        maskClosable: false,
        width: "70vw",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [
            _vm._v(" 重置 "),
          ]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [
            _vm._v(" 取消 "),
          ]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "操作类型", prop: "oprType" } },
            [
              _c("a-radio-group", {
                attrs: {
                  options: [
                    { label: "全部撤销", value: 1 },
                    { label: "部分撤销", value: 2 },
                  ],
                },
                model: {
                  value: _vm.form.oprType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oprType", $$v)
                  },
                  expression: "form.oprType",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "撤销原因", prop: "changeReason" } },
            [
              _c("a-textarea", {
                staticStyle: { width: "460px" },
                attrs: {
                  placeholder: "请输入撤销原因",
                  "auto-size": { minRows: 3, maxRows: 5 },
                },
                model: {
                  value: _vm.form.changeReason,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "changeReason",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.changeReason",
                },
              }),
            ],
            1
          ),
          _vm.form.oprType === 2
            ? _c("a-table", {
                attrs: {
                  "row-key": "taskCode",
                  "data-source": _vm.productTakeOutItemList,
                  columns: _vm.columns,
                  loading: _vm.dataLoading,
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange,
                  },
                  bordered: "",
                  scroll: { x: 1200 },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }