<template>
  <a-modal class="modal-container" :visible="true" :title="code + '报出'" :mask-closable="false" width="600px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleReset">重置</a-button>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
    </template>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="税率" prop="taxRate">
        <a-input-number
          v-model="form.taxRate"
          size="small"
          placeholder="请输入税率"
          :min="0"
          :max="1"
          :precision="2"
          style="width:200px;min-height: 30px;line-height:30px"
        />
      </a-form-model-item>
      <a-form-model-item label="付款方式" prop="payType">
        <a-radio-group v-model="form.payType">
          <a-radio value="CSH">现金</a-radio>
          <a-radio value="BNK">银行转账</a-radio>
          <a-radio value="CRD">挂账</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="交货方式" prop="deliveryType">
        <a-radio-group v-model="form.deliveryType">
          <a-radio value="SND">送货</a-radio>
          <a-radio value="SLF">自取</a-radio>
          <a-radio value="EXP">快递</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { putQuotations } from '@/api/offer'

export default {
  props: {
    code: String
  },
  data() {
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      loading: false,
      form: {
        code: this.code,
        taxRate: '0.13',
        payType: 'CSH',
        deliveryType: 'SND'
      },
      rules: {
        code: [
          {
            required: true,
            message: '请输入报价单号',
            trigger: 'blur'
          }
        ],
        taxRate: [
          {
            required: true,
            message: '请输入税率',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          putQuotations([this.form])
            .then(res => {
              this.$message.success('报价成功')
              this.$emit('confirm')
            })
            .catch(err => {
              this.$message.error(err.message)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
