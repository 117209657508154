export const parameterColumns = [
  {
    title: '参数编码',
    dataIndex: 'parameterCode',
    key: 'parameterCode',
    align: 'center',
    searchType: 'text',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '参数类型',
    dataIndex: 'parameterType',
    key: 'parameterType',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'parameterType'
    },
    filters: [
      {
        text: '根参数',
        value: 'ROT'
      },
      {
        text: '父参数',
        value: 'PAR'
      },
      {
        text: '子参数',
        value: 'NOD'
      },
      {
        text: '其他',
        value: 'OTH'
      }
    ]
  },
  {
    title: '参数名称',
    dataIndex: 'parameterName',
    key: 'parameterName',
    searchType: 'text',
    sorter: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '参数值',
    dataIndex: 'parameterValue',
    key: 'parameterValue',
    searchType: 'text',
    sorter: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '参数描述',
    dataIndex: 'parameterDesc',
    key: 'parameterDesc',
    searchType: 'text',
    sorter: true,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    }
  },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    scopedSlots: {
      customRender: 'operation'
    }
  }
]
