<template>
    <div class="card-container">
      <template v-if="cardList.length">
        <div id="craft-card-print">
          <div v-for="(item, index) in cardList" :key="index">
            <div style="page-break-after:always">
              <div class="title">
                不合格品返工处置通知单
              </div>
              <div class="version">JL-8.7-01</div>
              <p class="num">NO:{{ item.qualityBaseDTO.defeatReceiptNumber }}</p>
              <div class="base-info">
                  <div class="row-1">
                      <div class="item left">
                          <div class="label">任务号</div>
                          <div class="content">{{item.qualityBaseDTO.taskCode}}</div>
                      </div>
                      <div class="item right">
                          <div class="label">责任工序</div>
                          <div class="content">{{item.responsibleOperationInfo}}</div>
                      </div>
                  </div>
                  <div class="row-1 row-bottom">
                      <div class="item left">
                          <div class="label">产品名称</div>
                          <div class="content">{{ item.qualityBaseDTO.productName }}</div>
                      </div>
                      <div class="item right">
                          <div class="label">责任人</div>
                          <div class="content">{{ item.responsibleUserInfo }}</div>
                      </div>
                  </div>
                  <div class="row-2 row-bottom">
                      <div class="item left">
                          <div class="label">图号</div>
                          <div class="content">{{ item.qualityBaseDTO.drawingNum }}</div>
                      </div>
                      <div class="item middle">
                          <div class="label">交检数量</div>
                          <div class="content">{{ item.qualityBaseDTO.checkNumber }}</div>
                      </div>
                      <div class="item right">
                          <div class="label">不合格数</div>
                          <div class="content">{{ item.qualityBaseDTO.productAmount }}</div>
                      </div>
                  </div>
                  <div class="row-column row-bottom">
                      <div class="label">不合格原因类别</div>
                      <div class="content">{{ item.defeatReasonInfo }}</div>
                  </div>
                  <div class="hand-check row-bottom">
                      <div class="label">不合格<p>详情</p></div>
                      <div class="content">
                          <p>{{ item.qualityBaseDTO.defeatDesc }}</p>
                          <div class="bottom-check">
                              <p>检验员:{{ item.qualityBaseDTO.checker }}</p>
                              <p>日期: {{ item.qualityBaseDTO.generateTime }}</p>
                          </div>
                      </div>
                  </div>
                  <div class="hand-check row-bottom">
                      <div class="label">不合格处<p>置</p></div>
                      <div class="content content-row">
                        <div class="content-row-1">
                          <div class="label">返工要求</div>
                          <div class="context">{{ item.qualityBaseDTO.defeatDemand }}</div>
                        </div>
                        <div class="content-row-1">
                          <div class="label">返工工序</div>
                          <div class="context">{{ item.repairOperationInfo }}</div>
                        </div>
                        <div class="content-row-1">
                          <div class="label noborder">返工人</div>
                          <div class="context noborder">{{ item.repairUserInfo }}</div>
                        </div>
                      </div>
                  </div>
                  <div class="hand-check row-bottom">
                      <div class="label">处置效果</div>
                      <div class="content">
                        <p>{{ item.qualityBaseDTO.repairEffect === 'OK' ? '合格' : '再次返修' }}</p>
                          <div class="bottom-check">
                              <p>检验员: {{ item.qualityBaseDTO.checker }}</p>
                              <p>日期: </p>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div id="craft-card-print">
          <div style="page-break-after:always">
            <div class="title">
              不合格品返工处置通知单
            </div>
            <div class="version">JL-8.7-01</div>
            <p class="num">NO:{{ data.qualityBaseDTO.defeatReceiptNumber }}</p>
            <div class="base-info">
                <div class="row-1">
                    <div class="item left">
                        <div class="label">任务号</div>
                        <div class="content">{{data.qualityBaseDTO.taskCode}}</div>
                    </div>
                    <div class="item right">
                        <div class="label">责任工序</div>
                        <div class="content">{{responsibleOperationInfo}}</div>
                    </div>
                </div>
                <div class="row-1 row-bottom">
                    <div class="item left">
                        <div class="label">产品名称</div>
                        <div class="content">{{ data.qualityBaseDTO.productName }}</div>
                    </div>
                    <div class="item right">
                        <div class="label">责任人</div>
                        <div class="content">{{ responsibleUserInfo }}</div>
                    </div>
                </div>
                <div class="row-2 row-bottom">
                    <div class="item left">
                        <div class="label">图号</div>
                        <div class="content">{{ data.qualityBaseDTO.drawingNum }}</div>
                    </div>
                    <div class="item middle">
                        <div class="label">交检数量</div>
                        <div class="content">{{ data.qualityBaseDTO.checkNumber }}</div>
                    </div>
                    <div class="item right">
                        <div class="label">不合格数</div>
                        <div class="content">{{ data.qualityBaseDTO.productAmount }}</div>
                    </div>
                </div>
                <div class="row-column row-bottom">
                    <div class="label">不合格原因类别</div>
                    <div class="content">{{ defeatReasonInfo }}</div>
                </div>
                <div class="hand-check row-bottom">
                    <div class="label">不合格<p>详情</p></div>
                    <div class="content">
                        <p>{{ data.qualityBaseDTO.defeatDesc }}</p>
                        <div class="bottom-check">
                            <p>检验员:{{ data.qualityBaseDTO.checker }}</p>
                            <p>日期: {{ data.qualityBaseDTO.generateTime }}</p>
                        </div>
                    </div>
                </div>
                <div class="hand-check row-bottom">
                    <div class="label">不合格处<p>置</p></div>
                    <div class="content content-row">
                      <div class="content-row-1">
                        <div class="label">返工要求</div>
                        <div class="context">{{ data.qualityBaseDTO.defeatDemand }}</div>
                      </div>
                      <div class="content-row-1">
                        <div class="label">返工工序</div>
                        <div class="context">{{ repairOperationInfo }}</div>
                      </div>
                      <div class="content-row-1">
                        <div class="label noborder">返工人</div>
                        <div class="context noborder">{{ repairUserInfo }}</div>
                      </div>
                    </div>
                </div>
                <div class="hand-check row-bottom">
                    <div class="label">处置效果</div>
                    <div class="content">
                      <p>{{ data.qualityBaseDTO.repairEffect === 'OK' ? '合格' : '再次返修' }}</p>
                        <div class="bottom-check">
                            <p>检验员:{{ data.qualityBaseDTO.checker }}</p>
                            <p>日期: </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </template>
      <div class="footer">
        <a-button v-print="'craft-card-print'" type="primary" class="btn" block>打印</a-button>
      </div>
    </div>
  </template>
  <script>
  import {
    getQualitiesReceipt,
    getBatchQualitiesReceipt
  } from '@/api/qualities'
  

  export default {
    data() {
      return {
        data: [],
        responsibleOperationInfo: '',
        responsibleUserInfo: '',
        defeatReasonInfo: '',
        repairOperationInfo: '',
        repairUserInfo: '',
        cardList: []
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        const ids = this.$route.query.ids.split(',')
        if(ids.length <= 1) {
          getQualitiesReceipt(this.$route.query.ids).then((res) => {
            if (res.data) {
               this.data = res.data
               if(this.data.qualityDetailDTO.responsibleOperationInfo &&this.data.qualityDetailDTO.responsibleOperationInfo.length ) {
                this.data.qualityDetailDTO.responsibleOperationInfo.map((item) => {
                  if(this.responsibleOperationInfo) {
                    this.responsibleOperationInfo += ','
                  }
                  this.responsibleOperationInfo += item.operationName + ' '
                })
              }
              if(this.data.qualityDetailDTO.responsibleUserInfo &&this.data.qualityDetailDTO.responsibleUserInfo.length ) {
                this.data.qualityDetailDTO.responsibleUserInfo.map((item) => {
                  if(this.responsibleUserInfo) {
                    this.responsibleUserInfo += ','
                  }
                  this.responsibleUserInfo += item.realName + ' '
                })
              }
              if(this.data.qualityDetailDTO.defeatReasonInfo &&this.data.qualityDetailDTO.defeatReasonInfo.length ) {
                this.data.qualityDetailDTO.defeatReasonInfo.map((item) => {
                  if(this.defeatReasonInfo) {
                    this.defeatReasonInfo += ','
                  }
                  this.defeatReasonInfo += item.parameterName + ' '
                })
              }
              if(this.data.qualityDetailDTO.repairOperationInfo &&this.data.qualityDetailDTO.repairOperationInfo.length ) {
                this.data.qualityDetailDTO.repairOperationInfo.map((item) => {
                  if(this.repairOperationInfo) {
                    this.repairOperationInfo += ','
                  }
                  this.repairOperationInfo += item.operationName + ' '
                })
              }
              if(this.data.qualityDetailDTO.repairUserInfo &&this.data.qualityDetailDTO.repairUserInfo.length ) {
                this.data.qualityDetailDTO.repairUserInfo.map((item) => {
                  if(this.repairUserInfo) {
                    this.repairUserInfo += ','
                  }
                  this.repairUserInfo += item.realName + ' '
                })
              }
            } else {
              this.$message.error('获取数据失败')
            }
          })
        } else {
          getBatchQualitiesReceipt({ids: this.$route.query.ids}).then((res) => {
            this.cardList = res.data
            this.cardList.map((card) => {
              if(card.qualityDetailDTO.responsibleOperationInfo &&card.qualityDetailDTO.responsibleOperationInfo.length ) {
                card.responsibleOperationInfo = ''
                card.qualityDetailDTO.responsibleOperationInfo.map((item) => {
                  if(card.responsibleOperationInfo) {
                    card.responsibleOperationInfo += ','
                  }
                  card.responsibleOperationInfo += item.operationName + ' '
                })
              }
              if(card.qualityDetailDTO.responsibleUserInfo &&card.qualityDetailDTO.responsibleUserInfo.length ) {
                card.responsibleUserInfo = ''
                card.qualityDetailDTO.responsibleUserInfo.map((item) => {
                  if(card.responsibleUserInfo) {
                    card.responsibleUserInfo += ','
                  }
                  card.responsibleUserInfo += item.realName + ' '
                })
              }
              if(card.qualityDetailDTO.defeatReasonInfo &&card.qualityDetailDTO.defeatReasonInfo.length ) {
                card.defeatReasonInfo = ''
                card.qualityDetailDTO.defeatReasonInfo.map((item) => {
                  if(card.defeatReasonInfo) {
                    card.defeatReasonInfo += ','
                  }
                  card.defeatReasonInfo += item.parameterName + ' '
                })
              }
              if(card.qualityDetailDTO.repairOperationInfo &&card.qualityDetailDTO.repairOperationInfo.length ) {
                card.repairOperationInfo = ''
                card.qualityDetailDTO.repairOperationInfo.map((item) => {
                  if(card.repairOperationInfo) {
                    card.repairOperationInfo += ','
                  }
                  card.repairOperationInfo += item.operationName + ' '
                })
              }
              if(card.qualityDetailDTO.repairUserInfo &&card.qualityDetailDTO.repairUserInfo.length ) {
                card.repairUserInfo = ''
                card.qualityDetailDTO.repairUserInfo.map((item) => {
                  if(card.repairUserInfo) {
                    card.repairUserInfo += ','
                  }
                  card.repairUserInfo += item.realName + ' '
                })
              }
            })
          })
        }
      }
    },
  }
  </script>
  <style scoped lang="scss">
  .card-container {
    width: 80%;
    margin: auto;
    position: relative;
  }
  
  .page {
    margin-bottom: 100px;
  }
  .title {
    height: 50px;
    display: flex;
    justify-content: center;
    font-family: SimHei;
    font-size: 30px;
		font-weight: bold;
    line-height: 50px;
    margin-bottom: 8px;
    color: black;
  }
  
  .card-title >>> .ant-descriptions-item-label {
    font-family: SimHei;
    font-size: 20px;
  }
  .version {
  font-size: 30px;
  position: absolute;
  top: 0;
  left: 0;
  color: black;
}
  
  .amount-font {
    font-family: SimHei;
    font-size: 17px;
    margin: 0;
  }
  
  /deep/ .ant-table {
    padding: 10px 0px;
    color: black;
  }
  
  /deep/ .ant-descriptions-item-label {
    color: black;
  }
  
  /deep/ .ant-descriptions-item-content {
    color: black;
  }
  
  /deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
    text-align: center;
    border: 1px solid black;
    padding: 1px 2px;
  }
  
  /deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
    text-align: center;
    border: 1px solid black;
    font-family: SimHei;
    font-size: 20px;
    font-weight: bold;
    padding: 1px 2px;
  }
  
  /deep/ .ant-table-thead th {
    padding: 2px 2px;
    text-align: center;
    border: 1px solid black;
    color: black;
  }
  
  /deep/ .ant-table-tbody {
    font-size: 20px;
  }
  
  /deep/ .ant-table-tbody td {
    padding: 0px 0px;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    text-align: center;
  }
  
  /deep/ .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid black;
  }
  
  /deep/ .ant-descriptions-bordered .ant-descriptions-view {
    border: 0px;
  }
  
  /deep/ .ant-table-row {
    height: 43px !important;
    font-family: SimHei;
    font-weight: bold;
  }
  /deep/ .ant-descriptions-item-label {
    font-family: SimHei;
    font-size: 21px;
    font-weight: bold;
  }
  /deep/ .ant-table-thead {
    font-family: SimHei;
    font-size: 18px;
    font-weight: bold !important;
    color: black;
  }
  /deep/ .ant-table-thead > tr > th {
    font-weight: bold !important;
    color: black;
  }
  
  /deep/ .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    border: 1px solid black;
  }
  
  .footer {
    width: 100%;
		text-align: center;
		margin-top: 30px;
  }

  .base-info {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
		color: #000;
    .row-1 {
        min-height: 40px;
        border: 2px solid #000;
        display: flex;
        word-break: break-all;
        .left {
            flex: 1;
            display: flex;
            .label {
                width: 55%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 2px solid #000;
            }
            .content {
                width: 45%;
                text-align: center;
                line-height: 38px;
                border-right: 2px solid #000;
            }
        }
        .right {
            flex: 1.8;
            display: flex;
            .label {
                width: 20%;
                text-align: center;
                line-height: 38px;
                border-right: 2px solid #000;
            }
            .content {
                width: 80%;
                text-align: center;
                line-height: 38px;
            }
        }
    }
    .row-2 {
        min-height: 40px;
        border: 2px solid #000;
        display: flex;
        word-break: break-all;
        .left {
            flex: 1.5;
            display: flex;
            .label {
                width: 55%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 2px solid #000;
            }
            .content {
                width: 45%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 2px solid #000;
                padding: 0 8px;
            }
        }
        .middle {
            flex: 1.4;
            display: flex;
            .label {
                width: 44%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 2px solid #000;
            }
            .content {
                width: 70%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 2px solid #000;
            }
        }
        .right {
            flex: 1.3;
            display: flex;
            .label {
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 2px solid #000;
            }
            .content {
                width: 70%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .row-column {
        min-height: 40px;
        border: 2px solid #000;
        display: flex;
        .label {
					width: 19.7%;
          display: flex;
          justify-content: center;
          align-items: center;
					border-right: 2px solid #000;
        }
        .content {
					width: 45%;
					text-align: center;
					line-height: 38px;
        }
    }
		.hand-check {
			width: 100%;
			min-height: 100px;
			border: 2px solid #000;
			display: flex;
			.label {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				border-right: 2px solid #000;
			}
			.content {
				flex: 8;
				position: relative;
			}
			.bottom-check {
				display: flex;
				position: absolute;
				bottom: 0;
				left: 10px;
        flex-wrap: nowrap;
				P {
					width: 300px;
				}
			}
			.content-row {
				display: flex;
				flex-direction: column;
				.content-row-1 {
					width: 100%;
					flex: 1;
					display: flex;
					.label {
						flex: 1;
						border-bottom: 2px solid #000;
					}
					.context {
            box-sizing: border-box;
            padding-left: 8px;
						flex: 7;
						border-bottom: 2px solid #000;
					}
					.noborder {
						border-bottom: none;
					}
				}
			}
		}
    .row-bottom {
      border-top: none;
    }
  } 
	.num {
		box-sizing: border-box;
		width: 100%;
		font-size: 20px;
		font-weight: bold;
		color: #000;
		text-align: right;
	}
  </style>
  