var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "screen-title" }, [
      _vm._v(" 工厂生产实时情况概览 "),
      _c("p", { staticClass: "date" }, [_vm._v(_vm._s(_vm.date))]),
    ]),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "card target" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v("班组周/员工日目标完成率"),
          ]),
          _c(
            "div",
            { staticClass: "content" },
            [
              _vm.groupTargetInfo.all.length
                ? [
                    _c("div", {
                      staticClass: "bar week",
                      attrs: { id: "groupBar" },
                    }),
                  ]
                : [
                    _c("p", { staticClass: "nullStatus" }, [
                      _vm._v("班组周目标暂无数据"),
                    ]),
                  ],
              _vm.memberTargetInfo.all.length
                ? [
                    _c("div", {
                      staticClass: "bar day",
                      attrs: { id: "memberBar" },
                    }),
                  ]
                : [
                    _c("p", { staticClass: "nullStatus" }, [
                      _vm._v("员工日目标暂无数据"),
                    ]),
                  ],
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "card craft" }, [
          _c("p", { staticClass: "title" }, [_vm._v("排产计划情况概览")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "custom_item" }, [
              _c("p", { staticClass: "custom_item_title" }, [
                _vm._v("计划待制定项数"),
              ]),
              _c(
                "div",
                { staticClass: "custom_item_list" },
                _vm._l(_vm.planData, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.productType,
                      staticClass: "item",
                      class: { gap: item.productType !== "量具&刀具" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "item-data" },
                        [
                          _c("countTo", {
                            attrs: {
                              "start-val": 0,
                              "end-val": Number(item.totalPlanNotMakeCount),
                              duration: 1000,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "item-desc" }, [
                        _vm._v(
                          _vm._s(
                            item.productType === "量具&刀具"
                              ? "量具&刀具"
                              : item.productType
                          )
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "middle" }, [
        _c("div", { staticClass: "card all_target" }, [
          _c("p", { staticClass: "title" }, [_vm._v("目标进度概览")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "content-left target" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v("当前" + _vm._s(_vm.productType) + "型月目标完成情况"),
              ]),
              _c("div", { staticClass: "item-list" }, [
                _c("div", { staticClass: "item" }, [
                  _vm._m(0),
                  _vm.monthTargetInfo.taskStatisticsInfo
                    ? _c("div", { staticClass: "pie-info" }, [
                        _c("p", [
                          _vm._v(
                            "总计: " +
                              _vm._s(
                                _vm.monthTargetInfo.taskStatisticsInfo
                                  .totalCount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "完成: " +
                              _vm._s(
                                _vm.monthTargetInfo.taskStatisticsInfo
                                  .finishedCount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "未完成: " +
                              _vm._s(
                                _vm.monthTargetInfo.taskStatisticsInfo
                                  .totalCount -
                                  _vm.monthTargetInfo.taskStatisticsInfo
                                    .finishedCount
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "item" }, [
                  _vm._m(1),
                  _vm.monthTargetInfo.productStatisticsInfo
                    ? _c("div", { staticClass: "pie-info" }, [
                        _c("p", [
                          _vm._v(
                            "总计: " +
                              _vm._s(
                                _vm.monthTargetInfo.productStatisticsInfo
                                  .totalCount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "完成: " +
                              _vm._s(
                                _vm.monthTargetInfo.productStatisticsInfo
                                  .finishedCount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "未完成: " +
                              _vm._s(
                                _vm.monthTargetInfo.productStatisticsInfo
                                  .totalCount -
                                  _vm.monthTargetInfo.productStatisticsInfo
                                    .finishedCount
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "item" }, [
                  _vm._m(2),
                  _vm.monthTargetInfo.amountStatisticsInfo
                    ? _c("div", { staticClass: "pie-info" }, [
                        _c("p", [
                          _vm._v(
                            "总计: " +
                              _vm._s(
                                _vm.monthTargetInfo.amountStatisticsInfo
                                  .totalAmount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "完成: " +
                              _vm._s(
                                _vm.monthTargetInfo.amountStatisticsInfo
                                  .finishedAmount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "未完成: " +
                              _vm._s(
                                (
                                  _vm.monthTargetInfo.amountStatisticsInfo
                                    .totalAmount -
                                  _vm.monthTargetInfo.amountStatisticsInfo
                                    .finishedAmount
                                ).toFixed(2)
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "content-right target" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v("当前" + _vm._s(_vm.productType) + "型周目标完成情况"),
              ]),
              _c("div", { staticClass: "item-list" }, [
                _c("div", { staticClass: "item" }, [
                  _vm._m(3),
                  _vm.weekTargetInfo.taskStatisticsInfo
                    ? _c("div", { staticClass: "pie-info" }, [
                        _c("p", [
                          _vm._v(
                            "总计: " +
                              _vm._s(
                                _vm.weekTargetInfo.taskStatisticsInfo.totalCount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "完成: " +
                              _vm._s(
                                _vm.weekTargetInfo.taskStatisticsInfo
                                  .finishedCount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "未完成: " +
                              _vm._s(
                                _vm.weekTargetInfo.taskStatisticsInfo
                                  .totalCount -
                                  _vm.weekTargetInfo.taskStatisticsInfo
                                    .finishedCount
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "item" }, [
                  _vm._m(4),
                  _vm.weekTargetInfo.productStatisticsInfo
                    ? _c("div", { staticClass: "pie-info" }, [
                        _c("p", [
                          _vm._v(
                            "总计: " +
                              _vm._s(
                                _vm.weekTargetInfo.productStatisticsInfo
                                  .totalCount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "完成: " +
                              _vm._s(
                                _vm.weekTargetInfo.productStatisticsInfo
                                  .finishedCount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "未完成: " +
                              _vm._s(
                                _vm.weekTargetInfo.productStatisticsInfo
                                  .totalCount -
                                  _vm.weekTargetInfo.productStatisticsInfo
                                    .finishedCount
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "item" }, [
                  _vm._m(5),
                  _vm.weekTargetInfo.amountStatisticsInfo
                    ? _c("div", { staticClass: "pie-info" }, [
                        _c("p", [
                          _vm._v(
                            "总计: " +
                              _vm._s(
                                _vm.weekTargetInfo.amountStatisticsInfo
                                  .totalAmount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "完成: " +
                              _vm._s(
                                _vm.weekTargetInfo.amountStatisticsInfo
                                  .finishedAmount
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "未完成: " +
                              _vm._s(
                                (
                                  _vm.weekTargetInfo.amountStatisticsInfo
                                    .totalAmount -
                                  _vm.weekTargetInfo.amountStatisticsInfo
                                    .finishedAmount
                                ).toFixed(2)
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card all_inventory" }, [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "inventory-part" }, [
              _c("p", { staticClass: "title" }, [_vm._v("入库信息概览")]),
              _c("div", { staticClass: "custom_item" }, [
                _c("p", { staticClass: "custom_item_title" }, [
                  _vm._v("当月入库情况"),
                ]),
                _c("div", { staticClass: "custom_item_list" }, [
                  _c("div", { staticClass: "item gap" }, [
                    _c(
                      "p",
                      { staticClass: "item-data" },
                      [
                        _c("countTo", {
                          attrs: {
                            "start-val": 0,
                            "end-val": Number(
                              _vm.inventoryData.mouthPutInProductItemCount
                            ),
                            duration: 1000,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "item-desc" }, [_vm._v("产品项数")]),
                  ]),
                  _c("div", { staticClass: "item gap" }, [
                    _c(
                      "p",
                      { staticClass: "item-data" },
                      [
                        _c("countTo", {
                          attrs: {
                            "start-val": 0,
                            "end-val": Number(
                              _vm.inventoryData.mouthPutInProductCount
                            ),
                            duration: 1000,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "item-desc" }, [_vm._v("产品数量")]),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c(
                      "p",
                      { staticClass: "item-data" },
                      [
                        _c("countTo", {
                          attrs: {
                            "start-val": 0,
                            "end-val": Number(
                              _vm.inventoryData.mouthPutInAmount
                            ),
                            duration: 1000,
                            decimals: 2,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "item-desc" }, [
                      _vm._v("产值金额(元)"),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "custom_item" }, [
                _c("p", { staticClass: "custom_item_title" }, [
                  _vm._v("当日入库情况"),
                ]),
                _c("div", { staticClass: "custom_item_list" }, [
                  _c("div", { staticClass: "item gap" }, [
                    _c(
                      "p",
                      { staticClass: "item-data" },
                      [
                        _c("countTo", {
                          attrs: {
                            "start-val": 0,
                            "end-val": Number(
                              _vm.inventoryData.dayPutInProductItemCount
                            ),
                            duration: 1000,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "item-desc" }, [_vm._v("产品项数")]),
                  ]),
                  _c("div", { staticClass: "item gap" }, [
                    _c(
                      "p",
                      { staticClass: "item-data" },
                      [
                        _c("countTo", {
                          attrs: {
                            "start-val": 0,
                            "end-val": Number(
                              _vm.inventoryData.dayPutInProductCount
                            ),
                            duration: 1000,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "item-desc" }, [_vm._v("产品数量")]),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c(
                      "p",
                      { staticClass: "item-data" },
                      [
                        _c("countTo", {
                          attrs: {
                            "start-val": 0,
                            "end-val": Number(_vm.inventoryData.dayPutInAmount),
                            duration: 1000,
                            decimals: 2,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "item-desc" }, [
                      _vm._v("产值金额(元)"),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "plan-part" }, [
              _c("p", { staticClass: "title" }, [_vm._v("工艺情况概览")]),
              _c("div", { staticClass: "custom_item" }, [
                _c("p", { staticClass: "custom_item_title" }, [
                  _vm._v("工艺待编制项数"),
                ]),
                _c(
                  "div",
                  { staticClass: "custom_item_list" },
                  _vm._l(_vm.planData, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.productType,
                        staticClass: "item",
                        class: { gap: item.productType !== "量具&刀具" },
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "item-data" },
                          [
                            _c("countTo", {
                              attrs: {
                                "start-val": 0,
                                "end-val": Number(
                                  item.totalNotFinishCraftCount
                                ),
                                duration: 1000,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "item-desc" }, [
                          _vm._v(
                            _vm._s(
                              item.productType === "量具&刀具"
                                ? "量具&刀具"
                                : item.productType
                            )
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "custom_item" }, [
                _c("p", { staticClass: "custom_item_title" }, [
                  _vm._v("工艺编制超期项数"),
                ]),
                _c(
                  "div",
                  { staticClass: "custom_item_list" },
                  _vm._l(_vm.planData, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.productType,
                        staticClass: "item",
                        class: { gap: item.productType !== "量具&刀具" },
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "item-data" },
                          [
                            _c("countTo", {
                              attrs: {
                                "start-val": 0,
                                "end-val": Number(item.totalOverdueCraftCount),
                                duration: 1000,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "item-desc" }, [
                          _vm._v(
                            _vm._s(
                              item.productType === "量具&刀具"
                                ? "量具&刀具"
                                : item.productType
                            )
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card emg" }, [
          _c("p", { staticClass: "title" }, [_vm._v("未完急件情况概览")]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "emg-part" },
              _vm._l(_vm.emgData, function (emg) {
                return _c(
                  "div",
                  { key: emg.emergencyType, staticClass: "custom_item" },
                  [
                    _c("p", { staticClass: "custom_item_title" }, [
                      _vm._v(_vm._s(_vm.EmergencyType[emg.emergencyType])),
                    ]),
                    _c("div", { staticClass: "custom_item_list" }, [
                      _c("div", { staticClass: "item gap" }, [
                        _c(
                          "p",
                          { staticClass: "item-data" },
                          [
                            _c("countTo", {
                              attrs: {
                                "start-val": 0,
                                "end-val": Number(
                                  emg.totalNotFinishTaskItemCount
                                ),
                                duration: 1000,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "item-desc" }, [
                          _vm._v("任务项数"),
                        ]),
                      ]),
                      _c("div", { staticClass: "item gap" }, [
                        _c(
                          "p",
                          { staticClass: "item-data" },
                          [
                            _c("countTo", {
                              attrs: {
                                "start-val": 0,
                                "end-val": Number(
                                  emg.totalNotFinishProductCount
                                ),
                                duration: 1000,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "item-desc" }, [
                          _vm._v("产品数量"),
                        ]),
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _c(
                          "p",
                          { staticClass: "item-data" },
                          [
                            _c("countTo", {
                              attrs: {
                                "start-val": 0,
                                "end-val": Number(emg.totalNotFinishAmount),
                                duration: 1000,
                                decimals: 2,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "item-desc" }, [
                          _vm._v("产值金额(元)"),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "right" }, [
        _vm._m(6),
        _c("div", { staticClass: "card task" }, [
          _c("p", { staticClass: "title" }, [_vm._v("未完成任务信息概览")]),
          _c("div", { staticClass: "content" }, [
            _c("p", { staticClass: "content-title" }, [
              _vm._v(
                _vm._s(
                  _vm.showingTaskInfo.show.productType === "量具"
                    ? "L型"
                    : _vm.showingTaskInfo.show.productType === "刀具"
                    ? "D型"
                    : "LD型"
                )
              ),
            ]),
            _c("div", { staticClass: "custom_item" }, [
              _c("div", { staticClass: "custom_item_list" }, [
                _c("div", { staticClass: "item gap" }, [
                  _c(
                    "p",
                    { staticClass: "item-data" },
                    [
                      _c("countTo", {
                        attrs: {
                          "start-val": 0,
                          "end-val": Number(
                            _vm.showingTaskInfo.show.totalNotFinishTaskItemCount
                          ),
                          duration: 1000,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "item-desc" }, [_vm._v("产品项数")]),
                ]),
                _c("div", { staticClass: "item gap" }, [
                  _c(
                    "p",
                    { staticClass: "item-data" },
                    [
                      _c("countTo", {
                        attrs: {
                          "start-val": 0,
                          "end-val": Number(
                            _vm.showingTaskInfo.show.totalNotFinishProductCount
                          ),
                          duration: 1000,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "item-desc" }, [_vm._v("产品数量")]),
                ]),
                _c("div", { staticClass: "item amount" }, [
                  _c(
                    "p",
                    { staticClass: "item-data" },
                    [
                      _c("countTo", {
                        attrs: {
                          "start-val": 0,
                          "end-val": Number(
                            _vm.showingTaskInfo.show.totalNotFinishAmount
                          ),
                          duration: 1000,
                          decimals: 2,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "item-desc" }, [_vm._v("产值金额")]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pie" }, [
      _c("div", { staticClass: "pie", attrs: { id: "monthTaskPie" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pie" }, [
      _c("div", { staticClass: "pie", attrs: { id: "monthProductPie" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pie" }, [
      _c("div", { staticClass: "pie", attrs: { id: "monthAmountPie" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pie" }, [
      _c("div", { staticClass: "pie", attrs: { id: "weekTaskPie" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pie" }, [
      _c("div", { staticClass: "pie", attrs: { id: "weekProductPie" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pie" }, [
      _c("div", { staticClass: "pie", attrs: { id: "weekAmountPie" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card opearation" }, [
      _c("p", { staticClass: "title" }, [_vm._v("工序待加工任务信息概览")]),
      _c("div", { staticClass: "bar", attrs: { id: "productBar" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }