<template>
  <div class="app-container">
    <div class="top-tip">
      <div class="selected-wrapper">
        <span v-if="hasSelected">
          已选中
          <strong>{{ selectedRowKeys.length }}</strong> 项任务
        </span>
        <a-dropdown v-if="hasSelected">
          <a class="batch-operate-link" @click="(e) => e.preventDefault()">
            <a-icon type="edit" />批量操作
          </a>

          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="handleCreateSaveInventoryCard(selectedRowKeys)">生成入库单</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="handleBatchCreateAbandonCard(selectedRowKeys)">生成废品入库交接单</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="handleBatchCancelTasks(selectedRowKeys)">撤销入库任务</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-select v-if="selectVisible" v-model="currentActiveType" class="type-select" @change="handleTypeChange">
      <a-select-option value="L">L计划</a-select-option>
      <a-select-option value="D">D计划</a-select-option>
      <a-select-option value="G">G计划</a-select-option>
    </a-select>
    <a-tabs :active-key="currentActiveKey" type="card" @change="handleTabChange">
      <!-- <a-tab-pane key="L" tab="L计划" />
      <a-tab-pane key="D" tab="D计划" />
      <a-tab-pane key="G" tab="G计划" />-->
      <a-tab-pane key="0" tab="成品库房" />
      <a-tab-pane key="1" tab="半成品库房" />
      <a-tab-pane key="2" tab="工装库" />
      <a-tab-pane key="4" tab="废品库" />
      <a-tab-pane key="5" tab="来料退回库" />
      <a-tab-pane key="3" tab="材料库" />
      <a-tab-pane key="6" tab="所有库" />
    </a-tabs>
    <a-table
      :key="tableKey"
      row-key="id"
      bordered
      :scroll="{ x: 3000 }"
      :columns="inventoryColumns"
      :data-source="inventoryList"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: inventoryRowSelection.getCheckboxProps,
      }"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-else-if="column.searchType === 'number'">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([e, selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([selectedKeys[0], e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker
            size="small"
            style="width: 190px; margin-bottom: 8px"
            :ranges="{
              今天: [$moment(), $moment()],
              本月: [$moment().startOf('month'), $moment().endOf('month')],
            }"
            @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <div slot="actualUseMaterialList" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span v-for="(item, index) in text" :key="index">
              {{item.materialName}}({{item.materialCode}})
              <span v-if="index < text.length - 1">,</span>
            </span>
          </template>
          <div class="table-ellis">
            <span v-for="(item, index) in text" :key="index">{{item.materialName}}({{item.materialCode}}),</span>
          </div>
        </a-tooltip>
      </div>
      <span slot="warehouseType" slot-scope="text">
        <a-tag v-if="text === 1" color="orange">半成品</a-tag>
        <a-tag v-if="text === 0" color="green">成品</a-tag>
        <a-tag v-if="text === 2" color="blue">工装</a-tag>
        <a-tag v-if="text === 4" color="red">废品</a-tag>
        <a-tag v-if="text === 5" color="red">来料退回库</a-tag>
        <a-tag v-if="text === 3" color="orange">材料</a-tag>
      </span>
      <span slot="status" slot-scope="text">
        <a-tag v-if="text === 'NRM'" color="green">正常</a-tag>
        <a-tag v-if="text === 'OCN'" color="blue">出库单撤销</a-tag>
        <a-tag v-if="text === 'ICN'" color="orange">入库单撤销</a-tag>
        <a-tag v-if="text === 'TCN'" color="red">入库任务撤销</a-tag>
        <a-tag v-if="text === 'WOT'" color="orange">待出库</a-tag>
        <a-tag v-if="text === 'FOT'" color="red">已出库</a-tag>
      </span>
      <template slot="oddNumbers" slot-scope="text, column">
        <template v-if="column.status === 'TCN'">已撤销</template>
        <template v-else-if="column.putInNumber">
          <el-link type="primary" @click="handleCheckInventory(column)">
            {{
            text
            }}
          </el-link>
        </template>
        <template v-else>
          <el-link type="primary" @click="handleCreateSingleCard(column)">点击生成</el-link>
        </template>
      </template>
      <span slot="isParts" slot-scope="text">
        <a-tag v-if="text === 0" color="green">否</a-tag>
        <a-tag v-if="text === 1" color="red">是</a-tag>
      </span>
      <div slot="putInComment" slot-scope="text, column">
        <template v-if="commentEditId === column.id">
          <a-input style="width: 150px; margin-right: 10px" :value="text" @blur="(e) => handleUpdateComment(e.target.value, column)" />
        </template>
        <a-tooltip v-else title="点击更新备注">
          <div style="cursor: pointer; width: 100%; height: 30px;" @click="handleEditComment(column.id)">
            <span>{{ text }}</span>
            <span v-if="column.abandonReceiptNumber">【报废单号---{{ column.abandonReceiptNumber }}】</span>
          </div>
        </a-tooltip>
      </div>
      <div slot="operation" slot-scope="text, column">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item
              v-if="column.putInNumber && !column.takeOutNumber && column.status !== 'TCN'"
              @click="handleInReceipts(column)"
            >入库单撤销</a-menu-item>
            <a-menu-item v-if="!column.putInNumber && column.status !== 'TCN'" @click="handleCancelTasks(column)">入库任务撤销</a-menu-item>
            <a-menu-item @click="handleCheckLog(column)">查看操作日志</a-menu-item>
          </a-menu>
          <a>
            操作
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>

      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
    </a-table>
    <el-dropdown class="export-craft" @command="handleExportCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出入库记录</el-button>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item command="SELECTED">导出当前已选工艺</el-dropdown-item>
        <el-dropdown-item command="PAGE">导出当前页工艺</el-dropdown-item>-->
        <el-dropdown-item command="FILTERED">导出当前筛选记录</el-dropdown-item>
        <!-- <el-dropdown-item command="ALL">导出所有工艺</el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
    <CraftEditModal :modal-title="currentEditType" :craft-form="currentCraft" :visible="visible" />
    <ProcessRouteCard
      v-if="routeModalVisible"
      :modal-title="currentPlanCode"
      :record="currentProcessRoute"
      @cancel="handleRouteModalCanceled"
      @confirm="handleRouteModalConfirmed"
    />
    <CheckInventoryModal
      v-if="ifShowCheckInventoryModal"
      type="save"
      :current-warehouse-type="currentWarehouseType"
      :inventory-data="checkInventoryColumn"
      @cancel="handleCheckInventoryCanceled"
      @confirm="handleCheckInventoryConfirmed"
    />
    <InventoryRecordsModal
      v-if="ifShowInventoryRecordsModal"
      type="save"
      :records-data="recordIds"
      :warehouse-type="currentWarehouseType"
      @cancel="handleInventoryRecordsModalCanceled"
      @confirm="handleInventoryRecordsModalConfirmed"
    />
    <InventoryReceiptModal
      v-if="receiptModalVisible"
      :id="receiptIds"
      :type="receiptsType"
      @cancel="handleReceiptModalCanceled"
      @confirm="handleReceiptModalConfirmed"
    />
    <LogModal v-if="logModalVisible" :id="logId" type="IPC" @cancel="handleLogCanceled" />
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import * as R from 'ramda'
import { inventoryColumns, inventoryRowSelection } from './dataLogic'
import CraftEditModal from '@/components/CraftEditModal'
import CheckInventoryModal from '@/components/CheckInventoryModal'
import InventoryRecordsModal from '@/components/InventoryRecordsModal'
import ProcessRouteCard from '@/components/ProcessRouteCard'
import InventoryReceiptModal from '@/components/InventoryReceiptModal'
import LogModal from '@/components/LogModal'

import {
  getSaveInventorys,
  exportSaveInventory,
  updateRecordsComment
} from '@/api/inventory'
import { downloadItem } from '@/utils/api.request'

export default {
  components: {
    CraftEditModal,
    CheckInventoryModal,
    ProcessRouteCard,
    InventoryRecordsModal,
    InventoryReceiptModal,
    LogModal
  },
  data() {
    return {
      selectVisible: true,
      tableKey: 0,
      loading: false,
      inventoryList: [],
      inventoryColumns,
      inventoryRowSelection,
      selectedRowKeys: [],
      selectedRowPlanIds: [],
      searchInput: null,
      baseURL: '',
      fileURL: '',
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initInventoryListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      currentInventoryListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
        recordWarehouseType: 'eq:0',
        'product.type': 'eq:L'
      },
      currentActiveKey: '0',
      currentActiveType: 'L',
      currentEditType: '更新',
      currentCraft: {},
      currentPlanCode: '',
      currentProcessRoute: [],
      visible: false,
      routeModalVisible: false,
      columnSearchParams: {},
      ifShowCheckInventoryModal: false,
      checkInventoryColumn: {},
      ifShowInventoryRecordsModal: false,
      recordIds: [],
      exportLoading: false,
      commentEditId: '',
      receiptModalVisible: false,
      receiptIds: '',
      receiptsType: '',
      logModalVisible: false,
      logId: '',
      currentWarehouseType: ''
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  created() {
    // 初始化加载数据
    this.baseURL = process.env.VUE_APP_BASE_API
    this.getInventorys(this.currentInventoryListQueryParams)
  },
  methods: {
    handleTypeChange(activeType) {
      this.currentInventoryListQueryParams['product.type'] = 'eq:' + activeType
      this.selectedRowKeys = []
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInventorys(this.currentInventoryListQueryParams)
      this.currentActiveType = activeType
      this.tableKey += 1
    },
    handleTabChange(activeKey) {
      if (activeKey === '6') {
        delete this.currentInventoryListQueryParams['recordWarehouseType']
      } else {
        this.currentInventoryListQueryParams['recordWarehouseType'] =
          'eq:' + activeKey
      }
      if (activeKey !== '0' && activeKey !== '6') {
        delete this.currentInventoryListQueryParams['product.type']
        this.selectVisible = false
      } else {
        this.currentInventoryListQueryParams['product.type'] =
          'eq:' + this.currentActiveType
        this.selectVisible = true
      }

      this.selectedRowKeys = []
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.currentInventoryListQueryParams.pageNum = 1
      this.getInventorys(this.currentInventoryListQueryParams)
      this.currentActiveKey = activeKey
      this.tableKey += 1
    },
    // 获取工艺数据
    getInventorys(data) {
      this.loading = true
      getSaveInventorys(data)
        .then(res => {
          this.inventoryList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getInventorysByParams() {
      const cQueryParams = deepClone(this.currentInventoryListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getInventorys(queryParams)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    // 重置搜索筛选条件
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowPlanIds = selectedRows.map(val => {
        return val.planId
      })
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('isParts')) {
        delete this.columnSearchParams['isParts']
        if (filters['isParts'].length > 0) {
          this.columnSearchParams['isParts'] = `in:${filters[
            'isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['isParts']
        }
      }
      if (filtersKeys.includes('status')) {
        delete this.columnSearchParams['inOutRecord.status']
        if (filters['status'].length > 0) {
          this.columnSearchParams['inOutRecord.status'] = `in:${filters[
            'status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['inOutRecord.status']
        }
      }
      if (filtersKeys.includes('recordWarehouseType')) {
        delete this.columnSearchParams['recordWarehouseType']
        if (filters['recordWarehouseType'].length > 0) {
          this.columnSearchParams['recordWarehouseType'] = `in:${filters[
            'recordWarehouseType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['recordWarehouseType']
        }
      }

      this.pagination = pagination
      this.currentInventoryListQueryParams.pageNum = pagination.current
      this.currentInventoryListQueryParams.pageSize = pagination.pageSize

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentInventoryListQueryParams.sort_by =
          sortType + sorter.columnKey
      } else {
        this.currentInventoryListQueryParams.sort_by = null
      }

      this.getInventorysByParams()
    },
    handleCheckInventory(column) {
      this.checkInventoryColumn = column
      this.ifShowCheckInventoryModal = true
    },
    handleCheckInventoryConfirmed() {
      this.checkInventoryColumn = {}
      this.ifShowCheckInventoryModal = false
      this.getInventorysByParams()
    },
    handleCheckInventoryCanceled() {
      this.checkInventoryColumn = {}
      this.ifShowCheckInventoryModal = false
    },
    handleCreateSingleCard(column) {
      this.recordIds = [column.id]
      this.currentWarehouseType = column.recordWarehouseType
      this.ifShowInventoryRecordsModal = true
    },
    handleCreateSaveInventoryCard(selectedRowKeys) {
      this.recordIds = selectedRowKeys
      this.ifShowInventoryRecordsModal = true
    },
    handleBatchCreateAbandonCard(selectedRowKeys) {
      this.recordIds = selectedRowKeys
      this.currentWarehouseType = 4
      this.ifShowInventoryRecordsModal = true
    },
    handleInventoryRecordsModalCanceled() {
      this.ifShowInventoryRecordsModal = false
    },
    handleInventoryRecordsModalConfirmed() {
      this.selectedRowKeys = []
      this.recordIds = []
      this.ifShowInventoryRecordsModal = false
      this.getInventorysByParams()
    },
    handleExportCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'FILTERED':
          const cQueryParams = deepClone(this.currentInventoryListQueryParams)
          const queryParams = Object.assign(
            cQueryParams,
            this.columnSearchParams
          )
          exportSaveInventory({
            ...queryParams
          })
            .then(res => {
              this.exportLoading = false
              this.download(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        default:
          this.exportLoading = false
          break
      }
    },
    download(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        '入库记录表' + currentDate
      )
    },
    handleUpdateComment(value, column) {
      console.log(value, column)
      updateRecordsComment(column.id, {
        putInComment: value
      }).then(res => {
        this.$message.success('更新备注成功')
        this.commentEditId = null
        this.getInventorysByParams()
      })
    },
    handleEditComment(id) {
      this.commentEditId = id
    },
    handleInReceipts(column) {
      this.receiptsType = 'save'
      this.receiptModalVisible = true
      // this.receiptIds = column.id
      this.receiptIds = column.putInNumber
    },
    handleReceiptModalCanceled() {
      this.receiptModalVisible = false
    },
    handleReceiptModalConfirmed() {
      this.receiptModalVisible = false
      this.getInventorysByParams()
    },
    handleCancelTasks(column) {
      this.receiptsType = 'task'
      this.receiptModalVisible = true
      this.receiptIds = column.id
    },
    handleBatchCancelTasks(selectedRowKeys) {
      this.receiptsType = 'task'
      this.receiptModalVisible = true
      this.receiptIds = selectedRowKeys
    },
    handleCheckLog(column) {
      this.logId = column.id
      this.logModalVisible = true
    },
    handleLogCanceled() {
      this.logModalVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  .file-wrapper {
    width: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .filename-wrapper {
      display: inline-block;
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 10px;

      a {
        color: #67c23a;
      }
    }

    .filename-delete {
      color: #ff4d4f;
      margin-right: 8px;
    }
  }
  .batch-operate-link {
    padding-left: 20px;
    cursor: pointer;
    color: #409eff;
  }

  .type-select {
    z-index: 999;
    width: 100px;
    position: absolute;
    top: 72px;
    right: 20px;
  }

  .file-link {
    color: #409eff;
  }

  .craft-download-button {
    position: absolute;
    right: 20px;
    top: 70px;
  }
  .operation {
    .operation-btn {
      cursor: pointer;
      color: #409eff;
    }
  }

  .export-craft {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }
  .table-ellis {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
}
</style>
