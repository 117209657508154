var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: true,
        title: "新增合格证",
        "mask-closable": false,
        width: "600px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleReset } }, [_vm._v("重置")]),
          _c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("取消")]),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "检验员", prop: "checkerId" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    "show-search": "",
                    placeholder: "请选择检验员",
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterUser,
                  },
                  on: { select: _vm.handleUserSelected },
                  model: {
                    value: _vm.form.checkerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "checkerId", $$v)
                    },
                    expression: "form.checkerId",
                  },
                },
                _vm._l(_vm.currentUserList, function (user) {
                  return _c(
                    "a-select-option",
                    { key: user.id, attrs: { value: user.id } },
                    [
                      _vm._v(
                        _vm._s(user.realName) + "(" + _vm._s(user.uid) + ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "结论",
                prop: "checkResult",
                placeholder: "请选择结论",
              },
            },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "300px" },
                  model: {
                    value: _vm.form.checkResult,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "checkResult", $$v)
                    },
                    expression: "form.checkResult",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "合格" } }, [
                    _vm._v("合格"),
                  ]),
                  _c("a-select-option", { attrs: { value: "不合格" } }, [
                    _vm._v("不合格"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }