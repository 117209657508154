<template>
  <div class="app-container">
    <a-row>
      <a-col :span="2">
        <c-upload
          name="file"
          accept=".xlsx"
          :show-upload-list="false"
          :action="`${uploadUrl}materials/imports`"
          @change="handleFileChange"
          @beforeUpload="beforeUpload"
        >
          <el-button slot="content" class="header-button" type="primary" :loading="uploading" icon="el-icon-circle-plus-outline">导入材料</el-button>
        </c-upload>
      </a-col>
      <a-col :span="2">
        <el-button class="header-button" type="primary" icon="el-icon-circle-plus-outline" @click="handleAddMaterial">新增材料</el-button>
      </a-col>
      <a-col :span="3" :offset="17">
        <el-button type="text" icon="el-icon-download" @click="handleDownloadTemplate">点击下载材料导入模板</el-button>
      </a-col>
    </a-row>
    <a-table
      row-key="id"
      bordered
      class="operations-table"
      :columns="materialColumns"
      :data-source="materialList"
      :loading="loading"
      :pagination="pagination"
      :scroll="{y: 590 }"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`搜索 ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </template>
        <div v-if="column.searchType===&quot;number&quot;">
          <a-input-number
            v-model="selectedKeys[0]"
            size="small"
            placeholder="最小值"
            :min="0"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e,selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
          <span>-</span>
          <a-input-number
            v-model="selectedKeys[1]"
            size="small"
            placeholder="最大值"
            :min="selectedKeys[0]"
            :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0],e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)"
          />
        </div>
        <div v-if="column.searchType===&quot;date&quot;">
          <a-range-picker
            :show-time="{ format: 'HH:mm:ss' }"
            size="small"
            style="width: 330px; margin-bottom: 8px;"
            :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
            @change="(dates,dateStrings)=>setSelectedKeys([...dateStrings])"
          />
        </div>
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)"
        >搜索</a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operation" slot-scope="text, column" class="table-operation">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleUpdateMaterial(column)">更新材料</a-menu-item>
            <a-menu-item @click="handleDeleteMaterial(column)">删除材料</a-menu-item>
          </a-menu>
          <a class="operation-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <el-dropdown class="export-plan" @command="handleExportUserCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出材料</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="ALL">导出所有材料</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <MaterialEdit
      v-if="editModalVisible"
      :material-form="currentMaterial"
      :modal-title="currentEditType"
      @cancel="handleRecordModalCanceled"
      @confirm="handleRecordModalConfirmed"
    />
  </div>
</template>
<script>
import { CONSTANTS } from '@/utils/constants'
import { deepClone } from '@/utils'
import { downloadItem } from '@/utils/api.request'

import { materialColumns } from './dataLogic'
import MaterialEdit from '@/components/MaterialEdit'
import {
  getMaterials,
  deleteMaterial,
  exportAllMaterials
} from '@/api/material'
import { downloadTemplate } from '@/common/templateDownload'

export default {
  components: {
    MaterialEdit
  },
  data() {
    return {
      loading: false,
      uploading: false,
      materialList: [],
      materialColumns,
      selectedRowKeys: [],
      searchInput: null,
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initMaterialListQueryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentMaterialListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      uploadUrl: process.env.VUE_APP_BASE_API,
      currentEditType: '',
      currentMaterial: {},
      editModalVisible: false,
      columnSearchParams: {},
      exportLoading: false
    }
  },
  created() {
    this.getMaterials(this.initMaterialListQueryParams)
  },
  methods: {
    getMaterials(data) {
      this.loading = true
      getMaterials(data)
        .then(res => {
          this.materialList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getMaterialsByParams() {
      const cQueryParams = deepClone(this.currentMaterialListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getMaterials(queryParams)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)

      if (filtersKeys.includes('operationType')) {
        delete this.columnSearchParams['operationType']
        if (filters['operationType'].length > 0) {
          this.columnSearchParams['operationType'] = `in:${filters[
            'operationType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['operationType']
        }
      }

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentMaterialListQueryParams.sort_by =
          sortType + sorter.columnKey
      } else {
        this.currentMaterialListQueryParams.sort_by = null
      }

      this.pagination = pagination
      this.currentMaterialListQueryParams.pageNum = pagination.current
      this.currentMaterialListQueryParams.pageSize = pagination.pageSize
      this.getMaterialsByParams()
    },
    // 删除工序
    handleDeleteMaterial(column) {
      const materialName = column.materialName ? column.materialName : ''
      this.$confirm({
        content: '确认删除材料' + materialName + '?',
        onOk: () => {
          deleteMaterial(column.id).then(() => {
            this.$message.success('删除材料成功！')
            this.getMaterialsByParams()
          })
        }
      })
    },
    handleAddMaterial() {
      this.currentEditType = '新增材料'
      this.currentMaterial = {}
      this.editModalVisible = true
    },
    // 更新工序
    handleUpdateMaterial(column) {
      this.currentEditType = '更新材料'
      this.currentMaterial = deepClone(column)
      this.editModalVisible = true
    },
    handleEditCancel() {
      this.visible = false
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    getExportIdsFromSelection() {
      this.exportIds = this.selectedRowKeys
    },
    getExportIdsFromPage() {
      this.exportIds = this.operationList.map(item => {
        return item.id
      })
    },
    handleExportOperationCommand(command) {
      switch (command) {
        case 'SELECTED':
          if (this.selectedRowKeys.length === 0) {
            this.$message.warning('选择项不能为空！')
            break
          }
          this.getExportIdsFromSelection()
          exportOperations({
            ids: this.exportIds.toString()
          }).then(res => {
            this.downloadOperation(res)
          })
          break
        case 'PAGE':
          if (this.operationList.length === 0) {
            this.$message.warning('导出数据不能为！')
            break
          }
          this.getExportIdsFromPage()
          exportOperations({
            ids: this.exportIds.toString()
          }).then(res => {
            this.downloadOperation(res)
          })
          break
        case 'FILTERED':
          exportOperations({
            hasConditions: true
          }).then(res => {
            this.downloadOperation(res)
          })
          break
        case 'ALL': {
          exportOperations().then(res => {
            this.downloadOperation(res)
          })
          break
        }
        default:
          break
      }
    },
    handleRecordModalConfirmed() {
      this.editModalVisible = false
      this.getMaterials(this.currentMaterialListQueryParams)
    },
    handleRecordModalCanceled() {
      this.editModalVisible = false
    },
    handleDownloadTemplate() {
      downloadTemplate('material')
    },
    handleFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`批量导入失败，请稍后再试`)
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      this.$info({
        title: '材料信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入数据总计：' + response.totalNum + '条;'),
          h('p', '非法数据条数：' + response.failedNum + ';'),
          h('p', '导入成功数据条数：' + response.successfulNum + ';'),
          h('p', '非法数据信息:'),
          h('p', response.invalidMessages.join(' ||  '))
        ]),
        onOk: () => {
          this.uploading = false
          this.getMaterials(this.currentMaterialListQueryParams)
        }
      })
    },
    handleExportUserCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'ALL': {
          exportAllMaterials()
            .then(res => {
              this.downloadUser(res)
              this.exportLoading = false
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        }
        default:
          this.exportLoading = false
          break
      }
    },
    downloadUser(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.MARERIALS_FILE_NAME +
          currentDate +
          CONSTANTS.EXPORT_FILE_SUFFIX
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .operation-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-operation {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .header-button {
    margin-bottom: 10px;
  }
}
.export-plan {
  float: right;
  bottom: 55px;
  right: 0px;
}
</style>
