var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    _vm._g(
      _vm._b(
        {
          staticStyle: { width: "300px" },
          attrs: {
            "show-search": "",
            "default-active-first-option": false,
            placeholder: _vm.placeholder,
            "show-arrow": false,
            "filter-option": false,
            "not-found-content": _vm.fetching ? undefined : null,
          },
          on: {
            search: _vm.debounceFetch,
            change: function (value, option) {
              return _vm.onSelect(value, option)
            },
          },
        },
        "a-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.fetching
        ? _c("a-spin", {
            attrs: { slot: "notFoundContent", size: "small" },
            slot: "notFoundContent",
          })
        : _vm._e(),
      _vm._l(_vm.list, function (item) {
        return _c("a-select-option", { key: item[_vm.searchKey] }, [
          _vm._v(_vm._s(_vm.onShow(item))),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }