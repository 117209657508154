<template>
  <a-modal class="modal-container" visible="true" :title='userName+"员工角色设置"' :maskClosable="false"
    @cancel="handleCancel" width="800px">
    <template slot="footer">
      <a-button :loading="loading" @click="handleReset">
        重置
      </a-button>
      <a-button :loading="loading" @click="handleCancel">
        取消
      </a-button>
      <a-button type="primary" :loading="loading" @click="handleConfirm">
        确定
      </a-button>
    </template>
    <a-transfer :data-source="rolesData" :list-style="{
      width: '355px',
      height: '500px',
    }" show-search :target-keys="targetKeys" :titles="['未拥有角色', '已拥有角色']"
      :render="item => `${item.title} ${item.description}`" @change="handleChange">
      <span slot="notFoundContent">
        暂无数据
      </span>
    </a-transfer>
  </a-modal>
</template>
<script>
  import {
    getAllRoles,
  } from '@/api/role'
  import {
    updateRolesByUserId,
  } from '@/api/user'

  export default {
    props: {
      modalTitle: String,
      userForm: Object,
    },
    data() {
      return {
        loading: false,
        userName: this.userForm.realName,
        userId: this.userForm.id,
        rolesData: [],
        targetKeys: [],
      };
    },
    mounted() {
      this.getRolesData();
    },
    methods: {
      getRolesData() {
        let targetKeys = [];
        let rolesData = [];
        let currentUserRolesData = [];
        getAllRoles({
          userId: 'eq:' + this.userId,
        }).then(res => {
          if (res.data) {
            currentUserRolesData = res.data
            currentUserRolesData.forEach(item => {
              targetKeys.push(item.id);
            })
          }
        }).then(() => {
          getAllRoles().then(res => {
            if (res.data) {
              let rolesRes = res.data;
              rolesData = rolesRes.map(element => {
                return {
                  key: element.id,
                  title: element.name,
                  description: '(' + element.code + ')',
                }
              })
            }
            this.rolesData = rolesData;
            this.targetKeys = targetKeys;
          });
        })

      },
      setUserRolesByUserId() {
        this.loading = true;
        updateRolesByUserId(this.userId, this.targetKeys).then(res => {
          if (res.data && res.data.id) {
            const successMessage = '员工角色设置成功';
            const failMessage = '员工角色设置失败，请稍后重试';
            this.$emit('confirm');
            this.$message.success(successMessage);
          } else {
            this.$message.error(failMessage)
          }
        })
      },
      handleChange(targetKeys, direction, moveKeys) {
        this.targetKeys = targetKeys;
      },
      handleReset() {
        this.getRolesData();
      },
      handleConfirm() {
        this.setUserRolesByUserId()
      },
      handleCancel() {
        this.$emit('cancel');
      },
    },
  };

</script>
<style scoped>

</style>
