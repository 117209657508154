import request from '@/utils/api.request'
/**
 * 获取相关日志记录
 *
 */
export function getLogList(id) {
  return request({
    url: `/biz-operation-logs/${id}`,
    method: 'get',
  })
}
