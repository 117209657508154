export const productDetailColumns = [
  {
    title: '序号',
    width: 60,
    dataIndex: 'index',
    key: 'index',
    align: 'center',
  },
  {
    title: '产品名称',
    width: 150,
    ellipsis: true,
    dataIndex: 'productInfo.name',
    key: 'productInfo.name',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'name',
    },
  },
  {
    title: '产品图号',
    width: 200,
    ellipsis: true,
    dataIndex: 'productInfo.drawingNum',
    key: 'productInfo.drawingNum',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'drawingNum',
    },
  },
  {
    title: '产品规格',
    width: 150,
    ellipsis: true,
    dataIndex: 'productInfo.specification',
    key: 'productInfo.specification',
    align: 'center',
  },
  {
    title: '产品类型',
    width: 80,
    dataIndex: 'productInfo.type',
    key: 'productInfo.type',
    align: 'center',
  },
  {
    title: '核算状态',
    width: 100,
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '待核价',
        value: 'WCA',
      },
      {
        text: '核价中',
        value: 'CAC',
      },
      {
        text: '审价中',
        value: 'CCK',
      },
      {
        text: '待报出',
        value: 'QUT',
      },
      {
        text: '部分成交',
        value: 'PDL',
      },
      {
        text: '全部成交',
        value: 'ADL',
      },
      {
        text: '已报出',
        value: 'BGN',
      },
      {
        text: '未成交',
        value: 'DNL',
      },
    ],
  },
  {
    title: '询价数量',
    width: 100,
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
  },
  {
    title: '单位',
    width: 80,
    dataIndex: 'productInfo.unit',
    key: 'productInfo.unit',
    align: 'center',
  },
  {
    title: '询价备注',
    ellipsis: true,
    width: 100,
    dataIndex: 'comment',
    key: 'comment',
    align: 'center',
  },
  {
    title: '操作',
    width: 80,
    key: 'operate',
    align: 'center',
    fixed: 'right',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
