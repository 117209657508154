<template>
  <div class="app-container">
    <a-button type="primary" class="batch-button" :loading="searchLoading" @click="handlerAddBatchRecord()">批量录入</a-button>
    <a-input-search
      ref="search"
      v-model="searchTaskCode"
      size="large"
      :max-length="20"
      :allow-clear="true"
      class="input-search"
      placeholder="请输入任务编码或扫描二维码查询任务"
      :loading="searchLoading"
      enter-button="搜索"
      @search="onSearch"
    />
    <a-table
      row-key="id"
      bordered
      class="tasks-table"
      :columns="taskColumns"
      :data-source="taskList"
      :loading="loading"
      :scroll="{ x: 2700, y: 650 }"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <div slot="type" slot-scope="text">
        <template v-if="text === 0">
          <span>普通任务</span>
        </template>
        <template v-if="text === 1">
          <span>返修任务</span>
        </template>
        <template v-if="text === 2">
          <span>生产补投任务</span>
        </template>
        <template v-if="text === 3">
          <span>退库补投任务</span>
        </template>
        <template v-if="text === 4">
          <span>分批任务</span>
        </template>
        <template v-if="text === 5">
          <span>其他任务</span>
        </template>
      </div>
      <div slot="status" slot-scope="text">
        <template v-if="text === 0">
          <a-badge status="default" />准备中
        </template>
        <template v-if="text === 1">
          <a-badge status="default" />未开始
        </template>
        <template v-if="text === 2">
          <a-badge status="processing" />进行中
        </template>
        <template v-if="text === 3">
          <a-badge status="success" />正常完成
        </template>
        <template v-if="text === 4">
          <a-badge status="error" />超期进行中
        </template>
        <template v-if="text === 5">
          <a-badge status="warning" />即将超期
        </template>
        <template v-if="text === 6">
          <a-badge status="error" />超期完成
        </template>
        <template v-if="text === 8">
          <a-badge status="error" />已取消
        </template>
      </div>
      <div slot="operation" slot-scope="text, column" class="table-operation">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handlerAddRecord(column)">生产数据录入</el-button>
      </div>
    </a-table>
    <RecordEdit
      v-if="editModalVisible"
      :modal-title="currentEditType"
      :record-form="currentRecord"
      @cancel="handleRecordModalCanceled"
      @confirm="handleRecordModalConfirmed"
    />
    <BatchRecordEdit v-if="batchEditModalVisible" @cancel="handleBatchRecordModalCanceled" @confirm="handleBatchRecordModalConfirmed" />
    <CertificatesEdit
      v-show="certificatesEditVisible"
      :id="editCertificateTaskId"
      @cancel="handleCertificatesModalCanceled"
      @confirm="handleCertificatesModalConfirmed"
    />
  </div>
</template>
<script>
import RecordEdit from '@/components/RecordEdit'
import BatchRecordEdit from '@/components/BatchRecordEdit'
import CertificatesEdit from '@/components/CertificatesEdit'
import { taskColumns } from './dataLogic'
import { getTaskPlanProducts } from '@/api/task'
import * as R from 'ramda'
export default {
  components: {
    RecordEdit,
    BatchRecordEdit,
    CertificatesEdit,
  },
  data() {
    return {
      loading: false,
      searchLoading: false,

      taskList: [],
      taskColumns,
      searchTaskCode: '',
      editModalVisible: false,
      batchEditModalVisible: false,
      currentEditType: null,
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      currentTaskListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        'task.status': 'in:2,4,5,8',
      },
      currentRecord: {},
      visible: false,
      certificatesEditVisible: false,
      editCertificateTaskId: '',
    }
  },
  created() {
    this.getTasks(this.currentTaskListQueryParams)
  },
  methods: {
    getTasks(data) {
      this.loading = true
      getTaskPlanProducts(data)
        .then((res) => {
          this.taskList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
          this.searchLoading = false
        })
    },
    handleTableChange(pagination) {
      this.pagination = pagination
      this.currentTaskListQueryParams.pageNum = pagination.current
      this.currentTaskListQueryParams.pageSize = pagination.pageSize
      this.getTasks(this.currentTaskListQueryParams)
    },
    onSearch(value) {
      this.searchLoading = true
      if (value && R.trim(value) !== '') {
        this.currentTaskListQueryParams['task.code'] = 'eq:' + R.trim(value)
        this.loading = true
        getTaskPlanProducts(this.currentTaskListQueryParams)
          .then((res) => {
            this.taskList = res.data.records
            this.pagination.total = res.data.total
            if (this.taskList && this.taskList.length === 1) {
              this.handlerAddRecord({
                code: this.taskList[0].code,
                id: this.taskList[0].id,
                producingNum: this.taskList[0].producingNum,
                planId: this.taskList[0].planId,
              })
            }
          })
          .finally(() => {
            this.loading = false
            this.searchLoading = false
          })
      } else {
        this.currentTaskListQueryParams['task.code'] = null
        this.getTasks(this.currentTaskListQueryParams)
      }
    },
    handlerAddRecord(column) {
      this.currentEditType = '新增'
      this.currentRecord.id = null
      this.currentRecord.taskCode = column.code ? column.code : ''
      this.currentRecord.taskId = column.id
      this.currentRecord.userId = null
      this.currentRecord.operationId = null
      this.currentRecord.nextOperationId = null
      this.currentRecord.recordType = 1
      this.currentRecord.checkCount = column.producingNum
      this.currentRecord.actualCheckCount = column.producingNum
      this.currentRecord.generateTime = this.$moment().format(
        'YYYY-MM-DD HH:mm:ss'
      )
      this.currentRecord.expectMaterialTime = column.expectMaterialTime
      this.currentRecord.abandonCount = 0
      this.currentRecord.repairedCount = 0
      this.currentRecord.outOfToleranceCount = 0
      // 是否自检工序
      this.currentRecord.isSelfCheck = false
      console.log(column.planId)
      this.currentRecord.planId = column.planId
      this.editModalVisible = true
    },
    handlerAddBatchRecord() {
      this.batchEditModalVisible = true
    },
    handleRecordModalConfirmed(data) {
      this.searchTaskCode = ''
      if (data.certificate === 1) {
        console.log('sss')
        this.editCertificateTaskId = data.taskId
        this.certificatesEditVisible = true
      } else {
        this.$message.success('新增记录成功！')
        this.onSearch()
      }
      this.editModalVisible = false
    },
    handleBatchRecordModalCanceled() {
      this.batchEditModalVisible = false
    },
    handleBatchRecordModalConfirmed() {
      this.batchEditModalVisible = false
      this.$message.success('批量新增记录成功！')
      this.onSearch()
    },
    handleRecordModalCanceled() {
      this.editModalVisible = false
    },
    handleCertificatesModalConfirmed() {
      this.certificatesEditVisible = false
    },
    handleCertificatesModalCanceled() {
      this.certificatesEditVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 0 20px;

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .operation-btn {
    cursor: pointer;
    color: #409eff;
  }

  .export-task {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }
  .batch-button {
    top: 35px;
    left: 10px;
  }
  .input-search {
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 110px;
  }
}
</style>
