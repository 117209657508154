import { asyncRoutes, constantRoutes } from '@/router'
// const test = ['ProductManage', 'OrderManage', 'RoleManage', 'UserManage']
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
// function hasPermission(roles, route) {
//   if (route.meta && route.meta.roles) {
//     return roles.some(role => route.meta.roles.includes(role));
//   } else {
//     return true;
//   }
// }

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
// export function filterAsyncRoutes(routes, roles) {
//   const res = [];

//   routes.forEach(route => {
//     const tmp = { ...route };
//     if (hasPermission(roles, tmp)) {
//       if (tmp.children) {
//         tmp.children = filterAsyncRoutes(tmp.children, roles);
//       }
//       res.push(tmp);
//     }
//   });

//   return res;
// }

export function filterAsyncRoutes(routes) {
  const res = []
  asyncRoutes.forEach((route) => {
    const tmp = { ...route }
    if (routes.includes(tmp.name)) {
      res.push(tmp)
    } else {
      if (tmp.children) {
        tmp.children = filterChildRoutes(tmp.children, routes)
        if (tmp.children.length) {
          res.push(tmp)
        }
      }
    }
  })
  console.log(res)
  return res
}

export function filterChildRoutes(childrens, userRoutes) {
  const res = []
  childrens.forEach((route) => {
    const tmp = { ...route }
    if (tmp.children) {
      tmp.children = filterChildRoutes(tmp.children, userRoutes)
      if (tmp.children.length) {
        res.push(tmp)
      }
    } else {
      if (userRoutes.includes(tmp.name)) {
        res.push(tmp)
      }
    }
  })
  return res
}

const state = {
  routes: [],
  addRoutes: [],
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
}

const actions = {
  generateRoutes({ commit }, route) {
    // console.log(roles)
    return new Promise((resolve) => {
      const accessedRoutes = filterAsyncRoutes(route)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
