var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-container",
      attrs: {
        visible: "true",
        title: _vm.userName + "员工工种设置",
        "mask-closable": false,
        width: "800px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            { attrs: { loading: _vm.loading }, on: { click: _vm.handleReset } },
            [_vm._v("重置")]
          ),
          _c(
            "a-button",
            {
              attrs: { loading: _vm.loading },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("取消")]
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-transfer",
        {
          attrs: {
            "data-source": _vm.operationsData,
            "list-style": {
              width: "355px",
              height: "500px",
            },
            "show-search": "",
            "target-keys": _vm.targetKeys,
            titles: ["未拥有工种", "已拥有工种"],
            render: function (item) {
              return item.title + " " + item.description
            },
          },
          on: { change: _vm.handleChange },
        },
        [
          _c(
            "span",
            { attrs: { slot: "notFoundContent" }, slot: "notFoundContent" },
            [_vm._v("暂无数据")]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }