<template>
  <a-modal v-drag-modal class="modal-container" :visible="true" :title="title" :mask-closable="false" width="1200px" @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleConfirm">确定</a-button>
    </template>
    <div class="table-container">
      <a-table
        :key="tableKey"
        row-key="taskId"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        bordered
        :scroll="{ x: 800, y: 500 }"
        :columns="columns"
        :data-source="list"
        :loading="loading"
        :pagination="false"
      >
        <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
          <template v-if="column.searchType === 'text'">
            <a-input
              v-ant-ref="c => (searchInput = c)"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
          </template>
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >搜索</a-button>
          <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.dataIndex)">重置</a-button>
        </div>
      </a-table>
    </div>
  </a-modal>
</template>
<script>
import { getPlanInventoryNew } from '@/api/plan'
import { columns } from './dataLogic'
export default {
  props: {
    source: Array,
    title: String,
    productName: String,
    drawingNum: String,
    specification: String
  },
  data() {
    return {
      tableKey: 0,
      columns,
      list: [],
      selectedRowKeys: [],
      targetKeys: [],
      columnSearchParams: {},
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      currentTaskParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null
      },
      totalPages: '',
      loading: false,
      submitLoading: false,
      totalUserPages: '',
      fetchUserLoading: false,
      editableId: '',
      currentTurnsIntoNum: 0,
      list: this.source || [],
      currentProductName: this.productName || '',
      currentDrawingNum: this.drawingNum || '',
      currentSpecification: this.specification || ''
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$emit('confirm', this.currentTurnsIntoNum)
    },
    onSelectChange(rows, row) {
      this.selectedRowKeys = rows
      let _num = 0
      row.forEach(item => {
        _num += item.totalStockCount
      })
      this.currentTurnsIntoNum = _num
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      switch (dataIndex) {
        case 'productName':
          this.currentProductName = selectedKeys[0]
          break
        case 'drawingNum':
          this.currentDrawingNum = selectedKeys[0]
          break

        case 'specification':
          this.currentSpecification = selectedKeys[0]
          break
      }

      getPlanInventoryNew({
        productName: this.currentProductName,
        drawingNum: this.currentDrawingNum,
        specification: this.currentSpecification
      }).then(res => {
        this.list = res.data
      })
    },
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      switch (dataIndex) {
        case 'productName':
          this.currentProductName = this.productName
          break

        case 'drawingNum':
          this.currentDrawingNum = this.drawingNum
          break

        case 'specification':
          this.currentSpecification = this.specification
          break
      }
      getPlanInventoryNew({
        productName: this.currentProductName,
        drawingNum: this.currentDrawingNum,
        specification: this.currentSpecification
      }).then(res => {
        this.list = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-modal-body {
  height: 600px;
  overflow-y: scroll;
}
/deep/ .ant-checkbox-inner {
  border-color: black;
}
/deep/ .ant-modal {
  top: 50px;
  left: 100px;
}
.modal-container {
  overflow-y: scroll;
  .table-container {
    width: 100%;
    height: 620px;
    margin-top: 20px;
    .table-process {
      float: left;
      width: auto;
    }

    .process-desc p {
      margin: 0;
      width: 250px;
    }

    .description-font {
      color: rgb(31, 29, 29);
    }
  }
}
/deep/ .ant-steps-item-content {
  width: 250px;
}
/deep/ .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: hidden;
}
</style>
