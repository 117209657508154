export const offerColumns = [
  {
    title: '序号',
    width: 80,
    dataIndex: 'index',
    key: 'index',
    align: 'center',
    searchType: 'number',
    sorter: false,
    fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '报价单号',
    width: 160,
    dataIndex: 'code',
    key: 'code',
    searchType: 'text',
    sorter: true,
    fixed: 'left',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'code',
    },
  },
  {
    title: '询价单号',
    width: 170,
    dataIndex: 'inquiryCode',
    key: 'inquiryCode',
    searchType: 'text',
    fixed: 'left',
    sorter: false,
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'inquiryCode',
    },
  },
  {
    title: '报价单生成时间',
    width: 200,
    dataIndex: 'startTime',
    key: 'startTime',
    sorter: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '报价状态',
    width: 120,
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '待报出',
        value: 'WSO',
      },
      {
        text: '待成交',
        value: 'WDL',
      },

      {
        text: '已取消',
        value: 'CNL',
      },

      {
        text: '已成交',
        value: 'DEL',
      },

      {
        text: '部分成交',
        value: 'PDL',
      },

      {
        text: '未成交',
        value: 'NDL',
      },
      {
        text: '已撤销',
        value: 'RVC',
      },
    ],
  },
  {
    title: '报出时间',
    width: 200,
    dataIndex: 'sendOutTime',
    key: 'sendOutTime',
    sorter: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '成交时间',
    width: 200,
    dataIndex: 'dealTime',
    key: 'dealTime',
    sorter: true,
    align: 'center',
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户名称',
    width: 200,
    ellipsis: true,
    dataIndex: 'clientName',
    key: 'clientName',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品项数',
    width: 200,
    dataIndex: 'productItemCount',
    key: 'productItemCount',
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '无税总金额',
    width: 200,
    dataIndex: 'totalAmountTaxFree',
    key: 'totalAmountTaxFree',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '税率',
    width: 70,
    dataIndex: 'taxRate',
    key: 'taxRate',
    align: 'center',
    scopedSlots: {
      customRender: 'taxRate',
    },
  },
  {
    title: '含税总金额',
    width: 200,
    dataIndex: 'totalAmountWithTax',
    key: 'totalAmountWithTax',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '付款方式',
    width: 100,
    dataIndex: 'payType',
    key: 'payType',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'payType',
    },
  },
  {
    title: '交货方式',
    width: 100,
    dataIndex: 'deliveryType',
    key: 'deliveryType',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'deliveryType',
    },
  },

  {
    title: '备注',
    width: 200,
    dataIndex: 'comment',
    key: 'comment',
    align: 'center',
    ellipsis: true,
    searchType: 'text',
    scopedSlots: {
      customRender: 'customRender',
    },
  },
  {
    title: '操作',
    width: 80,
    fixed: 'right',
    key: 'operate',
    align: 'center',
    scopedSlots: {
      customRender: 'operate',
    },
  },
]
