var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c(
        "div",
        { staticClass: "custom-table", attrs: { id: "craft-card-print" } },
        [
          _c(
            "div",
            { staticStyle: { "page-break-after": "always" } },
            [
              _c("div", { staticClass: "title" }, [
                _vm._m(0),
                _vm._m(1),
                _c("div", { staticClass: "right" }, [
                  _vm._v("NO: " + _vm._s(_vm.data.returnReceiptNumber)),
                ]),
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "client" }, [
                  _vm._v("客户名称: " + _vm._s(_vm.data.client)),
                ]),
                _c("div", { staticClass: "date" }, [
                  _vm._v("退货日期: " + _vm._s(_vm.data.productReturnTime)),
                ]),
              ]),
              _c("a-table", {
                attrs: {
                  columns: _vm.columns,
                  "data-source": _vm.productList,
                  bordered: "",
                  pagination: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "defeatReasonInfo",
                    fn: function (text, column) {
                      return _c(
                        "div",
                        {},
                        [
                          text
                            ? [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      column.defeatReasonInfo
                                        .map(function (item) {
                                          return item.parameterName
                                        })
                                        .toString()
                                    )
                                  ),
                                ]),
                              ]
                            : [_vm._v(" " + _vm._s() + " ")],
                        ],
                        2
                      )
                    },
                  },
                ]),
              }),
              _c("div", { staticClass: "footer-form" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "top-left" }),
                  _c("div", { staticClass: "top-right" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("合计金额")]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.totalAmount)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "bottom" }, [
                  _c("div", { staticClass: "left" }, [
                    _vm._v("制单人：" + _vm._s(_vm.$store.state.user.name)),
                  ]),
                  _c("div", { staticClass: "middle" }, [
                    _vm._v("移交送货人："),
                  ]),
                  _c("div", { staticClass: "right" }, [_vm._v("收货人：")]),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-button",
        {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: "craft-card-print",
              expression: "'craft-card-print'",
            },
          ],
          attrs: { type: "primary", block: "" },
        },
        [_vm._v("打印")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        staticStyle: { width: "100%", height: "100%" },
        attrs: { src: require("@/assets/logo.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "middle" }, [
      _c("p", [_vm._v(" 重庆市DBR刀具有限公司 ")]),
      _c("p", [_vm._v(" 退库交接单 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }